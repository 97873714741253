import { gql } from '@urql/core';

import AccountUserFragment from '../account-user/account.user.fragment';

const QueryMe = gql`
  ${AccountUserFragment}

  query Me {
    me {
      user {
        id
        bio
        defaultAccountId
        email
        emailVerified
        externalNotificationId
        facebookUrl
        firstName
        instagramUrl
        jobDescription
        language
        avatarUrl
        lastName
        linkedinUrl
        location
        name
        phoneNumber
        phoneDisplay
        professionalEmail
        spokenLanguages
        twitterUrl
        why
        accounts {
          ...AccountUserFragment
        }
      }
    }
  }
`;

export default QueryMe;
