import { createContext, SetStateAction } from 'react';

import {
  RecruitmentNegotiationPropertyState,
  RecruitmentNegotiationRoom,
  RecruitmentNegotiationRoomStatus,
  RecruitmentNegotiationStateUpdateInput,
  Request,
} from '@domain/graphql.types';

type RecruitmentNegotiationContextProps = {
  request: Request | undefined;
  rooms: readonly RecruitmentNegotiationRoom[] | undefined;
  negotiationId: string;
  checkedRooms: string[];
  isComparing: boolean;
  isLoading: boolean;
  shouldDisplayMenu: (
    roomStatus: RecruitmentNegotiationRoomStatus,
    propertyState: RecruitmentNegotiationPropertyState
  ) => boolean;
  setIsComparing: (state: SetStateAction<boolean>) => void;
  setCheckedRooms: (state: SetStateAction<string[]>) => void;
  getModifiedProperties: <T extends object>(propertiesState: T) => string[];
  onAcceptValues: <T extends object>(
    id: string,
    key: keyof RecruitmentNegotiationStateUpdateInput,
    properties: (keyof T)[]
  ) => () => void;
  onRejectValues: <T extends object>(
    id: string,
    key: keyof RecruitmentNegotiationStateUpdateInput,
    properties: (keyof T)[]
  ) => () => void;
  refreshRecruitmentNegotiation: () => void;
};

const RecruitmentNegotiationContext =
  createContext<RecruitmentNegotiationContextProps>({
    request: undefined,
    negotiationId: '',
    rooms: [],
    checkedRooms: [],
    isComparing: false,
    isLoading: false,
    shouldDisplayMenu: () => false,
    setIsComparing: () => {},
    setCheckedRooms: () => {},
    getModifiedProperties: () => [],
    onAcceptValues: () => () => {},
    onRejectValues: () => () => {},
    refreshRecruitmentNegotiation: () => {},
  });

export default RecruitmentNegotiationContext;
