import { useMutation } from 'urql';

import { Mutation, MutationUserUpdateEmailArgs } from '@domain/graphql.types';
import { UserUpdateEmail } from '@infrastructure/graphql/user';

const useUserUpdateEmail = () => {
  const [{ data, fetching, error }, userUpdateEmail] = useMutation<
    Pick<Mutation, 'userUpdateEmail'>,
    MutationUserUpdateEmailArgs
  >(UserUpdateEmail);

  return {
    userUpdateEmail,
    data: data?.userUpdateEmail,
    fetching,
    error,
  };
};

export default useUserUpdateEmail;
