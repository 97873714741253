import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  DisplayButton,
  Fieldset,
  FormInputWithSuffix,
  Radio,
  SectionGrid,
  SectionGridRow,
  Stack,
} from '@application/components';
import { ConditionRemunerationType } from '@domain/graphql.types';

import { CandidateRequestFormFields } from '../../schema';
import { PaymentMethodsFields } from './fields';

type RecruitmentFieldsProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  register: UseFormRegister<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  trigger: UseFormTrigger<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
};

const RecruitmentFields = ({
  control,
  errors,
  setValue,
  register,
  trigger,
  watch,
}: RecruitmentFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const remunerationType = watch('recruitmentConditionRemunerationTypeValue');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateRequestFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <SectionGrid title={t('subtitles.recruitment')}>
      <SectionGridRow
        isTopRow
        rightChildren={
          <DisplayButton
            name="recruitmentConditionGuaranteedPeriodDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.maximumGuaranteeOffered')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.months')}
          mediumSuffix
          invalid={!!errors.recruitmentConditionGuaranteedPeriodValue}
          helperText={
            errors.recruitmentConditionGuaranteedPeriodValue?.message &&
            tGlobal(errors.recruitmentConditionGuaranteedPeriodValue?.message, {
              min: '1',
            })
          }
          {...register('recruitmentConditionGuaranteedPeriodValue')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="recruitmentConditionRemunerationTypeDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <Stack>
          <Controller
            name="recruitmentConditionRemunerationTypeValue"
            control={control}
            render={({ field: { name } }) => (
              <Fieldset
                legend={t('labels.remunerationType')}
                legendSize="medium"
              >
                <Cluster space={16} as="ul">
                  {Object.values(ConditionRemunerationType).map((type) => (
                    <li key={type}>
                      <Radio
                        name={name}
                        value={type}
                        label={t(
                          `enum.conditionRemunerationType.${type.toLowerCase()}`
                        )}
                        onChange={(e) => {
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          );
                          trigger(name);
                        }}
                        checked={remunerationType === type}
                        asButton
                      />
                    </li>
                  ))}
                </Cluster>
              </Fieldset>
            )}
          />

          {remunerationType ===
            ConditionRemunerationType.AnnualSalaryPercentage && (
            <FormInputWithSuffix
              type="number"
              minValue={1}
              label={t('labels.remunerationPercentage')}
              suffix={t('suffix.percentage')}
              mediumSuffix
              invalid={!!errors.recruitmentConditionRemunerationPercentage}
              helperText={
                errors.recruitmentConditionRemunerationPercentage?.message &&
                tGlobal(
                  errors.recruitmentConditionRemunerationPercentage?.message,
                  { min: '1' }
                )
              }
              {...register('recruitmentConditionRemunerationPercentage')}
            />
          )}

          {remunerationType === ConditionRemunerationType.FixedAmount && (
            <FormInputWithSuffix
              type="number"
              step={0.01}
              minValue={1}
              maxValue={99999999.99}
              label={t('labels.remunerationAmount')}
              suffix="$"
              mediumSuffix
              invalid={!!errors.recruitmentConditionRemunerationAmount}
              helperText={
                errors.recruitmentConditionRemunerationAmount?.message &&
                tGlobal(
                  errors.recruitmentConditionRemunerationAmount?.message,
                  {
                    max: '99,999,999.99',
                    min: '1',
                  }
                )
              }
              {...register('recruitmentConditionRemunerationAmount')}
            />
          )}

          {remunerationType === ConditionRemunerationType.HourlyRate && (
            <>
              <FormInputWithSuffix
                type="number"
                step={0.01}
                minValue={1}
                maxValue={99999999.99}
                label={t('labels.remunerationHourlyRate')}
                suffix={t('suffix.currencyPerHour')}
                mediumSuffix
                invalid={!!errors.recruitmentConditionRemunerationHourlyRate}
                helperText={
                  errors.recruitmentConditionRemunerationHourlyRate?.message &&
                  tGlobal(
                    errors.recruitmentConditionRemunerationHourlyRate?.message,
                    {
                      max: '99,999,999.99',
                      min: '1',
                    }
                  )
                }
                {...register('recruitmentConditionRemunerationHourlyRate')}
              />

              <FormInputWithSuffix
                type="number"
                minValue={1}
                label={t('labels.remunerationEstimatedHours')}
                suffix={t('suffix.hours')}
                mediumSuffix
                invalid={
                  !!errors.recruitmentConditionRemunerationEstimatedHours
                }
                helperText={
                  errors.recruitmentConditionRemunerationEstimatedHours
                    ?.message &&
                  tGlobal(
                    errors.recruitmentConditionRemunerationEstimatedHours
                      ?.message,
                    {
                      min: '1',
                    }
                  )
                }
                {...register('recruitmentConditionRemunerationEstimatedHours')}
              />
            </>
          )}
        </Stack>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="recruitmentConditionProbationPeriodDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.minimumTrialPeriodDuration')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.weeks')}
          mediumSuffix
          invalid={!!errors.recruitmentConditionProbationPeriodValue}
          helperText={
            errors.recruitmentConditionProbationPeriodValue?.message &&
            tGlobal(errors.recruitmentConditionProbationPeriodValue?.message, {
              min: '1',
            })
          }
          {...register('recruitmentConditionProbationPeriodValue')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="recruitmentConditionPaymentMethodDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <PaymentMethodsFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="recruitmentConditionBonusDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <Stack>
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.requestedBonus')}
            suffix="$"
            mediumSuffix
            noMargin
            invalid={!!errors.recruitmentConditionBonusValue}
            helperText={
              errors.recruitmentConditionBonusValue?.message &&
              tGlobal(errors.recruitmentConditionBonusValue?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('recruitmentConditionBonusValue')}
          />

          <FormInputWithSuffix
            label={t('labels.ifCandidateStaysMoreThan')}
            type="number"
            minValue={1}
            suffix={t('suffix.months')}
            mediumSuffix
            invalid={!!errors.recruitmentConditionBonusMonthValue}
            helperText={
              errors.recruitmentConditionBonusMonthValue?.message &&
              tGlobal(errors.recruitmentConditionBonusMonthValue?.message, {
                min: '1',
              })
            }
            {...register('recruitmentConditionBonusMonthValue')}
          />

          <FormInputWithSuffix
            label={t('labels.ifRecruitmentLessThan')}
            type="number"
            minValue={1}
            suffix={t('suffix.weeks')}
            mediumSuffix
            invalid={!!errors.recruitmentConditionBonusWeekValue}
            helperText={
              errors.recruitmentConditionBonusWeekValue?.message &&
              tGlobal(errors.recruitmentConditionBonusWeekValue?.message, {
                min: '1',
              })
            }
            {...register('recruitmentConditionBonusWeekValue')}
          />
        </Stack>
      </SectionGridRow>
    </SectionGrid>
  );
};

export default RecruitmentFields;
