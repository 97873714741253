import { gql } from '@urql/core';

const MutationAccountPreferredSpecialtyDelete = gql`
  mutation AccountPreferredSpecialtyDelete(
    $input: DeleteAccountPreferredSpecialtyInput!
  ) {
    accountPreferredSpecialtyDelete(input: $input) {
      ok
    }
  }
`;

export default MutationAccountPreferredSpecialtyDelete;
