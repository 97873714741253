import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { NoContent, Table } from '@application/components/table';
import { CandidateOffersFilters } from '@domain/graphql.types';

import FiltersFields from '../components/FiltersFields';
import { useCandidateOffersFilters } from '../hooks';
import { useCandidateOffersList } from '../hooks/useCandidateOffersList';

const CandidateOffersList = () => {
  const [tableFilters, setTableFilters] = useState<CandidateOffersFilters>();
  const { t } = useTranslation('candidates');

  const location = useLocation();

  const hash = useMemo(
    () =>
      location.hash ? location.hash.replace('#', '').replace('-', '_') : 'all',
    [location]
  );

  const {
    columns,
    currentPage,
    candidateOffers,
    sorting,
    count,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    isLoading,
  } = useCandidateOffersList(tableFilters);

  const { filters, updateSelectFilters, updateDateFilters, updateTextFilter } =
    useCandidateOffersFilters({ resetPagination: onPaginationReset });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="grow w-full mb-s-16">
        <FiltersFields
          updateTextFilter={updateTextFilter}
          updateSelectFilters={updateSelectFilters}
          updateDateFilters={updateDateFilters}
        />
      </div>

      <div
        className="relative mt-s-32"
        id="offers-list"
        aria-labelledby={
          filters.status?.length
            ? `tab-${String(filters.status).toLowerCase()}`
            : 'tab-all'
        }
        role="tabpanel"
      >
        <Table
          data={candidateOffers}
          columns={columns}
          count={count}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          paginationState={currentPage}
          onPaginationChange={onPaginationChange}
          onPaginationReset={onPaginationReset}
          isRowColorChangeOnHover
          noDataComponent={
            <NoContent title={t(`list.noContent.offers.${hash}.title`)} />
          }
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default CandidateOffersList;
