import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const RequestListFragment = gql`
  ${DescriptionsFragment}

  fragment RequestListFragment on Request {
    id
    jobSpecialtyCode
    jobOpenPositions
    status
    types
    operationUnitId
    createdAt
    updatedAt
    receivingOfferDeadline
    offersCount
    availableTransitions
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    operationUnit {
      name
    }
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    user {
      name
      avatarUrl
    }
  }
`;

export default RequestListFragment;
