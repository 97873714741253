import { gql } from 'urql';

const RecruitmentNegotiationHeadhunterPropertiesStateFragment = gql`
  fragment RecruitmentNegotiationHeadhunterPropertiesStateFragment on RecruitmentNegotiationHeadhunterPropertiesState {
    exclusivityPeriod
    maximumReplacement
  }
`;

export default RecruitmentNegotiationHeadhunterPropertiesStateFragment;
