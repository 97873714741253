import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AccountContext, AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { RequestStatusTypeCode } from '@domain/graphql.types';
import { useRequestCreate } from '@domain/request';

import { toViewModel } from '../view-models/create-request.vm';

const useCreateRequest = () => {
  const { data, fetching, error, createRequest } = useRequestCreate();

  const { t } = useTranslation('requests', {
    keyPrefix: 'messages',
  });

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);
  const navigate = useNavigate();

  const viewModel = toViewModel({ request: { data, fetching, error } });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('createError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.requestCreate?.request) {
      setAlertContent({
        action: t('createSuccess'),
        modifier: 'alert-success',
      });

      if (
        data?.requestCreate?.request.status === RequestStatusTypeCode.InProgress
      ) {
        refreshAccount();
      }

      navigate(
        generatePath(PrivatePage.REQUEST_DETAILS, {
          id: data.requestCreate.request.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { createRequest, viewModel };
};

export default useCreateRequest;
