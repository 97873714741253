import { useMutation } from 'urql';

import { Mutation, MutationAccountCreateArgs } from '@domain/graphql.types';
import { AccountCreate } from '@infrastructure/graphql/account';

const useAccountCreate = () => {
  const [{ data, fetching, error }, createAccount] = useMutation<
    Pick<Mutation, 'accountCreate'>,
    MutationAccountCreateArgs
  >(AccountCreate);

  return {
    createAccount,
    data,
    fetching,
    error,
  };
};

export default useAccountCreate;
