import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateNegotiationRoomRejectValuesArgs,
} from '@domain/graphql.types';
import { MutationCandidateNegotiationRoomRejectValues } from '@infrastructure/graphql/candidate';

const useCandidateNegotiationRoomRejectValues = () => {
  const [{ data, fetching, error }, rejectNegotiationRoomValues] = useMutation<
    Pick<Mutation, 'candidateNegotiationRoomRejectValues'>,
    MutationCandidateNegotiationRoomRejectValuesArgs
  >(MutationCandidateNegotiationRoomRejectValues);

  return {
    rejectNegotiationRoomValues,
    data,
    fetching,
    error,
  };
};

export default useCandidateNegotiationRoomRejectValues;
