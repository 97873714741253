import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  DisplayButton,
  Fieldset,
  FormInputWithSuffix,
  Radio,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { CandidateConditionBillingPeriod } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { CandidateRequestFormFields } from '../../schema';
import { ResponsibilityFields } from './fields';

type TemporaryPlacementFieldsProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  register: UseFormRegister<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
};

const TemporaryPlacementFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
}: TemporaryPlacementFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const possibleBuyback = watch(
    'temporaryPlacementConditionPossibleBuybackValue'
  );
  const billingPeriod = watch('temporaryPlacementConditionBillingPeriodValue');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateRequestFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <SectionGrid title={t('subtitles.temporaryPlacement')}>
      <SectionGridRow
        isTopRow
        rightChildren={
          <DisplayButton
            name="temporaryPlacementConditionProbationPeriodDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.minimumTrialPeriodDuration')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.weeks')}
          mediumSuffix
          invalid={!!errors.temporaryPlacementConditionProbationPeriodValue}
          helperText={
            errors.temporaryPlacementConditionProbationPeriodValue?.message &&
            tGlobal(
              errors.temporaryPlacementConditionProbationPeriodValue?.message,
              { min: '1' }
            )
          }
          {...register('temporaryPlacementConditionProbationPeriodValue')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="temporaryPlacementConditionMinimumNoticeDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.minimumNoticePeriod')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.weeks')}
          mediumSuffix
          invalid={!!errors.temporaryPlacementConditionMinimumNoticeValue}
          helperText={
            errors.temporaryPlacementConditionMinimumNoticeValue?.message &&
            tGlobal(
              errors.temporaryPlacementConditionMinimumNoticeValue?.message,
              { min: '1' }
            )
          }
          {...register('temporaryPlacementConditionMinimumNoticeValue')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="temporaryPlacementConditionPossibleBuybackDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <Controller
          name="temporaryPlacementConditionPossibleBuybackValue"
          control={control}
          render={({ field: { name } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.buyback')}
              language={extractLanguage(i18n.language)}
              legendSize="medium"
              value={possibleBuyback || undefined}
              onChange={(e) =>
                handleOnRadioChange(e as ChangeEvent<HTMLInputElement>, name)
              }
              alignRow
            />
          )}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="temporaryPlacementConditionPossibleBuybackBonusDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.defineBonusBuyout')}
          type="number"
          step={0.01}
          minValue={1}
          maxValue={99999999.99}
          noMargin
          suffix="$"
          mediumSuffix
          invalid={
            !!errors.temporaryPlacementConditionPossibleBuybackBonusValue
          }
          helperText={
            errors.temporaryPlacementConditionPossibleBuybackBonusValue
              ?.message &&
            tGlobal(
              errors.temporaryPlacementConditionPossibleBuybackBonusValue
                ?.message,
              {
                max: '99,999,999.99',
                min: '1',
              }
            )
          }
          {...register('temporaryPlacementConditionPossibleBuybackBonusValue')}
        />
      </SectionGridRow>

      <ResponsibilityFields
        control={control}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="temporaryPlacementConditionBillingPeriodDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <Controller
          name="temporaryPlacementConditionBillingPeriodValue"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset legend={t('labels.billingPeriod')} legendSize="medium">
              <Cluster space={16} as="ul">
                {Object.values(CandidateConditionBillingPeriod).map(
                  (period) => (
                    <li key={period}>
                      <Radio
                        name={name}
                        value={period}
                        label={t(
                          `enum.candidateConditionBillingPeriod.${period.toLowerCase()}`
                        )}
                        onChange={(e) =>
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={billingPeriod === period}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            </Fieldset>
          )}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default TemporaryPlacementFields;
