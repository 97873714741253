import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { NoContent, Table } from '@application/components/table';
import { CandidateRequestsFilters } from '@domain/graphql.types';

import { FiltersFields } from '../components';
import { useCandidateRequestFilters } from '../hooks';
import { useCandidateRequestsList } from '../hooks/useCandidateRequestsList';

const CandidateRequestsList = () => {
  const [tableFilters, setTableFilters] = useState<CandidateRequestsFilters>();

  const { t } = useTranslation('candidates', {
    keyPrefix: 'list.noContent.requests',
  });

  const location = useLocation();

  const hash = useMemo(
    () =>
      location.hash ? location.hash.replace('#', '').replace('-', '_') : 'all',
    [location]
  );

  const {
    columns,
    currentPage,
    candidateRequests,
    sorting,
    count,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    isLoading,
  } = useCandidateRequestsList(tableFilters);

  const { filters, updateSelectFilters, updateTextFilter } =
    useCandidateRequestFilters({ resetPagination: onPaginationReset });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="grow w-full mb-s-16">
        <FiltersFields
          updateTextFilter={updateTextFilter}
          updateSelectFilters={updateSelectFilters}
          // updateDateFilters={updateDateFilters}
        />
      </div>

      <div
        className="relative mt-s-32"
        id="candidate-requests-list"
        aria-labelledby={
          filters.status?.length
            ? `tab-${String(filters.status).toLowerCase()}`
            : 'tab-all'
        }
        role="tabpanel"
      >
        <Table
          data={candidateRequests}
          columns={columns}
          count={count}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          paginationState={currentPage}
          onPaginationChange={onPaginationChange}
          onPaginationReset={onPaginationReset}
          isRowColorChangeOnHover
          noDataComponent={
            <NoContent
              title={t(`${hash}.title`)}
              buttonLabel={t(`${hash}.button`)}
              // TODO : link to create candidate request
              path=""
            />
          }
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default CandidateRequestsList;
