import { useQuery } from 'urql';

import { Query, QueryRequestsArgs } from '@domain/graphql.types';
import { Requests } from '@infrastructure/graphql/request';

const useRequests = (input?: QueryRequestsArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'requests'>,
    QueryRequestsArgs
  >({
    query: Requests,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useRequests;
