import { useTranslation } from 'react-i18next';

import { OfferConditionResponsibility } from '@domain/graphql.types';

const useOfferConditionResponsibilityOptions = () => {
  const { t } = useTranslation();

  return Object.values(OfferConditionResponsibility).map((responsibility) => ({
    value: responsibility,
    label: t(
      `enum.offerConditionResponsibility.${responsibility.toLowerCase()}`
    ),
  }));
};

export default useOfferConditionResponsibilityOptions;
