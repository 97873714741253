// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components/switch';
import { RequestOutsourcingConditions } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../../constants';

const conditionsKey: (keyof RequestOutsourcingConditions)[] = [
  'maximumDurationValue',
  'exclusivityPeriodValue',
  'maximumBudgetValue',
];

type OutsourcingConditionsProps = {
  data: RequestOutsourcingConditions;
};

const OutsourcingConditions = ({ data }: OutsourcingConditionsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const getCellContent = useCallback(
    (condition: keyof RequestOutsourcingConditions) => {
      const cellValue = data?.[condition];

      switch (condition) {
        case 'maximumDurationValue':
        case 'exclusivityPeriodValue':
          return tGlobal('suffix.week', { count: cellValue as number });
        case 'maximumBudgetValue':
          return tGlobal('suffix.currencyPerHourValue', { value: cellValue });
        default:
          return '-';
      }
    },
    [data, tGlobal]
  );

  return (
    <>
      <tr>
        <th className="sr-only">
          <span>{t('outsourcingConditions')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.base, TD_CLASS_NAME.highlight)}>
          &nbsp;
        </td>
      </tr>
      {conditionsKey.map(
        (condition: keyof RequestOutsourcingConditions, index: number) => (
          <tr key={`requestOutsourcingConditions-${condition}`}>
            <th scope="col" className="sr-only">
              {t(condition)}
            </th>
            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-l-0',
                {
                  'border-b-0': index === conditionsKey.length - 1,
                }
              )}
            >
              <PlaceholderSwitcher value={data?.[condition]}>
                <span>{getCellContent(condition)}</span>
              </PlaceholderSwitcher>
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default OutsourcingConditions;
