import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateRequestDeleteArgs,
} from '@domain/graphql.types';
import { MutationCandidateRequestDelete } from '@infrastructure/graphql/candidate';

const useCandidateRequestDelete = () => {
  const [{ data, fetching, error }, deleteCandidateRequest] = useMutation<
    Pick<Mutation, 'candidateRequestDelete'>,
    MutationCandidateRequestDeleteArgs
  >(MutationCandidateRequestDelete);

  return {
    deleteCandidateRequest,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestDelete;
