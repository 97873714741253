import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';
import * as yup from 'yup';

import {
  ConfirmationModal,
  PageLoader,
  Stack,
  Stepper,
} from '@application/components';
import { LoadingSpinner } from '@application/components/spinner';
import { ModalContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { useWizard } from '@application/hooks';

import { useGetRecruitmentOpportunity } from '../opportunity/hooks';
import { CREATE_OFFER_STEPS, DEFAULT_VALUES } from './constants';
import { useCreateOffer } from './hooks';
import { normalizeOfferInputData } from './normalizers';
import {
  OFFER_CANDIDATE_INFORMATIONS_PUBLISH_SCHEMA,
  OFFER_PUBLISH_SCHEMA,
  OFFER_SCHEMA,
} from './schema';
import { CandidatePanel, ConditionsPanel } from './steps';
import { OfferFormFields } from './types';

const CreateOfferPage = () => {
  const [saving, setSaving] = useState<boolean>(false);
  const [currentSchema, setCurrentSchema] =
    useState<yup.ObjectSchema<any>>(OFFER_SCHEMA);

  const { t } = useTranslation('recruitment', { keyPrefix: 'offer' });

  const {
    createOffer,
    viewModel: { isLoading: isCreatingOffer },
  } = useCreateOffer();

  const { setModal } = useContext(ModalContext);

  const { id = '' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data: recruitmentOpportunity, isLoading },
  } = useGetRecruitmentOpportunity(id);

  const candidateInHandMandatoryValue =
    recruitmentOpportunity?.generalContractualConditions
      .candidateInHandMandatoryValue || false;

  const methods = useForm<OfferFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(currentSchema),
  });

  const { handleSubmit, setValue, formState, watch } = methods;

  const { activeStep, completedSteps, setActive, nextStep } = useWizard({
    steps: CREATE_OFFER_STEPS,
  });

  const candidates = watch('offerCandidates');
  const publishNow = watch('publishNow');

  const onSubmit: SubmitHandler<OfferFormFields> = useCallback(
    async (data) => {
      await createOffer({
        offerCreateInput: {
          ...(normalizeOfferInputData(data) as any),
          requestId: id,
        },
      });
    },
    [createOffer, id]
  );

  useEffect(() => {
    if (
      recruitmentOpportunity?.types &&
      recruitmentOpportunity?.types.length === 1
    ) {
      setValue('requestTypes', [...(recruitmentOpportunity?.types || [])]);
    }
  }, [recruitmentOpportunity?.types, setValue]);

  useEffect(() => {
    const foundErrorsFromOfferCandidateInformations = Object.keys(
      formState.errors
    ).filter((e) =>
      Object.keys(OFFER_CANDIDATE_INFORMATIONS_PUBLISH_SCHEMA.fields).includes(
        e
      )
    );

    if (
      saving &&
      publishNow &&
      candidateInHandMandatoryValue &&
      (!candidates || (candidates && candidates.length < 1))
    ) {
      setModal({
        title: t('modal.mustProvideCandidatesTitle'),
        maxWidth: '2xl',
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>{t('modal.mustProvideCandidates')}</p>{' '}
                <p>{t('modal.confirmRedirectStepOfferCandidate')}</p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              setModal(null);
              setActive(0);
            }}
          />
        ),
      });
    } else if (
      saving &&
      publishNow &&
      (methods.formState.errors?.offerCandidates ||
        foundErrorsFromOfferCandidateInformations.length > 0)
    ) {
      setModal({
        title: t('modal.fillOutEmptyRequiredField'),
        maxWidth: '2xl',
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    i18nKey="modal.requiredFieldError"
                    t={t}
                    values={{ step: t(CREATE_OFFER_STEPS[0].title) }}
                  />
                </p>
                <p>{t('modal.confirmRedirectStepOffer')}</p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              setModal(null);
              setActive(0);
            }}
          />
        ),
      });
    }
    setSaving(false);
  }, [
    candidateInHandMandatoryValue,
    candidates,
    formState.errors,
    methods,
    publishNow,
    saving,
    setActive,
    setModal,
    t,
  ]);

  const handleOnSave = () => {
    methods.setValue('publishNow', false);
    setCurrentSchema(OFFER_SCHEMA);
    setSaving(true);
  };

  const handleOnPublish = () => {
    methods.setValue('publishNow', true);
    setCurrentSchema(OFFER_PUBLISH_SCHEMA(candidateInHandMandatoryValue));
    setSaving(true);
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className="flex flex-col items-center justify-center w-full">
      {isCreatingOffer && <LoadingSpinner size="lg" overPage />}

      <div className="max-w-[66rem] w-full">
        <Stepper
          steps={CREATE_OFFER_STEPS}
          activeStep={activeStep}
          completedSteps={completedSteps}
          setActive={setActive}
          t={t}
        />

        <FormProvider {...methods}>
          <form className="md:px-s-48" onSubmit={handleSubmit(onSubmit)}>
            {activeStep === 0 && (
              <CandidatePanel
                recruitmentOpportunity={recruitmentOpportunity}
                nextStep={nextStep}
              />
            )}

            {activeStep === 1 && (
              <ConditionsPanel
                recruitmentOpportunity={recruitmentOpportunity!}
                onPublish={handleOnPublish}
                onSave={handleOnSave}
                isCreatingOffer={isCreatingOffer}
              />
            )}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default CreateOfferPage;
