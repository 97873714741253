import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreatableSelectField } from '@application/components';
import { OptionType } from '@application/components/select-field/select';
import { Section } from '@application/views/candidate/offer/components';
import { OfferFormFields } from '@application/views/recruitment/offer/types';

type SoftSkillsFieldsProps = {
  index: number;
};

const SoftSkillsFields = ({ index }: SoftSkillsFieldsProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'offer',
  });

  const { control, watch, setValue }: UseFormReturn<OfferFormFields> =
    useFormContext();

  const softSkills = watch(`offerCandidates.${index}.softSkills`) || [];

  return (
    <Section legend={t('subtitles.softSkills')} className="mt-s-40" hideBox>
      <Controller
        name={`offerCandidates.${index}.softSkills`}
        control={control}
        render={({ field: { name } }) => (
          <CreatableSelectField
            name={name}
            value={
              softSkills.map((s) => ({
                label: s,
                value: s,
              })) as OptionType[]
            }
            className="flex-1 min-w-fit"
            onChange={(options: any) => {
              setValue(
                name,
                options.map((option: any) => option.value)
              );
            }}
          />
        )}
      />
    </Section>
  );
};

export default SoftSkillsFields;
