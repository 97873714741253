import { useContext, useEffect } from 'react';

import { PageLoader } from '@application/components/page';
import { UserContext } from '@application/context';

type UserGuardProps = {
  children: React.ReactNode;
};

const UserGuard = ({ children }: UserGuardProps) => {
  const { error, initializeUser, initializationCompleted } =
    useContext(UserContext);

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  if (!initializationCompleted) {
    return <PageLoader />;
  }

  if (error) {
    throw error;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default UserGuard;
