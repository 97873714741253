import { usePublicAccount } from '@domain/account';

import { toViewModel } from '../view-models/get-public-account.vm';

const useGetPublicAccount = (accountId: string) => {
  const { data, fetching, error, reexecuteQuery } = usePublicAccount(accountId);

  const viewModel = toViewModel({
    account: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchPublicAccount: reexecuteQuery,
  };
};

export default useGetPublicAccount;
