import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AccountContext } from '../../../../context';
import useCreateAccountPreferredSpecialties from '../actions/useCreateAccountPreferredSpecialties';
import OnboardingStepper from './OnboardingStepper';
import SpecialtiesOnboardingProvider from './context/SpecialtiesOnboardingProvider';
import { SPECIALTIES_ONBOARDING_SCHEMA } from './schema';
import { SpecialtiesOnboardingFormFields } from './types';

type OnboardingContainerProps = {
  setShowOnboarding: (state: boolean) => void;
};

const OnboardingContainer = ({
  setShowOnboarding,
}: OnboardingContainerProps) => {
  const {
    createAccountPreferredSpecialties,
    viewModel: { isLoading },
  } = useCreateAccountPreferredSpecialties();

  const { account, refreshAccount } = useContext(AccountContext);

  const { getValues, handleSubmit, register, setValue, watch } =
    useForm<SpecialtiesOnboardingFormFields>({
      mode: 'onBlur',
      resolver: yupResolver(SPECIALTIES_ONBOARDING_SCHEMA),
      defaultValues: {
        industries: [],
        sectors: [],
        specialties: [],
      },
    });

  const onSubmit: SubmitHandler<SpecialtiesOnboardingFormFields> = useCallback(
    async ({ specialties }) => {
      if (!account?.id) {
        return;
      }

      const result = await createAccountPreferredSpecialties({
        input: {
          accountId: account.id,
          jobSpecialtyIds: specialties,
        },
      });

      if (
        result.data?.accountPreferredSpecialtiesCreate
          .accountPreferredSpecialties
      ) {
        refreshAccount();
        setShowOnboarding(false);
      }
    },
    [
      account?.id,
      createAccountPreferredSpecialties,
      refreshAccount,
      setShowOnboarding,
    ]
  );

  return (
    <SpecialtiesOnboardingProvider>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="TEST-onboarding-form"
      >
        <OnboardingStepper
          isCreating={isLoading}
          getValues={getValues}
          register={register}
          setValue={setValue}
          setShowOnboarding={setShowOnboarding}
          watch={watch}
        />
      </form>
    </SpecialtiesOnboardingProvider>
  );
};

export default OnboardingContainer;
