import { gql } from 'urql';

import { RecruitmentNegotiationRoomFragment } from '../fragment';

const RecruitmentNegotiationRoom = gql`
  ${RecruitmentNegotiationRoomFragment}

  query RecruitmentNegotiationRoom($input: RecruitmentNegotiationRoomInput!) {
    recruitmentNegotiationRoom(input: $input) {
      room {
        ...RecruitmentNegotiationRoomFragment
      }
    }
  }
`;

export default RecruitmentNegotiationRoom;
