import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Link } from '@application/components';
import { LinkFileName } from '@domain/graphql.types';

type OtherFileProps = {
  file: File | string;
  onDeleteOtherFiles: (file: string | File) => void;
  linkFileNames: (LinkFileName | undefined)[];
};

const OtherFile = ({
  file,
  onDeleteOtherFiles,
  linkFileNames,
}: OtherFileProps) => {
  const { t } = useTranslation('recruitment', { keyPrefix: 'offer' });

  const getFileName = useCallback(
    (f: string) => {
      const fileUrl = linkFileNames.find(
        (linkFile) => linkFile?.link === f
      )?.fileName;
      return fileUrl?.split('/').pop();
    },
    [linkFileNames]
  );

  return (
    <div className="flex border-1 gap-s-4 p-s-8 items-center rounded-md flex-wrap">
      {typeof file === 'string' ? (
        <Link to={file} newTab className="underline text-16 font-semibold">
          {getFileName(file)}
        </Link>
      ) : (
        <span>{file?.name}</span>
      )}
      <div className="top-s-0 h-full flex items-center">
        <IconButton
          ghost
          icon={<i className="ri-close-line text-24 font-normal" />}
          text={t('buttons.removeFile')}
          radius="round"
          size="small"
          onClick={() => onDeleteOtherFiles(file)}
        />
      </div>
    </div>
  );
};

export default OtherFile;
