import { useMemo } from 'react';

import { Badge } from '@application/components';
import { Modifier } from '@application/components/badge/Badge';
import { RecruitmentNegotiationPropertyState } from '@domain/graphql.types';

type StateBadgeProps = {
  value: string;
  state: RecruitmentNegotiationPropertyState;
};

const StateBadge = ({ value, state }: StateBadgeProps) => {
  const modifier = useMemo((): Modifier | undefined => {
    switch (state) {
      case 'ACCEPTED':
        return 'badge-success';
      case 'REJECTED':
        return 'badge-error';
      case 'MODIFIED':
        return 'badge-warning';
      default:
        return undefined;
    }
  }, [state]);

  return (
    <Badge
      className="rounded-md h-s-32"
      valueClassName="text-16"
      value={value}
      modifier={modifier}
    />
  );
};

export default StateBadge;
