import { useContext, useState } from 'react';

import { AccountContext } from '@application/context';
import { AccountTypeCode } from '@domain/graphql.types';

import AgencyServices from './AgencyServices';
import PreferredSpecialtiesList from './preferred-specialties/PeferredSpecialtiesList';
import OnboardingContainer from './specialties-onboarding/OnboardingContainer';

const JobOpportunitiesPage = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { account } = useContext(AccountContext);

  return showOnboarding ? (
    <OnboardingContainer setShowOnboarding={setShowOnboarding} />
  ) : (
    <>
      {account?.type === AccountTypeCode.Agency && (
        <>
          <AgencyServices /> <hr className="my-s-40" />
        </>
      )}
      <PreferredSpecialtiesList setShowOnboarding={setShowOnboarding} />
    </>
  );
};

export default JobOpportunitiesPage;
