import { useTranslation } from 'react-i18next';
import { components, DropdownIndicatorProps } from 'react-select';

import { cn } from '@utils/lib-utils';

import { OptionType } from '../types';

const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
  const { t } = useTranslation();

  const {
    selectProps: { menuIsOpen },
  } = props;

  const indicatorWrapperClassName = cn(
    'rotate-0 transition-transform duration-200',
    menuIsOpen && 'rotate-180'
  );

  return (
    <components.DropdownIndicator {...props}>
      <div
        className={indicatorWrapperClassName}
        aria-label={menuIsOpen ? t('button.close') : t('button.open')}
      >
        <i className="ri-arrow-down-s-line text-20" />
      </div>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
