import { gql } from '@urql/core';

const MutationRequestCreate = gql`
  mutation RequestCreate($requestCreateInput: RequestCreateInput!) {
    requestCreate(requestCreateInput: $requestCreateInput) {
      request {
        id
        status
      }
    }
  }
`;

export default MutationRequestCreate;
