import { gql } from 'urql';

const DescriptionsFragment = gql`
  fragment DescriptionsFragment on Descriptions {
    fr
    en
  }
`;

export default DescriptionsFragment;
