// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

import { TH_COL_CLASS_NAME } from '../constants';

const TableHeader = () => {
  const { t } = useTranslation('candidates');

  return (
    <thead>
      <tr>
        <th
          className={cn(
            'w-s-192 min-w-[12rem] max-w-[12rem]',
            TH_COL_CLASS_NAME
          )}
        >
          &nbsp;
        </th>
        <th className={cn(TH_COL_CLASS_NAME)}>
          {t('labels.underNegotiation')}
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
