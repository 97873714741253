import { useMemo } from 'react';

import { Accordion } from '@application/components/accordion';
import { AccordionItem } from '@application/components/accordion/accordion-item';
import { BadgeGroup } from '@application/components/badge';
import { cn } from '@utils/lib-utils';

import { Stack, Switcher } from '../container-layouts';
import { Space } from '../container-layouts/types';

type TextDescription = {
  text: string;
  description?: string;
  badges?: string[];
};

export type SummaryListProps = {
  data: TextDescription[];
  density?: 'default' | 'comfortable' | 'compact';
  className?: string;
  isCollapsable?: boolean;
  space?: Space;
};

const LIMIT = {
  default: 1,
  comfortable: 2,
  compact: 3,
} as { [key: string]: number };

const DescriptionView = ({
  description,
  badges,
}: {
  description?: string;
  badges?: string[];
}) => (
  <dd className="text-18 font-semibold text-primary">
    {badges ? <BadgeGroup elements={badges} variant="primary" /> : description}
  </dd>
);

const ListingView = ({
  text,
  description,
  badges,
  density,
}: TextDescription & { density?: 'default' | 'comfortable' | 'compact' }) => (
  <>
    <dt
      className={cn('text-16 font-normal text-neutral-secondary', {
        'pb-s-8': density !== 'compact',
      })}
    >
      {text}
    </dt>
    {density === 'compact' && <span>&nbsp;:&nbsp;</span>}
    <DescriptionView description={description} badges={badges} />
  </>
);

const AccordionView = ({
  index,
  text,
  description,
  badges,
}: TextDescription & { index: number }) => {
  const accordionClassName = cn(
    'border-b-0 border-l-0 border-r-0 rounded-none font-bold',
    {
      'border-t-0': index === 0,
    }
  );

  return (
    <Accordion>
      <AccordionItem summary={text} customClassName={accordionClassName}>
        <DescriptionView description={description} badges={badges} />
      </AccordionItem>
    </Accordion>
  );
};

const SummaryList = ({
  data = [],
  density = 'default',
  className: customClassName,
  isCollapsable = false,
  space = 24,
}: SummaryListProps) => {
  const array = useMemo(() => [...data], [data]);
  const list = useMemo(
    () =>
      data.reduce((acc) => {
        if (array.length < 1) {
          return acc;
        }
        const temp = array.splice(0, LIMIT[density]);
        acc.push(temp);
        return acc;
      }, [] as TextDescription[][]) || [],
    [array, data, density]
  );
  return (
    <dl className={customClassName}>
      <Stack space={space}>
        {list.map((rows, rowIndex) => (
          <Switcher
            key={`row-${rowIndex}`}
            space={space}
            limit={LIMIT[density] as any}
            threshold="md"
          >
            {rows.map(({ text, description, badges }, index) => (
              <div
                key={`${text}-${index}`}
                className={cn('', {
                  'flex items-center': density === 'compact',
                  'lg:justify-end': index === 2 && data.length === 3,
                })}
              >
                {isCollapsable ? (
                  <AccordionView
                    text={text}
                    description={description}
                    badges={badges}
                    index={rowIndex}
                  />
                ) : (
                  <ListingView
                    density={density}
                    text={text}
                    description={description}
                    badges={badges}
                  />
                )}
              </div>
            ))}
          </Switcher>
        ))}
      </Stack>
    </dl>
  );
};

export default SummaryList;
