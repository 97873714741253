import { gql } from 'urql';

const AccountUpdateLogo = gql`
  mutation AccountUpdateLogo(
    $input: AccountUpdateLogoInput!
    $files: [Upload!]!
  ) {
    accountUpdateLogo(input: $input, files: $files) {
      ok
    }
  }
`;

export default AccountUpdateLogo;
