import { useTranslation } from 'react-i18next';

import { CandidateRequest } from '@domain/graphql.types';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type CandidateRequestTableProps = {
  candidateRequest: CandidateRequest;
  className?: string;
};

const CandidateRequestTable = ({
  candidateRequest,
  className: customClassName = '',
}: CandidateRequestTableProps) => {
  const { t } = useTranslation('candidates');

  return (
    <table className={customClassName}>
      <caption className="sr-only">
        {t('labels.initialRequestProposition')}
      </caption>
      <TableHeader name={candidateRequest?.account?.name} />
      <TableBody candidateRequest={candidateRequest} />
    </table>
  );
};

export default CandidateRequestTable;
