import { useCallback, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { validate as isUUID } from 'uuid';

import {
  ConfirmationModal,
  PageHeader,
  PageLoader,
  RankBadge,
  Stack,
} from '@application/components';
import { Button } from '@application/components/buttons';
import { ButtonLink, Link } from '@application/components/links';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { OfferStatusTypeCode } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { OfferCandidatesList } from '../offer/candidate/list';
import { useRejectOffers } from '../offer/comparison/hooks';
import useAddRoomsToRecruitmentNegotiation from '../offer/comparison/hooks/useAddRoomsToRecruitmentNegotiation';
import useCreateRecruitmentNegotiation from '../offer/comparison/hooks/useCreateRecruitmentNegotiation';
import { useGetOffer } from '../offer/hooks';
import { useGetRequest } from './hooks';
import { ConditionsSummary } from './offer-summary';
import SalesPitch from './offer-summary/salesPitch/SalesPitch';

const OfferDetailsPage = () => {
  const { t, i18n } = useTranslation('requests');

  const { id = '', offerId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { setModal } = useContext(ModalContext);

  useEffect(() => {
    if (!(id && isUUID(id)) || !(offerId && isUUID(offerId))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, offerId, navigate]);

  const {
    viewModel: { data, isLoading },
    refetchOffer,
  } = useGetOffer(offerId);

  const {
    viewModel: { data: request },
    refetchRequest,
  } = useGetRequest(id);

  const { createRecruitmentNegotiation } = useCreateRecruitmentNegotiation();

  const { addRoomsToRecruitmentNegotiation } =
    useAddRoomsToRecruitmentNegotiation();

  const { rejectOffers } = useRejectOffers();

  const confirmInviteNegoRoom = useCallback(async () => {
    if (data && request) {
      await createRecruitmentNegotiation({
        input: {
          offerIds: [data?.id],
          requestId: request && request.id,
        },
      });
      refetchOffer();
      refetchRequest();
    }
  }, [
    createRecruitmentNegotiation,
    data,
    refetchOffer,
    refetchRequest,
    request,
  ]);

  const confirmInviteExistingNegoRoom = useCallback(async () => {
    // Should only be called when there is already an existing negotiation and we only select offers which are not in the negotiation yet.
    if (request && request.recruitmentNegotiation?.id && data) {
      await addRoomsToRecruitmentNegotiation({
        input: {
          offerIds: [data?.id],
          recruitmentNegotiationId: request.recruitmentNegotiation?.id,
        },
      });
      refetchOffer();
      refetchRequest();
    }
  }, [
    addRoomsToRecruitmentNegotiation,
    data,
    refetchOffer,
    refetchRequest,
    request,
  ]);

  const handleClickInviteNegoRoom = useCallback(() => {
    if (!request?.recruitmentNegotiation) {
      setModal({
        title: t('modal.titles.confirmInviteNegoRoomTitle'),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    t={t}
                    i18nKey="modal.contents.confirmInviteNegoRoomContent"
                    values={{
                      specialty: getLocalizedDescription(
                        request?.jobSpecialty?.descriptions,
                        i18n.language
                      ),
                    }}
                  />
                </p>
                <p>
                  {t('modal.contents.confirmInviteNegoRoomContentAdditional')}
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              confirmInviteNegoRoom();
              setModal(null);
            }}
          />
        ),
      });
    } else {
      setModal({
        title: t('modal.titles.confirmInviteExistingNegoRoomTitle'),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    t={t}
                    i18nKey="modal.contents.confirmInviteExistingNegoRoomContent"
                    values={{
                      specialty: getLocalizedDescription(
                        request?.jobSpecialty?.descriptions,
                        i18n.language
                      ),
                    }}
                  />
                </p>
                <p>
                  {t('modal.contents.confirmInviteNegoRoomContentAdditional')}
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              confirmInviteExistingNegoRoom();
              setModal(null);
            }}
          />
        ),
      });
    }
  }, [
    confirmInviteExistingNegoRoom,
    confirmInviteNegoRoom,
    i18n.language,
    request?.jobSpecialty?.descriptions,
    request?.recruitmentNegotiation,
    setModal,
    t,
  ]);

  const handleRejectOffer = useCallback(async () => {
    setModal(null);

    const result = await rejectOffers({ input: { ids: [offerId] } });

    if (result.data?.offersReject.offers) {
      refetchOffer();
    }
  }, [setModal, rejectOffers, offerId, refetchOffer]);

  const confirmOfferRejection = useCallback(() => {
    setModal({
      title: t('modal.titles.rejectOffer'),
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p className="whitespace-pre-line">
                <Trans
                  i18nKey="modal.contents.confirmRejectOffer"
                  t={t}
                  values={{
                    name: data?.account.name,
                  }}
                />
              </p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={handleRejectOffer}
        />
      ),
    });
  }, [setModal, t, data?.account.name, handleRejectOffer]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={
          data?.accountId ? (
            <span className="h3">
              <span>{t('title.offerDetailsWithoutName')}&nbsp;</span>
              <Link
                className="text-24"
                to={generatePath(PrivatePage.ORGANIZATION_PUBLIC_PROFILE, {
                  id: data?.accountId,
                })}
              >
                {data?.account?.name}
              </Link>
            </span>
          ) : (
            <span className="h3">
              {t('title.offerDetails', { accountName: data?.account?.name })}
            </span>
          )
        }
        complement={
          <RankBadge
            rank={Number(data?.rank)}
            className="mr-s-16 min-w-[2rem] text-center"
          />
        }
        backTo={generatePath(PrivatePage.OFFER_COMPARISON, { id })}
      >
        <div className="flex gap-s-16">
          {data?.negotiationRoomId ? (
            <ButtonLink
              icon={<i className="ri-shake-hands-line" />}
              to={generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
                id: request?.recruitmentNegotiation?.id || '#',
              })}
              primary
              from={location.pathname}
            >
              {t('button.goToNegoRoom')}
            </ButtonLink>
          ) : (
            <Button
              primary
              icon={<i className="ri-shake-hands-line" />}
              onClick={handleClickInviteNegoRoom}
            >
              {t('button.negoRoomInvitation')}
            </Button>
          )}

          {/* If the offer has no nego room AND the offer is not rejected yet -> Show the reject button */}
          {/* TODO: Showing the status here when the offer is declined would be great instead of nothing */}
          {!data?.negotiationRoomId &&
            data?.status !== OfferStatusTypeCode.Rejected && (
              <Button
                icon={<i className="ri-prohibited-line" />}
                onClick={confirmOfferRejection}
              >
                {t('button.reject')}
              </Button>
            )}
        </div>
      </PageHeader>

      <OfferCandidatesList
        offerId={offerId}
        noCandidatesExplaination={data?.noCandidatesExplaination || undefined}
      />

      <SalesPitch salesPitch={data?.salesPitch || undefined} />

      <ConditionsSummary data={data} request={request} />
    </>
  );
};

export default OfferDetailsPage;
