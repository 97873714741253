import { ChangeEvent, useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  Fieldset,
  FormInputWithSuffix,
  Radio,
  RadioGroup,
  RangeSlider,
} from '@application/components';
import {
  useGetJobPaymentFrequencies,
  useGetJobPaymentOtherTypes,
} from '@application/views/recruitment/request/hooks';
import {
  CandidateOfferBonus,
  CandidateOpportunity,
  CandidateRequestType,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { CandidateOfferFormFields } from '../../schema';

type PaymentFieldsProps = {
  candidateOpportunity?: CandidateOpportunity;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  register: UseFormRegister<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  trigger: UseFormTrigger<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const PaymentFields = ({
  candidateOpportunity,
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: PaymentFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { data: JOB_PAYMENT_FREQUENCIES = [] } = useGetJobPaymentFrequencies();
  const { data: JOB_PAYMENT_OTHER_TYPES = [] } = useGetJobPaymentOtherTypes();

  const JOB_PAYMENT_FREQUENCIES_OPTIONS = useMemo(() => {
    switch (candidateOpportunity?.type) {
      case CandidateRequestType.TemporaryPlacement:
      case CandidateRequestType.Outsourcing:
        return JOB_PAYMENT_FREQUENCIES.filter(
          (p) => p.value !== JobPaymentFrequencyCode.Yearly
        );
      default:
        return JOB_PAYMENT_FREQUENCIES;
    }
  }, [candidateOpportunity, JOB_PAYMENT_FREQUENCIES]);

  const paymentFrequencyCode = watch('paymentFrequencyCode');
  const hourlyRateMin = watch('hourlyRateMin');
  const hourlyRateMax = watch('hourlyRateMax');
  const annualSalaryMin = watch('annualSalaryMin');
  const annualSalaryMax = watch('annualSalaryMax');
  const otherPaymentTypeCode = watch('otherPaymentTypeCode');
  const candidateBonus = watch('candidateBonus');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateOfferFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <>
      <Controller
        name="paymentFrequencyCode"
        control={control}
        render={({ field: { name, value } }) => (
          <RadioGroup
            name={name}
            data={JOB_PAYMENT_FREQUENCIES_OPTIONS}
            legend={t('labels.remunerationType')}
            language={extractLanguage(i18n.language)}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setValue(name, event.target.value as JobPaymentFrequencyCode);
              trigger('remunerationAmount');
              trigger(name);
            }}
            legendSize="small"
            value={value || undefined}
            invalid={!!errors.paymentFrequencyCode}
            helperText={
              errors.paymentFrequencyCode?.message &&
              tGlobal(errors.paymentFrequencyCode?.message)
            }
          />
        )}
      />

      {paymentFrequencyCode === JobPaymentFrequencyCode.Yearly && (
        <RangeSlider
          minLabel={t('labels.minSalary')}
          maxLabel={t('labels.maxSalary')}
          minName="annualSalaryMin"
          maxName="annualSalaryMax"
          onChange={(name: string, value: number | string) =>
            setValue(name as keyof CandidateOfferFormFields, value)
          }
          minValue={annualSalaryMin || undefined}
          maxValue={annualSalaryMax || undefined}
          min={1}
          max={1000}
          suffix="k$"
        />
      )}

      {paymentFrequencyCode === JobPaymentFrequencyCode.Hourly && (
        <>
          <RangeSlider
            minLabel={t('labels.minHourlyRate')}
            maxLabel={t('labels.maxHourlyRate')}
            minName="hourlyRateMin"
            maxName="hourlyRateMax"
            onChange={(name: string, value: number | string) =>
              setValue(name as keyof CandidateOfferFormFields, value)
            }
            minValue={hourlyRateMin || undefined}
            maxValue={hourlyRateMax || undefined}
            min={0.01}
            max={999.99}
            step={0.01}
            suffix="$/h"
          />

          <FormInputWithSuffix
            label={t('labels.hourlyBonus')}
            suffix="$"
            type="number"
            minValue={1}
            maxValue={999.99}
            step={0.01}
            alignLeft
            invalid={!!errors.hourlyBonus}
            helperText={
              errors.hourlyBonus?.message &&
              tGlobal(errors.hourlyBonus?.message, {
                max: '999.99',
                min: '1',
              })
            }
            {...register('hourlyBonus')}
          />
        </>
      )}

      {paymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount && (
        <FormInputWithSuffix
          type="number"
          step={0.01}
          minValue={1}
          maxValue={99999999.99}
          label={t('labels.compensationAmount')}
          suffix="$"
          invalid={!!errors.remunerationAmount}
          helperText={
            errors.remunerationAmount?.message &&
            tGlobal(errors.remunerationAmount?.message, {
              max: '99,999,999.99',
              min: '1',
            })
          }
          {...register('remunerationAmount')}
        />
      )}

      {candidateOpportunity?.type === CandidateRequestType.Recruitment && (
        <Controller
          name="otherPaymentTypeCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_PAYMENT_OTHER_TYPES}
              legend={t('labels.otherCompensation')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobPaymentOtherTypesCode);
                trigger(name);
                trigger('otherPaymentAmount');
              }}
              legendSize="small"
              value={value as string}
            />
          )}
        />
      )}

      {!!otherPaymentTypeCode &&
        candidateOpportunity?.type === CandidateRequestType.Recruitment && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.otherCompensationAmount')}
            suffix="$"
            invalid={!!errors.otherPaymentAmount}
            helperText={
              errors.otherPaymentAmount?.message &&
              tGlobal(errors.otherPaymentAmount?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('otherPaymentAmount')}
          />
        )}

      {candidateOpportunity?.type === CandidateRequestType.Recruitment && (
        <Controller
          name="candidateBonus"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset legend={t('labels.candidateBonus')} legendSize="small">
              <Cluster space={16} as="ul">
                {Object.values(CandidateOfferBonus).map((code) => (
                  <li key={code}>
                    <Radio
                      name={name}
                      value={code}
                      label={t(
                        `enum.candidateOfferBonus.${code.toLowerCase()}`
                      )}
                      onChange={(e) => {
                        handleOnRadioChange(
                          e as ChangeEvent<HTMLInputElement>,
                          name
                        );
                        setValue('candidateBonusAmount', undefined);
                        trigger(name);
                      }}
                      checked={candidateBonus === code}
                      asButton
                    />
                  </li>
                ))}
              </Cluster>
            </Fieldset>
          )}
        />
      )}

      {!!candidateBonus &&
        candidateOpportunity?.type === CandidateRequestType.Recruitment && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.candidateBonusAmount')}
            suffix="$"
            invalid={!!errors.candidateBonusAmount}
            helperText={
              errors.candidateBonusAmount?.message &&
              tGlobal(errors.candidateBonusAmount?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('candidateBonusAmount')}
          />
        )}
    </>
  );
};

export default PaymentFields;
