import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@application/components';
import { BillingPortalButton } from '@application/views/billing';
import { AccountTypeCode } from '@domain/graphql.types';

export type NoAvailableSeatsModalProps = {
  onClose: () => void;
  accountType: AccountTypeCode;
};

const NoAvailableSeatsModal = ({
  onClose,
  accountType,
}: NoAvailableSeatsModalProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('organization', {
    keyPrefix: 'users.noAvailableSeatsModal',
  });

  return (
    <>
      <Modal.CloseBtn onClick={onClose} />
      {accountType === AccountTypeCode.Enterprise ? (
        <div> {t('enterpriseContent')}</div>
      ) : (
        <div> {t('agencyContent')}</div>
      )}

      <Modal.Action>
        <Button onClick={onClose}>{tGlobal('button.cancel')}</Button>
        {accountType === AccountTypeCode.Enterprise ? (
          <BillingPortalButton
            className="content-center"
            icon={<i className="ri-bank-card-line text-24" />}
            buttonType="primary"
          />
        ) : (
          <Button primary onClick={onClose}>
            {tGlobal('button.confirm')}
          </Button>
        )}
      </Modal.Action>
    </>
  );
};

export default NoAvailableSeatsModal;
