import { useMutation } from 'urql';

import { Mutation, MutationInvitationAcceptArgs } from '@domain/graphql.types';
import { InvitationAccept } from '@infrastructure/graphql/account-user';

const useInvitationAccept = () => {
  const [{ data, fetching, error }, acceptInvitation] = useMutation<
    Pick<Mutation, 'invitationAccept'>,
    MutationInvitationAcceptArgs
  >(InvitationAccept);

  return {
    acceptInvitation,
    data: data?.invitationAccept,
    fetching,
    error,
  };
};

export default useInvitationAccept;
