import { useEducationLevelsGet } from '@domain/education';

import { toViewModel } from '../view-models/get-education-levels.vm';

const useGetEducationLevels = () => {
  const { data = {}, fetching, error } = useEducationLevelsGet();

  return toViewModel({
    educationLevels: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetEducationLevels;
