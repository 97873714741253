// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, Switch } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import {
  Offer,
  OfferOutsourcingConditions,
  OfferStatusTypeCode,
  RecruitmentNegotiationOutsourcingPropertiesState,
  RecruitmentNegotiationPropertyState,
  RecruitmentNegotiationRoom,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';
import { isDefined } from '@utils/type-utils';

import { StateBadge, StateButtons, StatesMenu } from '../../../components';
import { RecruitmentNegotiationContext } from '../../../context';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

const conditionsKeys: [
  keyof OfferOutsourcingConditions,
  keyof Omit<RecruitmentNegotiationOutsourcingPropertiesState, '__typename'>,
][] = [
  ['maximumDuration', 'maximumDuration'],
  ['exclusivityPeriod', 'exclusivityPeriod'],
  ['maximumBudget', 'maximumBudget'],
];

const OutsourcingConditions = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const {
    rooms,
    checkedRooms,
    onAcceptValues,
    onRejectValues,
    getModifiedProperties,
    shouldDisplayMenu,
  } = useContext(RecruitmentNegotiationContext);

  const getCellContent = useCallback(
    (condition: keyof OfferOutsourcingConditions, offer: Offer) => {
      const cellValue = offer.outsourcingContractualConditions?.[condition];

      switch (condition) {
        case 'maximumDuration':
        case 'exclusivityPeriod':
          return tGlobal('suffix.week', { count: cellValue as number });
        case 'maximumBudget':
          return tGlobal('suffix.currencyPerHourValue', { value: cellValue });
        default:
          return '-';
      }
    },
    [tGlobal]
  );

  const renderStatesMenu = useCallback(
    (
      id: string,
      property: keyof RecruitmentNegotiationOutsourcingPropertiesState,
      state: RecruitmentNegotiationPropertyState,
      content: string
    ) => (
      <StatesMenu
        onAccept={onAcceptValues(id, 'outsourcingPropertiesState', [property])}
        onReject={onRejectValues(id, 'outsourcingPropertiesState', [property])}
      >
        <Switch fallback={<span>{content}</span>}>
          <Switch.Match
            when={state === RecruitmentNegotiationPropertyState.Modified}
          >
            <StateBadge value={content} state={state} />
          </Switch.Match>
        </Switch>
      </StatesMenu>
    ),
    [onAcceptValues, onRejectValues]
  );

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('outsourcingConditions')}
        </th>

        {rooms?.map(
          ({ id, outsourcingPropertiesState }: RecruitmentNegotiationRoom) => (
            <td
              key={`offerOutsourcingConditions-${id}`}
              className={cn(
                TH_ROW_CLASS_NAME.highlight,
                'border-l-1 border-b-1',
                {
                  '!border-x-main border-x-2': checkedRooms.includes(id),
                }
              )}
            >
              <Switch fallback={<>&nbsp;</>}>
                <Switch.Match
                  when={conditionsKeys.some(
                    ([, state]) =>
                      outsourcingPropertiesState[state] ===
                      RecruitmentNegotiationPropertyState.Modified
                  )}
                >
                  <StateButtons
                    onAccept={onAcceptValues(
                      id,
                      'outsourcingPropertiesState',
                      getModifiedProperties(
                        outsourcingPropertiesState
                      ) as (keyof RecruitmentNegotiationOutsourcingPropertiesState)[]
                    )}
                    onReject={onRejectValues(
                      id,
                      'outsourcingPropertiesState',
                      getModifiedProperties(
                        outsourcingPropertiesState
                      ) as (keyof RecruitmentNegotiationOutsourcingPropertiesState)[]
                    )}
                  />
                </Switch.Match>
              </Switch>
            </td>
          )
        )}
      </tr>

      {conditionsKeys.map(([condition, property], index) => (
        <tr
          key={`offerOutsourcingConditions-${condition}`}
          className="[&:last-child>th]:rounded-bl-md"
        >
          <th scope="col" className={TH_ROW_CLASS_NAME.base}>
            <TooltipEllipsis toolTip={t(condition)} lineClamp={2}>
              <div>{t(condition)}</div>
            </TooltipEllipsis>
          </th>

          {rooms?.map(
            ({
              id,
              negotiatingOffer: offer,
              status,
              outsourcingPropertiesState,
            }: RecruitmentNegotiationRoom) => (
              <td
                key={`offerOutsourcingConditions-${condition}-${id}`}
                className={cn(TD_CLASS_NAME.base, {
                  'border-x-2 !border-x-main': checkedRooms.includes(id),
                  'border-b-0': index === conditionsKeys.length - 1,
                  'border-b-2 border-b-main [&:last-child]:rounded-br-md':
                    checkedRooms.includes(id) &&
                    index === conditionsKeys.length - 1,
                  'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
                  'p-s-0':
                    isDefined(
                      offer.outsourcingContractualConditions?.[condition]
                    ) &&
                    shouldDisplayMenu(
                      status,
                      outsourcingPropertiesState[property]
                    ),
                })}
              >
                <PlaceholderSwitcher
                  value={offer.outsourcingContractualConditions?.[condition]}
                >
                  <Switch
                    fallback={
                      <StateBadge
                        value={getCellContent(condition, offer)}
                        state={outsourcingPropertiesState[property]}
                      />
                    }
                  >
                    <Switch.Match
                      when={shouldDisplayMenu(
                        status,
                        outsourcingPropertiesState[property]
                      )}
                    >
                      {renderStatesMenu(
                        id,
                        property,
                        outsourcingPropertiesState[property],
                        getCellContent(condition, offer)
                      )}
                    </Switch.Match>
                  </Switch>
                </PlaceholderSwitcher>
              </td>
            )
          )}
        </tr>
      ))}
    </>
  );
};

export default OutsourcingConditions;
