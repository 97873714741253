import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import { CandidateRequestListFragment } from './fragment';

const CandidateRequests = gql`
  ${CandidateRequestListFragment}

  query CandidateRequests(
    $filterBy: CandidateRequestsFilters,
    $sortBy: [CandidateRequestsSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    candidateRequests(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['CandidateRequestListFragment'])}
  }
`;

export default CandidateRequests;
