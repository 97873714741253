import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconBadge, Tooltip } from '@application/components';
import { RequestTypeCode } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type RequestTypeBadgesProps = {
  types: RequestTypeCode[] | undefined;
};

const RequestTypeBadges = ({ types }: RequestTypeBadgesProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'enum.requestTypeCode',
  });

  const badgeData = useMemo(
    () => ({
      typeColors: {
        [RequestTypeCode.Headhunter]: 'light-blue',
        [RequestTypeCode.InternationalRecruitment]: 'light-green',
        [RequestTypeCode.TemporaryHelp]: 'light-yellow',
        [RequestTypeCode.Outsourcing]: 'light-pink',
      },
      icon: {
        [RequestTypeCode.Headhunter]: 'customer-service',
        [RequestTypeCode.InternationalRecruitment]: 'global',
        [RequestTypeCode.TemporaryHelp]: 'timer',
        [RequestTypeCode.Outsourcing]: 'profile',
      },
    }),
    []
  );

  return types?.length ? (
    <div className="flex">
      {types.map((type) => (
        <Tooltip key={type} message={t(type.toLowerCase())} position="top">
          <IconBadge
            className={cn({ '-ml-s-8 z-0': types[0] !== type })}
            color={badgeData.typeColors[type]}
            icon={badgeData.icon[type]}
          />
        </Tooltip>
      ))}
    </div>
  ) : null;
};

export default RequestTypeBadges;
