import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from '@application/components';
import { AlertContext, ModalContext } from '@application/context';
import { useAccountUserUpdateRole } from '@domain/account-user';
import { UserRole } from '@domain/graphql.types';

import { toViewModel } from '../view-models/update-account-user-role.vm';

const useUpdateAccountUserRole = () => {
  const {
    data,
    fetching,
    error,
    updateAccountUserRole: executeUpdateAccountUserRole,
  } = useAccountUserUpdateRole();
  const { setModal } = useContext(ModalContext);

  const { t } = useTranslation('organization', {
    keyPrefix: 'users.list',
  });

  const updateAccountUserRole = useCallback(
    async (
      accountUserId: string,
      newRole: UserRole,
      oldOwner?: string,
      newOwner?: string
    ) => {
      if (newRole !== UserRole.Owner) {
        executeUpdateAccountUserRole({
          input: { accountUserId, newRole },
        });
      } else {
        setModal({
          title: t('actions.changeOwnerTitle'),
          content: (
            <ConfirmationModal
              content={t('actions.changeOwnerMessage', {
                oldOwner,
                newOwner,
              })}
              onCancel={() => setModal(null)}
              onConfirm={() => {
                executeUpdateAccountUserRole({
                  input: { accountUserId, newRole },
                });
                setModal(null);
              }}
            />
          ),
        });
      }
    },
    [setModal, t, executeUpdateAccountUserRole]
  );

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    accountUserUpdateRole: {
      data: data?.accountUserUpdateRole,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('actions.updateMemberError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.accountUserUpdateRole.accountUser) {
      setAlertContent({
        action: t('actions.updateMemberSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    viewModel,
    updateAccountUserRole,
  };
};

export default useUpdateAccountUserRole;
