import { TFunction } from 'i18next';
import { Fragment } from 'react';

import { IconButton } from '@application/components/buttons';
import type { StepProps } from '@application/hooks/useWizard';
import { cn } from '@utils/lib-utils';

type StepperProps = {
  steps: StepProps[];
  activeStep: number;
  completedSteps: boolean[];
  setActive: (stepIndex: number) => void;
  t: TFunction<'translation', undefined>;
};

const Stepper = ({
  steps,
  activeStep,
  completedSteps,
  setActive,
  t,
}: StepperProps) => {
  const handleOnClick = (index: number) => {
    setActive(index);
  };

  return (
    <ol className="flex w-full h-full mb-s-32">
      {steps.map((step, index) => (
        <Fragment key={index}>
          <li className="flex flex-col items-center w-s-112">
            <IconButton
              radius="square"
              outline={!completedSteps[index] && index !== activeStep}
              primary={completedSteps[index] || index === activeStep}
              className={cn('text-disabled-content mb-s-8 relative', {
                'bg-base-100': !completedSteps[index] && index !== activeStep,
                'pointer-events-none': index === activeStep,
              })}
              icon={
                <>
                  <i
                    className={cn(`${step.icon}`, {
                      'text-primary':
                        completedSteps[index] || index === activeStep,
                    })}
                  />

                  {completedSteps[index] && (
                    <i className="ri-check-line absolute top-[-6px] right-[-6px] flex items-center justify-center bg-content-action-light rounded-full w-s-20 h-s-20" />
                  )}
                </>
              }
              onClick={() => handleOnClick(index)}
              text={t(step.title)}
            />

            <span
              className={cn('text-center text-14 whitespace-pre-line', {
                'text-primary font-semibold':
                  index === activeStep || completedSteps[index],
              })}
            >
              {t(step.title)}
            </span>
          </li>

          {index < steps.length - 1 && (
            <div
              className={cn(
                'border-t border-gray-300 flex-grow flex-1 mt-s-24',
                { 'border-primary': completedSteps[index] }
              )}
            />
          )}
        </Fragment>
      ))}
    </ol>
  );
};

export default Stepper;
