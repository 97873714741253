import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import {
  Link,
  MatchingScore,
  PlaceholderSwitcher,
  RankBadge,
} from '@application/components';
import { PrivatePage } from '@application/enums/pagesUrl';
import { CandidateOffer, CandidateRequest } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_CLASS_NAME } from '../constants';
import { EmployerBrand, RecruiterOffer } from './rows';

type TableBodyProps = {
  candidateRequest: CandidateRequest;
  candidateOffers: readonly CandidateOffer[];
  checkedCandidateOffers: string[];
};

const TableBody = ({
  candidateRequest,
  candidateOffers,
  checkedCandidateOffers,
}: TableBodyProps) => {
  const { t } = useTranslation('candidates');

  const getTdClassName = useCallback(
    (id: string, bg?: string) =>
      cn(TD_CLASS_NAME, bg, {
        'border-x-2 !border-x-main': checkedCandidateOffers.includes(id),
      }),
    [checkedCandidateOffers]
  );

  return (
    <tbody className="text-neutral-secondary">
      <tr>
        <th className={cn(TH_CLASS_NAME, 'pt-s-12')}>
          {t('labels.matchingScore')}
        </th>

        {candidateOffers.map((offer) => (
          <td
            aria-label={t('labels.matchingScore')}
            key={offer.id}
            className={getTdClassName(offer.id)}
          >
            <MatchingScore value={Number(offer.matchingScore)} />
          </td>
        ))}
      </tr>

      <tr>
        <th className={TH_CLASS_NAME}>{t('labels.rank')}</th>

        {candidateOffers.map((offer) => (
          <td
            aria-label={t('labels.rank')}
            key={offer.id}
            className={getTdClassName(offer.id)}
          >
            {offer.rank && <RankBadge rank={offer.rank} />}
          </td>
        ))}
      </tr>

      <tr>
        <th className={TH_CLASS_NAME}>{t('labels.organizationProfile')}</th>
        {candidateOffers.map((offer) => (
          <td
            aria-label={t('labels.organizationProfile')}
            key={offer.id}
            className={getTdClassName(offer.id)}
          >
            <PlaceholderSwitcher value={offer.linkProfile}>
              <Link
                className="text-14"
                to={generatePath(PrivatePage.ORGANIZATION_PUBLIC_PROFILE, {
                  id: offer.accountId,
                })}
              >
                {t('button.viewProfile')}
              </Link>
            </PlaceholderSwitcher>
          </td>
        ))}
      </tr>

      <tr>
        <th className={cn(TH_CLASS_NAME, 'bg-neutral')}>
          <span className="text-secondary-content">
            {t('labels.employerBrand')}
          </span>
        </th>

        {candidateOffers.map((offer) => (
          <td
            aria-label={t('labels.employerBrand')}
            key={offer.id}
            className={getTdClassName(offer.id, 'bg-neutral')}
          />
        ))}
      </tr>

      <EmployerBrand
        candidateOffers={candidateOffers}
        checkedCandidateOffers={checkedCandidateOffers}
        candidateRequestType={candidateRequest.type || undefined}
      />

      <RecruiterOffer
        candidateOffers={candidateOffers}
        checkedCandidateOffers={checkedCandidateOffers}
        candidateRequestType={candidateRequest.type || undefined}
      />

      <tr>
        <th className={cn(TH_CLASS_NAME, 'font-semibold', {})}>
          {t('labels.openedRoom')}
        </th>

        {candidateOffers.map((offer, index) => (
          <td
            aria-label={t('labels.openedRoom')}
            key={offer.id}
            className={cn(getTdClassName(offer.id, offer.status), {
              'border-b-2 !border-b-main': checkedCandidateOffers.includes(
                offer.id
              ),
              '[&:last-child]:rounded-br-md':
                checkedCandidateOffers.includes(offer.id) &&
                index === candidateOffers.length - 1,
            })}
          >
            <PlaceholderSwitcher value={offer.negotiationRoomId}>
              <i className="ri-checkbox-circle-fill text-18" />
            </PlaceholderSwitcher>
          </td>
        ))}
      </tr>
    </tbody>
  );
};

export default TableBody;
