import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountPreferredSpecialtyDelete } from '@domain/account';

import { toViewModel } from './delete-account-preferred-specialty.vm';

const useDeleteAccountPreferredSpecialty = () => {
  const { data, fetching, error, deleteAccountPreferredSpecialty } =
    useAccountPreferredSpecialtyDelete();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities.messages',
  });

  const viewModel = toViewModel({
    accountPreferredSpecialtyDelete: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('deleteAccountPreferredSpecialtyError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.accountPreferredSpecialtyDelete?.ok) {
      setAlertContent({
        action: t('deleteAccountPreferredSpecialtySuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.accountPreferredSpecialtyDelete?.ok]);

  return {
    viewModel,
    deleteAccountPreferredSpecialty,
  };
};

export default useDeleteAccountPreferredSpecialty;
