import { useMutation } from 'urql';

import {
  Mutation,
  MutationAccountOperationTerritoriesCreateArgs,
} from '@domain/graphql.types';
import { AccountOperationTerritoriesCreate } from '@infrastructure/graphql/account';

const useAccountOperationTerritoriesCreate = () => {
  const [{ data, fetching, error }, createAccountOperationTerritories] =
    useMutation<
      Pick<Mutation, 'accountOperationTerritoriesCreate'>,
      MutationAccountOperationTerritoriesCreateArgs
    >(AccountOperationTerritoriesCreate);

  return {
    createAccountOperationTerritories,
    data,
    fetching,
    error,
  };
};

export default useAccountOperationTerritoriesCreate;
