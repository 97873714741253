import { useMemo } from 'react';
import { generatePath } from 'react-router';

import { PrivatePage } from '@application/enums/pagesUrl';
import {
  useLocalizedOperationTerritories,
  useLocalizedSpecialty,
} from '@application/hooks/localization';
import { CandidateNegotiationRoom } from '@domain/graphql.types';
import { calculateTimeSince } from '@utils/date-utils';

const useRoomListCard = (data: CandidateNegotiationRoom) => {
  const timeSinceLastActivity = useMemo(
    () => calculateTimeSince(data?.updatedAt, 'minute'),
    [data?.updatedAt]
  );

  const operationTerritories = useLocalizedOperationTerritories(
    data?.candidateRequest?.operationTerritories
  );

  const pathToRoom = useMemo(
    () =>
      generatePath(PrivatePage.CANDIDATE_NEGOTIATION_ROOM_DETAILS, {
        id: data.id,
      }),
    [data.id]
  );

  const specialty = useLocalizedSpecialty(data?.candidateRequest.jobSpecialty);

  return {
    operationTerritories,
    pathToRoom,
    specialty,
    timeSinceLastActivity,
  };
};

export default useRoomListCard;
