import React, { ForwardedRef, useMemo } from 'react';

import { Badge } from '@application/components';
import { Modifier } from '@application/components/badge/Badge';
import { CandidateNegotiationPropertyState } from '@domain/graphql.types';

type StateBadgeProps = {
  value?: string;
  state: CandidateNegotiationPropertyState;
};

type Ref = HTMLDivElement;

const StateBadge = React.forwardRef<Ref, StateBadgeProps>(
  ({ value, state }: StateBadgeProps, ref: ForwardedRef<Ref>) => {
    const modifier = useMemo((): Modifier | undefined => {
      switch (state) {
        case 'ACCEPTED':
          return 'badge-success';
        case 'REJECTED':
          return 'badge-error';
        case 'MODIFIED':
          return 'badge-warning';
        default:
          return undefined;
      }
    }, [state]);

    return (
      <Badge
        ref={ref}
        className="rounded-md h-s-32"
        valueClassName="text-16"
        value={value}
        modifier={modifier}
      />
    );
  }
);

export default StateBadge;
