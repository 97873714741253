import { gql } from '@urql/core';

const MutationCandidateOfferDelete = gql`
  mutation CandidateOfferDelete($input: DeleteCandidateOfferInput!) {
    candidateOfferDelete(input: $input) {
      ok
    }
  }
`;

export default MutationCandidateOfferDelete;
