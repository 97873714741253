import { ElementType, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

type SectionContainerProps = {
  as?: ElementType;
  titleAs?: ElementType;
  title: ReactNode;
  description?: string;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  actionControls?: ReactNode;
};

const SectionContainer = ({
  as: ContainerTag = 'section',
  title,
  titleAs: TitleTag = 'p',
  description,
  children,
  actionControls,
  className: customClassName,
  contentClassName,
}: SectionContainerProps) => {
  const className = cn('', customClassName);

  return (
    <ContainerTag className={className}>
      <TitleTag className="h4">{title}</TitleTag>

      {description && <p className="text-neutral-secondary">{description}</p>}

      {actionControls && <div>{actionControls}</div>}

      <div className={cn('mt-s-24', contentClassName)}>{children}</div>
    </ContainerTag>
  );
};

export default SectionContainer;
