import { useTranslation } from 'react-i18next';

import { Button, PlaceholderSwitcher, Switch } from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { CandidateNegotiationPropertyState } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import StateBadge from './StateBadge';

type RoomReadOnlyCellProps = {
  state: CandidateNegotiationPropertyState;
  value: any;
  displayedValue: string;
  showModifyButton?: boolean;
  onClick: () => void;
};

const RoomReadOnlyCell = ({
  state,
  value,
  displayedValue,
  showModifyButton = false,
  onClick,
}: RoomReadOnlyCellProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between h-full gap-s-8">
      <div className={cn('flex items-center rounded-md h-full w-fit')}>
        <PlaceholderSwitcher value={value}>
          <Switch
            fallback={
              <TooltipEllipsis toolTip={displayedValue}>
                <div>{displayedValue}</div>
              </TooltipEllipsis>
            }
          >
            <Switch.Match
              when={state !== CandidateNegotiationPropertyState.Initial}
            >
              <TooltipEllipsis toolTip={displayedValue}>
                <StateBadge value={displayedValue} state={state} />
              </TooltipEllipsis>
            </Switch.Match>
          </Switch>
        </PlaceholderSwitcher>
      </div>

      <Button
        className={cn('hidden font-normal px-s-8 min-w-fit', {
          'group-hover:block': showModifyButton,
        })}
        icon={<i className="ri-pencil-line mr-s-8" />}
        size="x-small"
        onClick={onClick}
      >
        {t('button.edit')}
      </Button>
    </div>
  );
};

export default RoomReadOnlyCell;
