import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Fieldset, FormInputWithSuffix, Radio } from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import {
  RequestConditionPaymentFrequencyCode as PaymentFrequencyCode,
  RequestConditionPaymentMethodCode as PaymentMethodCode,
  RequestConditionStartOfAgreementPaymentMethodCode as StartOfAgreementCode,
} from '@domain/graphql.types';

import { CandidateRequestFormFields } from '../../../schema';

type PaymentMethodsFieldsProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  register: UseFormRegister<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  trigger: UseFormTrigger<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
};

const PaymentMethodsFields = ({
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: PaymentMethodsFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const paymentMethod = watch('recruitmentConditionPaymentMethodValue');
  const startOfAgreementValue = watch(
    'recruitmentConditionStartOfAgreementPaymentMethodValue'
  );
  const paymentFrequency = watch('recruitmentConditionPaymentFrequencyValue');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateRequestFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <Fieldset legend={t('labels.preferredPaymentMethod')} legendSize="medium">
      <Controller
        name="recruitmentConditionPaymentMethodValue"
        control={control}
        render={({ field: { name } }) => (
          <Cluster space={16} as="ul">
            {Object.values(PaymentMethodCode).map((method) => (
              <li key={method}>
                <Radio
                  name={name}
                  value={method}
                  label={t(
                    `enum.requestConditionPaymentMethodCode.${method.toLowerCase()}`
                  )}
                  onChange={(e) => {
                    handleOnRadioChange(
                      e as ChangeEvent<HTMLInputElement>,
                      name
                    );
                    trigger(name);
                  }}
                  checked={paymentMethod === method}
                  asButton
                />
              </li>
            ))}
          </Cluster>
        )}
      />

      {paymentMethod === PaymentMethodCode.StartOfAgreement && (
        <Controller
          name="recruitmentConditionStartOfAgreementPaymentMethodValue"
          control={control}
          render={({ field: { name } }) => (
            <Cluster space={16} as="ul" className="mt-s-16">
              {Object.values(StartOfAgreementCode).map((method) => (
                <li key={method}>
                  <Radio
                    name={name}
                    value={method}
                    label={t(
                      `enum.requestConditionStartOfAgreementPaymentMethodCode.${method.toLowerCase()}`
                    )}
                    onChange={(e) => {
                      handleOnRadioChange(
                        e as ChangeEvent<HTMLInputElement>,
                        name
                      );
                      trigger(name);
                    }}
                    checked={startOfAgreementValue === method}
                    asButton
                  />
                </li>
              ))}
            </Cluster>
          )}
        />
      )}

      {startOfAgreementValue === StartOfAgreementCode.AmountWanted && (
        <FormInputWithSuffix
          type="number"
          label={t('labels.amountWanted')}
          minValue={1}
          invalid={!!errors.recruitmentConditionPaymentAmountValue}
          helperText={
            errors.recruitmentConditionPaymentAmountValue?.message &&
            tGlobal(errors.recruitmentConditionPaymentAmountValue?.message, {
              min: '1',
            })
          }
          {...register('recruitmentConditionPaymentAmountValue')}
        />
      )}

      {startOfAgreementValue === StartOfAgreementCode.Deposit && (
        <FormInputWithSuffix
          type="number"
          minValue={1}
          maxValue={99999999.99}
          step={0.01}
          invalid={!!errors.recruitmentConditionDepositValue}
          helperText={
            errors.recruitmentConditionDepositValue?.message &&
            tGlobal(errors.recruitmentConditionDepositValue?.message, {
              max: '99,999,999.99',
              min: '1',
            })
          }
          label={t('labels.maxDepositAmount')}
          {...register('recruitmentConditionDepositValue')}
        />
      )}

      {paymentMethod === PaymentMethodCode.StartOfAgreement &&
        startOfAgreementValue === StartOfAgreementCode.PaymentFrequency && (
          <Controller
            name="recruitmentConditionPaymentFrequencyValue"
            control={control}
            render={({ field: { name } }) => (
              <Cluster space={16} as="ul" className="mt-s-16">
                {Object.values(PaymentFrequencyCode).map((method) => (
                  <li key={method}>
                    <Radio
                      name={name}
                      value={method}
                      label={t(
                        `enum.requestConditionPaymentFrequencyCode.${method.toLowerCase()}`
                      )}
                      asButton
                      onChange={(e) => {
                        handleOnRadioChange(
                          e as ChangeEvent<HTMLInputElement>,
                          name
                        );
                        trigger(name);
                      }}
                      checked={paymentFrequency === method}
                    />
                  </li>
                ))}
              </Cluster>
            )}
          />
        )}
    </Fieldset>
  );
};

export default PaymentMethodsFields;
