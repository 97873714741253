import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  SelectField,
  TextAreaField,
  TextInputField,
} from '@application/components';
import { Button } from '@application/components/buttons';
import { Stack } from '@application/components/container-layouts';
import { AddressAutocomplete } from '@application/components/form/address-autocomplete';
import { SwitchField } from '@application/components/form/switch-field';
import { Modal } from '@application/components/modal';
import { OptionType } from '@application/components/select-field/select';
import { AccountContext, AlertContext } from '@application/context';
import { useSpecialties } from '@application/hooks';
import { AccountIndustryType } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { normalizeCreateInputData } from '../normalizers';
import { OPERATION_UNIT_SCHEMA } from '../schema';
import { OperationUnitFormFields } from '../types';
import { OPERATION_UNIT_DEFAULT_VALUES } from './constants';
import useCreateOperationUnit from './useCreateOperationUnit';

export type CreateOperationUnitModalProps = {
  onClose: () => void;
  afterSubmit: (operationUnitId: string) => void;
};

const CreateOperationUnitModal = ({
  onClose,
  afterSubmit,
}: CreateOperationUnitModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('operationUnit', {
    keyPrefix: 'operationUnitModal',
  });
  const { refreshAccount } = useContext(AccountContext);

  const { setAlertContent } = useContext(AlertContext);

  const {
    createOperationUnit,
    viewModel: { isLoading },
  } = useCreateOperationUnit();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<OperationUnitFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(OPERATION_UNIT_SCHEMA),
    defaultValues: OPERATION_UNIT_DEFAULT_VALUES,
  });

  const onSubmit: SubmitHandler<OperationUnitFormFields> = useCallback(
    async (data) => {
      const result = await createOperationUnit({
        operationUnitCreateInput: normalizeCreateInputData(data),
      });
      // Ideally we would put these operations in the useCreateOperationUnit.tsx, but the modal as it is kills the ongoing processes when closed.
      if (result.data?.operationUnitCreate.operationUnit) {
        refreshAccount();
        setAlertContent({
          action: t('messages.success.create'),
          modifier: 'alert-success',
        });
        afterSubmit(result.data?.operationUnitCreate.operationUnit.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterSubmit, createOperationUnit]
  );

  const industryCode = watch('industryCode');
  const sectorCode = watch('sectorCode');

  const {
    industryTypesLoading,
    sectorsLoading,
    INDUSTRY_TYPE_OPTIONS,
    INDUSTRY_SECTORS_OPTIONS,
    onIndustryTypeChange,
    onIndustrySectorChange,
  } = useSpecialties({
    industryType: industryCode ?? undefined,
    industrySector: sectorCode ?? undefined,
    language: extractLanguage(i18n.language),
    setSector: (value: string | undefined) => setValue('sectorCode', value),
    setType: (value: AccountIndustryType | undefined) =>
      setValue('industryCode', value),
    useCodeAsValue: true,
  });

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div ref={modalRef} tabIndex={-1}>
        <Modal.CloseBtn onClick={onClose} />
        <Stack space={24}>
          <TextInputField
            label={t('operationUnitName')}
            invalid={!!errors.name}
            helperText={
              errors.name?.message &&
              tGlobal(errors.name?.message, {
                field: t('operationUnitName'),
              })
            }
            {...register('name')}
          />

          <div>
            <Controller
              name="address"
              control={control}
              render={({
                field: { onBlur, onChange, name, value },
                fieldState: { error },
              }) => (
                <AddressAutocomplete
                  className="flex-grow"
                  label={t('address')}
                  name={name}
                  value={value!}
                  invalid={!!error}
                  helperText={
                    error?.message &&
                    tGlobal(error?.message, {
                      field: t('address'),
                    })
                  }
                  onChange={(option: OptionType) => onChange(option?.label)}
                  onBlur={onBlur}
                />
              )}
            />

            <SwitchField
              className="mt-s-8"
              label={t('headOffice')}
              {...register('headOffice')}
            />
          </div>

          <div>
            <TextInputField
              label={t('phoneNumber')}
              type="tel"
              invalid={!!errors.phoneNumber}
              helperText={
                errors.phoneNumber?.message &&
                tGlobal(errors.phoneNumber?.message, {
                  field: t('phoneNumber'),
                })
              }
              {...register('phoneNumber')}
            />

            <SwitchField
              className="mt-s-8"
              label={t('phoneDisplay')}
              {...register('phoneDisplay')}
            />
          </div>

          <div className="flex gap-s-16">
            <Controller
              name="industryCode"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <SelectField
                  className="w-full"
                  label={t('industry')}
                  name={name}
                  options={INDUSTRY_TYPE_OPTIONS}
                  value={
                    INDUSTRY_TYPE_OPTIONS.find((o) => o.value === value) || null
                  }
                  onChange={onIndustryTypeChange(onChange, value)}
                  searchable
                  clearable
                  disabled={industryTypesLoading}
                  invalid={!!errors.industryCode}
                  helperText={
                    errors.industryCode?.message &&
                    tGlobal(errors.industryCode?.message)
                  }
                />
              )}
            />

            <Controller
              name="sectorCode"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <SelectField
                  className="w-full"
                  label={t('sector')}
                  name={name}
                  options={INDUSTRY_SECTORS_OPTIONS}
                  value={
                    INDUSTRY_SECTORS_OPTIONS.find((o) => o.value === value) ||
                    null
                  }
                  onChange={onIndustrySectorChange(onChange, value)}
                  searchable
                  clearable
                  disabled={sectorsLoading}
                  invalid={!!errors.sectorCode}
                  helperText={
                    errors.sectorCode?.message &&
                    tGlobal(errors.sectorCode?.message)
                  }
                />
              )}
            />
          </div>

          <Controller
            name="notes"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <TextAreaField
                name={name}
                value={value || ''}
                className="mt-s-0"
                label={t('notes')}
                rows={4}
                maxChar={1024}
                onChange={onChange}
                invalid={!!errors.notes}
                helperText={
                  errors.notes?.message &&
                  tGlobal(errors.notes?.message, { max: '1024' })
                }
              />
            )}
          />
        </Stack>
        <Modal.Action>
          <Button onClick={onClose} disabled={isLoading}>
            {tGlobal('button.cancel')}
          </Button>

          <Button primary type="submit" loading={isLoading}>
            {t('addOperationUnit')}
          </Button>
        </Modal.Action>
      </div>
    </form>
  );
};

export default CreateOperationUnitModal;
