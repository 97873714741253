import * as yup from 'yup';

import { SPECIALTIES_ONBOARDING_SCHEMA } from './schema';

export enum OnboardingStep {
  INDUSTRIES = 0,
  SECTORS = 1,
  SPECIALTIES = 2,
}

export type SpecialtiesOnboardingFormFields = yup.InferType<
  typeof SPECIALTIES_ONBOARDING_SCHEMA
>;
