import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { ModalContext } from '@application/context';

import {
  AccountOperationTerritoryList,
  AddAccountOperationTerritoryModal,
} from './components';

const AccountOperationTerritoryListPage = () => {
  const { t } = useTranslation('organization', {
    keyPrefix: 'accountOperationTerritories.list',
  });
  const { setModal } = useContext(ModalContext);

  const afterSubmit = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const openCreateAccountOperationTerritoryModal = useCallback(() => {
    setModal({
      title: t('addOperationTerritories'),
      maxWidth: '2xl',
      content: (
        <AddAccountOperationTerritoryModal
          afterSubmit={afterSubmit}
          onClose={() => setModal(null)}
        />
      ),
    });
  }, [afterSubmit, setModal, t]);

  return (
    <>
      <div className="flex w-full justify-between mb-s-8">
        <h1 className="h3">{t('manageOperationTerritories')}</h1>
        <Button
          onClick={openCreateAccountOperationTerritoryModal}
          icon={<i className="ri-map-pin-add-line" />}
          primary
        >
          {t('addSomeOperationTerritories')}
        </Button>
      </div>

      <AccountOperationTerritoryList />
    </>
  );
};

export default AccountOperationTerritoryListPage;
