import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useOfferCandidateDeleteCV } from '@domain/offer';

import { toViewModel } from '../view-models/delete-offer-candidate-cv.vm';

const useDeleteCandidateOfferCV = () => {
  const { data, fetching, error, deleteOfferCandidateCV } =
    useOfferCandidateDeleteCV();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    offerCandidateDeleteCV: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      modifier: 'alert-error',
      action: t('deleteCVError'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    viewModel,
    deleteOfferCandidateCV,
  };
};

export default useDeleteCandidateOfferCV;
