import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MultiValue } from 'react-select';

import { OptionType } from '@application/components/select-field/select';
import { debounce } from '@application/utils';
import {
  CandidateOffersFilters,
  CandidateRequestType,
  OfferStatusTypeCode,
} from '@domain/graphql.types';

const defaultFilters = {
  dates: undefined,
  ids: undefined,
  operationTerritoryCodes: undefined,
  status: undefined,
  candidateRequestTypes: undefined,
  text: undefined,
};

const statusCodeMap: Record<string, CandidateOffersFilters['status']> = {
  '#published': [OfferStatusTypeCode.Published],
  '#accepted': [OfferStatusTypeCode.Accepted],
  '#rejected': [OfferStatusTypeCode.Rejected],
  '#draft': [OfferStatusTypeCode.Draft],
};

type UseFiltersProps = {
  resetPagination?: () => void;
};

const useOffersFilters = ({ resetPagination }: UseFiltersProps) => {
  const [filters, setFilters] =
    useState<CandidateOffersFilters>(defaultFilters);

  const location = useLocation();

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      status: statusCodeMap[location.hash] || undefined,
    }));
  }, [location.hash]);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateTextFilter = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }));
  });

  const updateSelectFilters =
    (fieldName: keyof CandidateOffersFilters) =>
    (values: MultiValue<OptionType>) => {
      let filterValues: (string | CandidateRequestType)[] = [];

      if (values.length) {
        filterValues = values.map((v: OptionType) =>
          fieldName === 'candidateRequestTypes'
            ? CandidateRequestType[v.value as keyof typeof CandidateRequestType]
            : v.value
        );
      }

      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: filterValues,
      }));
    };

  const updateDateFilters =
    (fieldName: keyof CandidateOffersFilters) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: event.target.value
          ? [new Date(event.target.value)]
          : undefined,
      }));
    };

  return {
    filters,
    updateSelectFilters,
    updateDateFilters,
    updateTextFilter,
  };
};

export default useOffersFilters;
