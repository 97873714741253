import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { RecruitmentNegotiationResponse } from '@domain/graphql.types';

import { normalizeRecruitmentNegotiationAgreement } from '../normalizers';
import { RecruitmentNegotiationAgreement } from '../types';

type ToViewModel = {
  agreement: {
    data: RecruitmentNegotiationResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  agreement: { data, fetching, error },
}: ToViewModel): ViewModel<RecruitmentNegotiationAgreement | undefined> =>
  new ViewModel<RecruitmentNegotiationAgreement | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: normalizeRecruitmentNegotiationAgreement(data?.negotiation),
  });
