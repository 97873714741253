import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Cluster } from '@application/components';
import { AccountContext } from '@application/context';
import { ServiceType } from '@domain/graphql.types';

import { useUpdateAccount } from '../profile/hooks';

const AgencyServices = () => {
  const { account, refreshAccount } = useContext(AccountContext);

  const { t } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.agencyServices',
  });

  const { t: tGlobal } = useTranslation();

  const { updateAccount } = useUpdateAccount();

  const handleOnChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!account) return;

      const { checked, value } = event.target;
      const services = (account?.services as ServiceType[]) || [];

      if (!checked && services.length === 1) return;

      if (checked) {
        services.push(value as ServiceType);
      } else {
        services.splice(services.indexOf(value as ServiceType), 1);
      }

      await updateAccount({
        accountUpdateInput: { services },
      });
      refreshAccount();
    },
    [account, refreshAccount, updateAccount]
  );

  return (
    <>
      <h1 className="h3 mb-s-24">{t('title')}</h1>
      <Cluster space={16} as="ul">
        {Object.values(ServiceType).map((service) => (
          <li key={service}>
            <Checkbox
              name={service}
              value={service}
              label={tGlobal(`enum.agencyServiceCode.${service.toLowerCase()}`)}
              onChange={handleOnChange}
              checked={account?.services?.includes(service)}
              asButton
            />
          </li>
        ))}
      </Cluster>
    </>
  );
};

export default AgencyServices;
