import { gql } from '@urql/core';

const MutationSaveAndPublishCandidateRequest = gql`
  mutation SaveAndPublish(
    $input: CandidateRequestUpdateInput!
    $statusInput: CandidateRequestUpdateStatusInput!
  ) {
    candidateRequestUpdate(input: $input) {
      candidateRequest {
        id
      }
    }
    candidateRequestUpdateStatus(input: $statusInput) {
      candidateRequest {
        id
      }
    }
  }
`;

export default MutationSaveAndPublishCandidateRequest;
