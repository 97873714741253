import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';

type FiltersFieldsProps = {
  updateTextFilters: (event: ChangeEvent<HTMLInputElement>) => void;
};

const FiltersFields = ({ updateTextFilters }: FiltersFieldsProps) => {
  const { t } = useTranslation('operationUnit', {
    keyPrefix: 'list.filters',
  });

  return (
    <Stack space={16}>
      <Cluster space={24}>
        <TextInputField
          label={t('search')}
          name="search"
          placeholder={`${t('search')}...`}
          withIcon="ri-search-2-line text-24"
          hideIconBorder
          className="flex-1"
          onChange={updateTextFilters}
        />
      </Cluster>
    </Stack>
  );
};

export default FiltersFields;
