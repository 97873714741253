export const CREATE_OFFER_STEPS = [
  {
    title: 'steps.candidate',
    icon: 'ri-suitcase-line',
  },
  {
    title: 'steps.conditions',
    icon: 'ri-folder-info-line',
  },
];

export const OFFER_CANDIDATE_DEFAULT_VALUES = {
  firstNameDisplay: false,
  lastNameDisplay: false,
  emailDisplay: false,
  annualSalaryMin: 20000,
  annualSalaryMax: 200000,
  hourlyRateMin: 20,
  hourlyRateMax: 200,
  metAt: null,
  fullTimeAvailability: false,
  partTimeAvailability: false,
};

export const DEFAULT_VALUES = {
  publishNow: false,
  requestTypes: [],
};
