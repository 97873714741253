import { gql } from '@urql/core';

const MutationRecruitmentNegotiationRoomAcceptValues = gql`
  mutation RecruitmentNegotiationRoomAcceptValues(
    $input: RecruitmentNegotiationStateUpdateInput!
  ) {
    recruitmentNegotiationRoomAcceptValues(input: $input) {
      room {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationRoomAcceptValues;
