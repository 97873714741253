import { useTranslation } from 'react-i18next';

import { JobPaymentOtherTypesCode } from '@domain/graphql.types';

const useJobPaymentOtherTypesOptions = () => {
  const { t } = useTranslation();

  return Object.values(JobPaymentOtherTypesCode).map((code) => ({
    value: code,
    label: t(`enum.jobPaymentOtherTypesCode.${code.toLowerCase()}`),
  }));
};

export default useJobPaymentOtherTypesOptions;
