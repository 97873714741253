import { useQuery } from 'urql';

import {
  Query,
  QueryRecruitmentOpportunitiesArgs,
} from '@domain/graphql.types';
import { RecruitmentOpportunities } from '@infrastructure/graphql/opportunity';

const useRecruitmentOpportunities = (
  input?: QueryRecruitmentOpportunitiesArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'recruitmentOpportunities'>,
    QueryRecruitmentOpportunitiesArgs
  >({
    query: RecruitmentOpportunities,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useRecruitmentOpportunities;
