import { useJobSpecialtiesGet } from '@domain/job';

import { toViewModel } from './get-job-specialties.vm';

const useGetJobSpecialties = () => {
  const { data = [], fetching, error } = useJobSpecialtiesGet();

  return toViewModel({
    jobSpecialties: { data, fetching, error },
  });
};

export default useGetJobSpecialties;
