import { useMutation } from 'urql';

import {
  Mutation,
  MutationRecruitmentNegotiationRoomRejectArgs,
} from '@domain/graphql.types';
import { MutationRecruitmentNegotiationRoomReject } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiationRoomReject = () => {
  const [{ data, fetching, error }, rejectNegotiationRoom] = useMutation<
    Pick<Mutation, 'recruitmentNegotiationRoomReject'>,
    MutationRecruitmentNegotiationRoomRejectArgs
  >(MutationRecruitmentNegotiationRoomReject);

  return {
    rejectNegotiationRoom,
    data,
    fetching,
    error,
  };
};

export default useRecruitmentNegotiationRoomReject;
