import { useContext } from 'react';

import { AccountContext } from '@application/context';

const useAccountSubscriptionType = () => {
  const { account } = useContext(AccountContext);

  const subscriptionType = account?.subscriptionType;

  return {
    subscriptionType,
  };
};

export default useAccountSubscriptionType;
