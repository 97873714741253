import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';

import { CandidateOfferFormFields } from '../schema';

type ToViewModel = {
  candidateOffer: {
    data: Record<string, never> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateOffer: { data = {}, fetching, error },
}: ToViewModel): ViewModel<CandidateOfferFormFields> =>
  new ViewModel<CandidateOfferFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data as CandidateOfferFormFields,
  });
