import { useQuery } from 'urql';

import { Query, QueryOfferArgs } from '@domain/graphql.types';
import { OfferAccountName } from '@infrastructure/graphql/offer';

const useOfferAccountName = (input: QueryOfferArgs) => {
  const [result] = useQuery<Pick<Query, 'offer'>, QueryOfferArgs>({
    query: OfferAccountName,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.offer.offer?.account.name,
    fetching,
    error,
  };
};

export default useOfferAccountName;
