import { useRequestOffers } from '@domain/request';

import { toViewModel } from '../view-models/get-offers.vm';

const useGetRequestOffers = (requestId: string) => {
  const { data, fetching, error, refreshRequestOffers } = useRequestOffers({
    input: { requestId },
  });

  const viewModel = toViewModel({
    offers: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refreshRequestOffers,
  };
};

export default useGetRequestOffers;
