import { DateTime } from 'luxon';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { cn } from '@utils/lib-utils';

import { IconButton } from '../buttons';
import { TextInput } from '../form/text-input-field/textInput';
import { LoadingSpinner } from '../spinner';
import ChatListItem, { ChatListItemProps } from './ChatListItem';
import DateSeparator from './DateSeparator';
import MessageItem, { MessageItemProps } from './MessageItem';

export type ChatContent = {
  title?: string;
  messageList: MessageItemProps[];
};

type NegoroomChatProps = {
  title: string;
  subtitle: string;
  backTo: string;
  chatList?: ChatListItemProps[];
  chatContent?: ChatContent;
  isLoading: boolean;
  onSendMessage: (message: string) => void;
  refetchMessages: () => void;
};

const NegoroomChat = ({
  title,
  subtitle,
  backTo,
  chatList,
  chatContent,
  isLoading,
  onSendMessage,
  refetchMessages,
}: NegoroomChatProps) => {
  const [newMessage, setNewMessage] = useState('');
  const MessagesWrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.from;
  const { t } = useTranslation();

  const onBackClick = useCallback(
    () => navigate(redirectTo || backTo),
    [backTo, navigate, redirectTo]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const messageList = useMemo(() => {
    const now = DateTime.now();
    let yesterdaySeparatorRendered = false;
    let todaySeparatorRendered = false;

    return chatContent?.messageList.map((message, i) => {
      const messageDate = DateTime.fromISO(message.date);
      let dateSeparator = null;

      if (
        !yesterdaySeparatorRendered &&
        messageDate.hasSame(now.minus({ days: 1 }), 'day')
      ) {
        yesterdaySeparatorRendered = true;
        dateSeparator = <DateSeparator type="yesterday" />;
      }

      if (!todaySeparatorRendered && messageDate.hasSame(now, 'day')) {
        todaySeparatorRendered = true;
        dateSeparator = <DateSeparator type="today" />;
      }

      return (
        <Fragment key={`message-${i}`}>
          {dateSeparator}

          <MessageItem
            position={message.position}
            date={message.date}
            viewed={message.viewed}
            content={message.content}
          />
        </Fragment>
      );
    });
  }, [chatContent?.messageList]);

  const scrollToBottom = () => {
    if (MessagesWrapperRef.current) {
      MessagesWrapperRef.current.scrollTop =
        MessagesWrapperRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatContent?.messageList]);

  const classMainWrapper = 'h-full';
  const classHeaderWrapper = 'h-s-48 flex items-center gap-s-16';
  const classTitle =
    'flex-shrink max-w-[60%] overflow-ellipsis overflow-hidden whitespace-nowrap h3';
  const classSubtitle =
    'flex-grow overflow-ellipsis overflow-hidden whitespace-nowrap text-neutral-secondary';
  const classContentWrapper =
    'mt-s-24 h-[calc(100%-3rem-1.5rem)] flex gap-s-16';
  const classLeftContentWrapper =
    'w-s-160 md:w-s-256 lg:w-[360px] flex-shrink-0 overflow-y-auto';
  const classRightContentWrapper = 'relative flex-grow border rounded-md';
  const classChatContentTitle =
    'h-s-64 flex items-center px-s-16 text-18 font-semibold border-b';
  const classMessagesWrapper = cn('h-full p-s-16 overflow-auto', {
    'h-[calc(100%-3rem)]': chatContent?.title === undefined,
    'h-[calc(100%-4rem-3rem)]': chatContent?.title !== undefined,
  });
  const classInputWrapper = 'flex border-t h-s-48';
  const classInput =
    'flex-grow px-s-16 rounded-tl-none rounded-br-none rounded-tr-none border-t-transparent border-l-transparent';
  const classSendButton = 'rounded-t-none rounded-bl-none';
  const classLoadingSpinner = 'h-full flex items-center justify-center';

  return (
    <div className={classMainWrapper}>
      <div className={classHeaderWrapper}>
        <IconButton
          type="button"
          icon={<i className="ri-arrow-left-line" />}
          text={t('button.backPreviousPage')}
          onClick={onBackClick}
        />

        <h1 className={classTitle}>{title}</h1>
        <div className={classSubtitle}>{subtitle}</div>

        <IconButton
          type="button"
          icon={<i className="ri-refresh-line" />}
          text="refresh"
          onClick={refetchMessages}
        />
      </div>

      <div className={classContentWrapper}>
        {chatList?.length && (
          <div className={classLeftContentWrapper}>
            {chatList.map((chat, i) => (
              <ChatListItem
                key={i}
                selected={chat.selected}
                rank={chat.rank}
                title={chat.title}
                preview={chat.preview}
                date={chat.date}
                newMessageCount={chat.newMessageCount}
                onClick={chat.onClick}
              />
            ))}
          </div>
        )}

        <div className={classRightContentWrapper}>
          {chatContent?.title && (
            <div className={classChatContentTitle}>{chatContent?.title}</div>
          )}

          <div className={classMessagesWrapper} ref={MessagesWrapperRef}>
            {isLoading ? (
              <div className={classLoadingSpinner}>
                <LoadingSpinner size="lg" />
              </div>
            ) : (
              messageList
            )}
          </div>

          <div className={classInputWrapper}>
            <TextInput
              id="newMessage"
              name="newMessage"
              className={classInput}
              placeholder={
                chatContent?.title
                  ? t('forms.placeholders.writeTo', {
                      field: chatContent.title,
                    })
                  : t('forms.placeholders.writeHere')
              }
              type="text"
              value={newMessage}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              autoComplete="off"
            />

            <IconButton
              className={classSendButton}
              icon={<i className="ri-send-plane-2-line" />}
              text={t('button.send')}
              primary
              onClick={handleSendMessage}
              disabled={isLoading || !newMessage.trim()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NegoroomChat;
