import { useTranslation } from 'react-i18next';

import { ContextualMenu } from '@application/components/contextual-menu';
import { RequestStatusTypeCode } from '@domain/graphql.types';

type ActionMenuProps = {
  id: string;
  availableTransitions: readonly RequestStatusTypeCode[];
  onUpdateStatus: (
    id: string,
    status: RequestStatusTypeCode
  ) => () => Promise<void>;
  onDuplicate: (requestId: string) => () => Promise<void>;
  onDelete: (requestId: string) => () => void;
  hideDelete?: boolean;
};

const ActionMenu = ({
  id,
  onUpdateStatus,
  onDuplicate,
  onDelete,
  availableTransitions,
  hideDelete,
}: ActionMenuProps) => {
  const { t } = useTranslation('requests', { keyPrefix: 'list.actions' });

  const actions = [
    {
      label: t('duplicate'),
      onClick: onDuplicate(id),
    },
    {
      label: t('pause'),
      hidden: !availableTransitions.includes(RequestStatusTypeCode.Paused),
      onClick: onUpdateStatus(id, RequestStatusTypeCode.Paused),
    },
    {
      label: t('archive'),
      hidden: !availableTransitions.includes(RequestStatusTypeCode.Archived),
      onClick: onUpdateStatus(id, RequestStatusTypeCode.Archived),
    },
    {
      label: t('delete'),
      hidden: hideDelete,
      onClick: onDelete(id),
    },
  ];

  return <ContextualMenu actions={actions} />;
};

export default ActionMenu;
