import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '@application/context';

import TermsAndConditionsModal from './TermsAndConditionsModal';

export const useTermsAndConditionsModal = () => {
  const { t: tGlobal } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const openTermsAndConditionsModal = useCallback(() => {
    setModal({
      title: tGlobal('termsAndConditions.title'),
      content: <TermsAndConditionsModal onClose={() => setModal(null)} />,
      maxWidth: '2xl',
    });
  }, [setModal, tGlobal]);

  return openTermsAndConditionsModal;
};
