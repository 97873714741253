import { gql } from 'urql';

const OfferGeneralConditionsFragment = gql`
  fragment OfferGeneralConditionsFragment on OfferGeneralConditions {
    deposit
    maxRecruitmentPeriod
    minGuaranteedPeriod
    minGuaranteedPeriodBonus
    paymentAmount
    paymentFrequency
    paymentMethod
    probationPeriod
    remunerationAmount
    remunerationEstimatedHours
    remunerationHourlyRate
    remunerationPercentage
    remunerationType
    hiredDateBonusRequirement
    hiredDateBonus
    startOfAgreementPaymentMethod
    bonus
    bonusMonth
  }
`;

export default OfferGeneralConditionsFragment;
