export const defaultPageData = {
  count: 0,
  limit: 20,
  offset: 0,
};

export const defaultPageInfo = {
  startCursor: '',
  endCursor: '',
  hasPreviousPage: false,
  hasNextPage: false,
};
