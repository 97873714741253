import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { PlaceholderSwitcher } from '@application/components/switch';
import { PrivatePage } from '@application/enums/pagesUrl';
import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

type ResumeLinkProps = {
  room: RecruitmentNegotiationRoom;
};

const ResumeLink = ({ room }: ResumeLinkProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  return (
    <tr>
      <th className={TH_ROW_CLASS_NAME.base}>{t('tableHeaders.resumes')}</th>

      <td
        className={cn(
          TD_CLASS_NAME.base,
          'text-center border-l-1 border-b-1 border-transparent'
        )}
      >
        <PlaceholderSwitcher value={room.offer.resumeCount || undefined}>
          <Link
            className="underline font-semibold hover:no-underline"
            to={generatePath(PrivatePage.VIEW_OFFER, {
              id: room.offer.id,
            })}
          >
            {t('tableHeaders.resumeCount', { count: room.offer.resumeCount })}
          </Link>
        </PlaceholderSwitcher>
      </td>
    </tr>
  );
};

export default ResumeLink;
