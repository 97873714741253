import { gql } from 'urql';

import { getPage } from '@infrastructure/graphql/pagination';

import AccountOperationTerritoryFragment from './account.operation.territory.fragment';

const AccountOperationTerritories = gql`
  ${AccountOperationTerritoryFragment}
  query AccountOperationTerritories(
    $filterBy: AccountOperationTerritoriesFilters,
    $sortBy: [AccountOperationTerritoriesSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    accountOperationTerritories(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['AccountOperationTerritoryFragment'])}
  }
`;

export default AccountOperationTerritories;
