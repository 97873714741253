import * as yup from 'yup';
import 'yup-phone-lite';

export const ACCEPT_INVITATION_SCHEMA = yup.object({
  accountUserId: yup.string().required(),
  termsAndConditionsAccepted: yup
    .boolean()
    .required('validations.required.termsAndConditions'),
});

export type AcceptInvitationFormFields = yup.InferType<
  typeof ACCEPT_INVITATION_SCHEMA
>;
