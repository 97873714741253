import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const JobSpecialties = gql`
  ${DescriptionsFragment}

  query JobSpecialties {
    jobSpecialties(input: {}) {
      page {
        edges {
          cursor
          node {
            id
            code
            descriptions {
              ...DescriptionsFragment
            }
            sector {
              code
              descriptions {
                ...DescriptionsFragment
              }
              industry {
                code
              }
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
      pageData {
        count
        limit
        offset
      }
    }
  }
`;

export default JobSpecialties;
