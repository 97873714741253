import { Fieldset } from '@application/components';
import { Box } from '@application/components/container-layouts';

type SectionProps = {
  legend: string;
  children: React.ReactNode;
  hideBox?: boolean;
};

const Section = ({ children, legend, hideBox }: SectionProps) => (
  <Fieldset
    className="w-full flex flex-col mb-s-24"
    legend={legend}
    legendClassName="text-20"
  >
    {hideBox ? (
      children
    ) : (
      <Box padding={24} className="w-full">
        {children}
      </Box>
    )}
  </Fieldset>
);

export default Section;
