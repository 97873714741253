import { RecruitmentOpportunity } from '@domain/graphql.types';

import GeneralInformation from './GeneralInformation';
import Notes from './Notes';
import Remuneration from './Remuneration';
import Skills from './Skills';

type DescriptionSummaryProps = {
  data: RecruitmentOpportunity | undefined;
  isInOpportunityOffer?: boolean;
};

const DescriptionSummary = ({
  data,
  isInOpportunityOffer = false,
}: DescriptionSummaryProps) => (
  <>
    <GeneralInformation
      data={data}
      isInOpportunityOffer={isInOpportunityOffer}
    />

    <Remuneration data={data} isInOpportunityOffer={isInOpportunityOffer} />

    <Skills
      data={data?.softSkills}
      isInOpportunityOffer={isInOpportunityOffer}
    />

    <Notes data={data} isInOpportunityOffer={isInOpportunityOffer} />
  </>
);

export default DescriptionSummary;
