import { useMutation } from 'urql';

import {
  Mutation,
  MutationOperationUnitDeleteArgs,
} from '@domain/graphql.types';
import { OperationUnitDelete } from '@infrastructure/graphql/operation';

const useOperationUnitDelete = () => {
  const [{ data, fetching, error }, deleteOperationUnit] = useMutation<
    Pick<Mutation, 'operationUnitDelete'>,
    MutationOperationUnitDeleteArgs
  >(OperationUnitDelete);

  return {
    deleteOperationUnit,
    data: data?.operationUnitDelete,
    fetching,
    error,
  };
};

export default useOperationUnitDelete;
