import { useValidateIdParam } from '@application/hooks';
import { useCandidateNegotiationRoom } from '@domain/candidate';

import { toViewModel } from '../view-models/get-candidate-negotiation-room.vm';

const useGetCandidateNegotiationRoom = (roomId: string) => {
  useValidateIdParam(roomId);

  const { data, fetching, error, reexecuteQuery } = useCandidateNegotiationRoom(
    {
      input: { roomId },
    }
  );

  const viewModel = toViewModel({
    room: {
      data: data?.candidateNegotiationRoom,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidate: reexecuteQuery,
  };
};

export default useGetCandidateNegotiationRoom;
