// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import { Select } from '@application/components/select-field/select';
import { PlaceholderSwitcher } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import {
  useBooleanOptions,
  useOfferConditionResponsibilityOptions,
} from '@application/hooks';
import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { RecruitmentNegotiationRoomFormFields } from '../../../room/schema';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';
import { getCellStyleForState } from '../../utils';

type TemporaryPlacementConditionsProps = {
  modificationEnabled: boolean;
  onSubmit: () => Promise<void>;
  room: RecruitmentNegotiationRoom;
};

const possibleBuybackKey: keyof RecruitmentNegotiationRoomFormFields =
  'temporaryPlacementConditionPossibleBuyback';
const cnesstKey: keyof RecruitmentNegotiationRoomFormFields =
  'temporaryPlacementConditionCnesstExpensesResponsibility';
const trainingKey: keyof RecruitmentNegotiationRoomFormFields =
  'temporaryPlacementConditionTrainingExpensesResponsibility';
const travelKey: keyof RecruitmentNegotiationRoomFormFields =
  'temporaryPlacementConditionTravelExpensesResponsibility';

const TemporaryPlacementConditions = ({
  modificationEnabled,
  onSubmit,
  room,
}: TemporaryPlacementConditionsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const { control, setValue, watch } =
    useFormContext<RecruitmentNegotiationRoomFormFields>();

  const fieldInModification = watch('fieldInModification');

  const booleanOptions = useBooleanOptions();
  const responsibilityOptions = useOfferConditionResponsibilityOptions();

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('temporaryPlacementConditions')}
        </th>

        <td
          className={cn(TH_ROW_CLASS_NAME.highlight, 'border-l-1 border-b-1')}
        >
          &nbsp;
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('possibleBuyback')} lineClamp={2}>
            <div>{t('possibleBuyback')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === possibleBuybackKey,
          })}
        >
          {fieldInModification === possibleBuybackKey ? (
            <Controller
              name="temporaryPlacementConditionPossibleBuyback"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  className="border-2 border-black h-full"
                  controlClassName="border-none"
                  name={name}
                  menuPlacement="bottom"
                  options={booleanOptions}
                  invalid={!!error}
                  value={booleanOptions.find(
                    (bool) => bool.value === String(value)
                  )}
                  onChange={(option: any) => {
                    onChange(option.value === 'true');
                    onSubmit();
                  }}
                  searchable
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.temporaryPlacementPropertiesState.possibleBuyback
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer
                      .temporaryPlacementContractualConditions?.possibleBuyback
                  }
                >
                  <span>
                    {room.negotiatingOffer
                      .temporaryPlacementContractualConditions?.possibleBuyback
                      ? tGlobal('boolean.true')
                      : tGlobal('boolean.false')}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() =>
                  setValue('fieldInModification', possibleBuybackKey)
                }
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis
            toolTip={t('travelExpensesResponsibility')}
            lineClamp={2}
          >
            <div>{t('travelExpensesResponsibility')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === travelKey,
          })}
        >
          {fieldInModification === travelKey ? (
            <Controller
              name="temporaryPlacementConditionTravelExpensesResponsibility"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  className="border-2 border-black h-full"
                  controlClassName="border-none"
                  name={name}
                  menuPlacement="bottom"
                  options={responsibilityOptions}
                  invalid={!!error}
                  value={responsibilityOptions.find(
                    (responsibility) => responsibility.value === value
                  )}
                  onChange={(option: any) => {
                    onChange(option.value);
                    onSubmit();
                  }}
                  searchable
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.temporaryPlacementPropertiesState
                      .travelExpensesResponsibility
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer
                      .temporaryPlacementContractualConditions
                      ?.travelExpensesResponsibility
                  }
                >
                  <span>
                    {tGlobal(
                      `enum.requestConditionResponsibility.${room.negotiatingOffer.temporaryPlacementContractualConditions?.travelExpensesResponsibility
                        ?.toString()
                        .toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() => setValue('fieldInModification', travelKey)}
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis
            toolTip={t('trainingExpensesResponsibility')}
            lineClamp={2}
          >
            <div>{t('trainingExpensesResponsibility')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === trainingKey,
          })}
        >
          {fieldInModification === trainingKey ? (
            <Controller
              name="temporaryPlacementConditionTrainingExpensesResponsibility"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  className="border-2 border-black h-full"
                  controlClassName="border-none"
                  name={name}
                  menuPlacement="bottom"
                  options={responsibilityOptions}
                  invalid={!!error}
                  value={responsibilityOptions.find(
                    (responsibility) => responsibility.value === value
                  )}
                  onChange={(option: any) => {
                    onChange(option.value);
                    onSubmit();
                  }}
                  searchable
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.temporaryPlacementPropertiesState
                      .trainingExpensesResponsibility
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer
                      .temporaryPlacementContractualConditions
                      ?.trainingExpensesResponsibility
                  }
                >
                  <span>
                    {tGlobal(
                      `enum.requestConditionResponsibility.${room.negotiatingOffer.temporaryPlacementContractualConditions?.trainingExpensesResponsibility
                        ?.toString()
                        .toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() => setValue('fieldInModification', trainingKey)}
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis
            toolTip={t('cnesstExpensesResponsibility')}
            lineClamp={2}
          >
            <div>{t('cnesstExpensesResponsibility')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === cnesstKey,
          })}
        >
          {fieldInModification === cnesstKey ? (
            <Controller
              name="temporaryPlacementConditionCnesstExpensesResponsibility"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  className="border-2 border-black h-full"
                  controlClassName="border-none"
                  name={name}
                  menuPlacement="bottom"
                  options={responsibilityOptions}
                  invalid={!!error}
                  value={responsibilityOptions.find(
                    (responsibility) => responsibility.value === value
                  )}
                  onChange={(option: any) => {
                    onChange(option.value);
                    onSubmit();
                  }}
                  searchable
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.temporaryPlacementPropertiesState
                      .cnesstExpensesResponsibility
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer
                      .temporaryPlacementContractualConditions
                      ?.cnesstExpensesResponsibility
                  }
                >
                  <span>
                    {tGlobal(
                      `enum.requestConditionResponsibility.${room.negotiatingOffer.temporaryPlacementContractualConditions?.cnesstExpensesResponsibility
                        ?.toString()
                        .toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() => setValue('fieldInModification', cnesstKey)}
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default TemporaryPlacementConditions;
