import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLoader } from '@application/components/page';
import { PrivatePage } from '@application/enums/pagesUrl';

type NoAuthGuardProps = {
  children: React.ReactNode;
};

const NoAuthGuard = ({ children }: NoAuthGuardProps) => {
  const { isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(PrivatePage.DASHBOARD);
    }
  }, [isAuthenticated, navigate]);

  if (isLoading) {
    return <PageLoader />;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default NoAuthGuard;
