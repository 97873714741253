import { gql } from '@urql/core';

import PricingTableFragment from './pricingTable.fragment';

const GetPricingTable = gql`
  ${PricingTableFragment}

  mutation GetPricingTable {
    getPricingTable {
      ...PricingTableFragment
    }
  }
`;

export default GetPricingTable;
