import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Banner from '@application/assets/banner.svg?react';
import { NavigationTabs } from '@application/components';
import { Avatar } from '@application/components/avatar';
import { ButtonLink } from '@application/components/links';
import { AccountContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { AccountTypeCode, UserRole } from '@domain/graphql.types';

import { useIsUserRoleAuthorized } from '../../hooks';
import { AgencyInformation, EnterpriseInformation } from './components';

const OrganizationAccountPage = () => {
  const { t } = useTranslation('organization');

  const { account, refreshAccount } = useContext(AccountContext);

  const canEditProfile = useIsUserRoleAuthorized({
    authorizedUserRoles: [UserRole.Admin, UserRole.Owner, UserRole.SuperAdmin],
  });

  const tabs = useMemo(
    () => [
      {
        path: RootPrivatePage.ORGANIZATION,
        element: t('tabs.information'),
        label: t('tabs.information'),
        id: 'tab-information',
      },
    ],
    [t]
  );

  useEffect(() => {
    refreshAccount();
  }, [refreshAccount]);

  return (
    <>
      <Banner className="w-[calc(100%+64px)] -mt-s-32 -ml-s-32" />

      <div className="grid md:grid-cols-[320px_1fr] gap-x-s-32 gap-y-s-8 mt-s-32">
        <Avatar
          className="border -mt-s-80"
          size="large"
          name={account?.name}
          url={account?.logoUrl}
        />

        <div className="flex justify-between gap-s-16">
          <NavigationTabs tabs={tabs} />

          {canEditProfile && (
            <ButtonLink
              to={PrivatePage.ORGANIZATION_USERS}
              icon={<i className="ri-pencil-line" />}
              className="h-s-48"
            >
              {t('button.editProfile')}
            </ButtonLink>
          )}
        </div>

        {account?.type === AccountTypeCode.Agency && (
          <AgencyInformation account={account} />
        )}

        {account?.type === AccountTypeCode.Enterprise && (
          <EnterpriseInformation account={account} />
        )}
      </div>
    </>
  );
};

export default OrganizationAccountPage;
