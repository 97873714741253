import { gql } from 'urql';

const MutationRecruitmentNegotiationCreate = gql`
  mutation RecruitmentNegotiationCreate(
    $input: RecruitmentNegotiationCreateInput!
  ) {
    recruitmentNegotiationCreate(input: $input) {
      negotiation {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationCreate;
