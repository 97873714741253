import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateOfferUpdateArgs,
} from '@domain/graphql.types';
import { MutationCandidateOfferUpdate } from '@infrastructure/graphql/candidate/offer';

const useCandidateOfferUpdate = () => {
  const [{ data, fetching, error }, updateCandidateOffer] = useMutation<
    Pick<Mutation, 'candidateOfferUpdate'>,
    MutationCandidateOfferUpdateArgs
  >(MutationCandidateOfferUpdate);

  return {
    updateCandidateOffer,
    data,
    fetching,
    error,
  };
};

export default useCandidateOfferUpdate;
