import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { NavigationTabs } from '@application/components/tabs';
import { AccountContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { AccountTypeCode, UserRole } from '@domain/graphql.types';

const OrganizationPage = () => {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation('organization');

  const isAgency = account?.type === AccountTypeCode.Agency;

  const tabs = useMemo(
    () => [
      {
        path: PrivatePage.ORGANIZATION_USERS,
        element: (
          <div className="flex items-center	">
            <span className={account?.invitationsCount ? 'mr-s-4' : ''}>
              {t('tabs.members')}
            </span>

            {!!account?.invitationsCount && (
              <div className="flex rounded-full w-s-18 h-s-18 bg-secondary justify-center items-center text-12">
                {account.invitationsCount}
              </div>
            )}
          </div>
        ),
        label: 'members',
        id: 'tab-members',
      },
      {
        path: PrivatePage.ORGANIZATION_PROFILE,
        element: t('tabs.information'),
        label: 'members',
        id: 'tab-information',
      },
      {
        path: PrivatePage.ORGANIZATION_ACCOUNT_MANAGEMENT,
        element: t('tabs.accountManagement'),
        label: 'company-locations',
        id: 'tab-company-locations',
        authorizedUserRoles: [
          UserRole.SuperAdmin,
          UserRole.Owner,
          UserRole.Admin,
        ],
      },
      {
        element: isAgency ? t('tabs.expertises') : t('tabs.requestsOffers'),
        label: 'job-opportunities',
        id: 'tab-job-opportunities',
        children: [
          {
            path: PrivatePage.ORGANIZATION_JOB_OPPORTUNITIES,
            element: t('tabs.jobOpportunities'),
            label: 'job-opportunities',
            id: 'tab-job-opportunities',
          },
        ],
        authorizedUserRoles: [
          UserRole.SuperAdmin,
          UserRole.Owner,
          UserRole.Admin,
        ],
      },
    ],
    [account?.invitationsCount, t, isAgency]
  );

  return (
    <>
      <h1 className="sr-only">{t('title')}</h1>

      <NavigationTabs tabs={tabs} />

      <Outlet />
    </>
  );
};

export default OrganizationPage;
