import { RequestFormFields } from './schema';

export const REQUEST_STEPS = [
  {
    title: 'steps.description',
    icon: 'ri-suitcase-line',
  },
  {
    title: 'steps.conditions',
    icon: 'ri-folder-info-line',
  },
];

export const DEFAULT_VALUES: RequestFormFields = {
  desiredStartDate: null,
  receivingOfferDeadline: null,
  jobOpenPositions: null,
  jobExperienceLevelMin: null,
  jobExperienceLevelMax: null,
  conditionMinimumBudgetValue: 3500,
  conditionMaximumBudgetValue: 15000,
  jobAvailabilityInHoursPerWeek: [],
  conditionCandidateInHandMandatoryDisplay: true,
  conditionMaxRecruitmentPeriodDisplay: true,
  conditionMinGuaranteedPeriodDisplay: true,
  conditionProbationPeriodDisplay: false,
  conditionPaymentMethodDisplay: false,
  conditionHiredDateBonusDisplay: true,
  conditionDepositDisplay: false,
  conditionBudgetDisplay: true,
  conditionBonusDisplay: true,
  types: [],
  citiesSubRegions: [],
  headhunterConditionMaximumReplacementDisplay: true,
  headhunterConditionExclusivityPeriodDisplay: true,
  internationalRecruitmentConditionMandatoryWorkPermitDisplay: true,
  internationalRecruitmentConditionExclusivityPeriodDisplay: false,
  internationalRecruitmentConditionMaximumReplacementDisplay: false,
  outsourcingConditionMaximumDurationDisplay: true,
  outsourcingConditionExclusivityPeriodDisplay: false,
  outsourcingConditionMaximumBudgetDisplay: true,
  temporaryPlacementConditionPossibleBuybackDisplay: true,
  temporaryPlacementConditionRisksDisplay: false,
  temporaryPlacementConditionResponsibilitiesDisplay: true,
  temporaryPlacementConditionForeignApplicantsDisplay: true,
  // set required values for publish function
  jobFullTimeAvailability: false,
  jobPartTimeAvailability: false,
  jobSalaryMin: 20000,
  jobSalaryMax: 200000,
  jobHourlyRateMin: 20,
  jobHourlyRateMax: 200,
  desiredEducationLevelCodes: [],
  perks: [],
  otherPerks: [],
  softSkills: [],
  conditionHiredDateBonusRequirement: '',
};
