import React, { useMemo, useState } from 'react';

import InvitationsListContext from './InvitationsListContext';

type InvitationsListProviderProps = {
  children: React.ReactNode;
};

const InvitationsListProvider = ({
  children,
}: InvitationsListProviderProps) => {
  const [shouldRefreshList, setShouldRefreshList] = useState(false);

  const value = useMemo(
    () => ({
      shouldRefreshList,
      setShouldRefreshList,
    }),
    [shouldRefreshList, setShouldRefreshList]
  );

  return (
    <InvitationsListContext.Provider value={value}>
      {children}
    </InvitationsListContext.Provider>
  );
};

export default React.memo(InvitationsListProvider);
