import { autoUpdate, useFloating } from '@floating-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../buttons';
import OnboardingGuide from './OnboardingGuide';

const OnboardingGuideButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.onboardingGuide',
  });
  const [guideOpen, setGuideOpen] = useState(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      <Button
        ref={refs.setReference}
        ghost
        onClick={() => setGuideOpen(!guideOpen)}
        size="x-small"
        className="text-content-action-dark bg-content-action-light"
      >
        {t('onboardingGuideButton')}
      </Button>

      {guideOpen && (
        <div
          ref={refs.setFloating}
          className="mt-s-28 p-s-32 bg-base-100 border border-stroke-default rounded-md shadow-sm"
          style={{
            ...floatingStyles,
          }}
        >
          <OnboardingGuide onClose={() => setGuideOpen(false)} />
        </div>
      )}
    </>
  );
};

export default OnboardingGuideButton;
