import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryList } from '@application/components/summary';
import { CandidateOffer } from '@domain/graphql.types';

type OtherPerksProps = {
  offer: CandidateOffer | undefined;
};

const OtherPerks = ({ offer }: OtherPerksProps) => {
  const { t } = useTranslation('candidates');

  return (
    <SummaryBlock className="mt-s-16">
      <SummaryList
        className="!mt-s-0"
        density="default"
        data={[
          {
            text: `${t('labels.candidateBonus')}:`,
            description: offer?.candidateBonus
              ? t(
                  `enum.candidateOfferBonus.${offer?.candidateBonus?.toLowerCase()}Value`,
                  { amount: offer?.candidateBonusAmount }
                )
              : '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default OtherPerks;
