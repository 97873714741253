/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useTranslation } from 'react-i18next';

import { RankBadge } from '@application/components';
import { CandidateOffer, CandidateRequestType } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../constants';
import { EmployerBrand, OfferToRecruiter } from './rows';

type TableBodyProps = {
  candidateOffer: CandidateOffer;
  type?: CandidateRequestType;
};

const TableBody = ({ candidateOffer, type }: TableBodyProps) => {
  const { t } = useTranslation('candidates');

  return (
    <tbody>
      <tr>
        <td className="sr-only">{t('labels.rank')}</td>
        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'h-[7rem] border-l-0 text-center'
          )}
        >
          {candidateOffer.rank && <RankBadge rank={candidateOffer.rank} />}
        </td>
      </tr>

      <EmployerBrand data={candidateOffer} type={type} />
      <OfferToRecruiter data={candidateOffer} type={type} />
    </tbody>
  );
};

export default TableBody;
