import { useState } from 'react';

export type StepProps = {
  title: string;
  icon: string;
};

type UseWizardProps = {
  steps: StepProps[];
};

const useWizard = ({ steps }: UseWizardProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<boolean[]>(
    Array(steps.length).fill(false)
  );

  const setCompleted = (stepIndex: number) => {
    if (stepIndex >= 0 && stepIndex < completedSteps.length) {
      const c = [...completedSteps];
      c[stepIndex] = true;
      setCompletedSteps(c);
    }
  };

  const nextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
      setCompleted(activeStep);
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const setActive = (stepIndex: number) => {
    if (stepIndex >= 0 && stepIndex < steps.length) {
      setActiveStep(stepIndex);
    }
  };

  const markCurrentStepAsCompleted = () => {
    setCompleted(activeStep);
  };

  return {
    activeStep,
    nextStep,
    prevStep,
    setActive,
    setCompleted,
    completedSteps,
    markCurrentStepAsCompleted,
  };
};

export default useWizard;
