import { useMutation } from 'urql';

import {
  MutationRecruitmentNegotiationRoomMessageCreateArgs,
  RecruitmentNegotiationRoomMessageResponse,
} from '@domain/graphql.types';
import { RecruitmentNegotiationRoomMessageCreate } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiationRoomMessageCreate = () => {
  const [{ data, fetching, error }, createRecruitmentNegotiationRoomMessage] =
    useMutation<
      RecruitmentNegotiationRoomMessageResponse,
      MutationRecruitmentNegotiationRoomMessageCreateArgs
    >(RecruitmentNegotiationRoomMessageCreate);

  return {
    createRecruitmentNegotiationRoomMessage,
    data,
    fetching,
    error,
  };
};

export default useRecruitmentNegotiationRoomMessageCreate;
