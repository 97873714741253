import { useTranslation } from 'react-i18next';
import { GroupBase, MultiValueRemoveProps } from 'react-select';

import { OptionType } from '../types';

const MultiValueRemove = (
  props: MultiValueRemoveProps<OptionType, boolean, GroupBase<OptionType>>
) => {
  const { t } = useTranslation();

  const {
    data,
    innerProps: { ref, ...restInnerProps },
  } = props;

  // We re-define default aria-label from innerProps here in order to handle translations
  return (
    <div
      ref={ref}
      role="button"
      {...restInnerProps}
      aria-label={t('select.label.removeItem', { item: data?.label })}
    >
      <i className="ri-close-line text-20 text-primary" />
    </div>
  );
};

export default MultiValueRemove;
