import { gql } from '@urql/core';

import DescriptionsFragment from '../descriptions/descriptions.fragment';
import { OperationUnitFragment } from '../operation';

const Account = gql`
  ${OperationUnitFragment}
  ${DescriptionsFragment}

  query Account {
    account {
      account {
        id
        logoUrl
        name
        website
        address
        operationTerritories {
          active
          operationTerritoryCode
        }
        phoneNumber
        phoneDisplay
        advantageCodes
        industryTypeCode
        sectorCode
        sizeTypeCode
        employerOfferDescription
        facebookUrl
        instagramUrl
        linkedinUrl
        twitterUrl
        services
        type
        enterpriseSubscriptionInformations {
          freemiumAllowedRequests
          freemiumRemainingRequests
          premiumAllowedCandidateOffers
          premiumRemainingCandidateOffers
        }
        invitationsCount
        subscriptionType
        operationUnits {
          ...OperationUnitFragment
        }
        preferredSpecialties {
          specialty {
            code
          }
        }
        sector {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
      }
    }
  }
`;

export default Account;
