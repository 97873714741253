import { gql } from '@urql/core';

const MutationOfferCandidateDeleteOtherFile = gql`
  mutation OfferCandidateDeleteOtherFile(
    $input: OfferCandidateDeleteOtherFileInput!
  ) {
    offerCandidateDeleteOtherFile(input: $input) {
      ok
    }
  }
`;

export default MutationOfferCandidateDeleteOtherFile;
