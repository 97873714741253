import { BadgeGroup } from '@application/components/badge';

interface SummaryBadgesProps {
  badges: string[];
}

const SummaryBadges = ({ badges }: SummaryBadgesProps) =>
  badges.length > 0 ? <BadgeGroup elements={badges} variant="primary" /> : '-';

export default SummaryBadges;
