import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import AccountPreferredSpecialtyListFragment from './account.preferred.specialty.list.fragment';

const AccountPreferredSpecialties = gql`
  ${AccountPreferredSpecialtyListFragment}

  query AccountPreferredSpecialties(
    $filterBy: AccountPreferredSpecialtiesFilters,
    $sortBy: [AccountPreferredSpecialtiesSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) { 
    accountPreferredSpecialties(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['AccountPreferredSpecialtyListFragment'])}
  }
`;

export default AccountPreferredSpecialties;
