import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useOfferCandidateDeleteOtherFile } from '@domain/offer';

import { toViewModel } from '../view-models/delete-offer-candidate-other-file.vm';

const useDeleteCandidateOfferOtherFile = () => {
  const { data, fetching, error, deleteOfferCandidateOtherFile } =
    useOfferCandidateDeleteOtherFile();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    offerCandidateDeleteOtherFile: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      modifier: 'alert-error',
      action: t('deleteOtherFileError'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    viewModel,
    deleteOfferCandidateOtherFile,
  };
};

export default useDeleteCandidateOfferOtherFile;
