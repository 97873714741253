import { useTranslation } from 'react-i18next';

import DashboardWelcomeImage from '@application/assets/dashboard-welcome.svg?react';

type HeaderProps = {
  userName: string;
  isEnterpriseAccount: boolean;
};

const Header = ({ userName, isEnterpriseAccount }: HeaderProps) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="flex w-full items-center">
      <DashboardWelcomeImage />

      <div className="w-full ml-s-24">
        <h1 className="text-24 font-semibold">
          {t('header.welcome', {
            userName,
          })}
        </h1>
        <h2 className="text-18 font-semibold">{t('header.dashboard')}</h2>
        <span className="text-16">
          {isEnterpriseAccount ? t('header.enterprise') : t('header.agency')}
        </span>
      </div>
    </div>
  );
};

export default Header;
