import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryList } from '@application/components/summary';
import { RecruitmentOpportunity } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type NotesProps = {
  data: RecruitmentOpportunity | undefined;
  isInOpportunityOffer: boolean;
};

const Notes = ({ data, isInOpportunityOffer }: NotesProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });

  return (
    <SummaryBlock
      className={cn('p-s-0', { 'border-0': isInOpportunityOffer })}
      headerTitleClassName={cn('mt-s-16', {
        '!border-0 !rounded-none': isInOpportunityOffer,
      })}
      headerTitle={t('subtitles.others')}
    >
      <SummaryList
        className="!mt-s-0"
        density="default"
        isCollapsable
        space={0}
        data={[
          {
            text: t('labels.generalNotes'),
            description: data?.generalNotes || '-',
          },
          {
            text: t('labels.jobAdditionalNotes'),
            description: data?.jobAdditionalNotes || '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default Notes;
