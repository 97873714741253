/* eslint jsx-a11y/control-has-associated-label: 0 */
import { cn } from '@utils/lib-utils';

import { TH_COL_CLASS_NAME } from '../constants';

type TableHeaderProps = {
  name?: string;
};

const TableHeader = ({ name }: TableHeaderProps) => (
  <thead>
    <tr>
      <td className="sr-only">&nbsp;</td>
      <th className={cn(TH_COL_CLASS_NAME, '!border-l-0')}>{name}</th>
    </tr>
  </thead>
);

export default TableHeader;
