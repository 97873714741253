import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from '@application/components/modal';
import { ModalContext } from '@application/context';
import {
  AccountOperationTerritoriesFilters,
  AccountOperationTerritoriesSortBy,
  AccountOperationTerritoriesSortDirective,
  AccountOperationTerritory,
  SortDirection,
} from '@domain/graphql.types';

import { flattenEdges } from '../../../../../utils/data-utils';
import {
  extractLanguage,
  getLocalizedDescription,
} from '../../../../../utils/i18n-utils';
import { IconButton, SwitchField } from '../../../../components';
import { usePagination } from '../../../../hooks';
import useDeleteAccountOperationTerritory from './useDeleteAccountOperationTerritory';
import useGetAccountOperationTerritories from './useGetAccountOperationTerritories';
import useUpdateAccountOperationTerritory from './useUpdateAccountOperationTerritory';

const columnIds = {
  description: 'description',
  active: 'active',
  actions: 'actions',
} as const;

const columnHelper = createColumnHelper<AccountOperationTerritory>();

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>,
  confirmAccountOperationTerritoryDeletion: (id: string) => () => void,
  handleAccountOperationTerritoryUpdate: (
    id: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void
) => [
  columnHelper.accessor((row) => row.operationTerritory, {
    id: columnIds.description,
    cell: (info) => (
      <span>
        {getLocalizedDescription(info.getValue()?.descriptions, language)}
      </span>
    ),
    header: () => t('column.operationTerritory'),
    size: 350,
    minSize: 250,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.active, {
    id: columnIds.active,
    cell: (info) => (
      <SwitchField
        name={info.row.original.id}
        className="justify-between w-s-120"
        label={info.getValue() ? t('active') : t('inactive')}
        checked={Boolean(info.getValue())}
        onChange={handleAccountOperationTerritoryUpdate(info.row.original.id)}
      />
    ),
    header: () => t('column.status'),
    size: 250,
    minSize: 150,
    maxSize: undefined,
    enableSorting: false,
  }),

  columnHelper.accessor((row) => row.id, {
    id: columnIds.actions,
    cell: (info) => (
      <IconButton
        text={tGlobal('button.delete')}
        className="animate-none"
        icon={<i className="ri-delete-bin-line text-20" />}
        outline
        size="x-small"
        onClick={confirmAccountOperationTerritoryDeletion(info.getValue())}
      />
    ),
    header: () => null,
    size: 32,
    minSize: 32,
    maxSize: 32,
    enableSorting: false,
  }),
];

const stringSortToEnum = (
  by: string,
  language: string
): AccountOperationTerritoriesSortBy => {
  const extractedLanguage = extractLanguage(language);
  switch (by) {
    case columnIds.description:
      return extractedLanguage === 'en'
        ? AccountOperationTerritoriesSortBy.OperationTerritoryEn
        : AccountOperationTerritoriesSortBy.OperationTerritoryFr;
    default:
      return extractedLanguage === 'en'
        ? AccountOperationTerritoriesSortBy.OperationTerritoryEn
        : AccountOperationTerritoriesSortBy.OperationTerritoryFr;
  }
};

const mapSorting = (
  sorting: SortingState,
  language: string
): AccountOperationTerritoriesSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id, language),
  }));

type AccountOperationTerritoriesList = {
  accountOperationTerritories: AccountOperationTerritory[];
  columnIds: typeof columnIds;
  columns: ColumnDef<AccountOperationTerritory>[];
  count?: number;
  currentPage?: PaginationState;
  isLoading: boolean;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  onSortingChange: OnChangeFn<SortingState>;
  sorting: SortingState;
};

export const useAccountOperationTerritoriesList = (
  filters?: AccountOperationTerritoriesFilters
): AccountOperationTerritoriesList => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: columnIds.description, desc: false },
  ]);

  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'accountOperationTerritories.list',
  });
  const { t: tGlobal } = useTranslation();

  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();

  const {
    viewModel: { data, isLoading },
    refetchAccountOperationTerritories,
  } = useGetAccountOperationTerritories(
    mapSorting(sorting, i18n.language),
    filters,
    pagination
  );

  useEffect(() => {
    setCursors({
      startCursor:
        data?.accountOperationTerritories.page.pageInfo?.startCursor ||
        undefined,
      endCursor:
        data?.accountOperationTerritories.page.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.accountOperationTerritories.page.pageInfo]);

  const { deleteAccountOperationTerritory } =
    useDeleteAccountOperationTerritory();

  const { updateAccountOperationTerritory } =
    useUpdateAccountOperationTerritory();

  const { setModal } = useContext(ModalContext);

  const handleDeleteAccountOperationTerritory = useCallback(
    (accountOperationTerritoryId: string) => async () => {
      setModal(null);

      const result = await deleteAccountOperationTerritory({
        input: { accountOperationTerritoryId },
      });

      if (result.data?.accountOperationTerritoryDelete.ok) {
        refetchAccountOperationTerritories();
      }
    },
    [
      deleteAccountOperationTerritory,
      refetchAccountOperationTerritories,
      setModal,
    ]
  );

  const confirmAccountOperationTerritoryDeletion = useCallback(
    (accountOperationTerritoryId: string) => () => {
      setModal({
        title: t('deleteOperationTerritoryTitle'),
        content: (
          <ConfirmationModal
            content={t('messages.deleteOperationTerritory')}
            onCancel={() => setModal(null)}
            onConfirm={handleDeleteAccountOperationTerritory(
              accountOperationTerritoryId
            )}
          />
        ),
      });
    },
    [setModal, t, handleDeleteAccountOperationTerritory]
  );

  const handleUpdateActive = useCallback(
    (accountOperationTerritoryId: string) =>
      async (event: ChangeEvent<HTMLInputElement>) => {
        await updateAccountOperationTerritory({
          input: {
            id: accountOperationTerritoryId,
            active: event.target.checked,
          },
        });
      },
    [updateAccountOperationTerritory]
  );

  const columns = getColumns(
    i18n.language,
    t,
    tGlobal,
    confirmAccountOperationTerritoryDeletion,
    handleUpdateActive
  );

  return {
    accountOperationTerritories: flattenEdges(
      data?.accountOperationTerritories.page.edges?.slice() || []
    ),
    columnIds,
    columns: columns as ColumnDef<AccountOperationTerritory>[],
    count: data?.accountOperationTerritories.pageData?.count,
    currentPage,
    isLoading,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    onSortingChange: setSorting,
    sorting,
  };
};
