import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { PageLoader } from '@application/components';
import { useLanguage } from '@application/hooks';

const Home = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { auth0CurrentLanguage } = useLanguage();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          ui_locales: auth0CurrentLanguage,
        },
      });
    }
  }, [loginWithRedirect, auth0CurrentLanguage, isAuthenticated]);

  return <PageLoader />;
};
export default Home;
