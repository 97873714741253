import { useMutation } from 'urql';

import { Mutation, MutationOffersRejectArgs } from '@domain/graphql.types';
import { MutationOffersReject } from '@infrastructure/graphql/offer';

const useOffersReject = () => {
  const [{ data, fetching, error }, rejectOffers] = useMutation<
    Pick<Mutation, 'offersReject'>,
    MutationOffersRejectArgs
  >(MutationOffersReject);

  return {
    rejectOffers,
    data,
    fetching,
    error,
  };
};

export default useOffersReject;
