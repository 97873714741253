import { useMutation } from 'urql';

import { Mutation, MutationUserUpdateAvatarArgs } from '@domain/graphql.types';
import { UserUpdateAvatar } from '@infrastructure/graphql/user';

const useUserUpdateAvatar = () => {
  const [{ data, fetching, error }, updateUserAvatar] = useMutation<
    Pick<Mutation, 'userUpdateAvatar'>,
    MutationUserUpdateAvatarArgs
  >(UserUpdateAvatar);

  return {
    updateUserAvatar,
    data,
    fetching,
    error,
  };
};

export default useUserUpdateAvatar;
