import React, { useCallback, useMemo, useState } from 'react';

import { OnboardingStep } from '../types';
import SpecialtiesOnboardingContext from './SpecialtiesOnboardingContext';

type SpecialtiesOnboardingProviderProps = {
  children: React.ReactNode;
};

const SpecialtiesOnboardingProvider = ({
  children,
}: SpecialtiesOnboardingProviderProps) => {
  const [step, setStep] = useState(OnboardingStep.INDUSTRIES);

  const nextStep = useCallback(() => {
    if (step !== OnboardingStep.SPECIALTIES) {
      setStep(step + 1);
    }
  }, [step, setStep]);

  const previousStep = useCallback(() => {
    if (step !== OnboardingStep.INDUSTRIES) {
      setStep(step - 1);
    }
  }, [step, setStep]);

  const value = useMemo(
    () => ({
      step,
      nextStep,
      previousStep,
    }),
    [step, nextStep, previousStep]
  );

  return (
    <SpecialtiesOnboardingContext.Provider value={value}>
      {children}
    </SpecialtiesOnboardingContext.Provider>
  );
};

export default React.memo(SpecialtiesOnboardingProvider);
