import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Checkbox,
  Fieldset,
  HelperText,
  RadioGroup,
  SectionGridRow,
} from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import { BOOLEAN_VALUES } from '@application/constants';
import { RequestTypeCode } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { Section } from '../../components';
import { RequestFormFields } from '../../schema';

type HRNeedsFieldsProps = {
  control: Control<RequestFormFields, any>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const HRNeedsFields = ({
  control,
  setValue,
  trigger,
  watch,
}: HRNeedsFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const types = watch('types') || [];

  const openToInternationalRecruitment = watch(
    'openToInternationalRecruitment'
  );
  const proposeCandidates = watch('conditionCandidateInHandMandatoryValue');

  // We just wanted to change the display without changing anything else, so this is a good enough solution for now.
  const handleOpenToInternationalRecruitmentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: keyof RequestFormFields
  ): void => {
    setValue(name, event.target.value === 'true');
    trigger(name);
    if (event.target.value === 'true') {
      setValue('types', [...types, RequestTypeCode.InternationalRecruitment]);
    } else {
      setValue('types', [
        ...types.filter(
          (type) => type !== RequestTypeCode.InternationalRecruitment
        ),
      ]);
    }
    trigger('types');
  };

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: keyof RequestFormFields
  ): void => {
    if (event.target.checked) {
      setValue('types', [...types, event.target.value as RequestTypeCode]);
    } else {
      setValue('types', [...types.filter((e) => e !== event.target.value)]);
    }
    trigger(name);
  };

  return (
    <Section legend={t('subtitles.hrNeed')} padding={0}>
      <SectionGridRow isTopRow className="p-s-24">
        <Controller
          name="types"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <Fieldset
              legend={t('subtitles.needType')}
              legendSize="small"
              legendClassName="pb-s-16"
              invalid={!!error}
            >
              <Cluster space={16} as="ul">
                {Object.values(RequestTypeCode)
                  .filter(
                    (type) => type !== RequestTypeCode.InternationalRecruitment
                  )
                  .map((type) => (
                    <li key={type}>
                      <Checkbox
                        name={name}
                        value={type}
                        label={t(`enum.requestTypeCode.${type.toLowerCase()}`)}
                        onChange={(e) =>
                          handleOnChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={types?.includes(type)}
                        asButton
                      />
                    </li>
                  ))}
              </Cluster>

              {error?.message && (
                <HelperText id="types-error" invalid>
                  {tGlobal(error?.message)}
                </HelperText>
              )}
            </Fieldset>
          )}
        />
      </SectionGridRow>
      <SectionGridRow className="p-s-24">
        <Controller
          name="openToInternationalRecruitment"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legendSize="medium"
              legend={
                <Trans i18nKey="labels.openToInternationalRecruitment" t={t} />
              }
              legendClassName="inline"
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleOpenToInternationalRecruitmentChange(event, name);
              }}
              alignRow
              value={openToInternationalRecruitment ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />
      </SectionGridRow>

      <SectionGridRow className="p-s-24">
        <Controller
          name="conditionCandidateInHandMandatoryValue"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.mustProposeCandidates')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value === 'true');
                trigger(name);
              }}
              legendSize="medium"
              alignRow
              value={proposeCandidates ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />
      </SectionGridRow>
    </Section>
  );
};

export default HRNeedsFields;
