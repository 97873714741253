import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoContent, Table } from '@application/components/table';
import { RecruitmentOpportunitiesFilters } from '@domain/graphql.types';

import { FiltersFields } from './components';
import { useRecruitmentFilters } from './hooks';
import { useRecruitmentOpportunitiesList } from './hooks/useRecruitmentOpportunitiesList';

const RecruitmentOpportunitiesListPage = () => {
  const [tableFilters, setTableFilters] =
    useState<RecruitmentOpportunitiesFilters>();

  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });

  const {
    columns,
    currentPage,
    recruitmentOpportunities,
    count,
    onPaginationChange,
    onPaginationReset,
    onSortingChange,
    sorting,
    isLoading,
  } = useRecruitmentOpportunitiesList(tableFilters);

  const { filters, updateSelectFilter, updateTextFilter } =
    useRecruitmentFilters({
      resetPagination: onPaginationReset,
    });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="grow w-full mb-s-16">
        <FiltersFields
          updateTextFilter={updateTextFilter}
          updateSelectFilter={updateSelectFilter}
        />
      </div>

      <Table
        data={recruitmentOpportunities}
        columns={columns}
        count={count}
        paginationState={currentPage}
        onPaginationChange={onPaginationChange}
        onPaginationReset={onPaginationReset}
        isRowColorChangeOnHover
        noDataComponent={<NoContent title={t(`list.noContent`)} />}
        sortingState={sorting}
        onSortingChange={onSortingChange}
        isLoading={isLoading}
      />
    </>
  );
};

export default RecruitmentOpportunitiesListPage;
