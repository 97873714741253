import { SummaryBlock, SummaryList } from '@application/components/summary';

type MessageDisplayProps = {
  label: string;
  text?: string;
};

const MessageDisplay = ({ label, text }: MessageDisplayProps) => (
  <SummaryBlock className="mt-s-16">
    <SummaryList
      className="!mt-s-0"
      density="default"
      data={[
        {
          text: label,
          description: text || '-',
        },
      ]}
    />
  </SummaryBlock>
);

export default MessageDisplay;
