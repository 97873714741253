import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import {
  Offer,
  OfferOutsourcingConditions,
  Request,
  RequestConditionsOutsourcingResponsibility,
  RequestOutsourcingConditions,
} from '@domain/graphql.types';

type OutsourcingConditionsProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const OUTSOURCING_CONDITIONS = [
  {
    key: 'exclusivityPeriod',
    label: 'outsourcingExclusivityPeriod',
    valueType: 'weeks',
  },
  {
    key: 'maximumBudget',
    label: 'maxBudget',
    valueType: 'currencyPerHour',
  },
  {
    key: 'maximumDuration',
    label: 'maxMandateDuration',
    valueType: 'weeks',
  },
];

const OutsourcingConditions = ({
  data,
  request,
}: OutsourcingConditionsProps) => {
  const { t } = useTranslation('requests');

  const getContentValue = useCallback(
    (
      type: string,
      value:
        | string
        | number
        | boolean
        | readonly RequestConditionsOutsourcingResponsibility[]
        | null
        | undefined
    ) => {
      switch (type) {
        case 'weeks':
          return value ? t('suffix.week', { count: Number(value) }) : '-';
        case 'currencyPerHour':
          return value ? `${value}${t('suffix.currencyPerHour')}` : '-';
        default:
          return '-';
      }
    },
    [t]
  );

  return (
    <>
      {OUTSOURCING_CONDITIONS.map(
        (
          condition: { key: string; label: string; valueType: string },
          index
        ) => (
          <SectionGridRow
            key={condition.key}
            isTopRow={index === 0}
            isOffer
            rightChildren={
              <PlaceholderSwitcher
                value={
                  request?.outsourcingContractualConditions?.[
                    `${condition.key}Value` as keyof RequestOutsourcingConditions
                  ]
                }
              >
                <span className="text-16 font-semibold">
                  {getContentValue(
                    condition.valueType,
                    request?.outsourcingContractualConditions?.[
                      `${condition.key}Value` as keyof RequestOutsourcingConditions
                    ]
                  )}
                </span>
              </PlaceholderSwitcher>
            }
          >
            <span className="mr-s-8">{t(`labels.${condition.label}`)}</span>

            <PlaceholderSwitcher
              value={
                data?.outsourcingContractualConditions?.[
                  condition.key as keyof OfferOutsourcingConditions
                ]
              }
            >
              <span className="text-18 font-semibold">
                {getContentValue(
                  condition.valueType,
                  data?.outsourcingContractualConditions?.[
                    condition.key as keyof OfferOutsourcingConditions
                  ]
                )}
              </span>
            </PlaceholderSwitcher>
          </SectionGridRow>
        )
      )}

      <SectionGridRow isOffer className="col-span-2">
        <span className="mr-s-8">
          {t(`labels.recruitmentResponsibilities`)}
        </span>

        <span className="text-18 font-semibold">
          {data?.outsourcingContractualConditions?.responsibilities || '-'}
        </span>
      </SectionGridRow>
    </>
  );
};

export default OutsourcingConditions;
