import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import { RequestListFragment } from './fragment';

const Requests = gql`
  ${RequestListFragment}

  query Requests(
    $filterBy: RequestsFilters,
    $sortBy: [RequestsSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    requests(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['RequestListFragment'])}
  }
`;

export default Requests;
