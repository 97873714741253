import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateNegotiationRoomAcceptValuesArgs,
} from '@domain/graphql.types';
import { MutationCandidateNegotiationRoomAcceptValues } from '@infrastructure/graphql/candidate';

const useCandidateNegotiationRoomAcceptValues = () => {
  const [{ data, fetching, error }, acceptNegotiationRoomValues] = useMutation<
    Pick<Mutation, 'candidateNegotiationRoomAcceptValues'>,
    MutationCandidateNegotiationRoomAcceptValuesArgs
  >(MutationCandidateNegotiationRoomAcceptValues);

  return {
    acceptNegotiationRoomValues,
    data,
    fetching,
    error,
  };
};

export default useCandidateNegotiationRoomAcceptValues;
