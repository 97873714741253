import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RankBadge } from '@application/components';
import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { RecruitmentNegotiationContext } from '../../context';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../constants';
import {
  GeneralConditions,
  HeadHunterConditions,
  InternationalRecruitmentConditions,
  OutsourcingConditions,
  ResumeLink,
  TemporaryPlacementConditions,
} from './rows';

const TableBody = () => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const { rooms, checkedRooms } = useContext(RecruitmentNegotiationContext);

  return (
    <tbody>
      <tr>
        <th className={TH_ROW_CLASS_NAME.base}>{t('rank')}</th>
        {rooms?.map(
          ({ id, negotiatingOffer: { rank } }: RecruitmentNegotiationRoom) => (
            <td
              aria-label={t('information.rank')}
              key={`rank-${id}`}
              className={cn(TD_CLASS_NAME.base, {
                'border-x-2 !border-x-main': checkedRooms.includes(id),
              })}
            >
              {rank ? <RankBadge rank={rank} /> : undefined}
            </td>
          )
        )}
      </tr>

      <ResumeLink />

      <GeneralConditions />

      <HeadHunterConditions />

      <TemporaryPlacementConditions />

      <InternationalRecruitmentConditions />

      <OutsourcingConditions />
    </tbody>
  );
};

export default TableBody;
