import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { Stack } from '@application/components/container-layouts';

import { TRANSLATION_PREFIX_KEYS } from '../constants';
import type { CreateAccountFormFields } from '../schema';

type CompanyInfoProps = {
  type: 'ENTERPRISE' | 'AGENCY';
};

const CompanyInfo = ({ type }: CompanyInfoProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('signup', {
    keyPrefix: `${TRANSLATION_PREFIX_KEYS[type]}.form`,
  });

  const {
    formState: { errors },
    register,
  } = useFormContext<CreateAccountFormFields>();

  return (
    <fieldset>
      <legend className="mb-s-16 text-20 font-semibold">
        {t('companyInfo.heading')}
      </legend>

      <Stack>
        <TextInputField
          maxChar={128}
          label={t('companyInfo.companyName.label')}
          invalid={!!errors.name}
          helperText={
            errors.name?.message &&
            tGlobal(errors.name?.message, {
              max: '128',
            })
          }
          {...register('name')}
        />
        <TextInputField
          maxChar={128}
          label={t('companyInfo.website.label')}
          invalid={!!errors.website}
          helperText={
            errors.website?.message &&
            tGlobal(errors.website?.message, {
              max: '128',
            })
          }
          withIcon="ri-global-line"
          {...register('website')}
        />

        <TextInputField
          label={t('companyInfo.phoneNumber.label')}
          type="tel"
          invalid={!!errors.phoneNumber}
          helperText={
            errors.phoneNumber?.message && tGlobal(errors.phoneNumber?.message)
          }
          {...register('phoneNumber')}
        />
      </Stack>
    </fieldset>
  );
};

export default CompanyInfo;
