import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import {
  RecruitmentNegotiation,
  RecruitmentNegotiationResponse,
} from '@domain/graphql.types';

type ToViewModel = {
  recruitmentNegotiation: {
    data: RecruitmentNegotiationResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  recruitmentNegotiation: { data, fetching, error },
}: ToViewModel): ViewModel<RecruitmentNegotiation | undefined> =>
  new ViewModel<RecruitmentNegotiation | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.negotiation || undefined,
  });
