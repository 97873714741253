/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components/switch';
import {
  CandidateNegotiationRoom,
  CandidateRequestType,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

type OfferToRecruiterProps = {
  room: CandidateNegotiationRoom;
  checked?: boolean;
  rounded?: boolean;
  type?: CandidateRequestType;
};

const OfferToRecruiter = ({
  checked,
  room,
  rounded,
  type,
}: OfferToRecruiterProps) => {
  const { t } = useTranslation('candidates');

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('labels.offerToRecruiter')}
        </th>

        <td
          className={cn(TH_ROW_CLASS_NAME.highlight, 'border-l-1 border-b-1', {
            'border-x-2 !border-x-main': checked,
          })}
        >
          &nbsp;
        </td>
      </tr>

      {type === CandidateRequestType.Recruitment ? (
        <>
          <tr>
            <th
              scope="col"
              className={cn(TH_ROW_CLASS_NAME.base, 'bg-disabled-light')}
            >
              {t('labels.proposedFees')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'bg-disabled-light', {
                'border-x-2 !border-x-main': checked,
              })}
            >
              <PlaceholderSwitcher
                value={
                  room.negotiatingCandidateOffer.recruitmentConditions?.budget
                }
              >
                {t('suffix.currency', {
                  value:
                    room.negotiatingCandidateOffer.recruitmentConditions
                      ?.budget,
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.additionalBonus')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'whitespace-nowrap', {
                'border-x-2 !border-x-main': checked,
              })}
            >
              <PlaceholderSwitcher
                value={
                  room.negotiatingCandidateOffer?.recruitmentConditions?.bonus
                }
              >
                {t('suffix.currency', {
                  value:
                    room.negotiatingCandidateOffer?.recruitmentConditions
                      ?.bonus,
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.guaranteeRequested')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'whitespace-nowrap', {
                'border-x-2 !border-x-main': checked,
              })}
            >
              <PlaceholderSwitcher
                value={
                  room.negotiatingCandidateOffer?.recruitmentConditions
                    ?.guaranteedPeriod
                }
              >
                {t('suffix.month', {
                  count: Number(
                    room.negotiatingCandidateOffer?.recruitmentConditions
                      ?.guaranteedPeriod
                  ),
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th
              scope="col"
              className={cn(TH_ROW_CLASS_NAME.base, 'rounded-bl-md')}
            >
              {t('labels.paymentMethods')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                'border-b-0 whitespace-nowrap text-ellipsis overflow-hidden',
                {
                  'border-x-2 !border-x-main border-b-2 border-b-main': checked,
                  '[&:last-child]:rounded-br-md': checked && rounded,
                }
              )}
            >
              <PlaceholderSwitcher
                value={
                  room.negotiatingCandidateOffer?.recruitmentConditions
                    ?.paymentMethod
                }
              >
                {t(
                  `enum.requestConditionPaymentMethodCode.${room.negotiatingCandidateOffer?.recruitmentConditions?.paymentMethod?.toLowerCase()}`
                )}
              </PlaceholderSwitcher>
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.desiredBillingPeriod')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                'whitespace-nowrap text-ellipsis overflow-hidden',
                {
                  'border-x-2 !border-x-main': checked,
                }
              )}
            >
              {type === CandidateRequestType.TemporaryPlacement ? (
                <PlaceholderSwitcher
                  value={
                    room.negotiatingCandidateOffer?.temporaryPlacementConditions
                      ?.billingPeriod
                  }
                >
                  {t(
                    `enum.candidateConditionBillingPeriod.${room.negotiatingCandidateOffer?.temporaryPlacementConditions?.billingPeriod?.toLowerCase()}`
                  )}
                </PlaceholderSwitcher>
              ) : (
                <PlaceholderSwitcher
                  value={
                    room.negotiatingCandidateOffer?.outsourcingConditions
                      ?.billingPeriod
                  }
                >
                  {t(
                    `enum.candidateConditionBillingPeriod.${room.negotiatingCandidateOffer?.outsourcingConditions?.billingPeriod?.toLowerCase()}`
                  )}
                </PlaceholderSwitcher>
              )}
            </td>
          </tr>

          <tr>
            <th
              scope="col"
              className={cn(TH_ROW_CLASS_NAME.base, {
                'rounded-bl-md': type === CandidateRequestType.Outsourcing,
              })}
            >
              {t('labels.minimumNotice')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, {
                'border-b-0': type === CandidateRequestType.Outsourcing,
                'border-x-2 !border-x-main': checked,
                'border-b-2 border-b-main [&:last-child]:rounded-br-md':
                  checked && type === CandidateRequestType.Outsourcing,
              })}
            >
              {type === CandidateRequestType.TemporaryPlacement ? (
                <PlaceholderSwitcher
                  value={
                    room.negotiatingCandidateOffer?.temporaryPlacementConditions
                      ?.minimumNotice
                  }
                >
                  {t('suffix.week', {
                    count: Number(
                      room.negotiatingCandidateOffer
                        ?.temporaryPlacementConditions?.minimumNotice
                    ),
                  })}
                </PlaceholderSwitcher>
              ) : (
                <PlaceholderSwitcher
                  value={
                    room.negotiatingCandidateOffer?.outsourcingConditions
                      ?.minimumNotice
                  }
                >
                  {t('suffix.week', {
                    count: Number(
                      room.negotiatingCandidateOffer?.outsourcingConditions
                        ?.minimumNotice
                    ),
                  })}
                </PlaceholderSwitcher>
              )}
            </td>
          </tr>

          {type === CandidateRequestType.TemporaryPlacement && (
            <tr>
              <th
                scope="col"
                className={cn(TH_ROW_CLASS_NAME.base, 'rounded-bl-md')}
              >
                {t('labels.possibleBuybackBonus')}
              </th>

              <td
                className={cn(TD_CLASS_NAME.base, 'border-b-0', {
                  'border-x-2 !border-x-main border-b-2 border-b-main [&:last-child]:rounded-br-md':
                    checked,
                })}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingCandidateOffer?.temporaryPlacementConditions
                      ?.minimumNotice
                  }
                >
                  {t('suffix.week', {
                    count: Number(
                      room.negotiatingCandidateOffer
                        ?.temporaryPlacementConditions?.minimumNotice
                    ),
                  })}
                </PlaceholderSwitcher>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default OfferToRecruiter;
