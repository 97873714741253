import {
  RecruitmentNegotiation,
  RecruitmentNegotiationsFilters,
} from '@domain/graphql.types';

import useGetRecruitmentNegotiations from './useGetRecruitmentNegotiations';

type UseRecruitmentNegotiationsListProps = {
  count?: number;
  recruitmentNegotiations: RecruitmentNegotiation[];
  isLoading: boolean;
};

const useRecruitmentNegotiationsList = (
  filters?: RecruitmentNegotiationsFilters
): UseRecruitmentNegotiationsListProps => {
  const {
    viewModel: { data, isLoading },
  } = useGetRecruitmentNegotiations(filters);

  return {
    count: data?.recruitmentNegotiations.totalCount,
    recruitmentNegotiations:
      data?.recruitmentNegotiations.results.slice() || [],
    isLoading,
  };
};

export default useRecruitmentNegotiationsList;
