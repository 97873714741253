import { ReactNode } from 'react';

export type AccordionSummaryProps = {
  /**
   * Specify the id of the `<AccordionSummary>` container element
   */
  id: string;
  /**
   * Specify content elements of the `<AccordionSummary>`
   */
  children: ReactNode;
};

const AccordionSummary = ({ id, children }: AccordionSummaryProps) => (
  <div
    id={id}
    className="collapse-title p-s-24 pr-s-48 [&&::after]:right-s-32 leading-loose"
  >
    {children}
  </div>
);

export default AccordionSummary;
