import { gql } from 'urql';

const AccountOperationTerritoriesCreate = gql`
  mutation AccountOperationTerritoriesCreate(
    $input: AccountOperationTerritoriesCreateInput!
  ) {
    accountOperationTerritoriesCreate(input: $input) {
      accountOperationTerritories {
        id
      }
    }
  }
`;

export default AccountOperationTerritoriesCreate;
