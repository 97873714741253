import { gql } from 'urql';

const RequestOutsourcingConditionsFragment = gql`
  fragment RequestOutsourcingConditionsFragment on RequestOutsourcingConditions {
    maximumDurationValue
    maximumDurationDisplay
    exclusivityPeriodValue
    exclusivityPeriodDisplay
    maximumBudgetValue
    maximumBudgetDisplay
    responsibilities
  }
`;

export default RequestOutsourcingConditionsFragment;
