import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useRecruitmentNegotiationRoomReject } from '@domain/negotiation';

import { toViewModel } from '../view-models/reject-recruitment-negotiation-room.vm';

const useRejectRecruitmentNegotiationRoom = () => {
  const { data, fetching, error, rejectNegotiationRoom } =
    useRecruitmentNegotiationRoomReject();

  const { t } = useTranslation('recruitment', {
    keyPrefix: 'messages',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    room: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('rejectNegotiationRoomError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.recruitmentNegotiationRoomReject.room) {
      setAlertContent({
        action: t('rejectNegotiationRoomSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { rejectNegotiationRoom, viewModel };
};

export default useRejectRecruitmentNegotiationRoom;
