import { components, NoticeProps } from 'react-select';

import { OptionType } from '../types';

const NoOptionsMessage = ({ children, ...props }: NoticeProps<OptionType>) => (
  <components.NoOptionsMessage {...props}>
    <div className="py-s-16 text-center text-neutral-secondary font-medium">
      <p>{children}</p>
    </div>
  </components.NoOptionsMessage>
);

export default NoOptionsMessage;
