import { gql } from '@urql/core';

const MutationOfferCandidateUpdateCV = gql`
  mutation OfferCandidateUpdateCV(
    $input: OfferCandidateCVInput!
    $files: [Upload!]!
  ) {
    offerCandidateUpdateCV(input: $input, files: $files) {
      ok
    }
  }
`;

export default MutationOfferCandidateUpdateCV;
