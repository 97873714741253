import { ElementType } from 'react';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Fieldset,
  SectionContainer,
  TextInputField,
} from '@application/components';
import {
  Box,
  Stack,
  Switcher,
} from '@application/components/container-layouts';

import { SocialNetworkFormFields } from './types';

type SocialNetworksProps<T extends SocialNetworkFormFields> = {
  errors: FieldErrors<SocialNetworkFormFields>;
  register: UseFormRegister<T>;
  titleAs?: ElementType;
  disabled?: boolean;
  className?: string;
};

const SocialNetworksSection = <T extends SocialNetworkFormFields>({
  register,
  errors,
  titleAs = 'h2',
  disabled = false,
  className: customClassName,
}: SocialNetworksProps<T>) => {
  const { t } = useTranslation();

  return (
    <SectionContainer
      titleAs={titleAs}
      title={t('socialNetworks.title')}
      className={customClassName}
    >
      <Box>
        <Fieldset
          legend={t('socialNetworks.legends.socialNetworkLinks')}
          hideLegend
        >
          <Stack space={24}>
            <Switcher space={24}>
              <TextInputField
                disabled={disabled}
                maxChar={128}
                label={t('socialNetworks.labels.facebook')}
                invalid={!!errors.facebookUrl}
                helperText={
                  errors.facebookUrl?.message &&
                  t(errors.facebookUrl?.message, { max: '128' })
                }
                withIcon="ri-facebook-circle-line"
                className="flex-grow"
                {...register('facebookUrl' as Path<T>)}
              />

              <TextInputField
                disabled={disabled}
                maxChar={128}
                label={t('socialNetworks.labels.instagram')}
                invalid={!!errors.instagramUrl}
                helperText={
                  errors.instagramUrl?.message &&
                  t(errors.instagramUrl?.message, { max: '128' })
                }
                withIcon="ri-instagram-line"
                className="flex-grow"
                {...register('instagramUrl' as Path<T>)}
              />
            </Switcher>

            <Switcher space={24}>
              <TextInputField
                disabled={disabled}
                maxChar={128}
                label={t('socialNetworks.labels.linkedIn')}
                invalid={!!errors.linkedinUrl}
                helperText={
                  errors.linkedinUrl?.message &&
                  t(errors.linkedinUrl?.message, { max: '128' })
                }
                withIcon="ri-linkedin-box-line"
                className="flex-grow"
                {...register('linkedinUrl' as Path<T>)}
              />

              <TextInputField
                disabled={disabled}
                maxChar={128}
                label={t('socialNetworks.labels.twitter')}
                invalid={!!errors.twitterUrl}
                helperText={
                  errors.twitterUrl?.message &&
                  t(errors.twitterUrl?.message, { max: '128' })
                }
                withIcon="ri-twitter-x-line"
                className="flex-grow"
                {...register('twitterUrl' as Path<T>)}
              />
            </Switcher>
          </Stack>
        </Fieldset>
      </Box>
    </SectionContainer>
  );
};

export default SocialNetworksSection;
