import { useTranslation } from 'react-i18next';

import Agreement from '@application/assets/agreement.svg?react';

type AgreementLayoutProps = {
  accountName?: string;
  emailTo?: string;
};

const AgreementLayout = ({ accountName, emailTo }: AgreementLayoutProps) => {
  const { t } = useTranslation();

  return (
    <div className="bg-content-action-light flex flex-col items-center justify-center w-full h-full p-s-32">
      <Agreement />

      <h1 className="text-64 mt-s-48 mb-s-8">
        {t('layout.agreement.agreement')}
      </h1>

      <p className="max-w-[25.875rem] text-center">
        {t('layout.agreement.content', { company: accountName })}
      </p>

      <p className="text-14 mt-s-32">
        {t('layout.agreement.didNotReceive')}&nbsp;
        <a href={`mailto:${emailTo}`} className="font-semibold underline">
          {t('layout.agreement.sendAgain')}
        </a>
      </p>

      <p className="font-bold mt-s-32">{emailTo}</p>
    </div>
  );
};

export default AgreementLayout;
