import { gql } from 'urql';

const OfferInternationalRecruitmentConditionsFragment = gql`
  fragment OfferInternationalRecruitmentConditionsFragment on OfferInternationalRecruitmentConditions {
    mandatoryWorkPermit
    exclusivityPeriod
    maximumReplacement
  }
`;

export default OfferInternationalRecruitmentConditionsFragment;
