import { gql } from '@urql/core';

const OperationUnitDelete = gql`
  mutation OperationUnitDelete($input: DeleteOperationUnitInput!) {
    operationUnitDelete(input: $input) {
      ok
    }
  }
`;

export default OperationUnitDelete;
