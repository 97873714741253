import { useMutation } from 'urql';

import {
  Mutation,
  MutationRecruitmentNegotiationCompleteArgs,
} from '@domain/graphql.types';
import { MutationRecruitmentNegotiationComplete } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiationComplete = () => {
  const [{ data, fetching, error }, completeRecruitmentNegotiation] =
    useMutation<
      Pick<Mutation, 'recruitmentNegotiationComplete'>,
      MutationRecruitmentNegotiationCompleteArgs
    >(MutationRecruitmentNegotiationComplete);

  return {
    completeRecruitmentNegotiation,
    data,
    fetching,
    error,
  };
};

export default useRecruitmentNegotiationComplete;
