import {
  RecruitmentOpportunitiesFilters,
  RecruitmentOpportunitiesSortDirective,
} from '@domain/graphql.types';
import { useRecruitmentOpportunities } from '@domain/opportunity';
import { Pagination } from '@domain/pagination/pagination';

import { toViewModel } from '../view-models/get-recruitment-opportunities.vm';

const useGetRecruitmentOpportunities = (
  sortBy: RecruitmentOpportunitiesSortDirective[],
  filterBy?: RecruitmentOpportunitiesFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } = useRecruitmentOpportunities(
    {
      sortBy,
      filterBy,
      ...pagination,
    }
  );

  const viewModel = toViewModel({
    recruitmentOpportunities: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRecruitmentOpportunities: reexecuteQuery,
  };
};

export default useGetRecruitmentOpportunities;
