import { useQuery } from 'urql';

import { Query } from '@domain/graphql.types';
import { JobPaymentOtherTypes } from '@infrastructure/graphql/job';

const useJobPaymentOtherTypesGet = () => {
  const [result] = useQuery<Pick<Query, 'jobPaymentOtherTypes'>, void>({
    query: JobPaymentOtherTypes,
    requestPolicy: 'cache-first',
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
  };
};

export default useJobPaymentOtherTypesGet;
