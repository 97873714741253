import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import Countdown from '@application/components/countdown/CountDown';

import useActionPromoModal from './useActionPromoModal';

interface ActionPromoButtonProps {
  endPromoDate: DateTime;
}

const ActionPromoButton = ({ endPromoDate }: ActionPromoButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.actionPromo',
  });

  const { openModal } = useActionPromoModal();

  return (
    <Button className="w-fit p-0" onClick={openModal}>
      <div className="flex flex-col">
        <span className="font-bold">{t('modalButton')}</span>
        <Countdown targetDate={endPromoDate} />
      </div>
    </Button>
  );
};

export default ActionPromoButton;
