import { ChangeEvent, useEffect, useState } from 'react';
import { MultiValue } from 'react-select';

import { OptionType } from '@application/components/select-field/select';
import { debounce } from '@application/utils';
import {
  AccountOperationTerritoriesFilters,
  OperationTerritoryCode,
} from '@domain/graphql.types';

type UseAccountOperationTerritoryFiltersProps = {
  resetPagination?: () => void;
};

const useAccountOperationTerritoryFilters = ({
  resetPagination,
}: UseAccountOperationTerritoryFiltersProps) => {
  const defaultFilters = {
    operationTerritoryCodes: undefined,
    text: undefined,
  };

  const [filters, setFilters] =
    useState<AccountOperationTerritoriesFilters>(defaultFilters);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateSelectOperationTerritoriesFilters = (
    values: MultiValue<OptionType>
  ) => {
    const filterValues = values.map(
      (v: OptionType) => v.value as OperationTerritoryCode
    );

    setFilters((currentFilters) => ({
      ...currentFilters,
      operationTerritoryCodes: filterValues,
    }));
  };

  const updateTextFilters = debounce((event: ChangeEvent<HTMLInputElement>) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }))
  );

  return {
    filters,
    updateTextFilters,
    updateSelectOperationTerritoriesFilters,
  };
};

export default useAccountOperationTerritoryFilters;
