import { OfferCandidate } from '@domain/graphql.types';

const getOfferCandidateName = (
  offerCandidate: OfferCandidate,
  placeholder?: string
): string => {
  const { firstName, lastName, firstNameDisplay, lastNameDisplay } =
    offerCandidate;

  if (firstNameDisplay && lastNameDisplay) {
    return `${firstName} ${lastName}`;
  }
  if (firstName && firstNameDisplay) {
    return firstName;
  }
  if (lastName && lastNameDisplay) {
    return lastName;
  }

  return placeholder || '-';
};

export { getOfferCandidateName };
