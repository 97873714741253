import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

import { CreateAccountFormFields } from '../schema';

type ToViewModel = {
  account: {
    data: Pick<Mutation, 'accountCreate'> | Record<string, never> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  account: { data = {}, fetching, error },
}: ToViewModel): ViewModel<CreateAccountFormFields> =>
  new ViewModel<CreateAccountFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data as unknown as CreateAccountFormFields,
  });
