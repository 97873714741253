import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { IconButton } from '@application/components/buttons';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type GeneralConditionsProps = {
  data: Request | undefined;
};

const GeneralConditions = ({ data }: GeneralConditionsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow
        isTopRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions.maxRecruitmentPeriodDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.duration')}</span>
        <PlaceholderSwitcher
          value={data?.generalContractualConditions.maxRecruitmentPeriodValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.generalContractualConditions.maxRecruitmentPeriodValue}
            &nbsp;
            {t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions
                    .candidateInHandMandatoryDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.mustProposeCandidates')}</span>
        <PlaceholderSwitcher
          value={
            data?.generalContractualConditions.candidateInHandMandatoryValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${!!data?.generalContractualConditions
                .candidateInHandMandatoryValue}`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions.probationPeriodDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.trialPeriodLength')}</span>
        <PlaceholderSwitcher
          value={data?.generalContractualConditions.probationPeriodValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.generalContractualConditions.probationPeriodValue}
            &nbsp;
            {t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions.paymentMethodDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.preferredPaymentMethod')}&nbsp;
            <PlaceholderSwitcher
              value={data?.generalContractualConditions.paymentMethodValue}
            >
              <span className="text-18 font-semibold text-primary">
                {t(
                  `enum.requestConditionPaymentMethodCode.${data?.generalContractualConditions.paymentMethodValue?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>
          </div>
          {data?.generalContractualConditions.paymentFrequencyValue && (
            <div className="mr-s-8 mt-s-16 flex">
              <span className="mr-s-8">
                {`${t('enum.requestConditionStartOfAgreementPaymentMethodCode.payment_frequency')}:`}
              </span>
              <span className="text-18 font-semibold text-primary">
                {t(
                  `enum.requestConditionPaymentFrequencyCode.${data?.generalContractualConditions.paymentFrequencyValue?.toLowerCase()}`
                )}
              </span>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions?.depositDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.depositPayment')}</span>
        <PlaceholderSwitcher
          value={data?.generalContractualConditions?.depositValue}
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal('suffix.currencyValue', {
              value: data?.generalContractualConditions?.depositValue,
            })}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions.budgetDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.BaselineBudget')}&nbsp;
            <PlaceholderSwitcher
              value={data?.generalContractualConditions.minimumBudgetValue}
            >
              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: data?.generalContractualConditions?.minimumBudgetValue,
                })}
              </span>
            </PlaceholderSwitcher>
          </div>

          {data?.generalContractualConditions?.maximumBudgetValue && (
            <div className="mr-s-8 mt-s-16 flex">
              <span>{t('labels.minBudget')}&nbsp;</span>

              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: data?.generalContractualConditions?.minimumBudgetValue,
                })}
              </span>

              <div className="divider divider-horizontal ml-s-8 mr-s-16 before:w-s-1 after:w-s-1" />

              <span>{t('labels.maxBudget')}&nbsp;</span>

              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: data?.generalContractualConditions?.maximumBudgetValue,
                })}
              </span>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions?.minGuaranteedPeriodDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <div className="flex flex-col">
          <span className="mr-s-8 mb-s-16">
            {t('labels.additionalBonusOffered')}
          </span>

          <div>
            <span className="mr-s-8">{t('labels.minGuaranteedPeriod')}</span>

            <PlaceholderSwitcher
              value={
                data?.generalContractualConditions?.minGuaranteedPeriodValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {data?.generalContractualConditions?.minGuaranteedPeriodValue}
                &nbsp;
                {t('suffix.months')}
              </span>
            </PlaceholderSwitcher>
          </div>

          <div className="mt-s-8">
            <span className="mr-s-8">
              {t('labels.minGuaranteedPeriodBonusValue')}
            </span>
            <PlaceholderSwitcher
              value={
                data?.generalContractualConditions
                  ?.minGuaranteedPeriodBonusValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value:
                    data?.generalContractualConditions
                      ?.minGuaranteedPeriodBonusValue,
                })}
              </span>
            </PlaceholderSwitcher>
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow
        partialBorderTop
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions?.bonusDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <div className="flex flex-col">
          <div>
            {data?.generalContractualConditions?.bonusMonthValue ? (
              <>
                {t('labels.ifCandidate')}
                <span className="text-18 ml-s-8 font-semibold text-primary">
                  {data?.generalContractualConditions?.bonusMonthValue}
                  &nbsp;
                  {t('suffix.months')}
                </span>
                <span className="ml-s-8">{t('labels.stayAfterHired')}</span>
              </>
            ) : (
              <>
                <span className="mr-s-8">
                  {t('labels.ifCandidate')}&nbsp;{t('labels.stayAfterHired')}:
                </span>
                <PlaceholderSwitcher
                  value={data?.generalContractualConditions?.bonusMonthValue}
                >
                  {data?.generalContractualConditions?.bonusMonthValue && (
                    <span className="text-18 font-semibold text-primary">
                      {data?.generalContractualConditions?.bonusMonthValue}
                      &nbsp;
                      {t('suffix.months')}
                    </span>
                  )}
                </PlaceholderSwitcher>
              </>
            )}

            <p className="mr-s-8 mt-s-8">
              {t('labels.additionalBonusOffered')}&nbsp;
              <PlaceholderSwitcher
                value={data?.generalContractualConditions?.bonusValue}
              >
                <span className="text-18 font-semibold text-primary">
                  {tGlobal('suffix.currencyValue', {
                    value: data?.generalContractualConditions?.bonusValue,
                  })}
                </span>
              </PlaceholderSwitcher>
            </p>
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow
        partialBorderTop
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.generalContractualConditions?.hiredDateBonusDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <div className="flex flex-col">
          <div>
            <span className="mr-s-8">{t('labels.ifHiredBefore')}</span>
            <PlaceholderSwitcher
              value={
                data?.generalContractualConditions?.hiredDateBonusRequirement
              }
            >
              <span className="text-18 mr-s-8 font-semibold text-primary">
                {formatDate(
                  data?.generalContractualConditions?.hiredDateBonusRequirement,
                  i18n.language
                )}
              </span>
            </PlaceholderSwitcher>
          </div>

          <p className="mr-s-8 mt-s-8">
            {t('labels.additionalBonusOffered')}&nbsp;
            <PlaceholderSwitcher
              value={data?.generalContractualConditions?.hiredDateBonusValue}
            >
              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value:
                    data?.generalContractualConditions?.hiredDateBonusValue,
                })}
              </span>
            </PlaceholderSwitcher>
          </p>
        </div>
      </SectionGridRow>
    </>
  );
};

export default GeneralConditions;
