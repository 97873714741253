import React, { useMemo, useState } from 'react';

import OperationUnitsListContext from './OperationUnitsListContext';

type OperationUnitsListProviderProps = {
  children: React.ReactNode;
};

const OperationUnitsListProvider = ({
  children,
}: OperationUnitsListProviderProps) => {
  const [shouldRefreshList, setShouldRefreshList] = useState(false);

  const value = useMemo(
    () => ({
      shouldRefreshList,
      setShouldRefreshList,
    }),
    [shouldRefreshList, setShouldRefreshList]
  );

  return (
    <OperationUnitsListContext.Provider value={value}>
      {children}
    </OperationUnitsListContext.Provider>
  );
};

export default React.memo(OperationUnitsListProvider);
