import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import ModalAction from './ModalAction';
import ModalBackdrop from './ModalBackdrop';
import ModalCloseBtn from './ModalCloseBtn';
import ModalTitle from './ModalTitle';

export type ModalMaxWidth = 'md' | 'lg' | 'xl' | '2xl' | '4xl' | 'fit';

export type ModalProps = {
  children: React.ReactNode | null;
  title: string;
  hideTitle?: boolean;
  maxWidth?: ModalMaxWidth;
  centered?: boolean;
};

const titleId = randomString();

/**
 * Note: Instead of using this component directly, use the `ModalContext`
 * already implemented in the application. To set an alert, import the `ModalContext`
 * and use the `setModal` function from the context to update the modal content.
 */
const Modal = ({
  title,
  hideTitle,
  centered,
  maxWidth = 'lg',
  children,
}: ModalProps) => {
  if (!children) return null;

  const maxWidthClassName = {
    'max-w-md': maxWidth === 'md',
    'max-w-lg': maxWidth === 'lg',
    'max-w-xl': maxWidth === 'xl',
    'max-w-2xl': maxWidth === '2xl',
    'max-w-4xl': maxWidth === '4xl',
    'max-w-fit': maxWidth === 'fit',
  };

  return (
    <div data-testid="TEST-modal">
      <ModalBackdrop />
      <dialog
        aria-labelledby={titleId}
        className="modal opacity-100 pointer-events-auto"
      >
        <div
          className={cn('modal-box rounded-md flex flex-col relative p-s-32', {
            'items-center text-center': centered,
            ...maxWidthClassName,
          })}
        >
          <ModalTitle
            id={titleId}
            className={cn({ 'sr-only': hideTitle, 'mx-s-32': centered })}
          >
            {title}
          </ModalTitle>

          {children}
        </div>
      </dialog>
    </div>
  );
};

Modal.Action = ModalAction;
Modal.CloseBtn = ModalCloseBtn;

export default Modal;
