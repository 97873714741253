import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@application/components/buttons';

type DisplayButtonProps<T extends FieldValues> = {
  name: keyof T;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  disabled?: boolean;
};

const DisplayButton = <T extends FieldValues>({
  name: fieldName,
  control,
  setValue,
  watch,
  disabled,
}: DisplayButtonProps<T>) => {
  const { t } = useTranslation('requests');

  const value = watch(fieldName as Path<T>);

  return (
    <Controller
      name={fieldName as Path<T>}
      control={control}
      render={({ field: { name } }) => (
        <IconButton
          ghost
          text={t('button.toggleVisibility')}
          icon={<i className={value ? 'ri-eye-fill' : 'ri-eye-off-line'} />}
          disabled={disabled}
          onClick={() =>
            setValue(name as Path<T>, !value as PathValue<T, Path<T>>)
          }
        />
      )}
    />
  );
};

export default DisplayButton;
