import { Crisp } from 'crisp-sdk-web';
import { useContext, useEffect } from 'react';

import { UserContext } from '@application/context';

const useCrisp = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID);
    }
  }, []);

  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      Crisp.user.setNickname(user?.name ?? '');
      Crisp.user.setEmail(user?.email ?? '');
      Crisp.user.setAvatar(user?.avatarUrl ?? '');
    }
  }, [user?.name, user?.email, user?.avatarUrl]);
};

export default useCrisp;
