import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { validate } from 'uuid';

import { RootPrivatePage } from '../enums/pagesUrl';

const useValidateIdParam = (id: string | undefined) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!(id && validate(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);
};

export default useValidateIdParam;
