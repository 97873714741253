import { useContext } from 'react';

import { AccountContext } from '@application/context';
import { AccountBillingSubscriptionType } from '@domain/graphql.types';

const useFreemiumPublishRequestValidation = () => {
  const { account } = useContext(AccountContext);

  const canPublishRequest = !(
    account?.subscriptionType ===
      AccountBillingSubscriptionType.EnterpriseFreemium &&
    account?.enterpriseSubscriptionInformations !== undefined &&
    account?.enterpriseSubscriptionInformations?.freemiumRemainingRequests === 0
  );

  return {
    canPublishRequest,
  };
};

export default useFreemiumPublishRequestValidation;
