import React, { ForwardedRef, MouseEvent, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

import { LoadingSpinner } from '../spinner';
import {
  GHOST_STYLES,
  OUTLINE_STYLES,
  PRIMARY_STYLES,
  SECONDARY_STYLES,
} from './constants';

type ButtonType = 'button' | 'submit' | 'reset';
type ButtonSize = 'x-small' | 'small' | 'medium' | 'large';
type ButtonRadius = 'round' | 'square';

type IconButtonProps = {
  icon: ReactNode;
  text: string;
  type?: ButtonType;
  size?: ButtonSize;
  radius?: ButtonRadius;
  className?: string;
  primary?: boolean;
  outline?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

type Ref = HTMLButtonElement;

const IconButton = React.forwardRef<Ref, IconButtonProps>(
  (
    {
      icon,
      text,
      type = 'button',
      size = 'medium',
      radius = 'square',
      className: customClassName,
      primary,
      outline,
      ghost,
      disabled,
      loading,
      readOnly,
      onClick,
    }: IconButtonProps,
    ref: ForwardedRef<Ref>
  ) => {
    const className = cn(
      'btn flex p-s-0 justify-center items-center transition-colors duration-200',
      primary && PRIMARY_STYLES,
      outline && OUTLINE_STYLES,
      !(primary || outline) && SECONDARY_STYLES,
      ghost && GHOST_STYLES,
      {
        'min-w-[2rem] min-h-[2rem] w-s-32 h-s-32': size === 'x-small',
        'min-w-[2.5rem] min-h-[2.5rem] w-s-40 h-s-40': size === 'small',
        'min-w-[3rem] min-h-[3rem] w-s-48 h-s-48': size === 'medium',
        'min-w-[3.5rem] min-h-[3.5rem] w-s-56 h-s-56': size === 'large',
        'rounded-md': radius === 'square',
        'rounded-full': radius === 'round',
        'pointer-events-none': readOnly,
      },
      customClassName
    );

    return (
      <button
        ref={ref}
        className={className}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        <span className="sr-only">{text}</span>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <span
            className={cn('font-normal leading-[initial]', {
              'text-16': size === 'x-small' || size === 'small',
              'text-20': size === 'medium' || size === 'large',
            })}
          >
            {icon}
          </span>
        )}
      </button>
    );
  }
);

export default IconButton;
