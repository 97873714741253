import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import * as yup from 'yup';

import {
  ConfirmationModal,
  PageHeader,
  PageLoader,
  Stack,
  ToggleButtons,
} from '@application/components';
import { ModalContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { useWizard } from '@application/hooks';
import { RequestStatusTypeCode } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

import { CREATE_CANDIDATE_REQUEST_STEPS, DEFAULT_VALUES } from './constants';
import {
  useGetCandidateRequest,
  useSaveAndPublishCandidateRequest,
  useUpdateCandidateRequest,
} from './hooks';
import {
  normalizeCandidateRequestEditData,
  normalizeCandidateRequestInputData,
} from './normalizers';
import {
  CANDIDATE_REQUEST_PUBLISH_SCHEMA,
  CANDIDATE_REQUEST_SCHEMA,
  CandidateRequestFormFields,
} from './schema';
import { ConditionsPanel, DescriptionPanel } from './steps';

const EditCandidateRequestPage = () => {
  const [currentSchema, setCurrentSchema] = useState<yup.ObjectSchema<any>>(
    CANDIDATE_REQUEST_SCHEMA
  );
  const [saving, setSaving] = useState<boolean>(false);

  const { t, i18n } = useTranslation('candidates');

  const {
    updateCandidateRequest,
    viewModel: { isLoading: isSaveLoading },
  } = useUpdateCandidateRequest();
  const {
    saveAndPublish,
    viewModel: { isLoading: isPublishLoading },
  } = useSaveAndPublishCandidateRequest();

  const { setModal } = useContext(ModalContext);

  const { id = '' } = useParams();

  const { activeStep, setActive, nextStep } = useWizard({
    steps: CREATE_CANDIDATE_REQUEST_STEPS,
  });

  const methods = useForm<CandidateRequestFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(currentSchema),
  });

  const {
    control,
    register,
    formState,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    setValue,
    trigger,
    watch,
  } = methods;

  const {
    viewModel: { data, isLoading },
  } = useGetCandidateRequest(id);

  useEffect(() => {
    if (data) {
      const d = {
        ...normalizeCandidateRequestEditData(data),
        desiredStartDate: data.desiredStartDate
          ? formatDate(data.desiredStartDate, i18n.language, 'short')
          : undefined,
        metAt: data.metAt
          ? formatDate(data.metAt, i18n.language, 'short')
          : undefined,
        receivingOfferDeadline: data.receivingOfferDeadline
          ? formatDate(data.receivingOfferDeadline, i18n.language, 'short')
          : undefined,
        id,
      };
      reset(d as CandidateRequestFormFields);
    }
  }, [id, data, reset, i18n.language]);

  const onSubmit: SubmitHandler<CandidateRequestFormFields> = useCallback(
    async (values) => {
      const { publishNow, ...otherValues } = values;
      const modifiedValues = { ...otherValues };

      if (publishNow) {
        await saveAndPublish({
          input: normalizeCandidateRequestInputData(modifiedValues) as any,
          statusInput: {
            id,
            status: RequestStatusTypeCode.InProgress,
          },
        });
      } else {
        await updateCandidateRequest({
          input: normalizeCandidateRequestInputData(modifiedValues) as any,
        });
      }
    },
    [updateCandidateRequest, id, saveAndPublish]
  );

  const handleOnSave = () => {
    setValue('publishNow', false);
    setCurrentSchema(CANDIDATE_REQUEST_SCHEMA);
    setSaving(true);
  };

  const handleOnPublish = () => {
    setValue('publishNow', true);
    setCurrentSchema(CANDIDATE_REQUEST_PUBLISH_SCHEMA);
    setSaving(true);
  };

  useEffect(() => {
    if (!saving || Object.keys(formState.errors).length === 0) {
      return;
    }

    setModal({
      title: t('modal.titles.fillOutEmptyRequiredField', {
        count: Object.keys(formState.errors).length,
      }),
      maxWidth: '2xl',
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p>
                <Trans
                  i18nKey="modal.contents.requiredFieldError"
                  t={t}
                  values={{
                    count: Object.keys(formState.errors).length,
                    step: t(CREATE_CANDIDATE_REQUEST_STEPS[0].title),
                  }}
                />
              </p>
              <p>
                {t('modal.contents.confirmRedirectStep', {
                  count: Object.keys(formState.errors).length,
                })}
              </p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            /**
             * FIXME: This is a only temporary. The redirection is made explicitly
             * to the first step (0) of the form in case of error since all required fields are present in this step.
             * It should be handle dynamically by redirecting to the first step containing errors on required fields.
             */
            setActive(0);
          }}
        />
      ),
    });

    setSaving(false);
    // We want to execute it only on saving AND presence of errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors, saving]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        title={t('title.edit')}
        backTo={RootPrivatePage.CANDIDATE_REQUESTS}
      >
        <ToggleButtons
          activeStep={activeStep}
          setActive={setActive}
          leftLabel={t('subtitles.description')}
          rightLabel={t('subtitles.conditions')}
        />
      </PageHeader>
      <FormProvider {...methods}>
        <form className="md:px-s-48" onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && (
            <DescriptionPanel
              resumeUrl={data?.resumeUrl as string}
              clearErrors={clearErrors}
              control={control}
              errors={formState.errors}
              nextStep={nextStep}
              register={register}
              setError={setError}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
            />
          )}

          {activeStep === 1 && (
            <ConditionsPanel
              control={control}
              errors={formState.errors}
              onPublish={handleOnPublish}
              onSave={handleOnSave}
              register={register}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
              saveLoading={isSaveLoading}
              publishLoading={isPublishLoading}
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default EditCandidateRequestPage;
