import LogoSection from './LogoSection';
import { ProfileForm } from './form';
import { useGetProfile } from './hooks';
import { OrganizationProfile } from './types';

const UpdateProfilePage = () => {
  const {
    viewModel: { data },
    refetchAccount,
  } = useGetProfile();

  return (
    <div aria-labelledby="tab-information" role="tabpanel">
      <LogoSection
        accountName={data?.account?.name}
        path={data?.account?.logoUrl as string}
      />

      <hr className="my-s-40" />

      <ProfileForm
        values={data?.account as OrganizationProfile | undefined}
        refetchAccount={refetchAccount}
      />
    </div>
  );
};

export default UpdateProfilePage;
