import { useMutation } from 'urql';

import { MutationSaveAndPublishCandidateRequest } from '@infrastructure/graphql/candidate';

const useCandidateRequestSaveAndPublish = () => {
  const [{ data, fetching, error }, saveAndPublish] = useMutation(
    MutationSaveAndPublishCandidateRequest
  );

  return {
    saveAndPublish,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestSaveAndPublish;
