import { ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

type HelperTextProps = {
  /**
   * Specify the content of the `<HelperText>`
   */
  children: ReactNode;
  /**
   * Provide a unique id for the given `<HelperText>`
   */
  id: string;
  /**
   * Specify wether the `<HelperText>` should be invalid
   */
  invalid?: boolean;
  /**
   * Provide a custom className to be applied to the `<HelperText>`
   */
  className?: string;
};

/**
 * Text that is used alongside form elements for additional help
 * or as a validation message on input error
 */
const HelperText = ({
  id,
  children,
  invalid,
  className: customClassName,
}: HelperTextProps) => {
  const className = cn(
    'm-0 px-s-4 pt-s-8 text-neutral-content text-14 min-h-[1.75rem]',
    customClassName,
    {
      'text-error-content': invalid,
      'text-12': !invalid,
    }
  );

  return (
    <p id={id} className={className}>
      {children}
    </p>
  );
};

export default HelperText;
