import { FC, ReactElement, ReactNode } from 'react';

type MatchProps = {
  /**
   * Specify the children of the <Switch.Match> component
   */
  children?: ReactNode;
  /**
   * Whether it should render its children depending on the truthfulness of the condition
   */
  // eslint-disable-next-line react/no-unused-prop-types
  when: boolean;
};

type SwitchProps = {
  /**
   * Specify the fallback element to render when no condition is fulfilled
   */
  fallback: ReactNode;
  /**
   * Specify the <Switch.Match> components as children of the <Switch> component
   */
  children?: ReactElement<MatchProps> | ReactElement<MatchProps>[];
};

type SwitchType = {
  /**
   * Specify the <Switch.Match> component
   */
  Match: FC<MatchProps>;
} & FC<SwitchProps>;

/**
 * This component will try in order to match each condition, stopping to render the first that evaluates to true.
 * Failing all of them, it will render the fallback.
 *
 * @example
 * <Switch fallback={<p>{x()} is between 5 and 10</p>}>
 *   <Switch.Match when={x() > 10}>
 *     <p>{x()} is greater than 10</p>
 *   </Switch.Match>
 *   <Switch.Match when={5 > x()}>
 *     <p>{x()} is less than 5</p>
 *   </Switch.Match>
 * </Switch>
 */
export const Switch: SwitchType = ({ fallback, children }: SwitchProps) => {
  if (!children) {
    return null;
  }

  const arrayOfChildren = Array.isArray(children) ? children : [children];
  const matches = arrayOfChildren.filter((child) => child.props.when === true);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return matches.length > 0 ? <>{matches}</> : <>{fallback}</>;
};

// eslint-disable-next-line react/jsx-no-useless-fragment
Switch.Match = ({ children }: MatchProps) => <>{children}</>;
