import { gql } from '@urql/core';

const MutationOfferCandidateUpdate = gql`
  mutation OfferCandidateUpdate($input: OfferCandidateUpdateInput!) {
    offerCandidateUpdate(input: $input) {
      offerCandidate {
        id
      }
    }
  }
`;

export default MutationOfferCandidateUpdate;
