import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const JobDurations = gql`
  ${DescriptionsFragment}

  query JobDurations {
    jobDurations {
      values {
        descriptions {
          ...DescriptionsFragment
        }
        code
      }
    }
  }
`;

export default JobDurations;
