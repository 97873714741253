import { gql } from '@urql/core';

const MutationRecruitmentNegotiationRoomsReject = gql`
  mutation RecruitmentNegotiationRoomsReject(
    $input: RecruitmentNegotiationRoomsRejectInput!
  ) {
    recruitmentNegotiationRoomsReject(input: $input) {
      rooms {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationRoomsReject;
