import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import {
  CandidateNegotiationsFilters,
  CandidateNegotiationStatus,
} from '@domain/graphql.types';

const defaultFilters = {
  statuses: undefined,
};

const statusCodeMap: Record<string, CandidateNegotiationsFilters['statuses']> =
  {
    '#in-progress': [CandidateNegotiationStatus.InProgress],
    '#completed': [CandidateNegotiationStatus.Completed],
  };

const useCandidateNegotiationFilters = () => {
  const [filters, setFilters] =
    useState<CandidateNegotiationsFilters>(defaultFilters);

  const { hash } = useLocation();

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      statuses: statusCodeMap[hash] || undefined,
    }));
  }, [hash]);

  return {
    filters,
  };
};

export default useCandidateNegotiationFilters;
