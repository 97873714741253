import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useParams } from 'react-router';

import {
  ButtonLink,
  ConfirmationModal,
  IconButton,
  PageHeader,
  PageLoader,
} from '@application/components';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import {
  CandidateNegotiationRoomStatus,
  CandidateRequestType,
} from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

import {
  useGetCandidateNegotiationRoom,
  useRejectCandidateNegotiationRoom,
} from '../hooks';
import { CandidateInitialOfferTable, CandidateRequestTable } from '../tables';
import { CandidateNegotiatingOfferTable } from '../tables/negotiating-offer-table';

const ViewCandidateNegotiationRoomPage = () => {
  const [showLoader, setShowLoader] = useState(false);

  const { t, i18n } = useTranslation('candidates');

  const { id = '' } = useParams();

  const { setModal } = useContext(ModalContext);

  const location = useLocation();

  const {
    viewModel: { data, isLoading },
    refetchCandidate,
  } = useGetCandidateNegotiationRoom(id);

  const { rejectNegotiationRoom } = useRejectCandidateNegotiationRoom();

  const candidateRequestDetailsPath = useMemo(
    () =>
      data?.candidateRequestId
        ? generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_DETAILS, {
            id: data.candidateRequestId,
          })
        : '#',
    [data?.candidateRequestId]
  );

  const agreementPath = useMemo(
    () =>
      generatePath(PrivatePage.CANDIDATE_NEGOTIATION_ROOM_AGREEMENT, {
        id,
      }),
    [id]
  );

  const handleLeave = useCallback(async () => {
    setModal(null);
    const result = await rejectNegotiationRoom({ input: { roomId: id } });

    if (result?.data?.candidateNegotiationRoomReject.room) {
      refetchCandidate();
    }
  }, [refetchCandidate, rejectNegotiationRoom, setModal, id]);

  const confirmLeave = useCallback(() => {
    setModal({
      title: t('modal.leaveNegotiationRoomTitle'),
      content: (
        <ConfirmationModal
          confirmButtonClassName="hover:btn-error border-error-content bg-error-content text-white"
          content={t('modal.leaveNegotiationRoomMessage', {
            accountName: data?.candidateRequest.account.name,
          })}
          onCancel={() => setModal(null)}
          onConfirm={handleLeave}
        />
      ),
    });
  }, [setModal, t, handleLeave, data]);

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  return showLoader && isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={getLocalizedDescription(
          data?.candidateRequest.jobSpecialty?.descriptions,
          i18n.language
        )}
        subtitle={data?.candidateRequest.specialty}
        backTo={RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS}
      >
        <div className="flex gap-s-16">
          {data?.status === CandidateNegotiationRoomStatus.Accepted && (
            <ButtonLink
              icon={<i className="ri-shake-hands-line" />}
              primary
              to={agreementPath}
            >
              {t('button.preAgreement')}
            </ButtonLink>
          )}

          <ButtonLink to={candidateRequestDetailsPath} from={location.pathname}>
            {t('button.requestDetails')}
          </ButtonLink>

          {data?.status === CandidateNegotiationRoomStatus.InProgress && (
            <IconButton
              text={t('button.leave')}
              icon={<i className="ri-logout-box-r-line" />}
              onClick={confirmLeave}
            />
          )}
        </div>
      </PageHeader>

      <div className="relative">
        <div className="overflow-x-auto">
          <div className="flex gap-s-16">
            {data?.candidateRequest && (
              <CandidateRequestTable
                className="bg-neutral"
                candidateRequest={data?.candidateRequest}
              />
            )}

            {data?.candidateOffer && (
              <CandidateInitialOfferTable
                candidateOffer={data?.candidateOffer}
                type={data?.candidateRequest.type as CandidateRequestType}
              />
            )}

            {data && <CandidateNegotiatingOfferTable room={data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCandidateNegotiationRoomPage;
