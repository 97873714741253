import { useQuery } from 'urql';

import { Query, QueryCandidateOffersArgs } from '@domain/graphql.types';
import { CandidateOffers } from '@infrastructure/graphql/candidate/offer';

const useCandidateOffers = (input?: QueryCandidateOffersArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateOffers'>,
    QueryCandidateOffersArgs
  >({
    query: CandidateOffers,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateOffers;
