import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import { NegoroomChat, PageLoader } from '@application/components';
import { ChatListItemProps } from '@application/components/negoroom-chat/ChatListItem';
import { ChatContent } from '@application/components/negoroom-chat/NegoroomChat';
import { PrivatePage } from '@application/enums/pagesUrl';
import { getLocalizedDescription } from '@utils/i18n-utils';

import {
  RecruitmentNegotiationContext,
  withRecruitmentNegotiationProvider,
} from './context';
import {
  useCreateRecruitmentNegotiationRoomMessage,
  useRecruitmentNegotiationRoomMessagesList,
} from './hooks';

const ViewRecruitmentNegotiationChatPage = () => {
  const [selectedRoomId, setSelectedRoomId] = useState<string>('');
  const { isLoading, request, rooms } = useContext(
    RecruitmentNegotiationContext
  );
  const { i18n } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  const onBackClick = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
        id: request?.recruitmentNegotiation?.id ?? '',
      }),
    [request?.recruitmentNegotiation?.id]
  );

  const { createRecruitmentNegotiationRoomMessage } =
    useCreateRecruitmentNegotiationRoomMessage();

  const chatList: ChatListItemProps[] = useMemo(
    () =>
      rooms?.map((room) => ({
        rank: room.negotiatingOffer.rank ?? 0,
        selected: selectedRoomId === room.id,
        title: room.negotiatingOffer.account.name,
        preview: room.offerAccountLastMessage?.message ?? '',
        date: room.offerAccountLastMessage?.sentAt,
        newMessageCount: 0, // TODO for v2 get the count of unread message by the last read message date
        onClick: () => setSelectedRoomId(room.id),
      })) || [],
    [rooms, selectedRoomId]
  );

  const {
    recruitmentNegotiationRoomMessages,
    isLoading: isMessagesListLoading,
    refetchRecruitmentNegotiationRoomMessages,
  } = useRecruitmentNegotiationRoomMessagesList({
    recruitmentNegotiationRoomId: selectedRoomId ?? '',
  });

  const selectedRoom = useMemo(
    () => rooms?.find((room) => room.id === selectedRoomId),
    [rooms, selectedRoomId]
  );

  const chatContent: ChatContent = useMemo(
    () => ({
      title: selectedRoom?.negotiatingOffer.account.name,
      messageList: recruitmentNegotiationRoomMessages?.map((message) => ({
        position:
          message.accountId === request?.accountId
            ? ('right' as const)
            : ('left' as const),
        date: message.sentAt,
        viewed: true,
        content: message.message,
      })),
    }),
    [
      recruitmentNegotiationRoomMessages,
      request?.accountId,
      selectedRoom?.negotiatingOffer.account.name,
    ]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createRecruitmentNegotiationRoomMessage({
        input: {
          recruitmentNegotiationRoomId: selectedRoomId,
          message,
        },
      });

      if (recruitmentNegotiationRoomMessages.length < 1) {
        refetchRecruitmentNegotiationRoomMessages();
      }
    },
    [
      createRecruitmentNegotiationRoomMessage,
      recruitmentNegotiationRoomMessages.length,
      refetchRecruitmentNegotiationRoomMessages,
      selectedRoomId,
    ]
  );

  useEffect(() => {
    if (rooms?.length && !selectedRoomId) {
      setSelectedRoomId(rooms[0].id);
    }
  }, [rooms, selectedRoomId]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <NegoroomChat
      isLoading={isMessagesListLoading}
      title={
        getLocalizedDescription(
          request?.jobSpecialty?.descriptions,
          i18n.language
        ) ?? ''
      }
      subtitle={request?.operationUnit?.name ?? ''}
      backTo={onBackClick}
      chatList={chatList}
      chatContent={chatContent}
      onSendMessage={handleSendMessage}
      refetchMessages={refetchRecruitmentNegotiationRoomMessages}
    />
  );
};

export const WrapperViewRecruitmentNegotiationChatPage =
  withRecruitmentNegotiationProvider(ViewRecruitmentNegotiationChatPage);
