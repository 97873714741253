import { ChangeEvent } from 'react';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type OffersTableProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const OffersTable = ({ onChange }: OffersTableProps) => (
  <table className="w-full bg-base-100 h-fit">
    <TableHeader onChange={onChange} />
    <TableBody />
  </table>
);

export default OffersTable;
