import { gql } from '@urql/core';

const MutationCandidateNegotiationCreate = gql`
  mutation CandidateNegotiationCreate(
    $input: CandidateNegotiationCreateInput!
  ) {
    candidateNegotiationCreate(input: $input) {
      negotiation {
        id
      }
    }
  }
`;

export default MutationCandidateNegotiationCreate;
