import React, { ReactNode, useMemo } from 'react';

import { Label } from '@application/components';
import { TEXT_INPUT_STYLES } from '@application/components/form/text-input-field/textInput/constants';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

type TextInputFieldProps = {
  label: ReactNode;
  id?: string;
  name: string;
  value: string | number | undefined;
  minValue: number | string | undefined;
  maxValue: number | string | undefined;
  disabled?: boolean;
  suffix?: string;
  className?: string;
  step?: number;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Provide an `onChange` handler that is called whenever `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInputField = ({
  id,
  label,
  name,
  value,
  minValue,
  maxValue,
  suffix,
  disabled,
  step,
  onBlur,
  onChange,
  className: customClassName,
  inputProps,
  ...rest
}: TextInputFieldProps) => {
  const inputId = useMemo(() => id || randomString(), [id]);

  const className = cn('relative', customClassName);

  const textInputClassName = cn(
    TEXT_INPUT_STYLES.base,
    'h-auto pt-s-28 pb-s-6',
    {
      'pl-s-40': suffix,
    },
    disabled && TEXT_INPUT_STYLES.disabled
  );

  return (
    <div className={className} {...rest}>
      <Label
        htmlFor={inputId}
        disabled={disabled}
        className="absolute z-[1] top-s-0 left-s-0 pt-s-6 pl-s-16"
      >
        {label}
      </Label>

      <div className="relative flex w-[inherit]">
        {suffix && (
          <div className="absolute top-[52%] left-s-0 pl-s-16 font-medium text-14 opacity-60">
            {suffix}
          </div>
        )}

        <div className="flex-grow">
          <input
            type="number"
            id={inputId}
            name={name}
            value={value}
            min={minValue}
            max={maxValue}
            onChange={onChange}
            onBlur={onBlur}
            step={step}
            className={textInputClassName}
            {...inputProps}
          />
        </div>
      </div>
    </div>
  );
};

export default TextInputField;
