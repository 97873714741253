import { useQuery } from 'urql';

import { Query, QueryPublicAccountArgs } from '@domain/graphql.types';
import { PublicAccount } from '@infrastructure/graphql/account';

const usePublicAccount = (accountId: string) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'publicAccount'>,
    QueryPublicAccountArgs
  >({
    query: PublicAccount,
    variables: {
      input: {
        accountId,
      },
    },
  });

  const { data, fetching, error } = result;

  return {
    data: data?.publicAccount,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default usePublicAccount;
