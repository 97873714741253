import { useCandidateRequests } from '@domain/candidate';
import {
  CandidateRequestsFilters,
  CandidateRequestsSortDirective,
} from '@domain/graphql.types';
import { Pagination } from '@domain/pagination/pagination';

import { toViewModel } from '../view-models/get-candidate-requests.vm';

const useGetCandidateRequests = (
  sortBy: CandidateRequestsSortDirective[],
  filterBy?: CandidateRequestsFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } = useCandidateRequests({
    sortBy,
    filterBy,
    ...pagination,
  });

  const viewModel = toViewModel({
    candidateRequests: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateRequests: reexecuteQuery,
  };
};

export default useGetCandidateRequests;
