import { useContext } from 'react';

import LanguageContext from '../context/LanguageContext';

type LanguageContextProps = {
  changeLanguage: (language: string) => void;
  auth0CurrentLanguage: string;
  t: (key: string) => string;
};

const useLanguage = (): LanguageContextProps => useContext(LanguageContext);

export default useLanguage;
