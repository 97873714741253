import { useQuery } from 'urql';

import { Query, QueryRequestOffersArgs } from '@domain/graphql.types';
import { RequestOffers } from '@infrastructure/graphql/request';

const useRequestOffers = (input: QueryRequestOffersArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'requestOffers'>,
    QueryRequestOffersArgs
  >({
    query: RequestOffers,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.requestOffers,
    fetching,
    error,
    refreshRequestOffers: reexecuteQuery,
  };
};

export default useRequestOffers;
