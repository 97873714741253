import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import { UserRoleGuard } from '@application/components/guards';
import { Table } from '@application/components/table';
import { UserRole } from '@domain/graphql.types';

import useInviteUserModal from '../hooks/useInviteUserModal';
import { useUsersList } from '../hooks/useUsersList';

const UsersList = () => {
  const { t } = useTranslation('organization', { keyPrefix: 'users' });

  const { columns, users, sorting, onSortingChange, isLoading } =
    useUsersList();

  const { openModal } = useInviteUserModal();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-between mb-s-24 gap-s-24">
        <div className="flex flex-col gap-s-12">
          <h2 className="h3">{t('members')}</h2>

          <span className="text-16 text-neutral-secondary">
            {t('inviteMembers')}
          </span>
        </div>
        <UserRoleGuard
          authorizedUserRoles={[
            UserRole.SuperAdmin,
            UserRole.Owner,
            UserRole.Admin,
          ]}
        >
          <Button
            className="w-fit"
            primary
            icon={<i className="ri-user-add-line" />}
            onClick={openModal}
          >
            {t('createInvitation')}
          </Button>
        </UserRoleGuard>
      </div>

      <div data-testid="TEST-users-table">
        <Table
          data={users}
          columns={columns}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          rowHeight="tall"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default UsersList;
