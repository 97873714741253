import { gql } from 'urql';

const CandidateNegotiationTemporaryPlacementPropertiesStateFragment = gql`
  fragment CandidateNegotiationTemporaryPlacementPropertiesStateFragment on CandidateNegotiationTemporaryPlacementPropertiesState {
    billingPeriod
    minimumNotice
    possibleBuybackBonus
  }
`;

export default CandidateNegotiationTemporaryPlacementPropertiesStateFragment;
