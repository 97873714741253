import React, { ForwardedRef, ReactNode, useMemo } from 'react';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import { HelperText, Label } from '../form';
import { CreatableSelect, SelectProps } from './select';

type CreatableSelectFieldProps = {
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  label?: ReactNode;
  /**
   * Provide the text that is used alongside the `<input>` for additional help
   * or as a validation message on input error
   */
  helperText?: string;
  /**
   * Specify whether the `<label>` should be visually hidden
   */
  hideLabel?: boolean;
} & SelectProps;

type Ref = HTMLDivElement;

const CreatableSelectField = React.forwardRef<Ref, CreatableSelectFieldProps>(
  (
    {
      label,
      id,
      name,
      value,
      defaultValue,
      placeholder,
      clearable,
      multiple,
      disabled,
      invalid,
      hideLabel,
      helperText,
      formatOptionLabel,
      className: customClassName,
      validateOnKeyDown,
      onInputChange,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      ...rest
    }: CreatableSelectFieldProps,
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    const selectId = useMemo(() => id || randomString(), [id]);
    const helperTextId = useMemo(() => randomString(), []);

    const className = cn('', customClassName);

    return (
      <div ref={ref} className={className} {...rest}>
        <Label
          htmlFor={selectId}
          disabled={disabled}
          invalid={invalid}
          className={hideLabel ? 'sr-only' : undefined}
        >
          {label}
        </Label>

        <CreatableSelect
          id={selectId}
          aria-describedby={helperText ? helperTextId : undefined}
          name={name}
          value={value}
          defaultValue={defaultValue}
          clearable={clearable}
          multiple={multiple}
          disabled={disabled}
          invalid={invalid}
          formatOptionLabel={formatOptionLabel}
          validateOnKeyDown={validateOnKeyDown}
          onInputChange={onInputChange}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
        />

        {helperText && (
          <HelperText id={helperTextId} invalid={invalid}>
            {helperText}
          </HelperText>
        )}
      </div>
    );
  }
);

export default CreatableSelectField;
