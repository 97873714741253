import { useMutation } from 'urql';

import {
  Mutation,
  MutationUserSendEmailVerificationLinkArgs,
} from '@domain/graphql.types';
import { UserSendEmailVerificationLink } from '@infrastructure/graphql/user';

const useUserSendEmailVerificationLink = () => {
  const [{ data, fetching, error }, userSendEmailVerificationLink] =
    useMutation<
      Pick<Mutation, 'userSendEmailVerificationLink'>,
      MutationUserSendEmailVerificationLinkArgs
    >(UserSendEmailVerificationLink);

  return {
    userSendEmailVerificationLink,
    data: data?.userSendEmailVerificationLink,
    fetching,
    error,
  };
};

export default useUserSendEmailVerificationLink;
