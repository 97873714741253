/* eslint jsx-a11y/control-has-associated-label: 0 */
// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, Switch } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import {
  Offer,
  OfferStatusTypeCode,
  OfferTemporaryPlacementConditions,
  RecruitmentNegotiationPropertyState,
  RecruitmentNegotiationRoom,
  RecruitmentNegotiationTemporaryPlacementPropertiesState,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';
import { isDefined } from '@utils/type-utils';

import { StateBadge, StateButtons, StatesMenu } from '../../../components';
import { RecruitmentNegotiationContext } from '../../../context';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

const conditionsKeys: [
  keyof OfferTemporaryPlacementConditions,
  keyof Omit<
    RecruitmentNegotiationTemporaryPlacementPropertiesState,
    '__typename'
  >,
][] = [
  ['possibleBuyback', 'possibleBuyback'],
  ['travelExpensesResponsibility', 'travelExpensesResponsibility'],
  ['trainingExpensesResponsibility', 'trainingExpensesResponsibility'],
  ['cnesstExpensesResponsibility', 'cnesstExpensesResponsibility'],
];

const TemporaryPlacementConditions = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const {
    rooms,
    checkedRooms,
    onAcceptValues,
    onRejectValues,
    getModifiedProperties,
    shouldDisplayMenu,
  } = useContext(RecruitmentNegotiationContext);

  const getCellContent = useCallback(
    (condition: keyof OfferTemporaryPlacementConditions, offer: Offer) => {
      const cellValue =
        offer.temporaryPlacementContractualConditions?.[condition];

      switch (condition) {
        case 'possibleBuyback':
          return tGlobal(`boolean.${String(cellValue)}`);
        case 'travelExpensesResponsibility':
        case 'trainingExpensesResponsibility':
        case 'cnesstExpensesResponsibility':
          return tGlobal(
            `enum.requestConditionResponsibility.${cellValue
              ?.toString()
              .toLowerCase()}`
          );
        default:
          return '-';
      }
    },
    [tGlobal]
  );

  const renderStatesMenu = useCallback(
    (
      id: string,
      property: keyof RecruitmentNegotiationTemporaryPlacementPropertiesState,
      state: RecruitmentNegotiationPropertyState,
      content: string
    ) => (
      <StatesMenu
        onAccept={onAcceptValues(id, 'temporaryPlacementPropertiesState', [
          property,
        ])}
        onReject={onRejectValues(id, 'temporaryPlacementPropertiesState', [
          property,
        ])}
      >
        <Switch fallback={<span>{content}</span>}>
          <Switch.Match
            when={state === RecruitmentNegotiationPropertyState.Modified}
          >
            <StateBadge value={content} state={state} />
          </Switch.Match>
        </Switch>
      </StatesMenu>
    ),
    [onAcceptValues, onRejectValues]
  );

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('temporaryPlacementConditions')}
        </th>

        {rooms?.map(
          ({
            id,
            temporaryPlacementPropertiesState,
          }: RecruitmentNegotiationRoom) => (
            <td
              key={`offerTemporaryPlacementConditions-${id}`}
              className={cn(
                TH_ROW_CLASS_NAME.highlight,
                'border-l-1 border-b-1',
                {
                  '!border-x-main border-x-2': checkedRooms.includes(id),
                }
              )}
            >
              <Switch fallback={<>&nbsp;</>}>
                <Switch.Match
                  when={conditionsKeys.some(
                    ([, state]) =>
                      temporaryPlacementPropertiesState[state] ===
                      RecruitmentNegotiationPropertyState.Modified
                  )}
                >
                  <StateButtons
                    onAccept={onAcceptValues(
                      id,
                      'temporaryPlacementPropertiesState',
                      getModifiedProperties(
                        temporaryPlacementPropertiesState
                      ) as (keyof RecruitmentNegotiationTemporaryPlacementPropertiesState)[]
                    )}
                    onReject={onRejectValues(
                      id,
                      'temporaryPlacementPropertiesState',
                      getModifiedProperties(
                        temporaryPlacementPropertiesState
                      ) as (keyof RecruitmentNegotiationTemporaryPlacementPropertiesState)[]
                    )}
                  />
                </Switch.Match>
              </Switch>
            </td>
          )
        )}
      </tr>

      {conditionsKeys.map(([condition, property]) => (
        <tr key={`offerTemporaryPlacementConditions-${condition}`}>
          <th scope="col" className={TH_ROW_CLASS_NAME.base}>
            <TooltipEllipsis toolTip={t(condition)} lineClamp={2}>
              <div>{t(condition)}</div>
            </TooltipEllipsis>
          </th>
          {rooms?.map(
            ({
              id,
              negotiatingOffer: offer,
              status,
              temporaryPlacementPropertiesState,
            }: RecruitmentNegotiationRoom) => (
              <td
                key={`offerTemporaryPlacementConditions-${condition}-${id}`}
                className={cn(TD_CLASS_NAME.base, {
                  'border-x-2 !border-x-main': checkedRooms.includes(id),
                  'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
                  'p-s-0':
                    isDefined(
                      offer.temporaryPlacementContractualConditions?.[condition]
                    ) &&
                    shouldDisplayMenu(
                      status,
                      temporaryPlacementPropertiesState[property]
                    ),
                })}
              >
                <PlaceholderSwitcher
                  value={
                    offer.temporaryPlacementContractualConditions?.[condition]
                  }
                >
                  <Switch
                    fallback={
                      <StateBadge
                        value={getCellContent(condition, offer)}
                        state={temporaryPlacementPropertiesState[property]}
                      />
                    }
                  >
                    <Switch.Match
                      when={shouldDisplayMenu(
                        status,
                        temporaryPlacementPropertiesState[property]
                      )}
                    >
                      {renderStatesMenu(
                        id,
                        property,
                        temporaryPlacementPropertiesState[property],
                        getCellContent(condition, offer)
                      )}
                    </Switch.Match>
                  </Switch>
                </PlaceholderSwitcher>
              </td>
            )
          )}
        </tr>
      ))}
    </>
  );
};

export default TemporaryPlacementConditions;
