import { useMemo } from 'react';

import { cn } from '@utils/lib-utils';

type SectionGridRowProps = {
  children?: React.ReactNode;
  className?: string;
  isTopRow?: boolean;
  partialBorderTop?: boolean;
  rightChildren?: React.ReactNode;
  isOffer?: boolean;
};

const SectionGridRow = ({
  children,
  className: customClassName,
  isTopRow,
  partialBorderTop,
  rightChildren,
  isOffer,
}: SectionGridRowProps) => {
  const partialBorderTopComponent = useMemo(
    () =>
      partialBorderTop ? (
        <hr className="absolute top-s-0 h-px w-[calc(100%-2rem)] border-t" />
      ) : null,
    [partialBorderTop]
  );

  return (
    <>
      <div
        className={cn(
          'p-s-16 h-full flex items-center relative',
          {
            'border-t': !isTopRow && !partialBorderTop,
            'border-r': !!rightChildren,
          },
          customClassName
        )}
      >
        {children}
        {partialBorderTopComponent}
      </div>

      {rightChildren && (
        <div
          className={cn('h-full flex items-center justify-center relative', {
            'border-t': !isTopRow && !partialBorderTop,
            'p-s-16 text-center': isOffer,
          })}
        >
          {rightChildren}
          {partialBorderTopComponent}
        </div>
      )}
    </>
  );
};

export default SectionGridRow;
