import { gql } from 'urql';

const OfferHeadhunterConditionsFragment = gql`
  fragment OfferHeadhunterConditionsFragment on OfferHeadhunterConditions {
    maximumReplacement
    headhuntingExclusivityPeriod
  }
`;

export default OfferHeadhunterConditionsFragment;
