import { useCallback, useEffect } from 'react';

type ClickOutsideProps = {
  refs: React.MutableRefObject<any>[];
  isDisregarded?: boolean;
  onOutsideClick: () => void;
};

const useClickOutside = ({
  refs,
  isDisregarded = false,
  onOutsideClick,
}: ClickOutsideProps) => {
  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        isDisregarded ||
        refs?.some(
          (ref) => ref?.current && ref.current?.contains(event.target as Node)
        )
      ) {
        return;
      }
      onOutsideClick();
    },
    [isDisregarded, onOutsideClick, refs]
  );

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);
};

export default useClickOutside;
