import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryList } from '@application/components/summary';
import { useGetPerks } from '@application/views/organization/profile/hooks';
import { CandidateOffer } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

type OtherPerksProps = {
  offer: CandidateOffer | undefined;
};

const OtherPerks = ({ offer }: OtherPerksProps) => {
  const { data: PERKS_LIST = [] } = useGetPerks();
  const { t, i18n } = useTranslation('candidates');

  return (
    <SummaryBlock className="mt-s-16">
      <SummaryList
        className="!mt-s-0"
        density="default"
        data={[
          {
            text: t('labels.perksOffered'),
            description:
              offer?.perks
                ?.map((perk) =>
                  getLocalizedDescription(
                    PERKS_LIST.find(
                      (code: { value: string }) => code.value === perk
                    )?.text,
                    i18n.language
                  )
                )
                .join(', ') || '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default OtherPerks;
