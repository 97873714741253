export const SOCIAL_NETWORKS_DEFAULT_VALUES = {
  facebookUrl: '',
  instagramUrl: '',
  linkedInUrl: '',
  twitterUrl: '',
};

export const PROFILE_DEFAULT_VALUES = {
  name: '',
  website: '',
  address: '',
  phoneNumber: '',
  perks: [],
  industryType: undefined,
  totalEmployees: undefined,
  offerDescription: '',
  ...SOCIAL_NETWORKS_DEFAULT_VALUES,
};
