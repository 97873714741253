import { useJobDurationsGet } from '@domain/job';

import { toViewModel } from '../view-models/get-job-durations.vm';

const useGetJobDurations = () => {
  const { data = {}, fetching, error } = useJobDurationsGet();

  return toViewModel({
    jobDurations: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetJobDurations;
