export const validateDecimal = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'number') {
    return /^\d+(\.\d{1,2})?$/.test(value.toString());
  }
  return false;
};

export const URL_REGEX =
  /^((https?):\/\/)?(www\.)?[a-zà-ÿ0-9-_]+(\.[a-z]{2,}){1,3}([/a-zA-ZÀ-ÿ0-9-_%]*)*\/?((?:\?[a-zA-ZÀ-ÿ0-9_\-+%&:=]+)?)*$/;

export const PHONE_REGEX =
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[ -./]?)?((?:\(?\d{1,}\)?[ -./]?){0,})(?:[ -./]?(?:#|ext\.?|extension|x)[ -./]?(\d+))?$/gim;

export const INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX = /^[1-9]\d*(\.\d)?$/;
