import { gql } from '@urql/core';

import { RequestFragment } from './fragment';

const Request = gql`
  ${RequestFragment}

  query Request($requestGetInput: RequestGetInput!) {
    request(requestGetInput: $requestGetInput) {
      request {
        ...RequestFragment
      }
    }
  }
`;

export default Request;
