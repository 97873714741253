import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Offer, Request } from '@domain/graphql.types';

type HeadhunterProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const HeadhunterConditions = ({ data, request }: HeadhunterProps) => {
  const { t } = useTranslation('requests');

  return (
    <>
      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <PlaceholderSwitcher
            value={
              request?.headhunterContractualConditions
                ?.headhuntingExclusivityPeriodValue
            }
          >
            <span className="text-16 font-semibold">
              {t('suffix.week', {
                count: Number(
                  request?.headhunterContractualConditions
                    ?.headhuntingExclusivityPeriodValue
                ),
              })}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <span className="flex flex-wrap items-center">
          <span className="mr-s-8">{t('labels.exclusivityPeriod')}</span>

          <PlaceholderSwitcher
            value={
              data?.headhunterContractualConditions
                ?.headhuntingExclusivityPeriod
            }
          >
            <span className="text-18 font-semibold">
              {t('suffix.week', {
                count: Number(
                  data?.headhunterContractualConditions
                    ?.headhuntingExclusivityPeriod
                ),
              })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <PlaceholderSwitcher
            value={
              request?.headhunterContractualConditions?.maximumReplacementValue
            }
          >
            <span className="text-16 font-semibold">
              {
                request?.headhunterContractualConditions
                  ?.maximumReplacementValue
              }
            </span>
          </PlaceholderSwitcher>
        }
      >
        <span className="flex flex-wrap items-center">
          <span className="mr-s-8">{t('labels.maxReplacements')}</span>

          <PlaceholderSwitcher
            value={data?.headhunterContractualConditions?.maximumReplacement}
          >
            <span className="text-18 font-semibold">
              {data?.headhunterContractualConditions?.maximumReplacement}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>
    </>
  );
};

export default HeadhunterConditions;
