import { gql } from '@urql/core';

import { CandidateRequestFragment } from '../request/fragment';
import CandidateNegotiationRoomFragment from './fragment/candidate.negotiation.room.fragment';

const CandidateNegotiation = gql`
  ${CandidateRequestFragment}
  ${CandidateNegotiationRoomFragment}

  query CandidateNegotiation($input: CandidateNegotiationInput!) {
    candidateNegotiation(input: $input) {
      negotiation {
        id
        accountId
        candidateRequestId
        userId
        status
        createdAt
        updatedAt
        candidateRequest {
          ...CandidateRequestFragment
        }
        roomsCount
        rooms {
          ...CandidateNegotiationRoomFragment
        }
      }
    }
  }
`;

export default CandidateNegotiation;
