import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { AccountResponse } from '@domain/graphql.types';

type ToViewModel = {
  account: {
    account: {
      data: AccountResponse | undefined;
      fetching: boolean;
      error: CombinedError | undefined;
    };
  };
};

export const toViewModel = ({
  account: {
    account: { data = {}, fetching, error },
  },
}: ToViewModel): ViewModel<AccountResponse | undefined> =>
  new ViewModel<AccountResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
