import { useMutation } from 'urql';

import { Mutation, MutationAccountUpdateArgs } from '@domain/graphql.types';
import { AccountUpdate } from '@infrastructure/graphql/account';

const useAccountUpdate = () => {
  const [{ data, fetching, error }, updateAccount] = useMutation<
    Pick<Mutation, 'accountUpdate'>,
    MutationAccountUpdateArgs
  >(AccountUpdate);

  return {
    updateAccount,
    data,
    fetching,
    error,
  };
};

export default useAccountUpdate;
