import { createContext } from 'react';

import { OnboardingStep } from '../types';

type SpecialtiesOnboardingContextProps = {
  step: OnboardingStep;
  nextStep: () => void;
  previousStep: () => void;
};

const SpecialtiesOnboardingContext =
  createContext<SpecialtiesOnboardingContextProps>({
    step: OnboardingStep.INDUSTRIES,
    nextStep: () => {},
    previousStep: () => {},
  });

export default SpecialtiesOnboardingContext;
