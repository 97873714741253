import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { UserUpdateEmailResponse } from '@domain/graphql.types';

type ToViewModel = {
  updateEmail: {
    data: UserUpdateEmailResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  updateEmail: { data, fetching, error },
}: ToViewModel): ViewModel<UserUpdateEmailResponse | undefined> =>
  new ViewModel<UserUpdateEmailResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
