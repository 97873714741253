import { gql } from 'urql';

const UserUpdateNotificationPreferences = gql`
  mutation UserUpdateNotificationPreferences(
    $input: UserUpdateNotificationPreferencesInput!
  ) {
    userUpdateNotificationPreferences(input: $input) {
      preferences {
        email
        inApp
      }
    }
  }
`;

export default UserUpdateNotificationPreferences;
