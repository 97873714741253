import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const AccountOperationTerritoryFragment = gql`
  ${DescriptionsFragment}

  fragment AccountOperationTerritoryFragment on AccountOperationTerritory {
    id
    accountId
    active
    operationTerritory {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    createdAt
    updatedAt
  }
`;

export default AccountOperationTerritoryFragment;
