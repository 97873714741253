import { gql } from 'urql';

const CandidateRequestRecruitmentConditionsFragment = gql`
  fragment CandidateRequestRecruitmentConditionsFragment on CandidateRequestRecruitmentConditions {
    guaranteedPeriodValue
    guaranteedPeriodDisplay
    remunerationTypeValue
    remunerationPercentage
    remunerationAmount
    remunerationHourlyRate
    remunerationEstimatedHours
    remunerationTypeDisplay
    probationPeriodValue
    probationPeriodDisplay
    paymentMethodValue
    startOfAgreementPaymentMethodValue
    paymentAmountValue
    depositValue
    paymentFrequencyValue
    paymentMethodDisplay
    bonusValue
    bonusMonthValue
    bonusDisplay
  }
`;

export default CandidateRequestRecruitmentConditionsFragment;
