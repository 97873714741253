import * as yup from 'yup';

import {
  CandidateConditionBillingPeriod,
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
  OfferConditionPaymentMethodCode,
  OperationTerritoryCode,
} from '@domain/graphql.types';
import { validateDecimal } from '@utils/yup-utils';

const RECRUITMENT_CONDITIONS_SCHEMA = yup.object({
  recruitmentConditionBudget: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  recruitmentConditionPaymentMethod: yup
    .mixed<OfferConditionPaymentMethodCode>()
    .oneOf(Object.values(OfferConditionPaymentMethodCode))
    .nullable(),
  recruitmentConditionGuaranteedPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  recruitmentConditionBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
});

const TEMPORARY_PLACEMENT_CONDITIONS_SCHEMA = yup.object({
  temporaryPlacementConditionMinimumNotice: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  temporaryPlacementConditionPossibleBuybackBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  temporaryPlacementConditionBillingPeriod: yup
    .mixed<CandidateConditionBillingPeriod>()
    .oneOf(Object.values(CandidateConditionBillingPeriod))
    .nullable(),
});

const OUTSOURCING_CONDITIONS_SCHEMA = yup.object({
  outsourcingConditionMinimumNotice: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  outsourcingConditionBillingPeriod: yup
    .mixed<CandidateConditionBillingPeriod>()
    .oneOf(Object.values(CandidateConditionBillingPeriod))
    .nullable(),
});

export const REMUNERATION_SCHEMA = yup.object({
  paymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .nullable(),
  annualSalaryMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  annualSalaryMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  hourlyRateMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyRateMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  remunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
});

export const CANDIDATE_NEGOTIATION_ROOM_SCHEMA = yup
  .object({
    fieldInModification: yup.string().nullable(),

    operationTerritoryCodes: yup
      .array(
        yup
          .mixed<OperationTerritoryCode>()
          .oneOf(Object.values(OperationTerritoryCode))
      )
      .nullable(),
    jobType: yup
      .mixed<JobDurationCode>()
      .oneOf(Object.values(JobDurationCode))
      .nullable(),
    jobDurationInMonths: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .integer('validations.integer')
      .positive('validations.positive')
      .min(1, 'validations.minNumber')
      .nullable(),
    hoursPerWeek: yup.array(yup.string()).nullable(),
    jobModeCode: yup
      .mixed<JobModeCode>()
      .oneOf(Object.values(JobModeCode))
      .nullable(),
    otherPaymentTypeCode: yup
      .mixed<JobPaymentOtherTypesCode>()
      .oneOf(Object.values(JobPaymentOtherTypesCode))
      .nullable(),
  })
  .concat(REMUNERATION_SCHEMA)
  .concat(RECRUITMENT_CONDITIONS_SCHEMA)
  .concat(TEMPORARY_PLACEMENT_CONDITIONS_SCHEMA)
  .concat(OUTSOURCING_CONDITIONS_SCHEMA);
