import { useContext } from 'react';
import { useNavigate } from 'react-router';

import { UserContext } from '@application/context';
import { UserRole } from '@domain/graphql.types';

import { RootPrivatePage } from '../../enums/pagesUrl';

type UserRoleGuardProps = React.PropsWithChildren<{
  authorizedUserRoles?: UserRole[] | null;
}>;

const UserRoleGuard = ({
  children,
  authorizedUserRoles,
}: UserRoleGuardProps) => {
  const { accountUser } = useContext(UserContext);
  const navigate = useNavigate();

  if (
    authorizedUserRoles &&
    !(accountUser?.role && authorizedUserRoles.includes(accountUser.role))
  ) {
    navigate(RootPrivatePage.DASHBOARD, { replace: true });
    return false;
  }

  // cast as any to remove type error in router file
  return children as any;
};

export default UserRoleGuard;
