import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { parseNumber } from '@utils/data-utils';

import { RecruitmentNegotiationRoomFormFields } from './schema';

export const normalizeRecruitmentNegotiationRoomEditData = (
  data: RecruitmentNegotiationRoom
): RecruitmentNegotiationRoomFormFields => ({
  /* GENERAL CONDITIONS */
  conditionMaxRecruitmentPeriod:
    data.negotiatingOffer.generalContractualConditions.maxRecruitmentPeriod,
  conditionMinGuaranteedPeriod:
    data.negotiatingOffer.generalContractualConditions.minGuaranteedPeriod,
  conditionPaymentMethod:
    data.negotiatingOffer.generalContractualConditions.paymentMethod,
  conditionDeposit: parseNumber(
    data.negotiatingOffer.generalContractualConditions.deposit
  ),
  conditionRemunerationType:
    data.negotiatingOffer.generalContractualConditions.remunerationType,
  conditionRemunerationAmount: parseNumber(
    data.negotiatingOffer.generalContractualConditions.remunerationAmount
  ),
  conditionRemunerationHourlyRate: parseNumber(
    data.negotiatingOffer.generalContractualConditions.remunerationHourlyRate
  ),
  conditionRemunerationPercentage:
    data.negotiatingOffer.generalContractualConditions.remunerationPercentage,
  conditionRemunerationEstimatedHours:
    data.negotiatingOffer.generalContractualConditions
      .remunerationEstimatedHours,

  /* HEADHUNTER CONDITIONS */
  headhunterConditionMaximumReplacement:
    data.negotiatingOffer.headhunterContractualConditions?.maximumReplacement,
  headhunterConditionExclusivityPeriod:
    data.negotiatingOffer.headhunterContractualConditions
      ?.headhuntingExclusivityPeriod,

  /* INTERNATIONAL RECRUITMENT CONDITIONS */
  internationalRecruitmentConditionExclusivityPeriod:
    data.negotiatingOffer.internationalRecruitmentContractualConditions
      ?.exclusivityPeriod,
  internationalRecruitmentConditionMaximumReplacement:
    data.negotiatingOffer.internationalRecruitmentContractualConditions
      ?.maximumReplacement,

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumDuration:
    data.negotiatingOffer.outsourcingContractualConditions?.maximumDuration,
  outsourcingConditionExclusivityPeriod:
    data.negotiatingOffer.outsourcingContractualConditions?.exclusivityPeriod,
  outsourcingConditionMaximumBudget: parseNumber(
    data.negotiatingOffer.outsourcingContractualConditions?.maximumBudget
  ),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuyback:
    data.negotiatingOffer.temporaryPlacementContractualConditions
      ?.possibleBuyback,
  temporaryPlacementConditionTravelExpensesResponsibility:
    data.negotiatingOffer.temporaryPlacementContractualConditions
      ?.travelExpensesResponsibility,
  temporaryPlacementConditionTrainingExpensesResponsibility:
    data.negotiatingOffer.temporaryPlacementContractualConditions
      ?.trainingExpensesResponsibility,
  temporaryPlacementConditionCnesstExpensesResponsibility:
    data.negotiatingOffer.temporaryPlacementContractualConditions
      ?.cnesstExpensesResponsibility,
});

export const normalizeRecruitmentNegotiationRoomInputData = (
  data: RecruitmentNegotiationRoomFormFields
) => ({
  ...data,

  /* GENERAL CONDITIONS */
  conditionDeposit: data.conditionDeposit?.toFixed(2) || null,
  conditionRemunerationAmount:
    data.conditionRemunerationAmount?.toFixed(2) || null,
  conditionRemunerationHourlyRate:
    data.conditionRemunerationHourlyRate?.toFixed(2) || null,

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumBudget:
    data.outsourcingConditionMaximumBudget?.toFixed(2) || null,

  fieldInModification: undefined,
});
