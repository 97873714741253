import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountGet } from '@domain/account';

import { toViewModel } from '../view-models/get-profile.vm';

const useGetProfile = () => {
  const { data, fetching, error, reexecuteQuery } = useAccountGet();

  const { t } = useTranslation('organization', {
    keyPrefix: 'updateProfileForm',
  });

  const { setAlertContent } = useContext(AlertContext);

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('messages.errors.get'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const viewModel = toViewModel({
    account: {
      account: {
        data,
        fetching,
        error,
      },
    },
  });

  return {
    viewModel,
    refetchAccount: reexecuteQuery,
  };
};

export default useGetProfile;
