import { useMutation } from 'urql';

import {
  Mutation,
  MutationOfferCandidateDeleteOtherFileArgs,
} from '@domain/graphql.types';
import { MutationOfferCandidateDeleteOtherFile } from '@infrastructure/graphql/offer';

const useOfferCandidateDeleteOtherFile = () => {
  const [{ data, fetching, error }, deleteOfferCandidateOtherFile] =
    useMutation<
      Pick<Mutation, 'offerCandidateDeleteOtherFile'>,
      MutationOfferCandidateDeleteOtherFileArgs
    >(MutationOfferCandidateDeleteOtherFile);

  return {
    deleteOfferCandidateOtherFile,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateDeleteOtherFile;
