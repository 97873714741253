import { gql } from 'urql';

const CandidateOfferTemporaryPlacementConditionsFragment = gql`
  fragment CandidateOfferTemporaryPlacementConditionsFragment on CandidateOfferTemporaryPlacementConditions {
    probationPeriod
    minimumNotice
    possibleBuyback
    possibleBuybackBonus
    travelExpensesResponsibility
    trainingExpensesResponsibility
    employeeHelpProgramResponsibility
    cnesstResponsibility
    otherResponsibilities
    billingPeriod
  }
`;

export default CandidateOfferTemporaryPlacementConditionsFragment;
