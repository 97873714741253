import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Query } from '@domain/graphql.types';

import { normalizeGetData } from '../normalizers';
import { UserProfile } from '../types';

type ToViewModel = {
  user: {
    data: Pick<Query, 'me'> | Record<string, never> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  user: { data = {}, fetching, error },
}: ToViewModel): ViewModel<UserProfile> =>
  new ViewModel<UserProfile>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: normalizeGetData(data),
  });
