import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxGroup } from '@application/components';
import { Box } from '@application/components/container-layouts';
import { extractLanguage } from '@utils/i18n-utils';

import { useGetPerks } from '../../hooks';
import { OrganizationProfileFormFields } from '../../types';

type PerksFieldsProps = {
  control: Control<OrganizationProfileFormFields, any>;
  setValue: UseFormSetValue<OrganizationProfileFormFields>;
  watch: UseFormWatch<OrganizationProfileFormFields>;
  disabled?: boolean;
};

const PerksFields = ({
  control,
  setValue,
  watch,
  disabled = false,
}: PerksFieldsProps) => {
  const { t, i18n } = useTranslation('organization');

  const { data: PERKS_LIST = [] } = useGetPerks();

  const perks = watch('advantageCodes') || [];

  const handleOnChange = (event: any): void => {
    if (event.target.checked) {
      setValue('advantageCodes', [...perks, event.target.value]);
    } else {
      setValue('advantageCodes', [
        ...perks.filter((p) => p !== event.target.value),
      ]);
    }
  };

  return (
    <Box>
      <Controller
        name="advantageCodes"
        control={control}
        render={({ field: { value } }) => (
          <CheckboxGroup
            disabled={disabled}
            data={PERKS_LIST}
            legend={t('labels.perks')}
            language={extractLanguage(i18n.language)}
            onChange={handleOnChange}
            legendSize="small"
            values={value}
          />
        )}
      />
    </Box>
  );
};

export default PerksFields;
