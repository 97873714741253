import { ChangeEvent, useMemo, useState } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Cluster,
  Fieldset,
  FormInputWithSuffix,
  HelperText,
  Radio,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  TextAreaField,
  VisibilitySwitcher,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import {
  RecruitmentOpportunity,
  RequestConditionResponsibility,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';
import { isDefined } from '@utils/type-utils';

import { OfferFormFields } from '../../types';

type TemporaryPlacementFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const TemporaryPlacementFields = ({
  recruitmentOpportunity,
}: TemporaryPlacementFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const [showNotes, setShowNotes] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const buyback = watch('temporaryPlacementConditionPossibleBuyback');
  const foreignApplicants = watch(
    'temporaryPlacementConditionForeignApplicants'
  );
  const travelExpenses = watch(
    'temporaryPlacementConditionTravelExpensesResponsibility'
  );
  const trainingExpenses = watch(
    'temporaryPlacementConditionTrainingExpensesResponsibility'
  );
  const employeeHelpProgram = watch(
    'temporaryPlacementConditionEmployeeHelpProgramResponsibility'
  );
  const cnesstExpenses = watch(
    'temporaryPlacementConditionCnesstExpensesResponsibility'
  );

  const showBuybackBonus = useMemo(() => {
    // If buyback is set from html, it will be a string because of radio buttons
    // But if we get it from backend, it's a boolean
    if (typeof buyback === 'string') {
      return buyback === 'true';
    }

    return buyback;
  }, [buyback]);

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof OfferFormFields
  ) => {
    setValue(name, event.target.value as any);
    trigger(name);
  };

  return (
    <SectionGrid
      title={t('subtitles.forTemporaryPlacement')}
      isOffer
      className="mt-s-40"
    >
      <SectionGridRow
        isTopRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              isDefined(
                recruitmentOpportunity?.temporaryPlacementContractualConditions
                  ?.possibleBuybackValue
              ) ? (
                <div className="flex flex-col">
                  <span className="text-16 font-semibold text-primary">
                    {tGlobal(
                      `boolean.${recruitmentOpportunity?.temporaryPlacementContractualConditions?.possibleBuybackValue}`
                    )}
                  </span>

                  {recruitmentOpportunity
                    ?.temporaryPlacementContractualConditions
                    ?.possibleBuybackValue &&
                    recruitmentOpportunity
                      ?.temporaryPlacementContractualConditions
                      ?.possibleBuybackBonus && (
                      <span className="text-16 font-semibold text-primary">
                        {
                          recruitmentOpportunity
                            ?.temporaryPlacementContractualConditions
                            ?.possibleBuybackBonus
                        }
                        &nbsp;{t('suffix.currency')}
                      </span>
                    )}
                </div>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!recruitmentOpportunity?.temporaryPlacementContractualConditions
                ?.possibleBuybackDisplay
            }
          />
        }
      >
        <div className="flex flex-col gap-s-16">
          <Controller
            name="temporaryPlacementConditionPossibleBuyback"
            control={control}
            render={({ field: { name } }) => (
              <RadioGroup
                name={name}
                data={BOOLEAN_VALUES}
                legend={t('labels.buyback')}
                language={extractLanguage(i18n.language)}
                onChange={(e) =>
                  handleOnRadioChange(e as ChangeEvent<HTMLInputElement>, name)
                }
                legendSize="medium"
                alignRow
                value={buyback ?? undefined}
                invalid={!!errors.temporaryPlacementConditionPossibleBuyback}
                helperText={
                  errors.temporaryPlacementConditionPossibleBuyback?.message &&
                  tGlobal(
                    errors.temporaryPlacementConditionPossibleBuyback?.message
                  )
                }
              />
            )}
          />

          {showBuybackBonus && (
            <FormInputWithSuffix
              label={t('labels.buybackBonus')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.temporaryPlacementConditionPossibleBuybackBonus}
              helperText={
                errors.temporaryPlacementConditionPossibleBuybackBonus
                  ?.message &&
                tGlobal(
                  errors.temporaryPlacementConditionPossibleBuybackBonus
                    ?.message,
                  {
                    max: '99,999,999.99',
                    min: '1',
                  }
                )
              }
              {...register('temporaryPlacementConditionPossibleBuybackBonus')}
            />
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={<VisibilitySwitcher content={<span>-</span>} visible />}
      >
        <Controller
          name="temporaryPlacementConditionRisks"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              value={value || ''}
              className="w-full"
              label={t('labels.risks')}
              labelSize="medium"
              rows={4}
              maxChar={1024}
              onChange={onChange}
              invalid={!!errors.temporaryPlacementConditionRisks}
              helperText={
                errors.temporaryPlacementConditionRisks?.message &&
                tGlobal(errors.temporaryPlacementConditionRisks?.message, {
                  max: '1024',
                })
              }
            />
          )}
        />
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="text-16 text-primary font-semibold text-center">
                <span className="font-normal text-14">
                  {t('labels.responsibilityTravel')}&nbsp;
                </span>
                <p className="mb-s-10">
                  {recruitmentOpportunity
                    ?.temporaryPlacementContractualConditions
                    ?.travelExpensesResponsibilityValue
                    ? t(
                        `enum.requestConditionResponsibility.${recruitmentOpportunity.temporaryPlacementContractualConditions.travelExpensesResponsibilityValue.toLowerCase()}`
                      )
                    : '-'}
                </p>

                <span className="font-normal text-14">
                  {t('labels.responsibilityTraining')}&nbsp;
                </span>
                <p className="mb-s-10">
                  {recruitmentOpportunity
                    ?.temporaryPlacementContractualConditions
                    ?.trainingExpensesResponsibilityValue
                    ? t(
                        `enum.requestConditionResponsibility.${recruitmentOpportunity.temporaryPlacementContractualConditions.trainingExpensesResponsibilityValue.toLowerCase()}`
                      )
                    : '-'}
                </p>

                <span className="font-normal text-14">
                  {t('labels.employeeHelpProgram')}&nbsp;
                </span>
                <p className="mb-s-10">
                  {recruitmentOpportunity
                    ?.temporaryPlacementContractualConditions
                    ?.employeeHelpProgramResponsibilityValue
                    ? t(
                        `enum.requestConditionResponsibility.${recruitmentOpportunity.temporaryPlacementContractualConditions.employeeHelpProgramResponsibilityValue.toLowerCase()}`
                      )
                    : '-'}
                </p>

                <span className="font-normal text-14">
                  {t('labels.responsibilityCnesst')}&nbsp;
                </span>
                <p className="mb-s-10">
                  {recruitmentOpportunity
                    ?.temporaryPlacementContractualConditions
                    ?.cnesstExpensesResponsibilityValue
                    ? t(
                        `enum.requestConditionResponsibility.${recruitmentOpportunity.temporaryPlacementContractualConditions.cnesstExpensesResponsibilityValue.toLowerCase()}`
                      )
                    : '-'}
                </p>
              </div>
            }
            visible={
              !!recruitmentOpportunity?.temporaryPlacementContractualConditions
                ?.responsibilitiesDisplay
            }
          />
        }
      >
        <Fieldset
          legend={t('labels.defineResponsibilities')}
          legendSize="medium"
        >
          <Controller
            name="temporaryPlacementConditionTravelExpensesResponsibility"
            control={control}
            render={({ field: { name } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.travelCosts')}
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) =>
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            )
                          }
                          checked={travelExpenses === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>
                {errors?.temporaryPlacementConditionTravelExpensesResponsibility
                  ?.message && (
                  <HelperText
                    id="temporaryPlacementConditionTravelExpensesResponsibility-error"
                    invalid
                  >
                    {tGlobal(
                      errors
                        ?.temporaryPlacementConditionTravelExpensesResponsibility
                        .message
                    )}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          <Controller
            name="temporaryPlacementConditionTrainingExpensesResponsibility"
            control={control}
            render={({ field: { name } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.trainingCosts')}
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) =>
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            )
                          }
                          checked={trainingExpenses === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>
                {errors
                  ?.temporaryPlacementConditionTrainingExpensesResponsibility
                  ?.message && (
                  <HelperText
                    id="temporaryPlacementConditionTrainingExpensesResponsibility-error"
                    invalid
                  >
                    {tGlobal(
                      errors
                        ?.temporaryPlacementConditionTrainingExpensesResponsibility
                        .message
                    )}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          <Controller
            name="temporaryPlacementConditionEmployeeHelpProgramResponsibility"
            control={control}
            render={({ field: { name } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.employeeHelpProgram')}
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) =>
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            )
                          }
                          checked={employeeHelpProgram === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>
                {errors
                  ?.temporaryPlacementConditionEmployeeHelpProgramResponsibility
                  ?.message && (
                  <HelperText
                    id="temporaryPlacementConditionEmployeeHelpProgramResponsibility-error"
                    invalid
                  >
                    {tGlobal(
                      errors
                        ?.temporaryPlacementConditionEmployeeHelpProgramResponsibility
                        .message
                    )}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          <Controller
            name="temporaryPlacementConditionCnesstExpensesResponsibility"
            control={control}
            render={({ field: { name } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    <span>
                      {t('labels.cnesst')}
                      <Button
                        ghost
                        className="btn-link hover:!bg-transparent !min-w-fit px-s-8"
                        onClick={() => setShowNotes((state) => !state)}
                      >
                        {t('responsibilitiesNotes.notesHere')}
                      </Button>
                    </span>
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) =>
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            )
                          }
                          checked={cnesstExpenses === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>
                {errors?.temporaryPlacementConditionCnesstExpensesResponsibility
                  ?.message && (
                  <HelperText
                    id="temporaryPlacementConditionCnesstExpensesResponsibility-error"
                    invalid
                  >
                    {tGlobal(
                      errors
                        ?.temporaryPlacementConditionCnesstExpensesResponsibility
                        .message
                    )}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          {showNotes && (
            <div className="my-s-16 p-s-24 bg-neutral rounded-md">
              <div className="flex justify-between items-center mb-s-16">
                <span>{t('responsibilitiesNotes.title')}</span>

                <Button
                  ghost
                  className="btn-link hover:!bg-transparent bg-transparent !min-w-fit px-s-0"
                  onClick={() => setShowNotes((state) => !state)}
                >
                  {t('responsibilitiesNotes.close')}
                </Button>
              </div>

              <ul className="flex flex-col gap-s-16 text-14 mb-s-16 list-disc	pl-s-16">
                <li>{t('responsibilitiesNotes.firstResponsibility')}</li>
                <li>{t('responsibilitiesNotes.secondResponsibility')}</li>
              </ul>
            </div>
          )}

          <Controller
            name="temporaryPlacementConditionOtherResponsibilities"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <TextAreaField
                name={name}
                value={value || ''}
                label={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.otherResponsibilities')}
                  </div>
                }
                labelSize="medium"
                rows={4}
                maxChar={1024}
                onChange={onChange}
                invalid={
                  !!errors.temporaryPlacementConditionOtherResponsibilities
                }
                helperText={
                  errors.temporaryPlacementConditionOtherResponsibilities
                    ?.message &&
                  tGlobal(
                    errors.temporaryPlacementConditionOtherResponsibilities
                      ?.message,
                    { max: '1024' }
                  )
                }
              />
            )}
          />
        </Fieldset>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              recruitmentOpportunity?.temporaryPlacementContractualConditions
                ?.foreignApplicantsValue ? (
                <span className="text-16 font-semibold text-primary">
                  {tGlobal(
                    `boolean.${recruitmentOpportunity.temporaryPlacementContractualConditions.foreignApplicantsValue}`
                  )}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!recruitmentOpportunity?.temporaryPlacementContractualConditions
                ?.foreignApplicantsDisplay
            }
          />
        }
      >
        <Controller
          name="temporaryPlacementConditionForeignApplicants"
          control={control}
          render={({ field: { name } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.foreignCandidates')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as any);
              }}
              legendSize="medium"
              alignRow
              value={foreignApplicants ?? undefined}
            />
          )}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default TemporaryPlacementFields;
