import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useMeQuery } from '@domain/user';

import { toViewModel } from '../view-models/get-profile.vm';

const useGetProfile = () => {
  const { data, fetching, error, reexecuteQuery } = useMeQuery();

  const { t } = useTranslation('user');

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    user: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('messages.getError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length, setAlertContent]);

  return {
    viewModel,
    refetchUser: reexecuteQuery,
  };
};

export default useGetProfile;
