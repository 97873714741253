// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import { TextInput } from '@application/components/form/text-input-field/textInput';
import { PlaceholderSwitcher } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { RecruitmentNegotiationRoomFormFields } from '../../../room/schema';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';
import { getCellStyleForState } from '../../utils';

type OutsourcingConditionsProps = {
  modificationEnabled: boolean;
  onSubmit: () => Promise<void>;
  room: RecruitmentNegotiationRoom;
};

const maximumDurationKey: keyof RecruitmentNegotiationRoomFormFields =
  'outsourcingConditionMaximumDuration';
const exclusivityPeriodKey: keyof RecruitmentNegotiationRoomFormFields =
  'outsourcingConditionExclusivityPeriod';
const maximumBudgetKey: keyof RecruitmentNegotiationRoomFormFields =
  'outsourcingConditionMaximumBudget';

const OutsourcingConditions = ({
  modificationEnabled,
  onSubmit,
  room,
}: OutsourcingConditionsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const { control, setValue, watch } =
    useFormContext<RecruitmentNegotiationRoomFormFields>();

  const fieldInModification = watch('fieldInModification');

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('outsourcingConditions')}
        </th>

        <td
          className={cn(TH_ROW_CLASS_NAME.highlight, 'border-l-1 border-b-1')}
        >
          &nbsp;
        </td>
      </tr>

      <tr className="[&:last-child>th]:rounded-bl-md">
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('maximumDuration')} lineClamp={2}>
            <div>{t('maximumDuration')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === maximumDurationKey,
          })}
        >
          {fieldInModification === maximumDurationKey ? (
            <Controller
              name="outsourcingConditionMaximumDuration"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.outsourcingPropertiesState.maximumDuration
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer.outsourcingContractualConditions
                      ?.maximumDuration
                  }
                >
                  <span>
                    {tGlobal('suffix.week', {
                      count: room.negotiatingOffer
                        .outsourcingContractualConditions
                        ?.maximumDuration as number,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() =>
                  setValue('fieldInModification', maximumDurationKey)
                }
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr className="[&:last-child>th]:rounded-bl-md">
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('exclusivityPeriod')} lineClamp={2}>
            <div>{t('exclusivityPeriod')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === exclusivityPeriodKey,
          })}
        >
          {fieldInModification === exclusivityPeriodKey ? (
            <Controller
              name="outsourcingConditionExclusivityPeriod"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.outsourcingPropertiesState.exclusivityPeriod
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer.outsourcingContractualConditions
                      ?.exclusivityPeriod
                  }
                >
                  <span>
                    {tGlobal('suffix.week', {
                      count: room.negotiatingOffer
                        .outsourcingContractualConditions
                        ?.exclusivityPeriod as number,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() =>
                  setValue('fieldInModification', exclusivityPeriodKey)
                }
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr className="[&:last-child>th]:rounded-bl-md">
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('maximumBudget')} lineClamp={2}>
            <div>{t('maximumBudget')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group border-b-0', {
            'p-s-0': fieldInModification === maximumBudgetKey,
          })}
        >
          {fieldInModification === maximumBudgetKey ? (
            <Controller
              name="outsourcingConditionMaximumBudget"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.outsourcingPropertiesState.maximumBudget
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer.outsourcingContractualConditions
                      ?.maximumBudget
                  }
                >
                  <span>
                    {tGlobal('suffix.currencyPerHourValue', {
                      value:
                        room.negotiatingOffer.outsourcingContractualConditions
                          ?.maximumBudget,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() =>
                  setValue('fieldInModification', maximumBudgetKey)
                }
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default OutsourcingConditions;
