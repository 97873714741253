// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.min.css';
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { Switcher } from '@application/components/container-layouts';
import { Modal } from '@application/components/modal';
import { ModalContext } from '@application/context';

type Image = {
  data: string;
  name: string;
  type: string;
};

type UseUploadImage = {
  modalTitle?: string;
  confirmButtonLabel?: string;
};

const maxImageSize = 480;

const allowedTypes = ['image/png', 'image/jpeg'];
/**
 * Use this hook in order to upload a selected image from an input file,
 * preview the rendered image into a modal, crop the image as desired
 * and return the cropped image file and data.
 */
const useUploadImage = (options?: UseUploadImage) => {
  const [selectedImage, setSelectedImage] = useState<Image | undefined>();
  const [imageFile, setImageFile] = useState<File | undefined>();
  const [croppedImageData, setCroppedImageData] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { t } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const cropperRef = createRef<ReactCropperElement>();

  const cropImage = useCallback(() => {
    if (
      typeof cropperRef.current?.cropper === 'undefined' ||
      selectedImage === undefined
    ) {
      return;
    }

    const canvasData: HTMLCanvasElement =
      cropperRef.current?.cropper.getCroppedCanvas({
        maxHeight: maxImageSize,
        maxWidth: maxImageSize,
      });

    canvasData.toBlob((blob) => {
      if (!blob) {
        return;
      }
      // create a new image file from the cropped image blob
      const croppedFile = new File([blob], selectedImage.name, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });
      setImageFile(croppedFile);
    }, selectedImage.type);

    setCroppedImageData(canvasData.toDataURL());
  }, [cropperRef, selectedImage]);

  const handleConfirmClick = useCallback(() => {
    cropImage();

    setSelectedImage(undefined);
    setModal(null);
  }, [cropImage, setModal]);

  const handleClose = useCallback(() => {
    setSelectedImage(undefined);
    setModal(null);
  }, [setModal]);

  const PreviewModalContent = useMemo(
    () => (
      <>
        <Modal.CloseBtn onClick={handleClose} />

        <div>
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1}
            src={selectedImage?.data}
            viewMode={2}
            responsive
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            dragMode="move"
            cropBoxResizable
            cropBoxMovable
            center
            toggleDragModeOnDblclick={false}
            minCropBoxWidth={240}
            minCropBoxHeight={240}
            guides
          />
        </div>

        <Modal.Action className="w-full [&>div]:w-full">
          <Switcher threshold="none">
            <Button className="grow" onClick={handleClose}>
              {t('button.cancel')}
            </Button>
            <Button primary className="grow" onClick={handleConfirmClick}>
              {options?.confirmButtonLabel || t('button.confirm')}
            </Button>
          </Switcher>
        </Modal.Action>
      </>
    ),
    [
      cropperRef,
      handleClose,
      handleConfirmClick,
      options?.confirmButtonLabel,
      selectedImage?.data,
      t,
    ]
  );

  const getImageExtensions = () =>
    allowedTypes.map((type) => type.split('/')[1].toUpperCase());

  const onFileChange = useCallback(
    (event: any) => {
      event.preventDefault();

      // validate if files exist
      if (!event.target.files || event.target.files.length === 0) {
        setError(t('uploadImage.errors.noFiles'));
        return;
      }

      // get selected image file
      const selectedImageFile = event.target.files[0];

      // validate image types
      if (!allowedTypes.includes(selectedImageFile?.type)) {
        const imageExtensions = getImageExtensions().join(', ');
        setError(t('uploadImage.errors.notAllowed', { imageExtensions }));
        return;
      }

      // read selected image file
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage({
          data: reader.result as any,
          name: selectedImageFile.name,
          type: selectedImageFile.type,
        });
      };
      reader.readAsDataURL(selectedImageFile);
    },
    [t]
  );

  useEffect(() => {
    if (!selectedImage) return;

    setModal({
      title: options?.modalTitle || t('uploadImage.modalTitle'),
      content: PreviewModalContent,
    });
  }, [PreviewModalContent, options?.modalTitle, selectedImage, setModal, t]);

  return {
    error,
    imageFile,
    croppedImageData,
    onFileChange,
    allowedTypes,
  };
};

export default useUploadImage;
