import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { OfferCandidate } from '@domain/graphql.types';

type ToViewModel = {
  requests: {
    data: OfferCandidate[];
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  requests: { data, fetching, error },
}: ToViewModel): ViewModel<OfferCandidate[]> =>
  new ViewModel<OfferCandidate[]>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
