import { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Fieldset, HelperText, Radio } from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import {
  RequestConditionPaymentFrequencyCode as PaymentFrequencyCode,
  RequestConditionPaymentMethodCode as PaymentMethodCode,
} from '@domain/graphql.types';

import { OfferFormFields } from '../../../types';

const PaymentMethodsFields = () => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const paymentMethod = watch('conditionPaymentMethod');
  const paymentFrequency = watch('conditionPaymentFrequency');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof OfferFormFields
  ) => {
    setValue(name, event.target.value as any);
    trigger(name);
    trigger('conditionPaymentFrequency');
  };

  useEffect(() => {
    if (paymentMethod !== PaymentMethodCode.StartOfAgreement) {
      setValue('conditionPaymentFrequency', null, {
        shouldValidate: false,
      });
    }
  }, [paymentMethod, setValue]);

  return (
    <Fieldset legend={t('labels.preferredPaymentMethod')} legendSize="medium">
      <Controller
        name="conditionPaymentMethod"
        control={control}
        render={({ field: { name } }) => (
          <Cluster space={16} as="ul">
            {Object.values(PaymentMethodCode).map((method) => (
              <li key={method}>
                <Radio
                  name={name}
                  value={method}
                  label={t(
                    `enum.requestConditionPaymentMethodCode.${method.toLowerCase()}`
                  )}
                  onChange={(e) =>
                    handleOnRadioChange(
                      e as ChangeEvent<HTMLInputElement>,
                      name
                    )
                  }
                  checked={paymentMethod === method}
                  asButton
                />
              </li>
            ))}
          </Cluster>
        )}
      />
      {errors.conditionPaymentMethod?.message && (
        <HelperText id="conditionPaymentMethod-error" invalid>
          {tGlobal(errors.conditionPaymentMethod.message)}
        </HelperText>
      )}

      {paymentMethod === PaymentMethodCode.StartOfAgreement && (
        <Controller
          name="conditionPaymentFrequency"
          control={control}
          render={({ field: { name } }) => (
            <Cluster space={16} as="ul" className="mt-s-16">
              {Object.values(PaymentFrequencyCode).map((method) => (
                <li key={method}>
                  <Radio
                    name={name}
                    value={method}
                    label={t(
                      `enum.requestConditionPaymentFrequencyCode.${method.toLowerCase()}`
                    )}
                    asButton
                    onChange={(e) =>
                      handleOnRadioChange(
                        e as ChangeEvent<HTMLInputElement>,
                        name
                      )
                    }
                    checked={paymentFrequency === method}
                  />
                </li>
              ))}
            </Cluster>
          )}
        />
      )}
      {errors.conditionPaymentFrequency?.message && (
        <HelperText id="conditionPaymentFrequency-error" invalid>
          {tGlobal(errors.conditionPaymentFrequency.message)}
        </HelperText>
      )}
    </Fieldset>
  );
};

export default PaymentMethodsFields;
