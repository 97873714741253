import { useTranslation } from 'react-i18next';

import { CandidateOffer, CandidateRequestType } from '@domain/graphql.types';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type CandidateInitialOfferTableProps = {
  candidateOffer: CandidateOffer;
  type?: CandidateRequestType;
};

const CandidateInitialOfferTable = ({
  candidateOffer,
  type,
}: CandidateInitialOfferTableProps) => {
  const { t } = useTranslation('candidates');

  return (
    <table className="bg-neutral">
      <caption className="sr-only">{t('initialOffer')}</caption>
      <TableHeader />
      <TableBody candidateOffer={candidateOffer} type={type} />
    </table>
  );
};

export default CandidateInitialOfferTable;
