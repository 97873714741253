import { ChangeEvent, useCallback } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@application/components/container-layouts';
import { SwitchField } from '@application/components/form/switch-field';

import { NotificationsConfigurationFormFields } from '../schema';

type MethodsFieldsProps = {
  setValue: UseFormSetValue<NotificationsConfigurationFormFields>;
  register: UseFormRegister<NotificationsConfigurationFormFields>;
  isLoading: boolean;
};

const MethodsFields = ({
  setValue,
  register,
  isLoading,
}: MethodsFieldsProps) => {
  const { t } = useTranslation('user');

  const onNoneChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('inApp', false);
        setValue('email', false);
      }
    },
    [setValue]
  );

  const onMethodChange = useCallback(
    (field: keyof Omit<NotificationsConfigurationFormFields, 'none'>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          setValue('none', false);
        }

        setValue(field, event.target.checked);
      },
    [setValue]
  );

  return (
    <>
      <Box className="flex items-center justify-between">
        <span>{t('labels.inApp')}</span>

        <SwitchField
          hideLabel
          label={t('labels.inApp')}
          disabled={isLoading}
          {...register('inApp')}
          onChange={onMethodChange('inApp')}
        />
      </Box>

      <Box className="flex items-center justify-between">
        <span>{t('labels.byEmail')}</span>

        <SwitchField
          hideLabel
          label={t('labels.byEmail')}
          disabled={isLoading}
          {...register('email')}
          onChange={onMethodChange('email')}
        />
      </Box>

      <Box className="flex items-center justify-between">
        <span>{t('labels.none')}</span>

        <SwitchField
          hideLabel
          label={t('labels.none')}
          disabled={isLoading}
          {...register('none')}
          onChange={onNoneChange}
        />
      </Box>
    </>
  );
};

export default MethodsFields;
