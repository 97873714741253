import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { IconButton } from '@application/components/buttons';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type TemporaryPlacementConditionsProps = {
  data: Request | undefined;
};

const TemporaryPlacementConditions = ({
  data,
}: TemporaryPlacementConditionsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow
        isTopRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.temporaryPlacementContractualConditions
                    ?.possibleBuybackDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.buyback')}</span>
        <PlaceholderSwitcher
          value={
            data?.temporaryPlacementContractualConditions?.possibleBuybackValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${!!data?.temporaryPlacementContractualConditions
                ?.possibleBuybackValue}`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.temporaryPlacementContractualConditions?.risksDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.risks')}</span>
        <PlaceholderSwitcher
          value={data?.temporaryPlacementContractualConditions?.risksValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.temporaryPlacementContractualConditions?.risksValue}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.temporaryPlacementContractualConditions
                    ?.responsibilitiesDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
        className="block"
      >
        <div className="flex flex-wrap items-center gap-s-16">
          <p>{t('labels.responsibilities')}</p>
          <div>
            <ul className="list-disc pl-s-16">
              <li>
                {t('labels.travelCosts')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.travelExpensesResponsibilityValue
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.travelExpensesResponsibilityValue?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
              <li>
                {t('labels.trainingCosts')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.trainingExpensesResponsibilityValue
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.trainingExpensesResponsibilityValue?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
              <li>
                {t('labels.employeeHelpProgram')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.employeeHelpProgramResponsibilityValue
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.employeeHelpProgramResponsibilityValue?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
              <li>
                {t('labels.cnesst')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.cnesstExpensesResponsibilityValue
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.cnesstExpensesResponsibilityValue?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
              <li>
                {t('labels.other')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.otherResponsibilitiesValue
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {
                      data?.temporaryPlacementContractualConditions
                        ?.otherResponsibilitiesValue
                    }
                  </span>
                </PlaceholderSwitcher>
              </li>
            </ul>
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.temporaryPlacementContractualConditions
                    ?.foreignApplicantsDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.foreignCandidates')}</span>
        <PlaceholderSwitcher
          value={
            data?.temporaryPlacementContractualConditions
              ?.foreignApplicantsValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${!!data?.temporaryPlacementContractualConditions
                ?.foreignApplicantsValue}`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>
    </>
  );
};

export default TemporaryPlacementConditions;
