import { gql } from 'urql';

const AccountFragment = gql`
  fragment AccountFragment on Account {
    name
    type
  }
`;

export default AccountFragment;
