import { useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import { HelperText, Label } from '../form';
import { RangeSlider } from '../range-slider';
import { CONTROL_STYLES } from '../select-field/select/constants';

interface DropdownRangeProps {
  label: string;
  labelRange: string;
  placeholder: string;
  minLabel: string;
  maxLabel: string;
  minName: string;
  maxName: string;
  minValue?: number;
  maxValue?: number;
  defaultMinValue?: number;
  defaultMaxValue?: number;
  min: number;
  max: number;
  suffix?: string;
  step?: number;
  setValue: (name: string, value: number | string | null) => void;
  invalid?: boolean;
  helperText?: string;
  className?: string;
}

const DropdownRange = ({
  label,
  labelRange,
  placeholder,
  minLabel,
  maxLabel,
  minName,
  maxName,
  minValue,
  maxValue,
  defaultMinValue,
  defaultMaxValue,
  min,
  max,
  step,
  suffix,
  setValue,
  invalid,
  helperText,
  className: customClassName,
}: DropdownRangeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const helperTextId = useMemo(() => randomString(), []);

  const ref = useRef<HTMLDivElement>(null);

  const className = cn('relative', customClassName);

  const toggleDropdown = () => {
    if (!isOpen && (minValue === undefined || maxValue === undefined)) {
      setValue(minName, defaultMinValue || min);
      setValue(maxName, defaultMaxValue || max);
    }
    setIsOpen(!isOpen);
  };

  const isRangeValueValid = useMemo(
    () => Number.isFinite(minValue) && Number.isFinite(maxValue),
    [minValue, maxValue]
  );

  const clearSelection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setValue(minName, null);
    setValue(maxName, null);
    setIsOpen(false);
  };

  useOnClickOutside(ref, () => {
    if (!isRangeValueValid) {
      setValue(minName, null);
      setValue(maxName, null);
    }

    if (!!minValue && !!maxValue && minValue > maxValue) {
      setValue(minName, min);
    }

    setIsOpen(false);
  });

  const buttonClassName = cn(
    CONTROL_STYLES.base,
    'input input-bordered w-full border-stroke-default focus:border-primary focus:outline-transparent placeholder:text-neutral-secondary flex justify-between items-center',
    invalid && CONTROL_STYLES.invalid
  );

  return (
    <div className={className} ref={ref}>
      <Label htmlFor="dropdown" invalid={invalid}>
        {label}
      </Label>
      <div className="flex flex-col">
        <div className="relative">
          <button
            name="dropdown"
            type="button"
            onClick={toggleDropdown}
            className={buttonClassName}
          >
            <span className="flex-grow text-left">
              {isRangeValueValid && placeholder}
            </span>
            <div className="flex items-center">
              {isRangeValueValid && (minValue || maxValue) && (
                <span
                  aria-label="Clear Selection"
                  role="button"
                  tabIndex={0}
                  onKeyDown={toggleDropdown}
                  onClick={clearSelection}
                  className="mr-2 text-neutral-secondary hover:text-neutral-dark"
                >
                  <i className="ri-close-line text-20" />
                </span>
              )}
              <div
                className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
              >
                <i className="ri-arrow-down-s-line text-20" />
              </div>
            </div>
          </button>
        </div>
        {isOpen && (
          <div className="absolute top-full mt-s-4 p-s-16 left-0 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg p-4 z-10">
            <span className="text-16">{labelRange}</span>
            <RangeSlider
              minLabel={minLabel}
              maxLabel={maxLabel}
              minName={minName}
              maxName={maxName}
              onChange={setValue}
              minValue={minValue}
              maxValue={maxValue}
              min={min}
              max={max}
              step={step}
              suffix={suffix}
              inputsThreshold="none"
              textInputFieldsClassName="flex-1"
            />
          </div>
        )}
      </div>

      {helperText && (
        <HelperText id={helperTextId} invalid={invalid}>
          {helperText}
        </HelperText>
      )}
    </div>
  );
};

export default DropdownRange;
