import * as yup from 'yup';

import { AccountIndustryType } from '@domain/graphql.types';

export const SPECIALTIES_ONBOARDING_SCHEMA = yup.object({
  industries: yup
    .array()
    .of(
      yup
        .mixed<AccountIndustryType>()
        .oneOf(Object.values(AccountIndustryType))
        .required('forms.rules.required')
    )
    .required('forms.rules.required'),
  sectors: yup
    .array()
    .of(yup.string().required('forms.rules.required'))
    .required('forms.rules.required'),
  specialties: yup
    .array()
    .of(yup.string().required('forms.rules.required'))
    .required('forms.rules.required'),
});
