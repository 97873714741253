import { Link } from 'react-router-dom';

import { Box, Stack } from '@application/components';

type SocialLinksProps = {
  facebookUrl?: string;
  linkedinUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
};

const SocialLinks = ({
  facebookUrl,
  linkedinUrl,
  twitterUrl,
  instagramUrl,
}: SocialLinksProps) => {
  if (!facebookUrl && !instagramUrl && !linkedinUrl && !twitterUrl) {
    return null;
  }

  return (
    <Box>
      <Stack as="ul" space={8} className="text-14">
        {linkedinUrl && (
          <li>
            <i className="ri-linkedin-box-line text-20 mr-s-8" />
            <Link to={linkedinUrl}>LinkedIn</Link>
          </li>
        )}

        {facebookUrl && (
          <li>
            <i className="ri-facebook-box-line text-20 mr-s-8" />
            <Link to={facebookUrl as string}>Facebook</Link>
          </li>
        )}

        {twitterUrl && (
          <li>
            <i className="ri-twitter-x-line text-16 mr-s-8 ml-[3px]" />
            <Link to={twitterUrl as string}>Twitter - X</Link>
          </li>
        )}

        {instagramUrl && (
          <li>
            <i className="ri-instagram-line text-20 mr-s-8" />
            <Link to={instagramUrl as string}>Instagram</Link>
          </li>
        )}
      </Stack>
    </Box>
  );
};

export default SocialLinks;
