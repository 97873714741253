import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { RecruitmentOpportunity } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../types';

type InternationalRecruitmentFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const InternationalRecruitmentFields = ({
  recruitmentOpportunity,
}: InternationalRecruitmentFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const mandatoryWorkPermit = watch(
    'internationalRecruitmentConditionMandatoryWorkPermit'
  );

  return (
    <SectionGrid
      title={t('subtitles.forInternationalRecruitment')}
      isOffer
      className="mt-s-40"
    >
      <SectionGridRow
        isTopRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              recruitmentOpportunity
                ?.internationalRecruitmentContractualConditions
                ?.mandatoryWorkPermitValue ? (
                <span className="text-16 font-semibold text-primary">
                  {tGlobal(
                    `boolean.${recruitmentOpportunity.internationalRecruitmentContractualConditions.mandatoryWorkPermitValue}`
                  )}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!recruitmentOpportunity
                ?.internationalRecruitmentContractualConditions
                ?.mandatoryWorkPermitDisplay
            }
          />
        }
      >
        <Controller
          name="internationalRecruitmentConditionMandatoryWorkPermit"
          control={control}
          render={({ field: { name } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.validWorkPermit')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as any);
                trigger(name);
              }}
              legendSize="medium"
              alignRow
              value={mandatoryWorkPermit ?? undefined}
              invalid={
                !!errors.internationalRecruitmentConditionMandatoryWorkPermit
              }
              helperText={
                errors.internationalRecruitmentConditionMandatoryWorkPermit
                  ?.message &&
                tGlobal(
                  errors.internationalRecruitmentConditionMandatoryWorkPermit
                    ?.message
                )
              }
            />
          )}
        />
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              recruitmentOpportunity
                ?.internationalRecruitmentContractualConditions
                ?.exclusivityPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.week', {
                    count:
                      recruitmentOpportunity
                        .internationalRecruitmentContractualConditions
                        .exclusivityPeriodValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!recruitmentOpportunity
                ?.internationalRecruitmentContractualConditions
                ?.exclusivityPeriodDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.foreignCandidatesExclusivityPeriod')}
          type="number"
          suffix={t('suffix.weeks')}
          mediumSuffix
          noMargin
          minValue={1}
          invalid={!!errors.internationalRecruitmentConditionExclusivityPeriod}
          helperText={
            errors.internationalRecruitmentConditionExclusivityPeriod
              ?.message &&
            tGlobal(
              errors.internationalRecruitmentConditionExclusivityPeriod
                ?.message,
              { min: '1' }
            )
          }
          {...register('internationalRecruitmentConditionExclusivityPeriod')}
        />
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              recruitmentOpportunity
                ?.internationalRecruitmentContractualConditions
                ?.maximumReplacementValue ? (
                <span className="text-16 font-semibold text-primary">
                  {
                    recruitmentOpportunity
                      .internationalRecruitmentContractualConditions
                      .maximumReplacementValue
                  }
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!recruitmentOpportunity
                ?.internationalRecruitmentContractualConditions
                ?.maximumReplacementDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.maxReplacements')}
          type="number"
          noMargin
          minValue={1}
          invalid={!!errors.internationalRecruitmentConditionMaximumReplacement}
          helperText={
            errors.internationalRecruitmentConditionMaximumReplacement
              ?.message &&
            tGlobal(
              errors.internationalRecruitmentConditionMaximumReplacement
                ?.message,
              {
                min: '1',
              }
            )
          }
          {...register('internationalRecruitmentConditionMaximumReplacement')}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default InternationalRecruitmentFields;
