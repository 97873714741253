import { Languages } from '@application/enums/languages';

export const extractLanguage = (
  i18nLanguage: string
): keyof typeof Languages => {
  if (i18nLanguage.length >= 2) {
    const extractedLanguage = i18nLanguage.substring(0, 2);

    return extractedLanguage in Languages
      ? Languages[extractedLanguage as keyof typeof Languages]
      : Languages.en;
  }

  return i18nLanguage in Languages
    ? (i18nLanguage as keyof typeof Languages)
    : Languages.en;
};

export const getLocalizedDescription = (
  descriptions: { fr: string; en: string } | undefined,
  i18nLanguage: string
): string | undefined => {
  const extractedLanguage = extractLanguage(i18nLanguage);

  if (!(extractedLanguage in Languages) || !descriptions) {
    return undefined;
  }

  return descriptions[extractedLanguage];
};
