import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RankBadge } from '@application/components';
import {
  CandidateNegotiationRoom,
  CandidateNegotiationRoomStatus,
  NegotiatingCandidateOfferUpdateInput,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import useUpdateNegotiatingCandidateOffer from '../../hooks/useUpdateNegotiatingCandidateOffer';
import {
  normalizeCandidateNegotiationRoomEditData,
  normalizeCandidateNegotiationRoomInputData,
} from '../../room/normalizers';
import { CANDIDATE_NEGOTIATION_ROOM_SCHEMA } from '../../room/schema';
import { CandidateNegotiationRoomFormFields } from '../../room/types';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../constants';
import { EmployerBrand, OfferToRecruiter } from './rows';

type TableBodyProps = {
  room: CandidateNegotiationRoom;
};

const TableBody = ({ room }: TableBodyProps) => {
  const { t } = useTranslation('candidates');

  const methods = useForm<CandidateNegotiationRoomFormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(CANDIDATE_NEGOTIATION_ROOM_SCHEMA),
  });

  const { setValue, watch, reset, getValues } = methods;

  const { updateNegotiatingCandidateOffer } =
    useUpdateNegotiatingCandidateOffer();

  const {
    viewModel: { isLoading },
  } = useUpdateNegotiatingCandidateOffer();

  const onSubmit: SubmitHandler<CandidateNegotiationRoomFormFields> =
    useCallback(
      async (values) => {
        setValue('fieldInModification', null);
        // NOTE: Since we use separated forms to edit specific values (i.e. into a modal), in order to trigger validations ONLY on those values,
        // we don't have a choice here to merge those values to ones from the original schema at submit.
        await updateNegotiatingCandidateOffer({
          input: {
            ...normalizeCandidateNegotiationRoomInputData({
              ...getValues(),
              ...values,
            }),
            id: room.negotiatingCandidateOfferId,
          } as NegotiatingCandidateOfferUpdateInput,
        });
      },
      [
        getValues,
        room.negotiatingCandidateOfferId,
        setValue,
        updateNegotiatingCandidateOffer,
      ]
    );

  const fieldInModification = watch('fieldInModification');

  useEffect(() => {
    if (room) {
      const updatedDefaultValues =
        normalizeCandidateNegotiationRoomEditData(room);

      reset(updatedDefaultValues);
    }
  }, [room, reset]);

  const modificationEnabled = useMemo(
    () =>
      !fieldInModification &&
      room.status === CandidateNegotiationRoomStatus.InProgress,
    [fieldInModification, room.status]
  );

  return (
    <tbody className="[&>tr:last-child>th]:rounded-bl-md">
      <tr>
        <th scope="col" className={cn(TH_ROW_CLASS_NAME.base, 'h-[7rem]')}>
          <span>{t('labels.rank')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.base, 'h-[7rem] text-center')}>
          {room.negotiatingCandidateOffer.rank && (
            <RankBadge rank={room.negotiatingCandidateOffer.rank} />
          )}
        </td>
      </tr>

      <FormProvider {...methods}>
        <EmployerBrand
          room={room}
          modificationEnabled={modificationEnabled}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
        <OfferToRecruiter
          room={room}
          modificationEnabled={modificationEnabled}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </tbody>
  );
};

export default TableBody;
