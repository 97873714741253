import React, { ForwardedRef } from 'react';

import { cn } from '@utils/lib-utils';

export type Modifier =
  | 'badge-info'
  | 'badge-success'
  | 'badge-warning'
  | 'badge-error';

type BadgeProps = {
  value?: string;
  placeholder?: string;
  className?: string;
  valueClassName?: string;
  modifier?: Modifier;
};

type Ref = HTMLDivElement;

const Badge = React.forwardRef<Ref, BadgeProps>(
  (
    {
      value,
      className: customClassName,
      modifier,
      valueClassName,
      placeholder = '-',
    }: BadgeProps,
    ref: ForwardedRef<Ref>
  ) => (
    <div
      ref={ref}
      className={cn(
        'badge px-s-12 py-s-10 min-h-[2rem]',
        modifier,
        customClassName,
        {
          'border-transparent justify-start': !value,
        }
      )}
    >
      <div
        className={cn('text-14 font-normal whitespace-nowrap', valueClassName)}
      >
        {value || placeholder}
      </div>
    </div>
  )
);

export default Badge;
