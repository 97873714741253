import { gql } from 'urql';

const UserUpdateAvatar = gql`
  mutation UserUpdateAvatar(
    $input: UserUpdateAvatarInput!
    $files: [Upload!]!
  ) {
    userUpdateAvatar(input: $input, files: $files) {
      ok
    }
  }
`;

export default UserUpdateAvatar;
