import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { validate as isUUID } from 'uuid';

import { ButtonLink, PageHeader, PageLoader } from '@application/components';
import { Badge } from '@application/components/badge';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { useGetRequest } from '@application/views/recruitment/request/hooks';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { isDefined } from '@utils/type-utils';

import { ComparisonTable } from './table';

const OffersComparisonPage = () => {
  const { t, i18n } = useTranslation('offers', {
    keyPrefix: 'comparison',
  });
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
    refetchRequest,
  } = useGetRequest(id);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={getLocalizedDescription(
          data?.jobSpecialty?.descriptions,
          i18n.language
        )}
        subtitle={data?.operationUnit?.name}
        complement={
          <Badge
            className="px-s-18"
            value={data?.offersCount.toString()}
            placeholder="0"
          />
        }
        backTo={RootPrivatePage.REQUESTS}
      >
        {isDefined(data?.recruitmentNegotiation) && (
          <ButtonLink
            primary
            icon={<i className="ri-shake-hands-line mr-s-4 font-normal" />}
            to={generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
              id: data?.recruitmentNegotiation?.id || null,
            })}
            from={location.pathname}
          >
            {t('buttons.negotiationsZone')}
          </ButtonLink>
        )}
      </PageHeader>

      {data && (
        <ComparisonTable request={data} refetchRequest={refetchRequest} />
      )}
    </>
  );
};

export default OffersComparisonPage;
