import { gql } from '@urql/core';

import { PlainCandidateOfferFragment } from './fragment';

const PlainCandidateOffer = gql`
  ${PlainCandidateOfferFragment}

  query PlainCandidateOffer($input: CandidateOfferInput!) {
    candidateOffer(input: $input) {
      candidateOffer {
        ...PlainCandidateOfferFragment
      }
    }
  }
`;

export default PlainCandidateOffer;
