// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useCallback, useContext, useMemo } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, Switch } from '@application/components';
import {
  CreatableSelect,
  OptionType,
  Select,
} from '@application/components/select-field/select';
import { ModalContext } from '@application/context';
import { useJobPaymentOtherTypesOptions } from '@application/hooks';
import useJobModeOptions from '@application/hooks/useJobModeOptions';
import useJobTypesOptions from '@application/hooks/useJobTypesOptions';
import { joinDescriptions, mapOptions } from '@application/utils';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';
import {
  CandidateNegotiationRoom,
  CandidateRequestType,
  JobPaymentFrequencyCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';
import { INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX } from '@utils/yup-utils';

import RoomReadOnlyCell from '../../../components/RoomReadOnlyCell';
import { CandidateNegotiationRoomFormFields } from '../../../room/types';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';
import UpdateRemunerationModal from '../UpdateRemunerationModal';

type EmployerBrandProps = {
  modificationEnabled: boolean;
  room: CandidateNegotiationRoom;
  isLoading: boolean;
  onSubmit: SubmitHandler<CandidateNegotiationRoomFormFields>;
};

const EmployerBrand = ({
  modificationEnabled,
  room,
  isLoading = false,
  onSubmit,
}: EmployerBrandProps) => {
  const { i18n, t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    trigger,
  } = useFormContext<CandidateNegotiationRoomFormFields>();

  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();
  const jobPaymentOtherTypesOptions = useJobPaymentOtherTypesOptions();
  const jobTypesOptions = useJobTypesOptions();
  const jobModeOptions = useJobModeOptions();

  const OPERATION_TERRITORIES_OPTIONS = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n]
  );

  const fieldInModification = watch('fieldInModification');

  const hoursPerWeek = watch('hoursPerWeek');

  const remuneration = useMemo(() => {
    switch (room.negotiatingCandidateOffer.paymentFrequencyCode) {
      case JobPaymentFrequencyCode.Hourly:
        return t('suffix.currencyPerHourRange', {
          min: room.negotiatingCandidateOffer.hourlyRateMin,
          max: room.negotiatingCandidateOffer.hourlyRateMax,
        });
      case JobPaymentFrequencyCode.Yearly:
        return t('suffix.thousandsPerYearRange', {
          min: room.negotiatingCandidateOffer.annualSalaryMin,
          max: room.negotiatingCandidateOffer.annualSalaryMax,
        });
      case JobPaymentFrequencyCode.FixedAmount:
        return t('suffix.currency', {
          value: room.negotiatingCandidateOffer.remunerationAmount,
        });
      default:
        return '-';
    }
  }, [room, t]);

  // TODO: Use the new hook after merge
  const operationTerritories = useMemo(
    () =>
      joinDescriptions(
        room.negotiatingCandidateOffer?.operationTerritories?.map(
          (territory) => territory.descriptions
        ) || [],
        i18n.language
      ),
    [i18n.language, room.negotiatingCandidateOffer?.operationTerritories]
  );

  const handleEditRemuneration = useCallback(() => {
    setModal({
      title: t('modal.titles.updateRemuneration'),
      maxWidth: '4xl',
      content: (
        <UpdateRemunerationModal
          type={room.candidateRequest.type!}
          data={room.negotiatingCandidateOffer}
          isLoading={isLoading}
          onSubmit={onSubmit}
          onClose={() => setModal(null)}
        />
      ),
    });
  }, [isLoading, onSubmit, room, setModal, t]);

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          <span>{t('labels.employerBrand')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.highlight)}>&nbsp;</td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          {t('labels.remunerationOffered')}
        </th>

        <td className={cn(TD_CLASS_NAME.base, 'group whitespace-nowrap')}>
          <RoomReadOnlyCell
            value={room.negotiatingCandidateOffer.paymentFrequencyCode}
            displayedValue={remuneration}
            state={room.generalPropertiesState.salary}
            showModifyButton={modificationEnabled}
            onClick={handleEditRemuneration}
          />
        </td>
      </tr>

      {room.candidateRequest.type === CandidateRequestType.Recruitment ? (
        <tr>
          <th scope="col" className={TH_ROW_CLASS_NAME.base}>
            {t('labels.otherRemunerations')}
          </th>

          <td
            className={cn(TD_CLASS_NAME.base, 'group whitespace-nowrap', {
              'p-s-0': fieldInModification === 'otherPaymentTypeCode',
            })}
          >
            <Switch
              fallback={
                <RoomReadOnlyCell
                  value={room.negotiatingCandidateOffer.otherPaymentTypeCode}
                  displayedValue={t(
                    `enum.jobPaymentOtherTypesCode.${room.negotiatingCandidateOffer.otherPaymentTypeCode?.toLowerCase()}`
                  )}
                  state={room.generalPropertiesState.otherRemunerations}
                  showModifyButton={modificationEnabled}
                  onClick={() =>
                    setValue('fieldInModification', 'otherPaymentTypeCode')
                  }
                />
              }
            >
              <Switch.Match
                when={fieldInModification === 'otherPaymentTypeCode'}
              >
                <Controller
                  name="otherPaymentTypeCode"
                  control={control}
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      autoFocus
                      className="border-2 border-black h-full"
                      controlClassName="border-none h-full"
                      name={name}
                      menuPlacement="bottom"
                      options={jobPaymentOtherTypesOptions}
                      invalid={!!error}
                      value={jobPaymentOtherTypesOptions.find(
                        (method) => method.value === value
                      )}
                      onBlur={() => setValue('fieldInModification', null)}
                      onChange={(option: any) => {
                        onChange(option.value);
                        handleSubmit(onSubmit)();
                      }}
                    />
                  )}
                />
              </Switch.Match>
            </Switch>
          </td>
        </tr>
      ) : (
        <>
          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.hoursPerWeek')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'group whitespace-nowrap', {
                'p-s-0': fieldInModification === 'hoursPerWeek',
              })}
            >
              <Switch
                fallback={
                  <RoomReadOnlyCell
                    value={room.negotiatingCandidateOffer.hoursPerWeek}
                    displayedValue={
                      room.negotiatingCandidateOffer.hoursPerWeek
                        ?.map((h) => t('suffix.hours', { value: h }))
                        .join(', ') || ''
                    }
                    state={room.generalPropertiesState.hours}
                    showModifyButton={modificationEnabled}
                    onClick={() =>
                      setValue('fieldInModification', 'hoursPerWeek')
                    }
                  />
                }
              >
                <Switch.Match when={fieldInModification === 'hoursPerWeek'}>
                  <Controller
                    name="hoursPerWeek"
                    control={control}
                    render={({ field: { name } }) => (
                      <CreatableSelect
                        autoFocus
                        name={name}
                        value={
                          hoursPerWeek?.map((v) => ({
                            label: v,
                            value: v,
                          })) as OptionType[]
                        }
                        className="border-2 border-primary h-full flex-grow"
                        controlClassName="[&&]:border-none h-full"
                        validateOnKeyDown={(newValue: string) =>
                          INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                        }
                        onInputChange={(newValue: string) => {
                          // Check if entered value is an integer or one decimal digit number only
                          // return an error otherwise
                          if (
                            newValue &&
                            !INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(
                              newValue
                            )
                          ) {
                            setError(name, {
                              type: 'custom',
                              message: tGlobal(
                                'validations.integerOrOneDecimalDigitNumber'
                              ),
                            });
                            return;
                          }
                          clearErrors(name);
                        }}
                        onChange={(options: any) => {
                          setValue(
                            name,
                            options.map((option: any) => option.value)
                          );
                          trigger(name);
                        }}
                        onBlur={handleSubmit(onSubmit)}
                        formatOptionLabel={({ value: newValue }) =>
                          `${newValue}h`
                        }
                        invalid={!!errors.hoursPerWeek}
                      />
                    )}
                  />
                </Switch.Match>
              </Switch>
            </td>
          </tr>

          <tr>
            <th
              scope="col"
              className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.disabled)}
            >
              {t('labels.contractDuration')}
            </th>

            <td className={cn(TD_CLASS_NAME.base, TD_CLASS_NAME.disabled)}>
              <PlaceholderSwitcher
                value={room.negotiatingCandidateOffer.jobDurationInMonths}
              >
                {t('suffix.month', {
                  count: Number(
                    room.negotiatingCandidateOffer.jobDurationInMonths
                  ),
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th
              scope="col"
              className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.disabled)}
            >
              {t('labels.potentialAmount')}
            </th>

            <td className={cn(TD_CLASS_NAME.base, TD_CLASS_NAME.disabled)}>
              <PlaceholderSwitcher
                value={room.negotiatingCandidateOffer.potentialAmount}
              >
                {t('suffix.currency', {
                  value: room.negotiatingCandidateOffer.potentialAmount,
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>
        </>
      )}

      <tr>
        <th scope="col" className={cn(TH_ROW_CLASS_NAME.base)}>
          {t('labels.operationTerritories')}
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group whitespace-nowrap', {
            'p-s-0': fieldInModification === 'operationTerritoryCodes',
          })}
        >
          <Switch
            fallback={
              <RoomReadOnlyCell
                value={
                  room.negotiatingCandidateOffer.operationTerritories?.length ||
                  undefined
                }
                displayedValue={operationTerritories}
                state={room.generalPropertiesState.operationTerritory}
                showModifyButton={modificationEnabled}
                onClick={() =>
                  setValue('fieldInModification', 'operationTerritoryCodes')
                }
              />
            }
          >
            <Switch.Match
              when={fieldInModification === 'operationTerritoryCodes'}
            >
              <Controller
                name="operationTerritoryCodes"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <Select
                    autoFocus
                    className="border-2 border-primary h-full flex-grow"
                    controlClassName="border-none h-full"
                    name={name}
                    options={OPERATION_TERRITORIES_OPTIONS}
                    value={
                      OPERATION_TERRITORIES_OPTIONS.filter((l) =>
                        value?.includes(l.value)
                      ) || null
                    }
                    onChange={(options: any) =>
                      onChange(
                        options
                          ? options.map((option: OptionType) => option.value)
                          : null
                      )
                    }
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!errors.operationTerritoryCodes}
                    searchable
                    clearable
                    multiple
                    limitTags={0}
                  />
                )}
              />
            </Switch.Match>
          </Switch>
        </td>
      </tr>

      {room.candidateRequest.type === CandidateRequestType.Recruitment && (
        <tr>
          <th scope="col" className={TH_ROW_CLASS_NAME.base}>
            {t('labels.jobType')}
          </th>

          <td
            className={cn(TD_CLASS_NAME.base, 'group whitespace-nowrap', {
              'p-s-0': fieldInModification === 'jobType',
            })}
          >
            <Switch
              fallback={
                <RoomReadOnlyCell
                  value={room.negotiatingCandidateOffer.jobType}
                  displayedValue={t(
                    `enum.jobDurationCode.${room.negotiatingCandidateOffer.jobType?.toLowerCase()}`
                  )}
                  state={room.generalPropertiesState.jobType}
                  showModifyButton={modificationEnabled}
                  onClick={() => setValue('fieldInModification', 'jobType')}
                />
              }
            >
              <Switch.Match when={fieldInModification === 'jobType'}>
                <Controller
                  name="jobType"
                  control={control}
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      autoFocus
                      className="border-2 border-black h-full"
                      controlClassName="border-none h-full"
                      name={name}
                      menuPlacement="bottom"
                      options={jobTypesOptions}
                      invalid={!!error}
                      value={jobTypesOptions.find(
                        (type) => type.value === value
                      )}
                      onBlur={() => setValue('fieldInModification', null)}
                      onChange={(option: any) => {
                        onChange(option.value);
                        handleSubmit(onSubmit)();
                      }}
                    />
                  )}
                />
              </Switch.Match>
            </Switch>
          </td>
        </tr>
      )}

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          {t('labels.remoteWork')}
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group whitespace-nowrap', {
            'p-s-0': fieldInModification === 'jobModeCode',
          })}
        >
          <Switch
            fallback={
              <RoomReadOnlyCell
                value={room.negotiatingCandidateOffer.jobModeCode}
                displayedValue={t(
                  `enum.jobModeCode.${room.negotiatingCandidateOffer.jobModeCode?.toLowerCase()}`
                )}
                state={room.generalPropertiesState.jobMode}
                showModifyButton={modificationEnabled}
                onClick={() => setValue('fieldInModification', 'jobModeCode')}
              />
            }
          >
            <Switch.Match when={fieldInModification === 'jobModeCode'}>
              <Controller
                name="jobModeCode"
                control={control}
                render={({
                  field: { name, value, onChange },
                  fieldState: { error },
                }) => (
                  <Select
                    autoFocus
                    className="border-2 border-black h-full"
                    controlClassName="border-none h-full"
                    name={name}
                    menuPlacement="bottom"
                    options={jobModeOptions}
                    invalid={!!error}
                    value={jobModeOptions.find((type) => type.value === value)}
                    onBlur={() => setValue('fieldInModification', null)}
                    onChange={(option: any) => {
                      onChange(option.value);
                      handleSubmit(onSubmit)();
                    }}
                  />
                )}
              />
            </Switch.Match>
          </Switch>
        </td>
      </tr>
    </>
  );
};

export default EmployerBrand;
