import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@application/components/links';
import { SummaryList } from '@application/components/summary';
import SummaryBlock, {
  SummaryBlockProps,
} from '@application/components/summary/SummaryBlock';
import { SummaryListProps } from '@application/components/summary/SummaryList';
import { joinDescriptions } from '@application/utils';
import { CandidateRequest } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { isDefined } from '@utils/type-utils';

type GeneralInformationProps = {
  data?: CandidateRequest;
};

const GeneralInformation = ({ data }: GeneralInformationProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const summaryBlockData: Omit<SummaryBlockProps, 'children'> = {
    headerTitle: t('subtitles.description'),
    title: getLocalizedDescription(
      data?.jobSpecialty?.descriptions,
      i18n.language
    ),
    subtitle: data?.specialty || undefined,
    candidateRequestTypeBadge: data?.type || undefined,
  };

  const summaryListCandidateDescriptionData: SummaryListProps = useMemo(
    () => ({
      data: [
        {
          text: t('labels.name'),
          description: `${data?.firstName} ${data?.lastName}`,
        },
        {
          text: t('labels.email'),
          description: data?.email || '-',
        },
        {
          text: t('labels.industry'),
          description:
            getLocalizedDescription(
              data?.jobSpecialty?.sector.industry.descriptions,
              i18n.language
            ) || '-',
        },
        {
          text: t('labels.activitySector'),
          description:
            getLocalizedDescription(
              data?.jobSpecialty?.sector.descriptions,
              i18n.language
            ) || '-',
        },
        {
          text: t('labels.operationTerritory'),
          description:
            joinDescriptions(
              data?.operationTerritories?.map(
                (territory) => territory.descriptions
              ) || [],
              i18n.language
            ) || '-',
        },
        {
          text: t('labels.experience'),
          description: t('list.experience.yearsCount', {
            count: data?.experience || 0,
          }),
        },
        {
          text: t('labels.metAt'),
          description: data?.metAt
            ? formatDate(data?.metAt, i18n.language)
            : '-',
        },
        {
          text: t('labels.canWorkInCanadaOrQuebec'),
          description: isDefined(data?.canWorkInCanadaOrQuebec)
            ? tGlobal(`boolean.${String(data?.canWorkInCanadaOrQuebec)}`)
            : '-',
        },
        {
          text: t('labels.deadline'),
          description: data?.receivingOfferDeadline
            ? formatDate(data.receivingOfferDeadline, i18n.language)
            : '-',
        },
        {
          text: t('labels.startDate'),
          description: data?.desiredStartDate
            ? formatDate(data.desiredStartDate, i18n.language)
            : '-',
        },
      ],
    }),
    [data, t, tGlobal, i18n.language]
  );

  return (
    <SummaryBlock {...summaryBlockData}>
      <SummaryList
        density="comfortable"
        {...summaryListCandidateDescriptionData}
      />

      {data?.resumeUrl && (
        <Link
          to={data?.resumeUrl}
          newTab
          className="underline text-16 font-semibold"
        >
          {t('labels.viewCv')}
        </Link>
      )}
    </SummaryBlock>
  );
};

export default GeneralInformation;
