import { gql } from 'urql';

import { DescriptionsFragment } from '../descriptions';

const OfferCandidateFragment = gql`
  ${DescriptionsFragment}

  fragment OfferCandidateFragment on OfferCandidate {
    id
    firstName
    firstNameDisplay
    lastName
    lastNameDisplay
    email
    emailDisplay
    offerId
    educationLevelCode
    studyField
    requestTypes
    experience
    specialty
    canWorkInCanadaOrQuebec
    citiesSubRegions
    notes
    feedback
    operationTerritoryCodes
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    metAt
    jobTypeCode
    jobDurationInMonths
    jobModeCode
    fullTimeAvailability
    partTimeAvailability
    hoursPerWeek
    schedules
    allowedRemoteDays
    annualSalaryMin
    annualSalaryMax
    paymentFrequencyCode
    hourlyRateMin
    hourlyRateMax
    hourlyBonus
    remunerationAmount
    otherPaymentTypes
    otherRemunerationAmount
    softSkills
    createdAt
    updatedAt
    cvUrl
    otherFilesUrls {
      link
      fileName
    }
  }
`;

export default OfferCandidateFragment;
