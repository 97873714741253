import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';

import { ButtonLink, PageHeader } from '@application/components';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';

import { useGetCandidateOpportunity } from './hooks';
import { ConditionsSummary, DescriptionSummary } from './summary';

const ViewCandidateOpportunityPage = () => {
  const { t } = useTranslation('candidates');

  const { id = '' } = useParams();

  const {
    viewModel: { data },
  } = useGetCandidateOpportunity(id);

  const showMakeOfferButton = useMemo(
    () =>
      data &&
      DateTime.now().startOf('day') <=
        DateTime.fromISO(data.receivingOfferDeadline).startOf('day'),
    [data]
  );

  return (
    <>
      <PageHeader
        titleAs="h1"
        title={t('title.opportunityDetails')}
        backTo={RootPrivatePage.CANDIDATE_OPPORTUNITIES}
      >
        {showMakeOfferButton && (
          <ButtonLink
            primary
            className="mr-s-16"
            to={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_OFFER_CREATE, {
              id,
            })}
          >
            {t('button.makeOffer')}
          </ButtonLink>
        )}
      </PageHeader>
      <div>
        <DescriptionSummary data={data} />
        <ConditionsSummary data={data} />
      </div>
    </>
  );
};

export default ViewCandidateOpportunityPage;
