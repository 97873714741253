// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useTranslation } from 'react-i18next';

import { Request } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../constants';
import {
  GeneralConditions,
  HeadHunterConditions,
  InternationalRecruitmentConditions,
  OutsourcingConditions,
  TemporaryPlacementConditions,
} from './rows';

type TableBodyProps = {
  request: Request;
};

const TableBody = ({ request }: TableBodyProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  return (
    <tbody>
      <tr>
        <td className="sr-only">&nbsp;</td>
        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'h-[7rem] border-l-0 text-center'
          )}
        >
          {t('initialRequestProposition')}
        </td>
      </tr>

      <GeneralConditions data={request.generalContractualConditions} />

      <HeadHunterConditions data={request.headhunterContractualConditions!} />

      <TemporaryPlacementConditions
        data={request.temporaryPlacementContractualConditions!}
      />

      <InternationalRecruitmentConditions
        data={request.internationalRecruitmentContractualConditions!}
      />

      <OutsourcingConditions data={request.outsourcingContractualConditions!} />
    </tbody>
  );
};

export default TableBody;
