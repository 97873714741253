import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import {
  Link,
  SectionGrid,
  SectionGridRow,
  Tooltip,
} from '@application/components';
import MessageDisplay from '@application/components/messageDisplay/MessageDisplay';
import { RequestTypeBadges } from '@application/components/request-type-badges';
import { SummaryHeader } from '@application/components/summary';
import { PrivatePage } from '@application/enums/pagesUrl';
import { Offer } from '@domain/graphql.types';

export type CandidatesSummaryProps = {
  offer?: Offer;
};

const CandidatesSummary = ({ offer }: CandidatesSummaryProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const location = useLocation();

  const candidates = offer?.candidates || [];

  return candidates.length > 0 ? (
    <>
      <SummaryHeader headerTitle={t(`subtitles.candidates`)} />

      <SectionGrid
        title={t(`subtitles.candidates`)}
        fullWidth
        hideTitle
        readOnly
      >
        {candidates.map((candidate, index) => (
          <SectionGridRow
            className="grid grid-cols-[2fr_1.5fr_1fr_1fr] p-s-24"
            key={candidate.id}
            isTopRow={index === 0}
          >
            <div className="flex gap-s-8 items-center">
              {candidate.metAt && (
                <Tooltip
                  message={t('labels.certifiedResume', {
                    date: new Date(candidate.metAt),
                  })}
                >
                  <i className="ri-verified-badge-fill text-18" />
                </Tooltip>
              )}
              <Link
                from={location.pathname}
                to={generatePath(PrivatePage.VIEW_OFFER_CANDIDATE, {
                  candidateId: candidate.id,
                })}
              >
                <span className="font-semibold">
                  {[candidate.firstName, candidate.lastName]
                    .filter(Boolean)
                    .join(' ') ||
                    t('candidatePlaceholder', { index: index + 1 })}
                </span>
              </Link>
            </div>

            <span>
              {t('labels.experienceYears', {
                count: candidate.experience as number,
              })}
            </span>

            {candidate.requestTypes ? (
              <RequestTypeBadges types={[...candidate.requestTypes]} />
            ) : (
              <span>-</span>
            )}

            {candidate.cvUrl ? (
              <a
                href={candidate.cvUrl}
                target="_blank"
                className="underline text-16 font-semibold text-end"
                rel="noreferrer"
              >
                {t('labels.resumeButton')}
              </a>
            ) : (
              <span className="text-end">-</span>
            )}
          </SectionGridRow>
        ))}
      </SectionGrid>
    </>
  ) : (
    offer?.noCandidatesExplaination && (
      <MessageDisplay
        label={t('noCandidatesExplaination')}
        text={offer?.noCandidatesExplaination || undefined}
      />
    )
  );
};

export default CandidatesSummary;
