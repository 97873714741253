import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SectionContainer, TextAreaField } from '@application/components';
import { Button } from '@application/components/buttons';
import { Box } from '@application/components/container-layouts';
import { SocialNetworksSection } from '@application/components/slices';
import { AccountContext, UserContext } from '@application/context';
import { AccountTypeCode } from '@domain/graphql.types';

import { PersonalInformation, ProfilePicture } from './components';
import { useGetProfile, useUpdateProfile } from './hooks';
import { normalizeUpdateInputData } from './normalizers';
import { USER_PROFILE_SCHEMA } from './schema';
import { UserProfileFormFields } from './types';

const UserProfilePage = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('user');

  const { user } = useContext(UserContext);
  const { account } = useContext(AccountContext);

  const {
    viewModel: { data: values },
    refetchUser,
  } = useGetProfile();
  const { updateProfile } = useUpdateProfile();

  // FIXME: professionalEmail should be nullable but
  // BE returns email type error if empty
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<UserProfileFormFields>({
    values: {
      ...values,
      phoneDisplay: true,
      organization: account?.name,
      professionalEmail: user?.professionalEmail || user?.email,
    } as UserProfileFormFields,
    mode: 'onBlur',
    resolver: yupResolver(USER_PROFILE_SCHEMA),
  });

  const onSubmit: SubmitHandler<UserProfileFormFields> = useCallback(
    async (data) => {
      await updateProfile({
        input: normalizeUpdateInputData({
          id: user?.id || '',
          defaultAccountId: user?.defaultAccountId || '',
          ...data,
        }),
      });
      reset();
      refetchUser();
    },
    [updateProfile, user, refetchUser, reset]
  );

  return (
    <>
      <h1 className="sr-only">{t('titles.myProfile')}</h1>

      {user && (
        <section>
          <ProfilePicture user={user} />
        </section>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionContainer
          titleAs="h2"
          title={t('personalInformation.title')}
          description={t('personalInformation.description')}
          className="mt-s-32"
        >
          <PersonalInformation
            control={control}
            register={register}
            errors={errors}
            organization={account?.name}
          />

          {account?.type === AccountTypeCode.Agency && (
            <>
              <SocialNetworksSection
                register={register}
                errors={errors}
                className="mt-s-32"
              />

              <SectionContainer
                titleAs="h2"
                title={t('subtitles.professionalBiography')}
                className="mt-s-32"
              >
                <Box>
                  <Controller
                    name="bio"
                    control={control}
                    render={({
                      field: { onChange, name, value },
                      fieldState: { error },
                    }) => (
                      <TextAreaField
                        rows={6}
                        name={name}
                        maxChar={1024}
                        value={value || ''}
                        label={t('labels.professionalBiography')}
                        invalid={!!error}
                        helperText={
                          errors.bio?.message &&
                          tGlobal(errors.bio?.message, {
                            max: 1024,
                          })
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
              </SectionContainer>

              <SectionContainer
                titleAs="h2"
                title={t('subtitles.whyBestPartner')}
                className="mt-s-32"
              >
                <Box>
                  <Controller
                    name="why"
                    control={control}
                    render={({
                      field: { onChange, name, value },
                      fieldState: { error },
                    }) => (
                      <TextAreaField
                        rows={6}
                        name={name}
                        maxChar={1024}
                        value={value || ''}
                        label={t('labels.writeWhyHere')}
                        invalid={!!error}
                        helperText={
                          errors.why?.message &&
                          tGlobal(errors.why?.message, {
                            max: '1024',
                          })
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
              </SectionContainer>
            </>
          )}

          <div className="flex justify-end gap-s-16 w-full mt-s-32">
            <Button type="button" onClick={() => reset()} disabled={!isDirty}>
              {tGlobal('button.discardChanges')}
            </Button>

            <Button type="submit" primary>
              {tGlobal('button.save')}
            </Button>
          </div>
        </SectionContainer>
      </form>
    </>
  );
};

export default UserProfilePage;
