import { Fieldset } from '@application/components';
import { Box } from '@application/components/container-layouts';
import { cn } from '@utils/lib-utils';

type SectionProps = {
  legend: string;
  children: React.ReactNode;
  hideBox?: boolean;
  className?: string;
};

const Section = ({
  children,
  legend,
  hideBox,
  className: customClassName,
}: SectionProps) => (
  <Fieldset
    className={cn('w-full flex flex-col mb-s-24', customClassName)}
    legend={legend}
    legendClassName="text-20"
  >
    {hideBox ? (
      children
    ) : (
      <Box padding={24} className="w-full">
        {children}
      </Box>
    )}
  </Fieldset>
);

export default Section;
