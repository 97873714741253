import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { UserContext } from '@application/context';

import { useSendEmailVerificationLink } from '../hooks';

const VerifyEmail = () => {
  const { t } = useTranslation('user');

  const {
    viewModel: { isLoading },
    sendEmailVerificationLink,
  } = useSendEmailVerificationLink();

  const { user } = useContext(UserContext);

  const verifyEmail = useCallback(() => {
    if (!user?.id) {
      return;
    }

    sendEmailVerificationLink({
      input: {
        userId: user?.id,
      },
    });
  }, [sendEmailVerificationLink, user?.id]);

  return (
    <section className="flex justify-between">
      <h2 className="self-center h4">{t('subtitles.verifyEmail')}</h2>

      <Button primary loading={isLoading} onClick={verifyEmail}>
        {t('button.verifyEmail')}
      </Button>
    </section>
  );
};

export default VerifyEmail;
