import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { cloneElement, ReactElement, ReactNode, useRef, useState } from 'react';

import { cn } from '@utils/lib-utils';

type TooltipProps = {
  message: ReactNode | undefined;
  children: ReactElement;
  position?: Placement;
  className?: string;
};

const Tooltip = ({
  message,
  children,
  position = 'bottom',
  className: customClassName,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const {
    refs: { setReference, setFloating },
    floatingStyles,
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: position,
    middleware: [
      offset(8),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const cloneChildren = cloneElement(children, {
    ref: setReference,
    ...getReferenceProps(),
  });

  return (
    <>
      {cloneChildren}
      {isOpen && message && (
        <div
          ref={setFloating}
          className={cn('z-tooltip', customClassName)}
          style={{ ...floatingStyles }}
          {...getFloatingProps()}
        >
          <div
            className="max-w-s-320 w-max py-s-8 px-s-12 bg-primary rounded-sm text-14 text-primary-content font-normal text-wrap"
            style={transitionStyles}
          >
            <FloatingArrow
              tipRadius={2}
              height={5}
              width={8}
              ref={arrowRef}
              context={context}
            />
            {message}
          </div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
