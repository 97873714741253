import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { AccountPreferredSpecialtiesCreateResponse } from '@domain/graphql.types';

type ToViewModel = {
  preferredSpecialties: {
    data: AccountPreferredSpecialtiesCreateResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  preferredSpecialties: { data, fetching, error },
}: ToViewModel): ViewModel<
  AccountPreferredSpecialtiesCreateResponse | undefined
> =>
  new ViewModel<AccountPreferredSpecialtiesCreateResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
