import { gql } from 'urql';

const RequestHeadhunterConditionsFragment = gql`
  fragment RequestHeadhunterConditionsFragment on RequestHeadhunterConditions {
    maximumReplacementValue
    maximumReplacementDisplay
    headhuntingExclusivityPeriodValue
    headhuntingExclusivityPeriodDisplay
  }
`;

export default RequestHeadhunterConditionsFragment;
