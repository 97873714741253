import { ElementType, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

import { Space, Threshold } from './types';

type Align = 'start' | 'center' | 'baseline' | 'end';

type Limit = 1 | 2 | 3 | 4;

type SwitcherProps = {
  /**
   * Specify content elements of the `<Switcher>`
   */
  children: ReactNode;
  /**
   * Specify the HTML element applied to the `<Switcher>` container
   */
  as?: ElementType;
  /**
   * Specify the container width at which the `<Switcher>` switches between a horizontal and vertical layout
   */
  threshold?: Threshold;
  /**
   * Specify the space (margin) between the (child) elements
   */
  space?: Space;
  /**
   * Specify the maximum number of elements allowed to appear in the horizontal configuration
   */
  limit?: Limit;
  /**
   * Specify the `align-items` value applied to the `<Switcher>`
   */
  align?: Align;
  /**
   * Specify a custom `className` to apply to the `<Switcher>`
   */
  className?: string;
};

/**
 * The **Switcher** element (based on [Flexbox Holy Albatross](https://heydonworks.com/article/the-flexbox-holy-albatross/))
 * switches a Flexbox context between a horizontal and a vertical layout at a given, container-based breakpoint.
 *
 * @see [https://every-layout.dev/](https://every-layout.dev/)
 *
 * @example
 * Default use:
 * <Switcher>
 *   <div><!-- child --></div>
 *   <div><!-- child --></div>
 *   <div><!-- etc --></div>
 * </Switcher>
 *
 * With provided `threshold`, `space` and `limit` values
 *  <Switcher threshold='md' space={24} limit={3}>
 *   <div><!-- child --></div>
 *   <div><!-- child --></div>
 *   <div><!-- etc --></div>
 * </Switcher>
 */
const Switcher = ({
  as: Tag = 'div',
  space = 16,
  threshold = 'md',
  limit = 2,
  children,
  align,
  className: customClassName,
}: SwitcherProps) => {
  const spaceClassName = {
    'gap-s-8': space === 8,
    'gap-s-16': space === 16,
    'gap-s-24': space === 24,
    'gap-s-32': space === 32,
  };

  /**
   * NOTE: The flex-basis value enters the (current) width of the container,
   * expressed as 100%, into a calculation with the designated breakpoint.
   *
   * Depending on the parsed value of 100%, this will return either a positive or negative value:
   * positive if the container is narrower than the breakpoint, or negative if it is wider.
   * This number is then multiplied by 999 to produce either a very large positive number
   * or a very large negative number.
   *
   * A negative flex-basis value is invalid, and dropped. Thanks to CSS’s resilient error handling
   * this means just the flex-basis line is ignored, and the rest of the CSS is still applied.
   * The erroneous negative flex-basis value is corrected to 0 and—because flex-grow is present—
   * each element grows to take up an equal proportion of horizontal space.
   */
  const thresholdClassName = {
    '[&>*]:basis-[calc((var(--screens-xs)-100%)*999)]': threshold === 'xs',
    '[&>*]:basis-[calc((var(--screens-sm)-100%)*999)]': threshold === 'sm',
    '[&>*]:basis-[calc((var(--screens-md)-100%)*999)]': threshold === 'md',
    '[&>*]:basis-[calc((var(--screens-lg)-100%)*999)]': threshold === 'lg',
  };

  /**
   * NOTE: The nth-last-child(n+X) selector targets any elements that are more than X from the end of the set.
   * Then, the general sibling combinator (~) applies the same rule to the rest of the elements
   * (it matches anything after :nth-last-child(n+X)).
   * If there are fewer that X items, no :nth-last-child(n+X) elements and the style is not applied.
   */
  const limitClassName = {
    '[&>:nth-last-child(n+2)]:basis-[100%] [&>:nth-last-child(n+2)~*]:basis-[100%]':
      limit === 1,
    '[&>:nth-last-child(n+3)]:basis-[100%] [&>:nth-last-child(n+3)~*]:basis-[100%]':
      limit === 2,
    '[&>:nth-last-child(n+4)]:basis-[100%] [&>:nth-last-child(n+4)~*]:basis-[100%]':
      limit === 3,
    '[&>:nth-last-child(n+5)]:basis-[100%] [&>:nth-last-child(n+5)~*]:basis-[100%]':
      limit === 4,
  };

  const alignClassName = {
    'items-start': align === 'start',
    'items-center': align === 'center',
    'items-end': align === 'end',
    'items-baseline': align === 'baseline',
  };

  const className = cn(
    'flex flex-wrap [&>*]:flex-grow',
    {
      ...spaceClassName,
      ...thresholdClassName,
      ...limitClassName,
      ...alignClassName,
    },
    customClassName
  );

  return <Tag className={className}>{children}</Tag>;
};

export default Switcher;
