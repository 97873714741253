import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

import { CandidateNegotiationFormFields } from '../schema';

type ToViewModel = {
  negotiation: {
    data:
      | Pick<Mutation, 'candidateNegotiationComplete'>
      | Record<string, never>
      | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  negotiation: { data = {}, fetching, error },
}: ToViewModel): ViewModel<CandidateNegotiationFormFields> =>
  new ViewModel<CandidateNegotiationFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
