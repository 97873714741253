import { getLocalizedDescription } from '../../utils/i18n-utils';

const mapDescriptions = (
  descriptions: { en: string; fr: string }[],
  language: string
): string[] =>
  descriptions.flatMap((description) => {
    const localizedDescriptions = getLocalizedDescription(
      description,
      language
    );

    return localizedDescriptions ? [localizedDescriptions] : [];
  });

export default mapDescriptions;
