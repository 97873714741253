import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import SummaryBadges from '@application/components/summary/SummaryBadges';
import SummaryDescription from '@application/components/summary/SummaryDescription';
import { JobPaymentFrequencyCode, OfferCandidate } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

type RemunerationProps = {
  offerCandidate: OfferCandidate | undefined;
};

const Remuneration = ({ offerCandidate }: RemunerationProps) => {
  const { t, i18n } = useTranslation('candidates', {
    keyPrefix: 'candidateView',
  });

  const remuneration = useMemo(() => {
    if (
      offerCandidate?.paymentFrequencyCode === JobPaymentFrequencyCode.Yearly
    ) {
      return t('labels.perYear', {
        min: offerCandidate.annualSalaryMin,
        max: offerCandidate.annualSalaryMax,
      });
    }

    if (
      offerCandidate?.paymentFrequencyCode === JobPaymentFrequencyCode.Hourly
    ) {
      return t('labels.perHour', {
        min: offerCandidate.hourlyRateMin,
        max: offerCandidate.hourlyRateMax,
      });
    }

    if (
      offerCandidate?.paymentFrequencyCode ===
      JobPaymentFrequencyCode.FixedAmount
    ) {
      return t('labels.fixedAmount', {
        amount: offerCandidate.remunerationAmount,
      });
    }

    return '-';
  }, [offerCandidate, t]);

  const jobTypeOne = useMemo(() => {
    const duration = t(
      `enum.jobDurationCode.${offerCandidate?.jobTypeCode?.toLowerCase()}`
    );

    const period = offerCandidate?.jobDurationInMonths
      ? t('labels.forPeriod', { months: offerCandidate.jobDurationInMonths })
      : '';

    return period ? `${duration} ${period}` : duration;
  }, [offerCandidate, t]);

  const jobTypeTwo = useMemo(() => {
    const options = [];

    if (offerCandidate?.fullTimeAvailability) {
      options.push(t('labels.fullTime'));
    }

    if (offerCandidate?.partTimeAvailability) {
      options.push(t('labels.partTime'));
    }

    return options;
  }, [offerCandidate, t]);

  const workingHours = useMemo(
    () =>
      offerCandidate?.schedules?.map((j) =>
        t(`enum.jobWorkingHours.${j.toLowerCase()}`)
      ) || [],
    [offerCandidate, t]
  );

  const mode = useMemo(() => {
    const type = getLocalizedDescription(
      offerCandidate?.jobMode?.descriptions,
      i18n.language
    );

    const days = offerCandidate?.allowedRemoteDays
      ? t('labels.daysOnSite', { days: offerCandidate?.allowedRemoteDays })
      : '';

    return days ? `${type} ${days}` : type;
  }, [offerCandidate, i18n.language, t]);

  const remunerationFields = useMemo(
    () => [
      {
        text: t('labels.jobType'),
        description: jobTypeOne || '-',
      },
      {
        text: t('labels.hoursPerWeekSummary'),
        badges:
          offerCandidate?.hoursPerWeek
            ?.map((hoursPerWeek) => `${hoursPerWeek}h`)
            .concat(jobTypeTwo) || [],
      },
      {
        text: t('labels.schedule'),
        badges: workingHours,
      },
      {
        text: t('labels.remoteWork'),
        description: mode || '-',
      },
      {
        text: t('labels.remunerationType'),
        description: remuneration,
      },
      {
        text: t('labels.otherCompensation'),
        badges:
          offerCandidate?.otherPaymentTypes?.map((jobPaymentOtherTypesCode) =>
            t(
              `labels.otherCompensations.${jobPaymentOtherTypesCode.toLowerCase()}`,
              { amount: offerCandidate.otherRemunerationAmount }
            )
          ) || [],
      },
    ],
    [
      offerCandidate?.hoursPerWeek,
      offerCandidate?.otherPaymentTypes,
      offerCandidate?.otherRemunerationAmount,
      jobTypeOne,
      jobTypeTwo,
      mode,
      remuneration,
      t,
      workingHours,
    ]
  );

  return (
    <>
      <SummaryHeader
        className="mt-s-16"
        headerTitle={t('subtitles.renumeration')}
      />

      <section className="grid grid-cols-[1fr] border rounded-b-md">
        {remunerationFields.map((remunerationField, index) => (
          <SectionGridRow key={remunerationField.text} isTopRow={index === 0}>
            {remunerationField.text} : &nbsp;
            {remunerationField.badges ? (
              <SummaryBadges badges={remunerationField.badges} />
            ) : (
              <SummaryDescription description={remunerationField.description} />
            )}
          </SectionGridRow>
        ))}
      </section>
    </>
  );
};

export default Remuneration;
