import { gql } from 'urql';

const RecruitmentNegotiationOutsourcingPropertiesStateFragment = gql`
  fragment RecruitmentNegotiationOutsourcingPropertiesStateFragment on RecruitmentNegotiationOutsourcingPropertiesState {
    exclusivityPeriod
    maximumBudget
    maximumDuration
  }
`;

export default RecruitmentNegotiationOutsourcingPropertiesStateFragment;
