import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { PrivatePage } from '@application/enums/pagesUrl';
import { Offer, OfferStatusTypeCode } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_CLASS_NAME } from './constants';

type ResumeLinkProps = {
  offers: readonly Offer[];
  checkedOffers: string[];
};

const ResumeLink = ({ offers, checkedOffers }: ResumeLinkProps) => {
  const { t } = useTranslation('offers', {
    keyPrefix: 'comparison',
  });

  return (
    <tr>
      <th className={TH_CLASS_NAME}>
        <TooltipEllipsis toolTip={t(`information.resumeCount`)}>
          <div>{t(`information.candidateCount`)}</div>
        </TooltipEllipsis>
      </th>

      {offers.map((offer) => (
        <td
          key={offer.id}
          className={cn(
            TD_CLASS_NAME,
            'border-x-2 border-b-2 border-transparent',
            {
              'border-main': checkedOffers.includes(offer.id),
              'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
            }
          )}
        >
          {offer.candidates.length > 0 ? (
            <Link
              className="underline font-semibold hover:no-underline"
              to={generatePath(PrivatePage.OFFER_CANDIDATES, {
                id: offer.requestId,
                offerId: offer.id,
              })}
            >
              {t('columns.candidateCount', { count: offer.candidates.length })}
            </Link>
          ) : (
            <span>-</span>
          )}
        </td>
      ))}
    </tr>
  );
};

export default ResumeLink;
