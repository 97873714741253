import NavLink from './NavLink';

type BottomLinksProps = {
  isNavOpen: boolean;
};

const BottomLinks = ({ isNavOpen }: BottomLinksProps) => {
  const SUPPORT_LINK = [
    {
      label: 'support',
      icon: 'ri-questionnaire-line',
      // FIXME: Temporary solution. Will be changed for support page when it is done.
      url: 'mailto:admin@kehops.com',
      disabled: false,
    },
  ];

  return (
    <ul className="border-t mt-s-0 mb-s-64 pt-s-16">
      {SUPPORT_LINK.map((link) => (
        <NavLink
          key={link.label}
          label={link.label}
          isNavOpen={isNavOpen}
          icon={link.icon}
          url={link.url}
          disabled={link.disabled}
        />
      ))}
    </ul>
  );
};

export default BottomLinks;
