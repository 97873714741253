import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NoContent, Table } from '@application/components/table';
import { AccountContext } from '@application/context';

import { useInvitationsList } from '../hooks/useInvitationsList';
import useInviteUserModal from '../hooks/useInviteUserModal';

const InvitationsList = () => {
  const { t } = useTranslation('organization', { keyPrefix: 'users' });

  const { account } = useContext(AccountContext);

  const { columns, users, sorting, onSortingChange, isLoading } =
    useInvitationsList();

  const { openModal } = useInviteUserModal();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-s-24 gap-s-12">
        <div className="flex gap-s-12">
          <h2 className="h3">{t('pendingInvitations')}</h2>

          <div className="flex rounded-full w-s-32 h-s-32 bg-secondary text-primary justify-center items-center">
            {account?.invitationsCount}
          </div>
        </div>

        <span className="text-16 text-neutral-secondary">
          {t('manageInvitations')}
        </span>
      </div>

      <div data-testid="TEST-invitations-table">
        <Table
          data={users}
          columns={columns}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          rowHeight="tall"
          noDataComponent={
            <NoContent
              title={t('list.noContentTitle')}
              message={t('list.noContentMessage')}
              onClick={openModal}
            />
          }
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default InvitationsList;
