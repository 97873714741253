import React, {
  AriaRole,
  ForwardedRef,
  HTMLAttributeAnchorTarget,
  ReactNode,
  useMemo,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import { cn } from '@utils/lib-utils';

import {
  GHOST_STYLES,
  OUTLINE_STYLES,
  PRIMARY_STYLES,
  SECONDARY_STYLES,
} from '../buttons/constants';

type ButtonSize = 'x-small' | 'small' | 'medium' | 'large';

type ButtonLinkProps = {
  children: ReactNode;
  size?: ButtonSize;
  className?: string;
  disabled?: boolean;
  isNavLink?: boolean;
  primary?: boolean;
  outline?: boolean;
  ghost?: boolean;
  icon?: ReactNode;
  to: string;
  id?: string;
  role?: AriaRole;
  target?: HTMLAttributeAnchorTarget;
  rootLocationCheck?: boolean;
  from?: string;
  onClick?: () => void;
};

type Ref = HTMLAnchorElement;

const ButtonLink = React.forwardRef<Ref, ButtonLinkProps>(
  (
    {
      className: customClassName,
      primary,
      outline,
      ghost,
      size = 'medium',
      disabled,
      isNavLink = false,
      children,
      icon,
      to,
      id,
      role,
      target,
      rootLocationCheck,
      from,
      onClick,
    }: ButtonLinkProps,
    ref: ForwardedRef<Ref>
  ) => {
    const { pathname } = useLocation();

    const isActiveLocation = useMemo(
      () =>
        rootLocationCheck
          ? to.length > 1 && pathname.split('/')[1] === to.split('/')[1]
          : to === pathname,
      [rootLocationCheck, pathname, to]
    );

    const className = cn(
      // FIXME: DaisyUi use a very specific class to target the anchor element. We have to use an important here to fix it simply.
      'btn h-auto text-16 font-semibold normal-case focus:!bg-secondary',
      primary && PRIMARY_STYLES,
      outline && OUTLINE_STYLES,
      !(primary || outline) && SECONDARY_STYLES,
      ghost && GHOST_STYLES,
      {
        'border-transparent bg-transparent cursor-default pointer-events-none opacity-20 outline-none select-none':
          disabled,
        'bg-secondary': isNavLink && isActiveLocation,
        'text-14 px-s-16 py-s-10 min-w-[7.375rem] min-h-[2rem] h-s-32':
          size === 'x-small',
        'text-14 px-s-16 py-s-10 min-w-[7.375rem] min-h-[2.5rem] h-s-40':
          size === 'small',
        'text-16 px-s-24 py-s-10 min-w-[8.75rem] min-h-[3rem] h-s-48 lg:min-w-[9.75rem]':
          size === 'medium',
        'text-16 px-s-24 py-s-10 min-w-[8.75rem] min-h-[3.5rem] h-s-56 lg:min-w-[9.75rem]':
          size === 'large',
      },
      customClassName
    );

    return (
      <Link
        ref={ref}
        id={id}
        role={role}
        to={to}
        className={className}
        target={target}
        state={{
          from,
        }}
        onClick={onClick} // FixMe : we should look for another way to apply the onclick event outside of Link
      >
        {icon && (
          <span
            className={cn('font-normal leading-[initial]', {
              'text-16': size === 'x-small' || size === 'small',
              'text-20': size === 'medium' || size === 'large',
            })}
          >
            {icon}
          </span>
        )}
        {children}
      </Link>
    );
  }
);

export default ButtonLink;
