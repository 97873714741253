import { gql } from 'urql';

import { RecruitmentNegotiationFragment } from './fragment';

const RecruitmentNegotiation = gql`
  ${RecruitmentNegotiationFragment}

  query RecruitmentNegotiation($input: RecruitmentNegotiationInput!) {
    recruitmentNegotiation(input: $input) {
      negotiation {
        ...RecruitmentNegotiationFragment
      }
    }
  }
`;

export default RecruitmentNegotiation;
