import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Badge, NumericBadge } from '@application/components';
import { BadgeList } from '@application/components/badge';
import { ButtonLink } from '@application/components/links';
import { ConfirmationModal } from '@application/components/modal';
import { PictureBadges } from '@application/components/picture-badges';
import { RequestTypeBadges } from '@application/components/request-type-badges';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { ModalContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { usePagination } from '@application/hooks';
import { mapDescriptions } from '@application/utils';
import {
  OperationTerritory,
  Request,
  RequestsFilters,
  RequestsSortBy,
  RequestsSortDirective,
  RequestStatusTypeCode,
  SortDirection,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import { getDiffFromNow, isOverDeadline } from '@utils/date-utils';
import { extractLanguage, getLocalizedDescription } from '@utils/i18n-utils';
import { getRequestStatusClassNames } from '@utils/styles-utils';

import { ActionMenu } from '../components';
import useDeleteRequest from './useDeleteRequest';
import useDuplicateRequest from './useDuplicateRequest';
import useGetRequests from './useGetRequests';
import useUpdateRequestStatus from './useUpdateRequestStatus';

const columnIds = {
  avatar: 'avatar',
  jobSpecialtyCode: 'jobSpecialtyCode',
  offerCount: 'offerCount',
  status: 'status',
  type: 'type',
  operationUnitId: 'operationUnitId',
  operationTerritories: 'operationTerritories',
  receivingOfferDeadline: 'receivingOfferDeadline',
  link: 'link',
  actions: 'actions',
  createdAt: 'createdAt',
} as const;

const columnHelper = createColumnHelper<Request>();

const getFormattedDate = (
  date: string,
  tGlobal: TFunction<'translation', undefined>
) => {
  const result = getDiffFromNow(date);
  if (getDiffFromNow(date)?.length === 0) {
    return tGlobal(`date.today`);
  }
  return result
    ?.map((d) =>
      tGlobal(`date.options.${d.unit}`, {
        count: d.value,
      })
    )
    .join(' ');
};

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>,
  onUpdateStatus: (
    id: string,
    status: RequestStatusTypeCode
  ) => () => Promise<void>,
  onDelete: (requestId: string) => () => void,
  onDuplicate: (requestId: string) => () => Promise<void>,
  hash: string
) => {
  const columns: ColumnDef<Request, any>[] = [
    columnHelper.accessor((row) => row.user.avatarUrl, {
      id: columnIds.avatar,
      cell: ({ row }) => (
        <PictureBadges
          pictures={[
            {
              name: row.original.user.name ?? undefined,
              url: row.original.user.avatarUrl,
            },
          ]}
          rounded="rounded-md"
          tooltipPosition="right"
        />
      ),
      header: () => '',
      size: 40,
      minSize: 40,
      maxSize: 40,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) =>
        getLocalizedDescription(row.jobSpecialty?.descriptions, language),
      {
        id: columnIds.jobSpecialtyCode,
        cell: (info) => (
          <Link
            to={generatePath(PrivatePage.REQUEST_DETAILS, {
              id: info.row.original.id,
            })}
            className="hover:underline"
          >
            <span>{info.getValue() || '-'}</span>
          </Link>
        ),
        header: () => t('list.column.job'),
        size: 256,
        minSize: 256,
        maxSize: undefined,
        sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
      }
    ),
    columnHelper.accessor((row) => row, {
      id: columnIds.offerCount,
      cell: ({ row }) => (
        <NumericBadge
          value={row.original.offersCount}
          to={
            row.original.offersCount > 0
              ? generatePath(PrivatePage.OFFER_COMPARISON, {
                  id: row.original.id,
                })
              : undefined
          }
        />
      ),
      header: () => t('list.column.offers'),
      size: 88,
      minSize: 88,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.status, {
      id: columnIds.status,
      cell: (info) => (
        <Badge
          value={t(`list.hashPath.${info.getValue().toLowerCase()}`)}
          placeholder="-"
          className={getRequestStatusClassNames(info.getValue())}
        />
      ),
      header: () => t('list.column.status'),
      size: 110,
      minSize: 110,
      maxSize: 110,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.types, {
      id: columnIds.type,
      cell: (info) => <RequestTypeBadges types={info.getValue()} />,
      header: () => t('list.column.type'),
      size: 122,
      minSize: 122,
      maxSize: 122,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.operationUnit?.name, {
      id: columnIds.operationUnitId,
      cell: (info) => (
        <TooltipEllipsis toolTip={info.getValue() || '-'}>
          <div className="max-w-[9rem]">{info.getValue() || '-'}</div>
        </TooltipEllipsis>
      ),
      header: () => t('list.column.location'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
    }),
    columnHelper.accessor((row) => row.operationTerritories, {
      id: columnIds.operationTerritories,
      cell: (info) => {
        const operationTerritories = mapDescriptions(
          info
            .getValue()
            ?.map((territory: OperationTerritory) => territory.descriptions) ||
            [],
          language
        );

        return <BadgeList values={operationTerritories} />;
      },
      header: () => t('list.column.operationTerritories'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.receivingOfferDeadline, {
      id: columnIds.receivingOfferDeadline,
      cell: (info) => {
        if (!info.getValue()) return '-';
        return (
          <span>
            {isOverDeadline(
              info.getValue().toString(),
              DateTime.now().toString()
            )
              ? tGlobal('date.isOverDeadline')
              : getFormattedDate(info.getValue(), tGlobal)}
          </span>
        );
      },
      header: () => t('list.column.deadline'),
      size: 112,
      minSize: 112,
      maxSize: undefined,
      sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.link,
      cell: ({ row }) =>
        row.original.status === RequestStatusTypeCode.Draft && (
          <ButtonLink
            to={generatePath(PrivatePage.REQUEST_EDIT, {
              id: row.original.id,
            })}
            size="x-small"
            className="whitespace-nowrap py-s-0 animate-none"
          >
            {t('list.actions.resumeCreation')}
          </ButtonLink>
        ),
      header: () => null,
      size: 150,
      minSize: 150,
      maxSize: 150,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.actions,
      cell: ({ row }) => (
        <ActionMenu
          id={row.original.id}
          onUpdateStatus={onUpdateStatus}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          hideDelete={
            row.original.offersCount > 0 ||
            row.original.status === RequestStatusTypeCode.Agreement
          }
          availableTransitions={row.original.availableTransitions}
        />
      ),
      header: () => null,
      size: 32,
      minSize: 32,
      maxSize: 32,
      enableSorting: false,
    }),
  ];

  return columns.filter((column) => {
    if (hash === '#draft') {
      const draftColumns = [
        columnIds.jobSpecialtyCode,
        columnIds.type,
        columnIds.operationUnitId,
        columnIds.link,
        columnIds.actions,
      ];
      return !!draftColumns.some((id) => id === column.id);
    }
    return true;
  });
};

const stringSortToEnum = (by: string, language: string): RequestsSortBy => {
  const extractedLanguage = extractLanguage(language);

  switch (by) {
    case columnIds.receivingOfferDeadline:
      return RequestsSortBy.Deadline;
    case columnIds.operationUnitId:
      return RequestsSortBy.OperationUnit;
    case columnIds.jobSpecialtyCode:
      return extractedLanguage === 'en'
        ? RequestsSortBy.JobSpecialtyEn
        : RequestsSortBy.JobSpecialtyFr;
    case columnIds.createdAt:
      return RequestsSortBy.Date;
    default:
      return extractedLanguage === 'en'
        ? RequestsSortBy.JobSpecialtyEn
        : RequestsSortBy.JobSpecialtyFr;
  }
};

const mapSorting = (
  sorting: SortingState,
  language: string
): RequestsSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id, language),
  }));

type UseRequestsListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<Request>[];
  count?: number;
  currentPage?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  onSortingChange: OnChangeFn<SortingState>;
  requests: Request[];
  sorting: SortingState;
  isLoading: boolean;
};

export const useRequestsList = (
  filters?: RequestsFilters
): UseRequestsListProps => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: columnIds.createdAt, desc: true },
  ]);
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();

  const {
    viewModel: { data, isLoading },
    refetchRequests,
  } = useGetRequests(mapSorting(sorting, i18n.language), filters, pagination);

  useEffect(() => {
    setCursors({
      startCursor: data?.requests.page.pageInfo?.startCursor || undefined,
      endCursor: data?.requests.page.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.requests.page.pageInfo]);

  useEffect(() => {
    resetPagination();
  }, [sorting, resetPagination]);

  const { hash } = useLocation();

  useEffect(() => {
    resetPagination();
    refetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const { updateRequestStatus } = useUpdateRequestStatus();
  const { duplicateRequest } = useDuplicateRequest();
  const { deleteRequest } = useDeleteRequest();

  const handleStatusUpdate = useCallback(
    (id: string, status: RequestStatusTypeCode) => async () => {
      await updateRequestStatus({ requestStatusUpdateInput: { id, status } });
      refetchRequests();
    },
    [updateRequestStatus, refetchRequests]
  );

  const handleDelete = useCallback(
    (requestId: string) => async () => {
      setModal(null);
      await deleteRequest({ deleteRequestInput: { requestId } });
      refetchRequests();
    },
    [deleteRequest, setModal, refetchRequests]
  );

  const confirmDelete = useCallback(
    (requestId: string) => () => {
      setModal({
        title: t('modal.titles.deleteRequest'),
        content: (
          <ConfirmationModal
            content={t('modal.contents.confirmDelete')}
            onCancel={() => setModal(null)}
            onConfirm={handleDelete(requestId)}
          />
        ),
      });
    },
    [setModal, t, handleDelete]
  );

  const handleDuplicate = useCallback(
    (requestId: string) => async () => {
      await duplicateRequest({ input: { requestId } });
      refetchRequests();
    },
    [duplicateRequest, refetchRequests]
  );

  const columns = getColumns(
    i18n.language,
    t,
    tGlobal,
    handleStatusUpdate,
    confirmDelete,
    handleDuplicate,
    hash
  );

  return {
    columnIds,
    columns: columns as ColumnDef<Request>[],
    count: data?.requests.pageData?.count,
    currentPage,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    onSortingChange: setSorting,
    requests: flattenEdges(data?.requests.page.edges?.slice() || []),
    sorting,
    isLoading,
  };
};
