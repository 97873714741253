import { useCallback, useMemo } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, Fieldset, HelperText } from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import { RequestTypeCode } from '@domain/graphql.types';
import { randomString } from '@utils/math-utils';

import { OfferFormFields } from '../../../../types';

type RequestTypesFieldsProps = {
  types: RequestTypeCode[];
};

const RequestTypesFields = ({ types = [] }: RequestTypesFieldsProps) => {
  const { t } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const { control, setValue, trigger, watch }: UseFormReturn<OfferFormFields> =
    useFormContext();

  const helperTextId = useMemo(() => randomString(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestTypes = watch('requestTypes') || [];

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newRequestTypes = event.target.checked
        ? [...requestTypes, event.target.value as RequestTypeCode]
        : (requestTypes
            .filter(
              (type: RequestTypeCode | undefined) => type !== event.target.value
            )
            .filter(Boolean) as RequestTypeCode[]);

      setValue('requestTypes', newRequestTypes);
      trigger('requestTypes');
    },
    [requestTypes, setValue, trigger]
  );

  return (
    <Controller
      name="requestTypes"
      control={control}
      render={({ field: { name }, fieldState: { error } }) => (
        <Fieldset
          legend={t('subtitles.type', {
            count: Object.values(types).length,
          })}
          legendSize="big"
          className="mb-s-32"
          invalid={!!error}
        >
          <Cluster space={16} as="ul">
            {Object.values(types).map((type) => (
              <li key={type}>
                <Checkbox
                  aria-describedby={helperTextId}
                  name={name}
                  value={type}
                  label={tGlobal(`enum.requestTypeCode.${type.toLowerCase()}`)}
                  onChange={handleOnChange}
                  checked={requestTypes.includes(type)}
                  asButton
                />
              </li>
            ))}
          </Cluster>

          {error?.message && t(error?.message) && (
            <HelperText id={helperTextId} invalid={!!error}>
              {tGlobal(error.message)}
            </HelperText>
          )}
        </Fieldset>
      )}
    />
  );
};

export default RequestTypesFields;
