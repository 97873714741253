import { cn } from '@utils/lib-utils';

import ModalBackdrop from '../modal/ModalBackdrop';

type LoadingSpinnerProps = {
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  overPage?: boolean;
};

const LoadingSpinner = ({
  className: customClassName,
  size = 'md',
  overPage = false,
}: LoadingSpinnerProps) => {
  const sizeClassName = {
    'loading-xs': size === 'xs',
    'loading-sm': size === 'sm',
    'loading-md': size === 'md',
    'loading-lg': size === 'lg',
  };

  const overPageClassName = {
    'fixed left-1/2 top-1/2 z-50': overPage,
  };
  const className = cn(
    'loading loading-spinner text-primary',
    sizeClassName,
    overPageClassName,
    customClassName
  );
  return overPage ? (
    <>
      <ModalBackdrop />
      <span className={className} />
    </>
  ) : (
    <span className={className} />
  );
};

export default LoadingSpinner;
