import { useAccountUsers } from '@domain/account-user';
import {
  AccountUsersFilters,
  AccountUsersSortDirective,
} from '@domain/graphql.types';

import { toViewModel } from '../view-models/get-account-users.vm';

const useGetAccountUsers = (
  sortBy: AccountUsersSortDirective[],
  filterBy?: AccountUsersFilters
) => {
  const { data, fetching, error, reexecuteQuery } = useAccountUsers({
    sortBy,
    filterBy,
  });

  const viewModel = toViewModel({
    accountUsers: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchAccountUsers: reexecuteQuery,
  };
};

export default useGetAccountUsers;
