import { Placement } from '@floating-ui/react';
import { cloneElement, ReactElement, useEffect, useRef, useState } from 'react';

import { cn } from '@utils/lib-utils';

import Tooltip from '../tooltip/Tooltip';

type TooltipProps = {
  children: ReactElement;
  toolTip: string;
  lineClamp?: number;
  placement?: Placement;
};

const TooltipEllipsis = ({
  children,
  toolTip,
  lineClamp,
  placement = 'top',
}: TooltipProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const childRef = useRef<HTMLElement>(null);

  const lineClampClassName = {
    'line-clamp-1': lineClamp === 1,
    'line-clamp-2': lineClamp === 2,
    'line-clamp-3': lineClamp === 3,
    'line-clamp-4': lineClamp === 4,
    'line-clamp-5': lineClamp === 5,
    'line-clamp-6': lineClamp === 6,
  };

  const clonedChild = cloneElement(children, {
    ref: childRef,
    className: cn(
      'text-left',
      lineClamp ? { ...lineClampClassName } : 'truncate',
      children.props.className
    ),
  });

  useEffect(() => {
    const child = childRef.current;
    let resizeObserver: ResizeObserver | null = null;

    if (child) {
      resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const target = entry.target as HTMLElement;

          if (lineClamp) {
            const lineHeight = parseInt(
              window.getComputedStyle(child).lineHeight,
              10
            );
            const expectedHeight = lineHeight * lineClamp;

            setIsTruncated(target.scrollHeight > expectedHeight);
          } else {
            setIsTruncated(target.offsetWidth < entry.target.scrollWidth);
          }
        });
      });

      resizeObserver.observe(child);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [lineClamp]);

  return (
    <Tooltip position={placement} message={isTruncated ? toolTip : undefined}>
      {/* Add tabindex 0 in order to show the tooltip when focusing on the div */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div tabIndex={0}>{clonedChild}</div>
    </Tooltip>
  );
};

export default TooltipEllipsis;
