import { ElementType, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

import { Space } from './types';

type StackProps = {
  /**
   * Specify content elements of the `<Box>`
   */
  children: ReactNode;
  /**
   * Specify the HTML element applied to the `<Box>` container
   */
  as?: ElementType;
  /**
   * Specify the space (margin) between successive sibling elements
   */
  space?: Space;
  /**
   * Provide a custom className to be applied to the `<Cluster>`
   */
  className?: string;
};

/**
 * The **Stack** layout applies a space(margin) between successive sibling elements
 *
 * @see [https://every-layout.dev/](https://every-layout.dev/)
 *
 * @example
 * Default use:
 * <Stack>
 *   <div><!-- child --></div>
 *   <div><!-- child --></div>
 *   <div><!-- etc --></div>
 * </Stack>
 *
 * With provided `as` and `space` values
 *  <Stack as='ul' space={24}>
 *   <li><!-- child --></li>
 *   <li><!-- child --></li>
 *   <li><!-- etc --></li>
 * </Stack>
 */
const Stack = ({
  as: Tag = 'div',
  space = 16,
  children,
  className: customClassName,
}: StackProps) => {
  const spaceClassName = {
    '[&>*+*]:[margin-block-start:var(--s-8)]': space === 8,
    '[&>*+*]:[margin-block-start:var(--s-16)]': space === 16,
    '[&>*+*]:[margin-block-start:var(--s-24)]': space === 24,
    '[&>*+*]:[margin-block-start:var(--s-32)]': space === 32,
    '[&>*+*]:[margin-block-start:var(--s-64)]': space === 64,
  };

  const className = cn(
    'flex flex-col justify-start',
    {
      ...spaceClassName,
    },
    customClassName
  );

  return <Tag className={className}>{children}</Tag>;
};

export default Stack;
