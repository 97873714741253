import { gql } from '@urql/core';

const MutationOfferCandidateDelete = gql`
  mutation OfferCandidateDelete($input: OfferCandidateDeleteInput!) {
    offerCandidateDelete(input: $input) {
      ok
    }
  }
`;

export default MutationOfferCandidateDelete;
