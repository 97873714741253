import { useTranslation } from 'react-i18next';

import {
  BadgeGroup,
  PlaceholderSwitcher,
  Stack,
} from '@application/components';
import { SummaryBlock } from '@application/components/summary';
import { Account } from '@domain/graphql.types';

import GeneralInformation from './GeneralInformation';
import ServiceTypes from './ServiceTypes';
import SocialLinks from './SocialLinks';

type AgencyInformationProps = {
  account?: Account;
};

const AgencyInformation = ({ account }: AgencyInformationProps) => {
  const { t } = useTranslation('organization');

  return (
    <>
      <Stack space={24}>
        <h1 className="text-32">{account?.name}</h1>

        <GeneralInformation account={account} />

        <ServiceTypes services={account?.services ?? undefined} />

        <SocialLinks
          linkedinUrl={account?.linkedinUrl ?? undefined}
          facebookUrl={account?.facebookUrl ?? undefined}
          twitterUrl={account?.twitterUrl ?? undefined}
          instagramUrl={account?.instagramUrl ?? undefined}
        />
      </Stack>

      <div>
        <SummaryBlock
          className="mb-s-16"
          verticalSpacing={0}
          headerTitle={t('information.servicesDescription')}
        >
          <PlaceholderSwitcher value={account?.employerOfferDescription}>
            <span className="text-14 text-neutral-secondary">
              {account?.employerOfferDescription}
            </span>
          </PlaceholderSwitcher>
        </SummaryBlock>

        <SummaryBlock
          verticalSpacing={0}
          headerTitle={t('information.locations')}
        >
          <PlaceholderSwitcher value={account?.operationUnits}>
            <BadgeGroup
              elements={account?.operationUnits?.map(({ name }) => name)}
            />
          </PlaceholderSwitcher>
        </SummaryBlock>
      </div>
    </>
  );
};

export default AgencyInformation;
