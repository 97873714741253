import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useRecruitmentNegotiationComplete } from '@domain/negotiation';

import { toViewModel } from '../view-models/complete-recruitment-negotiation.vm';

const useCompleteRecruitmentNegotiation = () => {
  const { data, fetching, error, completeRecruitmentNegotiation } =
    useRecruitmentNegotiationComplete();

  const { t } = useTranslation('recruitment', {
    keyPrefix: 'messages',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    negotiation: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('completeNegotiationError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.recruitmentNegotiationComplete.negotiation) {
      setAlertContent({
        action: t('completeNegotiationSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { completeRecruitmentNegotiation, viewModel };
};

export default useCompleteRecruitmentNegotiation;
