import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MultiValue } from 'react-select';

import { OptionType } from '@application/components/select-field/select';
import { debounce } from '@application/utils';
import {
  CandidateRequestsFilters,
  CandidateRequestType,
  RequestStatusTypeCode,
} from '@domain/graphql.types';

const defaultFilters = {
  dates: undefined,
  ids: undefined,
  operationTerritoryCodes: undefined,
  jobSpecialtyCodes: undefined,
  operationUnitIds: undefined,
  status: undefined,
  types: undefined,
};

const statusCodeMap: Record<string, CandidateRequestsFilters['status']> = {
  '#in-progress': [RequestStatusTypeCode.InProgress],
  '#agreement': [RequestStatusTypeCode.Agreement],
  '#draft': [RequestStatusTypeCode.Draft],
};

type UseCandidateRequestFiltersProps = {
  resetPagination?: () => void;
};

const useCandidateRequestFilters = ({
  resetPagination,
}: UseCandidateRequestFiltersProps) => {
  const [filters, setFilters] =
    useState<CandidateRequestsFilters>(defaultFilters);

  const location = useLocation();

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      status: statusCodeMap[location.hash] || undefined,
    }));
  }, [location.hash]);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateTextFilter = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }));
  });

  const updateSelectFilters =
    (fieldName: keyof CandidateRequestsFilters) =>
    (values: MultiValue<OptionType>) => {
      let filterValues: (string | CandidateRequestType)[] = [];

      if (values.length) {
        filterValues = values.map((v: OptionType) =>
          fieldName === 'types'
            ? CandidateRequestType[v.value as keyof typeof CandidateRequestType]
            : v.value
        );
      }

      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: filterValues,
      }));
    };

  const updateDateFilters =
    (fieldName: keyof CandidateRequestsFilters) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: event.target.value
          ? [new Date(event.target.value)]
          : undefined,
      }));
    };

  return {
    filters,
    updateSelectFilters,
    updateDateFilters,
    updateTextFilter,
  };
};

export default useCandidateRequestFilters;
