import { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Cluster,
  CreatableSelectField,
  Fieldset,
  FormInputWithSuffix,
  HelperText,
  Radio,
  RadioGroup,
  SelectField,
  Stack,
  Switcher,
} from '@application/components';
import { OptionType } from '@application/components/select-field/select';
import { AccountContext, ModalContext } from '@application/context';
import { mapOptions } from '@application/utils';
import { CreateOperationUnitModal } from '@application/views/organization';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';
import {
  useGetJobModes,
  useGetJobScheduleTypes,
} from '@application/views/recruitment/request/hooks';
import {
  CandidateOpportunity,
  CandidateRequestType,
  JobDurationCode,
  JobModeCode,
  Season,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';
import { INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX } from '@utils/yup-utils';

import { Section } from '../../components';
import { CandidateOfferFormFields } from '../../schema';
import PaymentFields from './PaymentFields';

type DescriptionFieldsProps = {
  candidateOpportunity?: CandidateOpportunity;
  clearErrors: UseFormClearErrors<CandidateOfferFormFields>;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  register: UseFormRegister<CandidateOfferFormFields>;
  setError: UseFormSetError<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  trigger: UseFormTrigger<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const DescriptionFields = ({
  candidateOpportunity,
  clearErrors,
  control,
  errors,
  register,
  setError,
  setValue,
  trigger,
  watch,
}: DescriptionFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { account } = useContext(AccountContext);
  const { setModal } = useContext(ModalContext);

  const { data: JOB_MODES = [] } = useGetJobModes();
  const { data: JOB_SCHEDULE_TYPES = [] } = useGetJobScheduleTypes();
  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();

  const OPERATION_TERRITORIES_OPTIONS = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n]
  );

  const OPERATION_UNITS_OPTIONS = useMemo(
    () =>
      account?.operationUnits?.map((op) => ({
        value: op.id,
        label: op.name,
      })) || [],
    [account]
  );

  const jobType = watch('jobType');
  const jobModeCode = watch('jobModeCode');
  const schedules = watch('schedules') || [];
  const seasons = watch('seasons') || [];
  const hoursPerWeek = watch('hoursPerWeek');

  const JOB_TYPE_OPTIONS = useMemo(() => {
    switch (candidateOpportunity?.type) {
      case CandidateRequestType.Recruitment:
        return [
          JobDurationCode.Permanent,
          JobDurationCode.Occasional,
          JobDurationCode.Seasonal,
          JobDurationCode.Internship,
          JobDurationCode.Contract,
        ];
      case CandidateRequestType.TemporaryPlacement:
        return [
          JobDurationCode.Seasonal,
          JobDurationCode.Contract,
          JobDurationCode.OpenEndedContract,
        ];
      case CandidateRequestType.Outsourcing:
        return [
          JobDurationCode.Freelance,
          JobDurationCode.Contract,
          JobDurationCode.OpenEndedContract,
        ];
      default:
        return JobDurationCode;
    }
  }, [candidateOpportunity]);

  const onSubmitOperationUnit = useCallback(
    (operationUnitId: string) => {
      setValue('operationUnitId', operationUnitId);
      setModal(null);
    },
    [setModal, setValue]
  );

  const handleOperationUnitModal = useCallback(() => {
    setModal({
      title: t('modal.titles.createOperationUnit'),
      maxWidth: '2xl',
      content: (
        <CreateOperationUnitModal
          afterSubmit={onSubmitOperationUnit}
          onClose={() => setModal(null)}
        />
      ),
    });
  }, [onSubmitOperationUnit, setModal, t]);

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateOfferFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  const handleSchedulesChange = (event: any): void => {
    if (event.target.checked) {
      setValue('schedules', [...schedules, event.target.value]);
    } else {
      setValue('schedules', [
        ...schedules.filter((s) => s !== event.target.value),
      ]);
    }
  };

  const handleSeasonsChange = (event: any): void => {
    if (event.target.checked) {
      setValue('seasons', [...seasons, event.target.value]);
    } else {
      setValue('seasons', [...seasons.filter((s) => s !== event.target.value)]);
    }
    trigger('seasons');
  };

  return (
    <Section legend={t('subtitles.offerDescription')} className="mb-s-16">
      <Stack space={24}>
        <Switcher space={24}>
          <Controller
            name="operationUnitId"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <SelectField
                label={t('labels.location')}
                name={name}
                options={OPERATION_UNITS_OPTIONS}
                value={
                  OPERATION_UNITS_OPTIONS.find((o) => o.value === value) || null
                }
                onChange={(option: any) => onChange(option?.value || null)}
                renderMenuFooterContent={
                  <Button
                    className="px-s-8"
                    icon={<i className="ri-add-line" />}
                    size="x-small"
                    ghost
                    onClick={handleOperationUnitModal}
                  >
                    {t('labels.addNewOperationUnit')}
                  </Button>
                }
                invalid={!!errors.operationUnitId}
                helperText={
                  errors.operationUnitId?.message &&
                  tGlobal(errors.operationUnitId?.message)
                }
                clearable
                searchable
              />
            )}
          />

          <Controller
            name="operationTerritoryCodes"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <SelectField
                label={t('labels.operationTerritories')}
                name={name}
                options={OPERATION_TERRITORIES_OPTIONS}
                value={
                  OPERATION_TERRITORIES_OPTIONS.filter((l) =>
                    value?.includes(l.value)
                  ) || null
                }
                onChange={(options: any) =>
                  onChange(
                    options
                      ? options.map((option: OptionType) => option.value)
                      : null
                  )
                }
                invalid={!!errors.operationTerritoryCodes}
                helperText={
                  errors.operationTerritoryCodes?.message &&
                  tGlobal(errors.operationTerritoryCodes?.message)
                }
                searchable
                clearable
                multiple
                limitTags={0}
              />
            )}
          />
        </Switcher>

        <Controller
          name="jobType"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset
              legend={t('labels.jobType')}
              legendSize="small"
              invalid={!!errors.jobType?.message}
            >
              <Cluster space={16} as="ul">
                {Object.values(JOB_TYPE_OPTIONS).map((code) => (
                  <li key={code}>
                    <Radio
                      name={name}
                      value={code}
                      label={t(`enum.jobDurationCode.${code.toLowerCase()}`)}
                      onChange={(e) => {
                        handleOnRadioChange(
                          e as ChangeEvent<HTMLInputElement>,
                          name
                        );
                        setValue('jobDurationInMonths', undefined);
                        setValue('seasons', undefined);
                        trigger('paymentFrequencyCode');
                        trigger('jobDurationInMonths');
                        trigger('seasons');
                        trigger(name);
                      }}
                      checked={jobType === code}
                      asButton
                    />
                  </li>
                ))}
              </Cluster>

              {errors.jobType?.message && (
                <HelperText id="jobType-error" invalid>
                  {tGlobal(errors.jobType.message)}
                </HelperText>
              )}
            </Fieldset>
          )}
        />

        {(jobType === JobDurationCode.Contract ||
          jobType === JobDurationCode.Freelance ||
          jobType === JobDurationCode.Internship) && (
          <FormInputWithSuffix
            label={t('labels.jobDurationInMonths')}
            type="number"
            minValue={1}
            suffix={t('suffix.months')}
            mediumSuffix
            noMargin
            invalid={!!errors.jobDurationInMonths}
            helperText={
              errors.jobDurationInMonths?.message &&
              tGlobal(errors.jobDurationInMonths?.message, { min: '1' })
            }
            {...register('jobDurationInMonths')}
          />
        )}

        {jobType === JobDurationCode.Seasonal && (
          <Controller
            name="seasons"
            control={control}
            render={({ field: { value } }) => (
              <Fieldset
                legend={t('labels.seasons')}
                legendSize="small"
                invalid={!!errors.seasons?.message}
              >
                <Cluster space={16} as="ul">
                  {Object.values(Season).map((code) => (
                    <li key={code}>
                      <Checkbox
                        name={code}
                        value={code}
                        label={t(`enum.seasons.${code.toLowerCase()}`)}
                        asButton
                        onChange={handleSeasonsChange}
                        checked={value?.includes(code)}
                      />
                    </li>
                  ))}
                </Cluster>

                {errors.seasons?.message && (
                  <HelperText id="seasons-error" invalid>
                    {tGlobal(errors.seasons.message)}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />
        )}

        <Cluster space={16} className="items-center">
          <Controller
            name="hoursPerWeek"
            control={control}
            render={({ field: { name } }) => (
              <CreatableSelectField
                name={name}
                label={t('labels.hoursPerWeek')}
                value={
                  hoursPerWeek?.map((v) => ({
                    label: v,
                    value: v,
                  })) as OptionType[]
                }
                className="flex-1 min-w-fit"
                validateOnKeyDown={(newValue: string) =>
                  INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                }
                onInputChange={(newValue: string) => {
                  // Check if entered value is an integer or one decimal digit number only
                  // return an error otherwise
                  if (
                    newValue &&
                    !INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                  ) {
                    setError(name, {
                      type: 'custom',
                      message: tGlobal(
                        'validations.integerOrOneDecimalDigitNumber'
                      ),
                    });
                    return;
                  }
                  clearErrors(name);
                }}
                onChange={(options: any) => {
                  setValue(
                    name,
                    options.map((option: any) => option.value)
                  );
                  trigger(name);
                }}
                formatOptionLabel={({ value: newValue }) => `${newValue}h`}
                invalid={!!errors.hoursPerWeek}
                helperText={
                  errors.hoursPerWeek?.message
                    ? tGlobal(errors.hoursPerWeek?.message)
                    : tGlobal('creatableSelect.enterHoursAsIntegerOrDecimal')
                }
              />
            )}
          />

          <Controller
            name="fullTimeAvailability"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Checkbox
                name={name}
                value={String(value)}
                label={t('labels.fullTime')}
                onChange={onChange}
                checked={Boolean(value)}
                asButton
              />
            )}
          />

          <Controller
            name="partTimeAvailability"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Checkbox
                name={name}
                value={String(value)}
                label={t('labels.partTime')}
                onChange={onChange}
                checked={Boolean(value)}
                asButton
              />
            )}
          />
        </Cluster>

        <Controller
          name="schedules"
          control={control}
          render={({ field: { value } }) => (
            <CheckboxGroup
              data={JOB_SCHEDULE_TYPES}
              legend={t('labels.scheduleMultiple')}
              language={extractLanguage(i18n.language)}
              onChange={handleSchedulesChange}
              legendSize="small"
              values={value}
            />
          )}
        />

        <Controller
          name="jobModeCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_MODES}
              legend={t('labels.remoteWork')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobModeCode);
                trigger(name);
                trigger('remoteDaysAllowed');
              }}
              legendSize="small"
              value={value as string}
              invalid={!!errors.jobModeCode}
              helperText={
                errors.jobModeCode?.message &&
                tGlobal(errors.jobModeCode?.message)
              }
            />
          )}
        />

        {jobModeCode === JobModeCode.Hybrid && (
          <FormInputWithSuffix
            type="number"
            minValue={1}
            maxValue={7}
            label={t('labels.remoteDaysAllowed')}
            suffix={t('suffix.daysPerWeek')}
            noMargin
            invalid={!!errors.remoteDaysAllowed}
            helperText={
              errors.remoteDaysAllowed?.message &&
              tGlobal(errors.remoteDaysAllowed?.message, { min: '1', max: '7' })
            }
            {...register('remoteDaysAllowed')}
          />
        )}

        <PaymentFields
          candidateOpportunity={candidateOpportunity}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      </Stack>
    </Section>
  );
};

export default DescriptionFields;
