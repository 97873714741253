import { ButtonLink } from '@application/components';
import { cn } from '@utils/lib-utils';

type ListIconButtonLinkProps = {
  count?: number;
  iconName: string;
  to: string;
};

const ListIconButtonLink = ({
  count = 0,
  iconName,
  to,
}: ListIconButtonLinkProps) => (
  <ButtonLink
    className="relative min-w-[2.5rem] min-h-[2.5rem] h-s-40 w-s-40 p-s-0 justify-self-end"
    size="small"
    to={to}
  >
    <i className={cn(iconName, 'font-normal text-20')} />

    {count > 0 && (
      <div className="flex justify-center items-center absolute bg-secondary-focus w-s-28 h-s-28 rounded-full top-[-0.75rem] right-[-0.75rem]">
        <span className="font-normal">{count}</span>
      </div>
    )}
  </ButtonLink>
);

export default ListIconButtonLink;
