import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { mapDescriptions } from '@application/utils';
import { OperationTerritory } from '@domain/graphql.types';

const useLocalizedOperationTerritories = (
  data: readonly OperationTerritory[] | undefined | null
) => {
  const { i18n } = useTranslation();

  const operationTerritories = useMemo(
    () =>
      mapDescriptions(
        data?.map((territory) => territory.descriptions) || [],
        i18n.language
      ),
    [data, i18n.language]
  );

  return operationTerritories;
};

export default useLocalizedOperationTerritories;
