import { useJobModesGet } from '@domain/job';

import { toViewModel } from '../view-models/get-job-modes.vm';

const useGetJobModes = () => {
  const { data = {}, fetching, error } = useJobModesGet();

  return toViewModel({
    jobModes: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetJobModes;
