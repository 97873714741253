import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateNegotiationCreateArgs,
} from '@domain/graphql.types';
import { MutationCandidateNegotiationCreate } from '@infrastructure/graphql/candidate';

const useCandidateNegotiationCreate = () => {
  const [{ data, fetching, error }, createCandidateNegotiation] = useMutation<
    Pick<Mutation, 'candidateNegotiationCreate'>,
    MutationCandidateNegotiationCreateArgs
  >(MutationCandidateNegotiationCreate);

  return {
    createCandidateNegotiation,
    data,
    fetching,
    error,
  };
};

export default useCandidateNegotiationCreate;
