import { gql } from '@urql/core';

import { CandidateOpportunityFragment } from './fragment';

const CandidateOpportunity = gql`
  ${CandidateOpportunityFragment}

  query CandidateOpportunity($input: CandidateOpportunityInput!) {
    candidateOpportunity(input: $input) {
      opportunity {
        ...CandidateOpportunityFragment
      }
    }
  }
`;

export default CandidateOpportunity;
