import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Fieldset, TextInputField } from '@application/components';
import {
  Box,
  Stack,
  Switcher,
} from '@application/components/container-layouts';
import { SwitchField } from '@application/components/form/switch-field';
import { SelectField } from '@application/components/select-field';
import { Languages } from '@application/enums/languages';

import { UserProfileFormFields } from '../types';

type PersonalInformationProps = {
  control: Control<UserProfileFormFields>;
  errors: FieldErrors<UserProfileFormFields>;
  register: UseFormRegister<UserProfileFormFields>;
  organization?: string;
};

const PersonalInformation = ({
  control,
  register,
  errors,
  organization,
}: PersonalInformationProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('user');

  const LanguagesOptions = Object.entries(Languages).map(([key, value]) => ({
    value,
    label: tGlobal(`languages.${key}`),
  }));

  return (
    <Box padding={24}>
      <Fieldset legend={t('subtitles.personalInformation')} hideLegend>
        <Stack space={24}>
          <Switcher space={24}>
            <TextInputField
              label={t('labels.lastName')}
              invalid={!!errors.lastName}
              helperText={
                errors.lastName?.message &&
                tGlobal(errors.lastName?.message, {
                  max: '128',
                })
              }
              {...register('lastName')}
            />

            <TextInputField
              label={t('labels.firstName')}
              invalid={!!errors.firstName}
              helperText={
                errors.firstName?.message &&
                tGlobal(errors.firstName?.message, {
                  max: '128',
                })
              }
              {...register('firstName')}
            />
          </Switcher>

          <TextInputField
            label={t('labels.professionalEmail')}
            invalid={!!errors.professionalEmail}
            helperText={
              errors.professionalEmail?.message &&
              tGlobal(errors.professionalEmail?.message, {
                max: '128',
              })
            }
            {...register('professionalEmail')}
          />

          <Switcher space={24}>
            <TextInputField
              label={t('labels.jobDescription')}
              invalid={!!errors.jobDescription}
              helperText={
                errors.jobDescription?.message &&
                tGlobal(errors.jobDescription?.message, {
                  max: '128',
                })
              }
              {...register('jobDescription')}
            />

            <TextInputField
              name="organization"
              label={t('labels.organization')}
              readOnly
              value={organization}
            />
          </Switcher>

          <Switcher space={24}>
            <Controller
              name="spokenLanguages"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <SelectField
                  className="flex-grow"
                  name={name}
                  label={t('labels.spokenLanguages')}
                  multiple
                  options={LanguagesOptions}
                  value={LanguagesOptions.filter((o) =>
                    value?.includes(o.value)
                  )}
                  onChange={(options: any) => {
                    onChange(options.map((option: any) => option.value));
                  }}
                />
              )}
            />

            <div>
              <TextInputField
                label={t('labels.phoneNumber')}
                invalid={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber?.message &&
                  tGlobal(errors.phoneNumber?.message, {
                    max: '36',
                  })
                }
                {...register('phoneNumber')}
              />
              <SwitchField
                className="mt-s-8"
                label={t('labels.phoneDisplay')}
                {...register('phoneDisplay')}
              />
            </div>
          </Switcher>

          <TextInputField
            label={t('labels.location')}
            invalid={!!errors.location}
            helperText={
              errors.location?.message &&
              tGlobal(errors.location?.message, { max: '256' })
            }
            {...register('location')}
          />
        </Stack>
      </Fieldset>
    </Box>
  );
};

export default PersonalInformation;
