import React, { ForwardedRef, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { cn } from '@utils/lib-utils';

type NavigationButtonProps = {
  childrenUrls: string[] | undefined;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
};

type Ref = HTMLButtonElement;

const NavigationButton = React.forwardRef<Ref, NavigationButtonProps>(
  (
    {
      className: customClassName,
      disabled,
      onClick,
      children,
      childrenUrls,
      icon,
    }: NavigationButtonProps,
    ref: ForwardedRef<Ref>
  ) => {
    const { pathname } = useLocation();

    const isActiveLocation = useMemo(
      () =>
        childrenUrls?.some(
          (url) =>
            url.length > 1 && pathname.split('/')[1] === url.split('/')[1]
        ),
      [childrenUrls, pathname]
    );

    const className = cn(
      // FIXME: DaisyUi use a very specific class to target the anchor element. We have to use an important here to fix it simply.
      'btn-ghost normal-case font-normal px-s-16 py-s-12 text-left rounded-md max-w-full hover:bg-secondary focus:!bg-secondary active:bg-secondary',
      customClassName,
      isActiveLocation && 'bg-secondary'
    );

    return (
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        className={className}
        disabled={disabled}
      >
        {icon && icon}
        {children}
      </button>
    );
  }
);

export default NavigationButton;
