import { cn } from '@utils/lib-utils';

type BadgeGroupProps<T> = {
  elements?: T[];
  variant?: 'default' | 'primary';
};

const BadgeGroup = <T extends { toString(): string }>({
  elements,
  variant,
}: BadgeGroupProps<T>) => (
  <div className="flex flex-wrap gap-s-10">
    {elements?.length
      ? elements?.map((el) => (
          <div
            className={cn(
              'text-primary px-s-16 py-s-10 bg-neutral rounded-sm',
              {
                'font-semibold border border-secondary rounded-md bg-base-100':
                  variant === 'primary',
              }
            )}
            key={el.toString()}
          >
            {el.toString()}
          </div>
        ))
      : '-'}
  </div>
);

export default BadgeGroup;
