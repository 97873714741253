import { gql } from '@urql/core';

const MutationNegotiatingCandidateOfferUpdate = gql`
  mutation NegotiatingCandidateOfferUpdate(
    $input: NegotiatingCandidateOfferUpdateInput!
  ) {
    negotiatingCandidateOfferUpdate(input: $input) {
      candidateOffer {
        id
      }
    }
  }
`;

export default MutationNegotiatingCandidateOfferUpdate;
