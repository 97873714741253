import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { ConfirmationModal } from '@application/components/modal';
import { UserCell } from '@application/components/user-cell';
import { AccountContext, ModalContext } from '@application/context';
import {
  AccountUser,
  AccountUserSortBy,
  AccountUsersSortDirective,
  AccountUserStatus,
  SortDirection,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import { formatDate } from '@utils/date-utils';

import { InvitationsListContext } from '../context';
import useDeleteInvitation from './useDeleteInvitation';
import useGetAccountUsers from './useGetAccountUsers';
import useResendInvitation from './useResendInvitation';

const columnIds = {
  name: 'name',
  createdAt: 'createdAt',
  actions: 'actions',
} as const;

const columnHelper = createColumnHelper<AccountUser>();

const getColumns = (
  t: TFunction<'translation', undefined>,
  language: string,
  deleteInvitation: (id: string) => () => void,
  resendInvitation: (id: string) => () => void
) => [
  columnHelper.accessor((row) => row.user, {
    id: columnIds.name,
    cell: (info) => <UserCell user={info.getValue()} />,
    header: () => <span>{t(columnIds.name)}</span>,
    size: 350,
    minSize: 350,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.createdAt, {
    id: columnIds.createdAt,
    cell: (info) => <span>{formatDate(info.getValue(), language)}</span>,
    header: () => <span>{t('sentAt')}</span>,
    size: undefined,
    minSize: 250,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.id, {
    id: columnIds.actions,
    cell: (info) => (
      <div className="flex justify-end">
        <Button
          className="mr-s-16 animate-none"
          onClick={deleteInvitation(info.getValue())}
        >
          {t('deleteInvitation')}
        </Button>

        <Button
          className="animate-none"
          primary
          onClick={resendInvitation(info.getValue())}
        >
          {t('resendInvitation')}
        </Button>
      </div>
    ),
    header: () => null,
    size: 400,
    minSize: 400,
    maxSize: undefined,
  }),
];

const stringSortToEnum = (by: string): AccountUserSortBy => {
  switch (by) {
    case columnIds.createdAt:
      return AccountUserSortBy.CreatedAt;
    default:
      return AccountUserSortBy.Name;
  }
};

const mapSorting = (sorting: SortingState): AccountUsersSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id),
  }));

type UseInvitationsList = {
  columns: ColumnDef<AccountUser>[];
  columnIds: typeof columnIds;
  users: AccountUser[];
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  isLoading: boolean;
};

export const useInvitationsList = (): UseInvitationsList => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'users.list',
  });

  const {
    viewModel: { data, isLoading },
    refetchAccountUsers,
  } = useGetAccountUsers(mapSorting(sorting), {
    status: AccountUserStatus.PendingInvitation,
  });

  const { deleteInvitation } = useDeleteInvitation();
  const { resendInvitation } = useResendInvitation();

  const { refreshAccount } = useContext(AccountContext);
  const { setModal } = useContext(ModalContext);
  const { shouldRefreshList } = useContext(InvitationsListContext);

  useEffect(() => {
    if (shouldRefreshList) {
      refetchAccountUsers();
    }
  }, [refetchAccountUsers, shouldRefreshList]);

  const deleteInvitationHandler = useCallback(
    (accountUserId: string) => async () => {
      setModal(null);

      await deleteInvitation({ input: { accountUserId } });

      refetchAccountUsers();
      refreshAccount();
    },
    [deleteInvitation, refetchAccountUsers, setModal, refreshAccount]
  );

  const resendInvitationHandler = useCallback(
    (accountUserId: string) => () => {
      resendInvitation({ input: { accountUserId } });
    },
    [resendInvitation]
  );

  const confirmInvitationDeletion = useCallback(
    (accountUserId: string) => async () => {
      setModal({
        title: t('actions.deleteInvitationTitle'),
        content: (
          <ConfirmationModal
            content={t('actions.deleteInvitationMessage')}
            onCancel={() => setModal(null)}
            onConfirm={deleteInvitationHandler(accountUserId)}
          />
        ),
      });
    },
    [setModal, t, deleteInvitationHandler]
  );

  const columns = getColumns(
    t,
    i18n.language,
    confirmInvitationDeletion,
    resendInvitationHandler
  );

  return {
    columns: columns as ColumnDef<AccountUser>[],
    columnIds,
    users: flattenEdges(data?.accountUsers.page.edges?.slice() || []),
    sorting,
    onSortingChange: setSorting,
    isLoading,
  };
};
