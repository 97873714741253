import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { DeleteAccountUserResponse } from '@domain/graphql.types';

type ToViewModel = {
  accountUserDelete: {
    data: DeleteAccountUserResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  accountUserDelete: { data, fetching, error },
}: ToViewModel): ViewModel<DeleteAccountUserResponse | undefined> =>
  new ViewModel<DeleteAccountUserResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
