import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions, PagePanel } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';

import { CandidateRequestFormFields } from '../../schema';
import ExpectationsFields from './ExpectationsFields';
import InformationFields from './InformationFields';
import NoteFields from './NoteFields';
import SoftSkillsFields from './SoftSkillsFields';

type DescriptionPanelProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  trigger: UseFormTrigger<CandidateRequestFormFields>;
  register: UseFormRegister<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
  setError: UseFormSetError<CandidateRequestFormFields>;
  clearErrors: UseFormClearErrors<CandidateRequestFormFields>;
  nextStep?: () => void;
  resumeUrl?: string;
};

const DescriptionPanel = ({
  control,
  errors,
  trigger,
  setValue,
  nextStep,
  register,
  setError,
  clearErrors,
  watch,
  resumeUrl,
}: DescriptionPanelProps) => {
  const { t } = useTranslation('candidates');

  return (
    <PagePanel title={t('steps.description')}>
      <InformationFields
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        watch={watch}
        trigger={trigger}
        setError={setError}
        clearErrors={clearErrors}
        resumeUrl={resumeUrl}
      />

      <ExpectationsFields
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        watch={watch}
        setError={setError}
        clearErrors={clearErrors}
        trigger={trigger}
      />

      <SoftSkillsFields />

      <NoteFields control={control} errors={errors} />

      <FormActions
        nextStep={nextStep}
        cancelPath={RootPrivatePage.CANDIDATE_REQUESTS}
      />
    </PagePanel>
  );
};

export default DescriptionPanel;
