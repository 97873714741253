import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';

import { ButtonLink, IconButton } from '@application/components';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';

import { useGetOfferAccountName } from './hooks';
import { OfferCandidatesList } from './list';

const OfferCandidatesPage = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('offers', { keyPrefix: 'candidates' });

  const navigate = useNavigate();

  const { id = '', offerId = '' } = useParams();

  if (!(offerId && isUUID(offerId)) || !(id && isUUID(id))) {
    navigate(RootPrivatePage.NOT_FOUND, { replace: true });
  }

  const {
    viewModel: { data: accountName, isLoading },
  } = useGetOfferAccountName({ offerId });

  return (
    <>
      <h1 className="sr-only">{t('pageTitle')}</h1>

      <div className="flex flex-col gap-s-32">
        <div className="flex flex-col lg:flex-row justify-between gap-s-32">
          <div className="flex items-center gap-s-16">
            <IconButton
              icon={<i className="ri-arrow-left-line" />}
              text={tGlobal('button.back')}
              onClick={() =>
                navigate(generatePath(PrivatePage.OFFER_COMPARISON, { id }))
              }
            />

            {isLoading ? (
              <div className="animate-pulse bg-skeleton rounded-md w-s-384 h-s-32" />
            ) : (
              <h2 className="h3">{t('listTitle', { accountName })}</h2>
            )}
          </div>

          {/* FIXME: Add link to NegoRoom */}
          <ButtonLink
            className="w-fit"
            primary
            icon={<i className="ri-shake-hands-line text-20" />}
            to="#"
            disabled
          >
            {t('negoRoomButton')}
          </ButtonLink>
        </div>

        <OfferCandidatesList offerId={offerId} />
      </div>
    </>
  );
};

export default OfferCandidatesPage;
