import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import SummaryBadges from '@application/components/summary/SummaryBadges';
import SummaryDescription from '@application/components/summary/SummaryDescription';
import { Request } from '@domain/graphql.types';

type SkillProps = {
  data: Request | undefined;
};

const Skills = ({ data }: SkillProps) => {
  const { t } = useTranslation('requests');

  const skillFields = useMemo(
    () => [
      {
        text: t('labels.softSkills'),
        badges: data?.softSkills?.map((softSkill) => softSkill) || [],
      },
    ],
    [data, t]
  );

  return (
    <>
      <SummaryHeader
        className="mt-s-16"
        headerTitle={t('subtitles.skillsCompetencies')}
      />

      <section className="grid grid-cols-[1fr] border rounded-b-md">
        {skillFields.map((remuneration, index) => (
          <SectionGridRow key={remuneration.text} isTopRow={index === 0}>
            {remuneration.text} : &nbsp;
            {remuneration.badges ? (
              <SummaryBadges badges={remuneration.badges} />
            ) : (
              <SummaryDescription description={remuneration.text} />
            )}
          </SectionGridRow>
        ))}
      </section>
    </>
  );
};

export default Skills;
