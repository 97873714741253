import { useTranslation } from 'react-i18next';

import { LanguageButtons } from '@application/components/buttons';
import { Stack } from '@application/components/container-layouts';
import { ButtonLink } from '@application/components/links';
import { PublicPage } from '@application/enums/pagesUrl';

const ErrorBoundary = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row-reverse p-s-32">
        <LanguageButtons />
      </div>

      <Stack className="flex items-center justify-center h-full" space={32}>
        <h1>{t('error.title')}</h1>

        <p className="w-s-384 text-center">{t('error.message')}</p>

        <ButtonLink primary to={PublicPage.HOME}>
          {t('error.backToHome')}
        </ButtonLink>
      </Stack>
    </div>
  );
};

export default ErrorBoundary;
