import { ReactNode, useMemo, useRef } from 'react';

import { randomString } from '@utils/math-utils';

import Button from './Button';

type FileUploadButtonProps = {
  /**
   * Specify the id of the`<FileUploadButton>`
   */
  id?: string;
  /**
   * Specify content elements of the `<FileUploadButton>`
   */
  children: ReactNode;
  /**
   * Optionally provide a custom className to apply to  the `<FileUploadButton>`
   */
  className?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever the hidden `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

const FileUploadButton = ({
  children,
  onChange,
  className: customClassName,
  ...rest
}: FileUploadButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const buttonLabelId = useMemo(() => randomString(), []);

  const handleClick = () => {
    // reset value here to bypass default file input behavior
    // which not firing upon selecting the same file.
    if (hiddenFileInput?.current?.value) {
      hiddenFileInput.current.value = '';
    }
    hiddenFileInput?.current?.click();
  };

  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        aria-labelledby={buttonLabelId}
        className="sr-only"
        onChange={onChange}
        {...rest}
      />

      <Button primary onClick={handleClick} className={customClassName}>
        <span id={buttonLabelId}>{children}</span>
      </Button>
    </>
  );
};

export default FileUploadButton;
