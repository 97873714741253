import { Notification } from '@magicbell/magicbell-react';
import { TFunction } from 'i18next';
import { generatePath } from 'react-router';

import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { Descriptions } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { CategoryMap } from './types';

export const categoryConfig: CategoryMap = {
  offer_created: {
    titleKey: 'notifications.category.offers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.offerCreated', {
        accountName,
        requestName,
      });
    },
  },
  offer_updated: {
    titleKey: 'notifications.category.offers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.offerUpdated', {
        accountName: customAttributes.offerAccountName,
        requestName,
      });
    },
  },
  candidate_offer_created: {
    titleKey: 'notifications.category.candidateOffers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateOfferCreated', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_offer_updated: {
    titleKey: 'notifications.category.candidateOffers',
    icon: 'money-dollar-circle',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_REQUESTS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateOfferUpdated', {
        accountName: customAttributes.candidateOfferAccountName,
        candidateName,
      });
    },
  },
  request_created: {
    titleKey: 'notifications.category.request',
    icon: 'suitcase',
    getActionUrl: ({ topic: id }) =>
      id
        ? generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, { id })
        : RootPrivatePage.RECRUITMENT_OPPORTUNITIES,
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.requestCreated', { requestName });
    },
  },
  request_updated: {
    titleKey: 'notifications.category.request',
    icon: 'suitcase',
    getActionUrl: ({ topic: id }) =>
      id
        ? generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, { id })
        : RootPrivatePage.RECRUITMENT_OPPORTUNITIES,
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.requestUpdated', { requestName });
    },
  },
  recruitment_negotiation_room_opened: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationRoomOpened', {
        requestName,
      });
    },
  },
  recruitment_negotiation_room_updated: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationRoomUpdated', {
        accountName,
        requestName,
      });
    },
  },
  recruitment_negotiation_room_rejected: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationRoomRejected', {
        accountName,
        requestName,
      });
    },
  },
  recruitment_negotiation_completed: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t('notifications.messages.recruitmentNegotiationCompleted', {
        accountName,
        requestName,
      });
    },
  },
  recruitment_negotiation_room_message_received: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'suitcase',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.DASHBOARD;
      }

      const url = new URL(actionUrl).pathname;
      if (window.location.pathname === url) {
        window.location.reload();
      }

      return url;
    },
    getMessage: (
      t: TFunction,
      language: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const requestName = getLocalizedDescription(
        customAttributes.jobSpecialty as Descriptions,
        language
      );

      return t(
        'notifications.messages.recruitmentNegotiationRoomMessageReceived',
        { accountName: customAttributes.senderAccountName, requestName }
      );
    },
  },
  candidate_negotiation_room_opened: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationRoomOpened', {
        candidateName,
      });
    },
  },
  candidate_negotiation_room_updated: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationRoomUpdated', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_negotiation_room_rejected: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationRoomRejected', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_negotiation_completed: {
    titleKey: 'notifications.category.negotiationRoom',
    icon: 'shake-hands',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return '#';
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (
      t: TFunction,
      _: string,
      { customAttributes }: Notification
    ) => {
      if (typeof customAttributes === 'string' || !customAttributes) {
        return '';
      }

      const { accountName } = customAttributes;
      const candidateName = `${customAttributes.firstName} ${customAttributes.lastName}`;

      return t('notifications.messages.candidateNegotiationCompleted', {
        accountName,
        candidateName,
      });
    },
  },
  candidate_request_matched: {
    titleKey: 'notifications.category.candidateRequest',
    icon: 'suitcase',
    getActionUrl: ({ actionUrl }) => {
      if (!actionUrl) {
        return RootPrivatePage.CANDIDATE_OPPORTUNITIES;
      }

      return new URL(actionUrl).pathname;
    },
    getMessage: (t: TFunction) =>
      t('notifications.messages.candidateRequestMatched'),
  },
};
