import { useTranslation } from 'react-i18next';

import { SectionGridRow, VisibilitySwitcher } from '@application/components';
import { PlaceholderSwitcher, Switch } from '@application/components/switch';
import {
  CandidateOffer,
  CandidateOpportunity,
  CandidateRequest,
  OfferConditionPaymentMethodCode,
  OfferConditionStartOfAgreementPaymentMethodCode,
  RequestConditionPaymentMethodCode,
  RequestConditionStartOfAgreementPaymentMethodCode,
} from '@domain/graphql.types';

type RecruitmentConditionsProps = {
  offer: CandidateOffer | undefined;
  request: CandidateRequest | CandidateOpportunity | undefined;
};

const RecruitmentConditions = ({
  offer,
  request,
}: RecruitmentConditionsProps) => {
  const { t } = useTranslation('candidates');

  const requestChildrenClassName = 'text-16 font-semibold text-primary';
  const offerWrapperClassName = 'flex flex-wrap gap-x-s-16 items-center';
  const offerLabelClassName = 'text-16 text-primary';
  const offerValueClassName = 'text-18 font-semibold text-primary';

  const conditions = offer?.recruitmentConditions;
  const recruitmentConditions = request?.recruitmentConditions;

  return (
    <>
      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <VisibilitySwitcher
            content={<span className={requestChildrenClassName}>-</span>}
            visible
          />
        }
      >
        <span className={offerWrapperClassName}>
          <span className={offerLabelClassName}>
            {t('labels.recruitmentConditionBudget')}
          </span>

          <PlaceholderSwitcher value={conditions?.budget}>
            <span className={offerValueClassName}>
              {t('suffix.currency', { value: conditions?.budget })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={recruitmentConditions?.paymentMethodValue}
              >
                <div className="font-semibold text-primary">
                  <p>
                    {t(
                      `enum.requestConditionPaymentMethodCode.${recruitmentConditions?.paymentMethodValue?.toLowerCase()}`
                    )}
                  </p>

                  {recruitmentConditions?.paymentMethodValue ===
                    RequestConditionPaymentMethodCode.StartOfAgreement && (
                    <>
                      <p className="mt-s-10">
                        {t(
                          `enum.requestConditionStartOfAgreementPaymentMethodCode.${recruitmentConditions?.startOfAgreementPaymentMethodValue?.toLowerCase()}`
                        )}
                      </p>

                      <Switch
                        fallback={
                          recruitmentConditions?.paymentAmountValue && (
                            <p className="mt-s-10">
                              {t('suffix.currency', {
                                value: recruitmentConditions.paymentAmountValue,
                              })}
                            </p>
                          )
                        }
                      >
                        <Switch.Match
                          when={
                            recruitmentConditions?.startOfAgreementPaymentMethodValue ===
                            RequestConditionStartOfAgreementPaymentMethodCode.PaymentFrequency
                          }
                        >
                          {recruitmentConditions?.paymentFrequencyValue && (
                            <p className="mt-s-10">
                              {t(
                                `enum.requestConditionPaymentFrequencyCode.${recruitmentConditions?.paymentFrequencyValue?.toLowerCase()}`
                              )}
                            </p>
                          )}
                        </Switch.Match>
                      </Switch>
                    </>
                  )}
                </div>
              </PlaceholderSwitcher>
            }
            visible={!!recruitmentConditions?.paymentMethodDisplay}
          />
        }
      >
        <div className="flex flex-row flex-wrap items-center">
          <span className="mr-s-16">{t('labels.preferredPaymentMethod')}</span>

          <PlaceholderSwitcher value={conditions?.paymentMethod}>
            <div className="[&>*]:text-18 font-semibold text-primary">
              <p>
                {t(
                  `enum.offerConditionPaymentMethodCode.${conditions?.paymentMethod?.toLowerCase()}`
                )}
              </p>

              {conditions?.paymentMethod ===
                OfferConditionPaymentMethodCode.StartOfAgreement && (
                <p>
                  {t(
                    `enum.offerConditionStartOfAgreementPaymentMethodCode.${conditions?.startOfAgreementPaymentMethod?.toLowerCase()}`
                  )}

                  <Switch
                    fallback={
                      conditions.paymentAmount &&
                      t('suffix.ofAmount', {
                        amount: conditions.paymentAmount,
                      })
                    }
                  >
                    <Switch.Match
                      when={
                        conditions.startOfAgreementPaymentMethod ===
                        OfferConditionStartOfAgreementPaymentMethodCode.PaymentFrequency
                      }
                    >
                      {conditions.paymentFrequency && (
                        <span className="ml-s-16">
                          {t(
                            `enum.offerConditionPaymentFrequencyCode.${conditions.paymentFrequency?.toLowerCase()}`
                          )}
                        </span>
                      )}
                    </Switch.Match>
                  </Switch>
                </p>
              )}
            </div>
          </PlaceholderSwitcher>
        </div>
      </SectionGridRow>
      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={recruitmentConditions?.guaranteedPeriodValue}
              >
                <span className={requestChildrenClassName}>
                  {t('suffix.month', {
                    count: Number(recruitmentConditions?.guaranteedPeriodValue),
                  })}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!recruitmentConditions?.guaranteedPeriodDisplay}
          />
        }
      >
        <span className={offerWrapperClassName}>
          <span className={offerLabelClassName}>
            {t('labels.guaranteedPeriod')}:
          </span>

          <PlaceholderSwitcher value={conditions?.guaranteedPeriod}>
            <span className={offerValueClassName}>
              {t('suffix.month', {
                count: Number(conditions?.guaranteedPeriod),
              })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>
    </>
  );
};

export default RecruitmentConditions;
