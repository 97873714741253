import React, { ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@application/components/avatar/Avatar';
import { Account, User } from '@domain/graphql.types';

type UserNavButtonProps = {
  isUserNavOpen: boolean;
  onClick: () => void;
  user: User;
  account?: Account;
  avatarUrl?: string | null;
};

type Ref = HTMLButtonElement;

const UserNavButton = React.forwardRef<Ref, UserNavButtonProps>(
  (
    { isUserNavOpen, onClick, user, account, avatarUrl }: UserNavButtonProps,
    ref: ForwardedRef<Ref>
  ) => {
    const { t } = useTranslation();

    return (
      <button
        ref={ref}
        type="button"
        className="btn btn-ghost normal-case flex flex-nowrap items-center gap-x-s-12 px-s-8 hover:bg-secondary active:bg-secondary focus:bg-secondary"
        onClick={onClick}
      >
        <span className="sr-only">
          {t(isUserNavOpen ? 'userNav.closeButton' : 'userNav.openButton')}
        </span>

        <Avatar name={user.name || user.email} url={avatarUrl} size="small" />

        <span className="text-left hidden lg:block">
          <span className="block text-16 font-semibold min-w-[7rem] max-w-[20rem] text-ellipsis overflow-hidden whitespace-nowrap">
            {user.name}
          </span>
          {account?.name && (
            <span className="block font-normal max-w-[20rem] text-ellipsis overflow-hidden whitespace-nowrap">
              {account.name}
            </span>
          )}
        </span>
      </button>
    );
  }
);

export default UserNavButton;
