import * as yup from 'yup';
import 'yup-phone-lite';

import { AccountIndustryType } from '../../../../domain/graphql.types';

export const OPERATION_UNIT_SCHEMA = yup.object({
  name: yup.string().required('forms.rules.required'),
  address: yup.string().nullable(),
  phoneNumber: yup.string().phone('CA', 'forms.rules.phoneValid').nullable(),
  phoneDisplay: yup.boolean().nullable(),
  headOffice: yup.boolean().nullable(),
  sectorCode: yup.string().nullable(),
  industryCode: yup
    .mixed<AccountIndustryType>()
    .oneOf(Object.values(AccountIndustryType))
    .nullable(),
  notes: yup.string().nullable(),
});
