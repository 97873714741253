import { AccountOperationTerritoryListPage } from '../account-operation-territory';
import { OperationUnitListPage } from '../operation-unit';

const AccountManagementPanel = () => (
  <div aria-labelledby="tab-account-management" role="tabpanel">
    <OperationUnitListPage />

    <hr className="my-s-40" />

    <AccountOperationTerritoryListPage />
  </div>
);

export default AccountManagementPanel;
