import { useQuery } from 'urql';

import { Query } from '@domain/graphql.types';
import { EducationLevels } from '@infrastructure/graphql/education';

const useEducationLevelsGet = () => {
  const [result] = useQuery<Pick<Query, 'educationLevels'>, void>({
    query: EducationLevels,
    requestPolicy: 'cache-first',
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
  };
};

export default useEducationLevelsGet;
