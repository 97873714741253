import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { cn } from '@utils/lib-utils';

import { NavigationTab, SingleTab } from './types';

const renderTabLink = (tab: SingleTab, last: boolean, selected: boolean) => (
  <li
    key={tab.path}
    className={cn({
      'mr-s-32': !last,
    })}
  >
    <Link
      aria-controls={tab.label}
      aria-selected={selected}
      role="tab"
      id={tab.id}
      to={tab.path}
      className={cn(
        'text-16 tab px-s-0 h-[3.063rem] pb-s-1 text-neutral-secondary whitespace-nowrap min-w-full w-full hover:text-primary',
        {
          'border-b border-primary pb-s-0 text-primary': selected,
        }
      )}
    >
      {tab.element}
    </Link>
  </li>
);

export type NavigationTabLinkProps = {
  last: boolean;
  tab: NavigationTab;
};

const NavigationTabLink = ({ last, tab }: NavigationTabLinkProps) => {
  const { pathname } = useLocation();

  if ('path' in tab) {
    return renderTabLink(tab, last, pathname === tab.path);
  }

  if ('children' in tab && tab.children.length > 0) {
    const selected = tab.children.some((c) => c.path === pathname);

    return renderTabLink(
      { ...tab, path: tab.children[0].path },
      last,
      selected
    );
  }

  return null;
};

export default NavigationTabLink;
