import {
  CandidateNegotiationRoom,
  CandidateOffer,
  OperationTerritoryCode,
} from '@domain/graphql.types';
import { parseNumber } from '@utils/data-utils';

import {
  CandidateNegotiationRoomFormFields,
  RemunerationFormFields,
} from './types';

export const normalizeCandidateNegotiationRoomEditData = (
  data: CandidateNegotiationRoom
): CandidateNegotiationRoomFormFields => ({
  operationTerritoryCodes: data.negotiatingCandidateOffer
    .operationTerritoryCodes as OperationTerritoryCode[],
  jobType: data.negotiatingCandidateOffer.jobType,
  jobDurationInMonths: data.negotiatingCandidateOffer.jobDurationInMonths,
  hoursPerWeek: (data.negotiatingCandidateOffer.hoursPerWeek as string[]) || [],
  jobModeCode: data.negotiatingCandidateOffer.jobModeCode,
  otherPaymentTypeCode: data.negotiatingCandidateOffer.otherPaymentTypeCode,
  paymentFrequencyCode: data.negotiatingCandidateOffer.paymentFrequencyCode,
  annualSalaryMin: data.negotiatingCandidateOffer.annualSalaryMin,
  annualSalaryMax: data.negotiatingCandidateOffer.annualSalaryMax,
  hourlyRateMin: parseNumber(data.negotiatingCandidateOffer.hourlyRateMin),
  hourlyRateMax: parseNumber(data.negotiatingCandidateOffer.hourlyRateMax),
  hourlyBonus: parseNumber(data.negotiatingCandidateOffer.hourlyBonus),
  remunerationAmount: parseNumber(
    data.negotiatingCandidateOffer.remunerationAmount
  ),

  /* RECRUITMENT CONDITIONS */
  recruitmentConditionBudget: parseNumber(
    data.negotiatingCandidateOffer.recruitmentConditions?.budget
  ),
  recruitmentConditionPaymentMethod:
    data.negotiatingCandidateOffer.recruitmentConditions?.paymentMethod,
  recruitmentConditionGuaranteedPeriod:
    data.negotiatingCandidateOffer.recruitmentConditions?.guaranteedPeriod,
  recruitmentConditionBonus: parseNumber(
    data.negotiatingCandidateOffer.recruitmentConditions?.bonus
  ),

  /* TEMPORARY_PLACEMENT_CONDITIONS */
  temporaryPlacementConditionMinimumNotice:
    data.negotiatingCandidateOffer.temporaryPlacementConditions?.minimumNotice,
  temporaryPlacementConditionPossibleBuybackBonus: parseNumber(
    data.negotiatingCandidateOffer.temporaryPlacementConditions
      ?.possibleBuybackBonus
  ),
  temporaryPlacementConditionBillingPeriod:
    data.negotiatingCandidateOffer.temporaryPlacementConditions?.billingPeriod,

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMinimumNotice:
    data.negotiatingCandidateOffer.outsourcingConditions?.minimumNotice,
  outsourcingConditionBillingPeriod:
    data.negotiatingCandidateOffer.outsourcingConditions?.billingPeriod,
});

export const normalizeRemunerationInputData = (
  data: CandidateOffer
): RemunerationFormFields => ({
  paymentFrequencyCode: data.paymentFrequencyCode,
  annualSalaryMin: data.annualSalaryMin,
  annualSalaryMax: data.annualSalaryMax,
  hourlyRateMin: parseNumber(data.hourlyRateMin),
  hourlyRateMax: parseNumber(data.hourlyRateMax),
  hourlyBonus: parseNumber(data.hourlyBonus),
  remunerationAmount: parseNumber(data.remunerationAmount),
});

export const normalizeCandidateNegotiationRoomInputData = (
  data: CandidateNegotiationRoomFormFields
) => ({
  ...data,
  hourlyRateMin: data.hourlyRateMin?.toFixed(2).toString() || null,
  hourlyRateMax: data.hourlyRateMax?.toFixed(2).toString() || null,
  hourlyBonus: data.hourlyBonus?.toFixed(2).toString() || null,
  remunerationAmount: data.remunerationAmount?.toFixed(2).toString() || null,

  /* RECRUITMENT CONDITIONS */
  recruitmentConditionBudget:
    data.recruitmentConditionBudget?.toFixed(2).toString() || null,
  recruitmentConditionBonus:
    data.recruitmentConditionBonus?.toFixed(2).toString() || null,

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuybackBonus:
    data.temporaryPlacementConditionPossibleBuybackBonus
      ?.toFixed(2)
      .toString() || null,

  fieldInModification: undefined,
});
