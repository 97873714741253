import { useQuery } from 'urql';

import { Query, QueryCandidateRequestsArgs } from '@domain/graphql.types';
import { CandidateRequests } from '@infrastructure/graphql/candidate';

const useCandidateRequests = (input?: QueryCandidateRequestsArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateRequests'>,
    QueryCandidateRequestsArgs
  >({
    query: CandidateRequests,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateRequests;
