import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Query } from '@domain/graphql.types';

type ToViewModel = {
  candidateRequests: {
    data: Pick<Query, 'candidateRequests'> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateRequests: { data, fetching, error },
}: ToViewModel): ViewModel<Pick<Query, 'candidateRequests'> | undefined> =>
  new ViewModel<Pick<Query, 'candidateRequests'> | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
