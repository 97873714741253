import { useQuery } from 'urql';

import { Query } from '@domain/graphql.types';
import { UserGetNotificationPreferences } from '@infrastructure/graphql/user';

const useUserGetNotificationPreferences = () => {
  const [result] = useQuery<Pick<Query, 'me'>, void>({
    query: UserGetNotificationPreferences,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.me.user.notificationPreferences,
    fetching,
    error,
  };
};

export default useUserGetNotificationPreferences;
