import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useCandidateRequestSaveAndPublish } from '@domain/candidate';

import { toViewModel } from '../view-models/save-and-publish-candidate-request.vm';

const useSaveAndPublishCandidateRequest = () => {
  const { data, fetching, error, saveAndPublish } =
    useCandidateRequestSaveAndPublish();

  const { t } = useTranslation('candidates', {
    keyPrefix: 'messages',
  });

  const navigate = useNavigate();
  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    candidateRequest: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('saveAndPublishError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateRequestUpdate?.candidateRequest) {
      setAlertContent({
        action: t('saveAndPublishSuccess'),
        modifier: 'alert-success',
      });

      navigate(
        generatePath(PrivatePage.CANDIDATE_REQUEST_DETAILS, {
          id: data.candidateRequestUpdate.candidateRequest.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateRequestUpdate?.candidateRequest]);

  return { saveAndPublish, viewModel };
};

export default useSaveAndPublishCandidateRequest;
