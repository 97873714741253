import { useRequest } from '@domain/request';

import { toViewModel } from '../view-models/get-request.vm';

const useGetRequest = (requestId: string) => {
  const { data, fetching, error, reexecuteQuery } = useRequest({
    requestGetInput: { id: requestId },
  });

  const viewModel = toViewModel({
    request: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRequest: reexecuteQuery,
  };
};

export default useGetRequest;
