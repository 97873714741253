import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { UpdateAccountUserRoleResponse } from '@domain/graphql.types';

type ToViewModel = {
  accountUserUpdateRole: {
    data: UpdateAccountUserRoleResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  accountUserUpdateRole: { data, fetching, error },
}: ToViewModel): ViewModel<UpdateAccountUserRoleResponse | undefined> =>
  new ViewModel<UpdateAccountUserRoleResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
