import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import { UseQueryExecute } from 'urql';

import {
  Button,
  ButtonLink,
  ConfirmationModal,
  Stack,
  Tooltip,
} from '@application/components';
import { ModalContext, NavContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { CandidateOffer, CandidateRequest } from '@domain/graphql.types';
import { formatDeadline, isOverDeadline } from '@utils/date-utils';
import { cn } from '@utils/lib-utils';

import { useAcceptCandidateOffer, useRejectCandidateOffers } from '../hooks';

type ActionMenuProps = {
  checkedOffers: string[];
  comparing?: boolean;
  candidateRequest: CandidateRequest;
  offers?: readonly CandidateOffer[];
  onInviteClicked?: () => void;
  onComparisonClicked: () => void;
  onDeselectAll: () => void;
  refreshCandidateOffers: UseQueryExecute;
  acceptedOffer?: CandidateOffer;
};

const ActionMenu = ({
  acceptedOffer,
  checkedOffers,
  comparing,
  candidateRequest,
  offers,
  onInviteClicked,
  onComparisonClicked,
  onDeselectAll,
  refreshCandidateOffers,
}: ActionMenuProps) => {
  const { t, i18n } = useTranslation('candidates');

  const { setModal } = useContext(ModalContext);
  const { isNavOpen, setIsBottomMenuOpen } = useContext(NavContext);

  const location = useLocation();

  const { acceptCandidateOffer } = useAcceptCandidateOffer();
  const { rejectCandidateOffers } = useRejectCandidateOffers();

  const getOfferDetailsPath = useCallback(
    (offerId: string) =>
      offerId
        ? generatePath(PrivatePage.CANDIDATE_OFFER_DETAILS, {
            id: candidateRequest?.id || null,
            offerId,
          })
        : '#',
    [candidateRequest?.id]
  );

  const getAccountNameList = useCallback(
    (ids: string[]) => {
      const filteredOffers = offers
        ?.filter((o) => ids.includes(o.id))
        .map((o) => o.account.name);

      return filteredOffers?.join('\n') || [];
    },
    [offers]
  );

  const negotiationId = useMemo(
    () => candidateRequest?.candidateNegotiation?.id,
    [candidateRequest?.candidateNegotiation?.id]
  );

  const isOverReceivingOfferDeadline = useMemo(
    () =>
      isOverDeadline(
        candidateRequest?.receivingOfferDeadline,
        DateTime.now().toISO()
      ),
    [candidateRequest?.receivingOfferDeadline]
  );

  const formattedReceivingOfferDeadline = useMemo(
    () =>
      formatDeadline(candidateRequest?.receivingOfferDeadline, i18n.language),
    [i18n.language, candidateRequest?.receivingOfferDeadline]
  );

  // TODO: remove when negotiation is done
  const handleAcceptOffer = useCallback(
    (candidateOfferId: string) => async () => {
      setModal(null);

      const result = await acceptCandidateOffer({
        input: { candidateOfferId },
      });

      // eslint-disable-next-line deprecation/deprecation
      if (result.data?.candidateOfferAccept.candidateOffer) {
        onDeselectAll();
        refreshCandidateOffers();
      }
    },
    [setModal, acceptCandidateOffer, onDeselectAll, refreshCandidateOffers]
  );

  // TODO: remove when negotiation is done
  const confirmAcceptOffer = useCallback(
    (offerId: string) => async () => {
      setModal({
        title: t('modal.titles.acceptOffer'),
        content: (
          <ConfirmationModal
            content={t('modal.contents.acceptOffer')}
            onCancel={() => setModal(null)}
            onConfirm={handleAcceptOffer(offerId)}
          />
        ),
      });
    },
    [setModal, t, handleAcceptOffer]
  );

  const handleRejectOffers = useCallback(
    (ids: string[]) => async () => {
      setModal(null);

      const result = await rejectCandidateOffers({ input: { ids } });

      if (result.data?.candidateOffersReject.candidateOffers) {
        onDeselectAll();
        refreshCandidateOffers();
      }
    },
    [onDeselectAll, rejectCandidateOffers, refreshCandidateOffers, setModal]
  );

  const confirmOffersRejection = useCallback(
    (ids: string[]) => async () => {
      setModal({
        title: t('modal.titles.rejectOffers', { count: checkedOffers.length }),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p className="whitespace-pre-line">
                  <Trans
                    i18nKey="modal.contents.rejectOffers"
                    t={t}
                    values={{
                      count: checkedOffers.length,
                      agencies: getAccountNameList(ids),
                    }}
                  />
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={handleRejectOffers(ids)}
          />
        ),
      });
    },
    [setModal, t, checkedOffers.length, getAccountNameList, handleRejectOffers]
  );

  const paddingLeft = useMemo(
    () => (isNavOpen ? 'lg:pl-[22rem]' : 'lg:pl-[9rem]'),
    [isNavOpen]
  );

  useEffect(() => {
    setIsBottomMenuOpen(checkedOffers.length >= 1);
  }, [checkedOffers, setIsBottomMenuOpen]);

  return (
    <div
      className={cn(
        'fixed bottom-s-0 left-s-0 bg-base-100 shadow-lg px-s-32 py-s-16 w-full min-h-[4.5rem] hidden',
        {
          block: checkedOffers.length >= 1 || acceptedOffer,
        },
        paddingLeft
      )}
    >
      {acceptedOffer ? (
        <div className="flex gap-s-16 justify-end">
          <ButtonLink
            size="small"
            className="py-s-0"
            icon={<i className="ri-file-text-line text-16 font-normal" />}
            to={getOfferDetailsPath(acceptedOffer.id)}
          >
            {t('button.offerDetails')}
          </ButtonLink>
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex items-center gap-s-16">
            <span>
              {t('labels.offersCount', { count: checkedOffers.length })}
            </span>
            {!comparing && (
              <Button size="small" ghost onClick={onDeselectAll}>
                {t('button.deselectOffer', { count: checkedOffers.length })}
              </Button>
            )}
          </div>

          <div className="flex flex-col lg:flex-row gap-s-16">
            {/* TODO: remove feature flag when negotiation is done */}
            {import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION === 'true' && (
              <>
                {/* IF there is no ongoing negotiation yet -> Show the invite button */}
                <Tooltip
                  message={
                    isOverReceivingOfferDeadline
                      ? undefined
                      : t('messages.invitationNotAvailable', {
                          date: formattedReceivingOfferDeadline,
                        })
                  }
                >
                  <Button
                    size="small"
                    primary
                    icon={
                      <i className="ri-shake-hands-line mr-s-4 text-16 font-normal" />
                    }
                    className={!negotiationId ? 'py-s-0' : 'hidden'}
                    disabled={!isOverReceivingOfferDeadline}
                    onClick={onInviteClicked}
                  >
                    {t('button.inviteToNegoRoom')}
                  </Button>
                </Tooltip>
              </>
            )}

            {/* TODO: remove feature flag when negotiation is done */}
            {import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION === 'false' && (
              <>
                {/* IF only one offer is selected -> Show the create agreement button */}
                <Button
                  size="small"
                  primary
                  className={checkedOffers.length === 1 ? 'py-s-0' : 'hidden'}
                  onClick={confirmAcceptOffer(checkedOffers[0])}
                >
                  <i className="ri-shake-hands-line mr-s-4 text-16 font-normal" />
                  {t('button.createAgreement')}
                </Button>
              </>
            )}

            {/* IF we only have one offer checked AND there's an ongoing negotiation -> Show the Go to the NegoRoom button */}
            <ButtonLink
              size="small"
              primary
              className={negotiationId ? 'py-s-0 flex items-center' : 'hidden'}
              icon={
                <i className="ri-shake-hands-line mr-s-4 text-16 font-normal" />
              }
              to={generatePath(PrivatePage.CANDIDATE_NEGOTIATION_DETAILS, {
                id: negotiationId || '#',
              })}
              from={location.pathname}
            >
              {t('button.goToNegoRoom')}
            </ButtonLink>

            {/* IF we only have ONE offer checked -> Show the offer details button */}
            <ButtonLink
              size="small"
              icon={<i className="ri-file-text-line text-16 font-normal" />}
              className={
                checkedOffers.length === 1
                  ? 'flex items-center py-s-0'
                  : 'hidden'
              }
              to={getOfferDetailsPath(checkedOffers[0])}
            >
              {t('button.offerDetails')}
            </ButtonLink>

            {/* IF there is only one offer checked AND the offer has no nego room -> Show the decline offer button */}
            <Button
              size="small"
              icon={<i className="ri-prohibited-line text-16 font-normal" />}
              className={
                // TODO: remove feature flag when negotiation is done
                !import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION ||
                (checkedOffers.length === 1 &&
                  offers?.some(
                    (o) => checkedOffers.includes(o.id) && !o.negotiationRoomId
                  ))
                  ? 'py-s-0'
                  : 'hidden'
              }
              onClick={confirmOffersRejection(checkedOffers)}
            >
              {t('button.declineOffers')}
            </Button>

            {/* IF there's more than one offer checked -> Show the comparison button */}
            {/* IF we are currently comparing offers -> Change the text to cancel selection */}
            <Button
              className={cn(checkedOffers.length === 1 && 'hidden')}
              size="small"
              onClick={onComparisonClicked}
            >
              {t(
                `${comparing ? 'button.cancelSelection' : 'button.compareOffers'}`
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
