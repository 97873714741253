import { gql } from '@urql/core';

const MutationCandidateNegotiationRoomAcceptValues = gql`
  mutation CandidateNegotiationRoomAcceptValues(
    $input: CandidateNegotiationStateUpdateInput!
  ) {
    candidateNegotiationRoomAcceptValues(input: $input) {
      room {
        id
      }
    }
  }
`;

export default MutationCandidateNegotiationRoomAcceptValues;
