import { AccountTypeCode } from '@domain/graphql.types';

/**
 * This will decide which translation prefix key to chose,
 * according to the type (agency/enterprise)
 */
export const TRANSLATION_PREFIX_KEYS = {
  [AccountTypeCode.Enterprise]: 'enterprise',
  [AccountTypeCode.Agency]: 'agency',
} as const;
