import { gql } from '@urql/core';

const OfferAccountName = gql`
  query OfferAccountName($input: GetOfferInput!) {
    offer(input: $input) {
      offer {
        account {
          name
        }
      }
    }
  }
`;

export default OfferAccountName;
