import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  Fieldset,
  FormInputWithSuffix,
  Radio,
  SectionGrid,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import {
  CandidateConditionBillingPeriod,
  CandidateOpportunity,
} from '@domain/graphql.types';

import { CandidateOfferFormFields } from '../../schema';

type OutsourcingFieldsProps = {
  candidateOpportunity?: CandidateOpportunity;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  register: UseFormRegister<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  trigger: UseFormTrigger<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const OutsourcingFields = ({
  candidateOpportunity,
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: OutsourcingFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const outsourcingConditionBillingPeriod = watch(
    'outsourcingConditionBillingPeriod'
  );

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateOfferFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <SectionGrid title={t('subtitles.offerAgency')} isOffer>
      <SectionGridRow
        isTopRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.outsourcingConditions
                ?.minimumNoticeValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.week', {
                    count:
                      candidateOpportunity.outsourcingConditions
                        .minimumNoticeValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.outsourcingConditions
                ?.minimumNoticeDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          type="number"
          minValue={1}
          label={t('labels.minimumNoticePeriod')}
          suffix={t('suffix.weeks')}
          noMargin
          mediumSuffix
          invalid={!!errors.outsourcingConditionMinimumNotice}
          helperText={
            errors.outsourcingConditionMinimumNotice?.message &&
            tGlobal(errors.outsourcingConditionMinimumNotice?.message, {
              min: '1',
            })
          }
          {...register('outsourcingConditionMinimumNotice')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.outsourcingConditions
                ?.billingPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t(
                    `enum.candidateConditionBillingPeriod.${candidateOpportunity.outsourcingConditions.billingPeriodValue.toLowerCase()}`
                  )}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.outsourcingConditions
                ?.billingPeriodDisplay
            }
          />
        }
      >
        <Fieldset legend={t('labels.billingPeriod')} legendSize="medium">
          <Controller
            name="outsourcingConditionBillingPeriod"
            control={control}
            render={({ field: { name } }) => (
              <Cluster space={16} as="ul" className="mt-s-16">
                {Object.values(CandidateConditionBillingPeriod).map(
                  (method) => (
                    <li key={method}>
                      <Radio
                        name={name}
                        value={method}
                        label={t(
                          `enum.candidateConditionBillingPeriod.${method.toLowerCase()}`
                        )}
                        onChange={(e) => {
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          );
                          trigger(name);
                        }}
                        checked={outsourcingConditionBillingPeriod === method}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            )}
          />
        </Fieldset>
      </SectionGridRow>
    </SectionGrid>
  );
};

export default OutsourcingFields;
