import React, { ForwardedRef, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import HelperText from '../HelperText';
import Label from '../Label';
import { TextArea } from './textArea';

type TextAreaFieldProps = {
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  label?: ReactNode;
  /**
   * Provide text that is used alongside the `<textarea>` for additional help
   * or as a validation message on textarea error
   */
  helperText?: string;
  /**
   * Specify a custom `id` for the `<textarea>`
   */
  id?: string;
  /**
   * Specify the name of the `<textarea>`
   */
  name: string;
  /**
   * Specify the value of the `<textarea>`
   */
  value?: string | number | undefined;
  /**
   * Specify the placeholder attribute for the `<textarea>`
   */
  placeholder?: string;
  /**
   * Specify the height of the `<textarea>` (in lines)
   */
  rows?: number;
  /**
   * Specify the visible width of the `<textarea>`
   */
  cols?: number;
  /**
   * specifies how the text in the `<textarea>` is to be wrapped when submitted in a form.
   */
  wrap?: 'soft' | 'hard';
  /**
   *  Specify the minimum of character allowed for the `<textarea>`
   */
  minChar?: number;
  /**
   *  Specify the maximum of character allowed for the `<textarea>`
   */
  maxChar?: number;
  /**
   * Specify whether the `<textarea>` should be disabled
   */
  disabled?: boolean;
  /**
   * Specify whether the `<textarea>` should be invalid
   */
  invalid?: boolean;
  /**
   * Specify whether the `<label>` should be visually hidden
   */
  hideLabel?: boolean;
  /**
   * Provide a custom className to be applied to the `<textarea>`
   */
  className?: string;
  /**
   * Provide a custom className to be applied to the label of the `<textarea>`
   */
  labelClassName?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever `<textarea>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * Optionally provide an `onBlur` handler that is called whenever `<textarea>` loses focus
   */
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  labelSize?: 'small' | 'medium';
};

type Ref = HTMLDivElement;

const TextAreaField = React.forwardRef<Ref, TextAreaFieldProps>(
  (
    {
      label,
      id,
      name,
      value,
      placeholder,
      rows,
      cols,
      wrap,
      minChar,
      maxChar,
      disabled,
      invalid,
      hideLabel,
      helperText,
      className: customClassName,
      labelClassName: customLabelClassName,
      onChange,
      onBlur,
      labelSize = 'small',
      ...rest
    },
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    const textareaId = useMemo(() => id || randomString(), [id]);
    const helperTextId = useMemo(() => randomString(), []);

    const { t } = useTranslation();

    const defaultClassName = 'relative';
    const className = cn(defaultClassName, customClassName);
    const textLength = value?.toString().length;

    return (
      <div ref={ref} className={className} {...rest}>
        <Label
          htmlFor={textareaId}
          disabled={disabled}
          invalid={invalid}
          className={cn(
            {
              'sr-only': hideLabel,
              'text-16': labelSize === 'medium',
            },
            customLabelClassName
          )}
        >
          {label}
        </Label>

        <TextArea
          id={textareaId}
          aria-describedby={helperText ? helperTextId : undefined}
          name={name}
          value={value}
          placeholder={placeholder || t('forms.placeholders.writeHere')}
          rows={rows}
          cols={cols}
          wrap={wrap}
          minChar={minChar}
          maxLength={maxChar}
          disabled={disabled}
          invalid={invalid}
          onChange={onChange}
          onBlur={onBlur}
        />

        {maxChar && (
          <div className="flex justify-end text-12 text-neutral-secondary">
            {textLength}/{maxChar}
          </div>
        )}

        {helperText && (
          <HelperText id={helperTextId} invalid={invalid}>
            {helperText}
          </HelperText>
        )}
      </div>
    );
  }
);

export default TextAreaField;
