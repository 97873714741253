import {
  CandidateNegotiationRoom,
  CandidateNegotiationRoomsFilters,
} from '@domain/graphql.types';

import useGetCandidateNegotiationRooms from './useGetCandidateNegotiationRooms';

type UseCandidateNegotiationRoomsListProps = {
  count?: number;
  candidateNegotiationRooms: CandidateNegotiationRoom[];
  isLoading: boolean;
};

const useCandidateNegotiationRoomsList = (
  filters?: CandidateNegotiationRoomsFilters
): UseCandidateNegotiationRoomsListProps => {
  const {
    viewModel: { data, isLoading },
  } = useGetCandidateNegotiationRooms(filters);

  return {
    count: data?.candidateNegotiationRooms.totalCount,
    candidateNegotiationRooms:
      data?.candidateNegotiationRooms.results.slice() || [],
    isLoading,
  };
};

export default useCandidateNegotiationRoomsList;
