import { useEffect, useState } from 'react';

/**
 * Load an external script on the fly
 *
 * @typedef  {Object} Output
 * @property {boolean} loaded — Whether the external script is loaded or not
 *
 * @param  {string} src — Source url of an external script file
 * @param  {string} id — id attribute to apply to the script tag
 * @return {Output}
 */
const useExternalScript = (src: string, id: string) => {
  const [loaded, setLoaded] = useState(false);

  /**
   * IMPORTANT: Make sure to be run only once at initial render
   */
  useEffect(() => {
    if (typeof window === 'undefined' || loaded) {
      return undefined;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    script.addEventListener('load', () => setLoaded(true));

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loaded,
  };
};

export default useExternalScript;
