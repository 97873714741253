import { useMutation } from 'urql';

import { Mutation, MutationInvitationResendArgs } from '@domain/graphql.types';
import { InvitationResend } from '@infrastructure/graphql/account-user';

const useInvitationResend = () => {
  const [{ data, fetching, error }, resendInvitation] = useMutation<
    Pick<Mutation, 'invitationResend'>,
    MutationInvitationResendArgs
  >(InvitationResend);

  return {
    resendInvitation,
    data: data?.invitationResend,
    fetching,
    error,
  };
};

export default useInvitationResend;
