import { gql } from 'urql';

const RecruitmentNegotiationGeneralPropertiesStateFragment = gql`
  fragment RecruitmentNegotiationGeneralPropertiesStateFragment on RecruitmentNegotiationGeneralPropertiesState {
    bonus
    deposit
    maxRecruitmentPeriod
    minGuaranteedPeriod
    paymentAmount
    paymentFrequency
    paymentMethod
    probationPeriod
    startOfAgreementPaymentMethod
    totalCosts
  }
`;

export default RecruitmentNegotiationGeneralPropertiesStateFragment;
