import { useParams } from 'react-router';

import Banner from '@application/assets/banner.svg?react';
import { Avatar } from '@application/components/avatar';
import { AccountTypeCode } from '@domain/graphql.types';

import { useValidateIdParam } from '../../hooks';
import { AgencyInformation, EnterpriseInformation } from './components';
import { useGetPublicAccount } from './public-profile/hooks';

const OrganizationPublicProfilePage = () => {
  const { id = '' } = useParams();

  useValidateIdParam(id);

  const {
    viewModel: { data: account },
  } = useGetPublicAccount(id);

  return (
    <>
      <Banner className="w-[calc(100%+64px)] -mt-s-32 -ml-s-32" />

      <div className="grid md:grid-cols-[320px_1fr] gap-x-s-32 gap-y-s-8 mt-s-32">
        <Avatar
          className="border -mt-s-80"
          size="large"
          name={account?.name}
          url={account?.logoUrl}
        />

        <div className="h-s-56" />

        {account?.type === AccountTypeCode.Agency && (
          <AgencyInformation account={account} />
        )}

        {account?.type === AccountTypeCode.Enterprise && (
          <EnterpriseInformation account={account} />
        )}
      </div>
    </>
  );
};

export default OrganizationPublicProfilePage;
