import { gql } from 'urql';

const RequestGeneralConditionsFragment = gql`
  fragment RequestGeneralConditionsFragment on RequestGeneralConditions {
    maxRecruitmentPeriodValue
    maxRecruitmentPeriodDisplay
    candidateInHandMandatoryValue
    candidateInHandMandatoryDisplay
    hiredDateBonusDisplay
    hiredDateBonusRequirement
    hiredDateBonusValue
    minGuaranteedPeriodBonusValue
    minGuaranteedPeriodDisplay
    minGuaranteedPeriodValue
    probationPeriodValue
    probationPeriodDisplay
    paymentMethodValue
    startOfAgreementPaymentMethodValue
    paymentAmountValue
    depositDisplay
    depositValue
    paymentFrequencyValue
    paymentMethodDisplay
    minimumBudgetValue
    maximumBudgetValue
    budgetDisplay
    bonusValue
    bonusMonthValue
    bonusDisplay
  }
`;

export default RequestGeneralConditionsFragment;
