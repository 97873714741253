import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddressAutocomplete } from '@application/components/form/address-autocomplete';
import { OptionType } from '@application/components/select-field/select';

import { TRANSLATION_PREFIX_KEYS } from '../constants';
import { CreateAccountFormFields } from '../schema';

type CompanyAddressProps = {
  type: 'ENTERPRISE' | 'AGENCY';
};

const CompanyAddress = ({ type }: CompanyAddressProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('signup', {
    keyPrefix: `${TRANSLATION_PREFIX_KEYS[type]}.form`,
  });

  const { control } = useFormContext<CreateAccountFormFields>();

  return (
    <fieldset className="mt-s-16 min-w-0">
      <legend className="mb-s-16 text-20 font-semibold">
        {t('companyAddress.heading')}
      </legend>

      <Controller
        name="address"
        control={control}
        render={({
          field: { onBlur, onChange, name, value },
          fieldState: { error },
        }) => (
          <AddressAutocomplete
            className="flex-grow"
            label={t('companyAddress.address.label')}
            name={name}
            value={value}
            invalid={!!error}
            helperText={
              error?.message && tGlobal(error?.message, { max: '255' })
            }
            onChange={(option: OptionType) => onChange(option?.label)}
            onBlur={onBlur}
          />
        )}
      />
    </fieldset>
  );
};

export default CompanyAddress;
