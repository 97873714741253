import { useQuery } from 'urql';

import { Query, QueryOfferCandidateArgs } from '@domain/graphql.types';
import { OfferCandidate } from '@infrastructure/graphql/offer';

const useOfferCandidate = (variables: QueryOfferCandidateArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'offerCandidate'>,
    QueryOfferCandidateArgs
  >({
    query: OfferCandidate,
    variables,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.offerCandidate,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useOfferCandidate;
