import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateNegotiationCompleteArgs,
} from '@domain/graphql.types';
import { MutationCandidateNegotiationComplete } from '@infrastructure/graphql/candidate';

const useCandidateNegotiationComplete = () => {
  const [{ data, fetching, error }, completeCandidateNegotiation] = useMutation<
    Pick<Mutation, 'candidateNegotiationComplete'>,
    MutationCandidateNegotiationCompleteArgs
  >(MutationCandidateNegotiationComplete);

  return {
    completeCandidateNegotiation,
    data,
    fetching,
    error,
  };
};

export default useCandidateNegotiationComplete;
