import {
  Mutation,
  OperationUnitCreateInput,
  OperationUnitResponse,
  OperationUnitUpdateInput,
} from '@domain/graphql.types';

import { OperationUnitFormFields } from './types';

export const normalizeUpdateInputData = (
  operationUnit: OperationUnitFormFields
): Omit<OperationUnitUpdateInput, 'id'> => ({
  name: operationUnit.name,
  address: operationUnit.address,
  phoneDisplay: operationUnit.phoneDisplay,
  phoneNumber: operationUnit.phoneNumber,
  headOffice: operationUnit.headOffice,
  industryCode: operationUnit.industryCode,
  sectorCode: operationUnit.sectorCode,
  notes: operationUnit.notes,
});

export const normalizeCreateInputData = (
  operationUnit: OperationUnitFormFields
): OperationUnitCreateInput => ({
  name: operationUnit.name,
  address: operationUnit.address,
  phoneDisplay: operationUnit.phoneDisplay,
  phoneNumber: operationUnit.phoneNumber,
  headOffice: operationUnit.headOffice,
  industryCode: operationUnit.industryCode,
  sectorCode: operationUnit.sectorCode,
  notes: operationUnit.notes,
});

export const normalizeCreateResponseData = ({
  operationUnitCreate: { operationUnit } = {} as OperationUnitResponse,
}:
  | Pick<Mutation, 'operationUnitCreate'>
  | Record<string, never>): OperationUnitFormFields => ({
  name: operationUnit?.name || '',
  address: operationUnit?.address || '',
  phoneDisplay: operationUnit?.phoneDisplay || true,
  phoneNumber: operationUnit?.phoneNumber || '',
});
