import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import KehopsLogo from '@application/assets/logo-white.svg?react';

export default function OnboardingLayout({ children }: PropsWithChildren) {
  const { t } = useTranslation();

  return (
    <div className="grid lg:grid-cols-2 min-h-[100vh] h-full scroll-smooth overflow-y-auto bg-neutral">
      {children}
      <div className="bg-primary hidden lg:flex items-center justify-center">
        <div className="w-s-224">
          <KehopsLogo title={t('appName')} />
        </div>
      </div>
    </div>
  );
}
