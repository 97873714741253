import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import RequestGeneralConditionsFragment from './request.general.conditions.fragment';
import RequestHeadhunterConditionsFragment from './request.headhunter.conditions.fragment';
import RequestInternationalRecruitmentConditionsFragment from './request.international.recruitment.conditions.fragment';
import RequestOutsourcingConditionsFragment from './request.outsourcing.conditions.fragment';
import RequestTemporaryPlacementConditionsFragment from './request.temporary.placement.conditions.fragment';

const PlainRequestFragment = gql`
  ${DescriptionsFragment}
  ${RequestGeneralConditionsFragment}
  ${RequestHeadhunterConditionsFragment}
  ${RequestTemporaryPlacementConditionsFragment}
  ${RequestInternationalRecruitmentConditionsFragment}
  ${RequestOutsourcingConditionsFragment}

  fragment PlainRequestFragment on Request {
    id
    accountId
    userId
    operationUnitId
    status
    priority
    isImportant
    jobSpecialtyCode
    specialty
    desiredStartDate
    receivingOfferDeadline
    operationTerritoryCodes
    jobExperienceLevelMin
    jobExperienceLevelMax
    jobDurationCode
    jobDurationInMonths
    jobModeCode
    jobFullTimeAvailability
    jobPartTimeAvailability
    jobAvailabilityInHoursPerWeek
    jobWorkingHours
    jobRemoteModeInDays
    jobOpenPositions
    jobSalaryMax
    jobSalaryMin
    jobPaymentFrequencyCode
    jobHourlyRateMax
    jobHourlyRateMin
    jobHourlyBonus
    jobRemunerationAmount
    jobPaymentOtherTypesCodes
    jobOtherRemunerationAmount
    jobAdditionalNotes
    generalContractualConditions {
      ...RequestGeneralConditionsFragment
    }
    headhunterContractualConditions {
      ...RequestHeadhunterConditionsFragment
    }
    temporaryPlacementContractualConditions {
      ...RequestTemporaryPlacementConditionsFragment
    }
    internationalRecruitmentContractualConditions {
      ...RequestInternationalRecruitmentConditionsFragment
    }
    outsourcingContractualConditions {
      ...RequestOutsourcingConditionsFragment
    }
    createdAt
    updatedAt
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    types
    operationUnit {
      name
    }
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    account {
      name
    }
    user {
      email
    }
  }
`;

export default PlainRequestFragment;
