import { gql } from '@urql/core';

const MutationSaveAndPublishOffer = gql`
  mutation SaveAndPublish(
    $updateInput: OfferUpdateInput!
    $statusUpdateInput: OfferStatusUpdateInput!
  ) {
    offerUpdate(offerUpdateInput: $updateInput) {
      offer {
        id
        requestId
      }
    }
    offerStatusUpdate(offerStatusUpdateInput: $statusUpdateInput) {
      offer {
        id
      }
    }
  }
`;

export default MutationSaveAndPublishOffer;
