import { gql } from '@urql/core';

const MutationUpdateCandidateAndCv = gql`
  mutation UpdateCandidateAndCv(
    $input: OfferCandidateUpdateInput!
    $cvInput: OfferCandidateCVInput!
    $files: [Upload!]!
  ) {
    offerCandidateUpdateCV(input: $cvInput, files: $files) {
      ok
    }
    offerCandidateUpdate(input: $input) {
      offerCandidate {
        id
      }
    }
  }
`;

export default MutationUpdateCandidateAndCv;
