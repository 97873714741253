import { useQuery } from 'urql';

import { Query, QueryAccountUsersArgs } from '@domain/graphql.types';
import { AccountUsers } from '@infrastructure/graphql/account-user';

const useAccountUsers = (input?: QueryAccountUsersArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'accountUsers'>,
    QueryAccountUsersArgs
  >({
    query: AccountUsers,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useAccountUsers;
