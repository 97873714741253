import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import {
  RecruitmentOpportunity,
  RecruitmentOpportunityResponse,
} from '@domain/graphql.types';

type ToViewModel = {
  recruitmentOpportunity: {
    data: RecruitmentOpportunityResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  recruitmentOpportunity: { data, fetching, error },
}: ToViewModel): ViewModel<RecruitmentOpportunity | undefined> =>
  new ViewModel<RecruitmentOpportunity | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.opportunity || undefined,
  });
