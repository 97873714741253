import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useCandidateRequestDuplicate } from '@domain/candidate';

import { toViewModel } from '../view-models/duplicate-candidate-request.vm';

const useDuplicateCandidateRequest = () => {
  const { data, fetching, error, duplicateCandidateRequest } =
    useCandidateRequestDuplicate();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    candidateRequest: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('duplicateError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateRequestDuplicate?.candidateRequest) {
      setAlertContent({
        action: t('duplicateSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateRequestDuplicate?.candidateRequest]);

  return {
    viewModel,
    duplicateCandidateRequest,
  };
};

export default useDuplicateCandidateRequest;
