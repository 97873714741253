import { gql } from '@urql/core';

const MutationOfferUpdate = gql`
  mutation OfferUpdate($offerUpdateInput: OfferUpdateInput!) {
    offerUpdate(offerUpdateInput: $offerUpdateInput) {
      offer {
        id
        requestId
      }
    }
  }
`;

export default MutationOfferUpdate;
