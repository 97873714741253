import { ReactNode, useContext } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import OnboardingIndustries from './OnboardingIndustries';
import OnboardingSectors from './OnboardingSectors';
import OnboardingSpecialties from './OnboardingSpecialties';
import SpecialtiesOnboardingContext from './context/SpecialtiesOnboardingContext';
import { OnboardingStep, SpecialtiesOnboardingFormFields } from './types';

type OnboardingStepperProps = {
  isCreating: boolean;
  setShowOnboarding: (state: boolean) => void;
  getValues: UseFormGetValues<SpecialtiesOnboardingFormFields>;
  register: UseFormRegister<SpecialtiesOnboardingFormFields>;
  setValue: UseFormSetValue<SpecialtiesOnboardingFormFields>;
  watch: UseFormWatch<SpecialtiesOnboardingFormFields>;
};

const getStepComponent = (
  step: OnboardingStep,
  {
    isCreating,
    getValues,
    register,
    setValue,
    watch,
    setShowOnboarding,
  }: OnboardingStepperProps
): ReactNode => {
  switch (step) {
    case OnboardingStep.INDUSTRIES:
      return (
        <OnboardingIndustries
          watch={watch}
          register={register}
          setValue={setValue}
          setShowOnboarding={setShowOnboarding}
        />
      );
    case OnboardingStep.SECTORS:
      return (
        <OnboardingSectors
          getValues={getValues}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      );
    case OnboardingStep.SPECIALTIES:
      return (
        <OnboardingSpecialties
          isCreating={isCreating}
          getValues={getValues}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      );
    default:
      return null;
  }
};

const OnboardingStepper = (props: OnboardingStepperProps) => {
  const { step } = useContext(SpecialtiesOnboardingContext);

  return getStepComponent(step, props) as React.JSX.Element;
};

export default OnboardingStepper;
