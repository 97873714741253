import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  CheckboxGroup,
  Cluster,
  CreatableSelectField,
  Fieldset,
  FormInputWithSuffix,
  HelperText,
  Radio,
  RadioGroup,
  RangeSlider,
  Stack,
} from '@application/components';
import { OptionType } from '@application/components/select-field/select';
import {
  useGetJobModes,
  useGetJobPaymentFrequencies,
  useGetJobPaymentOtherTypes,
  useGetJobScheduleTypes,
} from '@application/views/recruitment/request/hooks';
import {
  CandidateRequestType,
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
  Season,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';
import { INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX } from '@utils/yup-utils';

import { Section } from '../../components';
import { CandidateRequestFormFields } from '../../schema';

type ExpectationsFieldsProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  register: UseFormRegister<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  setError: UseFormSetError<CandidateRequestFormFields>;
  clearErrors: UseFormClearErrors<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
  trigger: UseFormTrigger<CandidateRequestFormFields>;
};

const ExpectationsFields = ({
  control,
  errors,
  register,
  setError,
  clearErrors,
  setValue,
  trigger,
  watch,
}: ExpectationsFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { data: JOB_SCHEDULE_TYPES = [] } = useGetJobScheduleTypes();
  const { data: JOB_MODES = [] } = useGetJobModes();
  const { data: JOB_PAYMENT_FREQUENCIES = [] } = useGetJobPaymentFrequencies();
  const { data: JOB_PAYMENT_OTHER_TYPES = [] } = useGetJobPaymentOtherTypes();

  const jobType = watch('jobType');
  const seasons = watch('seasons') || [];
  const schedules = watch('schedules') || [];
  const jobModeCode = watch('jobModeCode');
  const paymentFrequencyCode = watch('paymentFrequencyCode');
  const hourlyRateMin = watch('hourlyRateMin');
  const hourlyRateMax = watch('hourlyRateMax');
  const annualSalaryMin = watch('annualSalaryMin');
  const annualSalaryMax = watch('annualSalaryMax');
  const otherPaymentTypeCode = watch('otherPaymentTypeCode');
  const candidateRequestType = watch('type');
  const hoursPerWeek = watch('hoursPerWeek');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateRequestFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  const handleSeasonsChange = (event: any): void => {
    if (event.target.checked) {
      setValue('seasons', [...seasons, event.target.value]);
    } else {
      setValue('seasons', [...seasons.filter((s) => s !== event.target.value)]);
    }
    trigger('seasons');
  };

  const handleSchedulesChange = (event: any): void => {
    if (event.target.checked) {
      setValue('schedules', [...schedules, event.target.value]);
    } else {
      setValue('schedules', [
        ...schedules.filter((s) => s !== event.target.value),
      ]);
    }
  };

  return (
    <Section legend={t('subtitles.expectations')}>
      <Stack space={24}>
        <Controller
          name="jobType"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset
              legend={t('labels.jobType')}
              legendSize="small"
              invalid={!!errors.jobType?.message}
            >
              <Cluster space={16} as="ul">
                {Object.values(JobDurationCode).map((code) => (
                  <li key={code}>
                    <Radio
                      name={name}
                      value={code}
                      label={t(`enum.jobDurationCode.${code.toLowerCase()}`)}
                      onChange={(e) => {
                        handleOnRadioChange(
                          e as ChangeEvent<HTMLInputElement>,
                          name
                        );
                        setValue('jobDurationInMonths', undefined);
                        setValue('seasons', undefined);
                        trigger('paymentFrequencyCode');
                        trigger('jobDurationInMonths');
                        trigger('seasons');
                        trigger('type');
                        trigger(name);
                      }}
                      checked={jobType === code}
                      asButton
                    />
                  </li>
                ))}
              </Cluster>

              {errors.jobType?.message && (
                <HelperText id="jobType-error" invalid>
                  {tGlobal(errors.jobType.message)}
                </HelperText>
              )}
            </Fieldset>
          )}
        />

        {(jobType === JobDurationCode.Contract ||
          jobType === JobDurationCode.Freelance ||
          jobType === JobDurationCode.Internship) && (
          <FormInputWithSuffix
            label={t('labels.jobDurationInMonths')}
            type="number"
            minValue={1}
            suffix={t('suffix.months')}
            mediumSuffix
            noMargin
            invalid={!!errors.jobDurationInMonths}
            helperText={
              errors.jobDurationInMonths?.message &&
              tGlobal(errors.jobDurationInMonths?.message, { min: '1' })
            }
            {...register('jobDurationInMonths')}
          />
        )}

        {jobType === JobDurationCode.Seasonal && (
          <Controller
            name="seasons"
            control={control}
            render={({ field: { value } }) => (
              <Fieldset
                legend={t('labels.seasons')}
                legendSize="small"
                invalid={!!errors.seasons?.message}
              >
                <Cluster space={16} as="ul">
                  {Object.values(Season).map((code) => (
                    <li key={code}>
                      <Checkbox
                        name={code}
                        value={code}
                        label={t(`enum.seasons.${code.toLowerCase()}`)}
                        asButton
                        onChange={handleSeasonsChange}
                        checked={value?.includes(code)}
                      />
                    </li>
                  ))}
                </Cluster>

                {errors.seasons?.message && (
                  <HelperText id="seasons-error" invalid>
                    {tGlobal(errors.seasons.message)}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />
        )}

        <Cluster space={16} className="items-center">
          <Controller
            name="hoursPerWeek"
            control={control}
            render={({ field: { name } }) => (
              <CreatableSelectField
                name={name}
                label={t('labels.hoursPerWeek')}
                value={
                  hoursPerWeek?.map((v) => ({
                    label: v,
                    value: v,
                  })) as OptionType[]
                }
                className="flex-1 min-w-fit"
                validateOnKeyDown={(newValue: string) =>
                  INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                }
                onInputChange={(newValue: string) => {
                  // Check if entered value is an integer or one decimal digit number only
                  // return an error otherwise
                  if (
                    newValue &&
                    !INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                  ) {
                    setError(name, {
                      type: 'custom',
                      message: tGlobal(
                        'validations.integerOrOneDecimalDigitNumber'
                      ),
                    });
                    return;
                  }
                  clearErrors(name);
                }}
                onChange={(options: any) => {
                  setValue(
                    name,
                    options.map((option: any) => option.value)
                  );
                  trigger(name);
                }}
                formatOptionLabel={({ value: newValue }) => `${newValue}h`}
                invalid={!!errors.hoursPerWeek}
                helperText={
                  errors.hoursPerWeek?.message
                    ? tGlobal(errors.hoursPerWeek?.message)
                    : tGlobal('creatableSelect.enterHoursAsIntegerOrDecimal')
                }
              />
            )}
          />

          <Controller
            name="fullTimeAvailability"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Checkbox
                name={name}
                value={String(value)}
                label={t('labels.fullTime')}
                onChange={onChange}
                checked={Boolean(value)}
                asButton
              />
            )}
          />

          <Controller
            name="partTimeAvailability"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Checkbox
                name={name}
                value={String(value)}
                label={t('labels.partTime')}
                onChange={onChange}
                checked={Boolean(value)}
                asButton
              />
            )}
          />
        </Cluster>

        <Controller
          name="schedules"
          control={control}
          render={({ field: { value } }) => (
            <CheckboxGroup
              data={JOB_SCHEDULE_TYPES}
              legend={t('labels.scheduleMultiple')}
              language={extractLanguage(i18n.language)}
              onChange={handleSchedulesChange}
              legendSize="small"
              values={value}
            />
          )}
        />

        <Controller
          name="jobModeCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_MODES}
              legend={t('labels.remoteWork')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobModeCode);
                trigger(name);
                trigger('remoteDaysAllowed');
              }}
              legendSize="small"
              value={value as string}
              invalid={!!errors.jobModeCode}
              helperText={
                errors.jobModeCode?.message &&
                tGlobal(errors.jobModeCode?.message)
              }
            />
          )}
        />

        {jobModeCode === JobModeCode.Hybrid && (
          <FormInputWithSuffix
            type="number"
            minValue={1}
            maxValue={7}
            label={t('labels.remoteDaysAllowed')}
            suffix={t('suffix.daysPerWeek')}
            noMargin
            invalid={!!errors.remoteDaysAllowed}
            helperText={
              errors.remoteDaysAllowed?.message &&
              tGlobal(errors.remoteDaysAllowed?.message, { min: '1', max: '7' })
            }
            {...register('remoteDaysAllowed')}
          />
        )}

        <Controller
          name="paymentFrequencyCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_PAYMENT_FREQUENCIES}
              legend={t('labels.remunerationType')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobPaymentFrequencyCode);
                trigger('type');
                trigger('remunerationAmount');
                trigger(name);
              }}
              legendSize="small"
              value={value || undefined}
              invalid={!!errors.paymentFrequencyCode}
              helperText={
                errors.paymentFrequencyCode?.message &&
                tGlobal(errors.paymentFrequencyCode?.message)
              }
            />
          )}
        />

        {paymentFrequencyCode === JobPaymentFrequencyCode.Yearly && (
          <RangeSlider
            minLabel={t('labels.minSalary')}
            maxLabel={t('labels.maxSalary')}
            minName="annualSalaryMin"
            maxName="annualSalaryMax"
            onChange={(name: string, value: number | string) =>
              setValue(name as keyof CandidateRequestFormFields, value)
            }
            minValue={annualSalaryMin || undefined}
            maxValue={annualSalaryMax || undefined}
            min={1}
            max={1000}
            suffix="k$"
          />
        )}

        {paymentFrequencyCode === JobPaymentFrequencyCode.Hourly && (
          <>
            <RangeSlider
              minLabel={t('labels.minHourlyRate')}
              maxLabel={t('labels.maxHourlyRate')}
              minName="hourlyRateMin"
              maxName="hourlyRateMax"
              onChange={(name: string, value: number | string) =>
                setValue(name as keyof CandidateRequestFormFields, value)
              }
              minValue={hourlyRateMin || undefined}
              maxValue={hourlyRateMax || undefined}
              min={0.01}
              max={999.99}
              step={0.01}
              suffix="$/h"
            />

            <FormInputWithSuffix
              label={t('labels.hourlyBonus')}
              suffix="$"
              type="number"
              minValue={1}
              maxValue={999.99}
              step={0.01}
              alignLeft
              invalid={!!errors.hourlyBonus}
              helperText={
                errors.hourlyBonus?.message &&
                tGlobal(errors.hourlyBonus?.message, {
                  max: '999.99',
                  min: '1',
                })
              }
              {...register('hourlyBonus')}
            />
          </>
        )}

        {paymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.compensationAmount')}
            suffix="$"
            invalid={!!errors.remunerationAmount}
            helperText={
              errors.remunerationAmount?.message &&
              tGlobal(errors.remunerationAmount?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('remunerationAmount')}
          />
        )}

        <Controller
          name="otherPaymentTypeCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_PAYMENT_OTHER_TYPES}
              legend={t('labels.otherCompensation')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobPaymentOtherTypesCode);
                trigger(name);
                trigger('otherPaymentAmount');
              }}
              legendSize="small"
              value={value as string}
              disabled={
                candidateRequestType === CandidateRequestType.Outsourcing
              }
            />
          )}
        />

        {!!otherPaymentTypeCode && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.otherCompensationAmount')}
            suffix="$"
            invalid={!!errors.otherPaymentAmount}
            helperText={
              errors.otherPaymentAmount?.message &&
              tGlobal(errors.otherPaymentAmount?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            disabled={candidateRequestType === CandidateRequestType.Outsourcing}
            {...register('otherPaymentAmount')}
          />
        )}
      </Stack>
    </Section>
  );
};

export default ExpectationsFields;
