import { createContext } from 'react';

type OperationUnitsListContextProps = {
  shouldRefreshList: boolean;
  setShouldRefreshList: (state: boolean) => void;
};

const OperationUnitsListContext = createContext<OperationUnitsListContextProps>(
  {
    shouldRefreshList: false,
    setShouldRefreshList: () => {},
  }
);

export default OperationUnitsListContext;
