import { gql } from 'urql';

const RecruitmentOpportunityGeneralConditionsFragment = gql`
  fragment RecruitmentOpportunityGeneralConditionsFragment on RequestGeneralConditions {
    bonusDisplay
    bonusMonthValue
    bonusValue
    budgetDisplay
    candidateInHandMandatoryDisplay
    candidateInHandMandatoryValue
    depositDisplay
    depositValue
    hiredDateBonusDisplay
    hiredDateBonusRequirement
    hiredDateBonusValue
    maxRecruitmentPeriodDisplay
    maxRecruitmentPeriodValue
    maximumBudgetValue
    minGuaranteedPeriodBonusValue
    minGuaranteedPeriodDisplay
    minGuaranteedPeriodValue
    minimumBudgetValue
    paymentAmountValue
    paymentFrequencyValue
    paymentMethodDisplay
    paymentMethodValue
    probationPeriodDisplay
    probationPeriodValue
    startOfAgreementPaymentMethodValue
  }
`;

export default RecruitmentOpportunityGeneralConditionsFragment;
