import { gql } from 'urql';

const MutationRecruitmentNegotiationRoomCreate = gql`
  mutation RecruitmentNegotiationRoomCreate(
    $input: RecruitmentNegotiationRoomCreateInput!
  ) {
    recruitmentNegotiationRoomCreate(input: $input) {
      room {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationRoomCreate;
