import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation } from 'react-router-dom';

import { Box } from '@application/components';
import { BadgeList } from '@application/components/badge';
import { RequestTypeBadges } from '@application/components/request-type-badges';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useLocalizedOperationTerritories } from '@application/hooks/localization';
import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { calculateTimeSince } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';

import ListIconButtonLink from './ListIconButtonLink';

type ListCardProps = {
  data: RecruitmentNegotiationRoom;
};

const ListCard = ({ data }: ListCardProps) => {
  const location = useLocation();
  const { i18n, t: tGlobal } = useTranslation();

  const operationTerritories = useLocalizedOperationTerritories(
    data?.request?.operationTerritories
  );

  const timeSinceLastActivity = useMemo(
    () => calculateTimeSince(data?.updatedAt, 'minute'),
    [data?.updatedAt]
  );

  const pathToRoom = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_DETAILS, {
        id: data.id,
      }),
    [data.id]
  );

  const pathToNegotiationRoomChat = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_CHAT, {
        id: data.id,
      }),
    [data.id]
  );

  return (
    <Box className="min-w-[66rem] w-full h-s-96 shadow-xs">
      <div className="grid grid-cols-[4fr_1fr_1fr] gap-s-40 items-center justify-center">
        <div className="flex flex-col gap-s-8">
          <Link to={pathToRoom} className="hover:underline">
            <span className="text-18 font-semibold">
              {getLocalizedDescription(
                data?.request?.jobSpecialty?.descriptions,
                i18n.language
              ) || '-'}
            </span>
          </Link>

          <div className="flex gap-s-8 items-center">
            <span className="text-14">
              {data?.request?.operationUnit?.name || '-'}
            </span>

            <i className="ri-circle-fill text-6" />

            <BadgeList values={operationTerritories} />

            <i className="ri-circle-fill text-6" />

            <span className="text-14">
              {tGlobal('date.latestActivity', {
                date: tGlobal(`date.options.${timeSinceLastActivity.unit}`, {
                  count: timeSinceLastActivity.value,
                }),
              })}
            </span>
          </div>
        </div>

        {data?.request?.types?.length ? (
          <RequestTypeBadges types={[...data.request.types]} />
        ) : (
          <span>-</span>
        )}
        <div className="flex justify-end gap-s-16">
          <ListIconButtonLink
            count={data.notificationCount}
            iconName="ri-alarm-warning-line"
            to={pathToRoom}
          />

          <ListIconButtonLink
            // count={10}
            iconName="ri-question-answer-line"
            to={pathToNegotiationRoomChat}
            from={location.pathname}
          />
        </div>
      </div>
    </Box>
  );
};

export default ListCard;
