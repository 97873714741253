import { gql } from 'urql';

const CandidateOfferRecruitmentConditionsFragment = gql`
  fragment CandidateOfferRecruitmentConditionsFragment on CandidateOfferRecruitmentConditions {
    budget
    paymentMethod
    startOfAgreementPaymentMethod
    paymentAmount
    deposit
    paymentFrequency
    guaranteedPeriod
    probationPeriod
    bonus
  }
`;

export default CandidateOfferRecruitmentConditionsFragment;
