import { createContext } from 'react';

type NotificationCenterContextProps = {
  isPanelOpen: boolean;
  setIsPanelOpen: (state: boolean) => void;
};

const NotificationCenterContext = createContext<NotificationCenterContextProps>(
  {
    isPanelOpen: false,
    setIsPanelOpen: () => {},
  }
);

export default NotificationCenterContext;
