import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@application/components/badge';
import { ConfirmationModal } from '@application/components/modal';
import { ModalContext } from '@application/context';
import {
  OperationUnit,
  OperationUnitsFilters,
  OperationUnitsSortBy,
  OperationUnitsSortDirective,
  SortDirection,
} from '@domain/graphql.types';

import { OperationUnitsListContext } from '../../context';
import UpdateOperationUnitModal from '../../update-operation-unit/UpdateOperationUnitModal';
import ActionMenu from '../components/ActionMenu';
import useDeleteOperationUnit from './useDeleteOperationUnit';
import useGetOperationUnits from './useGetOperationUnits';

const columnIds = {
  name: 'name',
  address: 'address',
  phoneNumber: 'phoneNumber',
  actions: 'actions',
} as const;

const columnHelper = createColumnHelper<OperationUnit>();

const getColumns = (
  t: TFunction<'translation', undefined>,
  confirmOperationUnitDeletion: (id: string) => () => void,
  handleOperationUnitUpdate: (id: string) => () => void
) => [
  columnHelper.accessor((row) => row.name, {
    id: columnIds.name,
    cell: (info) => (
      <div className="font-semibold">
        {info.getValue()}
        {info.row.original.headOffice && (
          <Badge
            placeholder=""
            value={t('headOffice')}
            className="ml-s-8"
            valueClassName="font-semibold"
          />
        )}
      </div>
    ),
    header: () => t('locationName'),
    size: 250,
    minSize: 250,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.address, {
    id: columnIds.address,
    cell: (info) => <span>{info.getValue()} </span>,
    header: () => t('address'),
    size: 350,
    minSize: 350,
    maxSize: undefined,
  }),

  columnHelper.accessor((row) => row.phoneNumber, {
    id: columnIds.phoneNumber,
    cell: (info) => <span>{info.getValue()} </span>,
    header: () => t('phoneNumber'),
    size: 150,
    minSize: 150,
    maxSize: undefined,
  }),

  columnHelper.accessor((row) => row.id, {
    id: columnIds.actions,
    cell: (info) => (
      <ActionMenu
        onDelete={confirmOperationUnitDeletion}
        onUpdate={handleOperationUnitUpdate}
        id={info.getValue()}
        headOffice={info.row.original.headOffice}
      />
    ),
    header: () => null,
    size: 32,
    minSize: 32,
    maxSize: 32,
    enableSorting: false,
  }),
];

const stringSortToEnum = (by: string): OperationUnitsSortBy => {
  switch (by) {
    case columnIds.name:
      return OperationUnitsSortBy.Name;
    case columnIds.address:
      return OperationUnitsSortBy.Address;
    case columnIds.phoneNumber:
      return OperationUnitsSortBy.PhoneNumber;
    default:
      return OperationUnitsSortBy.CreatedAt;
  }
};

const mapSorting = (sorting: SortingState): OperationUnitsSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id),
  }));

type OperationUnitsList = {
  columns: ColumnDef<OperationUnit>[];
  columnIds: typeof columnIds;
  operationUnits: OperationUnit[];
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  isLoading: boolean;
};

export const useOperationUnitsList = (
  filters?: OperationUnitsFilters
): OperationUnitsList => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: true },
  ]);

  const { t } = useTranslation('operationUnit', { keyPrefix: 'list' });

  const {
    viewModel: { data, isLoading },
    refetchOperationUnits,
  } = useGetOperationUnits(mapSorting(sorting), filters);

  const { deleteOperationUnit } = useDeleteOperationUnit();

  const { setModal } = useContext(ModalContext);
  const { shouldRefreshList } = useContext(OperationUnitsListContext);

  useEffect(() => {
    if (shouldRefreshList) {
      refetchOperationUnits();
    }
  }, [refetchOperationUnits, shouldRefreshList]);

  const handleDeleteOperationUnit = useCallback(
    (operationUnitId: string) => async () => {
      setModal(null);

      const result = await deleteOperationUnit({ input: { operationUnitId } });

      if (result.data?.operationUnitDelete.ok) {
        refetchOperationUnits();
      }
    },
    [deleteOperationUnit, refetchOperationUnits, setModal]
  );

  const confirmOperationUnitDeletion = useCallback(
    (operationUnitId: string) => () => {
      setModal({
        title: t('deleteLocationTitle'),
        content: (
          <ConfirmationModal
            content={t('messages.deleteLocationMessage')}
            onCancel={() => setModal(null)}
            onConfirm={handleDeleteOperationUnit(operationUnitId)}
          />
        ),
      });
    },
    [setModal, t, handleDeleteOperationUnit]
  );

  const handleUpdateOperationUnit = useCallback(
    (operationUnitId: string) => () => {
      setModal({
        title: t('updateOperationUnit'),
        maxWidth: '2xl',
        content: (
          <UpdateOperationUnitModal
            afterSubmit={() => {
              setModal(null);
              refetchOperationUnits();
            }}
            onClose={() => setModal(null)}
            id={operationUnitId}
          />
        ),
      });
    },
    [refetchOperationUnits, setModal, t]
  );

  const columns = getColumns(
    t,
    confirmOperationUnitDeletion,
    handleUpdateOperationUnit
  );

  return {
    columns: columns as ColumnDef<OperationUnit>[],
    columnIds,
    operationUnits: data?.operationUnits || [],
    sorting,
    onSortingChange: setSorting,
    isLoading,
  };
};
