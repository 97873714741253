import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { JobSpecialty } from '@domain/graphql.types';

type ToViewModel = {
  jobSpecialties: {
    data: JobSpecialty[];
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  jobSpecialties: { data = [], fetching, error },
}: ToViewModel): ViewModel<JobSpecialty[]> =>
  new ViewModel<JobSpecialty[]>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
