import { gql } from 'urql';

import { RecruitmentNegotiationRoomListFragment } from '../fragment';

const RecruitmentNegotiationRooms = gql`
  ${RecruitmentNegotiationRoomListFragment}

  query RecruitmentNegotiationRooms(
    $filterBy: RecruitmentNegotiationRoomsFilters
  ) {
    recruitmentNegotiationRooms(filterBy: $filterBy) {
      results {
        ...RecruitmentNegotiationRoomListFragment
      }
      totalCount
    }
  }
`;

export default RecruitmentNegotiationRooms;
