import { useQuery } from 'urql';

import { Query } from '@domain/graphql.types';
import { Account } from '@infrastructure/graphql/account';

const useAccountGet = (pause?: boolean) => {
  const [result, reexecuteQuery] = useQuery<Pick<Query, 'account'>, void>({
    query: Account,
    pause,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.account,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useAccountGet;
