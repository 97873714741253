import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DisplayButton,
  FormInputWithSuffix,
  SectionGrid,
  SectionGridRow,
  TextAreaField,
} from '@application/components';

import { RequestFormFields } from '../../schema';

type OutsourcingFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const OutsourcingFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
}: OutsourcingFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGrid title={t('subtitles.forOutsourcing')} className="mt-s-40">
        <SectionGridRow
          isTopRow
          rightChildren={
            <DisplayButton
              name="outsourcingConditionMaximumDurationDisplay"
              control={control}
              setValue={setValue}
              watch={watch}
            />
          }
        >
          <FormInputWithSuffix
            label={t('labels.maxMandateDuration')}
            type="number"
            minValue={1}
            noMargin
            suffix={t('suffix.weeks')}
            mediumSuffix
            invalid={!!errors.outsourcingConditionMaximumDurationValue}
            helperText={
              errors.outsourcingConditionMaximumDurationValue?.message &&
              tGlobal(
                errors.outsourcingConditionMaximumDurationValue?.message,
                {
                  min: '1',
                }
              )
            }
            {...register('outsourcingConditionMaximumDurationValue')}
          />
        </SectionGridRow>

        <SectionGridRow
          rightChildren={
            <DisplayButton
              name="outsourcingConditionExclusivityPeriodDisplay"
              control={control}
              setValue={setValue}
              watch={watch}
            />
          }
        >
          <FormInputWithSuffix
            label={t('labels.outsourcingExclusivityPeriod')}
            type="number"
            minValue={1}
            noMargin
            suffix={t('suffix.weeks')}
            mediumSuffix
            invalid={!!errors.outsourcingConditionExclusivityPeriodValue}
            helperText={
              errors.outsourcingConditionExclusivityPeriodValue?.message &&
              tGlobal(
                errors.outsourcingConditionExclusivityPeriodValue?.message,
                {
                  min: '1',
                }
              )
            }
            {...register('outsourcingConditionExclusivityPeriodValue')}
          />
        </SectionGridRow>

        <SectionGridRow
          rightChildren={
            <DisplayButton
              name="outsourcingConditionMaximumBudgetDisplay"
              control={control}
              setValue={setValue}
              watch={watch}
            />
          }
        >
          <FormInputWithSuffix
            className="min-w-max"
            label={t('labels.maxCandidateBudget')}
            suffix={t('suffix.currencyPerHour')}
            type="number"
            mediumSuffix
            noMargin
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            invalid={!!errors.outsourcingConditionMaximumBudgetValue}
            helperText={
              errors.outsourcingConditionMaximumBudgetValue?.message &&
              tGlobal(errors.outsourcingConditionMaximumBudgetValue?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('outsourcingConditionMaximumBudgetValue')}
          />
        </SectionGridRow>
      </SectionGrid>

      <div className="flex items-center p-s-16 h-full border rounded-md">
        <Controller
          name="outsourcingConditionResponsibilities"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              className="w-full"
              value={value || ''}
              label={t('labels.recruitmentResponsibilities')}
              labelSize="medium"
              rows={4}
              onChange={onChange}
            />
          )}
        />
      </div>
    </>
  );
};

export default OutsourcingFields;
