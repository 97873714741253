/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { CandidateRequest, CandidateRequestType } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../../constants';

type OfferToRecruiterProps = {
  data: CandidateRequest;
};

const OfferToRecruiter = ({ data }: OfferToRecruiterProps) => {
  const { t } = useTranslation('candidates');

  return (
    <>
      <tr>
        <th className="sr-only">
          <span>{t('labels.offerToRecruiter')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.highlight, 'h-s-56')}>&nbsp;</td>
      </tr>

      {data.type === CandidateRequestType.Recruitment ? (
        <>
          <tr>
            <th scope="col" className="sr-only">
              {t('labels.proposedFees')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                TD_CLASS_NAME.disabled,
                'border-l-0'
              )}
            >
              <span>-</span>
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.additionalBonus')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-l-0 whitespace-nowrap'
              )}
            >
              <PlaceholderSwitcher
                value={data?.recruitmentConditions?.bonusValue}
              >
                {t('suffix.currency', {
                  value: data?.recruitmentConditions?.bonusValue,
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.guaranteeRequested')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-l-0 whitespace-nowrap'
              )}
            >
              <PlaceholderSwitcher
                value={data?.recruitmentConditions?.guaranteedPeriodValue}
              >
                {t('suffix.month', {
                  count: Number(
                    data?.recruitmentConditions?.guaranteedPeriodValue
                  ),
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.paymentMethods')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-b-0 border-l-0 whitespace-nowrap'
              )}
            >
              <PlaceholderSwitcher
                value={data?.recruitmentConditions?.paymentMethodValue}
              >
                <TooltipEllipsis
                  toolTip={t(
                    `enum.requestConditionPaymentMethodCode.short.${data?.recruitmentConditions?.paymentMethodValue?.toLowerCase()}`
                  )}
                >
                  <div>
                    {t(
                      `enum.requestConditionPaymentMethodCode.short.${data?.recruitmentConditions?.paymentMethodValue?.toLowerCase()}`
                    )}
                  </div>
                </TooltipEllipsis>
              </PlaceholderSwitcher>
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr>
            <th scope="col" className="sr-only">
              {t('labels.desiredBillingPeriod')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-l-0 whitespace-nowrap text-ellipsis overflow-hidden'
              )}
            >
              {data.type === CandidateRequestType.TemporaryPlacement ? (
                <PlaceholderSwitcher
                  value={data?.temporaryPlacementConditions?.billingPeriodValue}
                >
                  {t(
                    `enum.candidateConditionBillingPeriod.${data?.temporaryPlacementConditions?.billingPeriodValue?.toLowerCase()}`
                  )}
                </PlaceholderSwitcher>
              ) : (
                <PlaceholderSwitcher
                  value={data?.outsourcingConditions?.billingPeriodValue}
                >
                  {t(
                    `enum.candidateConditionBillingPeriod.${data?.outsourcingConditions?.billingPeriodValue?.toLowerCase()}`
                  )}
                </PlaceholderSwitcher>
              )}
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.minimumNotice')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-l-0',
                { 'border-b-0': data.type === CandidateRequestType.Outsourcing }
              )}
            >
              {data.type === CandidateRequestType.TemporaryPlacement ? (
                <PlaceholderSwitcher
                  value={data?.temporaryPlacementConditions?.minimumNoticeValue}
                >
                  {t('suffix.week', {
                    count: Number(
                      data?.temporaryPlacementConditions?.minimumNoticeValue
                    ),
                  })}
                </PlaceholderSwitcher>
              ) : (
                <PlaceholderSwitcher
                  value={data?.outsourcingConditions?.minimumNoticeValue}
                >
                  {t('suffix.week', {
                    count: Number(
                      data?.outsourcingConditions?.minimumNoticeValue
                    ),
                  })}
                </PlaceholderSwitcher>
              )}
            </td>
          </tr>

          {data.type === CandidateRequestType.TemporaryPlacement && (
            <tr>
              <th scope="col" className="sr-only">
                {t('labels.possibleBuybackBonus')}
              </th>

              <td
                className={cn(
                  TD_CLASS_NAME.base,
                  TD_CLASS_NAME.fixed,
                  'border-l-0 border-b-0'
                )}
              >
                <PlaceholderSwitcher
                  value={data?.temporaryPlacementConditions?.minimumNoticeValue}
                >
                  {t('suffix.week', {
                    count: Number(
                      data?.temporaryPlacementConditions?.minimumNoticeValue
                    ),
                  })}
                </PlaceholderSwitcher>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default OfferToRecruiter;
