import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { Stack, Switcher } from '@application/components/container-layouts';
import { SelectField } from '@application/components/select-field';
import { mapOptions } from '@application/utils';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';
import { RecruitmentOpportunitiesFilters } from '@domain/graphql.types';

type FiltersFieldsProps = {
  updateTextFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  updateSelectFilter: (
    fieldName: keyof RecruitmentOpportunitiesFilters
  ) => (values: any) => void;
};

const FiltersFields = ({
  updateTextFilter,
  updateSelectFilter,
}: FiltersFieldsProps) => {
  const { t, i18n } = useTranslation('recruitment', {
    keyPrefix: 'opportunity.filters',
  });

  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();

  const operationTerritories = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n.language]
  );

  return (
    <Stack space={16}>
      <Switcher space={24} className="items-end mb-s-16">
        <TextInputField
          label={t('search')}
          placeholder={`${t('search')}...`}
          name="text"
          withIcon="ri-search-2-line text-24"
          hideIconBorder
          className="flex-1"
          onChange={updateTextFilter}
        />

        <SelectField
          label={t('operationTerritory')}
          name="operationTerritory"
          options={operationTerritories}
          className="flex-1"
          onChange={updateSelectFilter('operationTerritoryCode')}
          limitTags={0}
          clearable
        />
      </Switcher>
    </Stack>
  );
};

export default FiltersFields;
