import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { IconButton } from '../buttons';

type PaginationProps<T> = {
  table: Table<T>;
  count?: number;
  onPaginationReset?: () => void;
};

const Pagination = <T,>({
  table,
  count,
  onPaginationReset,
}: PaginationProps<T>) => {
  const { t } = useTranslation();

  const calculateRange = (
    pageIndex: number,
    pageSize: number,
    totalItems: number
  ) => {
    const startIndex = pageIndex * pageSize + 1;
    const endIndex =
      (pageIndex + 1) * pageSize < totalItems
        ? (pageIndex + 1) * pageSize
        : totalItems;
    return { startIndex, endIndex };
  };

  const { startIndex, endIndex } = calculateRange(
    table.getState().pagination.pageIndex,
    table.getState().pagination.pageSize,
    count || 0
  );

  return (
    <div className="flex bg-base-100 rounded-b-md justify-between w-full border border-stroke-default border-t-white p-s-12">
      <div className="flex items-center">
        {t('table.pagination', {
          start: startIndex,
          end: endIndex,
          count: count || 0,
        })}
      </div>

      <div className="flex items-center">
        <IconButton
          icon={<i className="ri-skip-left-line text-24" />}
          text="arrow-left"
          size="x-small"
          onClick={onPaginationReset}
          disabled={!table.getCanPreviousPage()}
          className="mr-s-8"
        />

        <IconButton
          icon={<i className="ri-arrow-left-s-line text-24" />}
          text="arrow-left"
          size="x-small"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="mr-s-8"
        />

        <IconButton
          icon={<i className="ri-arrow-right-s-line text-24" />}
          text="arrow-right"
          size="x-small"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="mr-s-8"
        />
      </div>
    </div>
  );
};

export default Pagination;
