import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

import { TH_COL_CLASS_NAME } from '../constants';

const TableHeader = () => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  return (
    <thead>
      <tr>
        <th
          className={cn(
            'w-s-192 min-w-[12rem] max-w-[12rem]',
            TH_COL_CLASS_NAME
          )}
        >
          <span className="sr-only">{t('underNegotiation')}</span>
        </th>

        <th className={TH_COL_CLASS_NAME}>
          <span>{t('underNegotiation')}</span>
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
