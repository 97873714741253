import { RequestsFilters, RequestsSortDirective } from '@domain/graphql.types';
import { Pagination } from '@domain/pagination/pagination';
import { useRequests } from '@domain/request';

import { toViewModel } from '../view-models/get-requests.vm';

const useGetRequests = (
  sortBy: RequestsSortDirective[],
  filterBy?: RequestsFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } = useRequests({
    sortBy,
    filterBy,
    ...pagination,
  });

  const viewModel = toViewModel({
    requests: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRequests: reexecuteQuery,
  };
};

export default useGetRequests;
