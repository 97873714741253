import React, { ForwardedRef, ReactNode, useMemo } from 'react';

import { HelperText, TextInputField } from '@application/components';
import type { TextInputType } from '@application/components/form/text-input-field/textInput';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

type FormInputWithSuffixProps = {
  label: ReactNode;
  name: string;
  alignLeft?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  hideLabel?: boolean;
  invalid?: boolean;
  maxValue?: number;
  mediumSuffix?: boolean;
  minValue?: number | string;
  noMargin?: boolean;
  suffix?: string;
  type?: TextInputType;
  className?: string;
  inputClassName?: string;
  step?: number;
  value?: string | number | undefined;
  disabled?: boolean | undefined;
};

const FormInputWithSuffix = React.forwardRef<
  HTMLDivElement,
  FormInputWithSuffixProps
>(
  (
    {
      className: customClassName,
      inputClassName: customInputClassName,
      label,
      name,
      alignLeft,
      fullWidth,
      helperText,
      hideLabel,
      invalid,
      maxValue,
      mediumSuffix,
      minValue,
      noMargin,
      suffix,
      type = 'text',
      step,
      ...rest
    }: FormInputWithSuffixProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const helperTextId = useMemo(() => randomString(), []);

    const wrapperClassName = cn(
      { '!mt-s-16': !alignLeft && !noMargin },
      customClassName
    );
    const containerClassName = cn('flex flex-1 items-center', {
      'items-center': alignLeft,
    });
    const suffixClassName = cn('label text-14 py-s-0 text-neutral-secondary', {
      'mt-s-24': alignLeft,
      'text-16 pl-s-8': mediumSuffix,
    });
    const labelClassName = cn({ 'text-16 p-s-0 mr-s-8': !alignLeft });
    const textInputClassName = cn('items-center', {
      'flex flex-row': !alignLeft,
      'w-full': fullWidth,
    });

    return (
      <div className={wrapperClassName}>
        <div className={containerClassName}>
          <TextInputField
            name={name}
            label={label}
            labelClassName={labelClassName}
            className={textInputClassName}
            inputClassName={customInputClassName}
            minValue={minValue}
            maxValue={maxValue}
            type={type}
            invalid={invalid}
            hideLabel={hideLabel}
            ref={ref}
            step={step}
            {...rest}
          />

          {suffix && <span className={suffixClassName}>{suffix}</span>}
        </div>

        {helperText && (
          <HelperText id={helperTextId} invalid={invalid}>
            {helperText}
          </HelperText>
        )}
      </div>
    );
  }
);

export default FormInputWithSuffix;
