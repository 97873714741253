import { gql } from 'urql';

import { OperationUnitFragment } from '../operation';

const PublicAccountFragment = gql`
  ${OperationUnitFragment}

  fragment PublicAccountFragment on Account {
    id
    logoUrl
    name
    website
    address
    phoneNumber
    phoneDisplay
    advantageCodes
    industryTypeCode
    sectorCode
    sizeTypeCode
    employerOfferDescription
    facebookUrl
    instagramUrl
    linkedinUrl
    twitterUrl
    services
    type
    operationUnits {
      ...OperationUnitFragment
    }
    preferredSpecialties {
      specialty {
        code
      }
    }
  }
`;

export default PublicAccountFragment;
