import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import CandidateOfferOutsourcingConditionsFragment from '../../offer/fragment/candidate.offer.outsourcing.conditions.fragment';
import CandidateOfferRecruitmentConditionsFragment from '../../offer/fragment/candidate.offer.recruitment.conditions.fragment';
import CandidateOfferTemporaryPlacementConditionsFragment from '../../offer/fragment/candidate.offer.temporary.placement.conditions.fragment';

const CandidateRequestOfferFragment = gql`
  ${DescriptionsFragment}
  ${CandidateOfferRecruitmentConditionsFragment}
  ${CandidateOfferOutsourcingConditionsFragment}
  ${CandidateOfferTemporaryPlacementConditionsFragment}

  fragment CandidateRequestOfferFragment on CandidateOffer {
    id
    accountId
    account {
      name
    }
    userId
    candidateRequestId
    status
    matchingScore
    rank
    potentialJobDuration
    potentialAmount
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    operationUnitId
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    operationTerritoryCodes
    jobType
    jobDurationInMonths
    hoursPerWeek
    seasons
    fullTimeAvailability
    partTimeAvailability
    schedules
    jobModeCode
    remoteDaysAllowed
    paymentFrequencyCode
    annualSalaryMin
    annualSalaryMax
    hourlyRateMin
    hourlyRateMax
    hourlyBonus
    remunerationAmount
    otherPaymentTypeCode
    otherPaymentAmount
    candidateBonus
    candidateBonusAmount
    perks
    linkProfile
    salesPitch
    recruitmentConditions {
      ...CandidateOfferRecruitmentConditionsFragment
    }
    temporaryPlacementConditions {
      ...CandidateOfferTemporaryPlacementConditionsFragment
    }
    outsourcingConditions {
      ...CandidateOfferOutsourcingConditionsFragment
    }
    user {
      email
    }
    createdAt
    updatedAt
    availableTransitions
    negotiationRoomId
  }
`;

export default CandidateRequestOfferFragment;
