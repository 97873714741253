import { useMemo } from 'react';

import { useNotFoundOnPredicate, useValidateIdParam } from '@application/hooks';
import { useCandidateNegotiation } from '@domain/candidate';

import { toViewModel } from '../view-models/get-candidate-negotiation-agreement.vm';

const useGetCandidateNegotiationAgreement = (negotiationId: string) => {
  useValidateIdParam(negotiationId);

  const { data, error, fetching } = useCandidateNegotiation({
    input: { negotiationId },
  });

  const viewModel = useMemo(
    () =>
      toViewModel({
        agreement: { data, error, fetching },
      }),
    [data, error, fetching]
  );

  useNotFoundOnPredicate({
    predicate: !viewModel?.data && !viewModel?.isLoading,
  });

  return {
    viewModel,
  };
};

export default useGetCandidateNegotiationAgreement;
