import { gql } from 'urql';

const RecruitmentOpportunityOutsourcingConditionsFragment = gql`
  fragment RecruitmentOpportunityOutsourcingConditionsFragment on RequestOutsourcingConditions {
    maximumDurationValue
    maximumDurationDisplay
    exclusivityPeriodValue
    exclusivityPeriodDisplay
    maximumBudgetValue
    maximumBudgetDisplay
    responsibilities
  }
`;

export default RecruitmentOpportunityOutsourcingConditionsFragment;
