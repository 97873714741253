import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, {
  ClassNamesConfig,
  ControlProps,
  GroupBase,
  OptionProps,
  SelectComponentsConfig,
} from 'react-select';
import AsyncReactSelect from 'react-select/async';

import { cn } from '@utils/lib-utils';

import {
  ClearIndicator,
  DropdownIndicator,
  LoadingIndicator,
  LoadingMessage,
  Menu,
  MultiOption,
  MultiValue,
  MultiValueRemove,
  NoOptionsMessage,
  Option,
  Placeholder,
} from './components';
import {
  CONTROL_STYLES,
  DROPDOWN_INDICATOR_STYLES,
  INDICATORS_CONTAINER_STYLES,
  MENU_STYLES,
  MULTI_VALUE_LABEL_STYLES,
  MULTI_VALUE_REMOVE_STYLES,
  MULTI_VALUE_STYLES,
  OPTION_STYLES,
  PLACEHOLDER_STYLES,
  SELECT_INPUT_STYLES,
  SINGLE_VALUE_STYLES,
  VALUE_CONTAINER_STYLES,
} from './constants';
import { OptionType, SelectProps } from './types';

const Select = ({
  options,
  id,
  name,
  value,
  placeholder,
  defaultValue,
  cacheOptions,
  defaultOptions,
  isLoading,
  loadOptions,
  clearable,
  searchable = false,
  multiple,
  disabled,
  autoFocus,
  invalid,
  limitTags,
  className: customClassName,
  controlClassName: customControlClassName,
  hideDropdownIndicator,
  hideNoOptions,
  menuPlacement,
  smallMenu,
  renderNoOptionsContent,
  renderLoadingContent,
  renderMenuFooterContent,
  onInputChange,
  onChange,
  onFocus,
  onBlur,
  ...rest
}: SelectProps) => {
  const { t } = useTranslation();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const handleMenuOpen = () => setMenuOpened(true);
  const handleMenuClose = () => setMenuOpened(false);

  const components: SelectComponentsConfig<
    OptionType,
    boolean,
    GroupBase<OptionType>
  > = {
    DropdownIndicator,
    Placeholder,
    NoOptionsMessage,
    LoadingMessage,
    Menu: Menu(renderMenuFooterContent),
    MultiValue: multiple ? MultiValue(limitTags) : undefined,
    MultiValueRemove: multiple ? MultiValueRemove : undefined,
    Option: multiple ? MultiOption : Option,
    ClearIndicator: clearable ? ClearIndicator : undefined,
    IndicatorSeparator: () => null,
  };

  const classNames: ClassNamesConfig<
    OptionType,
    boolean,
    GroupBase<OptionType>
  > = {
    container: () => cn(customClassName),
    control: ({
      isFocused,
      isDisabled,
    }: ControlProps<OptionType, boolean, GroupBase<OptionType>>) =>
      cn(
        CONTROL_STYLES.base,
        isFocused ? CONTROL_STYLES.focus : CONTROL_STYLES.nonFocus,
        invalid && CONTROL_STYLES.invalid,
        isDisabled && CONTROL_STYLES.disabled,
        multiple && CONTROL_STYLES.multiple,
        customControlClassName
      ),
    input: () => SELECT_INPUT_STYLES,
    placeholder: () => PLACEHOLDER_STYLES,
    valueContainer: () => VALUE_CONTAINER_STYLES,
    singleValue: () => SINGLE_VALUE_STYLES,
    multiValue: () => (menuOpened ? '!hidden' : MULTI_VALUE_STYLES),
    multiValueLabel: () => MULTI_VALUE_LABEL_STYLES,
    multiValueRemove: () => MULTI_VALUE_REMOVE_STYLES,
    indicatorsContainer: () => INDICATORS_CONTAINER_STYLES,
    dropdownIndicator: () =>
      hideDropdownIndicator ? 'invisible' : DROPDOWN_INDICATOR_STYLES,
    menu: () => MENU_STYLES,
    menuList: () => (smallMenu ? '[&&]:max-h-[200px]' : ''),
    option: ({
      isFocused,
      isSelected,
    }: OptionProps<OptionType, boolean, GroupBase<OptionType>>) =>
      cn(
        OPTION_STYLES.base,
        isFocused && OPTION_STYLES.focus,
        isSelected && OPTION_STYLES.selected,
        multiple && OPTION_STYLES.multiple
      ),
  };

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) =>
    hideNoOptions && !inputValue
      ? null
      : renderNoOptionsContent || t('select.states.empty.noOptionsFound');

  const defaultPlaceholder = searchable
    ? t('select.placeholders.enterSelect')
    : t('select.placeholders.default');

  const commonProps = {
    unstyled: true,
    inputId: id,
    name,
    value,
    autoFocus,
    isDisabled: disabled,
    isClearable: clearable || !multiple,
    isMulti: multiple,
    closeMenuOnSelect: multiple && false,
    hideSelectedOptions: multiple && false,
    onInputChange,
    onChange,
    onFocus,
    onBlur,
    menuPlacement: menuPlacement || 'auto',
    menuPortalTarget: document.body,
    styles: { menuPortal: (base: any) => ({ ...base, zIndex: 1000 }) },
    defaultValue,
    noOptionsMessage,
    loadingMessage: () =>
      renderLoadingContent || t('select.states.loading.default'),
    placeholder: placeholder || defaultPlaceholder,
    classNames,
    onMenuOpen: handleMenuOpen,
    onMenuClose: handleMenuClose,
    isSearchable: multiple ? menuOpened : searchable,
  };

  // Use react-select Async component when using loadOptions props
  if (loadOptions) {
    return (
      <AsyncReactSelect
        {...commonProps}
        components={{
          ...components,
          LoadingIndicator,
        }}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        cacheOptions={cacheOptions}
        isLoading={isLoading}
        {...rest}
      />
    );
  }

  // Use default react-select component otherwise
  return (
    <ReactSelect
      {...commonProps}
      components={components}
      options={options}
      {...rest}
    />
  );
};

export default Select;
