import { useEffect } from 'react';

type UseNotFoundOnPredicateProps = {
  predicate: boolean;
};

const useNotFoundOnPredicate = ({ predicate }: UseNotFoundOnPredicateProps) => {
  useEffect(() => {
    if (predicate) {
      window.dispatchEvent(new Event('kehops-not-found'));
    }
  }, [predicate]);
};

export default useNotFoundOnPredicate;
