import { gql } from 'urql';

const CandidateOfferOutsourcingConditionsFragment = gql`
  fragment CandidateOfferOutsourcingConditionsFragment on CandidateOfferOutsourcingConditions {
    minimumNotice
    billingPeriod
  }
`;

export default CandidateOfferOutsourcingConditionsFragment;
