import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Account, AccountResponse } from '@domain/graphql.types';

type ToViewModel = {
  account: {
    data: AccountResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  account: { data, fetching, error },
}: ToViewModel): ViewModel<Account | undefined> =>
  new ViewModel<Account | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.account || undefined,
  });
