import { useMutation } from 'urql';

import {
  Mutation,
  MutationOfferCandidateDeleteArgs,
} from '@domain/graphql.types';
import { MutationOfferCandidateDelete } from '@infrastructure/graphql/offer';

const useOfferCandidateDelete = () => {
  const [{ data, fetching, error }, deleteOfferCandidate] = useMutation<
    Pick<Mutation, 'offerCandidateDelete'>,
    MutationOfferCandidateDeleteArgs
  >(MutationOfferCandidateDelete);

  return {
    deleteOfferCandidate,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateDelete;
