import i18next, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { enTranslations, frTranslations } from '@application/translations';
import {
  candidatesEn,
  candidatesFr,
} from '@application/views/candidate/translations';
import {
  dashboardEn,
  dashboardFr,
} from '@application/views/dashboard/translations';
import {
  invitationEn,
  invitationFr,
} from '@application/views/invitation/translations';
import {
  operationUnitEn,
  operationUnitFr,
} from '@application/views/organization/operation-unit/translations';
import {
  organizationEn,
  organizationFr,
} from '@application/views/organization/translations';
import {
  offersEn,
  offersFr,
} from '@application/views/recruitment/offer/translations';
import {
  requestsEn,
  requestsFr,
} from '@application/views/recruitment/request/translations';
import {
  recruitmentEn,
  recruitmentFr,
} from '@application/views/recruitment/translations';
import { signupEn, signupFr } from '@application/views/signup/translations';
import { userEn, userFr } from '@application/views/user/translations';

const resources = {
  en: {
    candidates: candidatesEn,
    dashboard: dashboardEn,
    invitation: invitationEn,
    offers: offersEn,
    operationUnit: operationUnitEn,
    organization: organizationEn,
    recruitment: recruitmentEn,
    requests: requestsEn,
    signup: signupEn,
    translation: enTranslations,
    user: userEn,
  },
  fr: {
    candidates: candidatesFr,
    dashboard: dashboardFr,
    invitation: invitationFr,
    offers: offersFr,
    operationUnit: operationUnitFr,
    organization: organizationFr,
    recruitment: recruitmentFr,
    requests: requestsFr,
    signup: signupFr,
    translation: frTranslations,
    user: userFr,
  },
};

use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'fr',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
