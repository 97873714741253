import { gql } from 'urql';

const AccountCreate = gql`
  mutation AccountCreate($accountCreateInput: AccountCreateInput!) {
    accountCreate(accountCreateInput: $accountCreateInput) {
      account {
        name
        type
        address
        website
        phoneNumber
        industryTypeCode
      }
    }
  }
`;

export default AccountCreate;
