import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import { UseQueryExecute } from 'urql';

import {
  Button,
  ButtonLink,
  ConfirmationModal,
  Stack,
} from '@application/components';
import { ModalContext, NavContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { Offer, OfferStatusTypeCode, Request } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { useRejectOffers } from '../hooks';

type ActionMenuProps = {
  checkedOffers: string[];
  comparing?: boolean;
  request: Request;
  offers: readonly Offer[];
  onInviteClicked?: () => void;
  onComparisonClicked: () => void;
  onDeselectAll: () => void;
  refreshRequestOffers: UseQueryExecute;
};

const ActionMenu = ({
  checkedOffers,
  comparing,
  request,
  offers,
  onInviteClicked,
  onComparisonClicked,
  onDeselectAll,
  refreshRequestOffers,
}: ActionMenuProps) => {
  const { t } = useTranslation('offers', {
    keyPrefix: 'comparison.menu',
  });
  const { setModal } = useContext(ModalContext);
  const { isNavOpen, setIsBottomMenuOpen } = useContext(NavContext);

  const location = useLocation();

  const { rejectOffers } = useRejectOffers();

  const getOfferDetailsPath = useCallback(
    (offerId: string) =>
      offerId
        ? generatePath(PrivatePage.OFFER_DETAILS, {
            id: request?.id || null,
            offerId,
          })
        : '#',
    [request?.id]
  );

  const getAccountNameList = useCallback(
    (ids: string[]) => {
      const filteredOffers = offers
        ?.filter((o) => ids.includes(o.id))
        .map((o) => o.account.name);

      return filteredOffers?.join('\n') || [];
    },
    [offers]
  );

  const acceptedOffer = useMemo(
    () => offers.find((offer) => offer.status === OfferStatusTypeCode.Accepted),
    [offers]
  );

  const negotiationId = useMemo(
    () => request?.recruitmentNegotiation?.id,
    [request?.recruitmentNegotiation?.id]
  );

  const noSelectedOffersInNegoRoom = useMemo(
    () =>
      !offers.some((o) => checkedOffers.includes(o.id) && o.negotiationRoomId),
    [checkedOffers, offers]
  );

  const handleRejectOffers = useCallback(
    (ids: string[]) => async () => {
      setModal(null);

      const result = await rejectOffers({ input: { ids } });

      if (result.data?.offersReject.offers) {
        onDeselectAll();
        refreshRequestOffers();
      }
    },
    [onDeselectAll, rejectOffers, refreshRequestOffers, setModal]
  );

  const confirmOffersRejection = useCallback(
    (ids: string[]) => async () => {
      setModal({
        title: t('actions.rejectOffersTitle', { count: checkedOffers.length }),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p className="whitespace-pre-line">
                  <Trans
                    i18nKey="actions.rejectOffersMessage"
                    t={t}
                    values={{
                      count: checkedOffers.length,
                      agencies: getAccountNameList(ids),
                    }}
                  />
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={handleRejectOffers(ids)}
          />
        ),
      });
    },
    [setModal, t, checkedOffers.length, getAccountNameList, handleRejectOffers]
  );

  const paddingLeft = useMemo(
    () => (isNavOpen ? 'lg:pl-[22rem]' : 'lg:pl-[9rem]'),
    [isNavOpen]
  );

  useEffect(() => {
    setIsBottomMenuOpen(checkedOffers.length >= 1);
  }, [checkedOffers, setIsBottomMenuOpen]);

  return (
    <div
      className={cn(
        'fixed bottom-s-0 right-s-0 bg-base-100 shadow-lg px-s-32 py-s-16 w-full min-h-[4.5rem] hidden',
        {
          block: checkedOffers.length >= 1 || acceptedOffer,
        },
        paddingLeft
      )}
    >
      {acceptedOffer ? (
        <div className="flex gap-s-16 justify-end">
          <ButtonLink
            size="small"
            className="py-s-0"
            icon={<i className="ri-file-text-line text-16 font-normal" />}
            to={getOfferDetailsPath(acceptedOffer.id)}
          >
            {t('offerDetails')}
          </ButtonLink>
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex items-center gap-s-16">
            <span>{t('offersCount', { count: checkedOffers.length })}</span>
            {!comparing && (
              <Button size="small" ghost onClick={onDeselectAll}>
                {t('deselectOffer', { count: checkedOffers.length })}
              </Button>
            )}
          </div>

          <div className="flex flex-col lg:flex-row gap-s-16">
            {/* IF the offer is not in negoroom -> Show the invite button */}
            <Button
              size="small"
              primary
              icon={
                <i className="ri-shake-hands-line mr-s-4 text-16 font-normal" />
              }
              className={
                !negotiationId || noSelectedOffersInNegoRoom
                  ? 'py-s-0'
                  : 'hidden'
              }
              onClick={onInviteClicked}
            >
              {t('inviteToNegoRoom')}
            </Button>

            {/* IF we only have one offer checked AND there's an ongoing negotiation -> Show the Go to the NegoRoom button */}
            <ButtonLink
              size="small"
              primary
              className={
                !noSelectedOffersInNegoRoom
                  ? 'py-s-0 flex items-center'
                  : 'hidden'
              }
              icon={
                <i className="ri-shake-hands-line mr-s-4 text-16 font-normal" />
              }
              to={generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
                id: negotiationId || '#',
              })}
              from={location.pathname}
            >
              {t('goToNegoRoom')}
            </ButtonLink>

            {/* IF we only have ONE offer checked -> Show the offer details button */}
            <ButtonLink
              size="small"
              icon={<i className="ri-file-text-line text-16 font-normal" />}
              className={
                checkedOffers.length === 1
                  ? 'flex items-center py-s-0'
                  : 'hidden'
              }
              to={getOfferDetailsPath(checkedOffers[0])}
            >
              {t('offerDetails')}
            </ButtonLink>

            {/* IF there is only one offer checked AND the offer has no nego room -> Show the decline offer button */}
            <Button
              size="small"
              icon={<i className="ri-prohibited-line text-16 font-normal" />}
              className={noSelectedOffersInNegoRoom ? 'py-s-0' : 'hidden'}
              onClick={confirmOffersRejection(checkedOffers)}
            >
              {t('declineOffers')}
            </Button>

            {/* IF there's more than one offer checked -> Show the comparison button */}
            {/* IF we are currently comparing offers -> Change the text to cancel selection */}
            <Button
              className={cn({
                hidden:
                  checkedOffers.length < 2 ||
                  checkedOffers.length === offers.length,
              })}
              size="small"
              onClick={onComparisonClicked}
            >
              {t(`${comparing ? 'cancelSelection' : 'compareOffers'}`)}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
