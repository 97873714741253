import { GetOfferInput } from '@domain/graphql.types';
import { useOfferAccountName } from '@domain/offer';

import { toViewModel } from '../view-models/get-offer-account-name.vm';

const useGetOfferAccountName = (input: GetOfferInput) => {
  const { data, fetching, error } = useOfferAccountName({
    input,
  });

  const viewModel = toViewModel({
    requests: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
  };
};

export default useGetOfferAccountName;
