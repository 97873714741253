import { RootPrivatePage } from '@application/enums/pagesUrl';

// parameters commented because not implemented yet
export const USER_NAV_LINKS = [
  {
    label: 'profile',
    icon: 'ri-user-line',
    url: RootPrivatePage.USER,
    isAppLink: true,
  },
  {
    label: 'organization',
    icon: 'ri-building-4-line',
    url: RootPrivatePage.ORGANIZATION,
    isAppLink: true,
  },
  // {
  //   label: 'parameters',
  //   icon: 'ri-settings-3-line',
  //   isAppLink: true,
  // },
  {
    label: 'logout',
    icon: 'ri-logout-box-r-line',
    isAppLink: false,
  },
];
