import { useMutation } from 'urql';

import {
  Mutation,
  MutationGetSubscriptionBillingPortalLinkArgs,
} from '@domain/graphql.types';
import GetSubscriptionLink from '@infrastructure/graphql/billing/get.subscriptionLink';

const useGetSubscriptionBillingPortalLink = () => {
  const [result, executeMutation] = useMutation<
    Pick<Mutation, 'getSubscriptionBillingPortalLink'>,
    MutationGetSubscriptionBillingPortalLinkArgs
  >(GetSubscriptionLink);

  const { data, fetching, error } = result;

  return {
    data: data?.getSubscriptionBillingPortalLink,
    fetching,
    error,
    executeMutation,
  };
};

export default useGetSubscriptionBillingPortalLink;
