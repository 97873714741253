import { useTranslation } from 'react-i18next';

import { SectionGridRow, VisibilitySwitcher } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Offer } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type TemporaryPlacementConditionsProps = {
  data: Offer | undefined;
};

const TemporaryPlacementConditions = ({
  data,
}: TemporaryPlacementConditionsProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tEnum } = useTranslation('offers', { keyPrefix: 'enum' });
  const { t: tGlobal } = useTranslation();

  const recruitmentOpportunity = data?.recruitmentOpportunity;
  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.temporaryPlacementContractualConditions;
  const conditions = data?.temporaryPlacementContractualConditions;

  const wrapperClassName = 'flex flex-wrap items-center gap-s-8';
  const valueTextClassName = 'text-18 font-semibold text-primary';

  return (
    <>
      <SectionGridRow
        isTopRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="text-16 text-primary font-semibold text-center">
                <div className="mb-s-10">
                  <span className="font-normal text-14">
                    {t('labels.responsibilityTravel')}&nbsp;
                  </span>
                  <PlaceholderSwitcher
                    value={
                      recruitmentOpportunityConditions?.travelExpensesResponsibilityValue
                    }
                  >
                    <p className={cn(valueTextClassName, 'text-16')}>
                      {tEnum(
                        `requestConditionResponsibility.${recruitmentOpportunityConditions?.travelExpensesResponsibilityValue?.toLowerCase()}`
                      )}
                    </p>
                  </PlaceholderSwitcher>
                </div>

                <div className="mb-s-10">
                  <span className="font-normal text-14">
                    {t('labels.responsibilityTraining')}&nbsp;
                  </span>
                  <PlaceholderSwitcher
                    value={
                      recruitmentOpportunityConditions?.trainingExpensesResponsibilityValue
                    }
                  >
                    <p className={cn(valueTextClassName, 'text-16')}>
                      {tEnum(
                        `requestConditionResponsibility.${recruitmentOpportunityConditions?.trainingExpensesResponsibilityValue?.toLowerCase()}`
                      )}
                    </p>
                  </PlaceholderSwitcher>
                </div>

                <div className="mb-s-10">
                  <span className="font-normal text-14">
                    {t('labels.responsibilityEmployeeHelpProgram')}&nbsp;
                  </span>
                  <PlaceholderSwitcher
                    value={
                      recruitmentOpportunityConditions?.employeeHelpProgramResponsibilityValue
                    }
                  >
                    <p className={cn(valueTextClassName, 'text-16')}>
                      {tEnum(
                        `requestConditionResponsibility.${recruitmentOpportunityConditions?.employeeHelpProgramResponsibilityValue?.toLowerCase()}`
                      )}
                    </p>
                  </PlaceholderSwitcher>
                </div>

                <div className="mb-s-10">
                  <span className="font-normal text-14">
                    {t('labels.responsibilityCnesst')}&nbsp;
                  </span>
                  <PlaceholderSwitcher
                    value={
                      recruitmentOpportunityConditions?.cnesstExpensesResponsibilityValue
                    }
                  >
                    <p className={cn(valueTextClassName, 'text-16')}>
                      {tEnum(
                        `requestConditionResponsibility.${recruitmentOpportunityConditions?.cnesstExpensesResponsibilityValue?.toLowerCase()}`
                      )}
                    </p>
                  </PlaceholderSwitcher>
                </div>
              </div>
            }
            visible
          />
        }
      >
        <div className="flex flex-wrap items-center gap-s-16">
          <p>{t('labels.responsibilities')}</p>
          <div>
            <ul className="list-disc pl-s-16">
              <li>
                {t('labels.travelCosts')}&nbsp;
                <PlaceholderSwitcher
                  value={conditions?.travelExpensesResponsibility}
                >
                  <span className="text-18 font-semibold text-primary">
                    {tEnum(
                      `requestConditionResponsibility.${conditions?.travelExpensesResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
              <li>
                {t('labels.trainingCosts')}&nbsp;
                <PlaceholderSwitcher
                  value={conditions?.trainingExpensesResponsibility}
                >
                  <span className="text-18 font-semibold text-primary">
                    {tEnum(
                      `requestConditionResponsibility.${conditions?.trainingExpensesResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
              <li>
                {t('labels.employeeHelpProgram')}&nbsp;
                <PlaceholderSwitcher
                  value={conditions?.employeeHelpProgramResponsibility}
                >
                  <span className="text-18 font-semibold text-primary">
                    {tEnum(
                      `requestConditionResponsibility.${conditions?.employeeHelpProgramResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
              <li>
                {t('labels.cnesst')}&nbsp;
                <PlaceholderSwitcher
                  value={conditions?.cnesstExpensesResponsibility}
                >
                  <span className="text-18 font-semibold text-primary">
                    {tEnum(
                      `requestConditionResponsibility.${conditions?.cnesstExpensesResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>
            </ul>
          </div>
        </div>
      </SectionGridRow>

      {/* <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={
                  recruitmentOpportunityConditions?.otherResponsibilitiesValue
                }
              >
                <span className={valueTextClassName}>
                  {recruitmentOpportunityConditions?.otherResponsibilitiesValue}
                </span>
              </PlaceholderSwitcher>
            }
            visible
          />
        }
      >
        <span className={wrapperClassName}>
          <span>{t(`labels.otherResponsibilities`)}</span>

          <PlaceholderSwitcher value={conditions?.otherResponsibilities}>
            <span className={valueTextClassName}>
              {conditions?.otherResponsibilities}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow> */}

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={recruitmentOpportunityConditions?.foreignApplicantsValue}
              >
                <span className={cn(valueTextClassName, 'text-16')}>
                  {tGlobal(
                    `boolean.${
                      recruitmentOpportunityConditions?.foreignApplicantsValue
                        ?.toString()
                        .toLowerCase() as string
                    }`
                  )}
                </span>
              </PlaceholderSwitcher>
            }
            visible={
              !!recruitmentOpportunityConditions?.foreignApplicantsDisplay
            }
          />
        }
      >
        <span className={wrapperClassName}>
          <span>{t(`labels.foreignCandidates`)}</span>

          <PlaceholderSwitcher value={conditions?.foreignApplicants}>
            <span className={valueTextClassName}>
              {tGlobal(
                `boolean.${
                  conditions?.foreignApplicants
                    ?.toString()
                    .toLowerCase() as string
                }`
              )}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={recruitmentOpportunityConditions?.possibleBuybackValue}
              >
                <span className={cn(valueTextClassName, 'text-16')}>
                  {tGlobal(
                    `boolean.${
                      recruitmentOpportunityConditions?.possibleBuybackValue
                        ?.toString()
                        .toLowerCase() as string
                    }`
                  )}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!recruitmentOpportunityConditions?.possibleBuybackDisplay}
          />
        }
      >
        <span className={wrapperClassName}>
          <span>{t(`labels.buyback`)}</span>

          <PlaceholderSwitcher value={conditions?.possibleBuyback}>
            <span className={valueTextClassName}>
              {tGlobal(
                `boolean.${
                  conditions?.possibleBuyback
                    ?.toString()
                    .toLowerCase() as string
                }`
              )}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      {/* <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={recruitmentOpportunityConditions?.risksValue}
              >
                <span className={cn(valueTextClassName, 'text-16')}>
                  {recruitmentOpportunityConditions?.risksValue}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!recruitmentOpportunityConditions?.risksDisplay}
          />
        }
      >
        <span className={wrapperClassName}>
          <span>{t(`labels.risks`)}</span>

          <PlaceholderSwitcher value={conditions?.risks}>
            <span className={valueTextClassName}>{conditions?.risks}</span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow> */}
    </>
  );
};

export default TemporaryPlacementConditions;
