import { gql } from 'urql';

const CandidateNegotiationOutsourcingPropertiesStateFragment = gql`
  fragment CandidateNegotiationOutsourcingPropertiesStateFragment on CandidateNegotiationOutsourcingPropertiesState {
    billingPeriod
    minimumNotice
  }
`;

export default CandidateNegotiationOutsourcingPropertiesStateFragment;
