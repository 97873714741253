import { gql } from '@urql/core';

const MutationRecruitmentNegotiationRoomReject = gql`
  mutation RecruitmentNegotiationRoomReject(
    $input: RecruitmentNegotiationRoomInput!
  ) {
    recruitmentNegotiationRoomReject(input: $input) {
      room {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationRoomReject;
