import { gql } from '@urql/core';

const MutationCandidateNegotiationRoomRejectValues = gql`
  mutation CandidateNegotiationRoomRejectValues(
    $input: CandidateNegotiationStateUpdateInput!
  ) {
    candidateNegotiationRoomRejectValues(input: $input) {
      room {
        id
      }
    }
  }
`;

export default MutationCandidateNegotiationRoomRejectValues;
