import { gql } from '@urql/core';

const MutationUpdateCandidateAndOtherFiles = gql`
  mutation UpdateCandidateAndOtherFiles(
    $input: OfferCandidateUpdateInput!
    $otherFilesInput: OfferCandidateOtherFilesInput!
    $files: [Upload!]!
  ) {
    offerCandidateUpdateOtherFiles(input: $otherFilesInput, files: $files) {
      ok
    }
    offerCandidateUpdate(input: $input) {
      offerCandidate {
        id
      }
    }
  }
`;

export default MutationUpdateCandidateAndOtherFiles;
