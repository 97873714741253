import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Fieldset, FormInputWithSuffix, Radio } from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import {
  OfferConditionPaymentFrequencyCode,
  OfferConditionPaymentMethodCode,
  OfferConditionStartOfAgreementPaymentMethodCode,
} from '@domain/graphql.types';

import { CandidateOfferFormFields } from '../../schema';

type PaymentMethodsFieldsProps = {
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  register: UseFormRegister<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  trigger: UseFormTrigger<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const PaymentMethodsFields = ({
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: PaymentMethodsFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const paymentMethod = watch('recruitmentConditionPaymentMethod');
  const startOfAgreementValue = watch(
    'recruitmentConditionStartOfAgreementPaymentMethod'
  );
  const paymentFrequency = watch('recruitmentConditionPaymentFrequency');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateOfferFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <Fieldset legend={t('labels.preferredPaymentMethod')} legendSize="medium">
      <Controller
        name="recruitmentConditionPaymentMethod"
        control={control}
        render={({ field: { name } }) => (
          <Cluster space={16} as="ul">
            {Object.values(OfferConditionPaymentMethodCode).map((method) => (
              <li key={method}>
                <Radio
                  name={name}
                  value={method}
                  label={t(
                    `enum.offerConditionPaymentMethodCode.${method.toLowerCase()}`
                  )}
                  onChange={(e) => {
                    handleOnRadioChange(
                      e as ChangeEvent<HTMLInputElement>,
                      name
                    );
                    setValue(
                      'recruitmentConditionStartOfAgreementPaymentMethod',
                      undefined
                    );
                    setValue('recruitmentConditionPaymentAmount', undefined);
                    setValue('recruitmentConditionDeposit', undefined);
                    setValue('recruitmentConditionPaymentFrequency', undefined);
                    trigger(name);
                  }}
                  checked={paymentMethod === method}
                  asButton
                />
              </li>
            ))}
          </Cluster>
        )}
      />

      {paymentMethod === OfferConditionPaymentMethodCode.StartOfAgreement && (
        <Controller
          name="recruitmentConditionStartOfAgreementPaymentMethod"
          control={control}
          render={({ field: { name } }) => (
            <Cluster space={16} as="ul" className="mt-s-16">
              {Object.values(
                OfferConditionStartOfAgreementPaymentMethodCode
              ).map((method) => (
                <li key={method}>
                  <Radio
                    name={name}
                    value={method}
                    label={t(
                      `enum.offerConditionStartOfAgreementPaymentMethodCode.${method.toLowerCase()}`
                    )}
                    onChange={(e) => {
                      handleOnRadioChange(
                        e as ChangeEvent<HTMLInputElement>,
                        name
                      );
                      trigger(name);
                    }}
                    checked={startOfAgreementValue === method}
                    asButton
                  />
                </li>
              ))}
            </Cluster>
          )}
        />
      )}

      {startOfAgreementValue ===
        OfferConditionStartOfAgreementPaymentMethodCode.AmountWanted && (
        <FormInputWithSuffix
          type="number"
          label={t('labels.amountWanted')}
          minValue={1}
          invalid={!!errors.recruitmentConditionPaymentAmount}
          helperText={
            errors.recruitmentConditionPaymentAmount?.message &&
            tGlobal(errors.recruitmentConditionPaymentAmount?.message, {
              min: '1',
            })
          }
          {...register('recruitmentConditionPaymentAmount')}
        />
      )}

      {startOfAgreementValue ===
        OfferConditionStartOfAgreementPaymentMethodCode.Deposit && (
        <FormInputWithSuffix
          type="number"
          minValue={1}
          maxValue={99999999.99}
          step={0.01}
          invalid={!!errors.recruitmentConditionDeposit}
          helperText={
            errors.recruitmentConditionDeposit?.message &&
            tGlobal(errors.recruitmentConditionDeposit?.message, {
              max: '99,999,999.99',
              min: '1',
            })
          }
          label={t('labels.maxDepositAmount')}
          {...register('recruitmentConditionDeposit')}
        />
      )}

      {paymentMethod === OfferConditionPaymentMethodCode.StartOfAgreement &&
        startOfAgreementValue ===
          OfferConditionStartOfAgreementPaymentMethodCode.PaymentFrequency && (
          <Controller
            name="recruitmentConditionPaymentFrequency"
            control={control}
            render={({ field: { name } }) => (
              <Cluster space={16} as="ul" className="mt-s-16">
                {Object.values(OfferConditionPaymentFrequencyCode).map(
                  (method) => (
                    <li key={method}>
                      <Radio
                        name={name}
                        value={method}
                        label={t(
                          `enum.offerConditionPaymentFrequencyCode.${method.toLowerCase()}`
                        )}
                        asButton
                        onChange={(e) => {
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          );
                          trigger(name);
                        }}
                        checked={paymentFrequency === method}
                      />
                    </li>
                  )
                )}
              </Cluster>
            )}
          />
        )}
    </Fieldset>
  );
};

export default PaymentMethodsFields;
