import { Trans, useTranslation } from 'react-i18next';

import KehopsLogo from '@application/assets/logo.svg?react';
import { Button, Modal } from '@application/components';

export type PromoModalProps = {
  closeModal: () => void;
  onConfirm: () => void;
};

const ActionPromoModal = ({ closeModal, onConfirm }: PromoModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.actionPromo',
  });

  return (
    <>
      <Modal.CloseBtn onClick={closeModal} />
      <div className="flex flex-col items-center">
        <KehopsLogo className="text-center min-w-s-96 min-h-s-96" />
        <div className="mb-s-8 text-18 font-semibold">
          {t('modalPayingPublication')}
        </div>
        <div className="text-18 text-center text-wrap">
          <Trans i18nKey="modalContent" t={t} />
        </div>
        <div className="mb-s-24 mt-s-24 text-12 text-left">
          {t('modalSubtext')}
        </div>
      </div>

      <Modal.Action>
        <Button onClick={closeModal}>{t('modalCancel')}</Button>
        <Button primary onClick={onConfirm}>
          {t('modalConfirm')}
        </Button>
      </Modal.Action>
    </>
  );
};

export default ActionPromoModal;
