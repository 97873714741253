import { ChangeEvent, FocusEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@application/components/buttons';
import { cn } from '@utils/lib-utils';
import { clamp } from '@utils/math-utils';

import { TEXT_INPUT_STYLES } from './constants';

export type TextInputType =
  | 'text'
  | 'email'
  | 'password'
  | 'number'
  | 'url'
  | 'tel'
  | 'date';

export type TextInputProps = {
  /**
   * Specify the type <input> element to display
   */
  type?: TextInputType;
  /**
   * Specify a custom `id` for the `<input>`
   */
  id: string;
  /**
   * Specify the name of the `<input>`
   */
  name: string;
  /**
   * Specify the value of the `<input>`
   */
  value?: string | number | undefined;
  /**
   * Specify the placeholder attribute for the `<input>`
   */
  placeholder?: string;
  /**
   *  Specify the maximum of character allowed for the `<input>`
   */
  maxChar?: number;
  /**
   * Specify the minimum allowed value for the `<input>`
   */
  minValue?: number | string | undefined;
  /**
   * Specify the maximum allowed value for the `<input>`
   */
  maxValue?: number | string | undefined;
  /**
   * Specify whether the `<input>` should be disabled
   */
  disabled?: boolean;
  /**
   * Specify wether the `<input>` should be invalid
   */
  invalid?: boolean;
  /**
   * Provide a custom className to be applied to the `<input>`
   */
  className?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optionally provide an `onBlur` handler that is called whenever `<input>` loses focus
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Specify what it the step for the input type number
   */
  step?: number;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

const TextInput = ({
  type = 'text',
  id,
  name,
  value,
  minValue = 0,
  maxValue = 9999,
  placeholder,
  maxChar,
  disabled,
  invalid,
  className: customClassName,
  readOnly,
  onChange,
  onBlur,
  step = 1,
  ...rest
}: TextInputProps) => {
  const { t } = useTranslation();

  const inputElementRef = useRef<HTMLInputElement | null>(null);

  const className = cn(
    TEXT_INPUT_STYLES.base,
    customClassName,
    invalid && TEXT_INPUT_STYLES.invalid,
    disabled && TEXT_INPUT_STYLES.disabled,
    disabled && invalid && TEXT_INPUT_STYLES.disabledInvalid,
    readOnly && TEXT_INPUT_STYLES.readonly
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      const newValue = parseFloat(e.target.value);
      if (!Number.isNaN(newValue)) {
        e.target.value = clamp(
          newValue,
          parseFloat(minValue as string),
          parseFloat(maxValue as string)
        ).toString();
      }
    }

    if (onChange) {
      onChange(e);
    }
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (type === 'text' || type === 'tel') {
      e.target.value = e.target.value.trim();
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnClick = () => {
    if (type === 'date') {
      const inputElement = inputElementRef.current;
      if (inputElement && typeof inputElement.showPicker === 'function') {
        inputElement.showPicker();
      }
    }
  };

  const renderInput = () => (
    <input
      ref={inputElementRef}
      type={type}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      maxLength={maxChar}
      disabled={disabled}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      className={className}
      readOnly={readOnly}
      min={minValue}
      max={maxValue}
      step={step}
      {...rest}
    />
  );

  if (type === 'date') {
    return (
      <div
        className={cn('flex-1', { 'relative w-full': type === 'date' })}
        onFocus={handleOnClick}
      >
        {renderInput()}

        <IconButton
          onClick={handleOnClick}
          ghost
          size="x-small"
          icon={<i className="text-20 ri-calendar-2-line" />}
          text={t('button.datePicker')}
          disabled={disabled}
          className="absolute top-[0.5rem] right-[1rem]"
        />
      </div>
    );
  }

  return <>{renderInput()}</>;
};

export default TextInput;
