import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountContext, AlertContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { RequestErrors } from '@domain/graphql.types';
import { useRequestDelete } from '@domain/request';

import { toViewModel } from '../view-models/delete-request.vm';

const useDeleteRequest = () => {
  const { data, fetching, error, deleteRequest } = useRequestDelete();

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const { t } = useTranslation('requests', { keyPrefix: 'messages' });

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const viewModel = toViewModel({
    requestDelete: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    const associatedOfferError = error.graphQLErrors.find(
      (e) => e.message === RequestErrors.AssociatedOffer
    );

    setAlertContent(
      associatedOfferError
        ? {
            modifier: 'alert-error',
            text: t('hasAssociatedOfferError'),
          }
        : {
            modifier: 'alert-error',
            action: t('deleteError'),
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.requestDelete?.ok) {
      setAlertContent({
        action: t('deleteSuccess'),
        modifier: 'alert-success',
      });

      refreshAccount();

      if (pathname.includes('details')) {
        navigate(RootPrivatePage.REQUESTS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.requestDelete?.ok]);

  return {
    viewModel,
    deleteRequest,
  };
};

export default useDeleteRequest;
