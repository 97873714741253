import { ElementType, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

import { Space } from './types';

type Align = 'start' | 'center' | 'baseline' | 'end';

type Justify = 'start' | 'center' | 'end';

type ClusterProps = {
  /**
   * Specify content elements of the `<Cluster>`
   */
  children: ReactNode;
  /**
   * Specify the HTML element applied to the `<Cluster>` container
   */
  as?: ElementType;
  /**
   * Specify the minimum space between the clustered child elements
   */
  space?: Space;
  /**
   * Specify the align-items value applied to the `<Cluster>`
   */
  align?: Align;
  /**
   * Specify the justify-content value applied to the `<Cluster>`
   */
  justify?: Justify;
  /**
   * Provide a custom className to be applied to the `<Cluster>`
   */
  className?: string;
};

/**
 * The **Cluster** layout suit any groups of elements that differ in length and are liable to wrap.
 *
 * Use the **Cluster** layout to align any groups of horizontally laid out elements
 * to the left or right, or in the center.
 *
 * @see [https://every-layout.dev/](https://every-layout.dev/)
 *
 * @example
 * Default use:
 * <Cluster>
 *   <div><!-- child --></div>
 *   <div><!-- child --></div>
 *   <div><!-- etc --></div>
 * </Cluster>
 *
 * With provided `as`, `space`, `align` and `justify` values:
 *  <Cluster as='ul' space={16} align='start' justify='end'>
 *   <li><!-- child --></li>
 *   <li><!-- child --></li>
 *   <li><!-- etc --></li>
 * </Cluster>
 */
const Cluster = ({
  as: Tag = 'div',
  space = 16,
  align = 'center',
  justify = 'start',
  children,
  className: customClassName,
}: ClusterProps) => {
  const spaceClassName = {
    'gap-s-8': space === 8,
    'gap-s-16': space === 16,
    'gap-s-24': space === 24,
    'gap-s-32': space === 32,
  };

  const alignClassName = {
    'align-start': align === 'start',
    'align-center': align === 'center',
    'align-end': align === 'end',
  };

  const justifyClassName = {
    'justify-start': justify === 'start',
    'justify-center': justify === 'center',
    'justify-end': justify === 'end',
  };

  const className = cn(
    `flex flex-wrap`,
    { ...spaceClassName, ...alignClassName, ...justifyClassName },
    customClassName
  );

  return <Tag className={className}>{children}</Tag>;
};

export default Cluster;
