import { gql } from 'urql';

const AccountDeleteLogo = gql`
  mutation DeleteAccountLogo {
    accountDeleteLogo {
      ok
    }
  }
`;

export default AccountDeleteLogo;
