import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

type ToViewModel = {
  recruitmentNegotiation: {
    data:
      | Pick<Mutation, 'recruitmentNegotiationCreate'>
      | Record<string, never>
      | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  recruitmentNegotiation: { data, fetching, error },
}: ToViewModel): ViewModel<
  | Pick<Mutation, 'recruitmentNegotiationCreate'>
  | Record<string, never>
  | undefined
> =>
  new ViewModel<
    | Pick<Mutation, 'recruitmentNegotiationCreate'>
    | Record<string, never>
    | undefined
  >({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
