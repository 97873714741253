import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

import { CandidateOfferFormFields } from '../schema';

type ToViewModel = {
  candidateOffer: {
    data:
      | Pick<Mutation, 'candidateOfferUpdate'>
      | Record<string, never>
      | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateOffer: { data = {}, fetching, error },
}: ToViewModel): ViewModel<CandidateOfferFormFields> =>
  new ViewModel<CandidateOfferFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data as CandidateOfferFormFields,
  });
