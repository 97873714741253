import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { UserVerifyEmailResponse } from '@domain/graphql.types';

type ToViewModel = {
  verifyEmail: {
    data: UserVerifyEmailResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  verifyEmail: { data, fetching, error },
}: ToViewModel): ViewModel<UserVerifyEmailResponse | undefined> =>
  new ViewModel<UserVerifyEmailResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
