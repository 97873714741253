import { ChangeEvent, useEffect } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Fieldset, HelperText, Radio } from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import {
  RequestConditionPaymentFrequencyCode as PaymentFrequencyCode,
  RequestConditionPaymentMethodCode as PaymentMethodCode,
} from '@domain/graphql.types';

import { RequestFormFields } from '../../../schema';

type PaymentMethodsFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const PaymentMethodsFields = ({
  control,
  errors,
  setValue,
  trigger,
  watch,
}: PaymentMethodsFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const paymentMethod = watch('conditionPaymentMethodValue');
  const paymentFrequency = watch('conditionPaymentFrequencyValue');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof RequestFormFields
  ) => {
    setValue(name, event.target.value as any);
    trigger(name);
  };

  useEffect(() => {
    if (paymentMethod !== PaymentMethodCode.StartOfAgreement) {
      setValue('conditionPaymentFrequencyValue', null, {
        shouldValidate: false,
      });
    }
  }, [paymentMethod, setValue]);

  const fields: (keyof RequestFormFields)[] = [
    'conditionPaymentMethodValue',
    'conditionPaymentFrequencyValue',
  ];

  return (
    <Fieldset
      legend={t('labels.preferredPaymentMethod')}
      legendSize="medium"
      invalid={fields.some((field) => !!errors[field])}
    >
      <Controller
        name="conditionPaymentMethodValue"
        control={control}
        render={({ field: { name } }) => (
          <>
            <Cluster space={16} as="ul">
              {Object.values(PaymentMethodCode).map((method) => (
                <li key={method}>
                  <Radio
                    name={name}
                    value={method}
                    aria-describedby="conditionPaymentMethodValue-error"
                    label={t(
                      `enum.requestConditionPaymentMethodCode.${method.toLowerCase()}`
                    )}
                    onChange={(e) =>
                      handleOnRadioChange(
                        e as ChangeEvent<HTMLInputElement>,
                        name
                      )
                    }
                    checked={paymentMethod === method}
                    asButton
                  />
                </li>
              ))}
            </Cluster>

            {errors.conditionPaymentMethodValue?.message && (
              <HelperText id="conditionPaymentMethodValue-error" invalid>
                {tGlobal(errors.conditionPaymentMethodValue.message)}
              </HelperText>
            )}
          </>
        )}
      />

      {paymentMethod === PaymentMethodCode.StartOfAgreement && (
        <Controller
          name="conditionPaymentFrequencyValue"
          control={control}
          render={({ field: { name } }) => (
            <>
              <Cluster space={16} as="ul" className="mt-s-16">
                {Object.values(PaymentFrequencyCode).map((method) => (
                  <li key={method}>
                    <Radio
                      name={name}
                      value={method}
                      aria-describedby="conditionPaymentFrequencyValue-error"
                      label={t(
                        `enum.requestConditionPaymentFrequencyCode.${method.toLowerCase()}`
                      )}
                      asButton
                      onChange={(e) =>
                        handleOnRadioChange(
                          e as ChangeEvent<HTMLInputElement>,
                          name
                        )
                      }
                      checked={paymentFrequency === method}
                    />
                  </li>
                ))}
              </Cluster>

              {errors.conditionPaymentFrequencyValue?.message && (
                <HelperText id="conditionPaymentFrequencyValue-error" invalid>
                  {tGlobal(errors.conditionPaymentFrequencyValue.message)}
                </HelperText>
              )}
            </>
          )}
        />
      )}
    </Fieldset>
  );
};

export default PaymentMethodsFields;
