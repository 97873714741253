import { CombinedError } from 'urql';

// Utility function to extract error codes from CombinedError
const extractErrorCodes = (
  error: CombinedError | undefined
): { code: string; message: string; error: string | undefined }[] =>
  error?.graphQLErrors.map((e) => ({
    code: e.extensions.code as string,
    message: e.message,
    error: (e.originalError as any)?.error,
  })) || [];

export default extractErrorCodes;
