import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const AccountPreferredSpecialtyListFragment = gql`
  ${DescriptionsFragment}

  fragment AccountPreferredSpecialtyListFragment on AccountPreferredSpecialty {
    id
    specialty {
      descriptions {
        ...DescriptionsFragment
      }
      sector {
        descriptions {
          ...DescriptionsFragment
        }
        industry {
          descriptions {
            ...DescriptionsFragment
          }
        }
      }
    }
  }
`;

export default AccountPreferredSpecialtyListFragment;
