import { useTranslation } from 'react-i18next';

import {
  PlaceholderSwitcher,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';

type OutsourcingConditionsProps = {
  data: RecruitmentOpportunity | undefined;
};

const OutsourcingConditions = ({ data }: OutsourcingConditionsProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });

  return (
    <>
      <SectionGridRow isTopRow>
        <span className="mr-s-8">{t('labels.maxMandateDuration')}</span>

        <VisibilitySwitcher
          visible={
            data?.outsourcingContractualConditions?.maximumDurationDisplay ||
            false
          }
          content={
            <PlaceholderSwitcher
              value={
                data?.outsourcingContractualConditions?.maximumDurationValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {data?.outsourcingContractualConditions?.maximumDurationValue}
                &nbsp;
                {t('suffix.weeks')}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">
          {t('labels.outsourcingExclusivityPeriod')}
        </span>

        <VisibilitySwitcher
          visible={
            data?.outsourcingContractualConditions?.exclusivityPeriodDisplay ||
            false
          }
          content={
            <PlaceholderSwitcher
              value={
                data?.outsourcingContractualConditions?.exclusivityPeriodValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {data?.outsourcingContractualConditions?.exclusivityPeriodValue}
                &nbsp;{t('suffix.weeks')}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.maxCandidateBudget')}</span>

        <VisibilitySwitcher
          visible={
            data?.outsourcingContractualConditions?.maximumBudgetDisplay ||
            false
          }
          content={
            <PlaceholderSwitcher
              value={
                !!data?.outsourcingContractualConditions?.maximumBudgetValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {data?.outsourcingContractualConditions?.maximumBudgetValue}
                {t('suffix.currencyPerHour')}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">
          {t('labels.recruitmentResponsibilities')}
        </span>

        <span className="text-18 font-semibold text-primary">
          {data?.outsourcingContractualConditions?.responsibilities || '-'}
        </span>
      </SectionGridRow>
    </>
  );
};

export default OutsourcingConditions;
