import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { UserErrors } from '@domain/graphql.types';
import { useUserUpdateEmail } from '@domain/user';

import { toViewModel } from '../view-models/update-email.vm';

const useUpdateEmail = () => {
  const {
    data,
    fetching,
    error,
    userUpdateEmail: updateEmail,
  } = useUserUpdateEmail();

  const { t } = useTranslation('user');

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    updateEmail: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (data?.user) {
      setAlertContent({
        action: t('messages.updateEmailSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user, setAlertContent]);

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    const isEmailAlreadyExistsError = errorCodes.some(
      (e) => e.message === UserErrors.EmailAlreadyExists
    );

    setAlertContent(
      isEmailAlreadyExistsError
        ? {
            text: t('messages.emailAlreadyExists'),
            modifier: 'alert-error',
          }
        : { action: t('messages.updateEmailError'), modifier: 'alert-error' }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length, setAlertContent]);

  return {
    viewModel,
    updateEmail,
  };
};

export default useUpdateEmail;
