import { useQuery } from 'urql';

import { Query, QueryCandidateRequestOffersArgs } from '@domain/graphql.types';
import { CandidateRequestOffers } from '@infrastructure/graphql/candidate';

const useCandidateRequestOffers = (input: QueryCandidateRequestOffersArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateRequestOffers'>,
    QueryCandidateRequestOffersArgs
  >({
    query: CandidateRequestOffers,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.candidateRequestOffers,
    fetching,
    error,
    refreshCandidateRequestOffers: reexecuteQuery,
  };
};

export default useCandidateRequestOffers;
