import * as yup from 'yup';

import { AccountIndustryType } from '@domain/graphql.types';

export const CREATE_ACCOUNT_PREFERRED_SPECIALTY_SCHEMA = yup.object({
  specialtyId: yup.string().required('forms.rules.required'),
  industryType: yup
    .mixed<AccountIndustryType>()
    .oneOf(Object.values(AccountIndustryType)),
  industrySector: yup.string(),
});
