import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryExecute } from 'urql';

import { IconButton } from '@application/components/buttons';
import { AccountContext, ModalContext } from '@application/context';
import { usePagination } from '@application/hooks';
import {
  AccountPreferredSpecialtiesFilters,
  AccountPreferredSpecialtiesSortDirective,
  AccountPreferredSpecialty,
  AccountPreferredSpecialtySortBy,
  SortDirection,
} from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { ConfirmationModal } from '../../../../../components/modal';
import useDeleteAccountPreferredSpecialty from './useDeleteAccountPreferredSpecialty';
import useGetAccountPreferredSpecialties from './useGetAccountPreferredSpecialties';

const columnIds = {
  specialtyCode: 'specialtyCode',
  industryCode: 'industryCode',
  sectorCode: 'sectorCode',
  delete: 'delete',
} as const;

const columnHelper = createColumnHelper<AccountPreferredSpecialty>();

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>,
  confirmDelete: (accountPreferredSpecialtyId: string) => () => void
) => [
  columnHelper.accessor(
    (row) => getLocalizedDescription(row.specialty.descriptions, language),
    {
      id: columnIds.specialtyCode,
      cell: (info) => <span>{info.getValue() || '-'}</span>,
      header: () => t('list.specialty'),
      size: 300,
      minSize: 300,
      maxSize: undefined,
    }
  ),
  columnHelper.accessor(
    (row) =>
      getLocalizedDescription(
        row.specialty.sector.industry.descriptions,
        language
      ),
    {
      id: columnIds.industryCode,
      cell: (info) => <span>{info.getValue() || '-'}</span>,
      header: () => t('list.industry'),
      size: 300,
      minSize: 300,
      maxSize: undefined,
    }
  ),
  columnHelper.accessor(
    (row) =>
      getLocalizedDescription(row.specialty.sector.descriptions, language),
    {
      id: columnIds.sectorCode,
      cell: (info) => <span>{info.getValue() || '-'}</span>,
      header: () => t('list.sector'),
      size: 300,
      minSize: 300,
      maxSize: undefined,
    }
  ),
  columnHelper.accessor((row) => row.id, {
    id: columnIds.delete,
    cell: (info) => (
      <IconButton
        text={tGlobal('button.delete')}
        icon={<i className="ri-delete-bin-line text-20" />}
        outline
        size="small"
        onClick={confirmDelete(info.getValue())}
      />
    ),
    header: () => null,
    size: 32,
    minSize: 32,
    maxSize: 32,
  }),
];

const stringSortToEnum = (by: string): AccountPreferredSpecialtySortBy => {
  switch (by) {
    case columnIds.industryCode:
      return AccountPreferredSpecialtySortBy.IndustryCode;
    case columnIds.sectorCode:
      return AccountPreferredSpecialtySortBy.SectorCode;
    default:
      return AccountPreferredSpecialtySortBy.SpecialtyCode;
  }
};

const mapSorting = (
  sorting: SortingState
): AccountPreferredSpecialtiesSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id),
  }));

type UseAccountPreferredSpecialtiesListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<AccountPreferredSpecialty>[];
  count?: number;
  currentPage?: PaginationState;
  isLoading: boolean;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  onSortingChange: OnChangeFn<SortingState>;
  preferredSpecialties: AccountPreferredSpecialty[];
  sorting: SortingState;
  refetchAccountPreferredSpecialties: UseQueryExecute;
};

export const useAccountPreferredSpecialtiesList = (
  filters?: AccountPreferredSpecialtiesFilters
): UseAccountPreferredSpecialtiesListProps => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities',
  });

  const { t: tGlobal } = useTranslation();

  const { refreshAccount } = useContext(AccountContext);
  const { setModal } = useContext(ModalContext);

  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();

  const {
    viewModel: { data, isLoading },
    refetchAccountPreferredSpecialties,
  } = useGetAccountPreferredSpecialties(
    mapSorting(sorting),
    filters,
    pagination
  );

  const { deleteAccountPreferredSpecialty } =
    useDeleteAccountPreferredSpecialty();

  useEffect(() => {
    setCursors({
      startCursor: data?.pageInfo?.startCursor || undefined,
      endCursor: data?.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pageInfo]);

  useEffect(() => {
    resetPagination();
  }, [sorting, resetPagination]);

  const handleDelete = useCallback(
    (accountPreferredSpecialtyId: string) => async () => {
      setModal(null);
      await deleteAccountPreferredSpecialty({
        input: { accountPreferredSpecialtyId },
      });
      refetchAccountPreferredSpecialties();
      refreshAccount();
    },
    [
      deleteAccountPreferredSpecialty,
      setModal,
      refetchAccountPreferredSpecialties,
      refreshAccount,
    ]
  );

  const confirmDelete = useCallback(
    (requestId: string) => () => {
      setModal({
        title: t('messages.confirmDeleteTitle'),
        content: (
          <ConfirmationModal
            content={t('messages.confirmDeleteText')}
            onCancel={() => setModal(null)}
            onConfirm={handleDelete(requestId)}
          />
        ),
      });
    },
    [setModal, t, handleDelete]
  );

  const columns = getColumns(i18n.language, t, tGlobal, confirmDelete);

  return {
    columnIds,
    columns: columns as ColumnDef<AccountPreferredSpecialty>[],
    count: data?.pageData?.count,
    currentPage,
    isLoading,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    onSortingChange: setSorting,
    preferredSpecialties: data?.preferredSpecialties || [],
    sorting,
    refetchAccountPreferredSpecialties,
  };
};
