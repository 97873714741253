import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateRequestUpdateArgs,
} from '@domain/graphql.types';
import { MutationCandidateRequestUpdate } from '@infrastructure/graphql/candidate';

const useCandidateRequestUpdate = () => {
  const [{ data, fetching, error }, updateCandidateRequest] = useMutation<
    Pick<Mutation, 'candidateRequestUpdate'>,
    MutationCandidateRequestUpdateArgs
  >(MutationCandidateRequestUpdate);

  return {
    updateCandidateRequest,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestUpdate;
