import { useMutation } from 'urql';

import { Mutation, MutationUserVerifyEmailArgs } from '@domain/graphql.types';
import { UserVerifyEmail } from '@infrastructure/graphql/user';

const useUserVerifyEmail = () => {
  const [{ data, fetching, error }, userVerifyEmail] = useMutation<
    Pick<Mutation, 'userVerifyEmail'>,
    MutationUserVerifyEmailArgs
  >(UserVerifyEmail);

  return {
    userVerifyEmail,
    data: data?.userVerifyEmail,
    fetching,
    error,
  };
};

export default useUserVerifyEmail;
