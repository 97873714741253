import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const IndustrySectors = gql`
  ${DescriptionsFragment}

  query IndustrySectors {
    industrySectors {
      values {
        code
        descriptions {
          ...DescriptionsFragment
        }
        industry {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
      }
    }
  }
`;

export default IndustrySectors;
