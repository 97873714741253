import { gql } from '@urql/core';

const MutationUpdateCandidateCvAndOtherFiles = gql`
  mutation UpdateCandidateCvAndOtherFiles(
    $input: OfferCandidateUpdateInput!
    $cvInput: OfferCandidateCVInput!
    $cvFiles: [Upload!]!
    $otherFilesInput: OfferCandidateOtherFilesInput!
    $otherFiles: [Upload!]!
  ) {
    offerCandidateUpdateCV(input: $cvInput, files: $cvFiles) {
      ok
    }
    offerCandidateUpdateOtherFiles(
      input: $otherFilesInput
      files: $otherFiles
    ) {
      ok
    }
    offerCandidateUpdate(input: $input) {
      offerCandidate {
        id
      }
    }
  }
`;

export default MutationUpdateCandidateCvAndOtherFiles;
