import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { StatusTabs } from '@application/components';
import { RecruitmentNegotiationRoomStatus } from '@domain/graphql.types';

import { RecruitmentNegotiationRoomsList } from './list';

const RecruitmentNegotiationRoomListPage = () => {
  const { t } = useTranslation('recruitment');

  const navigate = useNavigate();

  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      navigate('#in-progress', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <>
      <div className="w-fit">
        <StatusTabs
          t={t}
          type="recruitment-negotiation"
          statuses={[
            RecruitmentNegotiationRoomStatus.InProgress,
            RecruitmentNegotiationRoomStatus.Accepted,
            RecruitmentNegotiationRoomStatus.Rejected,
          ]}
        />
      </div>

      <RecruitmentNegotiationRoomsList />
    </>
  );
};

export default RecruitmentNegotiationRoomListPage;
