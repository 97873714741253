import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Fieldset, HelperText } from '@application/components';
import { Avatar } from '@application/components/avatar';
import { Button, FileUploadButton } from '@application/components/buttons';
import { Box, Stack } from '@application/components/container-layouts';
import { ConfirmationModal } from '@application/components/modal';
import { ModalContext, UserContext } from '@application/context';
import { useUploadImage } from '@application/hooks';
import { User } from '@domain/graphql.types';

import { useDeleteUserAvatar, useUpdateUserAvatar } from '../hooks';

type ProfilePictureProps = {
  user: User;
};

const ProfilePicture = ({ user }: ProfilePictureProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('user');

  const { error, croppedImageData, imageFile, allowedTypes, onFileChange } =
    useUploadImage({
      modalTitle: t('modal.uploadTitle'),
      confirmButtonLabel: t('modal.uploadConfirmButtonLabel'),
    });

  const [localAvatarUrl, setLocalAvatarUrl] = useState(user.avatarUrl);

  const { updateUserAvatar } = useUpdateUserAvatar();
  const { deleteUserAvatar } = useDeleteUserAvatar();
  const { setAvatarUrl } = useContext(UserContext);
  const { setModal } = useContext(ModalContext);

  useEffect(() => {
    if (!imageFile) {
      return;
    }

    const updateAvatar = async () => {
      await updateUserAvatar({
        files: [imageFile],
        input: { filename: imageFile?.name, userId: user.id },
      });
    };
    updateAvatar();
  }, [imageFile, updateUserAvatar, user.id]);

  useEffect(() => {
    if (!croppedImageData) {
      return;
    }
    setAvatarUrl(croppedImageData);
    setLocalAvatarUrl(croppedImageData);
  }, [croppedImageData, setAvatarUrl]);

  const deleteAvatar = useCallback(() => {
    setModal(null);
    deleteUserAvatar({ input: { userId: user.id } });
    setAvatarUrl(null);
    setLocalAvatarUrl(null);
  }, [deleteUserAvatar, setAvatarUrl, setModal, user]);

  const confirmAvatarDeletion = useCallback(() => {
    setModal({
      title: t('modal.deleteTitle'),
      content: (
        <ConfirmationModal
          content={t('modal.deleteMessage')}
          onCancel={() => setModal(null)}
          onConfirm={deleteAvatar}
        />
      ),
    });
  }, [deleteAvatar, setModal, t]);

  return (
    <Stack>
      <Box padding={24}>
        <Fieldset legend={t('subtitles.profilePicture')} hideLegend>
          <div className="flex gap-s-24 items-center justify-center flex-wrap md:flex-nowrap">
            <Avatar name={user.name} url={localAvatarUrl} />

            <div className="grow">
              <p className="max-w-[21rem] text-neutral-secondary">
                {t('profilePicture.instructions')}
              </p>
            </div>

            <div className="flex self-end gap-s-24">
              <Button
                disabled={!localAvatarUrl}
                onClick={confirmAvatarDeletion}
              >
                {tGlobal('button.delete')}
              </Button>

              <FileUploadButton
                aria-describedby="avatar-upload"
                accept={allowedTypes.join(',')}
                onChange={onFileChange}
              >
                {tGlobal('button.edit')}
              </FileUploadButton>
            </div>
          </div>

          {error && (
            <HelperText id="avatar-upload" invalid className="px-s-0">
              {error}
            </HelperText>
          )}
        </Fieldset>
      </Box>
    </Stack>
  );
};
export default ProfilePicture;
