import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import {
  Button,
  ButtonLink,
  ConfirmationModal,
  Stack,
} from '@application/components';
import { ModalContext, NavContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { CandidateNegotiationRoomStatus } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';
import { isDefined } from '@utils/type-utils';

import { CandidateNegotiationContext } from '../context';
import {
  useCompleteCandidateNegotiation,
  useRejectCandidateNegotiationRooms,
} from '../hooks';

const ActionMenu = () => {
  const { t, i18n } = useTranslation('candidates');
  const location = useLocation();
  const { isNavOpen, setIsBottomMenuOpen } = useContext(NavContext);
  const { setModal } = useContext(ModalContext);
  const {
    negotiationId,
    candidateRequest,
    rooms,
    checkedRooms,
    isComparing,
    setCheckedRooms,
    setIsComparing,
    refreshCandidateNegotiation,
  } = useContext(CandidateNegotiationContext);

  const { completeCandidateNegotiation } = useCompleteCandidateNegotiation();
  const { rejectNegotiationRooms } = useRejectCandidateNegotiationRooms();

  const getOfferDetailsPath = useCallback(
    (roomId: string) => {
      const room = rooms?.find((r) => r.id === roomId);
      return room
        ? generatePath(PrivatePage.CANDIDATE_OFFER_DETAILS, {
            id: candidateRequest?.id || null,
            offerId: room.candidateOfferId,
          })
        : '#';
    },
    [candidateRequest?.id, rooms]
  );

  const getAccountNameList = useCallback(
    (roomIds: string[]) => {
      const filteredRooms = rooms
        ?.filter((r) => roomIds.includes(r.id))
        .map((r) => r.candidateOffer.account.name);

      return filteredRooms?.join('\n') || [];
    },
    [rooms]
  );

  const acceptedRoom = useMemo(
    () =>
      rooms?.find(
        (room) => room.status === CandidateNegotiationRoomStatus.Accepted
      ),
    [rooms]
  );

  const paddingLeft = useMemo(
    () => (isNavOpen ? 'lg:pl-[22rem]' : 'lg:pl-[9rem]'),
    [isNavOpen]
  );

  const agreementPath = useMemo(
    () =>
      generatePath(PrivatePage.CANDIDATE_NEGOTIATION_AGREEMENT, {
        id: negotiationId,
      }),
    [negotiationId]
  );

  const handleDeselectAll = useCallback(
    () => setCheckedRooms([]),
    [setCheckedRooms]
  );

  const handleConfirmCreateAgreement = useCallback(
    async (roomId: string) => {
      await completeCandidateNegotiation({
        input: { roomId, negotiationId },
      });

      refreshCandidateNegotiation();
      handleDeselectAll();
    },
    [
      completeCandidateNegotiation,
      negotiationId,
      handleDeselectAll,
      refreshCandidateNegotiation,
    ]
  );

  const handleOnCreateAgreement = useCallback(
    (roomId: string) => {
      setModal({
        title: t('modal.titles.acceptOffer'),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    i18nKey="modal.contents.acceptOffer"
                    t={t}
                    values={{
                      agency: rooms?.find((room) => room.id === roomId)
                        ?.candidateOffer.account.name,
                      specialty: getLocalizedDescription(
                        candidateRequest?.jobSpecialty?.descriptions,
                        i18n.language
                      ),
                    }}
                  />
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              handleConfirmCreateAgreement(roomId);
              setModal(null);
            }}
          />
        ),
        maxWidth: 'md',
        centered: true,
      });
    },
    [
      handleConfirmCreateAgreement,
      i18n.language,
      candidateRequest?.jobSpecialty?.descriptions,
      rooms,
      setModal,
      t,
    ]
  );

  const handleConfirmRejectNegotiationRooms = useCallback(
    async (roomIds: string[]) => {
      await rejectNegotiationRooms({ input: { roomIds } });

      refreshCandidateNegotiation();
      handleDeselectAll();
    },
    [handleDeselectAll, refreshCandidateNegotiation, rejectNegotiationRooms]
  );

  const handleOnRejectNegotiationRooms = useCallback(
    (roomIds: string[]) => {
      setModal({
        title: t('modal.titles.rejectOffers', { count: checkedRooms.length }),
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p className="whitespace-pre-line">
                  <Trans
                    i18nKey="modal.contents.rejectOffers"
                    t={t}
                    values={{
                      count: checkedRooms.length,
                      agencies: getAccountNameList(roomIds),
                    }}
                  />
                </p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              handleConfirmRejectNegotiationRooms(roomIds);
              setModal(null);
            }}
          />
        ),
        maxWidth: 'fit',
        centered: true,
      });
    },
    [
      checkedRooms.length,
      getAccountNameList,
      handleConfirmRejectNegotiationRooms,
      setModal,
      t,
    ]
  );

  useEffect(() => {
    setIsBottomMenuOpen(isDefined(acceptedRoom) || checkedRooms.length >= 1);
  }, [acceptedRoom, checkedRooms, setIsBottomMenuOpen]);

  return (
    <div
      className={cn(
        'fixed bottom-s-0 right-s-0 bg-base-100 shadow-lg px-s-32 py-s-16 w-full min-h-[4.5rem] hidden',
        {
          block: checkedRooms.length >= 1 || acceptedRoom,
        },
        paddingLeft
      )}
    >
      {acceptedRoom ? (
        <div className="flex gap-s-16 justify-end">
          <ButtonLink
            size="small"
            className={cn('py-s-0', {
              hidden:
                acceptedRoom?.status !==
                CandidateNegotiationRoomStatus.Accepted,
            })}
            primary
            icon={<i className="ri-shake-hands-line" />}
            to={agreementPath}
          >
            {t('button.preAgreement')}
          </ButtonLink>

          <ButtonLink
            size="small"
            className="py-s-0"
            icon={<i className="ri-file-text-line text-16 font-normal" />}
            to={getOfferDetailsPath(acceptedRoom.id)}
          >
            {t('button.offerDetails')}
          </ButtonLink>
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex items-center gap-s-16">
            <span>
              {t('labels.offersCount', { count: checkedRooms.length })}
            </span>
            {!isComparing && (
              <Button size="small" ghost onClick={handleDeselectAll}>
                {t('button.deselectOffer', { count: checkedRooms.length })}
              </Button>
            )}
          </div>

          <div className="flex flex-col md:flex-row gap-s-16">
            {/* IF only one room is selected AND not rejected yet -> Show the create agreement button */}
            <Button
              size="small"
              primary
              icon={
                <i className="ri-shake-hands-line mr-s-4 text-16 font-normal" />
              }
              className={
                checkedRooms.length === 1 &&
                rooms?.some(
                  (room) =>
                    room.id === checkedRooms[0] &&
                    room.status !== CandidateNegotiationRoomStatus.Rejected
                )
                  ? 'py-s-0'
                  : 'hidden'
              }
              onClick={() => handleOnCreateAgreement(checkedRooms[0])}
            >
              {t('button.createAgreement')}
            </Button>

            {/* IF we only have ONE room checked -> Show the room details button */}
            <ButtonLink
              size="small"
              icon={<i className="ri-file-text-line text-16 font-normal" />}
              className={
                checkedRooms.length === 1
                  ? 'flex items-center py-s-0'
                  : 'hidden'
              }
              to={getOfferDetailsPath(checkedRooms[0])}
              from={location.pathname}
            >
              {t('button.offerDetails')}
            </ButtonLink>

            {/* IF none of the checked rooms has been rejected yet -> Show the reject offer button */}
            <Button
              size="small"
              icon={
                <i className="ri-prohibited-line mr-s-4 text-16 font-normal" />
              }
              className={
                rooms
                  ?.filter((r) => checkedRooms.includes(r.id))
                  .every(
                    (r) => r.status !== CandidateNegotiationRoomStatus.Rejected
                  )
                  ? 'py-s-0'
                  : 'hidden'
              }
              onClick={() => handleOnRejectNegotiationRooms(checkedRooms)}
            >
              {t('button.declineOffers')}
            </Button>

            {/* IF there's more than one room checked -> Show the comparison button */}
            {/* IF we are currently comparing rooms -> Change the text to cancel selection */}
            <Button
              className={checkedRooms.length > 1 ? 'py-s-0' : 'hidden'}
              size="small"
              onClick={() => setIsComparing(!isComparing)}
            >
              {t(
                `${isComparing ? 'button.cancelSelection' : 'button.compareOffers'}`
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
