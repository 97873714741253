import { useMutation } from 'urql';

import { Mutation, MutationRequestDeleteArgs } from '@domain/graphql.types';
import { MutationRequestDelete } from '@infrastructure/graphql/request';

const useRequestDelete = () => {
  const [{ data, fetching, error }, deleteRequest] = useMutation<
    Pick<Mutation, 'requestDelete'>,
    MutationRequestDeleteArgs
  >(MutationRequestDelete);

  return {
    deleteRequest,
    data,
    fetching,
    error,
  };
};

export default useRequestDelete;
