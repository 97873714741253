import { useTranslation } from 'react-i18next';

import { SummaryList } from '@application/components/summary';
import SummaryBlock from '@application/components/summary/SummaryBlock';
import { Request } from '@domain/graphql.types';

type NotesProps = {
  data: Request | undefined;
};

const Notes = ({ data }: NotesProps) => {
  const { t } = useTranslation('requests');

  return (
    <SummaryBlock
      className="p-s-0"
      headerTitleClassName="mt-s-16"
      headerTitle={t('subtitles.others')}
    >
      <SummaryList
        className="!mt-s-0"
        density="default"
        isCollapsable
        space={0}
        data={[
          {
            text: t('labels.generalNotes'),
            description: data?.generalNotes || '-',
          },
          {
            text: t('labels.jobAdditionalNotes'),
            description: data?.jobAdditionalNotes || '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default Notes;
