import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { Button } from '@application/components/buttons';
import { Modal } from '@application/components/modal';
import { AlertContext } from '@application/context';
import { AccountUserErrors } from '@domain/graphql.types';

import useInviteAccountUser from '../hooks/useInviteAccountUser';
import { INVITATION_SCHEMA, InvitationFormFields } from '../schema';

export type InviteUserModalProps = {
  onClose: () => void;
  afterSubmit: () => void;
  onHasNoAvailableSeatsError: () => void;
};

const InviteUserModal = ({
  onClose,
  afterSubmit,
  onHasNoAvailableSeatsError,
}: InviteUserModalProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('organization', {
    keyPrefix: 'users.invitationModal',
  });

  const { setAlertContent } = useContext(AlertContext);

  const {
    inviteAccountUser,
    viewModel: { isLoading, errorCodes },
  } = useInviteAccountUser();

  useEffect(() => {
    if (!errorCodes.length) {
      return;
    }
    if (
      errorCodes.find((e) => e.message === AccountUserErrors.NoAvailableSeats)
    ) {
      onHasNoAvailableSeatsError();
    }
  }, [errorCodes, onHasNoAvailableSeatsError]);

  const { control, handleSubmit } = useForm<InvitationFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(INVITATION_SCHEMA),
    defaultValues: {
      upgradeSubscription: false,
    },
  });

  const onSubmit: SubmitHandler<InvitationFormFields> = useCallback(
    async ({ email, upgradeSubscription = false }) => {
      const result = await inviteAccountUser({
        input: {
          email,
          upgradeSubscription,
        },
      });

      if (result.data?.accountUserInvite.accountUser) {
        setAlertContent({
          action: t('inviteMemberSuccess'),
          modifier: 'alert-success',
        });
        afterSubmit();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inviteAccountUser, afterSubmit]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.CloseBtn onClick={onClose} />

      <Controller
        name="email"
        defaultValue=""
        control={control}
        render={({
          field: { onChange, onBlur, value, name },
          fieldState: { error },
        }) => (
          <TextInputField
            id="invite-user-email"
            className="mt-s-8"
            disabled={isLoading}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            type="text"
            label={<span className="text-14">{t('email.label')}</span>}
            name={name}
            helperText={
              error?.message &&
              tGlobal(error.message, {
                field: t('email.label'),
              })
            }
            invalid={!!error}
          />
        )}
      />

      <Modal.Action>
        <Button onClick={onClose} disabled={isLoading}>
          {tGlobal('button.cancel')}
        </Button>
        <Button primary type="submit" loading={isLoading}>
          {tGlobal('button.confirm')}
        </Button>
      </Modal.Action>
    </form>
  );
};

export default InviteUserModal;
