import { useJobScheduleTypesGet } from '@domain/job';

import { toViewModel } from '../view-models/get-job-schedule-types.vm';

const useGetJobScheduleTypes = () => {
  const { data = {}, fetching, error } = useJobScheduleTypesGet();

  return toViewModel({
    jobScheduleTypes: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetJobScheduleTypes;
