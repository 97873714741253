/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BadgeList } from '@application/components/badge';
import { PlaceholderSwitcher } from '@application/components/switch';
import { mapDescriptions } from '@application/utils';
import {
  CandidateNegotiationRoom,
  CandidateRequestType,
  JobPaymentFrequencyCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

type EmployerBrandProps = {
  room: CandidateNegotiationRoom;
  checked?: boolean;
  type?: CandidateRequestType;
};

const EmployerBrand = ({ checked, room, type }: EmployerBrandProps) => {
  const { t, i18n } = useTranslation('candidates');

  const remuneration = useMemo(() => {
    switch (room.negotiatingCandidateOffer.paymentFrequencyCode) {
      case JobPaymentFrequencyCode.Hourly:
        return t('suffix.currencyPerHourRange', {
          min: room.negotiatingCandidateOffer.hourlyRateMin,
          max: room.negotiatingCandidateOffer.hourlyRateMax,
        });
      case JobPaymentFrequencyCode.Yearly:
        return t('suffix.thousandsPerYearRange', {
          min: room.negotiatingCandidateOffer.annualSalaryMin,
          max: room.negotiatingCandidateOffer.annualSalaryMax,
        });
      case JobPaymentFrequencyCode.FixedAmount:
        return t('suffix.currency', {
          value: room.negotiatingCandidateOffer.remunerationAmount,
        });
      default:
        return '-';
    }
  }, [room, t]);

  const operationTerritories = useMemo(
    () =>
      mapDescriptions(
        room?.negotiatingCandidateOffer?.operationTerritories?.map(
          (territory) => territory.descriptions
        ) || [],
        i18n.language
      ),
    [i18n.language, room?.negotiatingCandidateOffer?.operationTerritories]
  );

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('labels.employerBrand')}
        </th>

        <td
          className={cn(TH_ROW_CLASS_NAME.highlight, 'border-l-1 border-b-1', {
            'border-x-2 !border-x-main': checked,
          })}
        >
          &nbsp;
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          {t('labels.remunerationOffered')}
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'whitespace-nowrap', {
            'border-x-2 !border-x-main': checked,
          })}
        >
          <PlaceholderSwitcher
            value={room.negotiatingCandidateOffer.paymentFrequencyCode}
          >
            {remuneration}
          </PlaceholderSwitcher>
        </td>
      </tr>

      {type === CandidateRequestType.Recruitment ? (
        <tr>
          <th scope="col" className={TH_ROW_CLASS_NAME.base}>
            {t('labels.otherRemunerations')}
          </th>

          <td
            className={cn(TD_CLASS_NAME.base, 'whitespace-nowrap', {
              'border-x-2 !border-x-main': checked,
            })}
          >
            <PlaceholderSwitcher
              value={room.negotiatingCandidateOffer.otherPaymentTypeCode}
            >
              {t(
                `enum.jobPaymentOtherTypesCode.${room.negotiatingCandidateOffer.otherPaymentTypeCode?.toLowerCase()}`
              )}
            </PlaceholderSwitcher>
          </td>
        </tr>
      ) : (
        <>
          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.hoursPerWeek')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, {
                'border-x-2 !border-x-main': checked,
              })}
            >
              <PlaceholderSwitcher
                value={room.negotiatingCandidateOffer.hoursPerWeek}
              >
                {room.negotiatingCandidateOffer.hoursPerWeek
                  ?.map((h) => t('suffix.hours', { value: h }))
                  .join(', ')}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.contractDuration')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, {
                'border-x-2 !border-x-main': checked,
              })}
            >
              <PlaceholderSwitcher
                value={room.negotiatingCandidateOffer.jobDurationInMonths}
              >
                {t('suffix.month', {
                  count: Number(
                    room.negotiatingCandidateOffer.jobDurationInMonths
                  ),
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th
              scope="col"
              className={cn(TH_ROW_CLASS_NAME.base, 'bg-disabled-light')}
            >
              {t('labels.potentialAmount')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                'bg-disabled-light whitespace-nowrap',
                {
                  'border-x-2 !border-x-main': checked,
                }
              )}
            >
              <PlaceholderSwitcher
                value={room.negotiatingCandidateOffer.potentialAmount}
              >
                {t('suffix.currency', {
                  value: Number(room.negotiatingCandidateOffer.potentialAmount),
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>
        </>
      )}

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          {t('labels.operationTerritory')}
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, {
            'border-x-2 !border-x-main': checked,
          })}
        >
          <PlaceholderSwitcher value={operationTerritories.length || undefined}>
            <BadgeList values={operationTerritories} />
          </PlaceholderSwitcher>
        </td>
      </tr>

      {type === CandidateRequestType.Recruitment && (
        <tr>
          <th scope="col" className={TH_ROW_CLASS_NAME.base}>
            {t('labels.jobType')}
          </th>

          <td
            className={cn(TD_CLASS_NAME.base, 'whitespace-nowrap', {
              'border-x-2 !border-x-main': checked,
            })}
          >
            <PlaceholderSwitcher value={room.negotiatingCandidateOffer.jobType}>
              {t(
                `enum.jobDurationCode.${room.negotiatingCandidateOffer.jobType?.toLowerCase()}`
              )}
            </PlaceholderSwitcher>
          </td>
        </tr>
      )}

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          {t('labels.remoteWork')}
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'whitespace-nowrap', {
            'border-x-2 !border-x-main': checked,
          })}
        >
          <PlaceholderSwitcher
            value={room.negotiatingCandidateOffer.jobModeCode}
          >
            {t(
              `enum.jobModeCode.${room.negotiatingCandidateOffer.jobModeCode?.toLowerCase()}`
            )}
          </PlaceholderSwitcher>
        </td>
      </tr>
    </>
  );
};

export default EmployerBrand;
