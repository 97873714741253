import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import OfferFragment from './offer.fragment';

const Offers = gql`
  ${OfferFragment}

  query Offers(
    $filterBy: OffersFilters,
    $sortBy: [OffersSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    offers(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['OfferFragment'])}
  }
`;

export default Offers;
