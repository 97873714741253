import { useValidateIdParam } from '@application/hooks';
import { usePlainCandidateOffer } from '@domain/candidate/offer';

import { toViewModel } from '../view-models/get-candidate-offer.vm';

const useGetPlainCandidateOffer = (candidateOfferId: string) => {
  useValidateIdParam(candidateOfferId);

  const { data, fetching, error, reexecuteQuery } = usePlainCandidateOffer({
    input: { candidateOfferId },
  });

  const viewModel = toViewModel({
    candidateOffer: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateOffer: reexecuteQuery,
  };
};

export default useGetPlainCandidateOffer;
