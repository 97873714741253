import { useTranslation } from 'react-i18next';

import { JobDurationCode } from '@domain/graphql.types';

const useJobTypesOptions = () => {
  const { t } = useTranslation();

  return Object.values(JobDurationCode).map((code) => ({
    value: code,
    label: t(`enum.jobDurationCode.${code.toLowerCase()}`),
  }));
};

export default useJobTypesOptions;
