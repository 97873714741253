import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ModalContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';

import ActionPromoModal from './ActionPromoModal';

export type UsePromoModalProps = {
  openModal: () => void;
};

const useActionPromoModal = (): UsePromoModalProps => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.actionPromo',
  });

  const { setModal } = useContext(ModalContext);

  const navigate = useNavigate();

  const onConfirmButtonClick = useCallback(() => {
    navigate(PrivatePage.REQUEST_CREATE);
    setModal(null);
  }, [navigate, setModal]);

  const openModal = useCallback(() => {
    setModal({
      title: t('modalTitle'),
      content: (
        <ActionPromoModal
          closeModal={() => setModal(null)}
          onConfirm={onConfirmButtonClick}
        />
      ),
    });
  }, [onConfirmButtonClick, setModal, t]);

  return {
    openModal,
  };
};

export default useActionPromoModal;
