import { useMemo } from 'react';

import { cn } from '../../../utils/lib-utils';
import Badge from './Badge';

export type Modifier =
  | 'badge-info'
  | 'badge-success'
  | 'badge-warning'
  | 'badge-error';

type BadgeListProps = {
  className?: string;
  modifier?: Modifier;
  values: string[];
};

const BadgeList = ({
  className: customClassName,
  values,
  modifier,
}: BadgeListProps) => {
  const first = useMemo(() => values[0], [values]);
  const hasRemaining = useMemo(() => values.length > 1, [values]);
  const remaining = useMemo(() => `+${values.length - 1}`, [values]);

  return (
    <div className={cn('flex gap-s-8', customClassName)}>
      <Badge modifier={modifier} value={first} />

      {hasRemaining && <Badge modifier={modifier} value={remaining} />}
    </div>
  );
};

export default BadgeList;
