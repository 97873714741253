import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@application/components';

type StateButtonsProps = {
  onAccept: () => void;
  onReject: () => void;
};

const StateButtons = ({ onAccept, onReject }: StateButtonsProps) => {
  const { t } = useTranslation('recruitment', { keyPrefix: 'negotiation' });

  return (
    <div className="flex items-center justify-center gap-s-8">
      <Tooltip message={t('tooltips.accept')} position="bottom">
        <IconButton
          size="small"
          text={t('actions.accept')}
          icon={<i className="ri-thumb-up-line text-18 text-badge-green" />}
          onClick={onAccept}
        />
      </Tooltip>

      <Tooltip message={t('tooltips.reject')} position="bottom">
        <IconButton
          size="small"
          text={t('actions.reject')}
          icon={<i className="ri-thumb-down-line text-18 text-badge-red" />}
          onClick={onReject}
        />
      </Tooltip>
    </div>
  );
};

export default StateButtons;
