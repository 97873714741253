import { useTranslation } from 'react-i18next';
import { ClearIndicatorProps, components } from 'react-select';

import { OptionType } from '../types';

const ClearIndicator = (props: ClearIndicatorProps<OptionType>) => {
  const { t } = useTranslation();

  return (
    <components.ClearIndicator {...props}>
      <div
        aria-label={t('select.label.removeAll')}
        className="py-s-0 mx-s-16 text-primary"
      >
        <i className="ri-close-line text-20" />
      </div>
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
