import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { OperationUnitErrors } from '@domain/graphql.types';
import { useOperationUnitCreate } from '@domain/operation/unit';

import { toViewModel } from './view-models/create-opreation-unit.vm';

const useCreateOperationUnit = () => {
  const { data, fetching, error, createOperationUnit } =
    useOperationUnitCreate();

  const { t } = useTranslation('operationUnit', {
    keyPrefix: 'operationUnitModal',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    operationUnit: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    const alreadyExistsError = errorCodes.find(
      (e) => e.message === OperationUnitErrors.AlreadyExists
    );

    setAlertContent(
      alreadyExistsError
        ? { text: t('messages.errors.alreadyExists'), modifier: 'alert-error' }
        : { action: t('messages.errors.create'), modifier: 'alert-error' }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length, setAlertContent]);

  return {
    viewModel,
    createOperationUnit,
  };
};

export default useCreateOperationUnit;
