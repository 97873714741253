import { useTranslation } from 'react-i18next';

const useBooleanOptions = () => {
  const { t } = useTranslation();

  return [
    {
      value: String(true),
      label: t('boolean.true'),
    },
    {
      value: String(false),
      label: t('boolean.false'),
    },
  ];
};

export default useBooleanOptions;
