import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, Fieldset } from '@application/components';
import { Button } from '@application/components/buttons';
import { Box, Stack } from '@application/components/container-layouts';
import { LoadingSpinner } from '@application/components/spinner';
import { extractLanguage } from '@utils/i18n-utils';

import useGetJobSpecialties from '../actions/useGetJobSpecialties';
import SpecialtiesOnboardingContext from './context/SpecialtiesOnboardingContext';
import { SpecialtiesOnboardingFormFields } from './types';

type OnboardingSpecialtiesProps = {
  isCreating: boolean;
  getValues: UseFormGetValues<SpecialtiesOnboardingFormFields>;
  register: UseFormRegister<SpecialtiesOnboardingFormFields>;
  setValue: UseFormSetValue<SpecialtiesOnboardingFormFields>;
  watch: UseFormWatch<SpecialtiesOnboardingFormFields>;
};

const OnboardingSpecialties = ({
  isCreating,
  getValues,
  register,
  setValue,
  watch,
}: OnboardingSpecialtiesProps) => {
  const { t: tGlobal } = useTranslation();
  const { i18n, t } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities.onboarding',
  });

  const [showLoader, setShowLoader] = useState(false);

  const { previousStep } = useContext(SpecialtiesOnboardingContext);

  const registerFields = register('specialties');

  const specialties = watch('specialties');
  const { sectors = [] } = getValues();

  const { data: JOB_SPECIALTIES, isLoading } = useGetJobSpecialties();

  const jobSpecialties = useMemo(
    () =>
      JOB_SPECIALTIES.filter((s) => sectors.includes(s.sector.code)).map(
        ({ descriptions, id, sector }) => ({
          sectorLabel: sector.descriptions[extractLanguage(i18n.language)],
          sector: sector.code,
          value: id,
          label: descriptions[extractLanguage(i18n.language)],
        })
      ),
    [JOB_SPECIALTIES, i18n, sectors]
  );

  const buildSpecialtiesSection = useCallback(
    (sector: string) => {
      const filteredSpecialties = jobSpecialties.filter(
        (s) => s.sector === sector
      );

      const fieldsetLabel = filteredSpecialties[0]?.sectorLabel || '';

      return (
        <Fieldset key={sector} legend={fieldsetLabel}>
          <Stack
            space={0}
            as="ul"
            className="border-separate rounded-md border border-stroke-default border-spacing-s-0 overflow-hidden"
          >
            {filteredSpecialties.map(({ value, label }) => (
              <li key={value} className="w-full pt-s-4 first:pt-s-0">
                <Checkbox
                  key={value}
                  value={value}
                  label={label}
                  checked={specialties.includes(value)}
                  labelDirection="left"
                  labelTextClassName="text-16"
                  checkboxClassName="h-s-16 w-s-16"
                  className="justify-between h-s-40 px-s-16 hover:bg-secondary"
                  withBackground
                  {...registerFields}
                />
              </li>
            ))}
          </Stack>
        </Fieldset>
      );
    },
    [jobSpecialties, registerFields, specialties]
  );

  const selectDeselectAll = useCallback(() => {
    let specialtiesToSelect: string[] = [];

    if (jobSpecialties.length !== specialties.length) {
      specialtiesToSelect = jobSpecialties.map((i) => i.value);
    }

    setValue('specialties', specialtiesToSelect);
  }, [jobSpecialties, specialties, setValue]);

  const goBack = useCallback(() => {
    setValue('specialties', []);
    previousStep();
  }, [setValue, previousStep]);

  const specialtiesSections = useMemo(
    () => sectors.map((sector) => buildSpecialtiesSection(sector)),
    [sectors, buildSpecialtiesSection]
  );

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-s-16">
        <h2 className="h3">{t('specialties.title')}</h2>
        <span>{t('steps', { current: 3, total: 3 })}</span>
      </div>

      <div className="flex items-center justify-between mb-s-24">
        <span>{t('specialties.instructions')}</span>

        <Button
          className="bg-transparent underline"
          ghost
          onClick={selectDeselectAll}
        >
          <span>
            {tGlobal(
              jobSpecialties.length === specialties.length
                ? 'button.deselect'
                : 'button.selectAll'
            )}
          </span>
        </Button>
      </div>

      <Box className="min-h-[24rem] mb-s-24" padding={24}>
        {showLoader && isLoading && (
          <div className="h-s-384 w-full flex justify-center items-center">
            <LoadingSpinner size="lg" />
          </div>
        )}

        {!isLoading && (
          <div className="flex flex-col gap-s-24">{specialtiesSections}</div>
        )}
      </Box>

      {!isLoading && (
        <div className="flex justify-end gap-s-16">
          <Button size="large" onClick={goBack} disabled={isCreating}>
            <span>{tGlobal('button.back')}</span>
          </Button>

          <Button
            size="large"
            primary
            disabled={specialties.length === 0 || isCreating}
            type="submit"
            loading={isCreating}
          >
            <span>{tGlobal('button.complete')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default OnboardingSpecialties;
