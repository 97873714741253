import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateOfferCreateArgs,
} from '@domain/graphql.types';
import { MutationCandidateOfferCreate } from '@infrastructure/graphql/candidate/offer';

const useCandidateOfferCreate = () => {
  const [{ data, fetching, error }, createCandidateOffer] = useMutation<
    Pick<Mutation, 'candidateOfferCreate'>,
    MutationCandidateOfferCreateArgs
  >(MutationCandidateOfferCreate);

  return {
    createCandidateOffer,
    data,
    fetching,
    error,
  };
};

export default useCandidateOfferCreate;
