import { gql } from 'urql';

const OperationUnitFragment = gql`
  fragment OperationUnitFragment on OperationUnit {
    id
    accountId
    name
    address
    phoneNumber
    phoneDisplay
    headOffice
    sectorCode
    industryCode
    notes
    createdAt
    updatedAt
  }
`;

export default OperationUnitFragment;
