import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useCandidateOfferAccept } from '@domain/candidate/offer';

import { toViewModel } from '../view-models/accept-candidate-offer.vm';

const useAcceptCandidateOffer = () => {
  const { data, fetching, error, acceptCandidateOffer } =
    useCandidateOfferAccept();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    offer: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      modifier: 'alert-error',
      action: t('acceptOfferError'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    viewModel,
    acceptCandidateOffer,
  };
};

export default useAcceptCandidateOffer;
