import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../buttons';
import { Checkbox } from '../form';
import { useTermsAndConditionsModal } from '../modal/useTermsAndConditionsModal';

type TermsAndConditionsCheckboxProps<T extends FieldValues> = {
  className?: string;
  control: Control<T, any>;
  name: FieldPath<T>;
};

const TermsAndConditionsCheckbox = <T extends FieldValues>({
  className: customClassName,
  control,
  name,
}: TermsAndConditionsCheckboxProps<T>) => {
  const { t: tGlobal } = useTranslation();

  const openModal = useTermsAndConditionsModal();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name: fieldName, onChange, value } }) => (
        <Checkbox
          className={customClassName}
          name={fieldName}
          value={String(value)}
          label={
            <span className="text-14">
              <span>{tGlobal('termsAndConditions.label')}</span>

              <Button
                ghost
                className="btn-link bg-transparent hover:bg-transparent !min-w-fit px-s-0 text-14"
                onClick={openModal}
              >
                {tGlobal('termsAndConditions.title')}
              </Button>
            </span>
          }
          onChange={onChange}
          checked={Boolean(value)}
        />
      )}
    />
  );
};

export default TermsAndConditionsCheckbox;
