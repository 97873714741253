import { useQuery } from 'urql';

import { Query } from '@domain/graphql.types';
import { IndustrySectors } from '@infrastructure/graphql/industry';

const useIndustrySectors = () => {
  const [result] = useQuery<Pick<Query, 'industrySectors'>, void>({
    query: IndustrySectors,
    requestPolicy: 'cache-first',
  });

  const { data, fetching, error } = result;

  return {
    data: [...(data?.industrySectors?.values || [])],
    fetching,
    error,
  };
};

export default useIndustrySectors;
