import { cn } from '@utils/lib-utils';

type PagePanelProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
  isEditing?: boolean;
  text?: string;
};

const PagePanel = ({
  title,
  children,
  className: customClassName,
  isEditing,
  text,
}: PagePanelProps) => {
  const className = cn('mb-s-24', customClassName);

  return (
    <div className={className}>
      {!isEditing && (
        <div className="mb-s-40 text-center">
          <h1 className="h2 mb-s-16">{title}</h1>
          {text && <p className="text-16 text-neutral-secondary">{text}</p>}
        </div>
      )}

      {children}
    </div>
  );
};

export default PagePanel;
