import { useIndustrySectors } from '@domain/industry';

import { toViewModel } from './get-industry-sectors.vm';

const useGetIndustrySectors = () => {
  const { data, fetching, error } = useIndustrySectors();

  return toViewModel({
    jobSpecialties: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetIndustrySectors;
