import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { RecruitmentNegotiationErrors } from '@domain/graphql.types';
import { useRecruitmentNegotiationAddRooms } from '@domain/negotiation';

import { toViewModel } from '../view-models/add-rooms-recruitment-negotiation.vm';

const useAddRoomsToRecruitmentNegotiation = () => {
  const { data, fetching, error, addRoomsToRecruitmentNegotiation } =
    useRecruitmentNegotiationAddRooms();

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    recruitmentNegotiation: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    const maxFourRoomsError = error.graphQLErrors.find(
      (e) => e.message === RecruitmentNegotiationErrors.MaxFourRooms
    );

    setAlertContent(
      maxFourRoomsError
        ? {
            text: t('maxFourRoomsRecruitmentNegotiationError'),
            modifier: 'alert-error',
          }
        : {
            action: t('addRoomsToRecruitmentNegotiationError'),
            modifier: 'alert-error',
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.recruitmentNegotiationAddRooms.negotiation) {
      setAlertContent({
        action: t('addRoomsToRecruitmentNegotiationSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { addRoomsToRecruitmentNegotiation, viewModel };
};

export default useAddRoomsToRecruitmentNegotiation;
