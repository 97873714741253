import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext, UserContext } from '@application/context';
import { useUserVerifyEmail } from '@domain/user';

import { toViewModel } from '../view-models/verify-email.vm';

const useVerifyEmail = () => {
  const {
    data,
    fetching,
    error,
    userVerifyEmail: verifyEmail,
  } = useUserVerifyEmail();

  const { t } = useTranslation('dashboard', { keyPrefix: 'messages' });

  const { setAlertContent } = useContext(AlertContext);
  const { setIsEmailVerified } = useContext(UserContext);

  const viewModel = toViewModel({
    verifyEmail: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (data?.user?.emailVerified) {
      setAlertContent({
        action: t('verifyEmailSuccess'),
        modifier: 'alert-success',
      });

      setIsEmailVerified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user?.emailVerified, setAlertContent, setIsEmailVerified]);

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('verifyEmailError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length, setAlertContent]);

  return {
    viewModel,
    verifyEmail,
  };
};

export default useVerifyEmail;
