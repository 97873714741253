import { createContext } from 'react';

type LanguageContextProps = {
  changeLanguage: (language: string) => void;
  auth0CurrentLanguage: string;
  t: (key: string) => string;
};

const LanguageContext = createContext<LanguageContextProps>({
  changeLanguage: () => {},
  auth0CurrentLanguage: '',
  t: (key) => key,
});

export default LanguageContext;
