import { DateTime, Duration } from 'luxon';

import { extractLanguage } from './i18n-utils';

type Precision = 'year' | 'month' | 'day' | 'hour' | 'minute';

export const formattedNowDate = (): string => {
  const today = new Date();

  const day = today.getDate();
  const month = today.getMonth() + 1; // Months are zero based
  const year = today.getFullYear();

  return `${year}-${month < 10 ? String(month).padStart(2, '0') : month}-${
    day < 10 ? String(day).padStart(2, '0') : day
  }`;
};

export const formatTimeDifference = (
  timeDifference: number,
  precision: Precision = 'day'
) => {
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0 || precision === 'year') {
    return { value: years, unit: 'year' };
  }

  if (months > 0 || precision === 'month') {
    return { value: months, unit: 'month' };
  }

  if (days > 0 || precision === 'day') {
    return { value: days, unit: 'day' };
  }

  if (hours > 0 || precision === 'hour') {
    return { value: hours, unit: 'hour' };
  }

  return { value: minutes, unit: 'minute' };
};

export const getHoursSince = (timestamp: string) => {
  const date = DateTime.fromISO(timestamp).toJSDate();
  const currentDate = new Date();
  const timeDifference = Math.abs(currentDate.getTime() - date.getTime());
  const result = Math.floor(timeDifference / (1000 * 60 * 60));
  return result;
};

export const calculateTimeSince = (
  timestamp: string,
  precision: Precision = 'day'
) => {
  const date = DateTime.fromISO(timestamp).toJSDate();
  const currentDate = new Date();
  const timeDifference = Math.abs(currentDate.getTime() - date.getTime());
  return formatTimeDifference(timeDifference, precision);
};

export const calculateTimeUntil = (timestamp: string) => {
  const date = DateTime.fromISO(timestamp).startOf('day').toJSDate();
  const currentDate = new Date();
  const currentDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  return date.getTime() - currentDay.getTime();
};

export const formatDate = (
  timestamp: string,
  i18nLanguage: string,
  style:
    | 'full'
    | 'long'
    | 'medium'
    | 'short'
    | 'month-year'
    | 'time-only'
    | 'month-day'
    | 'short-and-time'
    | undefined = 'long'
) => {
  const lang = extractLanguage(i18nLanguage) === 'fr' ? 'fr-CA' : 'en-CA';
  const options: Intl.DateTimeFormatOptions = {};
  const formattedDate = DateTime.fromISO(timestamp).toJSDate();

  if (style === 'month-year') {
    options.month = 'long';
    options.year = 'numeric';
  } else if (style === 'time-only') {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hour12 = lang !== 'fr-CA';
  } else if (style === 'month-day') {
    options.month = '2-digit';
    options.day = '2-digit';
  } else if (style === 'short-and-time') {
    options.year = 'numeric';
    options.month = '2-digit';
    options.day = '2-digit';
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hour12 = lang !== 'fr-CA';
  } else {
    options.dateStyle = style;
  }

  if (style === 'time-only' || style === 'short-and-time') {
    return new Intl.DateTimeFormat(lang, options)
      .format(formattedDate)
      .replace(' h ', ':')
      .trim();
  }

  return formattedDate.toLocaleDateString(lang, options);
};

export const isOverDeadline = (
  deadline: string | undefined,
  currentDate: string
): boolean => {
  if (!deadline) return false;
  return (
    DateTime.fromISO(currentDate).startOf('day') >
    DateTime.fromISO(deadline).startOf('day')
  );
};

export const formatDeadline = (
  deadline: string | undefined,
  language: string
): string | undefined => {
  if (!deadline) return undefined;
  return DateTime.fromISO(deadline).toLocaleString(DateTime.DATE_FULL, {
    locale: language,
  });
};

// Add new utils to use LUXON instead of Date
const formatDurationDifference = (duration: Duration) => {
  const arr = [];

  if (duration.years > 0) {
    arr.push({ value: duration.years, unit: 'year' });
  }
  if (duration.months > 0) {
    arr.push({ value: duration.months, unit: 'month' });
  }
  if (duration.days > 0) {
    arr.push({ value: duration.days, unit: 'day' });
    return arr;
  }
  if (duration.hours > 0) {
    arr.push({ value: Math.round(duration.hours), unit: 'hour' });
    return arr;
  }
  if (duration.minutes > 0) {
    arr.push({ value: Math.round(duration.minutes), unit: 'minute' });
  }
  return arr;
};

export const getDiffFromNow = (
  date: string | undefined
): { value: number; unit: string }[] | undefined => {
  if (!date) return undefined;

  const duration = DateTime.fromISO(date).diffNow([
    'years',
    'months',
    'days',
    'hours',
    'minutes',
  ]);

  return formatDurationDifference(duration);
};

export const chatPreviewDateFormatter = (
  timestamp: string,
  yesterdayString: string
) => {
  const now = DateTime.now();
  const date = DateTime.fromISO(timestamp);

  if (date.hasSame(now, 'day')) {
    return formatDate(timestamp, 'fr', 'time-only');
  }

  if (date.hasSame(now.minus({ days: 1 }), 'day')) {
    return yesterdayString;
  }

  if (date.hasSame(now, 'year')) {
    return formatDate(timestamp, 'fr', 'month-day');
  }

  return formatDate(timestamp, 'fr', 'short');
};

export const chatDateFormatter = (timestamp: string) => {
  const now = DateTime.now();
  const date = DateTime.fromISO(timestamp);

  if (date.hasSame(now, 'day') || date.hasSame(now.minus({ days: 1 }), 'day')) {
    return formatDate(timestamp, 'fr', 'time-only');
  }

  return formatDate(timestamp, 'fr', 'short-and-time');
};
