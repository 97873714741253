import { components, NoticeProps } from 'react-select';

import { OptionType } from '../types';

const LoadingMessage = ({ children, ...props }: NoticeProps<OptionType>) => (
  <components.LoadingMessage {...props}>
    <div className="py-s-16 text-center text-neutral-secondary font-medium">
      {children}
    </div>
  </components.LoadingMessage>
);

export default LoadingMessage;
