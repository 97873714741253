import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { OperationUnit, OperationUnitResponse } from '@domain/graphql.types';

type ToViewModel = {
  operationUnit: {
    data: OperationUnitResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  operationUnit: { data, fetching, error },
}: ToViewModel): ViewModel<OperationUnit | undefined> =>
  new ViewModel<OperationUnit | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.operationUnit || undefined,
  });
