import ReactPlayer from 'react-player';

import { cn } from '@utils/lib-utils';

type PlayerProps = {
  url: string;
  className?: string;
};
const Player = ({ url, className: customClassName }: PlayerProps) => (
  <div className={cn('break-inside-avoid', customClassName)}>
    <div className="relative z-[2] aspect-video">
      <ReactPlayer
        className="[&>div>iframe]:rounded-md"
        width="100%"
        height="100%"
        controls
        url={url}
      />
    </div>
  </div>
);
export default Player;
