import { useTranslation } from 'react-i18next';

import { NoContent, Table } from '@application/components/table';
import { PrivatePage } from '@application/enums/pagesUrl';
import { RequestStatusTypeCode } from '@domain/graphql.types';

import { useCandidateRequestsList } from '../hooks/useCandidateRequestsList';

const InProgressCandidateRequestsList = () => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'list.noContent.requests',
  });

  const {
    columns,
    currentPage,
    candidateRequests,
    sorting,
    count,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    isLoading,
  } = useCandidateRequestsList({ status: [RequestStatusTypeCode.InProgress] });

  return (
    <Table
      data={candidateRequests}
      columns={columns}
      count={count}
      onSortingChange={onSortingChange}
      sortingState={sorting}
      paginationState={currentPage}
      onPaginationChange={onPaginationChange}
      onPaginationReset={onPaginationReset}
      isRowColorChangeOnHover
      noDataComponent={
        <NoContent
          title={t('in_progress.title')}
          buttonLabel={t('in_progress.button')}
          path={PrivatePage.CANDIDATE_REQUEST_CREATE}
        />
      }
      isLoading={isLoading}
    />
  );
};

export default InProgressCandidateRequestsList;
