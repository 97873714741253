/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components';
import { mapDescriptions } from '@application/utils';
import {
  CandidateOffer,
  CandidateRequestType,
  JobPaymentFrequencyCode,
  OfferStatusTypeCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { BadgeList } from '../../../../../components/badge';
import { TD_CLASS_NAME, TH_CLASS_NAME } from '../../constants';

type EmployerBrandProps = {
  candidateRequestType?: CandidateRequestType;
  candidateOffers: readonly CandidateOffer[];
  checkedCandidateOffers: string[];
};

const EmployerBrand = ({
  candidateRequestType,
  candidateOffers,
  checkedCandidateOffers,
}: EmployerBrandProps) => {
  const { t, i18n } = useTranslation('candidates');

  const getOperationTerritories = useCallback(
    (co: CandidateOffer) =>
      mapDescriptions(
        co?.operationTerritories?.map((territory) => territory.descriptions) ||
          [],
        i18n.language
      ),
    [i18n.language]
  );

  const getRemuneration = useCallback(
    (co: CandidateOffer) => {
      switch (co.paymentFrequencyCode) {
        case JobPaymentFrequencyCode.Hourly:
          return t('suffix.currencyPerHourRange', {
            min: co.hourlyRateMin,
            max: co.hourlyRateMax,
          });
        case JobPaymentFrequencyCode.Yearly:
          return t('suffix.thousandsPerYearRange', {
            min: co.annualSalaryMin,
            max: co.annualSalaryMax,
          });
        case JobPaymentFrequencyCode.FixedAmount:
          return t('suffix.currency', { value: co.remunerationAmount });
        default:
          return '-';
      }
    },
    [t]
  );

  const getTdClassName = useCallback(
    (co: CandidateOffer) =>
      cn(TD_CLASS_NAME, {
        'border-x-2 !border-x-main': checkedCandidateOffers.includes(co.id),
        'opacity-65': co.status === OfferStatusTypeCode.Rejected,
      }),
    [checkedCandidateOffers]
  );

  return (
    <>
      <tr>
        <th className={TH_CLASS_NAME}>{t('labels.remunerationOffered')}</th>

        {candidateOffers.map((offer) => (
          <td key={offer.id} className={getTdClassName(offer)}>
            {getRemuneration(offer)}
          </td>
        ))}
      </tr>

      {candidateRequestType === CandidateRequestType.Recruitment ? (
        <tr>
          <th className={TH_CLASS_NAME}>{t('labels.otherRemunerations')}</th>

          {candidateOffers.map((offer) => (
            <td key={offer.id} className={getTdClassName(offer)}>
              <PlaceholderSwitcher value={offer.otherPaymentTypeCode}>
                {t(
                  `enum.jobPaymentOtherTypesCode.${offer.otherPaymentTypeCode?.toLowerCase()}`
                )}
              </PlaceholderSwitcher>
            </td>
          ))}
        </tr>
      ) : (
        <>
          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.hoursPerWeek')}</th>

            {candidateOffers.map((offer) => (
              <td key={offer.id} className={getTdClassName(offer)}>
                <PlaceholderSwitcher value={offer.hoursPerWeek}>
                  {offer.hoursPerWeek
                    ?.map((h) => t('suffix.hours', { value: h }))
                    .join(', ')}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>

          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.contractDuration')}</th>

            {candidateOffers.map((offer) => (
              <td key={offer.id} className={getTdClassName(offer)}>
                <PlaceholderSwitcher value={offer.potentialJobDuration}>
                  {t('suffix.month', {
                    count: Number(offer.potentialJobDuration),
                  })}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>

          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.potentialAmount')}</th>

            {candidateOffers.map((offer) => (
              <td key={offer.id} className={getTdClassName(offer)}>
                <PlaceholderSwitcher value={offer.potentialAmount}>
                  {t('suffix.currency', {
                    value: Number(offer.potentialAmount),
                  })}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>
        </>
      )}

      <tr>
        <th className={TH_CLASS_NAME}>{t('labels.operationTerritory')}</th>

        {candidateOffers.map((offer) => (
          <td key={offer.id} className={getTdClassName(offer)}>
            <PlaceholderSwitcher
              value={offer.operationTerritories?.length || undefined}
            >
              <BadgeList
                className="justify-center"
                values={getOperationTerritories(offer)}
              />
            </PlaceholderSwitcher>
          </td>
        ))}
      </tr>

      {candidateRequestType === CandidateRequestType.Recruitment && (
        <tr>
          <th className={TH_CLASS_NAME}>{t('labels.jobType')}</th>

          {candidateOffers.map((offer) => (
            <td key={offer.id} className={getTdClassName(offer)}>
              <PlaceholderSwitcher value={offer.jobType}>
                {t(`enum.jobDurationCode.${offer.jobType?.toLowerCase()}`)}
              </PlaceholderSwitcher>
            </td>
          ))}
        </tr>
      )}

      <tr>
        <th className={TH_CLASS_NAME}>{t('labels.remoteWork')}</th>

        {candidateOffers.map((offer) => (
          <td key={offer.id} className={getTdClassName(offer)}>
            <PlaceholderSwitcher value={offer.jobModeCode}>
              {t(`enum.jobModeCode.${offer.jobModeCode?.toLowerCase()}`)}
            </PlaceholderSwitcher>
          </td>
        ))}
      </tr>
    </>
  );
};

export default EmployerBrand;
