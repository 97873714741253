import { useTranslation } from 'react-i18next';

import { Button } from '../buttons';
import { ButtonLink } from '../links';

export type NoContentProps = {
  title?: string;
  message?: string;
  onClick?: () => void;
  path?: string;
  buttonLabel?: string;
};

const NoContent = ({
  title,
  message,
  onClick,
  path,
  buttonLabel,
}: NoContentProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-s-24 p-s-64">
      <h2 className="h3 text-center max-w-[44rem]">
        {title || t('table.noContent')}
      </h2>

      {!!message && (
        <span className="h5 text-neutral-secondary text-center max-w-[44rem]">
          {message}
        </span>
      )}

      {onClick && (
        <Button className="max-w-[44rem]" primary onClick={onClick}>
          {buttonLabel || t('table.startNow')}
        </Button>
      )}

      {path && (
        <ButtonLink className="max-w-[44rem]" primary to={path}>
          {buttonLabel || t('table.startNow')}
        </ButtonLink>
      )}
    </div>
  );
};

export default NoContent;
