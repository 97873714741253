import { gql } from '@urql/core';

const MutationRequestDuplicate = gql`
  mutation RequestDuplicate($input: DuplicateRequestInput!) {
    requestDuplicate(input: $input) {
      request {
        id
      }
    }
  }
`;

export default MutationRequestDuplicate;
