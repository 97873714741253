import { gql } from '@urql/core';

import { CandidateOfferFragment } from './fragment';

const CandidateOffer = gql`
  ${CandidateOfferFragment}

  query CandidateOffer($input: CandidateOfferInput!) {
    candidateOffer(input: $input) {
      candidateOffer {
        ...CandidateOfferFragment
      }
    }
  }
`;

export default CandidateOffer;
