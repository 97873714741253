import { CombinedError } from 'urql';

import { extractErrorCodes, ValueText } from '@application/utils';
import ViewModel from '@application/view-model';
import { Query } from '@domain/graphql.types';

import { normalizeGetPerksData } from '../normalizers';

type ToViewModel = {
  accountAdvantageTypes: {
    data:
      | Pick<Query, 'accountAdvantageTypes'>
      | Record<string, never>
      | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  accountAdvantageTypes: { data = {}, fetching, error },
}: ToViewModel): ViewModel<ValueText[]> =>
  new ViewModel<ValueText[]>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: normalizeGetPerksData(data),
  });
