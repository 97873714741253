import { gql } from '@urql/core';

const InvitationResend = gql`
  mutation InvitationResend($input: ResendInvitationInput!) {
    invitationResend(input: $input) {
      ok
    }
  }
`;

export default InvitationResend;
