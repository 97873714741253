import { CandidateRequestType } from '@domain/graphql.types';

export const CREATE_CANDIDATE_REQUEST_STEPS = [
  {
    title: 'steps.description',
    icon: 'ri-suitcase-line',
  },
  {
    title: 'steps.conditions',
    icon: 'ri-list-check-3',
  },
  // {
  //   title: 'steps.validation',
  //   icon: 'ri-check-double-line',
  // },
];

export const DEFAULT_VALUES = {
  jobSpecialtyId: null,
  desiredStartDate: null,
  receivingOfferDeadline: null,
  metAt: null,
  hoursPerWeek: [],
  fullTimeAvailability: false,
  partTimeAvailability: false,
  annualSalaryMin: 20,
  annualSalaryMax: 200,
  hourlyRateMin: 20,
  hourlyRateMax: 200,
  type: CandidateRequestType.Recruitment,
  softSkills: [],
  recruitmentConditionGuaranteedPeriodDisplay: true,
  recruitmentConditionRemunerationTypeDisplay: true,
  recruitmentConditionProbationPeriodDisplay: true,
  recruitmentConditionPaymentMethodDisplay: true,
  recruitmentConditionBonusDisplay: true,
  temporaryPlacementConditionProbationPeriodDisplay: true,
  temporaryPlacementConditionMinimumNoticeDisplay: true,
  temporaryPlacementConditionPossibleBuybackDisplay: true,
  temporaryPlacementConditionPossibleBuybackBonusDisplay: true,
  temporaryPlacementConditionResponsibilitiesDisplay: true,
  temporaryPlacementConditionBillingPeriodDisplay: true,
  outsourcingConditionMinimumNoticeDisplay: true,
  outsourcingConditionBillingPeriodDisplay: true,
};
