import { rem } from './utils.cjs';

/**
 * This is the main Tailwind config. We separated it from the initial `tailwind.config.js` file
 * in order to access some of our theme values we want to dynamically apply to a React component.
 * This way we avoid huge bundle size to the client using the default `resolveConfig` helper
 * provided by Tailwind and can do without the use of any tool to avoid that.
 */
export default {
  daisyui: {
    logs: true,
    themes: [
      {
        kehops: {
          // Main daisyUI variables overrides
          primary: '#211F35', // Style guide reference: Background/Hightlight/Default
          'primary-content': '#FEFEFE', // Style guide reference: Background/Neutral/Primary
          secondary: '#D3D8F2', // Style guide reference: Background/Neutral/Tertiary
          'secondary-content': '#201E24',
          accent: '#72E3A1', // Style guide reference: Content/Action/Default
          'accent-content': '#201E24',
          neutral: '#F6F7FB', // Style guide reference: Background/Neutral/Secondary
          'neutral-content': '#201E24',
          'base-100': '#FEFEFE', // Style guide reference: Background/Neutral/Primary
          'base-200': '#E4E4E9',
          'base-content': '#201E24',
          info: '#E5F2FF',
          'info-content': '#004999',
          success: '#EFF9ED',
          'success-content': '#2F7E22',
          warning: '#FEF1E5',
          'warning-content': '#BF4600',
          error: '#FEE5E5',
          'error-content': '#CE1515',
          // Do not add any other variables here that it's not defined in daisyUI.
          // It won't work that way. Add them to the default tailwind theme instead.
        },
      },
    ],
  },
  theme: {
    extend: {
      colors: {
        current: 'currentColor',
        title: 'hsl(var(--title))',
        main: '#201E24',
        readonly: '#E4E4E9',
        disabled: {
          content: '#4C4856',
          default: '#CECBD6',
          light: '#F6F6F6',
        },
        stroke: {
          default: '#CECBD6',
          light: '#E5E7EB',
          dark: '#201E24',
        },
        neutral: {
          secondary: '#4C4856',
        },
        badge: {
          light: {
            blue: '#D1ECFF',
            gray: '#F1F1F5',
            green: '#EFF9ED',
            orange: '#FEF1E5',
            pink: '#FEEBFA',
            purple: '#E6E2F8',
            red: '#FEEBE9',
            yellow: '#FCF3D2',
          },
          blue: '#004999',
          gray: '#4C4856',
          green: '#2F7E22',
          orange: '#BF4600',
          pink: '#8F0E73',
          purple: '#3B2C86',
          red: '#CA3521',
          yellow: '#7D5608',
        },
        content: {
          action: {
            dark: '#095C36',
            light: '#EDFCF2',
          },
        },
        rankBadge: {
          first: '#F5CA5F',
          second: '#E0D3EF',
          third: '#E39E6F',
        },
        skeleton: '#EDEFFA',
      },
      lineHeight: {
        initial: 'initial',
      },
      /** Override the borderWidth property so by default className="border" will result in border: 1px */
      borderWidth: {
        DEFAULT: '1px',
        1: '1px',
        5: '5px',
      },
      width: {
        inherit: 'inherit',
        available: '-webkit-fill-available',
      },
      height: {
        available: '-webkit-fill-available',
      },
      flex: {
        2: '2 2 0%',
        3: '3 3 0%',
        4: '4 4 0%',
        5: '5 5 0%',
      },
      opacity: {
        32: '0.32',
      },
      zIndex: {
        background: '0',
        dropdown: '10',
        sticky: '20',
        fixed: '30',
        'modal-backdrop': '40',
        modal: '50',
        alert: '60',
        tooltip: '70',
      },
    },
    /**
     * Custom spacing based on the style guide
     *
     */
    spacing: {
      's-0': 0, // 0px
      's-1': rem(1), // 1px
      's-2': rem(2), // 2px
      's-4': rem(4), // 4px
      's-6': rem(6), // 6px
      's-8': rem(8), // 8px
      's-10': rem(10), // 10px
      's-12': rem(12), // 12px
      's-14': rem(14), // 14px
      's-16': rem(16), // 16px
      's-18': rem(18), // 18px
      's-20': rem(20), // 20px
      's-24': rem(24), // 24px
      's-28': rem(28), // 28px
      's-32': rem(32), // 32px
      's-36': rem(36), // 36px
      's-40': rem(40), // 40px
      's-44': rem(44), // 44px
      's-48': rem(48), // 48px
      's-56': rem(56), // 56px
      's-64': rem(64), // 64px
      's-72': rem(72), // 72px
      's-80': rem(80), // 80px
      's-96': rem(96), // 96px
      's-112': rem(112), // 112px
      's-120': rem(120), // 120px
      's-128': rem(128), // 128px
      's-144': rem(144), // 144px
      's-160': rem(160), // 160px
      's-176': rem(176), // 176px
      's-192': rem(192), // 192px
      's-208': rem(208), // 208px
      's-224': rem(224), // 224px
      's-240': rem(240), // 240px
      's-256': rem(256), // 256px
      's-288': rem(288), // 288px
      's-320': rem(320), // 320px
      's-384': rem(384), // 384px
    },
    /**
     * Custom `fontSize` based on the style guide
     *
     */
    fontSize: {
      6: [rem(6), { lineHeight: 10 / 6 }], // 6px
      8: [rem(8), { lineHeight: 10 / 8 }], // 8px
      10: [rem(10), { lineHeight: 14 / 10 }], // 10px
      12: [rem(12), { lineHeight: 16 / 12 }], // 12px
      14: [rem(14), { lineHeight: 20 / 14 }], // 14px
      16: [rem(16), { lineHeight: 24 / 16 }], // 16px
      18: [rem(18), { lineHeight: 28 / 18 }], // 18px
      20: [rem(20), { lineHeight: 28 / 20 }], // 20px
      22: [rem(22), { lineHeight: 30 / 22 }], // 22px
      24: [rem(24), { lineHeight: 32 / 24 }], // 24px
      32: [rem(32), { lineHeight: 40 / 32 }], // 32px
      40: [rem(40), { lineHeight: 48 / 40 }], // 40px
      64: [rem(64), { lineHeight: 72 / 64 }], // 64px
      80: [rem(80), { lineHeight: 88 / 80 }], // 80px
    },
    fontFamily: {
      body: ['Inter', 'sans-serif'],
      title: ['Inter', 'sans-serif'],
    },
    boxShadow: {
      DEFAULT: '0 6px 12px rgba(0, 0, 0, 0.08)',
      none: 'none',
      inner: 'inset 0 0 3px, 0 0 6px',
      xs: '0 4px 8px 0px rgba(0, 0, 0, 0.04)',
      sm: '0 6px 12px rgba(0, 0, 0, 0.08)',
      md: '0 10px 24px rgba(0, 0, 0, 0.12)',
      lg: '0 16px 48px rgba(0, 0, 0, 0.2)',
    },
    borderRadius: {
      DEFAULT: `${rem(4)}`,
      none: '0',
      sm: `${rem(4)}`,
      md: `${rem(8)}`,
      lg: `${rem(24)}`,
      full: '9999px',
    },
    /**
     * IMPORTANT: Do not change `px` to `em` or `rem`
     * due to a bug on safari which handle those units differently for media queries.
     * https://cgamesplay.com/post/2023/05/19/stop-using-em-in-media-queries/
     * https://keithjgrant.com/posts/2023/05/px-vs-em-in-media-queries/
     */
    screens: {
      xs: '375px',
      sm: '576px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1440px',
      '3xl': '1920px',
    },
  },
};
