import { gql } from 'urql';

const RecruitmentNegotiationInternationalRecruitmentPropertiesStateFragment = gql`
  fragment RecruitmentNegotiationInternationalRecruitmentPropertiesStateFragment on RecruitmentNegotiationInternationalRecruitmentPropertiesState {
    exclusivityPeriod
    maximumReplacement
  }
`;

export default RecruitmentNegotiationInternationalRecruitmentPropertiesStateFragment;
