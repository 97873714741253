import { useMutation } from 'urql';

import { Mutation, MutationRequestUpdateArgs } from '@domain/graphql.types';
import { MutationRequestUpdate } from '@infrastructure/graphql/request';

const useRequestUpdate = () => {
  const [{ data, fetching, error }, updateRequest] = useMutation<
    Pick<Mutation, 'requestUpdate'>,
    MutationRequestUpdateArgs
  >(MutationRequestUpdate);

  return {
    updateRequest,
    data,
    fetching,
    error,
  };
};

export default useRequestUpdate;
