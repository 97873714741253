import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, LanguageButtons } from '@application/components/buttons';
import { TermsAndConditionsCheckbox } from '@application/components/terms-and-conditions';
import { AccountContext, UserContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';

import useAcceptInvitation from './actions/useAcceptInvitation';
import { ACCEPT_INVITATION_SCHEMA, AcceptInvitationFormFields } from './schema';

const AcceptInvitationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('invitation', { keyPrefix: 'acceptInvitation' });

  const { accountUser, setHasFinishedOnboarding, setHasPendingInvitation } =
    useContext(UserContext);
  const { refreshAccount } = useContext(AccountContext);

  const {
    viewModel: { isLoading },
    acceptInvitation,
  } = useAcceptInvitation();

  const { control, handleSubmit, watch } = useForm<AcceptInvitationFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(ACCEPT_INVITATION_SCHEMA),
    defaultValues: {
      accountUserId: accountUser?.id,
      termsAndConditionsAccepted: false,
    },
  });

  const onSubmit: SubmitHandler<AcceptInvitationFormFields> = useCallback(
    async (data) => {
      const result = await acceptInvitation({
        input: data,
      });

      if (result.data?.invitationAccept.accountUser) {
        refreshAccount();

        setHasFinishedOnboarding(true);
        setHasPendingInvitation(false);

        navigate(PrivatePage.DASHBOARD);
      }
    },
    [
      acceptInvitation,
      navigate,
      refreshAccount,
      setHasFinishedOnboarding,
      setHasPendingInvitation,
    ]
  );

  const termsAndConditionsAccepted = watch('termsAndConditionsAccepted');

  return (
    <div className="flex flex-col">
      <div className="flex justify-end p-s-32">
        <LanguageButtons />
      </div>

      <div className="flex flex-col items-center justify-center gap-s-24 h-full w-full">
        <h2>{t('welcome')}</h2>

        <p className="text-center">
          <Trans
            i18nKey="invitationMessage"
            t={t}
            values={{ organization: accountUser?.account?.name }}
          />
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <TermsAndConditionsCheckbox
            control={control}
            name="termsAndConditionsAccepted"
          />

          <Button
            primary
            loading={isLoading}
            type="submit"
            disabled={!termsAndConditionsAccepted}
          >
            {t('acceptInvitation')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AcceptInvitationPage;
