import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { StatusTabs } from '@application/components';
import { CandidateNegotiationRoomStatus } from '@domain/graphql.types';

import { CandidateNegotiationRoomsList } from './list';

const CandidateNegotiationRoomListPage = () => {
  const { t } = useTranslation('candidates');

  const navigate = useNavigate();

  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      navigate('#in-progress', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <>
      <div className="w-fit">
        <StatusTabs
          t={t}
          type="candidate-negotiation"
          statuses={[
            CandidateNegotiationRoomStatus.InProgress,
            CandidateNegotiationRoomStatus.Accepted,
            CandidateNegotiationRoomStatus.Rejected,
          ]}
        />
      </div>

      <CandidateNegotiationRoomsList />
    </>
  );
};

export default CandidateNegotiationRoomListPage;
