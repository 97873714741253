import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';

import Modal from './Modal';

export type ConfirmationModalProps = {
  confirmButtonClassName?: string;
  content: ReactNode | null;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({
  confirmButtonClassName: customConfirmButtonClassName,
  content,
  onCancel,
  onConfirm,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.CloseBtn onClick={onCancel} />
      <div>{content}</div>
      <Modal.Action>
        <Button onClick={onCancel}>{t('button.cancel')}</Button>
        <Button
          className={customConfirmButtonClassName}
          onClick={onConfirm}
          primary
        >
          {t('button.confirm')}
        </Button>
      </Modal.Action>
    </>
  );
};

export default ConfirmationModal;
