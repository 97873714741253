import { gql } from '@urql/core';

const MutationCandidateOffersReject = gql`
  mutation CandidateOffersReject($input: RejectCandidateOffersInput!) {
    candidateOffersReject(input: $input) {
      candidateOffers {
        id
      }
    }
  }
`;

export default MutationCandidateOffersReject;
