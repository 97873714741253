import { useQuery } from 'urql';

import { Query, QueryOperationUnitsArgs } from '@domain/graphql.types';
import { OperationUnits } from '@infrastructure/graphql/operation';
import { defaultPageData } from '@infrastructure/graphql/pagination';
import { flattenEdges } from '@utils/data-utils';

const useOperationUnits = (input?: QueryOperationUnitsArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'operationUnits'>,
    QueryOperationUnitsArgs
  >({
    query: OperationUnits,
    variables: input,
  });

  const { data: paginatedData, fetching, error } = result;

  const pageData = paginatedData?.operationUnits.pageData || defaultPageData;
  const data = flattenEdges(paginatedData?.operationUnits.page.edges?.slice());

  return {
    data,
    pageData,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useOperationUnits;
