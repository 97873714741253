export type SwitchProps = {
  /**
   * Specify a custom `id` for the `<input>`
   */
  id?: string;
  /**
   * Specify the name of the `<input>`
   */
  name: string;
  /**
   * Specify the value of the `<input>`
   */
  value?: string | number | undefined;
  /**
   * Specify whether the `<input>` should be checked
   */
  checked?: boolean;
  /**
   * Specify whether the `<input>` should be disabled
   */
  disabled?: boolean;
  /**
   * Provide a custom className to be applied to the `<input>`
   */
  className?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optionally provide an `onBlur` handler that is called whenever `<input>` loses focus
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

const Switch = ({
  id,
  name,
  value,
  placeholder,
  disabled,
  className: customClassName,
  checked,
  onChange,
  ...rest
}: SwitchProps) => (
  <input
    type="checkbox"
    id={id}
    name={name}
    value={value}
    placeholder={placeholder}
    disabled={disabled}
    onChange={onChange}
    className="toggle toggle-accent"
    checked={checked}
    {...rest}
  />
);

export default Switch;
