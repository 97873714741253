import { cn } from '@utils/lib-utils';

export type TextAreaProps = {
  /**
   * Specify a custom `id` for the `<textarea>`
   */
  id?: string;
  /**
   * Specify the name of the `<textarea>`
   */
  name: string;
  /**
   * Specify the value of the `<textarea>`
   */
  value?: string | number | undefined;
  /**
   * Specify the placeholder attribute for the `<textarea>`
   */
  placeholder?: string;
  /**
   * Specify the height of the `<textarea>` (in lines)
   */
  rows?: number;
  /**
   * Specify the visible width of the `<textarea>`
   */
  cols?: number;
  /**
   * specifies how the text in the `<textarea>` is to be wrapped when submitted in a form.
   */
  wrap?: 'soft' | 'hard';
  /**
   *  Specify the minimum of character allowed for the `<textarea>`
   */
  minChar?: number;
  /**
   *  Specify the maximum of character allowed for the `<textarea>`
   */
  maxLength?: number;
  /**
   * Specify whether the `<textarea>` should be disabled
   */
  disabled?: boolean;
  /**
   * Specify wether the `<textarea>` should be invalid
   */
  invalid?: boolean;
  /**
   * Provide a custom className to be applied to the `<textarea>`
   */
  className?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever `<textarea>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * Optionally provide an `onBlur` handler that is called whenever `<textarea>` loses focus
   */
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
} & Omit<React.ComponentPropsWithoutRef<'textarea'>, 'size'>;

const TextArea = ({
  id,
  name,
  value,
  placeholder,
  rows,
  cols,
  wrap,
  minChar,
  maxLength,
  disabled,
  invalid,
  className: customClassName,
  onChange,
  ...rest
}: TextAreaProps) => {
  const className = cn(
    'py-s-10 textarea textarea-bordered w-full min-w-max text-16 border-stroke-default focus:border-primary focus:outline-transparent placeholder:text-neutral-secondary',
    customClassName,
    {
      'text-error-content bg-error !border-error-content focus:!outline-error-content':
        invalid,
      'disabled:text-disabled-content': disabled,
      'disabled:text-disabled-content disabled:border-error-content':
        disabled && invalid,
    }
  );

  return (
    <textarea
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      wrap={wrap}
      minLength={minChar}
      maxLength={maxLength}
      disabled={disabled}
      onChange={onChange}
      className={className}
      {...rest}
    />
  );
};

export default TextArea;
