import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useCandidateOfferSaveAndPublish } from '@domain/candidate/offer';
import { CandidateOfferErrors } from '@domain/graphql.types';

import { toViewModel } from '../view-models/save-and-publish-candidate-offer.vm';

const useSaveAndPublishCandidateOffer = () => {
  const { data, fetching, error, saveAndPublish } =
    useCandidateOfferSaveAndPublish();

  const { t } = useTranslation('candidates', {
    keyPrefix: 'messages',
  });

  const navigate = useNavigate();
  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    candidateOffer: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    const CannotPublishOfferRequestCompleted = error.graphQLErrors.find(
      (e) =>
        e.message === CandidateOfferErrors.CannotPublishOfferRequestCompleted
    );

    setAlertContent(
      CannotPublishOfferRequestCompleted
        ? {
            text: t('cannotPublishOfferRequestCompletedError'),
            modifier: 'alert-error',
          }
        : {
            action: t('saveAndPublishOfferError'),
            modifier: 'alert-error',
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateOfferUpdate?.candidateOffer) {
      setAlertContent({
        action: t('saveAndPublishOfferSuccess'),
        modifier: 'alert-success',
      });

      navigate(
        generatePath(PrivatePage.VIEW_CANDIDATE_OFFER, {
          id: data.candidateOfferUpdate.candidateOffer.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateOfferUpdate?.candidateOffer]);

  return { saveAndPublish, viewModel };
};

export default useSaveAndPublishCandidateOffer;
