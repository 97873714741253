import React, { ForwardedRef, ReactNode } from 'react';

import { Link } from '@application/components/links';
import { cn } from '@utils/lib-utils';

type IconLinkProps = {
  children: ReactNode;
  className?: string;
  icon: string;
  to: string;
  disabled?: boolean;
};

type Ref = HTMLAnchorElement;

const IconLink = React.forwardRef<Ref, IconLinkProps>(
  (
    { icon, to, className: customClassName, children, disabled }: IconLinkProps,
    ref: ForwardedRef<Ref>
  ) => (
    <Link
      ref={ref}
      to={to}
      role="link"
      aria-disabled={disabled}
      className={cn(
        'inline-flex items-center gap-s-16 no-underline',
        disabled && 'opacity-20',
        customClassName
      )}
    >
      <span className="badge w-s-48 h-s-48 rounded-md bg-accent border-accent">
        <i className={cn('text-20 font-normal leading-[initial]', icon)} />
      </span>
      <span className="underline">{children}</span>
    </Link>
  )
);

export default IconLink;
