import { gql } from '@urql/core';

import { AccountFragment } from '@infrastructure/graphql/account';

const RequestAcceptedOffer = gql`
  ${AccountFragment}

  query RequestAcceptedOffer($input: RequestAcceptedOfferInput!) {
    requestAcceptedOffer(input: $input) {
      offer {
        account {
          ...AccountFragment
        }
        user {
          name
          email
        }
      }
    }
  }
`;

export default RequestAcceptedOffer;
