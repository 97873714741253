import { useCallback } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Switcher, TextInputField } from '@application/components';
import { OfferFormFields } from '@application/views/recruitment/offer/types';

type PersonalInformationFieldsProps = {
  index: number;
};

const PersonalInformationFields = ({
  index,
}: PersonalInformationFieldsProps) => {
  const { t } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const handleVisibilityChange = useCallback(
    (field: string, value: boolean) => {
      setValue(
        `offerCandidates.${index}.${field}` as keyof OfferFormFields,
        value
      );
    },
    [index, setValue]
  );

  const firstNameDisplay = watch(`offerCandidates.${index}.firstNameDisplay`);
  const lastNameDisplay = watch(`offerCandidates.${index}.lastNameDisplay`);
  const emailDisplay = watch(`offerCandidates.${index}.emailDisplay`);

  return (
    <Switcher space={24} limit={3}>
      <TextInputField
        label={t('labels.firstName')}
        invalid={!!errors.offerCandidates?.[index]?.firstName}
        onDisplayChange={(value) =>
          handleVisibilityChange('firstNameDisplay', value)
        }
        displayValue={firstNameDisplay || false}
        helperText={
          errors.offerCandidates?.[index]?.firstName?.message &&
          tGlobal(
            errors.offerCandidates?.[index]?.firstName?.message as string,
            {
              max: '128',
            }
          )
        }
        {...register(`offerCandidates.${index}.firstName`)}
      />

      <TextInputField
        label={t('labels.lastName')}
        invalid={!!errors.offerCandidates?.[index]?.lastName}
        onDisplayChange={(value) =>
          handleVisibilityChange('lastNameDisplay', value)
        }
        displayValue={lastNameDisplay || false}
        helperText={
          errors.offerCandidates?.[index]?.lastName?.message &&
          tGlobal(
            errors.offerCandidates?.[index]?.lastName?.message as string,
            {
              max: '128',
            }
          )
        }
        {...register(`offerCandidates.${index}.lastName`)}
      />

      <TextInputField
        label={t('labels.email')}
        invalid={!!errors.offerCandidates?.[index]?.email}
        onDisplayChange={(value) =>
          handleVisibilityChange('emailDisplay', value)
        }
        displayValue={emailDisplay || false}
        helperText={
          errors.offerCandidates?.[index]?.email?.message &&
          tGlobal(errors.offerCandidates?.[index]?.email?.message as string, {
            max: '128',
          })
        }
        {...register(`offerCandidates.${index}.email`)}
      />
    </Switcher>
  );
};

export default PersonalInformationFields;
