import { gql } from '@urql/core';

import SubscriptionPromoFragment from './subscriptionPromo.fragment';

const GetSubscriptionPromo = gql`
  ${SubscriptionPromoFragment}

  mutation GetSubscriptionPromo($input: GetSubscriptionPromoInput!) {
    getSubscriptionPromo(input: $input) {
      ...SubscriptionPromoFragment
    }
  }
`;

export default GetSubscriptionPromo;
