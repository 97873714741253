import { gql } from '@urql/core';

import OperationUnitFragment from './operation.unit.fragment';

const OperationUnit = gql`
  ${OperationUnitFragment}

  query OperationUnit($input: OperationUnitInput!) {
    operationUnit(input: $input) {
      operationUnit {
        ...OperationUnitFragment
      }
    }
  }
`;

export default OperationUnit;
