import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { UserUpdateNotificationPreferencesResponse } from '@domain/graphql.types';

type ToViewModel = {
  updateNotificationPreferences: {
    data: UserUpdateNotificationPreferencesResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  updateNotificationPreferences: { data, fetching, error },
}: ToViewModel): ViewModel<
  UserUpdateNotificationPreferencesResponse | undefined
> =>
  new ViewModel<UserUpdateNotificationPreferencesResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
