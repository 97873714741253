import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useNegotiatingCandidateOfferUpdate } from '@domain/offer';

import { toViewModel } from '../view-models/update-negotiating-candidate-offer.vm';

const useUpdateNegotiatingCandidateOffer = () => {
  const { data, fetching, error, updateNegotiatingCandidateOffer } =
    useNegotiatingCandidateOfferUpdate();

  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    negotiatingCandidateOffer: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('updateError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.negotiatingCandidateOfferUpdate.candidateOffer) {
      setAlertContent({
        action: t('updateSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { updateNegotiatingCandidateOffer, viewModel };
};

export default useUpdateNegotiatingCandidateOffer;
