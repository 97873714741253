import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';

import { ErrorBoundary } from '@application/components/error';
import {
  AccountTypeGuard,
  AuthGuard,
  CompletedOnboardingGuard,
  NoAuthGuard,
  OnboardingGuard,
  UserGuard,
  UserRoleGuard,
} from '@application/components/guards';
import {
  OnboardingLayout,
  PrivateLayout,
  PublicLayout,
} from '@application/components/layouts';
import { PrivatePage } from '@application/enums/pagesUrl';
import {
  ONBOARDING_ROUTES,
  PRIVATE_AGENCY_ROUTES,
  PRIVATE_ENTERPRISE_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
} from '@application/router/routes';
import {
  AccountManagementPanel,
  JobOpportunitiesPage,
  MembersRolesPanel,
  OrganizationPage,
  ProfilePanel,
  UserNotificationsManagementPage,
  UserPage,
  UserProfilePage,
  UserSecurityManagementPage,
} from '@application/views';
import { AccountTypeCode, UserRole } from '@domain/graphql.types';

const Router = () => {
  const router = createBrowserRouter(
    [
      {
        element: <Outlet />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: (
              <NoAuthGuard>
                <PublicLayout>
                  <Outlet />
                </PublicLayout>
              </NoAuthGuard>
            ),
            children: Object.entries(PUBLIC_ROUTES).map(([path, element]) => ({
              path,
              element,
            })),
          },
          {
            element: (
              <AuthGuard>
                <UserGuard>
                  <OnboardingGuard>
                    <OnboardingLayout>
                      <Outlet />
                    </OnboardingLayout>
                  </OnboardingGuard>
                </UserGuard>
              </AuthGuard>
            ),
            children: Object.entries(ONBOARDING_ROUTES).map(
              ([path, element]) => ({ path, element })
            ),
          },
          {
            element: (
              <AuthGuard>
                <UserGuard>
                  <CompletedOnboardingGuard>
                    <PrivateLayout>
                      <Outlet />
                    </PrivateLayout>
                  </CompletedOnboardingGuard>
                </UserGuard>
              </AuthGuard>
            ),
            children: [
              {
                element: <Outlet />,
                children: [
                  ...Object.entries(PRIVATE_ROUTES).map(([path, element]) => ({
                    path,
                    element,
                  })),
                  {
                    element: (
                      <UserRoleGuard
                        authorizedUserRoles={[
                          UserRole.SuperAdmin,
                          UserRole.Owner,
                          UserRole.Admin,
                        ]}
                      >
                        <OrganizationPage />
                      </UserRoleGuard>
                    ),
                    children: [
                      {
                        element: <MembersRolesPanel />,
                        path: PrivatePage.ORGANIZATION_USERS,
                      },
                      {
                        element: <ProfilePanel />,
                        path: PrivatePage.ORGANIZATION_PROFILE,
                      },
                      {
                        element: <AccountManagementPanel />,
                        path: PrivatePage.ORGANIZATION_ACCOUNT_MANAGEMENT,
                      },
                      {
                        path: PrivatePage.ORGANIZATION_REQUESTS_OFFERS,
                        element: (
                          <Navigate
                            to={PrivatePage.ORGANIZATION_JOB_OPPORTUNITIES}
                            replace
                          />
                        ),
                      },
                      {
                        element: <JobOpportunitiesPage />,
                        path: PrivatePage.ORGANIZATION_JOB_OPPORTUNITIES,
                      },
                    ],
                  },
                  {
                    element: <UserPage />,
                    children: [
                      {
                        path: PrivatePage.USER_MANAGEMENT,
                        element: (
                          <Navigate
                            to={PrivatePage.USER_SECURITY_MANAGEMENT}
                            replace
                          />
                        ),
                      },
                      {
                        element: <UserProfilePage />,
                        path: PrivatePage.USER_PROFILE,
                      },
                      {
                        element: <UserSecurityManagementPage />,
                        path: PrivatePage.USER_SECURITY_MANAGEMENT,
                      },
                      {
                        element: <UserNotificationsManagementPage />,
                        path: PrivatePage.USER_NOTIFICATIONS_MANAGEMENT,
                      },
                    ],
                  },
                ],
              },
              {
                element: (
                  <AccountTypeGuard
                    authorizedAccountType={AccountTypeCode.Agency}
                  >
                    <Outlet />
                  </AccountTypeGuard>
                ),
                children: [
                  ...Object.entries(PRIVATE_AGENCY_ROUTES).map(
                    ([path, element]) => ({
                      path,
                      element,
                    })
                  ),
                ],
              },
              {
                element: (
                  <AccountTypeGuard
                    authorizedAccountType={AccountTypeCode.Enterprise}
                  >
                    <Outlet />
                  </AccountTypeGuard>
                ),
                children: [
                  ...Object.entries(PRIVATE_ENTERPRISE_ROUTES).map(
                    ([path, element]) => ({
                      path,
                      element,
                    })
                  ),
                ],
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to={PrivatePage.DASHBOARD} replace />,
          },
        ],
      },
    ],
    {
      future: {
        v7_normalizeFormMethod: true,
      },
    }
  );

  return <RouterProvider router={router} />;
};

export default Router;
