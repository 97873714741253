import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from './SwitchMatch';

type VisibilitySwitcherProps = {
  visible: boolean;
  content: ReactNode;
};

const VisibilitySwitcher = ({ visible, content }: VisibilitySwitcherProps) => {
  const { t } = useTranslation();

  return (
    <Switch
      fallback={<span className="text-16">{t('labels.notVisible')}</span>}
    >
      <Switch.Match when={visible}>{content}</Switch.Match>
    </Switch>
  );
};

export default VisibilitySwitcher;
