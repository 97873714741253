import { gql } from 'urql';

const RecruitmentNegotiationTemporaryPlacementPropertiesStateFragment = gql`
  fragment RecruitmentNegotiationTemporaryPlacementPropertiesStateFragment on RecruitmentNegotiationTemporaryPlacementPropertiesState {
    cnesstExpensesResponsibility
    employeeHelpProgramResponsibility
    possibleBuyback
    trainingExpensesResponsibility
    travelExpensesResponsibility
  }
`;

export default RecruitmentNegotiationTemporaryPlacementPropertiesStateFragment;
