import {
  AccountOperationTerritoriesFilters,
  AccountOperationTerritoriesSortDirective,
} from '@domain/graphql.types';

import { useAccountOperationTerritories } from '../../../../../domain/account';
import { Pagination } from '../../../../../domain/pagination/pagination';
import { toViewModel } from '../view-models/get-account-operation-territories.vm';

const useGetAccountOperationTerritories = (
  sortBy?: AccountOperationTerritoriesSortDirective[],
  filterBy?: AccountOperationTerritoriesFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } =
    useAccountOperationTerritories({
      sortBy,
      filterBy,
      ...pagination,
    });

  const viewModel = toViewModel({
    accountOperationTerritories: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchAccountOperationTerritories: reexecuteQuery,
  };
};

export default useGetAccountOperationTerritories;
