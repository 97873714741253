import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Query } from '@domain/graphql.types';

import { normalizeCandidateNegotiationRoomAgreement } from '../normalizers';
import { CandidateNegotiationRoomAgreement } from '../types';

type ToViewModel = {
  agreement: {
    data: Pick<Query, 'candidateNegotiationRoom'> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  agreement: { data, fetching, error },
}: ToViewModel): ViewModel<CandidateNegotiationRoomAgreement | undefined> =>
  new ViewModel<CandidateNegotiationRoomAgreement | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: normalizeCandidateNegotiationRoomAgreement(
      data?.candidateNegotiationRoom?.room
    ),
  });
