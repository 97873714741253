import { gql } from 'urql';

const SendPasswordChangeRequest = gql`
  mutation SendPasswordChangeRequest(
    $input: UserSendPasswordChangeRequestInput!
  ) {
    userSendPasswordChangeRequest(input: $input) {
      ok
    }
  }
`;

export default SendPasswordChangeRequest;
