import { useTranslation } from 'react-i18next';

import { useLanguage } from '@application/hooks';

import Button from './Button';

type LanguageButtonsProps = {
  ghost?: boolean;
};

const LanguageButtons = ({ ghost }: LanguageButtonsProps) => {
  const { i18n } = useTranslation();
  const { changeLanguage } = useLanguage();

  const handleChangeLanguage = (language: string) => {
    changeLanguage(language);
  };

  return i18n.resolvedLanguage === 'fr' ? (
    <Button
      size="medium"
      className="w-s-48 !min-w-[3rem] h-s-48"
      onClick={() => handleChangeLanguage('en')}
      ghost={ghost}
    >
      EN
    </Button>
  ) : (
    <Button
      size="medium"
      className="w-s-48 !min-w-[3rem] h-s-48"
      onClick={() => handleChangeLanguage('fr')}
      ghost={ghost}
    >
      FR
    </Button>
  );
};

export default LanguageButtons;
