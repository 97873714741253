/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { joinDescriptions } from '@application/utils';
import {
  CandidateOffer,
  CandidateRequestType,
  JobPaymentFrequencyCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../../constants';

type EmployerBrandProps = {
  data: CandidateOffer;
  type?: CandidateRequestType;
};

const EmployerBrand = ({ data, type }: EmployerBrandProps) => {
  const { t, i18n } = useTranslation('candidates');

  const remuneration = useMemo(() => {
    switch (data.paymentFrequencyCode) {
      case JobPaymentFrequencyCode.Hourly:
        return t('suffix.currencyPerHourRange', {
          min: data.hourlyRateMin,
          max: data.hourlyRateMax,
        });
      case JobPaymentFrequencyCode.Yearly:
        return t('suffix.thousandsPerYearRange', {
          min: data.annualSalaryMin,
          max: data.annualSalaryMax,
        });
      case JobPaymentFrequencyCode.FixedAmount:
        return t('suffix.currency', { value: data.remunerationAmount });
      default:
        return '-';
    }
  }, [data, t]);

  const operationTerritories = useMemo(
    () =>
      joinDescriptions(
        data?.operationTerritories?.map(
          (territory) => territory.descriptions
        ) || [],
        i18n.language
      ),
    [data?.operationTerritories, i18n.language]
  );

  return (
    <>
      <tr>
        <th className="sr-only">
          <span>{t('employerBrand')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.highlight, 'h-s-56 whitespace-nowrap')}>
          &nbsp;
        </td>
      </tr>

      <tr>
        <th scope="col" className="sr-only">
          {t('labels.remunerationOffered')}
        </th>

        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'border-l-0 whitespace-nowrap'
          )}
        >
          <PlaceholderSwitcher value={data.paymentFrequencyCode}>
            {remuneration}
          </PlaceholderSwitcher>
        </td>
      </tr>

      {type === CandidateRequestType.Recruitment ? (
        <tr>
          <th scope="col" className="sr-only">
            {t('labels.otherRemunerations')}
          </th>

          <td
            className={cn(
              TD_CLASS_NAME.base,
              TD_CLASS_NAME.fixed,
              'border-l-0 whitespace-nowrap'
            )}
          >
            <PlaceholderSwitcher value={data.otherPaymentTypeCode}>
              {t(
                `enum.jobPaymentOtherTypesCode.${data.otherPaymentTypeCode?.toLowerCase()}`
              )}
            </PlaceholderSwitcher>
          </td>
        </tr>
      ) : (
        <>
          <tr>
            <th scope="col" className="sr-only">
              {t('labels.hoursPerWeek')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,

                'border-l-0 whitespace-nowrap'
              )}
            >
              <PlaceholderSwitcher value={data.hoursPerWeek}>
                {data.hoursPerWeek
                  ?.map((h) => t('suffix.hours', { value: h }))
                  .join(', ')}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.contractDuration')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                TD_CLASS_NAME.disabled,
                'border-l-0 whitespace-nowrap'
              )}
            >
              <PlaceholderSwitcher value={data.jobDurationInMonths}>
                {t('suffix.month', {
                  count: Number(data.jobDurationInMonths),
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.potentialAmount')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                TD_CLASS_NAME.disabled,
                'border-l-0 whitespace-nowrap'
              )}
            >
              -
            </td>
          </tr>
        </>
      )}

      <tr>
        <th scope="col" className="sr-only">
          {t('labels.operationTerritories')}
        </th>

        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'border-l-0 whitespace-nowrap'
          )}
        >
          <PlaceholderSwitcher
            value={data.operationTerritories?.length || undefined}
          >
            <TooltipEllipsis toolTip={operationTerritories}>
              <div>{operationTerritories}</div>
            </TooltipEllipsis>
          </PlaceholderSwitcher>
        </td>
      </tr>

      {type === CandidateRequestType.Recruitment && (
        <tr>
          <th scope="col" className="sr-only">
            {t('labels.jobType')}
          </th>

          <td
            className={cn(
              TD_CLASS_NAME.base,
              TD_CLASS_NAME.fixed,
              'border-l-0 whitespace-nowrap'
            )}
          >
            <PlaceholderSwitcher value={data.jobType}>
              {t(`enum.jobDurationCode.${data.jobType?.toLowerCase()}`)}
            </PlaceholderSwitcher>
          </td>
        </tr>
      )}

      <tr>
        <th scope="col" className="sr-only">
          {t('labels.remoteWork')}
        </th>

        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'border-l-0 whitespace-nowrap'
          )}
        >
          <PlaceholderSwitcher value={data.jobModeCode}>
            {t(`enum.jobModeCode.${data.jobModeCode?.toLowerCase()}`)}
          </PlaceholderSwitcher>
        </td>
      </tr>
    </>
  );
};

export default EmployerBrand;
