import { useMutation } from 'urql';

import { Mutation, MutationRequestDuplicateArgs } from '@domain/graphql.types';
import { MutationRequestDuplicate } from '@infrastructure/graphql/request';

const useRequestDuplicate = () => {
  const [{ data, fetching, error }, duplicateRequest] = useMutation<
    Pick<Mutation, 'requestDuplicate'>,
    MutationRequestDuplicateArgs
  >(MutationRequestDuplicate);

  return {
    duplicateRequest,
    data,
    fetching,
    error,
  };
};

export default useRequestDuplicate;
