import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('404.sorry')}</h1>
      <p>{t('404.notfound')}</p>
    </>
  );
};

export default NotFoundPage;
