import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const JobExperienceLevels = gql`
  ${DescriptionsFragment}

  query JobExperienceLevels {
    jobExperienceLevels {
      values {
        descriptions {
          ...DescriptionsFragment
        }
        code
      }
    }
  }
`;

export default JobExperienceLevels;
