import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useRecruitmentNegotiationRoomAcceptValues } from '@domain/negotiation';

import { toViewModel } from '../view-models/accept-recruitment-negotiation-room-values.vm';

const useAcceptRecruitmentNegotiationRoomValues = () => {
  const { data, fetching, error, acceptNegotiationRoomValues } =
    useRecruitmentNegotiationRoomAcceptValues();

  const { t } = useTranslation('recruitment', {
    keyPrefix: 'messages',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    room: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('acceptNegotiationValuesError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.recruitmentNegotiationRoomAcceptValues.room) {
      setAlertContent({
        action: t('acceptNegotiationValuesSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { acceptNegotiationRoomValues, viewModel };
};

export default useAcceptRecruitmentNegotiationRoomValues;
