type Cancelable = {
  clear(): void;
};

/**
 * Delay the execution of function
 *
 * @callback  Callback
 * @param     {any[]} args
 * @return    {any}
 *
 * @param   Callback — Callback function to call
 * @param   {number} delay — Amount of time in ms to wait between calls
 * @returns {T} — Debounced function
 */
const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number = 300
) => {
  let timeout: ReturnType<typeof setTimeout>;
  const debounced = (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced as T & Cancelable;
};

export default debounce;
