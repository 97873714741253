import React, { useCallback } from 'react';

import { FileType } from '@domain/graphql.types';

export type Blob = {
  data: string;
  name: string;
  type: string;
};

type FileTypes = {
  [key in string]: string[];
};

export const allowedTypes: FileTypes = {
  document: ['pdf', 'doc', 'docx'],
  image: ['png', 'jpg', 'jpeg'],
};

type UseUploadFileProps = {
  setFile: (name: string, value: any) => void;
  setFileError: (name: string, message: string) => void;
  clearFileErrors?: (name: string) => void;
};

const useUploadFile = ({ setFile, setFileError }: UseUploadFileProps) => {
  const isValidFileType = (fileName: string | undefined, fileType: string) => {
    const fileExtension = fileName?.split('.').pop();
    return fileName && allowedTypes[fileType].indexOf(fileExtension!) > -1;
  };

  const getAllowedExtensionsFromType = (fileType: string) =>
    allowedTypes[fileType].map((e) => `.${e}`).join(',');

  const getAllowedExtensions = () =>
    Object.values(allowedTypes)
      .map((a) => a.map((e) => `.${e}`))
      .join(',');

  const getReadableExtensions = () => {
    const allowed = Object.values(allowedTypes)
      .join(',')
      .split(',')
      .reduce((acc, current) => {
        if (current.match(/doc[x]?$/)) {
          acc.push('Word');
          return acc;
        }
        if (current.match(/jp[e]?g$/)) {
          acc.push('JPG');
          return acc;
        }
        const type = current.toUpperCase();
        acc.push(type);
        return acc;
      }, [] as string[]);

    // create a unique comma-separated list of extension values (i.e. PDF, Word, etc.)
    return [...new Set(allowed)].join(', ');
  };

  const isFilesValid = useCallback(
    (files: FileList | null, name: string) => {
      // validate if files exist
      if (!files || files.length === 0) {
        setFileError(name, 'uploadFile.errors.processing');
        return false;
      }

      // validate allowed file types
      const file = files[0];
      if (
        !Object.keys(allowedTypes).some((type) =>
          isValidFileType(file?.name.toLowerCase(), type)
        )
      ) {
        setFileError(name, 'uploadFile.errors.notAllowed');
        return false;
      }
      return true;
    },
    [setFileError]
  );

  // const readFile = async (file: File | null) => {
  //   const reader = new FileReader();

  //   return new Promise((resolve, reject) => {
  //     reader.abort();
  //     reader.onload = (event: any) =>
  //       resolve({
  //         data: event.target.result as any,
  //         name: file?.name,
  //         type: file?.type,
  //       } as Blob);
  //     reader.onerror = (error) => reject(error);
  //     reader.readAsDataURL(file!);
  //   });
  // };

  const onFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, files } = event.target;

      if (!isFilesValid(files, name)) {
        return;
      }

      const file = files && files[0];

      // let blob;
      // try {
      //   blob = await readFile(file);
      // } catch (error: any) {
      //   setFileError(name, error.message);
      // }
      // clearFileErrors(name);
      setFile(name, { file, type: FileType.CandidateRequestResume });
    },
    [isFilesValid, setFile]
  );

  return {
    getAllowedExtensionsFromType,
    getAllowedExtensions,
    getReadableExtensions,
    onFileChange,
  };
};

export default useUploadFile;
