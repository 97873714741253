import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';

type ToViewModel = {
  requests: {
    data: string | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  requests: { data, fetching, error },
}: ToViewModel): ViewModel<string | undefined> =>
  new ViewModel<string | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
