import { components, GroupBase, OptionProps } from 'react-select';

import { Checkbox } from '@application/components';
import { cn } from '@utils/lib-utils';

import { OptionType } from '../types';

const MultiOption = (
  props: OptionProps<OptionType, boolean, GroupBase<OptionType>>
) => {
  const {
    data: { value, label },
    isFocused,
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <Checkbox
        key={value}
        name={`${value}-option`}
        value={value}
        label={label}
        checked={isSelected}
        onChange={() => null}
        withBackground
        className="px-s-8"
        // FIXME: Disable checkbox default checked animation since react-select re-render the entire list of options
        // on mouse and keys events which re-start the animation for all checked options
        checkboxClassName="[&&]:animate-none"
        backgroundClassName={cn('rounded-sm', { 'bg-neutral': isFocused })}
      />
    </components.Option>
  );
};

export default MultiOption;
