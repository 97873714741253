import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Offer, RequestOffersResponse } from '@domain/graphql.types';

type ToViewModel = {
  offers: {
    data: RequestOffersResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  offers: { data, fetching, error },
}: ToViewModel): ViewModel<readonly Offer[] | undefined> =>
  new ViewModel<readonly Offer[] | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.offers || undefined,
  });
