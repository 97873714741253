import { gql } from 'urql';

const OfferOutsourcingConditionsFragment = gql`
  fragment OfferOutsourcingConditionsFragment on OfferOutsourcingConditions {
    maximumDuration
    exclusivityPeriod
    maximumBudget
    responsibilities
  }
`;

export default OfferOutsourcingConditionsFragment;
