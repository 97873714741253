import { useAccountSizes } from '@domain/account';

import { toViewModel } from '../view-models/get-business-sizes.vm';

const useGetBusinessSizes = () => {
  const { data, fetching, error } = useAccountSizes();

  return toViewModel({
    accountSizes: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetBusinessSizes;
