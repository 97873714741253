import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useRequestDuplicate } from '@domain/request';

import { toViewModel } from '../view-models/duplicate-request.vm';

const useDuplicateRequest = () => {
  const { data, fetching, error, duplicateRequest } = useRequestDuplicate();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('requests', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    request: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('duplicateError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.requestDuplicate?.request) {
      setAlertContent({
        action: t('duplicateSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.requestDuplicate?.request]);

  return {
    viewModel,
    duplicateRequest,
  };
};

export default useDuplicateRequest;
