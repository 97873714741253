import * as yup from 'yup';

import {
  CandidateConditionBillingPeriod,
  CandidateConditionResponsibility,
  CandidateRequestFile,
  CandidateRequestType,
  ConditionRemunerationType,
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
  JobScheduleTypeCode,
  OperationTerritoryCode,
  RequestConditionPaymentFrequencyCode,
  RequestConditionPaymentMethodCode,
  RequestConditionStartOfAgreementPaymentMethodCode,
  Season,
} from '@domain/graphql.types';
import { validateDecimal } from '@utils/yup-utils';

export const CANDIDATE_REQUEST_SCHEMA = yup.object({
  publishNow: yup.boolean().nullable(),
  firstName: yup.string().nullable().max(128, 'validations.maxLength'),
  lastName: yup.string().nullable().max(128, 'validations.maxLength'),
  email: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .email('validations.emailValid'),
  jobSpecialtyId: yup.string().nullable(),
  specialty: yup.string().nullable().max(128, 'validations.maxLength'),
  files: yup.array(yup.mixed<CandidateRequestFile>()).nullable(),
  desiredStartDate: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  receivingOfferDeadline: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  operationTerritoryCodes: yup.array(
    yup
      .mixed<OperationTerritoryCode>()
      .oneOf(Object.values(OperationTerritoryCode))
  ),
  experience: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  metAt: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  type: yup
    .mixed<CandidateRequestType>()
    .oneOf(Object.values(CandidateRequestType))
    .nullable(),
  canWorkInCanadaOrQuebec: yup.boolean().nullable(),
  jobType: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .test(
      'valid-jobType',
      'Invalid jobType',
      function validateJobType(value, { parent }) {
        const { type } = parent;
        const jobType = value;
        if (!type) return true;
        if (
          type === CandidateRequestType.Recruitment &&
          [
            JobDurationCode.Permanent,
            JobDurationCode.Occasional,
            JobDurationCode.Seasonal,
            JobDurationCode.Internship,
            JobDurationCode.Contract,
          ].includes(jobType as any)
        ) {
          return true; // Validation passed
        }
        if (
          type === CandidateRequestType.TemporaryPlacement &&
          [
            JobDurationCode.Seasonal,
            JobDurationCode.Contract,
            JobDurationCode.OpenEndedContract,
          ].includes(jobType as any)
        ) {
          return true; // Validation passed
        }
        if (
          type === CandidateRequestType.Outsourcing &&
          [
            JobDurationCode.Freelance,
            JobDurationCode.Contract,
            JobDurationCode.OpenEndedContract,
          ].includes(jobType as any)
        ) {
          return true; // Validation passed
        }

        return this.createError({
          path: this.path,
          message: 'validations.jobType',
        });
      }
    )
    .nullable(),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  seasons: yup
    .array(yup.mixed<Season>().oneOf(Object.values(Season)))
    .nullable(),
  hoursPerWeek: yup.array(yup.string()).nullable(),
  fullTimeAvailability: yup.boolean().nullable(),
  partTimeAvailability: yup.boolean().nullable(),
  schedules: yup
    .array(
      yup.mixed<JobScheduleTypeCode>().oneOf(Object.values(JobScheduleTypeCode))
    )
    .nullable(),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .nullable(),
  remoteDaysAllowed: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber'),
  paymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .test(
      'valid-paymentFrequencyCode',
      'Invalid paymentFrequencyCode',
      function validatePaymentFrequencyCode(value, { parent }) {
        const { type } = parent;
        const paymentFrequencyCode = value;

        if (
          (type === CandidateRequestType.TemporaryPlacement ||
            type === CandidateRequestType.Outsourcing) &&
          [
            JobPaymentFrequencyCode.FixedAmount,
            JobPaymentFrequencyCode.Hourly,
          ].includes(paymentFrequencyCode as any)
        ) {
          return true; // Validation passed
        }

        if (type === CandidateRequestType.Recruitment) {
          return true;
        }

        return this.createError({
          path: this.path,
          message: 'validations.paymentFrequencyCode',
        });
      }
    )
    .nullable(),
  annualSalaryMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  annualSalaryMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  hourlyRateMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyRateMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  remunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  otherPaymentTypeCode: yup
    .mixed<JobPaymentOtherTypesCode>()
    .oneOf(Object.values(JobPaymentOtherTypesCode))
    .nullable(),
  otherPaymentAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  softSkills: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .nullable(),
  additionalNotes: yup.string().nullable().max(1024, 'validations.maxLength'),

  /* RECRUITMENT CONDITIONS */
  recruitmentConditionGuaranteedPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  recruitmentConditionGuaranteedPeriodDisplay: yup.boolean().nullable(),
  recruitmentConditionRemunerationTypeValue: yup
    .mixed<ConditionRemunerationType>()
    .oneOf(Object.values(ConditionRemunerationType))
    .nullable(),
  recruitmentConditionRemunerationPercentage: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  recruitmentConditionRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  recruitmentConditionRemunerationHourlyRate: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  recruitmentConditionRemunerationEstimatedHours: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  recruitmentConditionRemunerationTypeDisplay: yup.boolean().nullable(),
  recruitmentConditionProbationPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  recruitmentConditionProbationPeriodDisplay: yup.boolean().nullable(),
  recruitmentConditionPaymentMethodValue: yup
    .mixed<RequestConditionPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionPaymentMethodCode))
    .nullable(),
  recruitmentConditionStartOfAgreementPaymentMethodValue: yup
    .mixed<RequestConditionStartOfAgreementPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionStartOfAgreementPaymentMethodCode))
    .nullable(),
  recruitmentConditionPaymentAmountValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  recruitmentConditionDepositValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  recruitmentConditionPaymentFrequencyValue: yup
    .mixed<RequestConditionPaymentFrequencyCode>()
    .oneOf(Object.values(RequestConditionPaymentFrequencyCode))
    .nullable(),
  recruitmentConditionPaymentMethodDisplay: yup.boolean().nullable(),
  recruitmentConditionBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  recruitmentConditionBonusMonthValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  recruitmentConditionBonusWeekValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  recruitmentConditionBonusDisplay: yup.boolean().nullable(),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionProbationPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  temporaryPlacementConditionProbationPeriodDisplay: yup.boolean().nullable(),
  temporaryPlacementConditionMinimumNoticeValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  temporaryPlacementConditionMinimumNoticeDisplay: yup.boolean().nullable(),
  temporaryPlacementConditionPossibleBuybackValue: yup.boolean().nullable(),
  temporaryPlacementConditionPossibleBuybackDisplay: yup.boolean().nullable(),
  temporaryPlacementConditionPossibleBuybackBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  temporaryPlacementConditionPossibleBuybackBonusDisplay: yup
    .boolean()
    .nullable(),
  temporaryPlacementConditionTravelExpensesResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionTrainingExpensesResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionEmployeeHelpProgramResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionCnesstResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionOtherResponsibilities: yup
    .string()
    .nullable()
    .max(1024, 'validations.maxLength'),
  temporaryPlacementConditionResponsibilitiesDisplay: yup.boolean().nullable(),
  temporaryPlacementConditionBillingPeriodValue: yup
    .mixed<CandidateConditionBillingPeriod>()
    .oneOf(Object.values(CandidateConditionBillingPeriod))
    .nullable(),
  temporaryPlacementConditionBillingPeriodDisplay: yup.boolean().nullable(),

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMinimumNoticeValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  outsourcingConditionMinimumNoticeDisplay: yup.boolean().nullable(),
  outsourcingConditionBillingPeriodValue: yup
    .mixed<CandidateConditionBillingPeriod>()
    .oneOf(Object.values(CandidateConditionBillingPeriod))
    .nullable(),
  outsourcingConditionBillingPeriodDisplay: yup.boolean().nullable(),
});

export const CANDIDATE_REQUEST_PUBLISH_SCHEMA = CANDIDATE_REQUEST_SCHEMA.shape({
  firstName: yup
    .string()
    .max(128, 'validations.maxLength')
    .required('validations.required.firstName'),
  lastName: yup
    .string()
    .max(128, 'validations.maxLength')
    .required('validations.required.lastName'),
  email: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .email('validations.emailValid')
    .required('validations.required.email'),
  jobSpecialtyId: yup
    .string()
    .required('validations.required.jobSpecialtyCode'),
  receivingOfferDeadline: yup
    .string()
    .transform((_, val) => val || null)
    .required('validations.required.receivingOfferDeadline'),
  operationTerritoryCodes: yup
    .array(
      yup
        .mixed<OperationTerritoryCode>()
        .oneOf(Object.values(OperationTerritoryCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.operationTerritoryCodes',
      (value) => Array.isArray(value) && value.length > 0
    ),
  experience: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .required('validations.required.experience'),

  hoursPerWeek: yup
    .array(yup.string())
    .test(
      'notEmptyArray',
      'validations.required.hoursPerWeek',
      (value) => Array.isArray(value) && value.length > 0
    ),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .required('validations.required.jobModeCode'),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when('jobType', ([jobType], schema) =>
      !!jobType &&
      (jobType === JobDurationCode.Contract ||
        jobType === JobDurationCode.Freelance ||
        jobType === JobDurationCode.Internship)
        ? schema.required('validations.required.jobDurationInMonths')
        : schema.nullable()
    ),
  seasons: yup
    .array(yup.mixed<Season>().oneOf(Object.values(Season)))
    .when('jobType', ([jobType], schema) =>
      !!jobType && jobType === JobDurationCode.Seasonal
        ? schema.test(
            'notEmptyArray',
            'validations.required.seasons',
            (value) => Array.isArray(value) && value.length > 0
          )
        : schema.nullable()
    ),
  remoteDaysAllowed: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber')
    .when('jobModeCode', ([jobModeCode], schema) =>
      jobModeCode === JobModeCode.Hybrid
        ? schema.required('validations.required.jobRemoteModeInDays')
        : schema.nullable()
    ),
  remunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('paymentFrequencyCode', ([paymentFrequencyCode], schema) =>
      paymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount
        ? schema.required('validations.required.jobRemunerationAmount')
        : schema.nullable()
    ),
  otherPaymentAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('otherPaymentTypeCode', ([otherPaymentTypeCode], schema) =>
      otherPaymentTypeCode
        ? schema.required('validations.required.otherPaymentAmount')
        : schema.nullable()
    ),
  type: yup
    .mixed<CandidateRequestType>()
    .oneOf(Object.values(CandidateRequestType))
    .required('validations.required.candidateRequestType'),
  paymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .test(
      'valid-paymentFrequencyCode',
      'Invalid paymentFrequencyCode',
      function validatePaymentFrequencyCode(value, { parent }) {
        const { type } = parent;
        const paymentFrequencyCode = value;

        if (
          (type === CandidateRequestType.TemporaryPlacement ||
            type === CandidateRequestType.Outsourcing) &&
          [
            JobPaymentFrequencyCode.FixedAmount,
            JobPaymentFrequencyCode.Hourly,
          ].includes(paymentFrequencyCode as any)
        ) {
          return true; // Validation passed
        }

        if (type === CandidateRequestType.Recruitment) {
          return true;
        }

        return this.createError({
          path: this.path,
          message: 'validations.paymentFrequencyCode',
        });
      }
    )
    .required('validations.required.jobPaymentFrequencyCode'),
  jobType: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .test(
      'valid-jobType',
      'Invalid jobType',
      function validateJobType(value, { parent }) {
        const { type } = parent;
        const jobType = value;
        if (!type) return true;
        if (
          type === CandidateRequestType.Recruitment &&
          [
            JobDurationCode.Permanent,
            JobDurationCode.Occasional,
            JobDurationCode.Seasonal,
            JobDurationCode.Internship,
            JobDurationCode.Contract,
          ].includes(jobType as any)
        ) {
          return true; // Validation passed
        }
        if (
          type === CandidateRequestType.TemporaryPlacement &&
          [
            JobDurationCode.Seasonal,
            JobDurationCode.Contract,
            JobDurationCode.OpenEndedContract,
          ].includes(jobType as any)
        ) {
          return true; // Validation passed
        }
        if (
          type === CandidateRequestType.Outsourcing &&
          [
            JobDurationCode.Freelance,
            JobDurationCode.Contract,
            JobDurationCode.OpenEndedContract,
          ].includes(jobType as any)
        ) {
          return true; // Validation passed
        }

        return this.createError({
          path: this.path,
          message: 'validations.jobType',
        });
      }
    )
    .required('validations.required.jobDurationCode'),
});

export type CandidateRequestFormFields = yup.InferType<
  typeof CANDIDATE_REQUEST_SCHEMA
>;
