import { useEffect, useState } from 'react';

import { Table } from '@application/components/table';

import { AccountOperationTerritoriesFilters } from '../../../../../domain/graphql.types';
import { useAccountOperationTerritoryFilters } from '../hooks';
import { useAccountOperationTerritoriesList } from '../hooks/useAccountOperationTerritoriesList';
import FiltersFields from './FiltersFields';

const AccountOperationTerritoryList = () => {
  const [tableFilters, setTableFilters] =
    useState<AccountOperationTerritoriesFilters>();

  const {
    accountOperationTerritories,
    columns,
    count,
    currentPage,
    isLoading,
    onPaginationChange,
    onPaginationReset,
    onSortingChange,
    sorting,
  } = useAccountOperationTerritoriesList(tableFilters);

  const {
    filters,
    updateTextFilters,
    updateSelectOperationTerritoriesFilters,
  } = useAccountOperationTerritoryFilters({
    resetPagination: onPaginationReset,
  });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="grow w-full mb-s-16">
        <FiltersFields
          updateTextFilter={updateTextFilters}
          updateSelectFilters={updateSelectOperationTerritoriesFilters}
        />
      </div>

      <div
        className="relative mt-s-32"
        data-testid="TEST-account-operation-territories-table"
      >
        <Table
          data={accountOperationTerritories}
          columns={columns}
          count={count}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          paginationState={currentPage}
          onPaginationChange={onPaginationChange}
          onPaginationReset={onPaginationReset}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AccountOperationTerritoryList;
