import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateOfferUpdateStatusArgs,
} from '@domain/graphql.types';
import { MutationCandidateOfferStatusUpdate } from '@infrastructure/graphql/candidate/offer';

const useCandidateOfferUpdateStatus = () => {
  const [{ data, fetching, error }, updateCandidateOfferStatus] = useMutation<
    Pick<Mutation, 'candidateOfferUpdateStatus'>,
    MutationCandidateOfferUpdateStatusArgs
  >(MutationCandidateOfferStatusUpdate);

  return {
    updateCandidateOfferStatus,
    data,
    fetching,
    error,
  };
};

export default useCandidateOfferUpdateStatus;
