import { createContext } from 'react';

import { AccountUser, User } from '@domain/graphql.types';

type UserContextProps = {
  accountUser: AccountUser | undefined;
  avatarUrl: string | null | undefined;
  error: Error | undefined;
  hasFinishedOnboarding: boolean;
  hasPendingInvitation: boolean;
  initializationCompleted: boolean;
  initializeUser: () => void;
  isEmailVerified: boolean;
  isLoading: boolean;
  refreshUser: () => void;
  setAvatarUrl: (url: string | null | undefined) => void;
  setHasFinishedOnboarding: (state: boolean) => void;
  setHasPendingInvitation: (state: boolean) => void;
  setIsEmailVerified: (state: boolean) => void;
  updateUserEmail: (email: string) => void;
  user: User | undefined;
};

const UserContext = createContext<UserContextProps>({
  accountUser: undefined,
  avatarUrl: undefined,
  error: undefined,
  hasFinishedOnboarding: false,
  hasPendingInvitation: false,
  initializationCompleted: false,
  initializeUser: () => {},
  isEmailVerified: false,
  isLoading: false,
  refreshUser: () => {},
  setAvatarUrl: () => {},
  setHasFinishedOnboarding: () => {},
  setHasPendingInvitation: () => {},
  setIsEmailVerified: () => {},
  updateUserEmail: () => {},
  user: undefined,
});

export default UserContext;
