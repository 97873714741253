import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

import { ButtonLink } from '../links';
import { Tooltip } from '../tooltip';

type NavLinkProps = {
  label: string;
  isNavOpen: boolean;
  url: string;
  icon: string;
  disabled?: boolean;
};

const NavLink = ({ label, isNavOpen, url, icon, disabled }: NavLinkProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      message={!isNavOpen ? t(`sidebar.${label}`) : undefined}
      position="right"
    >
      <div>
        <li
          className={cn(
            'flex justify-center transition duration-300 overflow-hidden rounded-md mt-s-8',
            { 'max-w-[3.7rem]': !isNavOpen }
          )}
        >
          <ButtonLink
            rootLocationCheck
            size="large"
            isNavLink
            to={url}
            className="justify-start font-normal px-s-16 ml-s-2 border-transparent hover:border-transparent"
            icon={<i className={`mr-s-8 text-24 ${icon}`} />}
            disabled={disabled}
          >
            <span className="overflow-hidden whitespace-nowrap text-16 text-neutral-content">
              {t(`sidebar.${label}`)}
            </span>
          </ButtonLink>
        </li>
      </div>
    </Tooltip>
  );
};

export default NavLink;
