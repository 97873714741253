import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DisplayButton,
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { extractLanguage } from '@utils/i18n-utils';

import { RequestFormFields } from '../../schema';

type InternationalRecruitmentFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const InternationalRecruitmentFields = ({
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: InternationalRecruitmentFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const mandatoryWorkPermit = watch(
    'internationalRecruitmentConditionMandatoryWorkPermitValue'
  );

  return (
    <SectionGrid
      title={t('subtitles.forInternationalRecruitment')}
      className="mt-s-40"
    >
      <SectionGridRow
        isTopRow
        rightChildren={
          <DisplayButton
            name="internationalRecruitmentConditionMandatoryWorkPermitDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <Controller
          name="internationalRecruitmentConditionMandatoryWorkPermitValue"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.validWorkPermit')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as any);
                trigger(name);
              }}
              legendSize="medium"
              alignRow
              value={mandatoryWorkPermit ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="internationalRecruitmentConditionExclusivityPeriodDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.foreignCandidatesExclusivityPeriod')}
          type="number"
          suffix={t('suffix.weeks')}
          mediumSuffix
          noMargin
          minValue={1}
          invalid={
            !!errors.internationalRecruitmentConditionExclusivityPeriodValue
          }
          helperText={
            errors.internationalRecruitmentConditionExclusivityPeriodValue
              ?.message &&
            tGlobal(
              errors.internationalRecruitmentConditionExclusivityPeriodValue
                ?.message,
              { min: '1' }
            )
          }
          {...register(
            'internationalRecruitmentConditionExclusivityPeriodValue'
          )}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="internationalRecruitmentConditionMaximumReplacementDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.maxReplacements')}
          type="number"
          noMargin
          minValue={1}
          invalid={
            !!errors.internationalRecruitmentConditionMaximumReplacementValue
          }
          helperText={
            errors.internationalRecruitmentConditionMaximumReplacementValue
              ?.message &&
            tGlobal(
              errors.internationalRecruitmentConditionMaximumReplacementValue
                ?.message,
              {
                min: '1',
              }
            )
          }
          {...register(
            'internationalRecruitmentConditionMaximumReplacementValue'
          )}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default InternationalRecruitmentFields;
