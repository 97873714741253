import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconPensiveFace from '@application/assets/fluent-emoji/pensive_face_color.svg?react';
import IconSmilingFaceWithHeartEyes from '@application/assets/fluent-emoji/smiling_face_with_heart-eyes_color.svg?react';
import IconSmilingFaceWithSimlingEyes from '@application/assets/fluent-emoji/smiling_face_with_smiling_eyes_color.svg?react';
import { Radio } from '@application/components';
import { OfferCandidateFeedbackCode } from '@domain/graphql.types';

import useUpdateOfferCandidateFeedback from '../hooks/useUpdateOfferCandidateFeedback';

type CandidateFeedbackProps = {
  offerCandidateId: string;
  feedback?: OfferCandidateFeedbackCode | null;
};

const IconClassName = 'text-28 absolute top-s-8 left-s-8';

const CandidateFeedback = ({
  feedback,
  offerCandidateId,
}: CandidateFeedbackProps) => {
  const [localFeedback, setLocalFeedback] = useState(feedback);

  const { t } = useTranslation('offers', {
    keyPrefix: 'candidates',
  });

  const { updateOfferCandidateFeedback } = useUpdateOfferCandidateFeedback();

  const handleUpdateFeedback = useCallback(
    (updatedFeedback: OfferCandidateFeedbackCode) => () => {
      if (!offerCandidateId) {
        return;
      }

      setLocalFeedback(updatedFeedback);
      updateOfferCandidateFeedback({
        input: {
          id: offerCandidateId,
          feedback: updatedFeedback,
        },
      });
    },
    [offerCandidateId, setLocalFeedback, updateOfferCandidateFeedback]
  );

  return (
    <>
      <Radio
        className="h-s-48 w-s-48 hover:!opacity-100"
        style={{
          opacity:
            localFeedback === OfferCandidateFeedbackCode.Love ? '100%' : '60%',
        }}
        label={
          <IconSmilingFaceWithHeartEyes
            className={IconClassName}
            aria-label={t('actions.love')}
          />
        }
        name={`love-${offerCandidateId}`}
        checked={localFeedback === OfferCandidateFeedbackCode.Love}
        value={OfferCandidateFeedbackCode.Love}
        onClick={handleUpdateFeedback(OfferCandidateFeedbackCode.Love)}
        asButton
      />

      <Radio
        className="h-s-48 w-s-48 hover:!opacity-100"
        style={{
          opacity:
            localFeedback === OfferCandidateFeedbackCode.Like ? '100%' : '60%',
        }}
        label={
          <IconSmilingFaceWithSimlingEyes
            className={IconClassName}
            aria-label={t('actions.like')}
          />
        }
        name={`like-${offerCandidateId}`}
        checked={localFeedback === OfferCandidateFeedbackCode.Like}
        value={OfferCandidateFeedbackCode.Like}
        onClick={handleUpdateFeedback(OfferCandidateFeedbackCode.Like)}
        asButton
      />

      <Radio
        className="h-s-48 w-s-48 hover:!opacity-100"
        style={{
          opacity:
            localFeedback === OfferCandidateFeedbackCode.Dislike
              ? '100%'
              : '60%',
        }}
        label={
          <IconPensiveFace
            className={IconClassName}
            aria-label={t('actions.dislike')}
          />
        }
        name={`dislike-${offerCandidateId}`}
        checked={localFeedback === OfferCandidateFeedbackCode.Dislike}
        value={OfferCandidateFeedbackCode.Dislike}
        onClick={handleUpdateFeedback(OfferCandidateFeedbackCode.Dislike)}
        asButton
      />
    </>
  );
};

export default CandidateFeedback;
