import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
// import { IconButton } from '@application/components/buttons';
import { Stack, Switcher } from '@application/components/container-layouts';
import { SelectField } from '@application/components/select-field';
import { mapOptions } from '@application/utils';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';
import {
  CandidateRequestsFilters,
  CandidateRequestType,
} from '@domain/graphql.types';
// import { formattedNowDate } from '@utils/date-utils';
import { cn } from '@utils/lib-utils';

type FiltersFieldsProps = {
  updateTextFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  updateSelectFilters: (
    fieldName: keyof CandidateRequestsFilters
  ) => (values: any) => void;
  // updateDateFilters: (
  //   fieldName: keyof CandidateRequestsFilters
  // ) => (event: ChangeEvent<HTMLInputElement>) => void;
};

const FiltersFields = ({
  updateTextFilter,
  updateSelectFilters,
  // updateDateFilters,
}: FiltersFieldsProps) => {
  // const [showMoreOptions, setShowMoreOptions] = useState(false);

  const { t, i18n } = useTranslation('candidates');

  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();

  const operationTerritories = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n]
  );

  const candidateRequestTypes = Object.entries(CandidateRequestType).map(
    ([key, value]) => ({
      label: t(`enum.candidateRequestType.${value.toLowerCase()}`),
      value: key,
    })
  );

  return (
    <Stack space={16}>
      <Switcher
        space={24}
        limit={4}
        threshold="lg"
        className={cn(
          'items-end'
          // {
          //   '-mb-s-16': !showMoreOptions,
          // }
        )}
      >
        <TextInputField
          label={t('filters.search')}
          placeholder={`${t('filters.search')}...`}
          name="text"
          withIcon="ri-search-2-line text-24"
          hideIconBorder
          className="flex-1"
          onChange={updateTextFilter}
        />

        <SelectField
          label={t('filters.operationTerritory')}
          name="operationTerritoryCodes"
          options={operationTerritories}
          className="flex-1"
          onChange={updateSelectFilters('operationTerritoryCodes')}
          multiple
          limitTags={0}
          clearable
        />

        <SelectField
          label={t('filters.types')}
          name="types"
          options={candidateRequestTypes}
          className="flex-1"
          onChange={updateSelectFilters('types')}
          multiple
          limitTags={0}
          clearable
        />

        {/* TODO Remove comment when the date filter is replaced for the deadline filter
        <IconButton
          text={t('filters.moreOptions')}
          icon={<i className="ri-equalizer-line" />}
          onClick={() => setShowMoreOptions(!showMoreOptions)}
          className={cn(
            '[&&]:flex-grow-0 [&&]:basis-s-0',
            showMoreOptions ? 'bg-secondary' : ''
          )}
        /> */}
      </Switcher>

      {/* TODO Replace filter for deadline
      <Switcher
        space={24}
        limit={3}
        threshold="lg"
        className={cn('', {
          'h-s-0 w-s-0 opacity-0': !showMoreOptions,
        })}
      >
        <TextInputField
          type="date"
          label={t('filters.date')}
          name="dates"
          maxValue={formattedNowDate()}
          className="flex-1"
          onChange={updateDateFilters('dates')}
        />
        <div />
        <div />
      </Switcher> */}
    </Stack>
  );
};

export default FiltersFields;
