import { DateTime } from 'luxon';

import {
  CandidateRequest,
  JobScheduleTypeCode,
  OperationTerritoryCode,
  Season,
} from '@domain/graphql.types';
import { parseNumber } from '@utils/data-utils';

import { CandidateRequestFormFields } from './schema';

export const normalizeCandidateRequestInputData = (
  data: CandidateRequestFormFields
) => ({
  ...data,
  desiredStartDate: data.desiredStartDate
    ? DateTime.fromISO(data.desiredStartDate).toJSDate()
    : null,
  receivingOfferDeadline: data.receivingOfferDeadline
    ? DateTime.fromISO(data.receivingOfferDeadline).toJSDate()
    : null,
  metAt: data.metAt ? DateTime.fromISO(data.metAt).toJSDate() : null,
  hourlyRateMin: data.hourlyRateMin?.toFixed(2) || null,
  hourlyRateMax: data.hourlyRateMax?.toFixed(2) || null,
  hourlyBonus: data.hourlyBonus?.toFixed(2) || null,
  remunerationAmount: data.remunerationAmount?.toFixed(2) || null,
  otherPaymentAmount: data.otherPaymentAmount?.toFixed(2) || null,

  /* GENERAL CONDITIONS */
  recruitmentConditionRemunerationAmount:
    data.recruitmentConditionRemunerationAmount?.toFixed(2) || null,
  recruitmentConditionRemunerationHourlyRate:
    data.recruitmentConditionRemunerationHourlyRate?.toFixed(2) || null,
  recruitmentConditionDepositValue:
    data.recruitmentConditionDepositValue?.toFixed(2) || null,
  recruitmentConditionBonusValue:
    data.recruitmentConditionBonusValue?.toFixed(2) || null,

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuybackBonusValue:
    data.temporaryPlacementConditionPossibleBuybackBonusValue?.toFixed(2) ||
    null,
});

export const normalizeCandidateRequestEditData = (
  data: CandidateRequest
): CandidateRequestFormFields => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  jobSpecialtyId: data.jobSpecialtyId,
  specialty: data.specialty,
  files: [],
  desiredStartDate: data.desiredStartDate || null,
  receivingOfferDeadline: data.receivingOfferDeadline || null,
  operationTerritoryCodes:
    data.operationTerritoryCodes as OperationTerritoryCode[],
  experience: data.experience,
  metAt: data.metAt || null,
  type: data.type,
  canWorkInCanadaOrQuebec: data.canWorkInCanadaOrQuebec,
  jobType: data.jobType,
  jobDurationInMonths: data.jobDurationInMonths,
  seasons: data.seasons as Season[],
  hoursPerWeek: (data.hoursPerWeek as string[]) || [],
  fullTimeAvailability: data.fullTimeAvailability,
  partTimeAvailability: data.partTimeAvailability,
  schedules: data.schedules as JobScheduleTypeCode[],
  jobModeCode: data.jobModeCode,
  remoteDaysAllowed: data.remoteDaysAllowed,
  paymentFrequencyCode: data.paymentFrequencyCode,
  annualSalaryMin: data.annualSalaryMin,
  annualSalaryMax: data.annualSalaryMax,
  hourlyRateMin: parseNumber(data.hourlyRateMin),
  hourlyRateMax: parseNumber(data.hourlyRateMax),
  hourlyBonus: parseNumber(data.hourlyBonus),
  remunerationAmount: parseNumber(data.remunerationAmount),
  otherPaymentTypeCode: data.otherPaymentTypeCode,
  otherPaymentAmount: parseNumber(data.otherPaymentAmount),
  softSkills: (data.softSkills as string[]) || [],
  additionalNotes: data.additionalNotes,

  /* GENERAL CONDITIONS */
  recruitmentConditionGuaranteedPeriodValue:
    data.recruitmentConditions?.guaranteedPeriodValue,
  recruitmentConditionGuaranteedPeriodDisplay:
    data.recruitmentConditions?.guaranteedPeriodDisplay,
  recruitmentConditionRemunerationTypeValue:
    data.recruitmentConditions?.remunerationTypeValue,
  recruitmentConditionRemunerationPercentage:
    data.recruitmentConditions?.remunerationPercentage,
  recruitmentConditionRemunerationAmount: parseNumber(
    data.recruitmentConditions?.remunerationAmount
  ),
  recruitmentConditionRemunerationHourlyRate: parseNumber(
    data.recruitmentConditions?.remunerationHourlyRate
  ),
  recruitmentConditionRemunerationEstimatedHours:
    data.recruitmentConditions?.remunerationEstimatedHours,
  recruitmentConditionRemunerationTypeDisplay:
    data.recruitmentConditions?.remunerationTypeDisplay,
  recruitmentConditionProbationPeriodValue:
    data.recruitmentConditions?.probationPeriodValue,
  recruitmentConditionProbationPeriodDisplay:
    data.recruitmentConditions?.probationPeriodDisplay,
  recruitmentConditionPaymentMethodValue:
    data.recruitmentConditions?.paymentMethodValue,
  recruitmentConditionStartOfAgreementPaymentMethodValue:
    data.recruitmentConditions?.startOfAgreementPaymentMethodValue,
  recruitmentConditionPaymentAmountValue:
    data.recruitmentConditions?.paymentAmountValue,
  recruitmentConditionDepositValue: parseNumber(
    data.recruitmentConditions?.depositValue
  ),
  recruitmentConditionPaymentFrequencyValue:
    data.recruitmentConditions?.paymentFrequencyValue,
  recruitmentConditionPaymentMethodDisplay:
    data.recruitmentConditions?.paymentMethodDisplay,
  recruitmentConditionBonusValue: parseNumber(
    data.recruitmentConditions?.bonusValue
  ),
  recruitmentConditionBonusMonthValue:
    data.recruitmentConditions?.bonusMonthValue,
  recruitmentConditionBonusWeekValue:
    data.recruitmentConditions?.bonusWeekValue,
  recruitmentConditionBonusDisplay: data.recruitmentConditions?.bonusDisplay,

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionProbationPeriodValue:
    data.temporaryPlacementConditions?.probationPeriodValue,
  temporaryPlacementConditionProbationPeriodDisplay:
    data.temporaryPlacementConditions?.probationPeriodDisplay,
  temporaryPlacementConditionMinimumNoticeValue:
    data.temporaryPlacementConditions?.minimumNoticeValue,
  temporaryPlacementConditionMinimumNoticeDisplay:
    data.temporaryPlacementConditions?.minimumNoticeDisplay,
  temporaryPlacementConditionPossibleBuybackValue:
    data.temporaryPlacementConditions?.possibleBuybackValue,
  temporaryPlacementConditionPossibleBuybackDisplay:
    data.temporaryPlacementConditions?.possibleBuybackDisplay,
  temporaryPlacementConditionPossibleBuybackBonusValue: parseNumber(
    data.temporaryPlacementConditions?.possibleBuybackBonusValue
  ),
  temporaryPlacementConditionPossibleBuybackBonusDisplay:
    data.temporaryPlacementConditions?.possibleBuybackBonusDisplay,
  temporaryPlacementConditionTravelExpensesResponsibility:
    data.temporaryPlacementConditions?.travelExpensesResponsibility,
  temporaryPlacementConditionTrainingExpensesResponsibility:
    data.temporaryPlacementConditions?.trainingExpensesResponsibility,
  temporaryPlacementConditionEmployeeHelpProgramResponsibility:
    data.temporaryPlacementConditions?.employeeHelpProgramResponsibility,
  temporaryPlacementConditionCnesstResponsibility:
    data.temporaryPlacementConditions?.cnesstResponsibility,
  temporaryPlacementConditionOtherResponsibilities:
    data.temporaryPlacementConditions?.otherResponsibilities,
  temporaryPlacementConditionResponsibilitiesDisplay:
    data.temporaryPlacementConditions?.responsibilitiesDisplay,
  temporaryPlacementConditionBillingPeriodValue:
    data.temporaryPlacementConditions?.billingPeriodValue,
  temporaryPlacementConditionBillingPeriodDisplay:
    data.temporaryPlacementConditions?.billingPeriodDisplay,

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMinimumNoticeValue:
    data.outsourcingConditions?.minimumNoticeValue,
  outsourcingConditionMinimumNoticeDisplay:
    data.outsourcingConditions?.minimumNoticeDisplay,
  outsourcingConditionBillingPeriodValue:
    data.outsourcingConditions?.billingPeriodValue,
  outsourcingConditionBillingPeriodDisplay:
    data.outsourcingConditions?.billingPeriodDisplay,
});
