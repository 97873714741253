import { Placement } from '@floating-ui/react';

import { Avatar } from '@application/components/avatar';
import { cn } from '@utils/lib-utils';

import { Tooltip } from '../tooltip';

type PictureBadgesProps = {
  tooltipPosition?: Placement;
  rounded?: 'rounded-md' | 'rounded-full';
  pictures: { url?: string | null; name?: string }[] | undefined;
};

const PictureBadges = ({
  tooltipPosition,
  rounded,
  pictures,
}: PictureBadgesProps) => (
  <div className="flex">
    {pictures?.map((picture, idx) => (
      <Tooltip
        key={picture.name}
        position={tooltipPosition}
        message={picture.name}
        className="!z-0 max-h-[2.5rem]"
      >
        <Avatar
          className={cn(
            'w-s-40 h-s-40',
            { '-ml-s-8 z-0': idx !== 0 },
            rounded ?? 'rounded-full'
          )}
          name={picture.name}
          url={picture.url}
        />
      </Tooltip>
    ))}
  </div>
);

export default PictureBadges;
