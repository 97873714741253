import { useCandidateNegotiations } from '@domain/candidate';
import { CandidateNegotiationsFilters } from '@domain/graphql.types';

import { toViewModel } from '../view-models/get-candidate-negotiations.vm';

const useGetCandidateNegotiations = (
  filterBy?: CandidateNegotiationsFilters
) => {
  const { data, fetching, error, reexecuteQuery } = useCandidateNegotiations({
    filterBy,
  });

  const viewModel = toViewModel({
    candidateNegotiations: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateRequests: reexecuteQuery,
  };
};

export default useGetCandidateNegotiations;
