import { useMutation } from 'urql';

import { Mutation, MutationOfferStatusUpdateArgs } from '@domain/graphql.types';
import { MutationOfferStatusUpdate } from '@infrastructure/graphql/offer';

const useOfferUpdateStatus = () => {
  const [{ data, fetching, error }, updateOfferStatus] = useMutation<
    Pick<Mutation, 'offerStatusUpdate'>,
    MutationOfferStatusUpdateArgs
  >(MutationOfferStatusUpdate);

  return {
    updateOfferStatus,
    data,
    fetching,
    error,
  };
};

export default useOfferUpdateStatus;
