import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AccountContext, UserContext } from '@application/context';
import { AccountUserStatus, User } from '@domain/graphql.types';
import { useMeQuery } from '@domain/user';

type UserProviderProps = {
  children: React.ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [initializationCompleted, setInitializationCompleted] = useState(false);

  const { data, fetching, error, reexecuteQuery } = useMeQuery(true);
  const { account, initializeAccount } = useContext(AccountContext);

  const initializeUser = useCallback(() => {
    reexecuteQuery();
    initializeAccount();
  }, [initializeAccount, reexecuteQuery]);

  const user = useMemo(() => {
    if (!data?.me?.user) {
      return undefined;
    }

    if (!initializationCompleted) {
      setInitializationCompleted(true);
    }

    return { ...data.me.user };
  }, [data?.me.user, initializationCompleted]);

  const checkUserHasPendingInvitation = useCallback(
    (u: User | undefined) =>
      !!u?.accounts?.some(
        (a) => a.status === AccountUserStatus.PendingInvitation
      ),
    []
  );

  const [hasFinishedOnboarding, setHasFinishedOnboarding] = useState(
    !!user?.defaultAccountId
  );
  const [hasPendingInvitation, setHasPendingInvitation] = useState(
    checkUserHasPendingInvitation(user)
  );
  const [avatarUrl, setAvatarUrl] = useState(user?.avatarUrl);
  const [isEmailVerified, setIsEmailVerified] = useState(
    user?.emailVerified || false
  );

  const accountUser = useMemo(() => {
    if (user?.accounts?.length === 1) {
      return user.accounts[0];
    }

    return user?.accounts?.find((a) => a.accountId === account?.id);
  }, [user, account]);

  useEffect(() => {
    if (!user) {
      return;
    }

    setHasPendingInvitation(checkUserHasPendingInvitation(user));
    setAvatarUrl(user.avatarUrl);
  }, [checkUserHasPendingInvitation, user]);

  const updateUserEmail = useCallback(
    (email: string) => {
      if (!user) {
        return;
      }

      user.email = email;
    },
    [user]
  );

  const value = useMemo(
    () => ({
      accountUser,
      avatarUrl,
      error,
      hasFinishedOnboarding: !!user?.defaultAccountId || hasFinishedOnboarding,
      hasPendingInvitation,
      initializationCompleted,
      initializeUser,
      isEmailVerified: user?.emailVerified || isEmailVerified,
      isLoading: fetching,
      refreshUser: reexecuteQuery,
      setAvatarUrl,
      setHasFinishedOnboarding,
      setHasPendingInvitation,
      setIsEmailVerified,
      updateUserEmail,
      user,
    }),
    [
      accountUser,
      avatarUrl,
      error,
      fetching,
      hasFinishedOnboarding,
      hasPendingInvitation,
      initializationCompleted,
      initializeUser,
      isEmailVerified,
      reexecuteQuery,
      setAvatarUrl,
      setHasFinishedOnboarding,
      setHasPendingInvitation,
      setIsEmailVerified,
      updateUserEmail,
      user,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
