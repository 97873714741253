export const CREATE_OFFER_STEPS = [
  {
    title: 'steps.candidate',
    icon: 'ri-suitcase-line',
  },
  {
    title: 'steps.agency',
    icon: 'ri-folder-info-line',
  },
];

export const DEFAULT_VALUES = {
  publishNow: false,
  hoursPerWeek: [],
  seasons: [],
  fullTimeAvailability: false,
  partTimeAvailability: false,
  schedules: [],
  annualSalaryMin: 20,
  annualSalaryMax: 200,
  hourlyRateMin: 20,
  hourlyRateMax: 200,
  perks: [],
};

export const TH_CLASS_NAME =
  'min-w-[18rem] h-s-48 pl-s-16 p-medium font-normal whitespace-nowrap text-left flex items-center';

export const TD_CLASS_NAME =
  'min-w-[10rem] text-center p-medium px-s-6 whitespace-nowrap';
