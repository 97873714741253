import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import { UserContext } from '@application/context';
import { UserRole } from '@domain/graphql.types';

import useGetSubscriptionBillingPortalLink from './hooks/useGetSubscriptionBillingPortalLink';

interface BillingPortalButtonProps {
  text?: string;
  className?: string;
  icon?: React.ReactNode;
  buttonType?: 'primary' | 'ghost';
}

const BillingPortalButton = ({
  text,
  className,
  icon,
  buttonType,
}: BillingPortalButtonProps) => {
  const { i18n } = useTranslation();
  const { t: tGlobal } = useTranslation();
  const { fetching, executeMutation } = useGetSubscriptionBillingPortalLink();
  const { accountUser } = useContext(UserContext);

  const isAllowedToUpgradeSubscription = useMemo(
    () =>
      accountUser?.role === UserRole.Owner ||
      accountUser?.role === UserRole.Admin,
    [accountUser]
  );

  const handleButtonClick = async () => {
    const response = await executeMutation({
      input: { language: i18n.language },
    });

    if (response.data?.getSubscriptionBillingPortalLink.url) {
      window.open(response.data.getSubscriptionBillingPortalLink.url, '_blank');
    }
  };

  return isAllowedToUpgradeSubscription ? (
    <Button
      key="membership"
      loading={fetching}
      disabled={fetching}
      className={className}
      icon={icon}
      onClick={handleButtonClick}
      primary={buttonType === 'primary'}
      ghost={buttonType === 'ghost'}
    >
      <span className="font-normal text-16 text-neutral-secondary">
        {text || tGlobal('membership.default')}
      </span>
    </Button>
  ) : null;
};

export default BillingPortalButton;
