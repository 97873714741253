import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import RecruitmentOpportunityGeneralConditionsFragment from './recruitment.opportunity.general.conditions.fragment';
import RecruitmentOpportunityHeadhunterConditionsFragment from './recruitment.opportunity.headhunter.conditions.fragment';
import RecruitmentOpportunityInternationalRecruitmentConditionsFragment from './recruitment.opportunity.international.recruitment.conditions.fragment';
import RecruitmentOpportunityOutsourcingConditionsFragment from './recruitment.opportunity.outsourcing.conditions.fragment';
import RecruitmentOpportunityTemporaryPlacementConditionsFragment from './recruitment.opportunity.temporary.placement.conditions.fragment';

const RecruitmentOpportunityFragment = gql`
  ${DescriptionsFragment}
  ${RecruitmentOpportunityGeneralConditionsFragment}
  ${RecruitmentOpportunityHeadhunterConditionsFragment}
  ${RecruitmentOpportunityInternationalRecruitmentConditionsFragment}
  ${RecruitmentOpportunityOutsourcingConditionsFragment}
  ${RecruitmentOpportunityTemporaryPlacementConditionsFragment}

  fragment RecruitmentOpportunityFragment on RecruitmentOpportunity {
    id
    accountIndustryType {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    accountSector {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    citiesSubRegions
    createdAt
    desiredEducationLevels {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    desiredStartDate
    desiredStudyField
    generalContractualConditions {
      ...RecruitmentOpportunityGeneralConditionsFragment
    }
    generalNotes
    headhunterContractualConditions {
      ...RecruitmentOpportunityHeadhunterConditionsFragment
    }
    internationalRecruitmentContractualConditions {
      ...RecruitmentOpportunityInternationalRecruitmentConditionsFragment
    }
    jobAdditionalNotes
    jobAvailabilityInHoursPerWeek
    jobDuration {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobDurationCode
    jobDurationInMonths
    jobExperienceLevelMax
    jobExperienceLevelMin
    jobFullTimeAvailability
    jobHourlyBonus
    jobHourlyRateMax
    jobHourlyRateMin
    jobInternalTitle
    jobMode {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobModeCode
    jobOpenPositions
    jobOtherRemunerationAmount
    jobPartTimeAvailability
    jobPaymentFrequencyCode
    jobPaymentOtherTypesCodes
    jobRemoteModeInDays
    jobRemunerationAmount
    jobSalaryMax
    jobSalaryMin
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobSpecialtyCode
    jobWorkingHours
    operationTerritories {
      descriptions {
        ...DescriptionsFragment
      }
    }
    operationTerritoryCodes
    operationUnit {
      name
    }
    otherPerks
    outsourcingContractualConditions {
      ...RecruitmentOpportunityOutsourcingConditionsFragment
    }
    perks
    receivingOfferDeadline
    sector {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    softSkills
    specialty
    temporaryPlacementContractualConditions {
      ...RecruitmentOpportunityTemporaryPlacementConditionsFragment
    }
    types
    updatedAt
  }
`;

export default RecruitmentOpportunityFragment;
