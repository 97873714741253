import { useMutation } from 'urql';

import {
  Mutation,
  MutationNegotiatingOfferUpdateArgs,
} from '@domain/graphql.types';
import { MutationNegotiatingOfferUpdate } from '@infrastructure/graphql/offer';

const useNegotiatingOfferUpdate = () => {
  const [{ data, fetching, error }, updateNegotiatingOffer] = useMutation<
    Pick<Mutation, 'negotiatingOfferUpdate'>,
    MutationNegotiatingOfferUpdateArgs
  >(MutationNegotiatingOfferUpdate);

  return {
    updateNegotiatingOffer,
    data,
    fetching,
    error,
  };
};

export default useNegotiatingOfferUpdate;
