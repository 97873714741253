import { gql } from '@urql/core';

const MutationRequestStatusUpdate = gql`
  mutation RequestStatusUpdate(
    $requestStatusUpdateInput: RequestStatusUpdate!
  ) {
    requestStatusUpdate(requestStatusUpdateInput: $requestStatusUpdateInput) {
      request {
        id
        status
      }
    }
  }
`;

export default MutationRequestStatusUpdate;
