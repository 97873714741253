import { gql } from 'urql';

const MutationRecruitmentNegotiationAddRooms = gql`
  mutation RecruitmentNegotiationAddRooms(
    $input: RecruitmentNegotiationAddRoomsInput!
  ) {
    recruitmentNegotiationAddRooms(input: $input) {
      negotiation {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationAddRooms;
