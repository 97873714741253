import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SwitchField } from '@application/components';
import { Button } from '@application/components/buttons';
import { Stack } from '@application/components/container-layouts';
import { Modal } from '@application/components/modal';
import { SelectField } from '@application/components/select-field';
import { AccountContext, AlertContext } from '@application/context';
import { useSpecialties } from '@application/hooks';
import { AccountIndustryType } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import useCreateAccountPreferredSpecialties from '../actions/useCreateAccountPreferredSpecialties';
import { CREATE_ACCOUNT_PREFERRED_SPECIALTY_SCHEMA } from './schema';
import { CreateAccountPreferredSpecialtiesFormFields } from './types';

export type CreateSpecialtyModalProps = {
  onClose: () => void;
  afterSubmit: (newJobSpecialtyCode?: string) => void;
  createOnly?: boolean;
};

const CreateSpecialtyModal = ({
  onClose,
  afterSubmit,
  createOnly = false,
}: CreateSpecialtyModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities',
  });

  const { account } = useContext(AccountContext);
  const { setAlertContent } = useContext(AlertContext);

  const {
    createAccountPreferredSpecialties,
    viewModel: { isLoading },
  } = useCreateAccountPreferredSpecialties();

  const { control, handleSubmit, watch, setValue } =
    useForm<CreateAccountPreferredSpecialtiesFormFields>({
      mode: 'onBlur',
      resolver: yupResolver(CREATE_ACCOUNT_PREFERRED_SPECIALTY_SCHEMA),
    });

  const industryType = watch('industryType');
  const industrySector = watch('industrySector');

  const {
    industryTypesLoading,
    sectorsLoading,
    specialtiesLoading,
    INDUSTRY_TYPE_OPTIONS,
    INDUSTRY_SECTORS_OPTIONS,
    JOB_SPECIALTIES_OPTIONS,
    onIndustryTypeChange,
    onIndustrySectorChange,
    onSpecialtyChange,
  } = useSpecialties({
    industryType,
    industrySector,
    language: extractLanguage(i18n.language),
    setSpecialty: (value: string | undefined) => {
      if (value) {
        setValue('specialtyId', value);
      } else {
        setValue('specialtyId', '');
      }
    },
    setSector: (value: string | undefined) => setValue('industrySector', value),
    setType: (value: AccountIndustryType | undefined) =>
      setValue('industryType', value),
  });

  const [addToSpecialties, setAddToSpecialties] = useState(true);

  const onSubmit: SubmitHandler<CreateAccountPreferredSpecialtiesFormFields> =
    useCallback(
      async ({ specialtyId }) => {
        if (!account?.id) {
          return;
        }

        if (createOnly || addToSpecialties) {
          const result = await createAccountPreferredSpecialties({
            input: {
              accountId: account.id,
              jobSpecialtyIds: [specialtyId],
            },
          });

          /**
           * FIXME: Ideally we would put these operations in the
           * useCreateAccountPreferredSpecialties.ts, but the modal
           * as it is kills the ongoing processes when closed.
           */
          if (
            result.data?.accountPreferredSpecialtiesCreate
              .accountPreferredSpecialties
          ) {
            setAlertContent({
              action: t('messages.success.create'),
              modifier: 'alert-success',
            });
            afterSubmit(specialtyId);
          }
        } else {
          afterSubmit(specialtyId);
        }
      },
      [
        account?.id,
        addToSpecialties,
        afterSubmit,
        createAccountPreferredSpecialties,
        createOnly,
        setAlertContent,
        t,
      ]
    );

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div ref={modalRef} tabIndex={-1}>
        <Modal.CloseBtn onClick={onClose} />

        <Stack space={24}>
          <Controller
            name="industryType"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <SelectField
                className="flex-grow"
                name={name}
                menuPlacement="bottom"
                label={t('createModal.industry')}
                options={INDUSTRY_TYPE_OPTIONS}
                value={
                  INDUSTRY_TYPE_OPTIONS.find(
                    (industry) => industry.value === value
                  ) || null
                }
                onChange={onIndustryTypeChange(onChange, value)}
                searchable
                disabled={industryTypesLoading}
              />
            )}
          />

          <Controller
            name="industrySector"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <SelectField
                className="flex-grow"
                name={name}
                menuPlacement="bottom"
                label={t('createModal.sector')}
                options={INDUSTRY_SECTORS_OPTIONS}
                value={
                  INDUSTRY_SECTORS_OPTIONS.find(
                    (sector) => sector.value === value
                  ) || null
                }
                onChange={onIndustrySectorChange(onChange, value)}
                searchable
                disabled={sectorsLoading}
              />
            )}
          />

          <Controller
            name="specialtyId"
            control={control}
            render={({
              field: { onChange, name, value },
              fieldState: { error },
            }) => (
              <SelectField
                className="flex-grow"
                name={name}
                menuPlacement="top"
                label={t('createModal.specialty')}
                options={JOB_SPECIALTIES_OPTIONS}
                value={
                  JOB_SPECIALTIES_OPTIONS.find(
                    (specialty) => specialty.value === value
                  ) || null
                }
                invalid={!!error}
                helperText={error?.message && tGlobal(error?.message)}
                onChange={onSpecialtyChange(onChange)}
                searchable
                disabled={specialtiesLoading}
              />
            )}
          />
          {!createOnly && (
            <SwitchField
              label={t('createModal.addToPreferences')}
              name="addToPreference"
              className="mt-s-8"
              checked={addToSpecialties}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setAddToSpecialties(event.target.checked);
              }}
            />
          )}
        </Stack>

        <Modal.Action>
          <Button onClick={onClose} disabled={isLoading}>
            {tGlobal('button.cancel')}
          </Button>

          <Button primary type="submit" loading={isLoading}>
            {tGlobal('button.confirm')}
          </Button>
        </Modal.Action>
      </div>
    </form>
  );
};

export default CreateSpecialtyModal;
