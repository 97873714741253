import { useTranslation } from 'react-i18next';
import { components, GroupBase, MultiValueProps } from 'react-select';

import { OptionType } from '../types';

const MultiValue = (limitTags?: number | undefined) => {
  const { t } = useTranslation();

  const component = (
    props: MultiValueProps<OptionType, boolean, GroupBase<OptionType>>
  ) => {
    const { index, getValue } = props;

    const items = getValue()
      .slice(limitTags)
      .map((x) => x.label);

    if (limitTags === undefined) {
      return <components.MultiValue {...props} />;
    }

    if (index <= limitTags) {
      if (index === limitTags && items.length > 1) {
        const label = t('select.label.selectedItems', {
          count: items.length,
        });
        return (
          <components.MultiValue {...props}>
            <span>{label}</span>
          </components.MultiValue>
        );
      }
      return <components.MultiValue {...props} />;
    }
    return null;
  };
  return component;
};

export default MultiValue;
