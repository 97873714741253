import { gql } from '@urql/core';

const AccountUserDelete = gql`
  mutation AccountUserDelete($input: DeleteAccountUserInput!) {
    accountUserDelete(input: $input) {
      ok
    }
  }
`;

export default AccountUserDelete;
