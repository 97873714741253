import * as yup from 'yup';

import {
  ConditionRemunerationType,
  RequestConditionPaymentMethodCode,
  RequestConditionResponsibility,
} from '../../../../../domain/graphql.types';
import { validateDecimal } from '../../../../../utils/yup-utils';

export const RECRUITMENT_NEGOTIATION_ROOM_SCHEMA = yup.object({
  fieldInModification: yup.string().nullable(),

  /* GENERAL CONDITIONS */
  conditionPaymentMethod: yup
    .mixed<RequestConditionPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionPaymentMethodCode))
    .nullable(),
  conditionDeposit: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionMaxRecruitmentPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionMinGuaranteedPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionRemunerationType: yup
    .mixed<ConditionRemunerationType>()
    .oneOf(Object.values(ConditionRemunerationType))
    .nullable(),
  conditionRemunerationPercentage: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  conditionRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionRemunerationHourlyRate: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionRemunerationEstimatedHours: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),

  /* HEADHUNTER CONDITIONS */
  headhunterConditionMaximumReplacement: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  headhunterConditionExclusivityPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuyback: yup.boolean().nullable(),
  temporaryPlacementConditionTravelExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionTrainingExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionCnesstExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),

  /* INTERNATIONAL RECRUITMENT CONDITIONS */
  internationalRecruitmentConditionExclusivityPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  internationalRecruitmentConditionMaximumReplacement: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumDuration: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  outsourcingConditionExclusivityPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  outsourcingConditionMaximumBudget: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
});

export type RecruitmentNegotiationRoomFormFields = yup.InferType<
  typeof RECRUITMENT_NEGOTIATION_ROOM_SCHEMA
>;
