import { useQuery } from 'urql';

import { Query, QueryOfferCandidatesArgs } from '@domain/graphql.types';
import { OfferCandidates } from '@infrastructure/graphql/offer';

const useOfferCandidates = (variables: QueryOfferCandidatesArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'offerCandidates'>,
    QueryOfferCandidatesArgs
  >({
    query: OfferCandidates,
    variables,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.offerCandidates.results,
    totalCount: data?.offerCandidates.totalCount,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useOfferCandidates;
