import { useOperationTerritories } from '@domain/operation/territories';

import { toViewModel } from '../view-models/get-operation-territories.vm';

const useGetOperationTerritories = () => {
  const { data = {}, fetching, error } = useOperationTerritories();

  return toViewModel({
    operationTerritories: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetOperationTerritories;
