import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountContext } from '@application/context';

import { IconButton } from '../buttons';
import { Player } from '../player';
import { GetHelpButton, OnboardingSelectionItem } from './components';

export type OnboardingGuideProps = {
  onClose: () => void;
};

const OnboardingGuide = ({ onClose }: OnboardingGuideProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.onboardingGuide',
  });
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const { account } = useContext(AccountContext);
  const isEnterpriseAccount = account?.type === 'ENTERPRISE';

  const selectionItems = useMemo(
    () =>
      [
        {
          description: t('selectionItems.completeProfile'),
          videoLink: isEnterpriseAccount
            ? 'youtu.be/kQ4fxtmd5BM'
            : 'youtu.be/kqvRA3Mhga0',
        },
        {
          description: t('selectionItems.createFirstRequest'),
          videoLink: isEnterpriseAccount
            ? 'youtu.be/LCJ508f5iu8'
            : 'youtu.be/ypEpcQUV0RI',
        },
        {
          description: isEnterpriseAccount
            ? t('selectionItems.consultCandidateOpportunities')
            : t('selectionItems.consultRecruitmentOpportunities'),
          videoLink: isEnterpriseAccount
            ? 'youtu.be/od9KzaNJuyA'
            : 'youtu.be/uul8tB4rKkM',
        },
        {
          description: t('selectionItems.makeFirstOffer'),
          videoLink: isEnterpriseAccount
            ? 'youtu.be/hr6I4KYxoWo'
            : 'youtu.be/oHtHtL8brdM',
        },
        isEnterpriseAccount && {
          description: t('selectionItems.discoverNegotiations'),
          videoLink: 'youtu.be/LYca4Fb0nrA?si=j8oBGu5BurLau2Rf',
        },
      ].filter((x) => x !== false),
    [isEnterpriseAccount, t]
  );

  const title = useMemo(
    () => (videoUrl ? t('backToGuide') : t('title')),
    [t, videoUrl]
  );

  return (
    <div className="flex flex-col w-s-384">
      <div className="flex items-center h-s-24">
        {videoUrl && (
          <div
            tabIndex={0}
            role="button"
            aria-label={t('backToGuide')}
            onClick={() => setVideoUrl(undefined)}
            onKeyDown={() => setVideoUrl(undefined)}
          >
            <i className="ri-arrow-left-line text-24 mr-s-8" />
          </div>
        )}

        <h1 className="h3">{title}</h1>

        <IconButton
          text=""
          outline={false}
          onClick={onClose}
          className="border-transparent hover:bg-transparent hover:border-transparent ml-auto"
          icon={<i className="ri-close-line text-24" />}
        />
      </div>
      {videoUrl ? (
        <Player className="mt-s-20" url={videoUrl} />
      ) : (
        <>
          <hr className="mt-s-20 mb-s-16" />
          <ul>
            {selectionItems.map(({ description, videoLink }, index) => (
              <li className="py-s-4" key={description}>
                <OnboardingSelectionItem
                  description={description || ''}
                  badgeNumber={index + 1}
                  onClick={() => setVideoUrl(videoLink)}
                />
              </li>
            ))}
          </ul>

          <hr className="mt-s-16 mb-s-20" />

          <GetHelpButton />
        </>
      )}
    </div>
  );
};

export default OnboardingGuide;
