import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Banner from '@application/assets/banner.svg?react';
import { Avatar, Stack } from '@application/components';
import { ButtonLink } from '@application/components/links';
import { AccountContext, UserContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { AccountTypeCode } from '@domain/graphql.types';

import { GeneralInformation, SocialLinks } from './components';

// TODO: use StatisticsBox for stats
//  <Stack>
//   <Switcher>
//     <StatisticBox value={18} label={t('labels.contracts')} />
//     <StatisticBox value={8} label={t('labels.activities')} />
//   </Switcher>
//   <Switcher>
//     <StatisticBox value={2} label="Lorem ipsum" />
//     <StatisticBox value={54} label={t('labels.offers')} />
//   </Switcher>
// </Stack>

// also TODO: add tabs
// const tabs = useMemo(
//   () => [
//     {
//       path: '#',
//       element: t('tabs.statistics'),
//       label: t('tabs.statistics'),
//       id: 'tab-statistics',
//     },
//     {
//       path: '#',
//       element: t('tabs.recommendations'),
//       label: t('tabs.recommendations'),
//       id: 'tab-recommendations',
//     },
//   ],
//   [t]
// );

const OrganizationAccountPage = () => {
  const { t } = useTranslation('user');

  const { account } = useContext(AccountContext);
  const { accountUser, user, refreshUser } = useContext(UserContext);

  useEffect(() => refreshUser(), [refreshUser]);

  return (
    <>
      <Banner className="w-[calc(100%+64px)] -mt-s-32 -ml-s-32" />

      <div className="grid md:grid-cols-[320px_1fr] gap-x-s-32 gap-y-s-8 mt-s-32">
        <Avatar
          className="border -mt-s-80"
          size="large"
          name={user?.name}
          url={user?.avatarUrl}
        />

        <div className="flex justify-between gap-s-16">
          {/* FIXME: add tabs instead of empty */}
          <div className="h-s-72" />

          <ButtonLink
            to={PrivatePage.USER_PROFILE}
            icon={<i className="ri-pencil-line" />}
            className="ml-auto"
          >
            {t('button.editProfile')}
          </ButtonLink>
        </div>

        <div>
          <h1 className="text-24 text-primary">{user?.name}</h1>
          <h2 className="text-18 font-normal text-neutral-secondary mt-s-8 mb-s-24">
            {accountUser?.account?.name}
          </h2>

          <Stack space={16}>
            {account?.type === AccountTypeCode.Agency && user?.bio && (
              <p className="text-14 text-neutral-secondary mb-s-16">
                {user?.bio}
              </p>
            )}

            <GeneralInformation />

            <SocialLinks
              linkedinUrl={user?.linkedinUrl ?? undefined}
              facebookUrl={user?.facebookUrl ?? undefined}
              instagramUrl={user?.instagramUrl ?? undefined}
              twitterUrl={user?.twitterUrl ?? undefined}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default OrganizationAccountPage;
