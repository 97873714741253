import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';

import type { AccountPreferredSpecialtiesData } from '../types';

type ToViewModel = {
  preferredSpecialties: {
    data: AccountPreferredSpecialtiesData;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  preferredSpecialties: { data, fetching, error },
}: ToViewModel): ViewModel<AccountPreferredSpecialtiesData> =>
  new ViewModel<AccountPreferredSpecialtiesData>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
