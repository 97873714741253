import { useMutation } from 'urql';

import {
  Mutation,
  MutationOfferCandidateUpdateFeedbackArgs,
} from '@domain/graphql.types';
import { MutationOfferCandidateUpdateFeedback } from '@infrastructure/graphql/offer';

const useOfferCandidateUpdateFeedback = () => {
  const [result, updateOfferCandidateFeedback] = useMutation<
    Pick<Mutation, 'offerCandidateUpdateFeedback'>,
    MutationOfferCandidateUpdateFeedbackArgs
  >(MutationOfferCandidateUpdateFeedback);

  const { data, fetching, error } = result;

  return {
    data: data?.offerCandidateUpdateFeedback.offerCandidate,
    fetching,
    error,
    updateOfferCandidateFeedback,
  };
};

export default useOfferCandidateUpdateFeedback;
