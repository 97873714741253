import { useEffect, useMemo } from 'react';
import { FormState, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { useRequestBudget } from '@domain/request';

import { RequestFormFields } from '../schema';

type UseCalculateRequestBudgetProps = {
  dirtyFields: FormState<RequestFormFields>['dirtyFields'];
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  defaultBudgetValue?: string | null;
};

const useCalculateRequestBudget = ({
  defaultBudgetValue,
  dirtyFields,
  setValue,
  watch,
}: UseCalculateRequestBudgetProps) => {
  const isBudgetDirty = useMemo(
    () => dirtyFields.conditionMaximumBudgetValue,
    [dirtyFields.conditionMaximumBudgetValue]
  );

  const [
    conditionMaximumBudgetValue,
    jobHourlyRateMax,
    jobPaymentFrequencyCode,
    jobRemunerationAmount,
    jobSalaryMax,
  ] = watch([
    'conditionMaximumBudgetValue',
    'jobHourlyRateMax',
    'jobPaymentFrequencyCode',
    'jobRemunerationAmount',
    'jobSalaryMax',
  ]);

  const budget = useRequestBudget({
    jobHourlyRateMax,
    jobPaymentFrequencyCode,
    jobRemunerationAmount,
    jobSalaryMax,
  });

  const canUpdateValue = useMemo(
    () =>
      !isBudgetDirty &&
      !defaultBudgetValue &&
      conditionMaximumBudgetValue !== budget,
    [budget, conditionMaximumBudgetValue, defaultBudgetValue, isBudgetDirty]
  );

  useEffect(() => {
    if (canUpdateValue) {
      setValue(
        'conditionMaximumBudgetValue',
        budget ? Math.round(budget) : conditionMaximumBudgetValue
      );
    }
  }, [budget, canUpdateValue, setValue, conditionMaximumBudgetValue]);
};

export default useCalculateRequestBudget;
