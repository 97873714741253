import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { IconButton } from '@application/components/buttons';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type OutsourcingConditionsProps = {
  data: Request | undefined;
};

const OutsourcingConditions = ({ data }: OutsourcingConditionsProps) => {
  const { t } = useTranslation('requests');

  return (
    <>
      <SectionGridRow
        isTopRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.outsourcingContractualConditions?.maximumDurationDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.maxMandateDuration')}</span>
        <PlaceholderSwitcher
          value={data?.outsourcingContractualConditions?.maximumDurationValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.outsourcingContractualConditions?.maximumDurationValue}
            &nbsp;
            {t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.outsourcingContractualConditions
                    ?.exclusivityPeriodDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">
          {t('labels.outsourcingExclusivityPeriod')}
        </span>
        <PlaceholderSwitcher
          value={data?.outsourcingContractualConditions?.exclusivityPeriodValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.outsourcingContractualConditions?.exclusivityPeriodValue}
            &nbsp;{t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.outsourcingContractualConditions?.maximumBudgetDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.maxCandidateBudget')}</span>
        <PlaceholderSwitcher
          value={!!data?.outsourcingContractualConditions?.maximumBudgetValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.outsourcingContractualConditions?.maximumBudgetValue}
            {t('suffix.currencyPerHour')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow className="col-span-2">
        <span className="mr-s-8">
          {t('labels.recruitmentResponsibilities')}
        </span>

        <span className="text-18 font-semibold text-primary">
          {data?.outsourcingContractualConditions?.responsibilities || '-'}
        </span>
      </SectionGridRow>
    </>
  );
};

export default OutsourcingConditions;
