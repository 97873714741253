import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { PlaceholderSwitcher } from '@application/components/switch';
import { PrivatePage } from '@application/enums/pagesUrl';
import {
  OfferStatusTypeCode,
  RecruitmentNegotiationRoom,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { RecruitmentNegotiationContext } from '../../../context';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

const ResumeLink = () => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  const { rooms, checkedRooms } = useContext(RecruitmentNegotiationContext);

  return (
    <tr>
      <th className={TH_ROW_CLASS_NAME.base}>{t('tableHeaders.resumes')}</th>
      {rooms?.map(
        ({
          id,
          requestId,
          offerId,
          negotiatingOffer: { status, resumeCount },
        }: RecruitmentNegotiationRoom) => (
          <td
            key={`resumeLink-${id}`}
            className={cn(
              TD_CLASS_NAME.base,
              'text-center border-l-1 border-b-1 border-transparent',
              {
                '!border-x-main border-x-2': checkedRooms.includes(id),
                'opacity-65': status === OfferStatusTypeCode.Rejected,
              }
            )}
          >
            <PlaceholderSwitcher value={resumeCount || undefined}>
              <Link
                className="underline font-semibold hover:no-underline"
                to={generatePath(PrivatePage.OFFER_CANDIDATES, {
                  id: requestId,
                  offerId,
                })}
              >
                {t('tableHeaders.resumeCount', { count: resumeCount })}
              </Link>
            </PlaceholderSwitcher>
          </td>
        )
      )}
    </tr>
  );
};

export default ResumeLink;
