import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

type ToViewModel = {
  user: {
    data: Pick<Mutation, 'userDeleteAvatar'> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  user: { data, fetching, error },
}: ToViewModel): ViewModel<Pick<Mutation, 'userDeleteAvatar'> | undefined> =>
  new ViewModel<Pick<Mutation, 'userDeleteAvatar'> | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
