import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountDeleteLogo } from '@domain/account';

import { toViewModel } from '../view-models/delete-logo.vm';

const useDeleteLogo = () => {
  const { data, fetching, error, deleteAccountLogo } = useAccountDeleteLogo();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('organization', {
    keyPrefix: 'updateProfileForm.organizationLogo',
  });

  const viewModel = toViewModel({
    user: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('messages.errors.delete'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.accountDeleteLogo?.ok) {
      setAlertContent({
        action: t('messages.success.delete'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.accountDeleteLogo?.ok]);

  return {
    viewModel,
    deleteAccountLogo,
  };
};

export default useDeleteLogo;
