import { useQuery } from 'urql';

import { Query, QueryRecruitmentOpportunityArgs } from '@domain/graphql.types';
import { RecruitmentOpportunity } from '@infrastructure/graphql/opportunity';

const useRecruitmentOpportunity = (input: QueryRecruitmentOpportunityArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'recruitmentOpportunity'>,
    QueryRecruitmentOpportunityArgs
  >({
    query: RecruitmentOpportunity,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.recruitmentOpportunity,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useRecruitmentOpportunity;
