import { useMutation } from 'urql';

import { MutationSaveAndPublishOffer } from '@infrastructure/graphql/offer';

const useOfferSaveAndPublish = () => {
  const [{ data, fetching, error }, saveAndPublish] = useMutation(
    MutationSaveAndPublishOffer
  );

  return {
    saveAndPublish,
    data,
    fetching,
    error,
  };
};

export default useOfferSaveAndPublish;
