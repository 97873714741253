import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Switch } from '@application/components';
import {
  RecruitmentNegotiationRoom,
  RecruitmentNegotiationRoomStatus,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { RecruitmentNegotiationContext } from '../../context';
import { TH_COL_CLASS_NAME } from '../constants';

type TableHeaderProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TableHeader = ({ onChange }: TableHeaderProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const { rooms, checkedRooms, isComparing } = useContext(
    RecruitmentNegotiationContext
  );

  return (
    <thead>
      <tr>
        <th
          className={cn(
            'w-s-192 min-w-[12rem] max-w-[12rem]',
            TH_COL_CLASS_NAME
          )}
        >
          <span className="sr-only">{t('agencyName')}</span>
        </th>

        {rooms?.map(
          ({
            id,
            status,
            negotiatingOffer: { account },
          }: RecruitmentNegotiationRoom) => (
            <th
              key={`tableHeader-${id}`}
              className={cn(TH_COL_CLASS_NAME, {
                '!border-x-2 border-t-2 border-t-main border-x-main [&:last-child]:rounded-tr-md':
                  checkedRooms.includes(id),
              })}
            >
              <Switch
                fallback={
                  <Checkbox
                    id={id}
                    checked={checkedRooms.includes(id)}
                    onChange={onChange}
                    disabled={isComparing}
                    labelDirection="bottom"
                    label={account.name}
                    name={account.name}
                    value={String(checkedRooms.includes(id))}
                    className="p-s-0"
                    labelTextClassName="font-semibold"
                  />
                }
              >
                <Switch.Match
                  when={status === RecruitmentNegotiationRoomStatus.Rejected}
                >
                  <div className="flex flex-col gap-s-10">
                    <i className="text-24 leading-initial font-normal text-error-content ri-close-circle-line" />
                    <span className="label-text normal-case text-16 font-semibold">
                      {account.name}
                    </span>
                  </div>
                </Switch.Match>

                <Switch.Match
                  when={status === RecruitmentNegotiationRoomStatus.Accepted}
                >
                  <div className="flex flex-col gap-s-10">
                    <i className="text-24 leading-initial font-normal text-success-content ri-shake-hands-line" />
                    <span className="label-text normal-case text-16 font-semibold">
                      {account.name}
                    </span>
                  </div>
                </Switch.Match>
              </Switch>
            </th>
          )
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;
