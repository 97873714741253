import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ConfirmationModal, PageLoader, Stack } from '@application/components';
import { ModalContext, NavContext } from '@application/context';
import { CandidateRequest, OfferStatusTypeCode } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';

import { useCreateCandidateNegotiation } from '../../negotiation/hooks';
import { ActionMenu } from '../components';
import { useGetCandidateRequestOffers } from '../hooks';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

type ComparisonTableProps = {
  candidateRequest: CandidateRequest;
};

const ComparisonTable = ({ candidateRequest }: ComparisonTableProps) => {
  const [comparing, setComparing] = useState<boolean>();
  const [checkedCandidateOffers, setCheckedCandidateOffers] = useState<
    string[]
  >([]);

  const { t, i18n } = useTranslation('candidates');

  const { id } = useParams();
  const { isBottomMenuOpen } = useContext(NavContext);
  const { setModal } = useContext(ModalContext);

  const {
    viewModel: { data: candidateOffers = [], isLoading },
    refreshCandidateRequestOffers,
  } = useGetCandidateRequestOffers(id || '');

  const { createCandidateNegotiation } = useCreateCandidateNegotiation();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setCheckedCandidateOffers([...checkedCandidateOffers, event.target.id]);
    } else {
      setCheckedCandidateOffers([
        ...checkedCandidateOffers.filter((c) => c !== event.target.id),
      ]);
    }
  };

  const confirmInviteNegoRoom = useCallback(async () => {
    await createCandidateNegotiation({
      input: {
        candidateOfferIds: [...checkedCandidateOffers],
        candidateRequestId: candidateRequest.id,
      },
    });
  }, [checkedCandidateOffers, createCandidateNegotiation, candidateRequest.id]);

  const handleClickInviteNegoRoom = useCallback(() => {
    setModal({
      title: t('modal.titles.confirmInviteNegoRoom'),
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p>
                <Trans
                  t={t}
                  i18nKey="modal.contents.confirmInviteNegoRoom"
                  values={{
                    specialty: getLocalizedDescription(
                      candidateRequest?.jobSpecialty?.descriptions,
                      i18n.language
                    ),
                  }}
                />
              </p>
              <p>{t('modal.contents.confirmInviteNegoRoomAdditional')}</p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={() => {
            confirmInviteNegoRoom();
            setModal(null);
          }}
        />
      ),
    });
  }, [
    confirmInviteNegoRoom,
    i18n.language,
    candidateRequest?.jobSpecialty?.descriptions,
    setModal,
    t,
  ]);

  const displayCandidateOffers = useMemo(
    () =>
      (comparing
        ? candidateOffers.filter((o) => checkedCandidateOffers.includes(o.id))
        : candidateOffers) || [],
    [comparing, checkedCandidateOffers, candidateOffers]
  );

  const marginBottom = useMemo(
    () => (isBottomMenuOpen ? 'mb-s-80' : 'mb-s-0'),
    [isBottomMenuOpen]
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className={cn('relative', marginBottom)}>
      <div className="relative">
        <div className="overflow-x-auto">
          <table
            data-testid="TEST-candidate-offers-table"
            className="w-full bg-base-100"
          >
            <TableHeader
              comparing={comparing}
              candidateOffers={displayCandidateOffers}
              checkedCandidateOffers={checkedCandidateOffers}
              onChange={handleOnChange as any}
            />

            <TableBody
              candidateRequest={candidateRequest}
              candidateOffers={displayCandidateOffers}
              checkedCandidateOffers={checkedCandidateOffers}
            />
          </table>
        </div>
      </div>

      <ActionMenu
        checkedOffers={checkedCandidateOffers}
        comparing={comparing}
        onInviteClicked={handleClickInviteNegoRoom}
        onComparisonClicked={() => setComparing(!comparing)}
        onDeselectAll={() => setCheckedCandidateOffers([])}
        offers={candidateOffers}
        acceptedOffer={candidateOffers.find(
          (offer) => offer.status === OfferStatusTypeCode.Accepted
        )}
        candidateRequest={candidateRequest}
        refreshCandidateOffers={refreshCandidateRequestOffers}
      />
    </div>
  );
};

export default ComparisonTable;
