import { useMutation } from 'urql';

import { Mutation, MutationAccountUserInviteArgs } from '@domain/graphql.types';
import { AccountUserInvite } from '@infrastructure/graphql/account-user';

const useAccountUserInvite = () => {
  const [{ data, fetching, error }, inviteAccountUser] = useMutation<
    Pick<Mutation, 'accountUserInvite'>,
    MutationAccountUserInviteArgs
  >(AccountUserInvite);

  return {
    inviteAccountUser,
    data: data?.accountUserInvite,
    fetching,
    error,
  };
};

export default useAccountUserInvite;
