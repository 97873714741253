import { gql } from '@urql/core';

import { CandidateNegotiationRoomListFragment } from '../fragment';

const CandidateNegotiationRooms = gql`
  ${CandidateNegotiationRoomListFragment}

  query CandidateNegotiationRooms($filterBy: CandidateNegotiationRoomsFilters) {
    candidateNegotiationRooms(filterBy: $filterBy) {
      results {
        ...CandidateNegotiationRoomListFragment
      }
      totalCount
    }
  }
`;

export default CandidateNegotiationRooms;
