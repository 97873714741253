import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useCandidateOfferUpdate } from '@domain/candidate/offer';

import { toViewModel } from '../view-models/update-candidate-offer.vm';

const useUpdateCandidateOffer = () => {
  const { data, fetching, error, updateCandidateOffer } =
    useCandidateOfferUpdate();

  const { t } = useTranslation('candidates', {
    keyPrefix: 'messages',
  });

  const navigate = useNavigate();

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    candidateOffer: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('updateOfferError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.candidateOfferUpdate.candidateOffer) {
      setAlertContent({
        action: t('updateOfferSuccess'),
        modifier: 'alert-success',
      });

      navigate(
        generatePath(PrivatePage.VIEW_CANDIDATE_OFFER, {
          id: data.candidateOfferUpdate.candidateOffer.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { updateCandidateOffer, viewModel };
};

export default useUpdateCandidateOffer;
