import { useMutation } from 'urql';

import {
  Mutation,
  MutationUserUpdateNotificationPreferencesArgs,
} from '@domain/graphql.types';
import { UserUpdateNotificationPreferences } from '@infrastructure/graphql/user';

const useUserUpdateNotificationPreferences = () => {
  const [{ data, fetching, error }, userUpdateNotificationPreferences] =
    useMutation<
      Pick<Mutation, 'userUpdateNotificationPreferences'>,
      MutationUserUpdateNotificationPreferencesArgs
    >(UserUpdateNotificationPreferences);

  return {
    userUpdateNotificationPreferences,
    data: data?.userUpdateNotificationPreferences,
    fetching,
    error,
  };
};

export default useUserUpdateNotificationPreferences;
