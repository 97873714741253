import React, { useMemo, useState } from 'react';

import NotificationCenterContext from './NotificationCenterContext';

type NotificationCenterProviderProps = {
  children: React.ReactNode;
};

const NotificationCenterProvider = ({
  children,
}: NotificationCenterProviderProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const value = useMemo(
    () => ({
      isPanelOpen,
      setIsPanelOpen,
    }),
    [isPanelOpen, setIsPanelOpen]
  );

  return (
    <NotificationCenterContext.Provider value={value}>
      {children}
    </NotificationCenterContext.Provider>
  );
};

export default React.memo(NotificationCenterProvider);
