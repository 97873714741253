import { ValueText } from '@application/utils';

export const BOOLEAN_VALUES: ValueText[] = [
  {
    value: 'true',
    text: { fr: 'Oui', en: 'Yes' },
  },
  {
    value: 'false',
    text: { fr: 'Non', en: 'No' },
  },
];
