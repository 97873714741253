// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Switch } from '@application/components';
import { TextInput } from '@application/components/form/text-input-field/textInput';
import { Select } from '@application/components/select-field/select';
import { useOfferPaymentMethodOptions } from '@application/hooks';
import useCandidateBillingPeriodOptions from '@application/hooks/useCandidateBillingPeriodOptions';
import {
  CandidateNegotiationRoom,
  CandidateRequestType,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import RoomReadOnlyCell from '../../../components/RoomReadOnlyCell';
import { CandidateNegotiationRoomFormFields } from '../../../room/types';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

type OfferToRecruiterProps = {
  modificationEnabled: boolean;
  room: CandidateNegotiationRoom;
  onSubmit: SubmitHandler<CandidateNegotiationRoomFormFields>;
};

const OfferToRecruiter = ({
  modificationEnabled,
  room,
  onSubmit,
}: OfferToRecruiterProps) => {
  const { t } = useTranslation('candidates');

  const offerPaymentMethodOptions = useOfferPaymentMethodOptions().map((m) => ({
    value: m.value,
    label: m.label.short,
  }));
  const candidateBillingPeriodOptions = useCandidateBillingPeriodOptions();

  const { control, setValue, watch, handleSubmit } =
    useFormContext<CandidateNegotiationRoomFormFields>();

  const fieldInModification = watch('fieldInModification');

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('labels.offerToRecruiter')}
        </th>
        <td className={cn(TD_CLASS_NAME.highlight)}>&nbsp;</td>
      </tr>

      {room.candidateRequest.type === CandidateRequestType.Recruitment ? (
        <>
          <tr>
            <th
              scope="col"
              className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.disabled)}
            >
              {t('labels.proposedFees')}
            </th>

            <td className={cn(TD_CLASS_NAME.base, TD_CLASS_NAME.disabled)}>
              <div className="flex flex-row items-center justify-between">
                -
              </div>
            </td>
          </tr>

          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.additionalBonus')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'group', {
                'p-s-0': fieldInModification === 'recruitmentConditionBonus',
              })}
            >
              <Switch
                fallback={
                  <RoomReadOnlyCell
                    value={
                      room.negotiatingCandidateOffer?.recruitmentConditions
                        ?.bonus
                    }
                    displayedValue={t('suffix.currency', {
                      value:
                        room.negotiatingCandidateOffer?.recruitmentConditions
                          ?.bonus,
                    })}
                    state={room.recruitmentPropertiesState.bonus}
                    showModifyButton={modificationEnabled}
                    onClick={() =>
                      setValue(
                        'fieldInModification',
                        'recruitmentConditionBonus'
                      )
                    }
                  />
                }
              >
                <Switch.Match
                  when={fieldInModification === 'recruitmentConditionBonus'}
                >
                  <Controller
                    name="recruitmentConditionBonus"
                    control={control}
                    render={({
                      field: { name, value, onChange },
                      fieldState: { error },
                    }) => (
                      <TextInput
                        autoFocus
                        className="rounded-none border-2 border-black h-full"
                        name={name}
                        id={name}
                        type="number"
                        minValue={1}
                        maxValue={99999999.99}
                        step={0.01}
                        invalid={!!error}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={() => setValue('fieldInModification', null)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </Switch.Match>
              </Switch>
            </td>
          </tr>

          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.guaranteeRequested')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'group', {
                'p-s-0':
                  fieldInModification ===
                  'recruitmentConditionGuaranteedPeriod',
              })}
            >
              <Switch
                fallback={
                  <RoomReadOnlyCell
                    value={
                      room.negotiatingCandidateOffer?.recruitmentConditions
                        ?.guaranteedPeriod
                    }
                    displayedValue={t('suffix.month', {
                      count: Number(
                        room.negotiatingCandidateOffer?.recruitmentConditions
                          ?.guaranteedPeriod
                      ),
                    })}
                    state={room.recruitmentPropertiesState.guaranteedPeriod!}
                    showModifyButton={modificationEnabled}
                    onClick={() =>
                      setValue(
                        'fieldInModification',
                        'recruitmentConditionGuaranteedPeriod'
                      )
                    }
                  />
                }
              >
                <Switch.Match
                  when={
                    fieldInModification ===
                    'recruitmentConditionGuaranteedPeriod'
                  }
                >
                  <Controller
                    name="recruitmentConditionGuaranteedPeriod"
                    control={control}
                    render={({
                      field: { name, value, onChange },
                      fieldState: { error },
                    }) => (
                      <TextInput
                        autoFocus
                        className="rounded-none border-2 border-black h-full"
                        name={name}
                        id={name}
                        type="number"
                        minValue={1}
                        invalid={!!error}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={() => setValue('fieldInModification', null)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </Switch.Match>
              </Switch>
            </td>
          </tr>

          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.paymentMethods')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'border-b-0 group', {
                'p-s-0':
                  fieldInModification === 'recruitmentConditionPaymentMethod',
              })}
            >
              <Switch
                fallback={
                  <RoomReadOnlyCell
                    value={
                      room.negotiatingCandidateOffer?.recruitmentConditions
                        ?.paymentMethod
                    }
                    displayedValue={t(
                      `enum.offerConditionPaymentMethodCode.short.${room.negotiatingCandidateOffer?.recruitmentConditions?.paymentMethod?.toLowerCase()}`
                    )}
                    state={room.recruitmentPropertiesState.paymentMethod!}
                    showModifyButton={modificationEnabled}
                    onClick={() =>
                      setValue(
                        'fieldInModification',
                        'recruitmentConditionPaymentMethod'
                      )
                    }
                  />
                }
              >
                <Switch.Match
                  when={
                    fieldInModification === 'recruitmentConditionPaymentMethod'
                  }
                >
                  <Controller
                    name="recruitmentConditionPaymentMethod"
                    control={control}
                    render={({
                      field: { name, value, onChange },
                      fieldState: { error },
                    }) => (
                      <Select
                        autoFocus
                        className="border-2 border-black h-full rounded-br-md"
                        controlClassName="border-none h-full"
                        name={name}
                        menuPlacement="bottom"
                        options={offerPaymentMethodOptions}
                        invalid={!!error}
                        value={offerPaymentMethodOptions.find(
                          (type) => type.value === value
                        )}
                        onBlur={() => setValue('fieldInModification', null)}
                        onChange={(option: any) => {
                          onChange(option.value);
                          handleSubmit(onSubmit)();
                        }}
                      />
                    )}
                  />
                </Switch.Match>
              </Switch>
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.desiredBillingPeriod')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'group', {
                'p-s-0':
                  fieldInModification ===
                    'temporaryPlacementConditionBillingPeriod' ||
                  fieldInModification === 'outsourcingConditionBillingPeriod',
              })}
            >
              {room.candidateRequest.type ===
              CandidateRequestType.TemporaryPlacement ? (
                <Switch
                  fallback={
                    <RoomReadOnlyCell
                      value={
                        room.negotiatingCandidateOffer
                          ?.temporaryPlacementConditions?.billingPeriod
                      }
                      displayedValue={t(
                        `enum.candidateConditionBillingPeriod.${room.negotiatingCandidateOffer?.temporaryPlacementConditions?.billingPeriod?.toLowerCase()}`
                      )}
                      state={
                        room.temporaryPlacementPropertiesState.billingPeriod
                      }
                      showModifyButton={modificationEnabled}
                      onClick={() =>
                        setValue(
                          'fieldInModification',
                          'temporaryPlacementConditionBillingPeriod'
                        )
                      }
                    />
                  }
                >
                  <Switch.Match
                    when={
                      fieldInModification ===
                      'temporaryPlacementConditionBillingPeriod'
                    }
                  >
                    <Controller
                      name="temporaryPlacementConditionBillingPeriod"
                      control={control}
                      render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                      }) => (
                        <Select
                          autoFocus
                          className="border-2 border-black h-full rounded-br-md"
                          controlClassName="border-none h-full"
                          name={name}
                          menuPlacement="bottom"
                          options={candidateBillingPeriodOptions}
                          invalid={!!error}
                          value={candidateBillingPeriodOptions.find(
                            (type) => type.value === value
                          )}
                          onBlur={() => setValue('fieldInModification', null)}
                          onChange={(option: any) => {
                            onChange(option.value);
                            handleSubmit(onSubmit)();
                          }}
                        />
                      )}
                    />
                  </Switch.Match>
                </Switch>
              ) : (
                <Switch
                  fallback={
                    <RoomReadOnlyCell
                      value={
                        room.negotiatingCandidateOffer?.outsourcingConditions
                          ?.billingPeriod
                      }
                      displayedValue={t(
                        `enum.candidateConditionBillingPeriod.${room.negotiatingCandidateOffer?.outsourcingConditions?.billingPeriod?.toLowerCase()}`
                      )}
                      state={room.outsourcingPropertiesState.billingPeriod}
                      showModifyButton={modificationEnabled}
                      onClick={() =>
                        setValue(
                          'fieldInModification',
                          'outsourcingConditionBillingPeriod'
                        )
                      }
                    />
                  }
                >
                  <Switch.Match
                    when={
                      fieldInModification ===
                      'outsourcingConditionBillingPeriod'
                    }
                  >
                    <Controller
                      name="outsourcingConditionBillingPeriod"
                      control={control}
                      render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                      }) => (
                        <Select
                          autoFocus
                          className="border-2 border-black h-full"
                          controlClassName="border-none h-full"
                          name={name}
                          menuPlacement="bottom"
                          options={candidateBillingPeriodOptions}
                          invalid={!!error}
                          value={candidateBillingPeriodOptions.find(
                            (type) => type.value === value
                          )}
                          onBlur={() => setValue('fieldInModification', null)}
                          onChange={(option: any) => {
                            onChange(option.value);
                            handleSubmit(onSubmit)();
                          }}
                        />
                      )}
                    />
                  </Switch.Match>
                </Switch>
              )}
            </td>
          </tr>

          <tr>
            <th scope="col" className={TH_ROW_CLASS_NAME.base}>
              {t('labels.minimumNotice')}
            </th>

            <td
              className={cn(TD_CLASS_NAME.base, 'group', {
                'border-b-0':
                  room.candidateRequest.type ===
                  CandidateRequestType.Outsourcing,
                'p-s-0':
                  fieldInModification ===
                    'temporaryPlacementConditionMinimumNotice' ||
                  fieldInModification === 'outsourcingConditionMinimumNotice',
              })}
            >
              {room.candidateRequest.type ===
              CandidateRequestType.TemporaryPlacement ? (
                <Switch
                  fallback={
                    <RoomReadOnlyCell
                      value={
                        room.negotiatingCandidateOffer
                          ?.temporaryPlacementConditions?.minimumNotice
                      }
                      displayedValue={t('suffix.week', {
                        count: Number(
                          room.negotiatingCandidateOffer
                            ?.temporaryPlacementConditions?.minimumNotice
                        ),
                      })}
                      state={
                        room.temporaryPlacementPropertiesState.minimumNotice
                      }
                      showModifyButton={modificationEnabled}
                      onClick={() =>
                        setValue(
                          'fieldInModification',
                          'temporaryPlacementConditionMinimumNotice'
                        )
                      }
                    />
                  }
                >
                  <Switch.Match
                    when={
                      fieldInModification ===
                      'temporaryPlacementConditionMinimumNotice'
                    }
                  >
                    <Controller
                      name="temporaryPlacementConditionMinimumNotice"
                      control={control}
                      render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                      }) => (
                        <TextInput
                          autoFocus
                          className="rounded-none border-2 border-black h-full"
                          name={name}
                          id={name}
                          type="number"
                          minValue={1}
                          invalid={!!error}
                          value={value || ''}
                          onChange={onChange}
                          onBlur={() => setValue('fieldInModification', null)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSubmit(onSubmit)();
                            }
                          }}
                        />
                      )}
                    />
                  </Switch.Match>
                </Switch>
              ) : (
                <Switch
                  fallback={
                    <RoomReadOnlyCell
                      value={
                        room.negotiatingCandidateOffer?.outsourcingConditions
                          ?.minimumNotice
                      }
                      displayedValue={t('suffix.week', {
                        count: Number(
                          room.negotiatingCandidateOffer?.outsourcingConditions
                            ?.minimumNotice
                        ),
                      })}
                      state={room.outsourcingPropertiesState.minimumNotice}
                      showModifyButton={modificationEnabled}
                      onClick={() =>
                        setValue(
                          'fieldInModification',
                          'outsourcingConditionMinimumNotice'
                        )
                      }
                    />
                  }
                >
                  <Switch.Match
                    when={
                      fieldInModification ===
                      'outsourcingConditionMinimumNotice'
                    }
                  >
                    <Controller
                      name="outsourcingConditionMinimumNotice"
                      control={control}
                      render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                      }) => (
                        <TextInput
                          autoFocus
                          className="rounded-none border-2 border-black h-full"
                          name={name}
                          id={name}
                          type="number"
                          minValue={1}
                          invalid={!!error}
                          value={value || ''}
                          onChange={onChange}
                          onBlur={() => setValue('fieldInModification', null)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSubmit(onSubmit)();
                            }
                          }}
                        />
                      )}
                    />
                  </Switch.Match>
                </Switch>
              )}
            </td>
          </tr>

          {room.candidateRequest.type ===
            CandidateRequestType.TemporaryPlacement && (
            <tr>
              <th scope="col" className="sr-only">
                {t('labels.possibleBuybackBonus')}
              </th>

              <td
                className={cn(TD_CLASS_NAME.base, 'group border-b-0', {
                  'p-s-0':
                    fieldInModification ===
                    'temporaryPlacementConditionPossibleBuybackBonus',
                })}
              >
                <Switch
                  fallback={
                    <RoomReadOnlyCell
                      value={
                        room.negotiatingCandidateOffer
                          ?.temporaryPlacementConditions?.possibleBuybackBonus
                      }
                      displayedValue={t('suffix.week', {
                        count: Number(
                          room.negotiatingCandidateOffer
                            ?.temporaryPlacementConditions?.possibleBuybackBonus
                        ),
                      })}
                      state={
                        room.temporaryPlacementPropertiesState
                          .possibleBuybackBonus
                      }
                      showModifyButton={modificationEnabled}
                      onClick={() =>
                        setValue(
                          'fieldInModification',
                          'temporaryPlacementConditionPossibleBuybackBonus'
                        )
                      }
                    />
                  }
                >
                  <Switch.Match
                    when={
                      fieldInModification ===
                      'temporaryPlacementConditionPossibleBuybackBonus'
                    }
                  >
                    <Controller
                      name="temporaryPlacementConditionPossibleBuybackBonus"
                      control={control}
                      render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                      }) => (
                        <TextInput
                          autoFocus
                          className="rounded-none border-2 border-black h-full"
                          name={name}
                          id={name}
                          type="number"
                          minValue={1}
                          maxValue={99999999.99}
                          step={0.01}
                          invalid={!!error}
                          value={value || ''}
                          onChange={onChange}
                          onBlur={() => setValue('fieldInModification', null)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSubmit(onSubmit)();
                            }
                          }}
                        />
                      )}
                    />
                  </Switch.Match>
                </Switch>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default OfferToRecruiter;
