import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateRequestUpdateStatusArgs,
} from '@domain/graphql.types';
import { MutationCandidateRequestUpdateStatus } from '@infrastructure/graphql/candidate';

const useCandidateRequestUpdateStatus = () => {
  const [{ data, fetching, error }, updateCandidateRequestStatus] = useMutation<
    Pick<Mutation, 'candidateRequestUpdateStatus'>,
    MutationCandidateRequestUpdateStatusArgs
  >(MutationCandidateRequestUpdateStatus);

  return {
    updateCandidateRequestStatus,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestUpdateStatus;
