import { useValidateIdParam } from '@application/hooks';
import { useCandidateOpportunity } from '@domain/candidate';

import { toViewModel } from '../view-models/get-candidate-opportunity.vm';

const useGetCandidateOpportunity = (candidateRequestId: string) => {
  useValidateIdParam(candidateRequestId);

  const { data, fetching, error } = useCandidateOpportunity({
    input: { candidateRequestId },
  });

  const viewModel = toViewModel({
    candidateOpportunity: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
  };
};

export default useGetCandidateOpportunity;
