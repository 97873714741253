import { gql } from 'urql';

import UserFragment from '../user.fragment';

const UserUpdateEmail = gql`
  ${UserFragment}

  mutation UserUpdateEmail($input: UserUpdateEmailInput!) {
    userUpdateEmail(input: $input) {
      user {
        ...UserFragment
      }
    }
  }
`;

export default UserUpdateEmail;
