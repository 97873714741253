import { OfferCandidatesFilters } from '@domain/graphql.types';
import { useOfferCandidates } from '@domain/offer';

import { toViewModel } from '../view-models/get-offer-candidates.vm';

const useGetOfferCandidates = (filterBy: OfferCandidatesFilters) => {
  const { data, fetching, error, reexecuteQuery } = useOfferCandidates({
    filterBy,
  });

  const viewModel = toViewModel({
    requests: {
      data: [...(data || [])],
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchOfferCandidates: reexecuteQuery,
  };
};

export default useGetOfferCandidates;
