import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateOfferAcceptArgs,
} from '@domain/graphql.types';
import { MutationCandidateOfferAccept } from '@infrastructure/graphql/candidate/offer';

const useCandidateOfferAccept = () => {
  const [{ data, fetching, error }, acceptCandidateOffer] = useMutation<
    Pick<Mutation, 'candidateOfferAccept'>,
    MutationCandidateOfferAcceptArgs
  >(MutationCandidateOfferAccept);

  return {
    acceptCandidateOffer,
    data,
    fetching,
    error,
  };
};

export default useCandidateOfferAccept;
