import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import {
  CandidateOffer,
  CandidateRequestOffersResponse,
} from '@domain/graphql.types';

type ToViewModel = {
  candidateOffers: {
    data: CandidateRequestOffersResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateOffers: { data, fetching, error },
}: ToViewModel): ViewModel<readonly CandidateOffer[] | undefined> =>
  new ViewModel<readonly CandidateOffer[] | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.candidateOffers || undefined,
  });
