import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Cluster,
  Fieldset,
  HelperText,
} from '@application/components';
import { ServiceType } from '@domain/graphql.types';
import { randomString } from '@utils/math-utils';

import { CreateAccountFormFields } from '../schema';

const AgencyServices = () => {
  const { t } = useTranslation('signup', {
    keyPrefix: 'agency.form',
  });

  const { t: tGlobal } = useTranslation();

  const helperTextId = useMemo(() => randomString(), []);

  const { control, setValue, trigger, watch } =
    useFormContext<CreateAccountFormFields>();

  const services = watch('services') || [];

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setValue('services', [...services, event.target.value as ServiceType]);
    } else {
      setValue('services', [
        ...services.filter((e) => e !== event.target.value),
      ]);
    }
    trigger('services');
  };

  return (
    <Controller
      name="services"
      control={control}
      render={({ field: { name, onBlur }, fieldState: { error } }) => (
        <Fieldset
          legend={t('services.heading')}
          legendSize="big"
          className="mt-s-16 min-w-0"
        >
          <Cluster space={16} as="ul">
            {Object.values(ServiceType).map((service) => (
              <li key={service}>
                <Checkbox
                  aria-describedby={helperTextId}
                  name={name}
                  onBlur={onBlur}
                  value={service}
                  label={tGlobal(
                    `enum.agencyServiceCode.${service.toLowerCase()}`
                  )}
                  onChange={handleOnChange}
                  checked={services?.includes(service)}
                  asButton
                />
              </li>
            ))}
          </Cluster>
          {error?.message && t(error?.message) && (
            <HelperText id={helperTextId} invalid={!!error}>
              {tGlobal(error.message)}
            </HelperText>
          )}
        </Fieldset>
      )}
    />
  );
};

export default AgencyServices;
