import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, Fieldset } from '@application/components';
import { Button } from '@application/components/buttons';
import { Box, Cluster } from '@application/components/container-layouts';
import { LoadingSpinner } from '@application/components/spinner';
import { AccountIndustryType } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import useGetIndustrySectors from '../actions/useGetIndustrySectors';
import SpecialtiesOnboardingContext from './context/SpecialtiesOnboardingContext';
import { SpecialtiesOnboardingFormFields } from './types';

type OnboardingSectorsProps = {
  getValues: UseFormGetValues<SpecialtiesOnboardingFormFields>;
  register: UseFormRegister<SpecialtiesOnboardingFormFields>;
  setValue: UseFormSetValue<SpecialtiesOnboardingFormFields>;
  watch: UseFormWatch<SpecialtiesOnboardingFormFields>;
};

const OnboardingSectors = ({
  getValues,
  register,
  setValue,
  watch,
}: OnboardingSectorsProps) => {
  const { t: tGlobal } = useTranslation();
  const { i18n, t } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities.onboarding',
  });

  const [showLoader, setShowLoader] = useState(false);

  const { previousStep, nextStep } = useContext(SpecialtiesOnboardingContext);

  const registerFields = register('sectors');
  const sectors = watch('sectors');
  const { industries = [] } = getValues();

  const { data: INDUSTRY_SECTORS, isLoading } = useGetIndustrySectors();

  const industrySectors = useMemo(
    () =>
      INDUSTRY_SECTORS.filter((s) => industries.includes(s.industry.code)).map(
        ({ descriptions, code, industry }) => ({
          industry: industry.code,
          industryLabel: industry.descriptions[extractLanguage(i18n.language)],
          value: code,
          label: descriptions[extractLanguage(i18n.language)],
        })
      ),
    [INDUSTRY_SECTORS, industries, i18n]
  );

  const buildSectorsSection = useCallback(
    (industry: AccountIndustryType) => {
      const filteredSectors = industrySectors.filter(
        (s) => s.industry === industry
      );

      const fieldsetLabel = filteredSectors[0]?.industryLabel || '';

      return (
        <Box key={industry} className="mb-s-24" padding={24}>
          <Fieldset legend={fieldsetLabel}>
            <Cluster space={16}>
              {filteredSectors.map(({ value, label }) => (
                <Checkbox
                  asButton
                  key={value}
                  value={value}
                  label={label}
                  {...registerFields}
                />
              ))}
            </Cluster>
          </Fieldset>
        </Box>
      );
    },
    [industrySectors, registerFields]
  );

  const selectDeselectAll = useCallback(() => {
    let sectorsToSelect: string[] = [];

    if (industrySectors.length !== sectors.length) {
      sectorsToSelect = industrySectors.map((i) => i.value);
    }

    setValue('sectors', sectorsToSelect);
  }, [industrySectors, sectors, setValue]);

  const goBack = useCallback(() => {
    setValue('sectors', []);
    previousStep();
  }, [setValue, previousStep]);

  const sectorsSections = useMemo(
    () => industries.map((industry) => buildSectorsSection(industry)),
    [industries, buildSectorsSection]
  );

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-s-16">
        <h2 className="h3">{t('sectors.title')}</h2>
        <span>{t('steps', { current: 2, total: 3 })}</span>
      </div>

      <div className="flex items-center justify-between mb-s-24">
        <span>{t('sectors.instructions')}</span>

        <Button
          className="bg-transparent underline"
          ghost
          onClick={selectDeselectAll}
        >
          <span>
            {tGlobal(
              industrySectors.length === sectors.length
                ? 'button.deselect'
                : 'button.selectAll'
            )}
          </span>
        </Button>
      </div>

      {showLoader && isLoading && (
        <div className="h-s-384 w-full flex justify-center items-center">
          <LoadingSpinner size="lg" />
        </div>
      )}

      {!isLoading && sectorsSections}

      {!isLoading && (
        <div className="flex justify-end gap-s-16">
          <Button size="large" onClick={goBack}>
            <span>{tGlobal('button.back')}</span>
          </Button>

          <Button
            size="large"
            primary
            disabled={sectors.length === 0}
            onClick={nextStep}
          >
            <span>{tGlobal('button.continue')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default OnboardingSectors;
