import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLoader } from '@application/components/page';
import { PublicPage } from '@application/enums/pagesUrl';
import { useLogout } from '@domain/authentication';

type AuthGuardProps = {
  children: React.ReactNode;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
  useLogout();

  const { isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate(PublicPage.HOME, { replace: true });
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return <PageLoader />;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default AuthGuard;
