import { UseQueryExecute } from 'urql';

import {
  RecruitmentNegotiationRoomMessage,
  RecruitmentNegotiationRoomMessageInput,
} from '@domain/graphql.types';

import useGetRecruitmentNegotiationRoomMessages from './useGetRecruitmentNegotiationRoomMessages';

type UseRecruitmentNegotiationRoomMessagessListProps = {
  count?: number;
  recruitmentNegotiationRoomMessages: RecruitmentNegotiationRoomMessage[];
  isLoading: boolean;
  refetchRecruitmentNegotiationRoomMessages: UseQueryExecute;
};

const useRecruitmentNegotiationRoomMessagesList = (
  input: RecruitmentNegotiationRoomMessageInput
): UseRecruitmentNegotiationRoomMessagessListProps => {
  const {
    viewModel: { data, isLoading },
    refetchRecruitmentNegotiationRoomMessages,
  } = useGetRecruitmentNegotiationRoomMessages(input);

  return {
    count: data?.recruitmentNegotiationRoomMessages.totalCount,
    recruitmentNegotiationRoomMessages:
      data?.recruitmentNegotiationRoomMessages.results.slice() || [],
    isLoading,
    refetchRecruitmentNegotiationRoomMessages,
  };
};

export default useRecruitmentNegotiationRoomMessagesList;
