import { mapCodeDescriptions, ValueText } from '@application/utils';
import {
  AccountAdvantage,
  AccountAdvantageTypeCode,
  AccountAdvantageTypesResponse,
  AccountSize,
  AccountSizesResponse,
  AccountUpdateInput,
  IndustryType,
  IndustryTypesArrayResponse,
  OperationTerritoriesResponse,
  OperationTerritory,
  Query,
} from '@domain/graphql.types';

import { OrganizationProfileFormFields } from './types';

export const normalizeUpdateInputData = (
  data: OrganizationProfileFormFields
): AccountUpdateInput => ({
  name: data?.name,
  website: data?.website,
  address: data?.address,
  phoneNumber: data?.phoneNumber,
  phoneDisplay: data?.phoneDisplay,
  industryTypeCode: data?.industryTypeCode,
  sectorCode: data?.sectorCode,
  sizeTypeCode: data?.sizeTypeCode,
  employerOfferDescription: data?.employerOfferDescription,
  facebookUrl: data?.facebookUrl,
  instagramUrl: data?.instagramUrl,
  linkedinUrl: data?.linkedinUrl,
  twitterUrl: data?.twitterUrl,
  advantageCodes: data?.advantageCodes as AccountAdvantageTypeCode[],
});

export const normalizeGetIndustryTypesData = ({
  industryTypes: { values = [] } = {} as IndustryTypesArrayResponse,
}: Pick<Query, 'industryTypes'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as IndustryType[]);

export const normalizeGetAccountSizesData = ({
  accountSizes: { values = [] } = {} as AccountSizesResponse,
}: Pick<Query, 'accountSizes'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as AccountSize[]);

export const normalizeGetOperationTerritoriesData = ({
  operationTerritories: { values = [] } = {} as OperationTerritoriesResponse,
}: Pick<Query, 'operationTerritories'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as OperationTerritory[]);

export const normalizeGetPerksData = ({
  accountAdvantageTypes: { values = [] } = {} as AccountAdvantageTypesResponse,
}: Pick<Query, 'accountAdvantageTypes'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as AccountAdvantage[]);
