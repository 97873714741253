import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  SectionGrid,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';

import { OfferFormFields } from '../../types';

type HeadHunterFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const HeadHunterFields = ({
  recruitmentOpportunity,
}: HeadHunterFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    formState: { errors },
    register,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  return (
    <SectionGrid
      title={t('subtitles.forHeadHunting')}
      className="mt-s-40"
      isOffer
    >
      <SectionGridRow
        isTopRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              recruitmentOpportunity?.headhunterContractualConditions
                ?.maximumReplacementValue ? (
                <span className="text-16 font-semibold text-primary">
                  {
                    recruitmentOpportunity.headhunterContractualConditions
                      .maximumReplacementValue
                  }
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!recruitmentOpportunity?.headhunterContractualConditions
                ?.maximumReplacementDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.maxReplacements')}
          type="number"
          minValue={1}
          noMargin
          invalid={!!errors.headhunterConditionMaximumReplacement}
          helperText={
            errors.headhunterConditionMaximumReplacement?.message &&
            tGlobal(errors.headhunterConditionMaximumReplacement?.message, {
              min: '1',
            })
          }
          {...register('headhunterConditionMaximumReplacement')}
        />
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              recruitmentOpportunity?.headhunterContractualConditions
                ?.headhuntingExclusivityPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.week', {
                    count:
                      recruitmentOpportunity.headhunterContractualConditions
                        .headhuntingExclusivityPeriodValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!recruitmentOpportunity?.headhunterContractualConditions
                ?.headhuntingExclusivityPeriodDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.exclusivityPeriod')}
          suffix={t('suffix.weeks')}
          type="number"
          mediumSuffix
          noMargin
          minValue={1}
          invalid={!!errors.headhunterConditionExclusivityPeriod}
          helperText={
            errors.headhunterConditionExclusivityPeriod?.message &&
            tGlobal(errors.headhunterConditionExclusivityPeriod?.message, {
              min: '1',
            })
          }
          {...register('headhunterConditionExclusivityPeriod')}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default HeadHunterFields;
