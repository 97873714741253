import * as yup from 'yup';
import 'yup-phone-lite';

import { Languages } from '@application/enums/languages';
import { URL_REGEX } from '@utils/yup-utils';

export const USER_PROFILE_SCHEMA = yup.object({
  id: yup.string(),
  defaultAccountId: yup.string(),
  bio: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(1024, 'validations.maxLength'),
  facebookUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  firstName: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength'),
  instagramUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  jobDescription: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength'),
  lastName: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength'),
  linkedinUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  location: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(256, 'validations.maxLength'),
  phoneNumber: yup
    .string()
    .nullable()
    .phone('CA', 'validations.phone')
    .max(36, 'validations.maxLength'),
  phoneDisplay: yup.boolean().nullable(),
  professionalEmail: yup
    .string()
    .transform((_, val) => val || null)
    .max(128, 'validations.maxLength')
    .email('validations.emailValid')
    .required('validations.required.email'),
  spokenLanguages: yup
    .array(yup.mixed<Languages>().oneOf(Object.values(Languages)))
    .nullable(),
  twitterUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  why: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(1024, 'validations.maxLength'),
});
