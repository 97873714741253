import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { IconButton, LanguageButtons } from '@application/components';
import { OnboardingPage } from '@application/enums/pagesUrl';

import { Container } from './components';

const JoinCompanyPage = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('signup', { keyPrefix: 'joinCompany' });

  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end m-s-32">
        <IconButton
          text={tGlobal('backPreviousPage')}
          onClick={() => navigate(OnboardingPage.ACCOUNT_TYPE)}
          icon={<i className="ri-arrow-left-line" />}
        />
        <LanguageButtons />
      </div>

      <Container>
        <h1 className="mb-s-32">{t('heading')}</h1>
        <div className="flex flex-col mb-s-64">
          <p>{t('description')}</p>
        </div>
      </Container>
    </div>
  );
};

export default JoinCompanyPage;
