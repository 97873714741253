import { useTranslation } from 'react-i18next';

import { SectionGridRow, VisibilitySwitcher } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import {
  CandidateOffer,
  CandidateOpportunity,
  CandidateRequest,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type TemporaryPlacementConditionsProps = {
  offer?: CandidateOffer;
  request?: CandidateRequest | CandidateOpportunity;
};

const TemporaryPlacementConditions = ({
  offer,
  request,
}: TemporaryPlacementConditionsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const requestChildrenClassName = 'text-16 font-semibold text-primary';
  const offerWrapperClassName = 'flex flex-wrap gap-x-s-16 items-center';
  const offerLabelClassName = 'text-16 text-primary';
  const offerValueClassName = 'text-18 font-semibold text-primary';

  const conditions = offer?.temporaryPlacementConditions;
  const temporaryConditions = request?.temporaryPlacementConditions;

  return (
    <>
      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={temporaryConditions?.probationPeriodValue}
              >
                <span className={requestChildrenClassName}>
                  {t('suffix.week', {
                    count: Number(temporaryConditions?.probationPeriodValue),
                  })}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!temporaryConditions?.probationPeriodValue}
          />
        }
      >
        <span className={offerWrapperClassName}>
          <span className={offerLabelClassName}>
            {t('labels.probationPeriod')}
          </span>

          <PlaceholderSwitcher value={conditions?.probationPeriod}>
            <span className={offerValueClassName}>
              {t('suffix.week', { count: Number(conditions?.probationPeriod) })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={temporaryConditions?.minimumNoticeValue}
              >
                <span className={requestChildrenClassName}>
                  {t('suffix.week', {
                    count: Number(temporaryConditions?.minimumNoticeValue),
                  })}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!temporaryConditions?.minimumNoticeDisplay}
          />
        }
      >
        <span className={offerWrapperClassName}>
          <span className={offerLabelClassName}>
            {t('labels.minimumNoticePeriod')}
          </span>

          <PlaceholderSwitcher value={conditions?.minimumNotice}>
            <span className={offerValueClassName}>
              {t('suffix.week', { count: Number(conditions?.minimumNotice) })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={temporaryConditions?.possibleBuybackValue}
              >
                <div
                  className={cn(
                    requestChildrenClassName,
                    'flex flex-col gap-s-16'
                  )}
                >
                  <p>
                    {tGlobal(
                      `boolean.${temporaryConditions?.possibleBuybackValue}`
                    )}
                  </p>

                  {temporaryConditions?.possibleBuybackBonusValue &&
                    temporaryConditions.billingPeriodDisplay && (
                      <p>
                        {t('suffix.currency', {
                          value: temporaryConditions.possibleBuybackBonusValue,
                        })}
                      </p>
                    )}
                </div>
              </PlaceholderSwitcher>
            }
            visible={!!temporaryConditions?.possibleBuybackDisplay}
          />
        }
      >
        <span className={cn(offerWrapperClassName, 'gap-y-s-16')}>
          <span className={offerLabelClassName}>{t('labels.buyback')}</span>

          <PlaceholderSwitcher value={conditions?.possibleBuyback}>
            <>
              <span className={offerValueClassName}>
                {tGlobal(`boolean.${conditions?.possibleBuyback}`)}
              </span>

              {conditions?.possibleBuybackBonus && (
                <div className={cn(offerWrapperClassName, 'w-full')}>
                  <span className={offerLabelClassName}>
                    {t('labels.possibleBuybackBonus')}
                  </span>

                  <span className={offerValueClassName}>
                    {t('suffix.currency', {
                      value: conditions?.possibleBuybackBonus,
                    })}
                  </span>
                </div>
              )}
            </>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <div className="flex flex-col gap-s-16">
            <PlaceholderSwitcher
              value={temporaryConditions?.travelExpensesResponsibility}
            >
              <span className="font-semibold text-16">
                {t(
                  `enum.candidateConditionResponsibility.${temporaryConditions?.travelExpensesResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>

            <PlaceholderSwitcher
              value={temporaryConditions?.trainingExpensesResponsibility}
            >
              <span className="font-semibold text-16">
                {t(
                  `enum.candidateConditionResponsibility.${temporaryConditions?.trainingExpensesResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>

            <PlaceholderSwitcher
              value={temporaryConditions?.employeeHelpProgramResponsibility}
            >
              <span className="font-semibold text-16">
                {t(
                  `enum.candidateConditionResponsibility.${temporaryConditions?.employeeHelpProgramResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>

            <PlaceholderSwitcher
              value={temporaryConditions?.cnesstResponsibility}
            >
              <span className="font-semibold text-16">
                {t(
                  `enum.candidateConditionResponsibility.${temporaryConditions?.cnesstResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>
          </div>
        }
      >
        <div className="flex flex-col gap-s-16">
          <p>{t('labels.responsibilities')}</p>

          <div className="flex items-center">
            <i className="ri-circle-fill text-6 m-s-8" />
            <span className="mr-s-16">{t('labels.responsibilityTravel')}</span>
            <PlaceholderSwitcher
              value={conditions?.travelExpensesResponsibility}
            >
              <span className="font-semibold text-18">
                {t(
                  `enum.candidateConditionResponsibility.${conditions?.travelExpensesResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>
          </div>

          <div className="flex items-center">
            <i className="ri-circle-fill text-6 m-s-8" />
            <span className="mr-s-16">
              {t('labels.responsibilityTraining')}
            </span>
            <PlaceholderSwitcher
              value={conditions?.trainingExpensesResponsibility}
            >
              <span className="font-semibold text-18">
                {t(
                  `enum.candidateConditionResponsibility.${conditions?.trainingExpensesResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>
          </div>

          <div className="flex items-center">
            <i className="ri-circle-fill text-6 m-s-8" />
            <span className="mr-s-16">{t('labels.responsibilityProgram')}</span>
            <PlaceholderSwitcher
              value={conditions?.employeeHelpProgramResponsibility}
            >
              <span className="font-semibold text-18">
                {t(
                  `enum.candidateConditionResponsibility.${conditions?.employeeHelpProgramResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>
          </div>

          <div className="flex items-center">
            <i className="ri-circle-fill text-6 m-s-8" />
            <span className="mr-s-16">{t('labels.responsibilityCnesst')}</span>
            <PlaceholderSwitcher value={conditions?.cnesstResponsibility}>
              <span className="font-semibold text-18">
                {t(
                  `enum.candidateConditionResponsibility.${conditions?.cnesstResponsibility?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>
          </div>

          <div className="flex items-center">
            <i className="ri-circle-fill text-6 m-s-8" />
            <span className="mr-s-16">{t('labels.others')}</span>
            <PlaceholderSwitcher value={conditions?.otherResponsibilities}>
              <span className="font-semibold text-18">
                {conditions?.otherResponsibilities}
              </span>
            </PlaceholderSwitcher>
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={temporaryConditions?.billingPeriodValue}
              >
                <span className={requestChildrenClassName}>
                  {t(
                    `enum.candidateConditionBillingPeriod.${temporaryConditions?.billingPeriodValue?.toLowerCase()}`
                  )}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!temporaryConditions?.billingPeriodDisplay}
          />
        }
      >
        <span className={offerWrapperClassName}>
          <span className={offerLabelClassName}>
            {t('labels.billingPeriod')}
          </span>

          <PlaceholderSwitcher value={conditions?.billingPeriod}>
            <span className={offerValueClassName}>
              {t(
                `enum.candidateConditionBillingPeriod.${conditions?.billingPeriod?.toLowerCase()}`
              )}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>
    </>
  );
};

export default TemporaryPlacementConditions;
