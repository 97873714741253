import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import {
  Offer,
  OfferInternationalRecruitmentConditions,
  Request,
  RequestInternationalRecruitmentConditions,
} from '@domain/graphql.types';

type InternationalRecruitmentConditionsProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const INTERNATIONAL_RECRUITMENT_CONDITIONS = [
  {
    key: 'exclusivityPeriod',
    label: 'foreignCandidatesExclusivityPeriod',
    valueType: 'weeks',
  },
  {
    key: 'mandatoryWorkPermit',
    label: 'validWorkPermit',
    valueType: 'boolean',
  },
  {
    key: 'maximumReplacement',
    label: 'maxReplacements',
    valueType: 'number',
  },
];

const InternationalRecruitmentConditions = ({
  data,
  request,
}: InternationalRecruitmentConditionsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const getContentValue = useCallback(
    (type: string, value: string | number | boolean | null | undefined) => {
      switch (type) {
        case 'number':
          return value || '-';
        case 'weeks':
          return value ? t('suffix.week', { count: Number(value) }) : '-';
        case 'boolean':
          return value
            ? tGlobal(`boolean.${value?.toString().toLowerCase() as string}`)
            : '-';
        default:
          return '-';
      }
    },
    [t, tGlobal]
  );

  return (
    <>
      {INTERNATIONAL_RECRUITMENT_CONDITIONS.map(
        (
          condition: { key: string; label: string; valueType: string },
          index
        ) => (
          <SectionGridRow
            key={condition.key}
            isTopRow={index === 0}
            isOffer
            rightChildren={
              <PlaceholderSwitcher
                value={
                  request?.internationalRecruitmentContractualConditions?.[
                    `${condition.key}Value` as keyof RequestInternationalRecruitmentConditions
                  ]
                }
              >
                <span className="text-16 font-semibold">
                  {getContentValue(
                    condition.valueType,
                    request?.internationalRecruitmentContractualConditions?.[
                      `${condition.key}Value` as keyof RequestInternationalRecruitmentConditions
                    ]
                  )}
                </span>
              </PlaceholderSwitcher>
            }
          >
            <span className="mr-s-8">{t(`labels.${condition.label}`)}</span>

            <PlaceholderSwitcher
              value={
                data?.internationalRecruitmentContractualConditions?.[
                  condition.key as keyof OfferInternationalRecruitmentConditions
                ]
              }
            >
              <span className="text-18 font-semibold">
                {getContentValue(
                  condition.valueType,
                  data?.internationalRecruitmentContractualConditions?.[
                    condition.key as keyof OfferInternationalRecruitmentConditions
                  ]
                )}
              </span>
            </PlaceholderSwitcher>
          </SectionGridRow>
        )
      )}
    </>
  );
};

export default InternationalRecruitmentConditions;
