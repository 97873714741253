import { useFloating } from '@floating-ui/react';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { TableHorizontalScrollDivRefContext } from '@application/components/table/Table';
import { useClickOutside } from '@application/hooks';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

type ActionProps = {
  label: string;
  icon: ReactNode;
  onClick: () => void;
};

type StatesMenuProps = {
  children: ReactNode;
  onAccept: () => void;
  onReject: () => void;
};

const StatesMenu = ({ children, onAccept, onReject }: StatesMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [width, setWidth] = useState(0);

  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.actions',
  });

  const tableHorizontalScrollDivRef = useContext(
    TableHorizontalScrollDivRefContext
  );
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
  });

  const menuListId = useMemo(() => `menu-list-${randomString()}`, []);

  const actions: ActionProps[] = useMemo(
    () => [
      {
        label: t('accept'),
        icon: <i className="ri-thumb-up-line text-18 text-badge-green" />,
        onClick: onAccept,
      },
      {
        label: t('reject'),
        icon: <i className="ri-thumb-down-line text-18 text-badge-red" />,
        onClick: onReject,
      },
    ],
    [onAccept, onReject, t]
  );

  useClickOutside({
    refs: [refs.reference, refs.floating],
    onOutsideClick: () => setMenuOpen(false),
  });

  const handleResize = useCallback(() => {
    if (refs.reference.current) {
      const rect = refs.reference.current.getBoundingClientRect();

      setMenuOpen(false);
      setWidth(rect.width - 4);
    }
  }, [refs.reference]);

  /* Resize the floating element depending on the width of the reference element */
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, refs.reference, tableHorizontalScrollDivRef]);

  /** Detect when reference element dimensions changed 
      and resize the floating element accordingly */
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserver.observe(refs.reference.current as Element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize, refs.reference]);

  return (
    <>
      <button
        type="button"
        ref={refs.setReference}
        aria-expanded={menuOpen}
        aria-controls={menuListId}
        className="appearance-none flex items-center justify-between w-full h-full px-s-16 py-s-8"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {children}

        <i
          className={cn(
            'ri-arrow-down-s-fill text-22 rotate-0 transition-transform duration-200',
            menuOpen && 'rotate-180'
          )}
        />
      </button>

      {menuOpen && (
        <ul
          id={menuListId}
          ref={refs.setFloating}
          className={cn(
            'menu menu-vertical z-dropdown mt-s-2 p-s-4 bg-base-100 border border-stroke-default rounded-md shadow-sm [&>li+li]:mt-s-4'
          )}
          style={{
            ...floatingStyles,
            width: `${width}px`,
          }}
        >
          {actions.map((action: ActionProps) => (
            <li key={action.label}>
              <Button
                className={cn('justify-start p-s-8 [&&]:focus:bg-neutral')}
                ghost
                onClick={() => action.onClick()}
              >
                <span>{action.icon}</span>
                <span className="text-start font-normal text-neutral-secondary">
                  {action.label}
                </span>
              </Button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default StatesMenu;
