import { ElementType, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { cn } from '@utils/lib-utils';

import { IconButton } from '../buttons';

type PageHeaderProps = {
  /**
   * Specify the main title of the page header
   */
  title: ReactNode;
  /**
   * Specify the HTML tag to apply to the title
   */
  titleAs?: ElementType;
  /**
   * Specify the subtitle of the page header
   */
  subtitle?: ReactNode;
  /**
   * Specify the HTML tag to apply to the subtitle
   */
  subtitleAs?: ElementType;
  /**
   * Specify extra nodes to add as a complement of the title and subtitle
   */
  complement?: ReactNode;
  /**
   * Provide a custom CSS class names to be applied to the parent container
   */
  className?: string;
  /**
   * Specify the child nodes
   */
  children?: ReactNode;
  /**
   * Specify whether the header is centered to the middle of the page
   */
  centered?: boolean;
  /**
   * Specify the text of the back button
   */
  backButtonText?: string;
  /**
   * Specify to navigate to on back click
   */
  backTo: string;
};

const PageHeader = ({
  title,
  titleAs: TitleTag = 'p',
  subtitle,
  subtitleAs: SubtitleTag = 'span',
  complement,
  className: customClassName,
  children,
  centered = false,
  backTo,
  backButtonText,
}: PageHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const redirectTo = location.state?.from;

  // TODO : validate that this is ok.
  const onBackClick = useCallback(
    () => navigate(redirectTo || backTo),
    [backTo, navigate, redirectTo]
  );

  const className = cn(
    'w-full mb-s-32',
    {
      'max-w-[var(--max-container-width)] mx-auto': centered,
    },
    customClassName
  );

  return (
    <header className={className}>
      <div className="flex flex-wrap items-center justify-between gap-s-16">
        <div className="flex flex-wrap items-center gap-s-16">
          <IconButton
            type="button"
            icon={<i className="ri-arrow-left-line" />}
            text={backButtonText || t('button.backPreviousPage')}
            onClick={onBackClick}
          />

          <TitleTag className="h3">{title}</TitleTag>

          {subtitle && (
            <SubtitleTag className="text-neutral-secondary">
              {subtitle}
            </SubtitleTag>
          )}

          {complement && <div>{complement}</div>}
        </div>

        <div>{children}</div>
      </div>
    </header>
  );
};

export default PageHeader;
