import { useMutation } from 'urql';

import {
  Mutation,
  MutationAccountOperationTerritoryDeleteArgs,
} from '@domain/graphql.types';
import { AccountOperationTerritoryDelete } from '@infrastructure/graphql/account';

const useAccountOperationTerritoryDelete = () => {
  const [{ data, fetching, error }, deleteAccountOperationTerritory] =
    useMutation<
      Pick<Mutation, 'accountOperationTerritoryDelete'>,
      MutationAccountOperationTerritoryDeleteArgs
    >(AccountOperationTerritoryDelete);

  return {
    deleteAccountOperationTerritory,
    data,
    fetching,
    error,
  };
};

export default useAccountOperationTerritoryDelete;
