import { gql } from 'urql';

const AccountOperationTerritoryDelete = gql`
  mutation AccountOperationTerritoryDelete(
    $input: DeleteAccountOperationTerritoryInput!
  ) {
    accountOperationTerritoryDelete(input: $input) {
      ok
    }
  }
`;

export default AccountOperationTerritoryDelete;
