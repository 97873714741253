import { useValidateIdParam } from '@application/hooks';
import { useCandidateRequest } from '@domain/candidate';

import { toViewModel } from '../view-models/get-candidate-request.vm';

const useGetCandidateRequest = (candidateRequestId: string) => {
  useValidateIdParam(candidateRequestId);

  const { data, fetching, error, reexecuteQuery } = useCandidateRequest({
    input: { candidateRequestId },
  });

  const viewModel = toViewModel({
    candidateRequest: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidate: reexecuteQuery,
  };
};

export default useGetCandidateRequest;
