import { CombinedError } from 'urql';

import { extractErrorCodes, ValueText } from '@application/utils';
import ViewModel from '@application/view-model';
import { Query } from '@domain/graphql.types';

import { normalizeGetAccountSizesData } from '../normalizers';

type ToViewModel = {
  accountSizes: {
    data: Pick<Query, 'accountSizes'> | Record<string, never> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  accountSizes: { data = {}, fetching, error },
}: ToViewModel): ViewModel<ValueText[]> =>
  new ViewModel<ValueText[]>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: normalizeGetAccountSizesData(data),
  });
