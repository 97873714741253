import { createContext, SetStateAction } from 'react';

import {
  CandidateNegotiationPropertyState,
  CandidateNegotiationRoom,
  CandidateNegotiationRoomStatus,
  CandidateNegotiationStateUpdateInput,
  CandidateRequest,
} from '@domain/graphql.types';

type CandidateNegotiationContextProps = {
  candidateRequest: CandidateRequest | undefined;
  rooms: readonly CandidateNegotiationRoom[] | undefined;
  negotiationId: string;
  checkedRooms: string[];
  isComparing: boolean;
  isLoading: boolean;
  shouldDisplayMenu: (
    roomStatus: CandidateNegotiationRoomStatus,
    propertyState: CandidateNegotiationPropertyState
  ) => boolean;
  setIsComparing: (state: SetStateAction<boolean>) => void;
  setCheckedRooms: (state: SetStateAction<string[]>) => void;
  getModifiedProperties: <T extends object>(propertiesState: T) => string[];
  onAcceptValues: <T extends object>(
    id: string,
    key: keyof CandidateNegotiationStateUpdateInput,
    properties: (keyof T)[]
  ) => () => void;
  onRejectValues: <T extends object>(
    id: string,
    key: keyof CandidateNegotiationStateUpdateInput,
    properties: (keyof T)[]
  ) => () => void;
  refreshCandidateNegotiation: () => void;
};

const CandidateNegotiationContext =
  createContext<CandidateNegotiationContextProps>({
    candidateRequest: undefined,
    negotiationId: '',
    rooms: [],
    checkedRooms: [],
    isComparing: false,
    isLoading: false,
    shouldDisplayMenu: () => false,
    setIsComparing: () => {},
    setCheckedRooms: () => {},
    getModifiedProperties: () => [],
    onAcceptValues: () => () => {},
    onRejectValues: () => () => {},
    refreshCandidateNegotiation: () => {},
  });

export default CandidateNegotiationContext;
