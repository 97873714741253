import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useOfferCandidateUpdateCandidateAndCV } from '@domain/offer';

import { toViewModel } from '../view-models/update-candidate-and-others.vm';

const useUpdateCandidateAndCV = () => {
  const { data, fetching, error, updateCandidateAndCv } =
    useOfferCandidateUpdateCandidateAndCV();

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    offerCandidate: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('updateOfferCandidateError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.offerCandidateUpdate?.offerCandidate) {
      setAlertContent({
        action: t('updateOfferCandidateSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.offerCandidateUpdate?.offerCandidate]);

  return { updateCandidateAndCv, viewModel };
};

export default useUpdateCandidateAndCV;
