import { useMutation } from 'urql';

import {
  Mutation,
  MutationAccountOperationTerritoryUpdateArgs,
} from '@domain/graphql.types';
import { AccountOperationTerritoryUpdate } from '@infrastructure/graphql/account';

const useAccountOperationTerritoryUpdate = () => {
  const [{ data, fetching, error }, updateAccountOperationTerritory] =
    useMutation<
      Pick<Mutation, 'accountOperationTerritoryUpdate'>,
      MutationAccountOperationTerritoryUpdateArgs
    >(AccountOperationTerritoryUpdate);

  return {
    updateAccountOperationTerritory,
    data,
    fetching,
    error,
  };
};

export default useAccountOperationTerritoryUpdate;
