import * as yup from 'yup';

import {
  AccountAdvantageTypeCode,
  CandidateConditionBillingPeriod,
  CandidateConditionResponsibility,
  CandidateOfferBonus,
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
  JobScheduleTypeCode,
  OfferConditionPaymentFrequencyCode,
  OfferConditionPaymentMethodCode,
  OfferConditionStartOfAgreementPaymentMethodCode,
  OperationTerritoryCode,
  Season,
} from '@domain/graphql.types';
import { validateDecimal } from '@utils/yup-utils';

export const CANDIDATE_OFFER_SCHEMA = yup.object({
  publishNow: yup.boolean(),
  candidateRequestId: yup.string(),
  operationUnitId: yup.string().nullable(),
  operationTerritoryCodes: yup.array(
    yup
      .mixed<OperationTerritoryCode>()
      .oneOf(Object.values(OperationTerritoryCode))
  ),
  jobType: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .nullable(),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  hoursPerWeek: yup.array(yup.string()).nullable(),
  seasons: yup
    .array(yup.mixed<Season>().oneOf(Object.values(Season)))
    .nullable(),
  fullTimeAvailability: yup.boolean().nullable(),
  partTimeAvailability: yup.boolean().nullable(),
  schedules: yup
    .array(
      yup.mixed<JobScheduleTypeCode>().oneOf(Object.values(JobScheduleTypeCode))
    )
    .nullable(),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .nullable(),
  remoteDaysAllowed: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber'),
  paymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .nullable(),
  annualSalaryMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  annualSalaryMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  hourlyRateMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyRateMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  remunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  otherPaymentTypeCode: yup
    .mixed<JobPaymentOtherTypesCode>()
    .oneOf(Object.values(JobPaymentOtherTypesCode))
    .nullable(),
  otherPaymentAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  perks: yup
    .array(
      yup
        .mixed<AccountAdvantageTypeCode>()
        .oneOf(Object.values(AccountAdvantageTypeCode))
    )
    .nullable(),
  candidateBonus: yup
    .mixed<CandidateOfferBonus>()
    .oneOf(Object.values(CandidateOfferBonus))
    .nullable(),
  candidateBonusAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  linkProfile: yup.boolean().nullable(),
  salesPitch: yup.string().nullable().max(1024, 'validations.maxLength'),

  /* RECRUITMENT CONDITIONS */
  recruitmentConditionBudget: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  recruitmentConditionPaymentMethod: yup
    .mixed<OfferConditionPaymentMethodCode>()
    .oneOf(Object.values(OfferConditionPaymentMethodCode))
    .nullable(),
  recruitmentConditionStartOfAgreementPaymentMethod: yup
    .mixed<OfferConditionStartOfAgreementPaymentMethodCode>()
    .oneOf(Object.values(OfferConditionStartOfAgreementPaymentMethodCode))
    .nullable(),
  recruitmentConditionPaymentAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  recruitmentConditionDeposit: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  recruitmentConditionPaymentFrequency: yup
    .mixed<OfferConditionPaymentFrequencyCode>()
    .oneOf(Object.values(OfferConditionPaymentFrequencyCode))
    .nullable(),
  recruitmentConditionGuaranteedPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  recruitmentConditionProbationPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  recruitmentConditionBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionProbationPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  temporaryPlacementConditionMinimumNotice: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  temporaryPlacementConditionPossibleBuyback: yup.boolean().nullable(),
  temporaryPlacementConditionPossibleBuybackBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  temporaryPlacementConditionTravelExpensesResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionTrainingExpensesResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionEmployeeHelpProgramResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionCnesstResponsibility: yup
    .mixed<CandidateConditionResponsibility>()
    .oneOf(Object.values(CandidateConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionOtherResponsibilities: yup
    .string()
    .nullable()
    .max(1024, 'validations.maxLength'),
  temporaryPlacementConditionBillingPeriod: yup
    .mixed<CandidateConditionBillingPeriod>()
    .oneOf(Object.values(CandidateConditionBillingPeriod))
    .nullable(),

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMinimumNotice: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable(),
  outsourcingConditionBillingPeriod: yup
    .mixed<CandidateConditionBillingPeriod>()
    .oneOf(Object.values(CandidateConditionBillingPeriod))
    .nullable(),
});

export const CANDIDATE_OFFER_PUBLISH_SCHEMA = CANDIDATE_OFFER_SCHEMA.shape({
  operationUnitId: yup
    .string()
    .required('validations.required.operationUnitId'),
  operationTerritoryCodes: yup
    .array(
      yup
        .mixed<OperationTerritoryCode>()
        .oneOf(Object.values(OperationTerritoryCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.operationTerritoryCodes',
      (value) => Array.isArray(value) && value.length > 0
    ),
  jobType: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .required('validations.required.jobType'),
  hoursPerWeek: yup
    .array(yup.string())
    .test(
      'notEmptyArray',
      'validations.required.hoursPerWeek',
      (value) => Array.isArray(value) && value.length > 0
    ),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .required('validations.required.jobModeCode'),
  paymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .required('validations.required.paymentFrequencyCode'),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when('jobType', ([jobType], schema) =>
      !!jobType &&
      (jobType === JobDurationCode.Contract ||
        jobType === JobDurationCode.Freelance ||
        jobType === JobDurationCode.Internship)
        ? schema.required('validations.required.jobDurationInMonths')
        : schema.nullable()
    ),
  remoteDaysAllowed: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber')
    .when('jobModeCode', ([jobModeCode], schema) =>
      jobModeCode === JobModeCode.Hybrid
        ? schema.required('validations.required.jobRemoteModeInDays')
        : schema.nullable()
    ),
  remunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('paymentFrequencyCode', ([paymentFrequencyCode], schema) =>
      paymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount
        ? schema.required('validations.required.jobRemunerationAmount')
        : schema.nullable()
    ),
  otherPaymentAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('otherPaymentTypeCode', ([otherPaymentTypeCode], schema) =>
      otherPaymentTypeCode
        ? schema.required('validations.required.otherPaymentAmount')
        : schema.nullable()
    ),
  candidateBonusAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('candidateBonus', ([candidateBonus], schema) =>
      candidateBonus
        ? schema.required('validations.required.candidateBonusAmount')
        : schema.nullable()
    ),
});

export type CandidateOfferFormFields = yup.InferType<
  typeof CANDIDATE_OFFER_SCHEMA
>;
