import { createContext } from 'react';

type InvitationsListContextProps = {
  shouldRefreshList: boolean;
  setShouldRefreshList: (state: boolean) => void;
};

const InvitationsListContext = createContext<InvitationsListContextProps>({
  shouldRefreshList: false,
  setShouldRefreshList: () => {},
});

export default InvitationsListContext;
