import { useTranslation } from 'react-i18next';

import { Offer } from '@domain/graphql.types';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type InitialOfferTableProps = {
  offer: Offer;
};

const InitialOfferTable = ({ offer }: InitialOfferTableProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  return (
    <table className="bg-neutral">
      <caption className="sr-only">{t('initialOffer')}</caption>
      <TableHeader />
      <TableBody offer={offer} />
    </table>
  );
};

export default InitialOfferTable;
