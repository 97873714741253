import { useParams } from 'react-router';

import { AgreementLayout, PageLoader } from '@application/components';

import { useGetCandidateNegotiationRoomAgreement } from '../hooks';

const CandidateNegotiationAgreementPage = () => {
  const { id = '' } = useParams();

  const {
    viewModel: { data, isLoading },
  } = useGetCandidateNegotiationRoomAgreement(id);

  return (
    <>
      {isLoading && !data && <PageLoader />}

      {data && (
        <AgreementLayout
          accountName={data?.candidateRequestAccountName}
          emailTo={data?.candidateRequestUserEmail}
        />
      )}
    </>
  );
};

export default CandidateNegotiationAgreementPage;
