import { gql } from '@urql/core';

import { RecruitmentOpportunityFragment } from './fragment';

const RecruitmentOpportunity = gql`
  ${RecruitmentOpportunityFragment}

  query RecruitmentOpportunity($input: RecruitmentOpportunityInput!) {
    recruitmentOpportunity(input: $input) {
      opportunity {
        ...RecruitmentOpportunityFragment
      }
    }
  }
`;

export default RecruitmentOpportunity;
