/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useTranslation } from 'react-i18next';

import { CandidateRequest } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../constants';
import { EmployerBrand, OfferToRecruiter } from './rows';

type TableBodyProps = {
  candidateRequest: CandidateRequest;
};

const TableBody = ({ candidateRequest }: TableBodyProps) => {
  const { t } = useTranslation('candidates');

  return (
    <tbody>
      <tr>
        <td className="sr-only">&nbsp;</td>
        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'h-[7rem] border-l-0 text-center'
          )}
        >
          {t('labels.initialRequestProposition')}
        </td>
      </tr>

      <EmployerBrand data={candidateRequest} />
      <OfferToRecruiter data={candidateRequest} />
    </tbody>
  );
};

export default TableBody;
