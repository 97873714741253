import { useCallback, useContext, useMemo } from 'react';

import { AccountContext } from '@application/context';
import { Languages } from '@application/enums/languages';
import { mapOptions } from '@application/utils';
import {
  useGetIndustrySectors,
  useGetJobSpecialties,
} from '@application/views/organization';
import { useGetIndustryTypes } from '@application/views/organization/profile/hooks';
import { AccountIndustryType } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

type UseSpecialtiesProps = {
  industryType: AccountIndustryType | undefined;
  industrySector: string | undefined;
  language: keyof typeof Languages;
  setSpecialty?: (value: string | undefined) => void;
  setSector: (value: string | undefined) => void;
  setType: (value: AccountIndustryType | undefined) => void;
  usePreferredSpecialties?: boolean;
  useCodeAsValue?: boolean;
  specialty?: string | null;
};

const useSpecialties = ({
  industryType,
  industrySector,
  language,
  setSpecialty,
  setSector,
  setType,
  usePreferredSpecialties,
  useCodeAsValue,
  specialty,
}: UseSpecialtiesProps) => {
  const { data: INDUSTRY_TYPES = [], isLoading: industryTypesLoading } =
    useGetIndustryTypes();
  const { data: INDUSTRY_SECTORS = [], isLoading: sectorsLoading } =
    useGetIndustrySectors();
  const { data: JOB_SPECIALTIES = [], isLoading: specialtiesLoading } =
    useGetJobSpecialties();

  const { preferredSpecialtyCodes } = useContext(AccountContext);

  const INDUSTRY_TYPE_OPTIONS = useMemo(
    () => mapOptions(INDUSTRY_TYPES, language),
    [INDUSTRY_TYPES, language]
  );

  const INDUSTRY_SECTORS_OPTIONS = useMemo(
    () =>
      (industryType
        ? INDUSTRY_SECTORS.filter((s) => s.industry.code === industryType)
        : INDUSTRY_SECTORS
      ).map(({ descriptions, code }) => ({
        value: code,
        label: descriptions[extractLanguage(language)],
      })),
    [INDUSTRY_SECTORS, industryType, language]
  );

  const SECTORS_OPTIONS = useMemo(
    () =>
      INDUSTRY_SECTORS.map(({ descriptions, code }) => ({
        value: code,
        label: descriptions[extractLanguage(language)],
      })),
    [INDUSTRY_SECTORS, language]
  );

  const JOB_SPECIALTIES_OPTIONS = useMemo(() => {
    let filteredSpecialties =
      usePreferredSpecialties && preferredSpecialtyCodes.length > 0
        ? JOB_SPECIALTIES.filter(
            (s) =>
              preferredSpecialtyCodes.includes(s.code) ||
              (useCodeAsValue ? s.code === specialty : s.id === specialty)
          )
        : [...JOB_SPECIALTIES];

    if (industryType) {
      filteredSpecialties = filteredSpecialties.filter(
        (s) => s.sector.industry.code === industryType
      );
    }

    if (industrySector) {
      filteredSpecialties = filteredSpecialties.filter(
        (s) => s.sector.code === industrySector
      );
    }

    return filteredSpecialties.map(({ descriptions, id, code }) => ({
      value: useCodeAsValue ? code : id,
      label: descriptions[extractLanguage(language)],
    }));
  }, [
    JOB_SPECIALTIES,
    industryType,
    language,
    industrySector,
    useCodeAsValue,
    usePreferredSpecialties,
    preferredSpecialtyCodes,
    specialty,
  ]);

  const onSpecialtyChange = useCallback(
    (onChange: (...event: any[]) => void) => (option: any) => {
      onChange(option?.value || undefined);

      if (!option?.value) {
        return;
      }

      const foundSpecialty = JOB_SPECIALTIES.find((s) =>
        useCodeAsValue ? s.code === option.value : s.id === option.value
      );

      if (!foundSpecialty) {
        return;
      }

      setSector(foundSpecialty.sector.code);
      setType(foundSpecialty.sector.industry.code);
    },
    [JOB_SPECIALTIES, useCodeAsValue, setSector, setType]
  );

  const onIndustryTypeChange = useCallback(
    (onChange: (...event: any[]) => void, currentValue: any) =>
      (option: any) => {
        if (
          !option?.value ||
          (option?.value && option.value !== currentValue)
        ) {
          setSpecialty?.(undefined);
          setSector(undefined);
        }
        onChange(option?.value || undefined);
      },
    [setSpecialty, setSector]
  );

  const onIndustrySectorChange = useCallback(
    (onChange: (...event: any[]) => void, currentValue: any) =>
      (option: any) => {
        if (
          !option?.value ||
          (option?.value && option.value !== currentValue)
        ) {
          setSpecialty?.(undefined);

          if (option?.value) {
            const sector = INDUSTRY_SECTORS.find(
              (s) => s.code === option.value
            );
            if (sector) {
              setType(sector.industry.code);
            }
          }
        }
        onChange(option?.value || undefined);
      },
    [INDUSTRY_SECTORS, setSpecialty, setType]
  );

  return {
    industryTypesLoading,
    sectorsLoading,
    specialtiesLoading,
    SECTORS_OPTIONS,
    INDUSTRY_TYPE_OPTIONS,
    INDUSTRY_SECTORS_OPTIONS,
    JOB_SPECIALTIES_OPTIONS,
    onIndustryTypeChange,
    onIndustrySectorChange,
    onSpecialtyChange,
  };
};

export default useSpecialties;
