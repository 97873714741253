import { useCandidateNegotiationRooms } from '@domain/candidate';
import { CandidateNegotiationRoomsFilters } from '@domain/graphql.types';

import { toViewModel } from '../view-models/get-candidate-negotiation-rooms.vm';

const useGetCandidateNegotiationRooms = (
  filterBy?: CandidateNegotiationRoomsFilters
) => {
  const { data, fetching, error, reexecuteQuery } =
    useCandidateNegotiationRooms({
      filterBy,
    });

  const viewModel = toViewModel({
    candidateNegotiationRooms: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateRequests: reexecuteQuery,
  };
};

export default useGetCandidateNegotiationRooms;
