import { gql } from '@urql/core';

const MutationOfferDelete = gql`
  mutation OfferDelete($input: DeleteOfferInput!) {
    offerDelete(input: $input) {
      ok
    }
  }
`;

export default MutationOfferDelete;
