import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, TextAreaField, Tooltip } from '@application/components';

import { Section } from '../../components';
import { RequestFormFields } from '../../schema';

type NotesFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
};

const NotesFields = ({ control, errors }: NotesFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <Section legend={t('subtitles.notes')} hideBox hideLegend>
      <Stack>
        <Controller
          name="generalNotes"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              value={value || ''}
              className="mt-s-0"
              label={t('labels.generalNotes')}
              labelClassName="text-20 font-semibold pb-s-16"
              rows={6}
              maxChar={3000}
              onChange={onChange}
              invalid={!!errors.generalNotes}
              helperText={
                errors.generalNotes?.message &&
                tGlobal(errors.generalNotes?.message, { max: '3000' })
              }
            />
          )}
        />
        <Controller
          name="jobAdditionalNotes"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              value={value || ''}
              className="mt-s-0"
              label={
                <div className="flex items-center">
                  {t('labels.jobAdditionalNotes')}
                  <Tooltip message={t('labels.jobAdditionalNotesTooltips')}>
                    <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
                  </Tooltip>
                </div>
              }
              labelClassName="text-20 font-semibold pb-s-16"
              rows={6}
              maxChar={3000}
              onChange={onChange}
              invalid={!!errors.jobAdditionalNotes}
              helperText={
                errors.jobAdditionalNotes?.message &&
                tGlobal(errors.jobAdditionalNotes?.message, { max: '3000' })
              }
            />
          )}
        />
      </Stack>
    </Section>
  );
};

export default NotesFields;
