import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Fieldset,
  HelperText,
  SectionContainer,
} from '@application/components';
import { Avatar } from '@application/components/avatar';
import { Button, FileUploadButton } from '@application/components/buttons';
import { Box, Stack } from '@application/components/container-layouts';
import { UserRoleGuard } from '@application/components/guards';
import { ConfirmationModal } from '@application/components/modal';
import { ModalContext } from '@application/context';
import useUploadImage from '@application/hooks/useUploadImage';
import { UserRole } from '@domain/graphql.types';

import { useDeleteLogo, useUpdateLogo } from './hooks';

type LogoSectionProps = {
  accountName?: string;
  path?: string;
};

const LogoSection = ({ accountName, path }: LogoSectionProps) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('organization', {
    keyPrefix: 'updateProfileForm.organizationLogo',
  });

  const { deleteAccountLogo } = useDeleteLogo();
  const { updateAccountLogo } = useUpdateLogo();

  const { setModal } = useContext(ModalContext);

  const { error, croppedImageData, imageFile, allowedTypes, onFileChange } =
    useUploadImage({
      modalTitle: t('uploadModal.title'),
      confirmButtonLabel: t('uploadModal.confirmButtonLabel'),
    });

  useEffect(() => {
    setLogoUrl(path || null);
  }, [path]);

  useEffect(() => {
    if (!imageFile) {
      return;
    }

    const updateAvatar = async () => {
      await updateAccountLogo({
        files: [imageFile],
        input: {
          filename: imageFile?.name,
        },
      });
    };
    updateAvatar();
  }, [imageFile, updateAccountLogo]);

  useEffect(() => {
    if (!croppedImageData) {
      return;
    }
    setLogoUrl(croppedImageData);
  }, [croppedImageData]);

  const deleteLogo = useCallback(() => {
    setModal(null);
    deleteAccountLogo();
    setLogoUrl(null);
  }, [deleteAccountLogo, setLogoUrl, setModal]);

  const confirmLogoDeletion = useCallback(() => {
    setModal({
      title: t('deleteModal.title'),
      content: (
        <ConfirmationModal
          content={t('deleteModal.message')}
          onCancel={() => setModal(null)}
          onConfirm={deleteLogo}
        />
      ),
    });
  }, [deleteLogo, setModal, t]);

  return (
    <SectionContainer
      titleAs="h2"
      title={t('title')}
      description={t('description')}
    >
      <Stack>
        <Box>
          <Fieldset legend={t('title')} hideLegend>
            <div className="flex gap-s-24 items-center flex-wrap md:flex-nowrap">
              <Avatar name={accountName} url={logoUrl} />

              <div className="grow">
                <p className="max-w-[21rem]">{t('instructions')}</p>
              </div>

              <UserRoleGuard
                authorizedUserRoles={[
                  UserRole.SuperAdmin,
                  UserRole.Owner,
                  UserRole.Admin,
                ]}
              >
                <div className="flex self-end gap-s-24">
                  <Button disabled={!logoUrl} onClick={confirmLogoDeletion}>
                    {tGlobal('button.delete')}
                  </Button>

                  <FileUploadButton
                    aria-describedby="avatar-upload"
                    accept={allowedTypes.join(',')}
                    onChange={onFileChange}
                  >
                    {tGlobal('button.edit')}
                  </FileUploadButton>
                </div>
              </UserRoleGuard>
            </div>

            {error && (
              <HelperText id="avatar-upload" invalid className="px-s-0">
                {error}
              </HelperText>
            )}
          </Fieldset>
        </Box>
      </Stack>
    </SectionContainer>
  );
};
export default LogoSection;
