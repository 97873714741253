import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { CandidateOffer, CandidateOfferResponse } from '@domain/graphql.types';

type ToViewModel = {
  candidateOffer: {
    data: CandidateOfferResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateOffer: { data, fetching, error },
}: ToViewModel): ViewModel<CandidateOffer | undefined> =>
  new ViewModel<CandidateOffer | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.candidateOffer || undefined,
  });
