import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const AccountSizes = gql`
  ${DescriptionsFragment}

  query AccountSizes {
    accountSizes {
      values {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
  }
`;

export default AccountSizes;
