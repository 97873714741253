import { useTranslation } from 'react-i18next';

import { StatusTabs } from '@application/components';
import { ButtonLink } from '@application/components/links';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { RequestStatusTypeCode } from '@domain/graphql.types';

import { CandidateRequestsList } from './list';

const CandidateRequestListPage = () => {
  const { t } = useTranslation('candidates');

  return (
    <>
      <div className="flex w-full justify-between mb-s-32">
        <StatusTabs
          t={t}
          type="candidate-request"
          mainPath={RootPrivatePage.CANDIDATE_REQUESTS}
          statuses={[
            RequestStatusTypeCode.Agreement,
            RequestStatusTypeCode.Draft,
            RequestStatusTypeCode.InProgress,
          ]}
        />

        <ButtonLink
          to={PrivatePage.CANDIDATE_REQUEST_CREATE}
          icon={<i className="ri-file-add-line" />}
          primary
        >
          {t('button.newRequest')}
        </ButtonLink>
      </div>

      <CandidateRequestsList />
    </>
  );
};

export default CandidateRequestListPage;
