import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Cluster,
  Fieldset,
  FileInputField,
  FormInputWithSuffix,
  HelperText,
  Link,
  Radio,
  RadioGroup,
  SelectField,
  Stack,
  Switcher,
  TextInputField,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { AccountContext, ModalContext } from '@application/context';
import { useUploadFile } from '@application/hooks';
import { mapOptions } from '@application/utils';
import {
  CreateSpecialtyModal,
  useGetJobSpecialties,
} from '@application/views/organization';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';
import {
  CandidateRequestFile,
  CandidateRequestType,
} from '@domain/graphql.types';
import { formattedNowDate } from '@utils/date-utils';
import { extractLanguage } from '@utils/i18n-utils';
import { isDefined } from '@utils/type-utils';

import { OptionType } from '../../../../../components/select-field/select';
import { Section } from '../../components';
import { CandidateRequestFormFields } from '../../schema';

type InformationFieldsProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  register: UseFormRegister<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  trigger: UseFormTrigger<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
  setError: UseFormSetError<CandidateRequestFormFields>;
  clearErrors: UseFormClearErrors<CandidateRequestFormFields>;
  resumeUrl?: string;
};

const InformationFields = ({
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
  setError,
  clearErrors,
  resumeUrl,
}: InformationFieldsProps) => {
  const [cvUrl, setCvUrl] = useState<string | undefined>(resumeUrl);
  const [candidateMet, setCandidateMet] = useState<boolean | undefined>(
    undefined
  );

  const { setModal } = useContext(ModalContext);
  const { refreshAccount } = useContext(AccountContext);

  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const specialty = watch('jobSpecialtyId');
  const canWorkInCanadaOrQuebec = watch('canWorkInCanadaOrQuebec');
  const candidateRequestType = watch('type');
  const files = watch('files');
  const metAt = watch('metAt');

  const { onFileChange, getReadableExtensions, getAllowedExtensions } =
    useUploadFile({
      setFile: (name: string, value: CandidateRequestFile) => {
        setValue(name as keyof CandidateRequestFormFields, [value]);
        setCvUrl(undefined);
      },
      setFileError: (name: string, message: string) =>
        setError(name as any, { type: 'custom', message }),
      clearFileErrors: (name: string) => {
        clearErrors(name as any);
      },
    });

  const { data: JOB_SPECIALTIES = [], isLoading: specialtiesLoading } =
    useGetJobSpecialties();

  const { preferredSpecialtyCodes } = useContext(AccountContext);

  const JOB_SPECIALTIES_OPTIONS = useMemo(() => {
    const specialties =
      preferredSpecialtyCodes.length > 0
        ? JOB_SPECIALTIES.filter(
            (s) =>
              preferredSpecialtyCodes.includes(s.code) || s.id === specialty
          )
        : [...JOB_SPECIALTIES];

    return specialties.map(({ descriptions, id }) => ({
      value: id,
      label: descriptions[extractLanguage(i18n.language)],
    }));
  }, [JOB_SPECIALTIES, i18n.language, preferredSpecialtyCodes, specialty]);

  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();

  const OPERATION_TERRITORIES_OPTIONS = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n]
  );

  const onSubmitSpecialty = useCallback(
    (jobSpecialtyId?: string) => {
      refreshAccount();
      setModal(null);
      if (jobSpecialtyId) {
        setValue('jobSpecialtyId', jobSpecialtyId);
      }
      refreshAccount();
      setModal(null);
    },
    [refreshAccount, setModal, setValue]
  );

  const handleSpecialtyModal = useCallback(() => {
    setModal({
      title: t('modal.titles.searchSpecialty'),
      content: (
        <CreateSpecialtyModal
          afterSubmit={onSubmitSpecialty}
          onClose={() => setModal(null)}
        />
      ),
    });
  }, [onSubmitSpecialty, setModal, t]);

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateRequestFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  useEffect(() => {
    if (candidateMet === undefined && isDefined(metAt)) {
      setCandidateMet(true);
    }
  }, [candidateMet, metAt]);

  return (
    <Section legend={t('subtitles.information')}>
      <Stack space={24}>
        <Switcher space={24} limit={3}>
          <TextInputField
            label={t('labels.firstName')}
            invalid={!!errors.firstName}
            helperText={
              errors.firstName?.message &&
              tGlobal(errors.firstName?.message, { max: '128' })
            }
            {...register('firstName')}
          />

          <TextInputField
            label={t('labels.lastName')}
            invalid={!!errors.lastName}
            helperText={
              errors.lastName?.message &&
              tGlobal(errors.lastName?.message, { max: '128' })
            }
            {...register('lastName')}
          />

          <TextInputField
            label={t('labels.email')}
            invalid={!!errors.email}
            helperText={
              errors.email?.message &&
              tGlobal(errors.email?.message, { max: '128' })
            }
            {...register('email')}
          />
        </Switcher>

        <Controller
          name="jobSpecialtyId"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <SelectField
              className="lg:w-2/3"
              label={t('labels.job')}
              name={name}
              options={JOB_SPECIALTIES_OPTIONS}
              value={
                JOB_SPECIALTIES_OPTIONS.find((s) => s.value === value) || null
              }
              onChange={(option: any) => onChange(option?.value || null)}
              renderMenuFooterContent={
                <Button
                  className="px-s-8"
                  icon={<i className="ri-add-line" />}
                  size="x-small"
                  ghost
                  onClick={handleSpecialtyModal}
                >
                  {t('labels.chooseAnOtherSpecialty')}
                </Button>
              }
              searchable
              invalid={!!errors.jobSpecialtyId}
              helperText={
                errors.jobSpecialtyId?.message &&
                tGlobal(errors.jobSpecialtyId?.message)
              }
              disabled={specialtiesLoading}
              clearable
            />
          )}
        />

        <Switcher space={24} limit={3}>
          <TextInputField
            label={t('labels.specialty')}
            invalid={!!errors.specialty}
            helperText={
              errors.specialty?.message &&
              tGlobal(errors.specialty?.message, { max: '128' })
            }
            {...register('specialty')}
          />

          <TextInputField
            type="date"
            minValue={formattedNowDate()}
            label={t('labels.deadline')}
            invalid={!!errors.receivingOfferDeadline}
            helperText={
              errors.receivingOfferDeadline?.message &&
              tGlobal(errors.receivingOfferDeadline?.message)
            }
            {...register('receivingOfferDeadline')}
          />

          <TextInputField
            type="date"
            minValue={formattedNowDate()}
            label={t('labels.startDate')}
            {...register('desiredStartDate')}
          />
        </Switcher>

        <Switcher space={24} limit={2}>
          <Controller
            name="operationTerritoryCodes"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <SelectField
                label={t('labels.operationTerritories')}
                name={name}
                options={OPERATION_TERRITORIES_OPTIONS}
                value={
                  OPERATION_TERRITORIES_OPTIONS.filter((l) =>
                    value?.includes(l.value)
                  ) || null
                }
                onChange={(options: any) =>
                  onChange(
                    options
                      ? options.map((option: OptionType) => option.value)
                      : null
                  )
                }
                invalid={!!errors.operationTerritoryCodes}
                helperText={
                  errors.operationTerritoryCodes?.message &&
                  tGlobal(errors.operationTerritoryCodes?.message)
                }
                searchable
                clearable
                multiple
                limitTags={0}
              />
            )}
          />

          <FormInputWithSuffix
            type="number"
            label={t('labels.experience')}
            minValue={1}
            noMargin
            suffix={t('suffix.years')}
            alignLeft
            fullWidth
            mediumSuffix
            invalid={!!errors.experience}
            helperText={
              errors.experience?.message &&
              tGlobal(errors.experience?.message, { min: '1' })
            }
            {...register('experience')}
          />
        </Switcher>

        <Controller
          name="files"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <FileInputField
              name={name}
              label={t('labels.resume')}
              acceptTypes={{
                values: getAllowedExtensions(),
                text: getReadableExtensions(),
              }}
              fileName={files ? files[0]?.file.name : undefined}
              invalid={!!error}
              helperText={
                error?.message &&
                tGlobal(error.message, {
                  fileExtensions: getAllowedExtensions().split(',').join(', '),
                })
              }
              onChange={onFileChange}
              onRemove={() => {
                setValue('files', undefined);
              }}
            />
          )}
        />

        {cvUrl && (
          <Link to={cvUrl} newTab className="underline text-16 font-semibold">
            {t('labels.viewCv')}
          </Link>
        )}

        <RadioGroup
          name="candidateMet"
          data={BOOLEAN_VALUES}
          legend={t('labels.candidateMet')}
          language={extractLanguage(i18n.language)}
          legendSize="medium"
          alignRow
          value={candidateMet}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setCandidateMet(event.target.value === 'true');
            if (event.target.value === 'false') {
              setValue('metAt', null);
            }
          }}
        />

        {candidateMet && (
          <FormInputWithSuffix
            label={t('labels.meetingDate')}
            type="date"
            mediumSuffix
            noMargin
            {...register('metAt')}
          />
        )}

        <Controller
          name="canWorkInCanadaOrQuebec"
          control={control}
          render={({ field: { name } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.canWorkInCanadaOrQuebec')}
              language={extractLanguage(i18n.language)}
              legendSize="medium"
              value={
                isDefined(canWorkInCanadaOrQuebec)
                  ? canWorkInCanadaOrQuebec
                  : undefined
              }
              onChange={(e) =>
                handleOnRadioChange(e as ChangeEvent<HTMLInputElement>, name)
              }
              alignRow
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset
              legend={t('labels.candidateType')}
              legendSize="small"
              invalid={!!errors.type?.message}
            >
              <Cluster space={16} as="ul">
                {Object.values(CandidateRequestType).map((type) => (
                  <li key={type}>
                    <Radio
                      name={name}
                      value={type}
                      label={t(
                        `enum.candidateRequestType.${type.toLowerCase()}`
                      )}
                      onChange={(e) => {
                        const event = e as ChangeEvent<HTMLInputElement>;
                        handleOnRadioChange(event, name);
                        if (
                          event.target.value ===
                          CandidateRequestType.Outsourcing
                        ) {
                          setValue('otherPaymentTypeCode', undefined);
                          setValue('otherPaymentAmount', undefined);
                        }
                        trigger(name);
                        trigger('jobType');
                        trigger('paymentFrequencyCode');
                      }}
                      checked={candidateRequestType === type}
                      asButton
                    />
                  </li>
                ))}
              </Cluster>

              {errors.type?.message && (
                <HelperText id="type-error" invalid>
                  {tGlobal(errors.type.message)}
                </HelperText>
              )}
            </Fieldset>
          )}
        />
      </Stack>
    </Section>
  );
};

export default InformationFields;
