import { ReactNode } from 'react';
import { components, MenuProps } from 'react-select';

import { cn } from '@utils/lib-utils';

import { OptionType } from '../types';

const Menu = (renderMenuFooterContent?: ReactNode) => {
  const component = ({ children, ...props }: MenuProps<OptionType>) => {
    const className = cn(
      'relative !z-dropdown',
      renderMenuFooterContent && 'pb-s-48'
    );

    return (
      <components.Menu {...props} className={className}>
        {children}
        {renderMenuFooterContent && (
          <div className="absolute bottom-s-0 left-s-0 px-s-8 flex justify-end items-center border-t-1 rounded-b-md border-stroke-default bg-white w-full h-s-48">
            {renderMenuFooterContent}
          </div>
        )}
      </components.Menu>
    );
  };
  return component;
};

export default Menu;
