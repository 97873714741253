import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useOfferCandidateUpdateFeedback } from '@domain/offer';

import { toViewModel } from '../view-models/update-offer-candidate-feedback.vm';

const useUpdateOfferCandidateFeedback = () => {
  const { data, fetching, error, updateOfferCandidateFeedback } =
    useOfferCandidateUpdateFeedback();

  const { t } = useTranslation('offers', {
    keyPrefix: 'candidates',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    updateOfferCandidateFeedback: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('messages.updateFeedbackError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return { updateOfferCandidateFeedback, viewModel };
};

export default useUpdateOfferCandidateFeedback;
