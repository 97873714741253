import { gql } from '@urql/core';

import { CandidateRequestOfferFragment } from '../fragment';

const CandidateRequestOffers = gql`
  ${CandidateRequestOfferFragment}

  query CandidateRequestOffers($input: CandidateRequestOffersInput!) {
    candidateRequestOffers(input: $input) {
      candidateOffers {
        ...CandidateRequestOfferFragment
      }
    }
  }
`;

export default CandidateRequestOffers;
