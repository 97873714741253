import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountUserInvite } from '@domain/account-user';
import { AccountUserErrors } from '@domain/graphql.types';

import { toViewModel } from '../view-models/invite-account-user.vm';

const useInviteAccountUser = () => {
  const { data, fetching, error, inviteAccountUser } = useAccountUserInvite();

  const { t } = useTranslation('organization', {
    keyPrefix: 'users',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    accountUserInvite: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  // Error "preconditions failed" happens if user invites
  // another user already on the app. Displays a generic
  // message bc we don't want to display who's on the app.
  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    const availableSeatsError = errorCodes.find(
      (e) => e.message === AccountUserErrors.NoAvailableSeats
    );

    // We handle this error in useInviteUserModal
    if (!availableSeatsError) {
      setAlertContent({
        action: t('inviteMemberError'),
        modifier: 'alert-error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return {
    viewModel,
    inviteAccountUser,
  };
};

export default useInviteAccountUser;
