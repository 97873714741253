import { useTranslation } from 'react-i18next';

import { SummaryList } from '@application/components/summary';
import SummaryBlock from '@application/components/summary/SummaryBlock';
import { Offer } from '@domain/graphql.types';

type SalesPitchProps = {
  offer: Offer | undefined;
};

const SalesPitch = ({ offer }: SalesPitchProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });

  return (
    <SummaryBlock className="p-s-0 mt-s-24">
      <SummaryList
        className="!mt-s-0"
        density="default"
        isCollapsable
        space={0}
        data={[
          {
            text: t('labels.salesPitch'),
            description: offer?.salesPitch || '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default SalesPitch;
