import { gql } from '@urql/core';

const MutationCandidateNegotiationRoomsReject = gql`
  mutation CandidateNegotiationRoomsReject(
    $input: CandidateNegotiationRoomsRejectInput!
  ) {
    candidateNegotiationRoomsReject(input: $input) {
      rooms {
        id
      }
    }
  }
`;

export default MutationCandidateNegotiationRoomsReject;
