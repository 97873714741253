import { useIndustryTypes } from '@domain/industry';

import { toViewModel } from '../view-models/get-industry-types.vm';

const useGetIndustryTypes = () => {
  const { data, fetching, error } = useIndustryTypes();

  return toViewModel({
    industryTypes: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetIndustryTypes;
