import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  SelectField,
  TextAreaField,
  TextInputField,
} from '@application/components';
import { Button } from '@application/components/buttons';
import { Stack } from '@application/components/container-layouts';
import { AddressAutocomplete } from '@application/components/form/address-autocomplete';
import { SwitchField } from '@application/components/form/switch-field';
import { Modal } from '@application/components/modal';
import { OptionType } from '@application/components/select-field/select';
import { LoadingSpinner } from '@application/components/spinner';
import { AccountContext, AlertContext } from '@application/context';
import { useSpecialties } from '@application/hooks';
import { AccountIndustryType } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { useGetOperationUnit } from '../hooks';
import useUpdateOperationUnit from '../hooks/useUpdateOperationUnit';
import { normalizeUpdateInputData } from '../normalizers';
import { OPERATION_UNIT_SCHEMA } from '../schema';
import { OperationUnitFormFields } from '../types';

export type UpdateOperationUnitModalProps = {
  id: string;
  onClose: () => void;
  afterSubmit: (operationUnitId: string) => void;
};

const UpdateOperationUnitModal = ({
  id,
  onClose,
  afterSubmit,
}: UpdateOperationUnitModalProps) => {
  const [originalHeadOffice, setOriginalHeadOffice] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('operationUnit', {
    keyPrefix: 'operationUnitModal',
  });
  const { refreshAccount } = useContext(AccountContext);

  const { setAlertContent } = useContext(AlertContext);

  const {
    viewModel: { data, isLoading },
  } = useGetOperationUnit(id);

  const {
    updateOperationUnit,
    viewModel: { isLoading: isUpdating },
  } = useUpdateOperationUnit();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<OperationUnitFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(OPERATION_UNIT_SCHEMA),
  });

  useEffect(() => {
    if (data) {
      reset(data);
      setOriginalHeadOffice(data.headOffice || false);
    }
  }, [data, reset]);

  const onSubmit: SubmitHandler<OperationUnitFormFields> = useCallback(
    async (values) => {
      const result = await updateOperationUnit({
        input: { ...normalizeUpdateInputData(values), id },
      });

      // Ideally we would put these operations in the useUpdateOperationUnit.tsx, but the modal as it is kills the ongoing processes when closed.
      if (result.data?.operationUnitUpdate.operationUnit) {
        refreshAccount();
        setAlertContent({
          action: t('messages.success.update'),
          modifier: 'alert-success',
        });
        afterSubmit(result.data?.operationUnitUpdate.operationUnit.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterSubmit, id, updateOperationUnit]
  );

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  const industryCode = watch('industryCode');
  const sectorCode = watch('sectorCode');

  const {
    industryTypesLoading,
    sectorsLoading,
    INDUSTRY_TYPE_OPTIONS,
    INDUSTRY_SECTORS_OPTIONS,
    onIndustryTypeChange,
    onIndustrySectorChange,
  } = useSpecialties({
    industryType: industryCode ?? undefined,
    industrySector: sectorCode ?? undefined,
    language: extractLanguage(i18n.language),
    setSector: (value: string | undefined) => setValue('sectorCode', value),
    setType: (value: AccountIndustryType | undefined) =>
      setValue('industryCode', value),
    useCodeAsValue: true,
  });

  return (
    <>
      {showLoader && isLoading && (
        <div className="flex w-full h-s-96 justify-center">
          <LoadingSpinner size="lg" />
        </div>
      )}

      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.CloseBtn onClick={onClose} />
          <Stack space={24}>
            <TextInputField
              label={t('operationUnitName')}
              invalid={!!errors.name}
              helperText={
                errors.name?.message &&
                tGlobal(errors.name?.message, {
                  field: t('operationUnitName'),
                })
              }
              {...register('name')}
            />

            <div>
              <Controller
                name="address"
                control={control}
                render={({
                  field: { onBlur, onChange, name, value },
                  fieldState: { error },
                }) => (
                  <AddressAutocomplete
                    className="flex-grow"
                    label={t('address')}
                    name={name}
                    value={value!}
                    invalid={!!error}
                    helperText={
                      error?.message &&
                      tGlobal(error?.message, {
                        field: t('address'),
                      })
                    }
                    onChange={(option: OptionType) => onChange(option?.label)}
                    onBlur={onBlur}
                  />
                )}
              />

              <SwitchField
                className="mt-s-8"
                label={t('headOffice')}
                disabled={originalHeadOffice}
                {...register('headOffice')}
              />
            </div>

            <div>
              <TextInputField
                label={t('phoneNumber')}
                type="tel"
                invalid={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber?.message &&
                  tGlobal(errors.phoneNumber?.message, {
                    field: t('phoneNumber'),
                  })
                }
                {...register('phoneNumber')}
              />

              <SwitchField
                className="mt-s-8"
                disabled={isLoading}
                label={t('phoneDisplay')}
                {...register('phoneDisplay')}
              />
            </div>

            <div className="flex gap-s-16">
              <Controller
                name="industryCode"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <SelectField
                    className="w-full"
                    label={t('industry')}
                    name={name}
                    options={INDUSTRY_TYPE_OPTIONS}
                    value={
                      INDUSTRY_TYPE_OPTIONS.find((o) => o.value === value) ||
                      null
                    }
                    onChange={onIndustryTypeChange(onChange, value)}
                    searchable
                    clearable
                    disabled={industryTypesLoading}
                    invalid={!!errors.industryCode}
                    helperText={
                      errors.industryCode?.message &&
                      tGlobal(errors.industryCode?.message)
                    }
                  />
                )}
              />

              <Controller
                name="sectorCode"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <SelectField
                    className="w-full"
                    label={t('sector')}
                    name={name}
                    options={INDUSTRY_SECTORS_OPTIONS}
                    value={
                      INDUSTRY_SECTORS_OPTIONS.find((o) => o.value === value) ||
                      null
                    }
                    onChange={onIndustrySectorChange(onChange, value)}
                    searchable
                    clearable
                    disabled={sectorsLoading}
                    invalid={!!errors.sectorCode}
                    helperText={
                      errors.sectorCode?.message &&
                      tGlobal(errors.sectorCode?.message)
                    }
                  />
                )}
              />
            </div>

            <Controller
              name="notes"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <TextAreaField
                  name={name}
                  value={value || ''}
                  className="mt-s-0"
                  label={t('notes')}
                  rows={4}
                  maxChar={1024}
                  onChange={onChange}
                  invalid={!!errors.notes}
                  helperText={
                    errors.notes?.message &&
                    tGlobal(errors.notes?.message, { max: '1024' })
                  }
                />
              )}
            />
          </Stack>

          <Modal.Action>
            <Button onClick={onClose} disabled={isUpdating}>
              {tGlobal('button.cancel')}
            </Button>

            <Button primary type="submit" loading={isUpdating}>
              {t('updateOperationUnit')}
            </Button>
          </Modal.Action>
        </form>
      )}
    </>
  );
};

export default UpdateOperationUnitModal;
