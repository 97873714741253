import { gql } from '@urql/core';

const MutationOfferCandidateDeleteCV = gql`
  mutation OfferCandidateDeleteCV($input: OfferCandidateDeleteCVInput!) {
    offerCandidateDeleteCV(input: $input) {
      ok
    }
  }
`;

export default MutationOfferCandidateDeleteCV;
