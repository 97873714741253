import { useMutation } from 'urql';

import { Mutation, MutationRequestCreateArgs } from '@domain/graphql.types';
import { MutationRequestCreate } from '@infrastructure/graphql/request';

const useRequestCreate = () => {
  const [{ data, fetching, error }, createRequest] = useMutation<
    Pick<Mutation, 'requestCreate'>,
    MutationRequestCreateArgs
  >(MutationRequestCreate);

  return {
    createRequest,
    data,
    fetching,
    error,
  };
};

export default useRequestCreate;
