import { useMutation } from 'urql';

import {
  Mutation,
  MutationRecruitmentNegotiationCreateArgs,
} from '@domain/graphql.types';
import { MutationRecruitmentNegotiationCreate } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiationCreate = () => {
  const [{ data, fetching, error }, createRecruitmentNegotiation] = useMutation<
    Pick<Mutation, 'recruitmentNegotiationCreate'>,
    MutationRecruitmentNegotiationCreateArgs
  >(MutationRecruitmentNegotiationCreate);

  return {
    createRecruitmentNegotiation,
    data,
    fetching,
    error,
  };
};

export default useRecruitmentNegotiationCreate;
