import { useQuery } from 'urql';

import { Query, QueryCandidateOpportunityArgs } from '@domain/graphql.types';
import { CandidateOpportunity } from '@infrastructure/graphql/candidate';

const useCandidateOpportunity = (input: QueryCandidateOpportunityArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateOpportunity'>,
    QueryCandidateOpportunityArgs
  >({
    query: CandidateOpportunity,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.candidateOpportunity,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateOpportunity;
