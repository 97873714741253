import { gql } from '@urql/core';

import { AccountFragment } from '@infrastructure/graphql/account';

const CandidateRequestAcceptedOffer = gql`
  ${AccountFragment}

  query CandidateRequestAcceptedOffer(
    $input: CandidateRequestAcceptedOfferInput!
  ) {
    candidateRequestAcceptedOffer(input: $input) {
      candidateOffer {
        account {
          ...AccountFragment
        }
        user {
          name
          email
        }
      }
    }
  }
`;

export default CandidateRequestAcceptedOffer;
