import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';

import { CandidateRequestFormFields } from '../schema';

type ToViewModel = {
  candidateRequest: {
    data: Record<string, never> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateRequest: { data = {}, fetching, error },
}: ToViewModel): ViewModel<CandidateRequestFormFields> =>
  new ViewModel<CandidateRequestFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data as CandidateRequestFormFields,
  });
