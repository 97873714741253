import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { UserRole } from '@domain/graphql.types';

import { UserRoleGuard } from '../guards';
import { ButtonLink } from '../links';
import NavigationTabLink from './NavigationTabLink';

type SingleTab = {
  path: string;
  element: React.ReactNode;
  id: string;
  label: string;
  authorizedUserRoles?: UserRole[] | null;
};

type NavigationTab =
  | SingleTab
  | (Omit<SingleTab, 'path'> & { children: SingleTab[] });

type NavigationTabsProps = {
  tabs: NavigationTab[];
};

const NavigationTabs = ({ tabs }: NavigationTabsProps) => {
  const location = useLocation();

  const currentChildren = useMemo(() => {
    const tab = tabs.find((p) =>
      'children' in p
        ? p.children.some((c) => location.pathname === c.path)
        : false
    );

    if (tab && 'children' in tab && tab.children.length > 0) {
      return tab.children;
    }

    return null;
  }, [location, tabs]);

  return (
    <>
      <ul
        role="tablist"
        className="tabs border-b overflow-x-auto flex-nowrap w-fit mb-s-24"
      >
        {tabs.map((tab, index) => (
          <UserRoleGuard
            authorizedUserRoles={tab.authorizedUserRoles}
            key={tab.id}
          >
            <NavigationTabLink
              key={tab.id}
              tab={tab}
              last={index === tabs.length - 1}
            />
          </UserRoleGuard>
        ))}
      </ul>

      {!!currentChildren && currentChildren.length > 1 && (
        <ul className="tabs overflow-x-auto flex-nowrap gap-s-16 mb-s-32">
          {currentChildren.map(({ path, element, label, id }) => (
            <li key={path}>
              <ButtonLink
                size="small"
                aria-controls={label}
                aria-selected={location.pathname === path}
                to={path}
                role="tab"
                id={id}
                isNavLink
              >
                {element}
              </ButtonLink>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default NavigationTabs;
