import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { RecruitmentNegotiationRoomResponse } from '@domain/graphql.types';

import { normalizeRecruitmentNegotiationRoomAgreement } from '../normalizers';
import { RecruitmentNegotiationRoomAgreement } from '../types';

type ToViewModel = {
  agreement: {
    data: RecruitmentNegotiationRoomResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  agreement: { data, fetching, error },
}: ToViewModel): ViewModel<RecruitmentNegotiationRoomAgreement | undefined> =>
  new ViewModel<RecruitmentNegotiationRoomAgreement | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: normalizeRecruitmentNegotiationRoomAgreement(data?.room),
  });
