import React, {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import { Switcher } from '../container-layouts';
import { Threshold } from '../container-layouts/types';
import { HelperText } from '../form';
import { RangeInput } from './range-input';
import { TextInputField } from './text-input-field';

type RangeSliderProps = {
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  minLabel?: ReactNode;
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  maxLabel?: ReactNode;
  /**
   * Provide text that is used alongside the `<input>` for additional help
   * or as a validation message on input error
   */
  helperText?: string;
  /**
   * Specify the name of the min slider `<input>`
   */
  minName: string;
  /**
   * Specify the name of the max slider `<input>`
   */
  maxName: string;
  /**
   * Specify the value of the min slider `<input>`
   */
  minValue?: number | undefined;
  /**
   * Specify the value of the max slider `<input>`
   */
  maxValue?: number | undefined;
  /**
   * Specify the min value of the `<input>`
   */
  min: number;
  /**
   * Specify the max value of the `<input>`
   */
  max: number;
  /**
   * Provide a suffix to be added to the minInputValue and maxInputValue state
   */
  suffix?: string;
  /**
   * Specify whether the `<input>` should be disabled
   */
  disabled?: boolean;
  /**
   * Provide a custom className to be applied to the `<input>`
   */
  className?: string;
  /**
   * Provide a custom className to be applied to the `<TextInputField>`
   */
  textInputFieldsClassName?: string;
  /**
   * Specify what it the step for the input type number
   */
  step?: number;
  /**
   * Specify the container width at which the `<Switcher>` switches between the inputs
   */
  inputsThreshold?: Threshold;
  /**
   * Optionally provide an `onChange` handler that is called whenever the
   * slider `<input>` is updated
   */
  onChange: (name: string, value: number | string) => void;
};

type Ref = HTMLDivElement;

const RangeSlider = React.forwardRef<Ref, RangeSliderProps>(
  (
    {
      minLabel,
      maxLabel,
      minName,
      maxName,
      minValue,
      maxValue,
      min,
      max,
      disabled,
      helperText,
      className: customClassName,
      textInputFieldsClassName,
      inputsThreshold,
      onChange,
      suffix,
      step,
      ...rest
    }: RangeSliderProps,
    ref: ForwardedRef<Ref>
  ) => {
    const rangeInputId = useMemo(() => randomString(), []);
    const helperTextId = useMemo(() => randomString(), []);

    const className = cn(
      'flex flex-col relative [&>*+*]:mt-s-16',
      customClassName
    );

    const handleInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target;
        const newValue = parseFloat(input.value);
        if (Number.isNaN(newValue)) {
          onChange(input.name, input.name === minName ? min : max);
        }
        onChange(input.name, newValue);
      },
      [onChange, max, min, minName]
    );

    const handleInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        const input = event.target;
        const newValue = parseFloat(input.value);
        const inputMinValue = parseFloat(input.min);
        const inputMaxValue = parseFloat(input.max);

        if (
          Number.isNaN(newValue) ||
          (input.name === maxName &&
            (Number.isNaN(inputMinValue) || newValue < inputMinValue)) ||
          (input.name === minName &&
            (Number.isNaN(inputMaxValue) || newValue > inputMaxValue))
        ) {
          onChange(input.name, input.name === minName ? min : max);
        } else if (step && step % 1 !== 0) {
          const roundedValue = newValue.toFixed(2);
          onChange(input.name, roundedValue);
        } else {
          onChange(input.name, parseInt(input.value, 10));
        }
      },
      [minName, maxName, min, max, onChange, step]
    );

    return (
      <div ref={ref} className={className} {...rest}>
        <RangeInput
          id={rangeInputId}
          minName={minName}
          maxName={maxName}
          minValue={
            minValue !== undefined &&
            maxValue !== undefined &&
            minValue < maxValue
              ? minValue
              : min
          }
          maxValue={
            minValue !== undefined &&
            maxValue !== undefined &&
            maxValue > minValue
              ? maxValue
              : max
          }
          min={min}
          max={max}
          disabled={disabled}
          onChange={onChange}
          step={step}
        />

        {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}

        {minLabel && maxLabel && (
          <Switcher limit={2} threshold={inputsThreshold}>
            <TextInputField
              className={textInputFieldsClassName}
              label={minLabel}
              name={minName}
              value={minValue}
              minValue={min}
              maxValue={maxValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              suffix={suffix}
              inputProps={{ 'aria-controls': rangeInputId }}
              step={step}
            />
            <TextInputField
              className={textInputFieldsClassName}
              label={maxLabel}
              name={maxName}
              value={maxValue}
              minValue={minValue}
              maxValue={max}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              suffix={suffix}
              inputProps={{ 'aria-controls': rangeInputId }}
              step={step}
            />
          </Switcher>
        )}
      </div>
    );
  }
);

export default RangeSlider;
