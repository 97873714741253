import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaField } from '@application/components';
import { Box, Stack } from '@application/components/container-layouts';

import { OrganizationProfileFormFields } from '../../types';
import Contact from './ContactFields';
import IndustryEmployees from './IndustryEmployeesFields';
import Perks from './PerksFields';

type GeneralInformationFieldsProps = {
  control: Control<OrganizationProfileFormFields>;
  errors: FieldErrors<OrganizationProfileFormFields>;
  register: UseFormRegister<OrganizationProfileFormFields>;
  setValue: UseFormSetValue<OrganizationProfileFormFields>;
  watch: UseFormWatch<OrganizationProfileFormFields>;
  disabled?: boolean;
};

const GeneralInformationFields = ({
  control,
  setValue,
  watch,
  register,
  errors,
  disabled = false,
}: GeneralInformationFieldsProps) => {
  const { t } = useTranslation('organization');
  const { t: tGlobal } = useTranslation();

  return (
    <Stack>
      <Contact
        control={control}
        register={register}
        errors={errors}
        disabled={disabled}
      />

      <Perks
        control={control}
        setValue={setValue}
        watch={watch}
        disabled={disabled}
      />

      <IndustryEmployees
        control={control}
        disabled={disabled}
        setValue={setValue}
        watch={watch}
      />

      <Box>
        <Controller
          name="employerOfferDescription"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              disabled={disabled}
              name={name}
              value={value || ''}
              maxChar={1024}
              rows={5}
              label={t(
                'updateProfileForm.generalInformation.labels.offerDescription'
              )}
              onChange={onChange}
              invalid={!!errors.employerOfferDescription}
              helperText={
                errors.employerOfferDescription?.message &&
                tGlobal(errors.employerOfferDescription?.message, {
                  max: '1024',
                })
              }
            />
          )}
        />
      </Box>
    </Stack>
  );
};
export default GeneralInformationFields;
