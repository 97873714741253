import { useQuery } from 'urql';

import { Query, QueryRecruitmentNegotiationsArgs } from '@domain/graphql.types';
import { RecruitmentNegotiations } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiations = (
  input?: QueryRecruitmentNegotiationsArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'recruitmentNegotiations'>,
    QueryRecruitmentNegotiationsArgs
  >({
    query: RecruitmentNegotiations,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useRecruitmentNegotiations;
