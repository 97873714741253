import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SectionContainer } from '@application/components';
import { Button } from '@application/components/buttons';
import { LoadingSpinner } from '@application/components/spinner';
import { UserContext } from '@application/context';

import { MethodsFields } from './components';
import {
  useGetNotificationPreferences,
  useUpdateNotificationPreferences,
} from './hooks';
import {
  NOTIFICATIONS_CONFIGURATION_SCHEMA,
  NotificationsConfigurationFormFields,
} from './schema';

const NotificationsManagementPage = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('user');

  const { user } = useContext(UserContext);

  const {
    viewModel: { isLoading },
    updateNotificationPreferences,
  } = useUpdateNotificationPreferences();

  const {
    viewModel: { data: notificationPreferences, isLoading: isFetching },
  } = useGetNotificationPreferences();

  const { handleSubmit, register, setValue } =
    useForm<NotificationsConfigurationFormFields>({
      mode: 'onBlur',
      resolver: yupResolver(NOTIFICATIONS_CONFIGURATION_SCHEMA),
    });

  const onSubmit: SubmitHandler<NotificationsConfigurationFormFields> =
    useCallback(
      ({ email, inApp }) => {
        if (!user?.id) {
          return;
        }

        updateNotificationPreferences({
          input: {
            email,
            inApp,
            userId: user.id,
          },
        });
      },
      [user?.id, updateNotificationPreferences]
    );

  useEffect(() => {
    if (!notificationPreferences) {
      return;
    }

    setValue('inApp', notificationPreferences.inApp);
    setValue('email', notificationPreferences.email);
    setValue(
      'none',
      !notificationPreferences.email && !notificationPreferences.inApp
    );
  }, [notificationPreferences, setValue]);

  return (
    <div aria-labelledby="tab-notifications" role="tabpanel">
      <h1 className="sr-only">{t('titles.notifications')}</h1>

      {isFetching ? (
        <div className="h-s-320 flex justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <SectionContainer
          title={t('labels.methods')}
          contentClassName="mt-s-16"
        >
          <form
            className="flex flex-col gap-s-16"
            onSubmit={handleSubmit(onSubmit)}
          >
            <MethodsFields
              isLoading={isLoading}
              register={register}
              setValue={setValue}
            />

            <Button
              className="self-end mt-s-8"
              primary
              type="submit"
              loading={isLoading}
            >
              {tGlobal('button.save')}
            </Button>
          </form>
        </SectionContainer>
      )}
    </div>
  );
};

export default NotificationsManagementPage;
