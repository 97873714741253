import { useParams } from 'react-router';

import { AgreementLayout, PageLoader } from '@application/components';

import { useGetRecruitmentNegotiationAgreement } from './hooks';

const RecruitmentNegotiationAgreementPage = () => {
  const { id = '' } = useParams();

  const {
    viewModel: { data, isLoading },
  } = useGetRecruitmentNegotiationAgreement(id);

  return (
    <>
      {isLoading && !data && <PageLoader />}

      {data && (
        <AgreementLayout
          accountName={data?.offerAccountName}
          emailTo={data?.offerUserEmail}
        />
      )}
    </>
  );
};

export default RecruitmentNegotiationAgreementPage;
