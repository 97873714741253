import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions, PagePanel } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { CandidateOpportunity } from '@domain/graphql.types';

import { CandidateOfferFormFields } from '../../schema';
import DescriptionFields from './DescriptionFields';
import PerksFields from './PerksFields';
import SalesPitchFields from './SalesPitchFields';

type CandidatePanelProps = {
  candidateOpportunity?: CandidateOpportunity;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  trigger: UseFormTrigger<CandidateOfferFormFields>;
  register: UseFormRegister<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
  setError: UseFormSetError<CandidateOfferFormFields>;
  clearErrors: UseFormClearErrors<CandidateOfferFormFields>;
  nextStep?: () => void;
};

const CandidatePanel = ({
  candidateOpportunity,
  clearErrors,
  control,
  errors,
  nextStep,
  register,
  setError,
  setValue,
  trigger,
  watch,
}: CandidatePanelProps) => {
  const { t } = useTranslation('candidates');

  return (
    <PagePanel title={t('steps.candidate')}>
      <DescriptionFields
        candidateOpportunity={candidateOpportunity}
        clearErrors={clearErrors}
        control={control}
        errors={errors}
        register={register}
        setError={setError}
        setValue={setValue}
        trigger={trigger}
        watch={watch}
      />

      <PerksFields control={control} setValue={setValue} watch={watch} />

      <SalesPitchFields control={control} errors={errors} setValue={setValue} />

      <FormActions
        cancelPath={RootPrivatePage.CANDIDATE_OPPORTUNITIES}
        nextStep={nextStep}
      />
    </PagePanel>
  );
};

export default CandidatePanel;
