import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@application/components';
import { RequestTypeBadges } from '@application/components/request-type-badges';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { useGetJobSpecialties } from '@application/views/organization';
import { JobSpecialty } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

type CandidateSummaryProps = {
  index: number;
};

const CandidateSummary = ({ index }: CandidateSummaryProps) => {
  const [specialty, setSpecialty] = useState<JobSpecialty | undefined>(
    undefined
  );
  const { watch } = useFormContext();
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });

  const { data: JOB_SPECIALTIES = [] } = useGetJobSpecialties();

  const jobSpecialtyId = watch(`offerCandidates.${index}.jobSpecialtyId`);
  const firstName = watch(`offerCandidates.${index}.firstName`);
  const lastName = watch(`offerCandidates.${index}.lastName`);
  const experience = watch(`offerCandidates.${index}.experience`);
  const requestTypes = watch(`offerCandidates.${index}.requestTypes`);
  const resume = watch(`offerCandidates.${index}.resume`) as any;
  const metAt = watch(`offerCandidates.${index}.metAt`);

  useEffect(() => {
    if (JOB_SPECIALTIES.length === 0) {
      return;
    }

    const foundSpecialty = JOB_SPECIALTIES.find(
      (s) => s.code === jobSpecialtyId
    );
    setSpecialty(foundSpecialty);
  }, [JOB_SPECIALTIES, jobSpecialtyId]);

  if (
    specialty ||
    firstName ||
    lastName ||
    experience ||
    metAt ||
    (requestTypes && requestTypes?.length > 0) ||
    resume?.data
  ) {
    const fullName = `${firstName} ${lastName}`.trim() || '-';
    const specialtyDescription = getLocalizedDescription(
      specialty?.descriptions,
      i18n.language
    );

    return (
      <div className="flex items-center gap-s-32">
        <div className="hidden flex-2 sm:flex sm:items-center">
          {metAt && (
            <Tooltip
              message={t('labels.certifiedResume', {
                date: new Date(metAt),
              })}
            >
              <i className="ri-verified-badge-fill text-18" />
            </Tooltip>
          )}

          {specialtyDescription && (
            <TooltipEllipsis toolTip={specialtyDescription} lineClamp={1}>
              <span>{specialtyDescription}</span>
            </TooltipEllipsis>
          )}

          {!metAt && !specialtyDescription && <span>-</span>}
        </div>
        <div className="flex-2 flex items-center">
          <TooltipEllipsis toolTip={fullName || '-'} lineClamp={1}>
            <span>{fullName || '-'}</span>
          </TooltipEllipsis>
        </div>
        <div className="hidden flex-1 md:block">
          {experience
            ? t('summary.experienceYears', { count: Number(experience) })
            : '-'}
        </div>
        <div className="flex-1">
          {requestTypes?.length ? (
            <RequestTypeBadges types={[...requestTypes]} />
          ) : (
            '-'
          )}
        </div>
        <div className="hidden flex-2 lg:block">
          {resume?.data ? t('summary.cvAdded') : '-'}
        </div>
      </div>
    );
  }

  return <div>{t('placeholders.addingNewCandidate')}</div>;
};

export default CandidateSummary;
