import { gql } from 'urql';

const RequestTemporaryPlacementConditionsFragment = gql`
  fragment RequestTemporaryPlacementConditionsFragment on RequestTemporaryPlacementConditions {
    possibleBuybackValue
    possibleBuybackBonus
    possibleBuybackDisplay
    risksValue
    risksDisplay
    travelExpensesResponsibilityValue
    trainingExpensesResponsibilityValue
    employeeHelpProgramResponsibilityValue
    cnesstExpensesResponsibilityValue
    otherResponsibilitiesValue
    responsibilitiesDisplay
    foreignApplicantsValue
    foreignApplicantsDisplay
  }
`;

export default RequestTemporaryPlacementConditionsFragment;
