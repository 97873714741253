import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import {
  CandidateRequest,
  CandidateRequestResponse,
} from '@domain/graphql.types';

type ToViewModel = {
  candidateRequest: {
    data: CandidateRequestResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateRequest: { data, fetching, error },
}: ToViewModel): ViewModel<CandidateRequest | undefined> =>
  new ViewModel<CandidateRequest | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.candidateRequest || undefined,
  });
