import { useTranslation } from 'react-i18next';

import { CandidateNegotiationRoom } from '@domain/graphql.types';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type CandidateNegotiatingOfferTableProps = {
  room: CandidateNegotiationRoom;
};

const CandidateNegotiatingOfferTable = ({
  room,
}: CandidateNegotiatingOfferTableProps) => {
  const { t } = useTranslation('candidates');

  return (
    <table className="w-full">
      <caption className="sr-only">{t('labels.underNegotiation')}</caption>
      <TableHeader />
      <TableBody room={room} />
    </table>
  );
};

export default CandidateNegotiatingOfferTable;
