import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import { Link, Tooltip } from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { PrivatePage } from '@application/enums/pagesUrl';
import { getOfferCandidateName } from '@application/views/recruitment/request/candidate/OfferCandidateAnonymization';
import { OfferCandidate, OfferCandidatesFilters } from '@domain/graphql.types';

import { CandidateFeedback } from '../components';
import useGetOfferCandidates from './useGetOfferCandidates';

const columnIds = {
  name: 'name',
  experience: 'experience',
  cv: 'cv',
  notes: 'notes',
  actions: 'actions',
} as const;

const columnHelper = createColumnHelper<OfferCandidate>();

const getColumns = (t: TFunction<'translation', undefined>) => [
  columnHelper.accessor(
    (row, index) => (
      <Link
        from={useLocation().pathname}
        to={generatePath(PrivatePage.OFFER_CANDIDATE_DETAILS, {
          candidateId: row.id,
        })}
      >
        {getOfferCandidateName(
          row,
          t('list.candidatePlaceHolder', { index: index + 1 })
        )}
      </Link>
    ),
    {
      id: columnIds.name,
      cell: (info) => (
        <div className="flex gap-s-8 items-center">
          {info.row.original.metAt && (
            <Tooltip
              message={t('certifiedResume', {
                date: new Date(info.row.original.metAt),
              })}
            >
              <i className="ri-verified-badge-fill text-18" />
            </Tooltip>
          )}

          <span>{info.getValue() || '-'}</span>
        </div>
      ),
      header: () => t('list.candidate'),
      size: 300,
      minSize: 300,
      maxSize: undefined,
    }
  ),
  columnHelper.accessor((row) => row.experience, {
    id: columnIds.experience,
    cell: (info) => (
      <span>
        {info.getValue() !== undefined
          ? t('list.experienceYears', { count: info.getValue() as number })
          : '-'}
      </span>
    ),
    header: () => t('list.experience'),
    size: 180,
    minSize: 100,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.cvUrl, {
    id: columnIds.cv,
    cell: (info) => {
      const cvUrl = info.getValue();

      return cvUrl ? (
        <a
          href={cvUrl}
          target="_blank"
          className="underline text-16 font-semibold"
          rel="noreferrer"
        >
          {t('list.cvButton')}
        </a>
      ) : (
        <span>-</span>
      );
    },
    header: () => t('list.cv'),
    size: 180,
    minSize: 120,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.notes, {
    id: columnIds.notes,
    cell: (info) => (
      <TooltipEllipsis toolTip={info.getValue() || '-'} lineClamp={2}>
        <div className="text-left">{info.getValue() || '-'}</div>
      </TooltipEllipsis>
    ),
    header: () => t('list.notes'),
    size: 180,
    minSize: 180,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row, {
    id: columnIds.actions,
    cell: (info) => (
      <div className="flex gap-s-16 my-s-16">
        <CandidateFeedback
          offerCandidateId={info.getValue().id}
          feedback={info.getValue().feedback}
        />
      </div>
    ),
    header: () => t('list.feedback'),
    size: 180,
    minSize: 180,
    maxSize: undefined,
  }),
];

type UseAccountPreferredSpecialtiesListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<OfferCandidate>[];
  isLoading: boolean;
  offerCandidates: OfferCandidate[];
};

export const useOfferCandidatesList = (
  filters: OfferCandidatesFilters
): UseAccountPreferredSpecialtiesListProps => {
  const { t } = useTranslation('offers', {
    keyPrefix: 'candidates',
  });

  const {
    viewModel: { data, isLoading },
  } = useGetOfferCandidates(filters);

  const columns = getColumns(t);

  return {
    columnIds,
    columns: columns as ColumnDef<OfferCandidate>[],
    isLoading,
    offerCandidates: data,
  };
};
