import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

import { OfferFormFields } from '../types';

type ToViewModel = {
  offer: {
    data: Pick<Mutation, 'offerCreate'> | Record<string, never> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  offer: { data = {}, fetching, error },
}: ToViewModel): ViewModel<OfferFormFields> =>
  new ViewModel<OfferFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data as unknown as OfferFormFields,
  });
