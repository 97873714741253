import { FocusEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectField } from '../../select-field';
import { OptionType } from '../../select-field/select';
import useFetchPredictions, { Prediction } from './useFetchPredictions';
import { normalize } from './utils';

type AddressFieldProps = {
  /**
   * Specify the name of the `<select>`
   */
  name: string;
  /**
   * Specify whether the `<select>` should be invalid
   */
  invalid: boolean;
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  label: string;
  /**
   * Specify the value of the `<select>`
   *
   * IMPORTANT: the value needs to be the same type as one of the options.
   * https://github.com/JedWatson/react-select/issues/5031#issuecomment-1114823718
   */
  value: string;
  /**
   * Provide the text that is used alongside the `<input>` for additional help
   * or as a validation message on input error
   */
  helperText?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever `<select>` is updated
   */
  onChange?: (option: any) => void | undefined;
  /**
   * Optionally provide an `onBlur` handler that is called whenever `<select>` loses focus
   */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /**
   * Provide a custom className to be applied to the `<select>`
   */
  className?: string;
};

const AddressAutocomplete = ({
  name,
  label,
  value,
  invalid,
  helperText,
  onChange,
  onBlur,
  className: customClassName,
}: AddressFieldProps) => {
  const [loadOptions, setLoadOptions] = useState<
    (inputValue: string, callback: (options: OptionType[]) => any) => void
  >(() => () => {});

  const { t } = useTranslation();
  const { isReady, getAddressPredictions } = useFetchPredictions();

  useEffect(() => {
    if (!isReady) {
      return undefined;
    }

    let ignore = false;

    setLoadOptions(
      () => (inputValue: string, callback: (options: OptionType[]) => void) => {
        getAddressPredictions(
          {
            input: inputValue || value,
            componentRestrictions: { country: ['ca', 'us'] },
          },
          (predictions: readonly Prediction[]) => {
            if (ignore) {
              return;
            }

            const newOptions: OptionType[] = normalize(predictions);

            callback(newOptions);
          }
        );
      }
    );

    return () => {
      ignore = true;
    };
  }, [getAddressPredictions, isReady, value]);

  return (
    <SelectField
      className={customClassName}
      name={name}
      label={label || t('select.address')}
      value={value ? { value, label: value } : null}
      loadOptions={loadOptions}
      placeholder={t('select.startTypingAddress')}
      renderNoOptionsContent={t('select.noAddressFound')}
      renderLoadingContent={t('select.states.loading.searching')}
      onChange={onChange}
      onBlur={onBlur}
      invalid={invalid}
      helperText={helperText}
      clearable
      searchable
      hideDropdownIndicator
      hideNoOptions
      smallMenu
    />
  );
};

export default AddressAutocomplete;
