import { useQuery } from 'urql';

import {
  Query,
  QueryAccountPreferredSpecialtiesArgs,
} from '@domain/graphql.types';
import { AccountPreferredSpecialties } from '@infrastructure/graphql/account';
import {
  defaultPageData,
  defaultPageInfo,
} from '@infrastructure/graphql/pagination';
import { flattenEdges } from '@utils/data-utils';

const useAccountPreferredSpecialties = (
  input?: QueryAccountPreferredSpecialtiesArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'accountPreferredSpecialties'>,
    QueryAccountPreferredSpecialtiesArgs
  >({
    query: AccountPreferredSpecialties,
    variables: input,
  });

  const { data: paginatedData, fetching, error } = result;

  const pageData =
    paginatedData?.accountPreferredSpecialties.pageData || defaultPageData;
  const pageInfo =
    paginatedData?.accountPreferredSpecialties.page.pageInfo || defaultPageInfo;
  const data = flattenEdges(
    paginatedData?.accountPreferredSpecialties.page.edges?.slice() || []
  );

  return {
    data,
    pageData,
    pageInfo,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useAccountPreferredSpecialties;
