import { useContext } from 'react';

import { UserContext } from '@application/context';
import { UserRole } from '@domain/graphql.types';

type AuthorizedUserRolesProps = {
  authorizedUserRoles?: UserRole[] | null;
};

export const useIsUserRoleAuthorized = ({
  authorizedUserRoles,
}: AuthorizedUserRolesProps) => {
  const { accountUser } = useContext(UserContext);
  return (
    !!accountUser?.role && !!authorizedUserRoles?.includes(accountUser.role)
  );
};

export default useIsUserRoleAuthorized;
