import { useTranslation } from 'react-i18next';

import { SectionGridRow, VisibilitySwitcher } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Offer } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type HeadhunterProps = {
  data: Offer | undefined;
};

const HeadhunterConditions = ({ data }: HeadhunterProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });

  const recruitmentOpportunity = data?.recruitmentOpportunity;
  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.headhunterContractualConditions;
  const conditions = data?.headhunterContractualConditions;

  const wrapperClassName = 'flex flex-wrap items-center gap-s-8';
  const valueTextClassName = 'text-18 font-semibold text-primary';

  return (
    <>
      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={
                  recruitmentOpportunityConditions?.headhuntingExclusivityPeriodValue
                }
              >
                <span className={cn(valueTextClassName, 'text-16')}>
                  {t('suffix.week', {
                    count: Number(
                      recruitmentOpportunityConditions?.headhuntingExclusivityPeriodValue
                    ),
                  })}
                </span>
              </PlaceholderSwitcher>
            }
            visible={
              !!recruitmentOpportunityConditions?.headhuntingExclusivityPeriodDisplay
            }
          />
        }
      >
        <span className={wrapperClassName}>
          <span>{t(`labels.exclusivityPeriod`)}</span>

          <PlaceholderSwitcher value={conditions?.headhuntingExclusivityPeriod}>
            <span className={valueTextClassName}>
              {t('suffix.week', {
                count: Number(conditions?.headhuntingExclusivityPeriod),
              })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={
                  recruitmentOpportunityConditions?.maximumReplacementValue
                }
              >
                <span className={cn(valueTextClassName, 'text-16')}>
                  {recruitmentOpportunityConditions?.maximumReplacementValue}
                </span>
              </PlaceholderSwitcher>
            }
            visible={
              !!recruitmentOpportunityConditions?.maximumReplacementDisplay
            }
          />
        }
      >
        <span className={wrapperClassName}>
          <span>{t(`labels.maxReplacements`)}</span>

          <PlaceholderSwitcher value={conditions?.maximumReplacement}>
            <span className={valueTextClassName}>
              {conditions?.maximumReplacement}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>
    </>
  );
};

export default HeadhunterConditions;
