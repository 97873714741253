import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { UserContext } from '@application/context';

import { useSendPasswordChangeRequest } from '../hooks';

const ChangePassword = () => {
  const { t } = useTranslation('user');

  const {
    viewModel: { isLoading },
    sendPasswordChangeRequest,
  } = useSendPasswordChangeRequest();

  const { user } = useContext(UserContext);

  const changePassword = useCallback(() => {
    if (!user?.email) {
      return;
    }

    sendPasswordChangeRequest({
      input: {
        email: user?.email,
      },
    });
  }, [sendPasswordChangeRequest, user?.email]);

  return (
    <section className="flex justify-between">
      <h2 className="self-center h4">{t('subtitles.changePassword')}</h2>

      <Button primary loading={isLoading} onClick={changePassword}>
        {t('button.changePassword')}
      </Button>
    </section>
  );
};

export default ChangePassword;
