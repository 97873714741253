import { gql } from '@urql/core';

import SubscriptionBillingPortalLinkFragment from './subscriptionBillingPortalLink.fragment';

const GetSubscriptionLink = gql`
  ${SubscriptionBillingPortalLinkFragment}

  mutation GetSubscriptionBillingPortalLink(
    $input: GetSubscriptionBillingPortalLinkInput!
  ) {
    getSubscriptionBillingPortalLink(input: $input) {
      ...SubscriptionBillingPortalLinkFragment
    }
  }
`;

export default GetSubscriptionLink;
