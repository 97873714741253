import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { JobSpecialty } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

const useLocalizedSpecialty = (data: JobSpecialty | undefined | null) => {
  const { i18n } = useTranslation();

  const specialty = useMemo(
    () => getLocalizedDescription(data?.descriptions, i18n.language),
    [data, i18n.language]
  );

  return specialty;
};

export default useLocalizedSpecialty;
