import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { User } from '@domain/graphql.types';

import { AlertContext } from '../../../context';
import useVerifyEmail from './useVerifyEmail';

const ONLY_ONCE_MESSAGE = 'This URL can be used only once';

export type UseEmailVerificationProps = {
  user: User | undefined;
};

const useEmailVerification = ({ user }: UseEmailVerificationProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'messages' });

  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const message = searchParams.get('message');

  const { setAlertContent } = useContext(AlertContext);

  const { verifyEmail } = useVerifyEmail();

  useEffect(() => {
    if (message === ONLY_ONCE_MESSAGE) {
      setAlertContent({
        text: t('linkAlreadyUsed'),
        modifier: 'alert-warning',
      });
    }

    if (code && ['already-verified', 'success'].includes(code) && user?.id) {
      verifyEmail({
        input: {
          userId: user.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, user?.id, message, setAlertContent, verifyEmail]);
};

export default useEmailVerification;
