import { useMutation } from 'urql';

import { MutationSaveAndPublishRequest } from '@infrastructure/graphql/request';

const useRequestSaveAndPublish = () => {
  const [{ data, fetching, error }, saveAndPublish] = useMutation(
    MutationSaveAndPublishRequest
  );

  return {
    saveAndPublish,
    data,
    fetching,
    error,
  };
};

export default useRequestSaveAndPublish;
