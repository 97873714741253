import { gql } from '@urql/core';

import PublicAccountFragment from './public.account.fragment';

const PublicAccount = gql`
  ${PublicAccountFragment}

  query PublicAccount($input: PublicAccountInput!) {
    publicAccount(input: $input) {
      account {
        ...PublicAccountFragment
      }
    }
  }
`;

export default PublicAccount;
