import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { File } from '@application/components/file';
import { SummaryList } from '@application/components/summary';
import SummaryBlock, {
  SummaryBlockProps,
} from '@application/components/summary/SummaryBlock';
import { SummaryListProps } from '@application/components/summary/SummaryList';
import { OfferCandidate, RequestTypeCode } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { getOfferCandidateName } from '../OfferCandidateAnonymization';

type GeneralInformationProps = {
  offerCandidate: OfferCandidate | undefined;
};

const GeneralInformation = ({ offerCandidate }: GeneralInformationProps) => {
  const { t, i18n } = useTranslation('candidates', {
    keyPrefix: 'candidateView',
  });

  const { t: TGlobal } = useTranslation();

  const summaryBlockData: Omit<SummaryBlockProps, 'children'> = useMemo(
    () => ({
      headerTitle: t('subtitles.description'),
      title: getLocalizedDescription(
        offerCandidate?.jobSpecialty?.descriptions,
        i18n.language
      ),
      subtitle: offerCandidate?.specialty || undefined,
      requestTypesBadge: offerCandidate?.requestTypes?.length
        ? (offerCandidate.requestTypes as RequestTypeCode[])
        : undefined,
    }),
    [offerCandidate, t, i18n.language]
  );

  const summaryListCandidateInfoData: SummaryListProps = useMemo(
    () => ({
      data: [
        {
          text: t('labels.name'),
          description: offerCandidate
            ? getOfferCandidateName(offerCandidate)
            : '-',
        },
        {
          text: t('labels.email'),
          description:
            offerCandidate?.emailDisplay && offerCandidate?.email
              ? offerCandidate?.email
              : '-',
        },
        {
          text: t('labels.operationTerritory'),
          badges:
            offerCandidate?.operationTerritories?.map(
              (territory) =>
                getLocalizedDescription(
                  territory.descriptions,
                  i18n.language
                ) || '-'
            ) || [],
        },
        {
          text: t('labels.citiesSubRegions'),
          badges:
            offerCandidate?.citiesSubRegions?.map((subRegion) => subRegion) ||
            [],
        },
        {
          text: t('labels.educationLevel'),
          description: offerCandidate?.educationLevel
            ? getLocalizedDescription(
                offerCandidate?.educationLevel.descriptions,
                i18n.language
              ) || '-'
            : '-',
        },
        {
          text: t('labels.studyField'),
          description: offerCandidate?.studyField
            ? offerCandidate.studyField
            : '-',
        },
        {
          text: t('labels.experience'),
          description: offerCandidate?.experience
            ? `${offerCandidate.experience} ${t('suffix.years')}`
            : '-',
        },
      ],
    }),
    [offerCandidate, t, i18n.language]
  );

  const summaryListCandidateMoreInfoData: SummaryListProps = useMemo(
    () => ({
      data: [
        {
          text: t('labels.metAt'),
          description: offerCandidate?.metAt
            ? formatDate(offerCandidate.metAt, i18n.language)
            : '-',
        },
        {
          text: t('labels.canWorkInCanadaOrQuebec'),
          description: offerCandidate?.canWorkInCanadaOrQuebec
            ? TGlobal('boolean.true')
            : TGlobal('boolean.false'),
        },
      ],
    }),
    [offerCandidate, t, TGlobal, i18n.language]
  );

  return (
    <SummaryBlock {...summaryBlockData}>
      <div>
        <SummaryList
          className="mt-s-24"
          density="comfortable"
          {...summaryListCandidateInfoData}
        />

        <hr className="my-s-24" />

        {(offerCandidate?.cvUrl || !!offerCandidate?.otherFilesUrls) && (
          <>
            <div className="mb-s-16">
              <span className="text-16 font-normal text-neutral-secondary">
                {t('labels.documents')}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-y-s-16">
              {offerCandidate?.cvUrl && (
                <File
                  link={offerCandidate.cvUrl}
                  text={t('labels.curriculumVitae')}
                />
              )}
              {offerCandidate?.otherFilesUrls?.map((otherFile) => (
                <File
                  key={otherFile.link}
                  link={otherFile.link}
                  text={
                    otherFile.fileName.split('/').pop()?.split('.')[0] || '-'
                  }
                />
              ))}
            </div>
            <hr className="my-s-24" />
          </>
        )}

        <SummaryList
          className="mt-s-24"
          density="comfortable"
          {...summaryListCandidateMoreInfoData}
        />
      </div>
    </SummaryBlock>
  );
};

export default GeneralInformation;
