import { useTranslation } from 'react-i18next';

import { OfferConditionPaymentMethodCode } from '@domain/graphql.types';

const useOfferPaymentMethodOptions = () => {
  const { t } = useTranslation();

  return Object.values(OfferConditionPaymentMethodCode).map((method) => ({
    value: method,
    label: {
      long: t(`enum.offerConditionPaymentMethodCode.${method.toLowerCase()}`),
      short: t(
        `enum.offerConditionPaymentMethodCode.short.${method.toLowerCase()}`
      ),
    },
  }));
};

export default useOfferPaymentMethodOptions;
