import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const JobPaymentFrequencies = gql`
  ${DescriptionsFragment}

  query JobPaymentFrequencies {
    jobPaymentFrequencies {
      values {
        descriptions {
          ...DescriptionsFragment
        }
        code
      }
    }
  }
`;

export default JobPaymentFrequencies;
