import * as yup from 'yup';

export const INVITATION_SCHEMA = yup.object({
  email: yup
    .string()
    .email('forms.rules.emailValid')
    .max(128)
    .required('forms.rules.required'),
  upgradeSubscription: yup.boolean(),
});

export type InvitationFormFields = yup.InferType<typeof INVITATION_SCHEMA>;
