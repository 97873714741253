import * as yup from 'yup';

export const NOTIFICATIONS_CONFIGURATION_SCHEMA = yup.object({
  inApp: yup.boolean().required(),
  email: yup.boolean().required(),
  none: yup.boolean(),
});

export type NotificationsConfigurationFormFields = yup.InferType<
  typeof NOTIFICATIONS_CONFIGURATION_SCHEMA
>;
