import { gql } from 'urql';

const CandidateRequestOutsourcingConditionsFragment = gql`
  fragment CandidateRequestOutsourcingConditionsFragment on CandidateRequestOutsourcingConditions {
    minimumNoticeValue
    minimumNoticeDisplay
    billingPeriodValue
    billingPeriodDisplay
  }
`;

export default CandidateRequestOutsourcingConditionsFragment;
