import { gql } from '@urql/core';

import OfferFragment from './offer.fragment';

const Offer = gql`
  ${OfferFragment}

  query Offer($input: GetOfferInput!) {
    offer(input: $input) {
      offer {
        ...OfferFragment
      }
    }
  }
`;

export default Offer;
