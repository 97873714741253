import { useTranslation } from 'react-i18next';

import {
  PlaceholderSwitcher,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';

type HeadhunterConditionsProps = {
  data: RecruitmentOpportunity | undefined;
};

const HeadhunterConditions = ({ data }: HeadhunterConditionsProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });

  return (
    <>
      <SectionGridRow isTopRow>
        <span className="mr-s-8">{t('labels.maxReplacements')}</span>

        <VisibilitySwitcher
          visible={
            data?.headhunterContractualConditions?.maximumReplacementDisplay ||
            false
          }
          content={
            <PlaceholderSwitcher
              value={
                data?.headhunterContractualConditions?.maximumReplacementValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {data?.headhunterContractualConditions?.maximumReplacementValue}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.exclusivityPeriod')}</span>

        <VisibilitySwitcher
          visible={
            data?.headhunterContractualConditions
              ?.headhuntingExclusivityPeriodDisplay || false
          }
          content={
            <PlaceholderSwitcher
              value={
                data?.headhunterContractualConditions
                  ?.headhuntingExclusivityPeriodValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {
                  data?.headhunterContractualConditions
                    ?.headhuntingExclusivityPeriodValue
                }
                &nbsp;{t('suffix.weeks')}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>
    </>
  );
};

export default HeadhunterConditions;
