import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreatableSelectField } from '@application/components';
import { OptionType } from '@application/components/select-field/select';

import { Section } from '../../components';
import { CandidateRequestFormFields } from '../../schema';

const SoftSkillsFields = () => {
  const { t } = useTranslation('candidates');

  const {
    control,
    watch,
    setValue,
  }: UseFormReturn<CandidateRequestFormFields> = useFormContext();

  const softSkills = watch('softSkills');

  return (
    <Section legend={t('subtitles.softSkills')}>
      <Controller
        name="softSkills"
        control={control}
        render={({ field: { name } }) => (
          <CreatableSelectField
            name={name}
            label={t('labels.softSkills')}
            value={
              softSkills?.map((s) => ({
                label: s,
                value: s,
              })) as OptionType[]
            }
            className="flex-1 min-w-fit"
            onChange={(options: any) => {
              setValue(
                name,
                options.map((option: any) => option.value)
              );
            }}
          />
        )}
      />
    </Section>
  );
};

export default SoftSkillsFields;
