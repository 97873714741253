import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const AccountAdvantageTypes = gql`
  ${DescriptionsFragment}

  query AccountAdvantageTypes {
    accountAdvantageTypes {
      values {
        descriptions {
          ...DescriptionsFragment
        }
        code
      }
    }
  }
`;

export default AccountAdvantageTypes;
