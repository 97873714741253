import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react';
import { useCallback, useState } from 'react';

import { COLORS } from '@tailwind/theme';

type RangeInputProps = {
  id: string;
  /**
   * Specify the name of the min slider `<input>`
   */
  minName: string;
  /**
   * Specify the name of the max slider `<input>`
   */
  maxName: string;
  /**
   * Specify the value of the min slider `<input>`
   */
  minValue?: number | undefined;
  /**
   * Specify the value of the max slider `<input>`
   */
  maxValue?: number | undefined;
  /**
   * Specify the min value of the `<input>`
   */
  min?: number | undefined;
  /**
   * Specify the max value of the `<input>`
   */
  max?: number | undefined;
  /**
   * Specify whether the `<input>` should be disabled
   */
  disabled?: boolean;
  /**
   * Provide a suffix to be added to the minCaption and maxCaption state
   */
  suffix?: string;
  /**
   * Specify what it the step for the input type number
   */
  step?: number;
  onInput?: (event: ChangeResult) => void;
  /**
   * Optionally provide an `onChange` handler that is called whenever the
   * slider `<input>` is updated
   */
  onChange?: (name: string, value: number) => void;
};

const RangeInput = ({
  id,
  minName,
  maxName,
  minValue,
  maxValue,
  min,
  max,
  disabled,
  suffix,
  step,
  onInput,
  onChange,
}: RangeInputProps) => {
  const [minCaption, setMinCaption] = useState<string | undefined>();
  const [maxCaption, setMaxCaption] = useState<string | undefined>();

  const formatValue = useCallback(
    (value: number) => `$${value}${suffix}`,
    [suffix]
  );

  const handleInput = useCallback(
    (e: ChangeResult) => {
      setMaxCaption(formatValue(e.maxValue));
      setMinCaption(formatValue(e.minValue));

      if (onInput) {
        onInput(e);
      }
    },
    [formatValue, onInput]
  );

  const handleChange = useCallback(
    (e: ChangeResult) => {
      if (onChange) {
        onChange(minName, e.minValue);
        onChange(maxName, e.maxValue);
      }
    },
    [maxName, minName, onChange]
  );

  const baseProps = {
    id,
    min,
    max,
    step: step || 5,
    minValue,
    maxValue,
    onInput: handleInput,
    onChange: handleChange,
    disabled,
    ruler: false,
    label: false,
    minCaption,
    maxCaption,
  };

  const customStyles = {
    className: 'range-input',
    barLeftColor: COLORS.neutral,
    barRightColor: COLORS.neutral,
    barInnerColor: COLORS.accent,
    thumbLeftColor: COLORS['base-100'],
    thumbRightColor: COLORS['base-100'],
  };

  return (
    <div className="w-full">
      <MultiRangeSlider {...baseProps} {...customStyles} />
    </div>
  );
};

export default RangeInput;
