import { ChangeEvent, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

import { OptionType } from '@application/components/select-field/select';
import { debounce } from '@application/utils';
import { RecruitmentOpportunitiesFilters } from '@domain/graphql.types';

const defaultFilters = {
  operationTerritoryCode: undefined,
};

type UseRecruitmentFiltersProps = {
  resetPagination?: () => void;
};

const useRecruitmentFilters = ({
  resetPagination,
}: UseRecruitmentFiltersProps) => {
  const [filters, setFilters] =
    useState<RecruitmentOpportunitiesFilters>(defaultFilters);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateTextFilter = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }));
  });

  const updateSelectFilter =
    (fieldName: keyof RecruitmentOpportunitiesFilters) =>
    (value: SingleValue<OptionType>) => {
      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: value?.value,
      }));
    };

  return {
    filters,
    updateSelectFilter,
    updateTextFilter,
  };
};

export default useRecruitmentFilters;
