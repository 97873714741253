import { ButtonLink } from '../links';

type NumericBadgeProps = {
  value?: number;
  placeholder?: string;
  to?: string;
};

const NumericBadge = ({ value, placeholder = '-', to }: NumericBadgeProps) =>
  to ? (
    <ButtonLink
      className="h-s-32 w-s-32 p-s-0 min-h-[2rem] min-w-[2rem] lg:min-w-[2rem] rounded-sm"
      to={to}
    >
      {value || placeholder}
    </ButtonLink>
  ) : (
    <span className="block w-s-32 text-center">{value || placeholder}</span>
  );

export default NumericBadge;
