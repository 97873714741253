import { useQuery } from 'urql';

import {
  Query,
  QueryCandidateNegotiationRoomsArgs,
} from '@domain/graphql.types';
import { CandidateNegotiationRooms } from '@infrastructure/graphql/candidate';

const useCandidateNegotiationRooms = (
  input: QueryCandidateNegotiationRoomsArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateNegotiationRooms'>,
    QueryCandidateNegotiationRoomsArgs
  >({
    query: CandidateNegotiationRooms,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateNegotiationRooms;
