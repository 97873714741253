import { gql } from '@urql/core';

const MutationRecruitmentNegotiationComplete = gql`
  mutation RecruitmentNegotiationComplete(
    $input: RecruitmentNegotiationCompleteInput!
  ) {
    recruitmentNegotiationComplete(input: $input) {
      negotiation {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationComplete;
