import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';

import { PageHeader, PageLoader } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';

import { CandidateFeedback } from '../../offer/candidate/components';
import { useGetOfferCandidate } from '../../offer/candidate/hooks';
import { getOfferCandidateName } from './OfferCandidateAnonymization';
import GeneralInformation from './summary/GeneralInformation';
import Remuneration from './summary/Remuneration';
import SoftSkills from './summary/SoftSkills';

const OfferCandidateDetailsPage = () => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'candidateView',
  });

  const navigate = useNavigate();

  const { candidateId = '' } = useParams();

  const {
    viewModel: { data: offerCandidate, isLoading },
  } = useGetOfferCandidate(candidateId);

  const location = useLocation();
  const fromState = location.state?.from;

  useEffect(() => {
    if (!(candidateId && isUUID(candidateId))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [navigate, candidateId]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        className="relative"
        titleAs="h1"
        title={
          <div className="flex justify-between items-center w-full">
            <div>
              {offerCandidate &&
                t('title', { name: getOfferCandidateName(offerCandidate) })}
            </div>
            {offerCandidate && (
              <div className="flex gap-s-16 absolute right-s-0">
                <CandidateFeedback
                  offerCandidateId={offerCandidate.id}
                  feedback={offerCandidate.feedback}
                />
              </div>
            )}
          </div>
        }
        backTo={fromState.from || RootPrivatePage.CANDIDATE_OFFERS}
      />

      <GeneralInformation offerCandidate={offerCandidate} />
      <Remuneration offerCandidate={offerCandidate} />
      <SoftSkills offerCandidate={offerCandidate} />
    </>
  );
};

export default OfferCandidateDetailsPage;
