import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxGroup } from '@application/components';
import { Box } from '@application/components/container-layouts';
import { useGetPerks } from '@application/views/organization/profile/hooks';
import { extractLanguage } from '@utils/i18n-utils';

import { CandidateOfferFormFields } from '../../schema';

type PerksFieldsProps = {
  control: Control<CandidateOfferFormFields, any>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const PerksFields = ({ control, setValue, watch }: PerksFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');

  const { data: PERKS_LIST = [] } = useGetPerks();

  const perks = watch('perks') || [];

  const handleOnChange = (event: any): void => {
    if (event.target.checked) {
      setValue('perks', [...perks, event.target.value]);
    } else {
      setValue('perks', [...perks.filter((p) => p !== event.target.value)]);
    }
  };

  return (
    <Box>
      <Controller
        name="perks"
        control={control}
        render={({ field: { value } }) => (
          <CheckboxGroup
            data={PERKS_LIST}
            legend={t('labels.perksMultiple')}
            language={extractLanguage(i18n.language)}
            onChange={handleOnChange}
            legendSize="medium"
            values={value}
          />
        )}
      />
    </Box>
  );
};

export default PerksFields;
