import { useMutation } from 'urql';

import {
  Mutation,
  MutationOfferCandidateDeleteCvArgs,
} from '@domain/graphql.types';
import { MutationOfferCandidateDeleteCV } from '@infrastructure/graphql/offer';

const useOfferCandidateDeleteCV = () => {
  const [{ data, fetching, error }, deleteOfferCandidateCV] = useMutation<
    Pick<Mutation, 'offerCandidateDeleteCV'>,
    MutationOfferCandidateDeleteCvArgs
  >(MutationOfferCandidateDeleteCV);

  return {
    deleteOfferCandidateCV,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateDeleteCV;
