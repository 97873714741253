import { useMutation } from 'urql';

import { Mutation } from '@domain/graphql.types';
import { AccountDeleteLogo } from '@infrastructure/graphql/account';

const useAccountDeleteLogo = () => {
  const [{ data, fetching, error }, deleteAccountLogo] = useMutation<
    Pick<Mutation, 'accountDeleteLogo'>,
    void
  >(AccountDeleteLogo);

  return {
    deleteAccountLogo,
    data,
    fetching,
    error,
  };
};

export default useAccountDeleteLogo;
