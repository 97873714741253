import { gql } from '@urql/core';

const MutationCandidateOfferUpdate = gql`
  mutation CandidateOfferUpdate($input: CandidateOfferUpdateInput!) {
    candidateOfferUpdate(input: $input) {
      candidateOffer {
        id
      }
    }
  }
`;

export default MutationCandidateOfferUpdate;
