import { UserRoleGuard } from '@application/components/guards';
import { UserRole } from '@domain/graphql.types';

import { withInvitationsListProvider } from './context';
import { InvitationsList, UsersList } from './list';

const MembersRolesPanel = () => (
  <div aria-labelledby="tab-members" role="tabpanel">
    <UsersList />

    <UserRoleGuard
      authorizedUserRoles={[
        UserRole.SuperAdmin,
        UserRole.Owner,
        UserRole.Admin,
      ]}
    >
      <hr className="my-s-40" />
      <InvitationsList />
    </UserRoleGuard>
  </div>
);

export const WrapperMembersRolesPanel =
  withInvitationsListProvider(MembersRolesPanel);
