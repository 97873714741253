import { useMutation } from 'urql';

import { Mutation } from '@domain/graphql.types';
import { UserUpdateProfile } from '@infrastructure/graphql/user';

const useUserUpdateProfile = () => {
  const [{ data, fetching, error }, userUpdateProfile] =
    useMutation<Pick<Mutation, 'userUpdateProfile'>>(UserUpdateProfile);

  return {
    userUpdateProfile,
    data,
    fetching,
    error,
  };
};

export default useUserUpdateProfile;
