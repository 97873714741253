import * as yup from 'yup';

export const UPDATE_EMAIL_SCHEMA = yup.object({
  email: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .email('validations.emailValid'),
});
