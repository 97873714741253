import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  RadioGroup,
  TextAreaField,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { OfferFormFields } from '@application/views/recruitment/offer/types';
import { extractLanguage } from '@utils/i18n-utils';
import { isDefined } from '@utils/type-utils';

type MoreInformationFieldsProps = {
  index: number;
};

const MoreInformationFields = ({ index }: MoreInformationFieldsProps) => {
  const [candidateMet, setCandidateMet] = useState<boolean | undefined>(
    undefined
  );

  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });

  const { control, register, watch, setValue }: UseFormReturn<OfferFormFields> =
    useFormContext();

  const metAt = watch(`offerCandidates.${index}.metAt`);

  const canWorkInCanadaOrQuebec = watch(
    `offerCandidates.${index}.canWorkInCanadaOrQuebec`
  );

  useEffect(() => {
    if (candidateMet === undefined && isDefined(metAt)) {
      setCandidateMet(true);
    }
  }, [candidateMet, metAt]);

  return (
    <>
      <Controller
        name={`offerCandidates.${index}.notes`}
        control={control}
        render={({
          field: { onChange, name, value },
          fieldState: { error },
        }) => (
          <TextAreaField
            name={name}
            value={value || ''}
            label={t('labels.notes')}
            rows={5}
            maxChar={500}
            invalid={!!error}
            helperText={
              error?.message &&
              t(error?.message as string, {
                max: '1024',
              })
            }
            onChange={onChange}
          />
        )}
      />

      <RadioGroup
        name={`offerCandidates.${index}.isCandidateMet`}
        data={BOOLEAN_VALUES}
        legend={t('labels.candidateMet')}
        language={extractLanguage(i18n.language)}
        legendSize="medium"
        alignRow
        value={candidateMet}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setCandidateMet(event.target.value === 'true');
          if (event.target.value === 'false') {
            setValue(`offerCandidates.${index}.metAt`, null);
          }
        }}
      />

      {candidateMet && (
        <FormInputWithSuffix
          label={t('labels.meetingDate')}
          type="date"
          mediumSuffix
          noMargin
          {...register(`offerCandidates.${index}.metAt`)}
        />
      )}

      <RadioGroup
        name={`offerCandidates.${index}.canWorkInCanadaOrQuebec`}
        data={BOOLEAN_VALUES}
        legend={t('labels.canWorkInCanadaOrQuebec')}
        language={extractLanguage(i18n.language)}
        legendSize="medium"
        alignRow
        value={
          isDefined(canWorkInCanadaOrQuebec)
            ? canWorkInCanadaOrQuebec
            : undefined
        }
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setValue(
            `offerCandidates.${index}.canWorkInCanadaOrQuebec`,
            event.target.value as any
          );
        }}
      />
    </>
  );
};

export default MoreInformationFields;
