import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGrid } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import { Request, RequestTypeCode } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import GeneralConditions from './GeneralConditions';
import HeadhunterConditions from './HeadhunterConditions';
import InternationalRecruitmentConditions from './InternationalRecrutmentConditions';
import OutsourcingConditions from './OutsourcingConditions';
import TemporaryPlacementConditions from './TemporaryPlacementConditions';

type ConditionsSummaryProps = {
  data: Request | undefined;
};

const ConditionsSummary = ({ data }: ConditionsSummaryProps) => {
  const { t } = useTranslation('requests');

  const conditions = useMemo(
    () => [
      {
        title: 'conditions',
        children: <GeneralConditions data={data} />,
        className: 'mt-s-16 rounded-t-md',
        visible: true,
      },
      {
        title: 'headHunter',
        children: <HeadhunterConditions data={data} />,
        visible: data?.types?.includes(RequestTypeCode.Headhunter),
      },
      {
        title: 'internationalRecruitment',
        children: <InternationalRecruitmentConditions data={data} />,
        visible: data?.types?.includes(
          RequestTypeCode.InternationalRecruitment
        ),
      },
      {
        title: 'outsourcing',
        children: <OutsourcingConditions data={data} />,
        visible: data?.types?.includes(RequestTypeCode.Outsourcing),
      },
      {
        title: 'temporaryPlacement',
        children: <TemporaryPlacementConditions data={data} />,
        visible: data?.types?.includes(RequestTypeCode.TemporaryHelp),
      },
    ],
    [data]
  );

  const filteredConditions = conditions.filter((c) => c.visible);

  return (
    <>
      {filteredConditions.map(
        (c, index) =>
          c.visible && (
            <Fragment key={c.title}>
              <SummaryHeader
                className={cn('rounded-none', c.className)}
                headerTitle={t(`subtitles.${c.title}`)}
              />

              <SectionGrid
                gridColsClassName={cn(
                  {
                    'rounded-none': !(index === filteredConditions.length - 1),
                    'border-b-0': !(index === filteredConditions.length - 1),
                  },
                  'grid-cols-[1fr_10rem] border-t-0'
                )}
                className="my-s-0"
                readOnly
                title={t(`subtitles.${c.title}`)}
                hideTitle
              >
                {c.children}
              </SectionGrid>
            </Fragment>
          )
      )}
    </>
  );
};

export default ConditionsSummary;
