import { components, PlaceholderProps } from 'react-select';

import { OptionType } from '../types';

const Placeholder = ({ children, ...props }: PlaceholderProps<OptionType>) => (
  <components.Placeholder {...props}>
    <span className="text-neutral-secondary truncate">{children}</span>
  </components.Placeholder>
);

export default Placeholder;
