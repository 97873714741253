import { extractLanguage } from '@utils/i18n-utils';

const mapOptions = (
  data: any[],
  language: string
): { value: any; label: string }[] => {
  if (!data || !data.length) return [];

  return data.map(({ value, text }: any) => ({
    value,
    label: text[extractLanguage(language)],
  }));
};

export default mapOptions;
