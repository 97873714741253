import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const IndustryTypes = gql`
  ${DescriptionsFragment}

  query IndustryTypes {
    industryTypes {
      values {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
  }
`;

export default IndustryTypes;
