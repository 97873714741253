import {
  RecruitmentNegotiation,
  RecruitmentNegotiationRoom,
  RecruitmentNegotiationRoomStatus,
} from '../../../../domain/graphql.types';
import {
  RecruitmentNegotiationAgreement,
  RecruitmentNegotiationRoomAgreement,
} from './types';

export const normalizeRecruitmentNegotiationAgreement = (
  negotiation: RecruitmentNegotiation | undefined
): RecruitmentNegotiationAgreement | undefined => {
  if (!negotiation) {
    return undefined;
  }

  const acceptedRoom = negotiation.rooms.find(
    ({ status }) => status === RecruitmentNegotiationRoomStatus.Accepted
  );

  return (
    acceptedRoom && {
      offerAccountName: acceptedRoom.offer.account.name,
      offerUserEmail: acceptedRoom.offer.user.email,
    }
  );
};

export const normalizeRecruitmentNegotiationRoomAgreement = (
  room: RecruitmentNegotiationRoom | undefined
): RecruitmentNegotiationRoomAgreement | undefined => {
  if (!room || room.status !== RecruitmentNegotiationRoomStatus.Accepted) {
    return undefined;
  }
  return {
    requestAccountName: room.request.account.name,
    requestUserEmail: room.request.user.email,
  };
};
