import { gql } from 'urql';

const SendEmailVerificationLink = gql`
  mutation SendEmailVerificationLink(
    $input: UserSendEmailVerificationLinkInput!
  ) {
    userSendEmailVerificationLink(input: $input) {
      ok
    }
  }
`;

export default SendEmailVerificationLink;
