import { useTranslation } from 'react-i18next';

import { Checkbox } from '@application/components';
import { Switch } from '@application/components/switch';
import { CandidateOffer, OfferStatusTypeCode } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type TableHeaderProps = {
  comparing?: boolean;
  candidateOffers: readonly CandidateOffer[];
  checkedCandidateOffers: string[];
  onChange?: React.FormEventHandler<HTMLLabelElement> &
    ((event: React.ChangeEvent<HTMLInputElement>) => void);
};

const TableHeader = ({
  comparing,
  candidateOffers,
  checkedCandidateOffers,
  onChange,
}: TableHeaderProps) => {
  const { t } = useTranslation('candidates');

  return (
    <thead>
      <tr>
        <th className="w-s-256 min-w-[16rem] max-w-[16rem] text-left pl-s-16 pt-s-28 h-s-72">
          {t('labels.enterpriseName')}
        </th>

        {candidateOffers.map((offer, index) => (
          <th
            key={offer.id}
            className={cn('h-s-72 border-x-2 border-t-2 border-transparent', {
              'border-primary': checkedCandidateOffers.includes(offer.id),
              '[&:last-child]:rounded-tr-md':
                checkedCandidateOffers.includes(offer.id) &&
                index === candidateOffers.length - 1,
            })}
          >
            <Switch
              fallback={
                <div className="flex flex-col">
                  <Checkbox
                    id={offer.id}
                    checked={checkedCandidateOffers.includes(offer.id)}
                    disabled={comparing}
                    onChange={onChange}
                    labelDirection="bottom"
                    label={offer.account.name}
                    name={offer.account.name}
                    value={String(checkedCandidateOffers.includes(offer.id))}
                    labelTextClassName="font-semibold"
                  />

                  <span className="font-normal text-14">
                    {offer.user.email}
                  </span>
                </div>
              }
            >
              <Switch.Match
                when={offer.status === OfferStatusTypeCode.Rejected}
              >
                <div className="flex flex-col">
                  <i className="text-24 leading-initial font-normal text-error-content ri-close-circle-line py-s-6" />

                  <span className="label-text normal-case text-16 font-semibold">
                    {offer.account.name}
                  </span>

                  <span className="font-normal text-14 pt-s-8">
                    {offer.user.email}
                  </span>
                </div>
              </Switch.Match>

              <Switch.Match
                when={offer.status === OfferStatusTypeCode.Accepted}
              >
                <div className="flex flex-col">
                  <i className="text-24 leading-initial font-normal text-success-content ri-shake-hands-line py-s-6" />

                  <span className="label-text normal-case text-16 font-semibold">
                    {offer.account.name}
                  </span>

                  <span className="font-normal text-14 pt-s-8">
                    {offer.user.email}
                  </span>
                </div>
              </Switch.Match>
            </Switch>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
