import { useTranslation } from 'react-i18next';

const GetHelpButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.onboardingGuide',
  });

  return (
    <a className="flex items-center h-s-20" href="mailto:admin@kehops.com">
      <i className="ri-questionnaire-line text-20" />
      <span className="text-14 ml-s-8 font-semibold underline">
        {t('getHelp')}
      </span>
    </a>
  );
};

export default GetHelpButton;
