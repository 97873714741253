import { gql } from '@urql/core';

import { CandidateRequestFragment } from './fragment';

const CandidateRequest = gql`
  ${CandidateRequestFragment}

  query CandidateRequest($input: CandidateRequestInput!) {
    candidateRequest(input: $input) {
      candidateRequest {
        ...CandidateRequestFragment
      }
    }
  }
`;

export default CandidateRequest;
