import { useMemo } from 'react';

import { useNotFoundOnPredicate, useValidateIdParam } from '@application/hooks';
import { useCandidateNegotiationRoom } from '@domain/candidate';

import { toViewModel } from '../view-models/get-candidate-negotiation-room-agreement.vm';

const useGetCandidateNegotiationRoomAgreement = (roomId: string) => {
  useValidateIdParam(roomId);

  const { data, error, fetching } = useCandidateNegotiationRoom({
    input: { roomId },
  });

  const viewModel = useMemo(
    () =>
      toViewModel({
        agreement: { data, error, fetching },
      }),
    [data, error, fetching]
  );

  useNotFoundOnPredicate({
    predicate: !viewModel?.data && !viewModel?.isLoading,
  });

  return {
    viewModel,
  };
};

export default useGetCandidateNegotiationRoomAgreement;
