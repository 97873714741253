import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountPreferredSpecialtiesCreate } from '@domain/account';
import { AccountPreferredSpecialtyErrors } from '@domain/graphql.types';

import { toViewModel } from './create-account-preferred-specialties.vm';

const useCreateAccountPreferredSpecialties = () => {
  const { data, fetching, error, createAccountPreferredSpecialties } =
    useAccountPreferredSpecialtiesCreate();

  const { t } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    preferredSpecialties: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    const alreadyExistsError = errorCodes.find(
      (e) =>
        e.message === AccountPreferredSpecialtyErrors.AlreadyExistsForAccount
    );

    setAlertContent(
      alreadyExistsError
        ? { text: t('messages.errors.alreadyExists'), modifier: 'alert-error' }
        : { action: t('messages.errors.create'), modifier: 'alert-error' }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length, setAlertContent]);

  return {
    viewModel,
    createAccountPreferredSpecialties,
  };
};

export default useCreateAccountPreferredSpecialties;
