import { gql } from 'urql';

const OperationUnitCreate = gql`
  mutation OperationUnitCreate(
    $operationUnitCreateInput: OperationUnitCreateInput!
  ) {
    operationUnitCreate(operationUnitCreateInput: $operationUnitCreateInput) {
      operationUnit {
        id
        accountId
        name
        address
        phoneNumber
        phoneDisplay
        createdAt
        updatedAt
      }
    }
  }
`;

export default OperationUnitCreate;
