import { useMutation } from 'urql';

import {
  Mutation,
  MutationRecruitmentNegotiationRoomAcceptValuesArgs,
} from '@domain/graphql.types';
import { MutationRecruitmentNegotiationRoomAcceptValues } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiationRoomAcceptValues = () => {
  const [{ data, fetching, error }, acceptNegotiationRoomValues] = useMutation<
    Pick<Mutation, 'recruitmentNegotiationRoomAcceptValues'>,
    MutationRecruitmentNegotiationRoomAcceptValuesArgs
  >(MutationRecruitmentNegotiationRoomAcceptValues);

  return {
    acceptNegotiationRoomValues,
    data,
    fetching,
    error,
  };
};

export default useRecruitmentNegotiationRoomAcceptValues;
