import { gql } from '@urql/core';

const MutationOfferAccept = gql`
  mutation OfferAccept($input: OfferInput!) {
    offerAccept(input: $input) {
      offer {
        id
      }
    }
  }
`;

export default MutationOfferAccept;
