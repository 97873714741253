import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { validate as isUUID } from 'uuid';

import { PageHeader, PageLoader } from '@application/components';
import { ButtonLink } from '@application/components/links';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';

import { useGetRecruitmentOpportunity } from './hooks';
import { ConditionsSummary, DescriptionSummary } from './summary';

const ViewRecruitmentOpportunityPage = () => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });
  const { id = '' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
  } = useGetRecruitmentOpportunity(id);

  const showMakeOfferButton = useMemo(
    () =>
      data &&
      DateTime.now().startOf('day') <=
        DateTime.fromISO(data.receivingOfferDeadline).startOf('day'),
    [data]
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={t('title.details')}
        backTo={RootPrivatePage.RECRUITMENT_OPPORTUNITIES}
      >
        {showMakeOfferButton && (
          <ButtonLink
            to={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_OFFER_CREATE, {
              id,
            })}
            primary
          >
            {t('button.makeOffer')}
          </ButtonLink>
        )}
      </PageHeader>

      <div>
        <DescriptionSummary data={data} />

        <ConditionsSummary data={data} />
      </div>
    </>
  );
};

export default ViewRecruitmentOpportunityPage;
