import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryList } from '@application/components/summary';
import { CandidateOpportunity } from '@domain/graphql.types';

type SoftSkillsProps = {
  data?: CandidateOpportunity;
};

const SoftSkills = ({ data }: SoftSkillsProps) => {
  const { t } = useTranslation('candidates');

  return (
    <SummaryBlock className="mt-s-16">
      <SummaryList
        className="!mt-s-0"
        density="default"
        data={[
          {
            text: t('labels.softSkills'),
            description: data?.softSkills?.join(',') || '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default SoftSkills;
