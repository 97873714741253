import { LoadingIndicatorProps } from 'react-select';

import { LoadingSpinner } from '@application/components/spinner';

import { OptionType } from '../types';

const LoadingIndicator = (props: LoadingIndicatorProps<OptionType>) => (
  <LoadingSpinner {...props} size="sm" />
);

export default LoadingIndicator;
