import { createContext } from 'react';

import { AlertProps } from '@application/components/alert/Alert';

type AlertContextProps = {
  alertContent: AlertProps | null;
  setAlertContent: (alertContent: AlertProps | null) => void;
};

const AlertContext = createContext<AlertContextProps>({
  alertContent: null,
  setAlertContent: () => {},
});

export default AlertContext;
