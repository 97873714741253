import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  Fieldset,
  Radio,
  SectionGridRow,
  TextAreaField,
  VisibilitySwitcher,
} from '@application/components';
import {
  CandidateConditionResponsibility,
  CandidateOpportunity,
} from '@domain/graphql.types';

import { CandidateOfferFormFields } from '../../schema';

type ResponsibilityFieldsProps = {
  candidateOpportunity?: CandidateOpportunity;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const ResponsibilityFields = ({
  candidateOpportunity,
  control,
  errors,
  setValue,
  watch,
}: ResponsibilityFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const travelExpenses = watch(
    'temporaryPlacementConditionTravelExpensesResponsibility'
  );
  const trainingExpenses = watch(
    'temporaryPlacementConditionTrainingExpensesResponsibility'
  );
  const helpProgram = watch(
    'temporaryPlacementConditionEmployeeHelpProgramResponsibility'
  );
  const cnesstExpenses = watch(
    'temporaryPlacementConditionCnesstResponsibility'
  );

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateOfferFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <SectionGridRow
      rightChildren={
        <VisibilitySwitcher
          content={
            <div className="text-16 text-primary font-semibold text-center">
              {candidateOpportunity?.temporaryPlacementConditions
                ?.travelExpensesResponsibility && (
                <>
                  <span className="font-normal text-14">
                    {t('labels.responsibilityTravel')}&nbsp;
                  </span>
                  <p className="mb-s-10">
                    {t(
                      `enum.candidateConditionResponsibility.${candidateOpportunity.temporaryPlacementConditions.travelExpensesResponsibility.toLowerCase()}`
                    )}
                  </p>
                </>
              )}

              {candidateOpportunity?.temporaryPlacementConditions
                ?.trainingExpensesResponsibility && (
                <>
                  <span className="font-normal text-14">
                    {t('labels.responsibilityTraining')}&nbsp;
                  </span>
                  <p className="mb-s-10">
                    {t(
                      `enum.candidateConditionResponsibility.${candidateOpportunity.temporaryPlacementConditions.trainingExpensesResponsibility.toLowerCase()}`
                    )}
                  </p>
                </>
              )}

              {candidateOpportunity?.temporaryPlacementConditions
                ?.employeeHelpProgramResponsibility && (
                <>
                  <span className="font-normal text-14">
                    {t('labels.responsibilityProgram')}&nbsp;
                  </span>
                  <p className="mb-s-10">
                    {t(
                      `enum.candidateConditionResponsibility.${candidateOpportunity.temporaryPlacementConditions.employeeHelpProgramResponsibility.toLowerCase()}`
                    )}
                  </p>
                </>
              )}

              {candidateOpportunity?.temporaryPlacementConditions
                ?.cnesstResponsibility && (
                <>
                  <span className="font-normal text-14">
                    {t('labels.responsibilityCnesst')}&nbsp;
                  </span>
                  <p className="mb-s-10">
                    {t(
                      `enum.candidateConditionResponsibility.${candidateOpportunity.temporaryPlacementConditions.cnesstResponsibility.toLowerCase()}`
                    )}
                  </p>
                </>
              )}
            </div>
          }
          visible={
            !!candidateOpportunity?.temporaryPlacementConditions
              ?.responsibilitiesDisplay
          }
        />
      }
    >
      <Fieldset legend={t('labels.defineResponsibilities')} legendSize="medium">
        <Controller
          name="temporaryPlacementConditionTravelExpensesResponsibility"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset
              legend={
                <div className="flex items-start">
                  <i className="ri-circle-fill text-6 m-s-8" />
                  {t('labels.travelCosts')}
                </div>
              }
              legendSize="medium"
              className="mb-s-16"
            >
              <Cluster space={16} as="ul" className="-mt-s-8">
                {Object.values(CandidateConditionResponsibility).map(
                  (responsibility) => (
                    <li key={responsibility}>
                      <Radio
                        name={name}
                        value={responsibility}
                        label={t(
                          `enum.candidateConditionResponsibility.${responsibility.toLowerCase()}`
                        )}
                        onChange={(e) =>
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={travelExpenses === responsibility}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            </Fieldset>
          )}
        />

        <Controller
          name="temporaryPlacementConditionTrainingExpensesResponsibility"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset
              legend={
                <div className="flex items-start">
                  <i className="ri-circle-fill text-6 m-s-8" />
                  {t('labels.trainingCosts')}
                </div>
              }
              legendSize="medium"
              className="mb-s-16"
            >
              <Cluster space={16} as="ul" className="-mt-s-8">
                {Object.values(CandidateConditionResponsibility).map(
                  (responsibility) => (
                    <li key={responsibility}>
                      <Radio
                        name={name}
                        value={responsibility}
                        label={t(
                          `enum.candidateConditionResponsibility.${responsibility.toLowerCase()}`
                        )}
                        onChange={(e) =>
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={trainingExpenses === responsibility}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            </Fieldset>
          )}
        />

        <Controller
          name="temporaryPlacementConditionEmployeeHelpProgramResponsibility"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset
              legend={
                <div className="flex items-start">
                  <i className="ri-circle-fill text-6 m-s-8" />
                  {t('labels.employeeAssistanceProgram')}
                </div>
              }
              legendSize="medium"
              className="mb-s-16"
            >
              <Cluster space={16} as="ul" className="-mt-s-8">
                {Object.values(CandidateConditionResponsibility).map(
                  (responsibility) => (
                    <li key={responsibility}>
                      <Radio
                        name={name}
                        value={responsibility}
                        label={t(
                          `enum.candidateConditionResponsibility.${responsibility.toLowerCase()}`
                        )}
                        onChange={(e) =>
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={helpProgram === responsibility}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            </Fieldset>
          )}
        />

        <Controller
          name="temporaryPlacementConditionCnesstResponsibility"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset
              legend={
                <div className="flex items-start">
                  <i className="ri-circle-fill text-6 m-s-8" />
                  {t('labels.cnesst')}
                </div>
              }
              legendSize="medium"
              className="mb-s-16"
            >
              <Cluster space={16} as="ul" className="-mt-s-8">
                {Object.values(CandidateConditionResponsibility).map(
                  (responsibility) => (
                    <li key={responsibility}>
                      <Radio
                        name={name}
                        value={responsibility}
                        label={t(
                          `enum.candidateConditionResponsibility.${responsibility.toLowerCase()}`
                        )}
                        onChange={(e) =>
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={cnesstExpenses === responsibility}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            </Fieldset>
          )}
        />

        <Controller
          name="temporaryPlacementConditionOtherResponsibilities"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              value={value || ''}
              label={
                <div className="flex items-start">
                  <i className="ri-circle-fill text-6 m-s-8" />
                  <span className="text-16">
                    {t('labels.otherResponsibilities')}
                  </span>
                </div>
              }
              rows={4}
              maxChar={1024}
              onChange={onChange}
              invalid={
                !!errors.temporaryPlacementConditionOtherResponsibilities
              }
              helperText={
                errors.temporaryPlacementConditionOtherResponsibilities
                  ?.message &&
                tGlobal(
                  errors.temporaryPlacementConditionOtherResponsibilities
                    ?.message,
                  { max: '1024' }
                )
              }
            />
          )}
        />
      </Fieldset>
    </SectionGridRow>
  );
};

export default ResponsibilityFields;
