import { ElementType, ReactNode } from 'react';

import { CandidateRequestType, RequestTypeCode } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { Box, Stack } from '../container-layouts';
import { Space } from '../container-layouts/types';
import HeadingGroup from '../heading-group/HeadingGroup';
import {
  CandidateRequestTypeBadge,
  RequestTypeBadges,
} from '../request-type-badges';

export type SummaryCardProps = {
  title?: ReactNode;
  titleAs?: ElementType;
  subtitle?: ReactNode;
  /**
   * Specify the HTML tag applied to the subtitle
   *
   * NOTE: Restrict to `<p>`, `<span>` and `<div>` to respect semantic
   */
  subtitleAs?: 'p' | 'span' | 'div';
  className?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  verticalSpacing?: Space;
  requestTypesBadge?: RequestTypeCode[];
  candidateRequestTypeBadge?: CandidateRequestType;
  children: ReactNode;
};

const SummaryCard = ({
  title,
  titleAs: TitleTag = 'p',
  subtitle,
  subtitleAs = 'p',
  className: customClassName,
  titleClassName: customTitleClassName,
  subtitleClassName: customSubtitleClassName,
  children,
  verticalSpacing,
  requestTypesBadge,
  candidateRequestTypeBadge,
}: SummaryCardProps) => {
  const className = cn('', customClassName);
  const cardTitleClassName = cn('h3', customTitleClassName);
  const cardSubtitleClassName = cn('pt-s-6', customSubtitleClassName);

  return (
    <Box padding={24} className={className}>
      <Stack space={verticalSpacing ?? 24}>
        <div className="flex flex-row justify-between">
          {title && subtitle && (
            <HeadingGroup
              title={title}
              titleAs={TitleTag}
              subtitle={subtitle}
              subtitleAs={subtitleAs}
              titleClassName={cardTitleClassName}
              subtitleClassName={cardSubtitleClassName}
            />
          )}

          {title && !subtitle && (
            <TitleTag className={cardTitleClassName}>{title}</TitleTag>
          )}

          {requestTypesBadge && <RequestTypeBadges types={requestTypesBadge} />}

          {candidateRequestTypeBadge && (
            <CandidateRequestTypeBadge type={candidateRequestTypeBadge} />
          )}
        </div>

        {children}
      </Stack>
    </Box>
  );
};

export default SummaryCard;
