import React, { ForwardedRef } from 'react';

import { cn } from '@utils/lib-utils';

type Size = 'small' | 'large';

type AvatarProps = {
  url?: string | null;
  name?: string | null;
  size?: Size;
  className?: string;
};

type Ref = HTMLSpanElement;

// FIXME: image goes over border
const Avatar = React.forwardRef<Ref, AvatarProps>(
  (
    {
      url,
      name,
      size = 'large',
      className: customClassName,
      ...rest
    }: AvatarProps,
    ref: ForwardedRef<Ref>
  ) => {
    const className = cn(
      'rounded-md',
      size === 'large' ? 'w-s-128 h-s-128' : 'w-s-40 h-s-40',
      customClassName
    );
    const sizePx = size === 'large' ? '128' : '40';

    return (
      // Add tabindex 0 in order to make the span focusable
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <span ref={ref} className="avatar" tabIndex={0} {...rest}>
        <span className={className}>
          <img
            src={
              url ||
              `https://ui-avatars.com/api/?background=72E3A1&size=${sizePx}&name=${name}`
            }
            alt={name ?? undefined}
          />
        </span>
      </span>
    );
  }
);

export default Avatar;
