import { gql } from '@urql/core';

const MutationCandidateRequestDuplicate = gql`
  mutation CandidateRequestDuplicate($input: CandidateRequestDuplicateInput!) {
    candidateRequestDuplicate(input: $input) {
      candidateRequest {
        id
      }
    }
  }
`;

export default MutationCandidateRequestDuplicate;
