import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '@application/context';
import { OnboardingPage } from '@application/enums/pagesUrl';

type CompletedOnboardingGuardProps = {
  children: React.ReactNode;
};

const CompletedOnboardingGuard = ({
  children,
}: CompletedOnboardingGuardProps) => {
  const { hasFinishedOnboarding } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!hasFinishedOnboarding) {
      navigate(OnboardingPage.ACCOUNT_TYPE);
    }
  }, [hasFinishedOnboarding, navigate]);

  if (!hasFinishedOnboarding) {
    return false;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default CompletedOnboardingGuard;
