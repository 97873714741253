import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryList } from '@application/components/summary';

type SalesPitchProps = {
  salesPitch: string | undefined;
};

const SalesPitch = ({ salesPitch }: SalesPitchProps) => {
  const { t } = useTranslation('candidates');

  return (
    <SummaryBlock className="mt-s-16">
      <SummaryList
        className="!mt-s-0"
        density="default"
        data={[
          {
            text: `${t('labels.pitch')}:`,
            description: salesPitch || '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default SalesPitch;
