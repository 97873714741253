import { captureException, withScope } from '@sentry/react';
import { authExchange } from '@urql/exchange-auth';
import { cacheExchange, Client, fetchExchange, mapExchange } from 'urql';
import { v4 as uuid } from 'uuid';

declare global {
  interface WindowEventMap {
    'kehops-logout': CustomEvent;
    'kehops-not-found': CustomEvent;
  }
}

const client = ({
  getAccessTokenSilently,
  isAuthenticated,
}: {
  getAccessTokenSilently: () => Promise<string>;
  isAuthenticated: boolean;
}) =>
  new Client({
    url: `${import.meta.env.VITE_BACKEND_URL}/graphql`,
    requestPolicy: 'network-only',
    exchanges: [
      authExchange(async (utils) => {
        let token: string | null = null;

        if (isAuthenticated) {
          try {
            token = await getAccessTokenSilently();
          } catch (e) {
            window.dispatchEvent(new Event('kehops-logout'));
          }
        }

        return {
          addAuthToOperation(operation) {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
            });
          },
          async refreshAuth() {
            window.dispatchEvent(new Event('kehops-logout'));
          },
          didAuthError(error) {
            const isGraphQLAuthError = error.graphQLErrors.some(
              (e) =>
                e.extensions?.code === 'Unauthorized' ||
                e.extensions?.code === 'UNAUTHENTICATED'
            );
            return isGraphQLAuthError || error.response?.status === 401;
          },
        };
      }),
      cacheExchange,
      mapExchange({
        onError(error, { kind, query }) {
          const definition = query.definitions[0];
          const queryName =
            ('name' in definition && definition.name?.value) || definition.kind;

          withScope((scope) => {
            scope.setFingerprint([error.name, kind, queryName]);
            captureException(error);
          });

          const notFoundError = error.graphQLErrors.find(
            ({ extensions }) => extensions.status === 404
          );

          if (notFoundError) {
            window.dispatchEvent(new Event('kehops-not-found'));
          }
        },
      }),
      fetchExchange,
    ],
    fetchOptions: {
      credentials: 'omit',
      headers: {
        // See https://github.com/mswjs/msw/issues/1593
        Accept: '*/*',
        'X-Correlation-ID': uuid(),
        'apollo-require-preflight': 'true',
      },
    },
  });
export default client;
