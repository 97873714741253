import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { NavigationTabs } from '@application/components/tabs';
import { PrivatePage } from '@application/enums/pagesUrl';

const UserPage = () => {
  const { t } = useTranslation('user', { keyPrefix: 'tabs' });

  const tabs = useMemo(
    () => [
      {
        path: PrivatePage.USER_PROFILE,
        element: t('information'),
        label: 'information',
        id: 'tab-information',
      },
      {
        element: t('management'),
        label: 'security',
        id: 'tab-security',
        children: [
          {
            path: PrivatePage.USER_NOTIFICATIONS_MANAGEMENT,
            element: t('notifications'),
            label: 'notifications',
            id: 'tab-notifications',
          },
          {
            path: PrivatePage.USER_SECURITY_MANAGEMENT,
            element: t('security'),
            label: 'security',
            id: 'tab-security',
          },
        ],
      },
    ],
    [t]
  );

  return (
    <>
      <NavigationTabs tabs={tabs} />
      <Outlet />
    </>
  );
};

export default UserPage;
