import { gql } from 'urql';

const OfferTemporaryPlacementConditionsFragment = gql`
  fragment OfferTemporaryPlacementConditionsFragment on OfferTemporaryPlacementConditions {
    possibleBuyback
    possibleBuybackBonus
    risks
    travelExpensesResponsibility
    trainingExpensesResponsibility
    employeeHelpProgramResponsibility
    cnesstExpensesResponsibility
    otherResponsibilities
    foreignApplicants
  }
`;

export default OfferTemporaryPlacementConditionsFragment;
