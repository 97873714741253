import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  Fieldset,
  FormInputWithSuffix,
  Radio,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import {
  CandidateConditionBillingPeriod,
  CandidateOpportunity,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { CandidateOfferFormFields } from '../../schema';
import ResponsibilityFields from './ResponsibilityFields';

type TemporaryPlacementFieldsProps = {
  candidateOpportunity?: CandidateOpportunity;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  register: UseFormRegister<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const TemporaryPlacementFields = ({
  candidateOpportunity,
  control,
  errors,
  register,
  setValue,
  watch,
}: TemporaryPlacementFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const possibleBuyback = watch('temporaryPlacementConditionPossibleBuyback');
  const billingPeriod = watch('temporaryPlacementConditionBillingPeriod');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateOfferFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <SectionGrid title={t('subtitles.offerAgency')} isOffer>
      <SectionGridRow
        isTopRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.temporaryPlacementConditions
                ?.probationPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.week', {
                    count:
                      candidateOpportunity.temporaryPlacementConditions
                        .probationPeriodValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.temporaryPlacementConditions
                ?.probationPeriodDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.minimumTrialPeriodDuration')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.weeks')}
          mediumSuffix
          invalid={!!errors.temporaryPlacementConditionProbationPeriod}
          helperText={
            errors.temporaryPlacementConditionProbationPeriod?.message &&
            tGlobal(
              errors.temporaryPlacementConditionProbationPeriod?.message,
              { min: '1' }
            )
          }
          {...register('temporaryPlacementConditionProbationPeriod')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.temporaryPlacementConditions
                ?.minimumNoticeValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.week', {
                    count:
                      candidateOpportunity.temporaryPlacementConditions
                        .minimumNoticeValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.temporaryPlacementConditions
                ?.minimumNoticeDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.minimumNoticePeriod')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.weeks')}
          mediumSuffix
          invalid={!!errors.temporaryPlacementConditionMinimumNotice}
          helperText={
            errors.temporaryPlacementConditionMinimumNotice?.message &&
            tGlobal(errors.temporaryPlacementConditionMinimumNotice?.message, {
              min: '1',
            })
          }
          {...register('temporaryPlacementConditionMinimumNotice')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.temporaryPlacementConditions
                ?.possibleBuybackValue ? (
                <span className="text-16 font-semibold text-primary">
                  {tGlobal(
                    `boolean.${candidateOpportunity.temporaryPlacementConditions.possibleBuybackValue}`
                  )}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.temporaryPlacementConditions
                ?.possibleBuybackDisplay
            }
          />
        }
      >
        <Controller
          name="temporaryPlacementConditionPossibleBuyback"
          control={control}
          render={({ field: { name } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.buyback')}
              language={extractLanguage(i18n.language)}
              legendSize="medium"
              value={possibleBuyback || undefined}
              onChange={(e) =>
                handleOnRadioChange(e as ChangeEvent<HTMLInputElement>, name)
              }
              alignRow
            />
          )}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.temporaryPlacementConditions
                ?.possibleBuybackBonusValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.currency', {
                    value:
                      candidateOpportunity.temporaryPlacementConditions
                        .possibleBuybackBonusValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.temporaryPlacementConditions
                ?.possibleBuybackBonusDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.defineBonusBuyout')}
          type="number"
          step={0.01}
          minValue={1}
          maxValue={99999999.99}
          noMargin
          suffix="$"
          mediumSuffix
          invalid={!!errors.temporaryPlacementConditionPossibleBuybackBonus}
          helperText={
            errors.temporaryPlacementConditionPossibleBuybackBonus?.message &&
            tGlobal(
              errors.temporaryPlacementConditionPossibleBuybackBonus?.message,
              {
                max: '99,999,999.99',
                min: '1',
              }
            )
          }
          {...register('temporaryPlacementConditionPossibleBuybackBonus')}
        />
      </SectionGridRow>

      <ResponsibilityFields
        candidateOpportunity={candidateOpportunity}
        control={control}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.temporaryPlacementConditions
                ?.billingPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t(
                    `enum.candidateConditionBillingPeriod.${candidateOpportunity.temporaryPlacementConditions.billingPeriodValue.toLowerCase()}`
                  )}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.temporaryPlacementConditions
                ?.billingPeriodDisplay
            }
          />
        }
      >
        <Controller
          name="temporaryPlacementConditionBillingPeriod"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset legend={t('labels.billingPeriod')} legendSize="medium">
              <Cluster space={16} as="ul">
                {Object.values(CandidateConditionBillingPeriod).map(
                  (period) => (
                    <li key={period}>
                      <Radio
                        name={name}
                        value={period}
                        label={t(
                          `enum.candidateConditionBillingPeriod.${period.toLowerCase()}`
                        )}
                        onChange={(e) =>
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={billingPeriod === period}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            </Fieldset>
          )}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default TemporaryPlacementFields;
