import { useAccountPreferredSpecialties } from '@domain/account';
import {
  AccountPreferredSpecialtiesFilters,
  AccountPreferredSpecialtiesSortDirective,
} from '@domain/graphql.types';
import { Pagination } from '@domain/pagination/pagination';

import { toViewModel } from './get-account-preferred-specialties.vm';

const useGetAccountPreferredSpecialties = (
  sortBy: AccountPreferredSpecialtiesSortDirective[],
  filterBy?: AccountPreferredSpecialtiesFilters,
  pagination?: Pagination
) => {
  const { data, pageData, pageInfo, fetching, error, reexecuteQuery } =
    useAccountPreferredSpecialties({
      sortBy,
      filterBy,
      ...pagination,
    });

  const viewModel = toViewModel({
    preferredSpecialties: {
      data: {
        preferredSpecialties: data,
        pageData,
        pageInfo,
      },
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchAccountPreferredSpecialties: reexecuteQuery,
  };
};

export default useGetAccountPreferredSpecialties;
