import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';

import { Box } from '../container-layouts';
import Modal from './Modal';

const termsAndConditionsItemsCount = 8;
const items = Array.from(
  { length: termsAndConditionsItemsCount },
  (_, i) => i + 1
);

export type TermsAndConditionsModalProps = {
  onClose: () => void;
};

const TermsAndConditionsModal = ({ onClose }: TermsAndConditionsModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.CloseBtn onClick={onClose} />

      <Box className="flex flex-col gap-s-16 overflow-auto text-justify">
        <p>{t('termsAndConditions.content.description')}</p>

        <ol className="flex flex-col gap-s-16">
          {items.map((item) => (
            <li key={`terms-and-conditions-${item}`} className="flex flex-col">
              <h3 className="font-bold text-18">
                {t(`termsAndConditions.content.${item}.title`)}
              </h3>

              <p className="mt-s-0">
                {t(`termsAndConditions.content.${item}.message`)}
              </p>
            </li>
          ))}
        </ol>
      </Box>

      <Modal.Action>
        <Button onClick={onClose} primary>
          {t('button.close')}
        </Button>
      </Modal.Action>
    </>
  );
};

export default TermsAndConditionsModal;
