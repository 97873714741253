import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormInputWithSuffix,
  SectionGrid,
  SectionGridRow,
  TextAreaField,
  VisibilitySwitcher,
} from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';

import { OfferFormFields } from '../../types';

type OutsourcingFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const OutsourcingFields = ({
  recruitmentOpportunity,
}: OutsourcingFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
    register,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  return (
    <>
      <SectionGrid
        title={t('subtitles.forOutsourcing')}
        className="mt-s-40"
        isOffer
      >
        <SectionGridRow
          isTopRow
          isOffer
          rightChildren={
            <VisibilitySwitcher
              content={
                recruitmentOpportunity?.outsourcingContractualConditions
                  ?.maximumDurationValue ? (
                  <span className="text-16 font-semibold text-primary">
                    {t('suffix.week', {
                      count:
                        recruitmentOpportunity.outsourcingContractualConditions
                          .maximumDurationValue,
                    })}
                  </span>
                ) : (
                  <span>-</span>
                )
              }
              visible={
                !!recruitmentOpportunity?.outsourcingContractualConditions
                  ?.maximumDurationDisplay
              }
            />
          }
        >
          <FormInputWithSuffix
            label={t('labels.maxMandateDuration')}
            type="number"
            minValue={1}
            noMargin
            suffix={t('suffix.weeks')}
            mediumSuffix
            invalid={!!errors.outsourcingConditionMaximumDuration}
            helperText={
              errors.outsourcingConditionMaximumDuration?.message &&
              tGlobal(errors.outsourcingConditionMaximumDuration?.message, {
                min: '1',
              })
            }
            {...register('outsourcingConditionMaximumDuration')}
          />
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <VisibilitySwitcher
              content={
                recruitmentOpportunity?.outsourcingContractualConditions
                  ?.exclusivityPeriodValue ? (
                  <span className="text-16 font-semibold text-primary">
                    {t('suffix.week', {
                      count:
                        recruitmentOpportunity.outsourcingContractualConditions
                          .exclusivityPeriodValue,
                    })}
                  </span>
                ) : (
                  <span>-</span>
                )
              }
              visible={
                !!recruitmentOpportunity?.outsourcingContractualConditions
                  ?.exclusivityPeriodDisplay
              }
            />
          }
        >
          <FormInputWithSuffix
            label={t('labels.outsourcingExclusivityPeriod')}
            type="number"
            minValue={1}
            noMargin
            suffix={t('suffix.weeks')}
            mediumSuffix
            invalid={!!errors.outsourcingConditionExclusivityPeriod}
            helperText={
              errors.outsourcingConditionExclusivityPeriod?.message &&
              tGlobal(errors.outsourcingConditionExclusivityPeriod?.message, {
                min: '1',
              })
            }
            {...register('outsourcingConditionExclusivityPeriod')}
          />
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <VisibilitySwitcher
              content={
                <div className="flex flex-col text-16 font-semibold text-primary">
                  {recruitmentOpportunity?.outsourcingContractualConditions
                    ?.maximumBudgetValue && (
                    <p className="mb-s-10">
                      {
                        recruitmentOpportunity.outsourcingContractualConditions
                          .maximumBudgetValue
                      }
                      {t('suffix.currencyPerHour')}
                    </p>
                  )}
                </div>
              }
              visible={
                !!recruitmentOpportunity?.outsourcingContractualConditions
                  ?.maximumBudgetDisplay
              }
            />
          }
        >
          <FormInputWithSuffix
            className="min-w-max"
            label={t('labels.maxCandidateBudget')}
            suffix={t('suffix.currencyPerHour')}
            type="number"
            mediumSuffix
            noMargin
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            invalid={!!errors.outsourcingConditionMaximumBudget}
            helperText={
              errors.outsourcingConditionMaximumBudget?.message &&
              tGlobal(errors.outsourcingConditionMaximumBudget?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('outsourcingConditionMaximumBudget')}
          />
        </SectionGridRow>
      </SectionGrid>

      <Box>
        <Controller
          name="outsourcingConditionResponsibilities"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              className="w-full"
              value={value || ''}
              label={t('labels.recruitmentResponsibilities')}
              labelSize="medium"
              rows={4}
              onChange={onChange}
            />
          )}
        />
      </Box>
    </>
  );
};

export default OutsourcingFields;
