import { useTranslation } from 'react-i18next';

import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import {
  Offer,
  OfferStatusTypeCode,
  RequestTypeCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_CLASS_NAME } from './constants';

type RequestTypeConditionProps = {
  type: string;
  offers: readonly Offer[];
  checkedOffers: string[];
};

const RequestTypeCondition = ({
  type,
  offers,
  checkedOffers,
}: RequestTypeConditionProps) => {
  const { t: tGlobal } = useTranslation('translation', {
    keyPrefix: 'enum.requestTypeCode',
  });

  return (
    <tr>
      <th className={TH_CLASS_NAME}>
        <TooltipEllipsis toolTip={tGlobal(type.toLowerCase())}>
          <div>{tGlobal(type.toLowerCase())}</div>
        </TooltipEllipsis>
      </th>

      {offers.map((offer) => (
        <td
          key={offer.id}
          className={cn(TD_CLASS_NAME, {
            'border-x-2 !border-x-main': checkedOffers.find(
              (offerId) => offer.id === offerId
            ),
            'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
          })}
        >
          {offer.requestTypes?.includes(type as RequestTypeCode) ? (
            <i className="ri-checkbox-circle-fill text-18" />
          ) : (
            <span>-</span>
          )}
        </td>
      ))}
    </tr>
  );
};

export default RequestTypeCondition;
