import { FC } from 'react';

import { cn } from '@utils/lib-utils';

import SummaryCard, { SummaryCardProps } from './SummaryCard';
import SummaryHeader from './SummaryHeader';

export type SummaryBlockProps = SummaryCardProps & {
  headerTitle?: string;
  headerTitleClassName?: string;
};

const SummaryBlock: FC<SummaryBlockProps> = ({
  headerTitle,
  headerTitleClassName,
  className,
  ...rest
}) => {
  const summaryCardProps = { ...rest };
  const summaryCardClasses = cn(
    { 'rounded-t-none border-t-transparent': !!headerTitle },
    className
  );

  return (
    <>
      {headerTitle && (
        <SummaryHeader
          className={headerTitleClassName}
          headerTitle={headerTitle}
        />
      )}
      <SummaryCard className={summaryCardClasses} {...summaryCardProps} />
    </>
  );
};

export default SummaryBlock;
