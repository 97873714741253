import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components';
import {
  CandidateOffer,
  CandidateRequestType,
  OfferStatusTypeCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_CLASS_NAME } from '../../constants';

type RecruiterOfferProps = {
  candidateRequestType?: CandidateRequestType;
  candidateOffers: readonly CandidateOffer[];
  checkedCandidateOffers: string[];
};

const RecruiterOffer = ({
  candidateRequestType,
  candidateOffers,
  checkedCandidateOffers,
}: RecruiterOfferProps) => {
  const { t } = useTranslation('candidates');

  const getTdClassName = useCallback(
    (id: string, status: OfferStatusTypeCode, bg?: string) =>
      cn(TD_CLASS_NAME, bg, {
        'border-x-2 !border-x-main': checkedCandidateOffers.includes(id),
        'opacity-65': status === OfferStatusTypeCode.Rejected,
      }),
    [checkedCandidateOffers]
  );

  return (
    <>
      <tr>
        <th className={cn(TH_CLASS_NAME, 'bg-neutral')}>
          {t('labels.offerToRecruiter')}
        </th>

        {candidateOffers.map((offer) => (
          <td
            aria-label={t('labels.offerToRecruiter')}
            key={offer.id}
            className={getTdClassName(offer.id, offer.status, 'bg-neutral')}
          />
        ))}
      </tr>

      {candidateRequestType === CandidateRequestType.Recruitment ? (
        <>
          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.proposedFees')}</th>

            {candidateOffers.map((offer) => (
              <td
                aria-label={t('labels.proposedFees')}
                key={offer.id}
                className={getTdClassName(offer.id, offer.status)}
              >
                <PlaceholderSwitcher
                  value={offer.recruitmentConditions?.budget}
                >
                  {t('suffix.currency', {
                    value: offer.recruitmentConditions?.budget,
                  })}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>

          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.additionalBonus')}</th>

            {candidateOffers.map((offer) => (
              <td
                aria-label={t('labels.additionalBonus')}
                key={offer.id}
                className={getTdClassName(offer.id, offer.status)}
              >
                <PlaceholderSwitcher value={offer.recruitmentConditions?.bonus}>
                  {t('suffix.currency', {
                    value: offer.recruitmentConditions?.bonus,
                  })}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>

          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.guaranteeRequested')}</th>

            {candidateOffers.map((offer) => (
              <td
                aria-label={t('labels.guaranteeRequested')}
                key={offer.id}
                className={getTdClassName(offer.id, offer.status)}
              >
                <PlaceholderSwitcher
                  value={offer.recruitmentConditions?.guaranteedPeriod}
                >
                  {t('suffix.month', {
                    count: Number(
                      offer?.recruitmentConditions?.guaranteedPeriod
                    ),
                  })}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>

          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.paymentMethods')}</th>

            {candidateOffers.map((offer) => (
              <td
                aria-label={t('labels.paymentMethods')}
                key={offer.id}
                className={getTdClassName(offer.id, offer.status)}
              >
                <PlaceholderSwitcher
                  value={offer.recruitmentConditions?.paymentMethod}
                >
                  {t(
                    `enum.requestConditionPaymentMethodCode.${offer?.recruitmentConditions?.paymentMethod?.toLowerCase()}`
                  )}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>
        </>
      ) : (
        <>
          <tr>
            <th className={TH_CLASS_NAME}>
              {t('labels.desiredBillingPeriod')}
            </th>

            {candidateOffers.map((offer) => (
              <td
                aria-label={t('labels.desiredBillingPeriod')}
                key={offer.id}
                className={getTdClassName(offer.id, offer.status)}
              >
                <PlaceholderSwitcher
                  value={offer.temporaryPlacementConditions?.billingPeriod}
                >
                  {t('suffix.currency', {
                    value: offer.temporaryPlacementConditions?.billingPeriod,
                  })}
                </PlaceholderSwitcher>
              </td>
            ))}
          </tr>

          <tr>
            <th className={TH_CLASS_NAME}>{t('labels.minimumNotice')}</th>

            {candidateOffers.map((offer) => (
              <td
                aria-label={t('labels.minimumNotice')}
                key={offer.id}
                className={getTdClassName(offer.id, offer.status)}
              >
                {candidateRequestType ===
                CandidateRequestType.TemporaryPlacement ? (
                  <PlaceholderSwitcher
                    value={offer.temporaryPlacementConditions?.minimumNotice}
                  >
                    {t('suffix.week', {
                      count: Number(
                        offer.temporaryPlacementConditions?.minimumNotice
                      ),
                    })}
                  </PlaceholderSwitcher>
                ) : (
                  <PlaceholderSwitcher
                    value={offer.outsourcingConditions?.minimumNotice}
                  >
                    {t('suffix.week', {
                      count: Number(offer.outsourcingConditions?.minimumNotice),
                    })}
                  </PlaceholderSwitcher>
                )}
              </td>
            ))}
          </tr>
        </>
      )}

      {candidateRequestType === CandidateRequestType.TemporaryPlacement && (
        <tr>
          <th className={TH_CLASS_NAME}>{t('labels.possibleBuybackBonus')}</th>

          {candidateOffers.map((offer) => (
            <td
              aria-label={t('labels.desiredBillingPeriod')}
              key={offer.id}
              className={getTdClassName(offer.id, offer.status)}
            >
              <PlaceholderSwitcher
                value={offer.temporaryPlacementConditions?.minimumNotice}
              >
                {t('suffix.week', {
                  count: Number(
                    offer.temporaryPlacementConditions?.minimumNotice
                  ),
                })}
              </PlaceholderSwitcher>
            </td>
          ))}
        </tr>
      )}
    </>
  );
};

export default RecruiterOffer;
