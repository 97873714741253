import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGrid, SectionGridRow } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { joinDescriptions } from '@application/utils';
import {
  CandidateOffer,
  CandidateOpportunity,
  CandidateRequest,
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
} from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';

import Bonus from './Bonus';
import OtherPerks from './OtherPerks';
import SalesPitch from './SalesPitch';

type CandidateSummaryProps = {
  offer: CandidateOffer | undefined;
  request: CandidateRequest | CandidateOpportunity | undefined;
};

const CandidateSummary = ({ offer, request }: CandidateSummaryProps) => {
  const { t, i18n } = useTranslation('candidates');

  const requestChildrenClassName = 'text-16 font-semibold text-primary';
  const offerWrapperClassName = 'flex flex-wrap gap-x-s-16 items-center';
  const offerLabelClassName = 'text-16 text-primary';
  const offerValueClassName = 'text-18 font-semibold text-primary';

  const getJobType = useCallback(
    (
      data?: CandidateOffer | CandidateRequest | CandidateOpportunity,
      hideLabels?: boolean
    ) => {
      const styles = hideLabels
        ? requestChildrenClassName
        : offerValueClassName;

      switch (data?.jobType) {
        case JobDurationCode.Contract:
        case JobDurationCode.Freelance:
        case JobDurationCode.Internship:
          return (
            <div className="flex-col">
              <div className={offerWrapperClassName}>
                {!hideLabels && (
                  <span className={offerLabelClassName}>
                    {t('labels.jobType')}:
                  </span>
                )}
                <span className={styles}>
                  {t(
                    `enum.jobDurationCode.${data?.jobType.toString().toLowerCase()}`
                  )}
                </span>
              </div>
              <div className={offerWrapperClassName}>
                {!hideLabels && (
                  <span className={offerLabelClassName}>
                    {t('labels.jobDurationInMonths')}:
                  </span>
                )}

                <p className={styles}>
                  {t('suffix.month', {
                    count: Number(data?.jobDurationInMonths),
                  })}
                </p>
              </div>
            </div>
          );
        case JobDurationCode.Seasonal:
          return (
            <span className={styles}>
              {`${t(
                `enum.jobDurationCode.${data?.jobType.toString().toLowerCase()}`
              )} `}

              {data.seasons
                ?.map((s) => t(`enum.seasons.${s.toString().toLowerCase()}`))
                .join(', ')}
            </span>
          );
        case JobDurationCode.Permanent:
        case JobDurationCode.Occasional:
        case JobDurationCode.OpenEndedContract:
          return (
            <span className={styles}>
              {t(
                `enum.jobDurationCode.${data?.jobType.toString().toLowerCase()}`
              )}
            </span>
          );
        default:
          return '-';
      }
    },
    [t]
  );

  const getRemuneration = useCallback(
    (
      paymentFrequencyCode?: JobPaymentFrequencyCode,
      data?: CandidateOffer | CandidateRequest | CandidateOpportunity
    ) => {
      switch (paymentFrequencyCode) {
        case JobPaymentFrequencyCode.Hourly:
          return t('suffix.currencyPerHourRange', {
            min: data?.hourlyRateMin,
            max: data?.hourlyRateMax,
          });
        case JobPaymentFrequencyCode.Yearly:
          return t('suffix.thousandsPerYearRange', {
            min: data?.annualSalaryMin,
            max: data?.annualSalaryMax,
          });
        case JobPaymentFrequencyCode.FixedAmount:
          return t('suffix.currency', { value: data?.remunerationAmount });
        default:
          return '-';
      }
    },
    [t]
  );

  const jobTypeOffer = getJobType(offer);
  const jobTypeRequest = getJobType(request, true);

  const remunerationOffer = getRemuneration(
    offer?.paymentFrequencyCode as JobPaymentFrequencyCode,
    offer
  );
  const remunerationRequest = getRemuneration(
    request?.paymentFrequencyCode as JobPaymentFrequencyCode,
    request
  );

  const availability = useMemo(
    () =>
      [
        request?.fullTimeAvailability && t('labels.fullTimeShort'),
        request?.partTimeAvailability && t('labels.partTimeShort'),
      ]
        .filter(Boolean)
        .join(' '),
    [request?.fullTimeAvailability, request?.partTimeAvailability, t]
  );

  return (
    <>
      <div className="grid grid-cols-[auto_10rem] items-center py-s-6 border border-b-transparent rounded-t-md bg-secondary">
        <span className="text-18 font-semibold px-s-16">
          {t('subtitles.offerDescription')}
        </span>

        <span className="text-center whitespace-pre-line text-14">
          {t('subtitles.initialConditions')}
        </span>
      </div>

      <SectionGrid
        readOnly
        title={t('subtitles.offerAgency')}
        hideTitle
        gridColsClassName="grid-cols-[1fr_10rem]"
      >
        <SectionGridRow
          isOffer
          isTopRow
          rightChildren={
            <PlaceholderSwitcher
              value={request?.operationTerritories?.length || undefined}
            >
              <span className={requestChildrenClassName}>
                {joinDescriptions(
                  request?.operationTerritories?.map(
                    (territory) => territory.descriptions
                  ) || [],
                  i18n.language
                )}
              </span>
            </PlaceholderSwitcher>
          }
        >
          <span className={offerWrapperClassName}>
            <span className={offerLabelClassName}>{t('labels.location')}:</span>

            <PlaceholderSwitcher
              value={offer?.operationTerritories?.length || undefined}
            >
              <span className={offerValueClassName}>
                {joinDescriptions(
                  offer?.operationTerritories?.map(
                    (territory) => territory.descriptions
                  ) || [],
                  i18n.language
                )}
              </span>
            </PlaceholderSwitcher>
          </span>
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <span className={requestChildrenClassName}>{jobTypeRequest}</span>
          }
        >
          <span className={offerWrapperClassName}>{jobTypeOffer}</span>
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <PlaceholderSwitcher
              value={request?.hoursPerWeek?.length || undefined}
            >
              <span className={requestChildrenClassName}>
                {request?.hoursPerWeek?.map((h) => `${h}h`).join(', ')}

                {availability && <span className="ml-s-8">{availability}</span>}
              </span>
            </PlaceholderSwitcher>
          }
        >
          <span className={offerWrapperClassName}>
            <span className={offerLabelClassName}>
              {t('labels.hoursPerWeek')}:
            </span>

            <PlaceholderSwitcher value={offer?.hoursPerWeek}>
              <span className={offerValueClassName}>
                <span className="mr-s-16">
                  {offer?.hoursPerWeek
                    ?.map((h) => t('suffix.hours', { value: h }))
                    .join(', ')}
                </span>
                <span className="mr-s-8">
                  {offer?.fullTimeAvailability && t('labels.fullTimeShort')}
                </span>
                {offer?.partTimeAvailability && t('labels.partTimeShort')}
              </span>
            </PlaceholderSwitcher>
          </span>
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <PlaceholderSwitcher
              value={request?.schedules?.length || undefined}
            >
              <span className={requestChildrenClassName}>
                {request?.schedules
                  ?.map((s) => t(`enum.jobScheduleTypeCode.${s.toLowerCase()}`))
                  .join(', ')}
              </span>
            </PlaceholderSwitcher>
          }
        >
          <span className={offerWrapperClassName}>
            <span className={offerLabelClassName}>{t('labels.schedule')}:</span>

            <PlaceholderSwitcher value={offer?.schedules?.length || undefined}>
              <span className={offerValueClassName}>
                {offer?.schedules
                  ?.map((s) => t(`enum.jobScheduleTypeCode.${s.toLowerCase()}`))
                  .join(', ')}
              </span>
            </PlaceholderSwitcher>
          </span>
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <PlaceholderSwitcher value={request?.jobMode?.code}>
              <span className={requestChildrenClassName}>
                {getLocalizedDescription(
                  request?.jobMode?.descriptions,
                  i18n.language
                )}

                {request?.jobMode?.code === JobModeCode.Hybrid && (
                  <span className="ml-s-8">
                    {request?.remoteDaysAllowed}
                    &nbsp;
                    {t('suffix.daysPerWeek')}
                  </span>
                )}
              </span>
            </PlaceholderSwitcher>
          }
        >
          <span className={offerWrapperClassName}>
            <span className={offerLabelClassName}>
              {t('labels.remoteWork')}:
            </span>

            <PlaceholderSwitcher value={offer?.jobModeCode}>
              <span className={offerValueClassName}>
                {t(`enum.jobModeCode.${offer?.jobModeCode?.toLowerCase()}`)}

                {offer?.jobModeCode === JobModeCode.Hybrid && (
                  <span className="ml-s-16">
                    {offer?.remoteDaysAllowed}
                    &nbsp;
                    {t('suffix.daysPerWeek')}
                  </span>
                )}
              </span>
            </PlaceholderSwitcher>
          </span>
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <span className={requestChildrenClassName}>
              {remunerationRequest}

              {request?.paymentFrequencyCode ===
                JobPaymentFrequencyCode.Hourly &&
                request.hourlyBonus && (
                  <span className="block w-full">
                    {t('labels.hourlyBonus')}
                    {request.hourlyBonus}$
                  </span>
                )}
            </span>
          }
        >
          <span className={offerWrapperClassName}>
            <span className={offerLabelClassName}>
              {t('labels.remunerationAmount')}
            </span>

            <span className={offerValueClassName}>{remunerationOffer}</span>

            {offer?.paymentFrequencyCode === JobPaymentFrequencyCode.Hourly &&
              offer.hourlyBonus && (
                <span className={cn(offerWrapperClassName, 'w-full mt-s-8')}>
                  <span className={offerLabelClassName}>
                    {t('labels.hourlyBonus')}:
                  </span>
                  <span className={offerValueClassName}>
                    {offer?.hourlyBonus}$
                  </span>
                </span>
              )}
          </span>
        </SectionGridRow>

        <SectionGridRow
          isOffer
          rightChildren={
            <PlaceholderSwitcher value={request?.otherPaymentType?.code}>
              <span className={requestChildrenClassName}>
                {t(
                  `labels.otherCompensations.${request?.otherPaymentTypeCode
                    ?.toString()
                    .toLowerCase()}`,
                  {
                    amount: request?.otherPaymentAmount,
                  }
                )}
              </span>
            </PlaceholderSwitcher>
          }
        >
          <span className={offerWrapperClassName}>
            <span className={offerLabelClassName}>
              {t('labels.otherCompensation')}:
            </span>

            <PlaceholderSwitcher value={offer?.otherPaymentTypeCode}>
              <span className={offerValueClassName}>
                {t(
                  `labels.otherCompensations.${offer?.otherPaymentTypeCode
                    ?.toString()
                    .toLowerCase()}`,
                  {
                    amount: offer?.otherPaymentAmount,
                  }
                )}
              </span>
            </PlaceholderSwitcher>
          </span>
        </SectionGridRow>
      </SectionGrid>

      <Bonus offer={offer} />

      <OtherPerks offer={offer} />

      <SalesPitch salesPitch={offer?.salesPitch || undefined} />
    </>
  );
};

export default CandidateSummary;
