import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import {
  RecruitmentNegotiationRoomsFilters,
  RecruitmentNegotiationRoomStatus,
} from '@domain/graphql.types';

const defaultFilters = {
  statuses: undefined,
};

const statusCodeMap: Record<
  string,
  RecruitmentNegotiationRoomsFilters['statuses']
> = {
  '#in-progress': [RecruitmentNegotiationRoomStatus.InProgress],
  '#accepted': [RecruitmentNegotiationRoomStatus.Accepted],
  '#rejected': [RecruitmentNegotiationRoomStatus.Rejected],
};

const useRecruitmentNegotiationFilters = () => {
  const [filters, setFilters] =
    useState<RecruitmentNegotiationRoomsFilters>(defaultFilters);

  const { hash } = useLocation();

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      statuses: statusCodeMap[hash] || undefined,
    }));
  }, [hash]);

  return {
    filters,
  };
};

export default useRecruitmentNegotiationFilters;
