import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

type SectionGridProps = {
  children?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  gridColsClassName?: string;
  hideTitle?: boolean;
  readOnly?: boolean;
  title?: string;
  isOffer?: boolean;
};

const SectionGrid = ({
  children,
  className: customClassName,
  fullWidth,
  gridColsClassName: customGridColsClassName,
  hideTitle,
  readOnly,
  title,
  isOffer,
}: SectionGridProps) => {
  const { t } = useTranslation('requests');

  return (
    <section
      className={cn('w-full my-s-16', { 'mt-s-0': readOnly }, customClassName)}
    >
      <div
        className={cn('grid grid-cols-[1fr_5.5rem] mb-s-16 items-center', {
          'sr-only': hideTitle,
          'grid-cols-[1fr_10rem]': isOffer,
        })}
      >
        <h2 className="h4">{title}</h2>
        <span className={cn('p-medium text-center', { 'text-14': isOffer })}>
          {t(
            isOffer ? 'labels.desiredConditions' : 'labels.visibleInformation'
          )}
        </span>
      </div>

      <div
        className={cn(
          'grid grid-cols-[1fr_5rem] border rounded-md items-center',
          {
            'rounded-tl-none rounded-tr-none border-t-transparent': readOnly,
            'grid-cols-1': fullWidth,
            'grid-cols-[1fr_10rem]': isOffer,
          },
          customGridColsClassName
        )}
      >
        {children}
      </div>
    </section>
  );
};

export default SectionGrid;
