import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { NoContent, Table } from '@application/components/table';
import { AccountContext, ModalContext } from '@application/context';

import {
  AccountPreferredSpecialtiesFilters,
  AccountTypeCode,
} from '../../../../../domain/graphql.types';
import CreateSpecialtyModal from './CreateSpecialtyModal';
import PreferredSpecialtiesFilters from './PreferredSpecialtiesFilters';
import { useAccountPreferredSpecialtiesList } from './actions/useAccountPreferredSpecialtiesList';
import useFilters from './actions/useFilters';

type PreferredSpecialtiesListProps = {
  setShowOnboarding: (state: boolean) => void;
};

const PreferredSpecialtiesList = ({
  setShowOnboarding,
}: PreferredSpecialtiesListProps) => {
  const { t } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities',
  });

  const [tableFilters, setTableFilters] =
    useState<AccountPreferredSpecialtiesFilters>();

  const { setModal } = useContext(ModalContext);
  const { account, refreshAccount } = useContext(AccountContext);

  const isAgency = account?.type === AccountTypeCode.Agency;

  const {
    columns,
    currentPage,
    sorting,
    count,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    preferredSpecialties,
    refetchAccountPreferredSpecialties,
    isLoading,
  } = useAccountPreferredSpecialtiesList(tableFilters);

  const { hasFilters, filters, updateSelectFilters, updateTextFilter } =
    useFilters({
      resetPagination: onPaginationReset,
    });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  const noContent = useMemo(
    () =>
      preferredSpecialties.length === 0 && hasFilters ? (
        <NoContent
          title={t('list.noFilteredContentTitle')}
          message={t('list.noFilteredContentMessage')}
        />
      ) : (
        <NoContent
          title={
            isAgency
              ? t('onboarding.start.agencyTitle')
              : t('onboarding.start.title')
          }
          message={t('onboarding.start.message')}
          onClick={() => setShowOnboarding(true)}
        />
      ),
    [preferredSpecialties, hasFilters, setShowOnboarding, t, isAgency]
  );

  const afterSubmit = useCallback(() => {
    refreshAccount();
    refetchAccountPreferredSpecialties();
    setModal(null);
  }, [setModal, refreshAccount, refetchAccountPreferredSpecialties]);

  const openCreateSpecialtyModal = useCallback(() => {
    setModal({
      title: isAgency ? t('createModal.agencyTitle') : t('createModal.title'),
      content: (
        <CreateSpecialtyModal
          afterSubmit={afterSubmit}
          onClose={() => setModal(null)}
          createOnly
        />
      ),
    });
  }, [afterSubmit, setModal, t, isAgency]);

  return (
    <div data-testid="TEST-preferred-specialties-table">
      <div
        aria-labelledby="tab-company-specialties"
        role="tabpanel"
        className="flex w-full justify-between mb-s-8"
      >
        <h1 className="h3">{t('title')}</h1>

        {preferredSpecialties.length > 0 && (
          <Button
            icon={<i className="ri-add-line text-20" />}
            primary
            onClick={openCreateSpecialtyModal}
          >
            {isAgency ? t('addJob') : t('addSpecialty')}
          </Button>
        )}
      </div>

      <div className="mb-s-32">
        <PreferredSpecialtiesFilters
          updateSelectFilters={updateSelectFilters}
          updateTextFilters={updateTextFilter}
        />
      </div>

      <div>
        <Table
          data={preferredSpecialties}
          columns={columns}
          count={count}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          paginationState={currentPage}
          onPaginationChange={onPaginationChange}
          onPaginationReset={onPaginationReset}
          noDataComponent={noContent}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default PreferredSpecialtiesList;
