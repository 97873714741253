import { useMutation } from 'urql';

import { MutationUpdateCandidateCvAndOtherFiles } from '@infrastructure/graphql/offer';

const useOfferCandidateUpdateCandidateCVAndOtherFiles = () => {
  const [{ data, fetching, error }, updateCandidateCvAndOtherFiles] =
    useMutation(MutationUpdateCandidateCvAndOtherFiles);

  return {
    updateCandidateCvAndOtherFiles,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateUpdateCandidateCVAndOtherFiles;
