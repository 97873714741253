import { useTranslation } from 'react-i18next';

import { SectionGrid } from '@application/components';
import {
  CandidateOffer,
  CandidateOpportunity,
  CandidateRequest,
  CandidateRequestType,
} from '@domain/graphql.types';

import OutsourcingConditions from './OutsourcingConditions';
import RecruitmentConditions from './RecruitmentConditions';
import TemporaryPlacementConditions from './TemporaryPlacementConditions';

type AgencySummaryProps = {
  offer: CandidateOffer | undefined;
  request: CandidateRequest | CandidateOpportunity | undefined;
};

const AgencySummary = ({ offer, request }: AgencySummaryProps) => {
  const { t } = useTranslation('candidates');

  return (
    <>
      <div className="grid grid-cols-[auto_10rem] items-center py-s-6 border border-b-transparent rounded-t-md bg-secondary mt-s-32">
        <span className="text-18 font-semibold px-s-16">
          {t('subtitles.offerAgency')}
        </span>

        <span className="text-center whitespace-pre-line text-14">
          {t('subtitles.initialConditions')}
        </span>
      </div>

      <SectionGrid
        readOnly
        title={t('subtitles.offerAgency')}
        hideTitle
        gridColsClassName="grid-cols-[1fr_10rem]"
      >
        {request?.type === CandidateRequestType.Recruitment && (
          <RecruitmentConditions offer={offer} request={request} />
        )}

        {request?.type === CandidateRequestType.Outsourcing && (
          <OutsourcingConditions offer={offer} request={request} />
        )}

        {request?.type === CandidateRequestType.TemporaryPlacement && (
          <TemporaryPlacementConditions offer={offer} request={request} />
        )}
      </SectionGrid>
    </>
  );
};

export default AgencySummary;
