import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountUserDelete } from '@domain/account-user';

import { toViewModel } from '../view-models/delete-account-user.vm';

const useDeleteAccountUser = () => {
  const { data, fetching, error, deleteAccountUser } = useAccountUserDelete();

  const { t } = useTranslation('organization', {
    keyPrefix: 'users.list',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    accountUserDelete: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('actions.deleteMemberError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return {
    viewModel,
    deleteAccountUser,
  };
};

export default useDeleteAccountUser;
