import { gql } from '@urql/core';

const MutationCandidateRequestDelete = gql`
  mutation CandidateRequestDelete($input: DeleteCandidateRequestInput!) {
    candidateRequestDelete(input: $input) {
      ok
    }
  }
`;

export default MutationCandidateRequestDelete;
