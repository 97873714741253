import { useCandidateRequestOffers } from '@domain/candidate';

import { toViewModel } from '../view-models/get-candidate-request-offers.vm';

const useGetCandidateRequestOffers = (candidateRequestId: string) => {
  const { data, fetching, error, refreshCandidateRequestOffers } =
    useCandidateRequestOffers({
      input: { candidateRequestId },
    });

  const viewModel = toViewModel({
    candidateOffers: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refreshCandidateRequestOffers,
  };
};

export default useGetCandidateRequestOffers;
