/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { joinDescriptions } from '@application/utils';
import {
  CandidateRequest,
  CandidateRequestType,
  JobPaymentFrequencyCode,
} from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../../constants';

type EmployerBrandProps = {
  data: CandidateRequest;
};

const EmployerBrand = ({ data }: EmployerBrandProps) => {
  const { t, i18n } = useTranslation('candidates');

  const remuneration = useMemo(() => {
    switch (data.paymentFrequencyCode) {
      case JobPaymentFrequencyCode.Hourly:
        return t('suffix.currencyPerHourRange', {
          min: data.hourlyRateMin,
          max: data.hourlyRateMax,
        });
      case JobPaymentFrequencyCode.Yearly:
        return t('suffix.thousandsPerYearRange', {
          min: data.annualSalaryMin,
          max: data.annualSalaryMax,
        });
      case JobPaymentFrequencyCode.FixedAmount:
        return t('suffix.currency', { value: data.remunerationAmount });
      default:
        return '-';
    }
  }, [data, t]);

  const operationTerritories = useMemo(
    () =>
      joinDescriptions(
        data?.operationTerritories?.map(
          (territory) => territory.descriptions
        ) || [],
        i18n.language
      ),
    [data?.operationTerritories, i18n.language]
  );

  return (
    <>
      <tr>
        <th className="sr-only">
          <span>{t('labels.employerBrand')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.highlight, 'h-s-56 whitespace-nowrap')}>
          &nbsp;
        </td>
      </tr>

      <tr>
        <th scope="col" className="sr-only">
          {t('labels.remunerationOffered')}
        </th>

        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'border-l-0 whitespace-nowrap'
          )}
        >
          <PlaceholderSwitcher value={data.paymentFrequencyCode}>
            {remuneration}
          </PlaceholderSwitcher>
        </td>
      </tr>

      {data.type === CandidateRequestType.Recruitment ? (
        <tr>
          <th scope="col" className="sr-only">
            {t('labels.otherRemunerations')}
          </th>

          <td
            className={cn(
              TD_CLASS_NAME.base,
              TD_CLASS_NAME.fixed,
              'border-l-0 whitespace-nowrap'
            )}
          >
            <PlaceholderSwitcher value={data.otherPaymentType}>
              {getLocalizedDescription(
                data.otherPaymentType?.descriptions,
                i18n.language
              )}
            </PlaceholderSwitcher>
          </td>
        </tr>
      ) : (
        <>
          <tr>
            <th scope="col" className="sr-only">
              {t('labels.hoursPerWeek')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-l-0 whitespace-nowrap'
              )}
            >
              <PlaceholderSwitcher value={data.hoursPerWeek}>
                <TooltipEllipsis
                  toolTip={
                    data.hoursPerWeek
                      ?.map((h) => t('suffix.hours', { value: h }))
                      .join(', ') || ''
                  }
                >
                  <div>
                    {data.hoursPerWeek
                      ?.map((h) => t('suffix.hours', { value: h }))
                      .join(', ')}
                  </div>
                </TooltipEllipsis>
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.contractDuration')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                TD_CLASS_NAME.disabled,
                'border-l-0 whitespace-nowrap'
              )}
            >
              <PlaceholderSwitcher value={data.jobDurationInMonths}>
                {t('suffix.month', {
                  count: Number(data.jobDurationInMonths),
                })}
              </PlaceholderSwitcher>
            </td>
          </tr>

          <tr>
            <th scope="col" className="sr-only">
              {t('labels.potentialAmount')}
            </th>

            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                TD_CLASS_NAME.disabled,
                'border-l-0 whitespace-nowrap'
              )}
            >
              -
            </td>
          </tr>
        </>
      )}

      <tr>
        <th scope="col" className="sr-only">
          {t('labels.operationTerritory')}
        </th>

        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'border-l-0 whitespace-nowrap text-ellipsis overflow-hidden'
          )}
        >
          <PlaceholderSwitcher
            value={data.operationTerritories?.length || undefined}
          >
            <TooltipEllipsis toolTip={operationTerritories}>
              <div>{operationTerritories}</div>
            </TooltipEllipsis>
          </PlaceholderSwitcher>
        </td>
      </tr>

      {data.type === CandidateRequestType.Recruitment && (
        <tr>
          <th scope="col" className="sr-only">
            {t('labels.jobType')}
          </th>

          <td
            className={cn(
              TD_CLASS_NAME.base,
              TD_CLASS_NAME.fixed,
              'border-l-0 whitespace-nowrap'
            )}
          >
            <PlaceholderSwitcher value={data.jobDuration}>
              {getLocalizedDescription(
                data.jobDuration?.descriptions,
                i18n.language
              )}
            </PlaceholderSwitcher>
          </td>
        </tr>
      )}

      <tr>
        <th scope="col" className="sr-only">
          {t('labels.remoteWork')}
        </th>

        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'border-l-0 whitespace-nowrap'
          )}
        >
          <PlaceholderSwitcher value={data.jobMode}>
            {getLocalizedDescription(data.jobMode?.descriptions, i18n.language)}
          </PlaceholderSwitcher>
        </td>
      </tr>
    </>
  );
};

export default EmployerBrand;
