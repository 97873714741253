import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { To, useNavigate } from 'react-router';

import { Button } from '@application/components/buttons';
import { ButtonLink } from '@application/components/links';
import { useClickOutside } from '@application/hooks';
import { BillingPortalButton } from '@application/views/billing';
import { dispatchLogout } from '@domain/authentication';
import { cn } from '@utils/lib-utils';

import { USER_NAV_LINKS } from '../constants';

type UserNavProps = {
  isUserNavOpen: boolean;
  onClick: () => void;
  buttonRef: RefObject<HTMLButtonElement>;
};

const UserNav = ({ isUserNavOpen, onClick, buttonRef }: UserNavProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const userNavRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    refs: [buttonRef, userNavRef],
    isDisregarded: !isUserNavOpen,
    onOutsideClick: () => onClick(),
  });

  return (
    <div
      ref={userNavRef}
      className={`
        absolute -bottom-s-16 translate-y-full lg:-left-s-4 -right-s-4 w-[calc(100vw-1rem)] lg:w-[calc(100%+1.75rem)] lg:right-0 shadow
        ${isUserNavOpen ? 'block' : 'hidden'}`}
    >
      <nav role="navigation" className="bg-base-100 rounded-md">
        <ul className="menu menu-vertical text-16">
          <BillingPortalButton
            className="justify-start content-center p-s-12 mt-s-4"
            icon={<i className="ri-bank-card-line text-24" />}
            buttonType="ghost"
          />
          {USER_NAV_LINKS.map((link, index: number) =>
            link.isAppLink ? (
              <div
                key={link.label}
                onClick={onClick}
                role="button"
                tabIndex={0}
                className={cn(
                  'hover:bg-secondary focus:bg-secondary active:bg-secondary rounded-md',
                  { 'mt-s-4': index > 0 }
                )}
                onKeyDown={(
                  event: React.KeyboardEvent<HTMLDivElement>
                ): void => {
                  if (event.key === 'Enter') {
                    onClick();
                    navigate(link.url as To);
                  }
                }}
              >
                <li>
                  <ButtonLink
                    rootLocationCheck
                    className="justify-start content-center p-s-12"
                    to={link.url || ''}
                    isNavLink
                    icon={<i className={`${link.icon} text-24`} />}
                    ghost
                  >
                    <span className="font-normal text-16 text-neutral-secondary">
                      {t(`userNav.${link.label}`)}
                    </span>
                  </ButtonLink>
                </li>
              </div>
            ) : (
              <Button
                key={link.label}
                className="justify-start content-center p-s-12 mt-s-4"
                icon={<i className={`${link.icon} text-24`} />}
                onClick={dispatchLogout}
                ghost
              >
                <span className="font-normal text-16 text-neutral-secondary">
                  {t(`userNav.${link.label}`)}
                </span>
              </Button>
            )
          )}
        </ul>
      </nav>
    </div>
  );
};

export default UserNav;
