import { useAuth0 } from '@auth0/auth0-react';
import { useEventListener } from 'usehooks-ts';

export const useLogout = () => {
  const { logout } = useAuth0();

  const execute = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };
  useEventListener('kehops-logout', execute);

  return {
    execute,
  };
};

export const dispatchLogout = async () => {
  window.dispatchEvent(new Event('kehops-logout'));
};
