import { useRecruitmentNegotiation } from '@domain/negotiation';

import { toViewModel } from '../view-models/get-recruitment-negotiation.vm';

const useGetRecruitmentNegotiation = (negotiationId: string) => {
  const { data, fetching, error, reexecuteQuery } = useRecruitmentNegotiation({
    input: { negotiationId },
  });

  const viewModel = toViewModel({
    recruitmentNegotiation: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refreshRecruitmentNegotiation: reexecuteQuery,
  };
};

export default useGetRecruitmentNegotiation;
