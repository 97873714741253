export const TH_COL_CLASS_NAME =
  'h-s-72 px-s-16 [&:not(:first-child)]:border-l-1 border-b-1';

export const TH_ROW_CLASS_NAME = {
  base: 'h-s-56 min-w-[15rem] px-s-24 py-s-16 flex items-center font-normal text-14 text-left bg-neutral',
  highlight: 'bg-secondary font-semibold',
};

export const TD_CLASS_NAME = {
  base: 'h-s-56 min-w-[10rem] px-s-16 py-s-8 border-l-1 border-b-1',
  fixed: 'w-[10.75rem] min-w-[10.75rem] max-w-[10.75rem]',
  highlight: 'bg-secondary',
  modified: 'bg-warning text-warning-content',
  accepted: 'bg-success text-success-content',
  rejected: 'bg-error text-error-content',
};
