import { useMutation } from 'urql';

import { Mutation, MutationAccountUpdateLogoArgs } from '@domain/graphql.types';
import { AccountUpdateLogo } from '@infrastructure/graphql/account';

const useAccountUpdateLogo = () => {
  const [{ data, fetching, error }, updateAccountLogo] = useMutation<
    Pick<Mutation, 'accountUpdateLogo'>,
    MutationAccountUpdateLogoArgs
  >(AccountUpdateLogo);

  return {
    updateAccountLogo,
    data,
    fetching,
    error,
  };
};

export default useAccountUpdateLogo;
