import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';

import { AlertContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { useOfferDelete } from '@domain/offer';

import { toViewModel } from '../view-models/delete-offer.vm';

const useDeleteOffer = () => {
  const { data, fetching, error, deleteOffer } = useOfferDelete();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const matchDetails = useMatch(PrivatePage.VIEW_OFFER);
  const navigate = useNavigate();

  const viewModel = toViewModel({
    offerDelete: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      modifier: 'alert-error',
      action: t('deleteError'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.offerDelete?.ok) {
      setAlertContent({
        action: t('deleteSuccess'),
        modifier: 'alert-success',
      });

      if (matchDetails) {
        navigate(RootPrivatePage.OFFERS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.offerDelete?.ok]);

  return {
    viewModel,
    deleteOffer,
  };
};

export default useDeleteOffer;
