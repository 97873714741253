import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectField } from '@application/components/select-field';
import { mapOptions } from '@application/utils';
import { useGetIndustryTypes } from '@application/views/organization/profile/hooks';

import { TRANSLATION_PREFIX_KEYS } from '../constants';
import { CreateAccountFormFields } from '../schema';

type IndustryTypeProps = {
  type: 'ENTERPRISE' | 'AGENCY';
};

const IndustryType = ({ type }: IndustryTypeProps) => {
  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('signup', {
    keyPrefix: `${TRANSLATION_PREFIX_KEYS[type]}.form`,
  });

  const { control } = useFormContext<CreateAccountFormFields>();

  const { data: INDUSTRY_TYPES_LIST = [] } = useGetIndustryTypes();

  const INDUSTRY_TYPE_OPTIONS = mapOptions(INDUSTRY_TYPES_LIST, i18n.language);

  return (
    <fieldset className="mt-s-16">
      <legend className="mb-s-16 text-20 font-semibold">
        {t('industryType.heading')}
      </legend>

      <Controller
        name="industryTypeCode"
        control={control}
        render={({
          field: { onChange, onBlur, value, name },
          fieldState: { error },
        }) => (
          <SelectField
            className="max-w-[25rem]"
            onChange={(option: any) => onChange(option.value)}
            onBlur={onBlur}
            value={INDUSTRY_TYPE_OPTIONS.find((size) => size.value === value)}
            label={t('industryType.list')}
            name={name}
            options={INDUSTRY_TYPE_OPTIONS}
            helperText={error?.message && tGlobal(error.message)}
            invalid={!!error}
            menuPlacement="top"
            searchable
          />
        )}
      />
    </fieldset>
  );
};

export default IndustryType;
