import { gql } from 'urql';

import UserFragment from '../user.fragment';

const UserVerifyEmail = gql`
  ${UserFragment}

  mutation UserVerifyEmail($input: UserVerifyEmailInput!) {
    userVerifyEmail(input: $input) {
      user {
        ...UserFragment
      }
    }
  }
`;

export default UserVerifyEmail;
