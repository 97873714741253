import {
  OfferStatusTypeCode,
  RequestStatusTypeCode,
} from '@domain/graphql.types';

export const getRequestStatusClassNames = (value: RequestStatusTypeCode) => {
  switch (value) {
    case RequestStatusTypeCode.Agreement:
      return 'bg-badge-light-green border-badge-light-green text-badge-green';
    case RequestStatusTypeCode.InProgress:
      return 'bg-badge-light-orange border-badge-light-orange text-badge-orange';
    default:
      return 'bg-badge-light-gray border-badge-light-gray text-badge-gray';
  }
};

export const getRequestDropDownStatusClassNames = (
  value: RequestStatusTypeCode
) => {
  switch (value) {
    case RequestStatusTypeCode.Agreement:
      return '!bg-badge-light-green border-badge-light-green font-semibold';
    case RequestStatusTypeCode.InProgress:
      return '!bg-badge-light-orange border-badge-light-orange font-semibold';
    default:
      return '!bg-badge-light-gray border-badge-light-gray font-semibold';
  }
};

export const getOfferStatusClassNames = (value: OfferStatusTypeCode) => {
  switch (value) {
    case OfferStatusTypeCode.Accepted:
      return 'bg-badge-light-green border-badge-light-green text-badge-green';
    case OfferStatusTypeCode.Published:
      return 'bg-badge-light-orange border-badge-light-orange text-badge-orange';
    default:
      return 'bg-badge-light-gray border-badge-light-gray text-badge-gray';
  }
};

export const getOfferDropDownStatusClassNames = (
  value: OfferStatusTypeCode
) => {
  switch (value) {
    case OfferStatusTypeCode.Accepted:
      return '!bg-badge-light-green border-badge-light-green font-semibold';
    case OfferStatusTypeCode.Published:
      return '!bg-badge-light-orange border-badge-light-orange font-semibold';
    default:
      return '!bg-badge-light-gray border-badge-light-gray font-semibold';
  }
};
