import { useTranslation } from 'react-i18next';

import { IconButton, LanguageButtons } from '@application/components';
import { OnboardingPage } from '@application/enums/pagesUrl';
import { dispatchLogout } from '@domain/authentication';

import { Container } from './components';
import { ChoiceLink } from './steps';

export default function AccountType() {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('signup', { keyPrefix: 'accountType' });

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end m-s-32">
        <IconButton
          text={tGlobal('backPreviousPage')}
          onClick={dispatchLogout}
          icon={<i className="ri-arrow-left-line" />}
        />
        <LanguageButtons />
      </div>

      <Container>
        <h1 className="mt-auto mb-s-32">{t('heading')}</h1>

        <div className="-my-s-12">
          <ChoiceLink
            url={OnboardingPage.CREATE_COMPANY}
            text={t('choice.fill')}
          />
          <ChoiceLink
            url={OnboardingPage.CREATE_AGENCY}
            text={t('choice.place')}
          />
          <ChoiceLink
            url={OnboardingPage.JOIN_COMPANY}
            text={t('choice.join')}
          />
        </div>

        <p className="mt-auto pt-s-24 text-center">
          {t('loginLink.text')}

          <button
            className="text-16 ml-s-8 p-s-0 btn btn-link normal-case hover:no-underline"
            type="button"
            onClick={dispatchLogout}
          >
            {t('loginLink.linkLabel')}
          </button>
        </p>
      </Container>
    </div>
  );
}
