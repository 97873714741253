import { useMutation } from 'urql';

import { MutationUpdateCandidateAndCv } from '@infrastructure/graphql/offer';

const useOfferCandidateUpdateCandidateAndCV = () => {
  const [{ data, fetching, error }, updateCandidateAndCv] = useMutation(
    MutationUpdateCandidateAndCv
  );

  return {
    updateCandidateAndCv,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateUpdateCandidateAndCV;
