import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { OfferErrors } from '@domain/graphql.types';
import { useOfferSaveAndPublish } from '@domain/offer';

import { toViewModel } from '../view-models/save-and-publish-offer.vm';

const useSaveAndPublishOffer = () => {
  const { data, fetching, error, saveAndPublish } = useOfferSaveAndPublish();

  const { t } = useTranslation('offers', {
    keyPrefix: 'messages',
  });

  const navigate = useNavigate();
  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    offer: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    const cannotPublishOfferRequestCompleted = error.graphQLErrors.find(
      (e) => e.message === OfferErrors.CannotPublishOfferRequestCompleted
    );

    setAlertContent(
      cannotPublishOfferRequestCompleted
        ? {
            text: t('cannotPublishOfferRequestCompletedError'),
            modifier: 'alert-error',
          }
        : {
            action: t('saveAndPublishOfferError'),
            modifier: 'alert-error',
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.offerUpdate?.offer) {
      setAlertContent({
        action: t('saveAndPublishOfferSuccess'),
        modifier: 'alert-success',
      });

      navigate(
        generatePath(PrivatePage.VIEW_OFFER, {
          id: data.offerUpdate.offer.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.offerUpdate?.offer]);

  return { saveAndPublish, viewModel };
};

export default useSaveAndPublishOffer;
