import { Table } from '@application/components/table';
import { OperationUnitsFilters } from '@domain/graphql.types';

import { useOperationUnitsList } from '../hooks/useOperationUnitsList';

type OperationUnitListProps = {
  filters: OperationUnitsFilters;
};

const OperationUnitList = ({ filters }: OperationUnitListProps) => {
  const { columns, operationUnits, sorting, onSortingChange, isLoading } =
    useOperationUnitsList(filters);

  return (
    <div className="relative" data-testid="TEST-operation-units-table">
      <Table
        data={operationUnits}
        columns={columns}
        onSortingChange={onSortingChange}
        sortingState={sorting}
        isLoading={isLoading}
      />
    </div>
  );
};

export default OperationUnitList;
