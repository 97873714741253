import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { Cluster } from '@application/components/container-layouts';
import { ButtonLink } from '@application/components/links';
import { cn } from '@utils/lib-utils';

import { Tooltip } from '../tooltip';

type FormActionsProps = {
  cancelPath: string;
  nextStep?: () => void;
  prevStep?: () => void;
  isLastStep?: boolean;
  disabled?: boolean;
  publishDisabled?: boolean;
  saveLoading?: boolean;
  publishLoading?: boolean;
  saveToolTip?: string;
  publishToolTip?: string;
  onSave?: () => void;
  onPublish?: () => void;
  className?: string;
};

const FormActions = ({
  disabled = false,
  publishDisabled = false,
  saveLoading = false,
  publishLoading = false,
  publishToolTip,
  saveToolTip,
  isLastStep,
  nextStep,
  prevStep,
  onSave,
  onPublish,
  cancelPath,
  className: customClassName,
}: FormActionsProps) => {
  const { t: tGlobal } = useTranslation();
  const computedDisabled = saveLoading || publishLoading || disabled;

  const className = cn('mt-s-32 flex justify-between', customClassName);

  const publishButton = useMemo(
    () => (
      <Button
        primary
        icon={<i className="ri-send-plane-line" />}
        type="submit"
        onClick={onPublish}
        disabled={computedDisabled || publishDisabled}
        loading={publishLoading}
      >
        {tGlobal('button.publish')}
      </Button>
    ),
    [computedDisabled, onPublish, publishDisabled, publishLoading, tGlobal]
  );

  const saveButton = useMemo(
    () => (
      <Button
        icon={<i className="ri-save-line" />}
        type="submit"
        onClick={onSave}
        primary={!onPublish}
        disabled={computedDisabled}
        loading={saveLoading}
      >
        {tGlobal('button.save')}
      </Button>
    ),
    [computedDisabled, onPublish, onSave, saveLoading, tGlobal]
  );

  return (
    <div className={className}>
      <Cluster justify="start">
        {prevStep && (
          <Button
            icon={<i className="ri-arrow-left-line" />}
            onClick={prevStep}
          >
            {tGlobal('button.back')}
          </Button>
        )}
      </Cluster>

      <Cluster justify="end">
        <ButtonLink to={cancelPath}>{tGlobal('button.cancel')}</ButtonLink>

        {nextStep && (
          <Button disabled={computedDisabled} primary onClick={nextStep}>
            {tGlobal('button.next')}
          </Button>
        )}

        {isLastStep && (
          <>
            <div>
              {saveToolTip ? (
                <Tooltip message={saveToolTip}>{saveButton}</Tooltip>
              ) : (
                saveButton
              )}
            </div>

            {onPublish && (
              <div>
                {publishToolTip ? (
                  <Tooltip message={publishToolTip}>{publishButton}</Tooltip>
                ) : (
                  publishButton
                )}
              </div>
            )}
          </>
        )}
      </Cluster>
    </div>
  );
};

export default FormActions;
