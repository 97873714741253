import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';

import { NegoroomChat, PageLoader } from '@application/components';
import { ChatContent } from '@application/components/negoroom-chat/NegoroomChat';
import { PrivatePage } from '@application/enums/pagesUrl';
import { getLocalizedDescription } from '@utils/i18n-utils';

import {
  useCreateRecruitmentNegotiationRoomMessage,
  useGetRecruitmentNegotiationRoom,
  useRecruitmentNegotiationRoomMessagesList,
} from '../hooks';

const ViewRecruitmentNegotiationRoomChatPage = () => {
  const { id = '' } = useParams();
  const { i18n } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.room',
  });

  const {
    viewModel: { data, isLoading: isRoomLoading },
  } = useGetRecruitmentNegotiationRoom(id);

  const { createRecruitmentNegotiationRoomMessage } =
    useCreateRecruitmentNegotiationRoomMessage();

  const {
    recruitmentNegotiationRoomMessages,
    isLoading: isMessagesListLoading,
    refetchRecruitmentNegotiationRoomMessages,
  } = useRecruitmentNegotiationRoomMessagesList({
    recruitmentNegotiationRoomId: id ?? '',
  });

  const onBackClick = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_DETAILS, {
        id: data?.id ?? '',
      }),
    [data?.id]
  );

  const chatContent: ChatContent = useMemo(
    () => ({
      messageList: recruitmentNegotiationRoomMessages?.map((message) => ({
        position:
          message.accountId === data?.offerAccountId
            ? ('right' as const)
            : ('left' as const),
        date: message.sentAt,
        viewed: true,
        content: message.message,
      })),
    }),
    [data?.offerAccountId, recruitmentNegotiationRoomMessages]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createRecruitmentNegotiationRoomMessage({
        input: {
          recruitmentNegotiationRoomId: id,
          message,
        },
      });

      if (recruitmentNegotiationRoomMessages.length < 1) {
        refetchRecruitmentNegotiationRoomMessages();
      }
    },
    [
      createRecruitmentNegotiationRoomMessage,
      id,
      recruitmentNegotiationRoomMessages.length,
      refetchRecruitmentNegotiationRoomMessages,
    ]
  );

  return isRoomLoading ? (
    <PageLoader />
  ) : (
    <NegoroomChat
      isLoading={isMessagesListLoading}
      title={
        getLocalizedDescription(
          data?.request?.jobSpecialty?.descriptions,
          i18n.language
        ) ?? ''
      }
      subtitle={data?.request?.operationUnit?.name ?? ''}
      backTo={onBackClick}
      chatContent={chatContent}
      onSendMessage={handleSendMessage}
      refetchMessages={refetchRecruitmentNegotiationRoomMessages}
    />
  );
};

export default ViewRecruitmentNegotiationRoomChatPage;
