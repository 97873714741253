import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RadioGroup, Stack, TextAreaField } from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { extractLanguage } from '@utils/i18n-utils';

import { Section } from '../../components';
import { CandidateOfferFormFields } from '../../schema';

type SalesPitchFieldsProps = {
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
};

const SalesPitchFields = ({
  control,
  errors,
  setValue,
}: SalesPitchFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  return (
    <Section legend={t('subtitles.salesPitch')} className="mt-s-40">
      <Stack space={24}>
        <Controller
          name="linkProfile"
          control={control}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              alignRow
              legendSize="medium"
              data={BOOLEAN_VALUES}
              legend={t('labels.joinOrganizationProfile')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as any);
              }}
              value={value ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />

        <Controller
          name="salesPitch"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              value={value || ''}
              className="mt-s-0"
              label={t('labels.salesPitch')}
              rows={4}
              maxChar={1024}
              onChange={onChange}
              invalid={!!errors.salesPitch}
              helperText={
                errors.salesPitch?.message &&
                tGlobal(errors.salesPitch?.message, { max: '1024' })
              }
            />
          )}
        />
      </Stack>
    </Section>
  );
};

export default SalesPitchFields;
