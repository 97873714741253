import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useCandidateRequestCreate } from '@domain/candidate';

import { toViewModel } from '../view-models/create-candidate-request.vm';

const useCreateCandidateRequest = () => {
  const { data, fetching, error, createCandidateRequest } =
    useCandidateRequestCreate();

  const { t } = useTranslation('candidates', {
    keyPrefix: 'messages',
  });

  const { setAlertContent } = useContext(AlertContext);
  const navigate = useNavigate();

  const viewModel = toViewModel({
    candidateRequest: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('createError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.candidateRequestCreate.candidateRequest) {
      setAlertContent({
        action: t('createSuccess'),
        modifier: 'alert-success',
      });

      navigate(
        generatePath(PrivatePage.CANDIDATE_REQUEST_DETAILS, {
          id: data.candidateRequestCreate.candidateRequest.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { createCandidateRequest, viewModel };
};

export default useCreateCandidateRequest;
