import { useQuery } from 'urql';

import { Query } from '@domain/graphql.types';
import { JobSpecialties } from '@infrastructure/graphql/job';
import { defaultPageData } from '@infrastructure/graphql/pagination';
import { flattenEdges } from '@utils/data-utils';

const useJobSpecialtiesGet = () => {
  const [result] = useQuery<Pick<Query, 'jobSpecialties'>, void>({
    query: JobSpecialties,
    requestPolicy: 'cache-first',
  });

  const { data: paginatedData, fetching, error } = result;

  const pageData = paginatedData?.jobSpecialties.pageData || defaultPageData;
  const data = flattenEdges(paginatedData?.jobSpecialties.page.edges?.slice());

  return {
    data,
    pageData,
    fetching,
    error,
  };
};

export default useJobSpecialtiesGet;
