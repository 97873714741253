import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useOfferCandidateDelete } from '@domain/offer';

import { toViewModel } from '../view-models/delete-offer-candidate.vm';

const useDeleteOfferCandidate = () => {
  const { data, fetching, error, deleteOfferCandidate } =
    useOfferCandidateDelete();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    offerCandidateDelete: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      modifier: 'alert-error',
      action: t('deleteCandidateError'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.offerCandidateDelete?.ok) {
      setAlertContent({
        modifier: 'alert-success',
        action: t('deleteCandidateSuccess'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.offerCandidateDelete?.ok]);

  return {
    viewModel,
    deleteOfferCandidate,
  };
};

export default useDeleteOfferCandidate;
