import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGrid } from '@application/components';
import { Offer, Request, RequestTypeCode } from '@domain/graphql.types';

import GeneralConditions from './GeneralConditions';
import HeadhunterConditions from './HeadhunterConditions';
import InternationalRecruitmentConditions from './InternationalRecruitmentConditions';
import OutsourcingConditions from './OutsourcingConditions';
import TemporaryPlacementConditions from './TemporaryPlacementConditions';

type ConditionsSummaryProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const ConditionsSummary = ({ data, request }: ConditionsSummaryProps) => {
  const { t } = useTranslation('requests');

  const conditions = useMemo(
    () => [
      {
        title: 'conditions',
        children: <GeneralConditions data={data} request={request} />,
        isVisible: true,
      },
      {
        title: 'headHunter',
        children: <HeadhunterConditions data={data} request={request} />,
        isVisible:
          request?.types?.includes(RequestTypeCode.Headhunter) ||
          data?.requestTypes?.includes(RequestTypeCode.Headhunter),
      },
      {
        title: 'internationalRecruitment',
        children: (
          <InternationalRecruitmentConditions data={data} request={request} />
        ),
        isVisible:
          request?.types?.includes(RequestTypeCode.InternationalRecruitment) ||
          data?.requestTypes?.includes(
            RequestTypeCode.InternationalRecruitment
          ),
      },
      {
        title: 'outsourcing',
        children: <OutsourcingConditions data={data} request={request} />,
        isVisible:
          request?.types?.includes(RequestTypeCode.Outsourcing) ||
          data?.requestTypes?.includes(RequestTypeCode.Outsourcing),
      },
      {
        title: 'temporaryPlacement',
        children: (
          <TemporaryPlacementConditions data={data} request={request} />
        ),
        isVisible:
          request?.types?.includes(RequestTypeCode.TemporaryHelp) ||
          data?.requestTypes?.includes(RequestTypeCode.TemporaryHelp),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, t]
  );

  return (
    <div>
      {conditions.map(
        (c) =>
          c.isVisible && (
            <div key={c.title}>
              <div className="grid grid-cols-[auto_10rem] items-center py-s-6 px-s-16 pr-s-0 border border-b-transparent rounded-t-md bg-secondary mt-s-32">
                <span className="text-18 font-semibold">
                  {t(`subtitles.${c.title}`)}
                </span>

                <span className="text-center whitespace-pre-line">
                  {t(`subtitles.initialConditions`)}
                </span>
              </div>
              <SectionGrid
                readOnly
                title={t(`subtitles.${c.title}`)}
                hideTitle
                gridColsClassName="grid-cols-[1fr_10rem]"
              >
                {c.children}
              </SectionGrid>
            </div>
          )
      )}
    </div>
  );
};

export default ConditionsSummary;
