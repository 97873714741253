import { useMemo } from 'react';

import { cn } from '@utils/lib-utils';

type RankBadgeProps = {
  className?: string;
  rank?: string | number;
};

const RankBadge = ({ rank, className: customClassName }: RankBadgeProps) => {
  const checkBgColor = useMemo(() => {
    switch (Number(rank)) {
      case 1:
        return 'bg-rankBadge-first';
      case 2:
        return 'bg-rankBadge-second';
      case 3:
        return 'bg-rankBadge-third';
      default:
        return 'border';
    }
  }, [rank]);

  return (
    <div
      className={cn(
        'flex items-center justify-center w-s-28 h-s-28 mx-auto rounded-md font-semibold',
        checkBgColor,
        customClassName
      )}
    >
      {rank || '-'}
    </div>
  );
};

export default RankBadge;
