import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { OfferCandidate, OfferCandidateResponse } from '@domain/graphql.types';

type ToViewModel = {
  offerCandidate: {
    data: OfferCandidateResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  offerCandidate: { data, fetching, error },
}: ToViewModel): ViewModel<OfferCandidate | undefined> =>
  new ViewModel<OfferCandidate | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.offerCandidate || undefined,
  });
