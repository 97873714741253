// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components/switch';
import { RequestGeneralConditions } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../../constants';

const conditionsKey: (keyof RequestGeneralConditions)[] = [
  'maximumBudgetValue',
  'paymentMethodValue',
  'depositValue',
  'maxRecruitmentPeriodValue',
  'minGuaranteedPeriodValue',
];

type GeneralConditionsProps = {
  data: RequestGeneralConditions;
};

const GeneralConditions = ({ data }: GeneralConditionsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const getCellContent = useCallback(
    (condition: keyof RequestGeneralConditions) => {
      const cellValue = data[condition];
      switch (condition) {
        case 'maximumBudgetValue':
          return tGlobal('suffix.currencyValue', {
            value: Number(cellValue),
          });
        case 'depositValue':
          return tGlobal('suffix.currencyValue', { value: cellValue });
        case 'paymentMethodValue':
          return tGlobal(
            `enum.requestConditionPaymentMethodCode.short.${cellValue
              ?.toString()
              .toLowerCase()}`
          );
        case 'maxRecruitmentPeriodValue':
          return tGlobal('suffix.week', { count: cellValue as number });
        case 'minGuaranteedPeriodValue':
          return tGlobal('suffix.month', { count: cellValue as number });
        default:
          return '-';
      }
    },
    [data, tGlobal]
  );

  return (
    <>
      <tr>
        <th className="sr-only">
          <span>{t('generalConditions')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.base, TD_CLASS_NAME.highlight)}>
          &nbsp;
        </td>
      </tr>
      {conditionsKey.map((condition: keyof RequestGeneralConditions) => (
        <tr key={`requestGeneralConditions-${condition}`}>
          <th scope="col" className="sr-only">
            {t(condition)}
          </th>
          <td
            className={cn(
              TD_CLASS_NAME.base,
              TD_CLASS_NAME.fixed,
              'border-l-0'
            )}
          >
            <PlaceholderSwitcher value={data[condition]}>
              <span>{getCellContent(condition)}</span>
            </PlaceholderSwitcher>
          </td>
        </tr>
      ))}
    </>
  );
};

export default GeneralConditions;
