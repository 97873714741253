import { useTranslation } from 'react-i18next';

import { Request } from '@domain/graphql.types';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type RequestTableProps = {
  request: Request;
  className?: string;
};

const RequestTable = ({
  request,
  className: customClassName = '',
}: RequestTableProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  return (
    <table className={customClassName}>
      <caption className="sr-only">{t('initialRequestProposition')}</caption>
      <TableHeader name={request?.account?.name} />
      <TableBody request={request} />
    </table>
  );
};

export default RequestTable;
