import {
  IRemoteNotification,
  Timestamp,
  useNotification,
} from '@magicbell/magicbell-react';
import { KeyboardEvent, MouseEvent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@application/components/buttons';

import { NotificationCenterContext } from '../context';
import { categoryConfig } from './categories.config';
import { CategoryMap } from './types';

type NotificationProps = {
  notification: IRemoteNotification;
};

/**
 * WIP: The Notification component
 * 
 * TODO: handle and display the sent at time and unread state.
 *
 * @param props.notification The notification data returned by MagicBell
 *
 * @example
 * <MagicBell
    apiKey={...}
    userEmail={email}
    >
    {(props) => (
      <FloatingNotificationInbox
        width={380}
        layout={['content', 'header']}
        NotificationItem={Notification}
        {...props}
      />
    )}
  </MagicBell>
 */
const Notification = ({ notification: notif }: NotificationProps) => {
  const notification = useNotification(notif);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { setIsPanelOpen } = useContext(NotificationCenterContext);

  const category = notification.category as keyof CategoryMap;
  const notificationCategory = categoryConfig[category];

  const onNotificationClick = useCallback(() => {
    const { getActionUrl } = categoryConfig[category];

    if (getActionUrl) {
      navigate(getActionUrl(notification));
    }

    notification.markAsRead();
    setIsPanelOpen(false);
  }, [navigate, notification, setIsPanelOpen, category]);

  const onNotificationKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onNotificationClick();
      }
    },
    [onNotificationClick]
  );

  const onDeleteClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      notification.delete();
      event.stopPropagation();
    },
    [notification]
  );

  return (
    <div
      className="notification"
      tabIndex={0}
      role="button"
      onClick={onNotificationClick}
      onKeyDown={onNotificationKeyDown}
    >
      <div className="notification-container">
        <div className="notification-main-section">
          <div className="notification-icon">
            <div className="icon-wrapper">
              {notificationCategory && (
                <i className={`ri-${notificationCategory.icon}-line`} />
              )}
            </div>
          </div>

          <div>
            <div className="notification-title-section">
              {notificationCategory && (
                <span>{t(notificationCategory.titleKey)}</span>
              )}

              <div className="notification-dot">
                {!notification.isRead && (
                  <i className="ri-circle-fill !text-10 text-accent" />
                )}
              </div>
            </div>

            <div className="notification-content">
              <span>
                {notificationCategory &&
                  notificationCategory.getMessage(
                    t,
                    i18n.language,
                    notification
                  )}
              </span>
            </div>
          </div>
        </div>

        <div className="notification-end-section">
          <div className="notification-timestamp">
            {notification.sentAt && (
              <Timestamp date={notification.sentAt} tooltipPlacement="left" />
            )}
          </div>

          <IconButton
            ghost
            size="x-small"
            onClick={onDeleteClick}
            icon={<i className="ri-delete-bin-line" />}
            text={t('notifications.label')}
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
