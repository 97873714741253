import { createContext } from 'react';

type NavContextProps = {
  isNavOpen: boolean;
  isBottomMenuOpen: boolean;
  setIsNavOpen: (state: boolean) => void;
  setIsBottomMenuOpen: (state: boolean) => void;
};

const NavContext = createContext<NavContextProps>({
  isNavOpen: false,
  isBottomMenuOpen: false,
  setIsNavOpen: () => {},
  setIsBottomMenuOpen: () => {},
});

export default NavContext;
