// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

import { TH_COL_CLASS_NAME } from '../constants';

const TableHeader = () => {
  const { t } = useTranslation('candidates');

  return (
    <thead>
      <tr>
        <td className="sr-only">&nbsp;</td>
        <th className={cn(TH_COL_CLASS_NAME, '!border-l-0')}>
          {t('labels.initialOffer')}
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
