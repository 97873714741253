import { gql } from 'urql';

const MutationRecruitmentNegotiationRoomMessageCreate = gql`
  mutation RecruitmentNegotiationRoomMessageCreate(
    $input: RecruitmentNegotiationRoomMessageCreateInput!
  ) {
    recruitmentNegotiationRoomMessageCreate(input: $input) {
      message {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationRoomMessageCreate;
