import { ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

type LabelProps = {
  /**
   * Specify the content of the `<label>`
   */
  children: ReactNode;
  /**
   * Provide a custom className to be applied to the containing `<label>` node
   */
  className?: string;
  /**
   * Provide a unique id for the given `<label>` to specify
   * which form element it is bound to
   */
  htmlFor: string;
  /**
   * Specify whether the `<label>` should be disabled
   */
  disabled?: boolean;
  /**
   * Specify whether the `<label>` should be invalid
   */
  invalid?: boolean;
};

/**
 * A text caption or description of a field's input.
 *
 * Note: use in combination with field's inputs into a form only.
 */
const Label = ({
  children,
  className: customClassName,
  htmlFor,
  disabled,
  invalid,
}: LabelProps) => {
  const className = cn(
    'label text-14 pt-s-0 text-neutral-secondary',
    customClassName,
    {
      'text-disabled-content': disabled,
      'text-error-content': invalid,
    }
  );

  return (
    <label htmlFor={htmlFor} className={className}>
      {children}
    </label>
  );
};

export default Label;
