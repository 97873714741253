import { cn } from '@utils/lib-utils';

export type ModalTitleProps = {
  children: React.ReactNode | null;
  id: string;
  className?: string;
};

const ModalTitle = ({
  id,
  children,
  className: customClassName,
}: ModalTitleProps) => {
  const className = cn(
    'text-22 font-semibold mr-s-32 mb-s-24',
    customClassName
  );

  return (
    <h2 id={id} className={className}>
      {children}
    </h2>
  );
};

export default ModalTitle;
