import { ColumnDef, flexRender, HeaderGroup } from '@tanstack/react-table';

import { cn } from '@utils/lib-utils';

type TableHeadProps<T> = {
  headerGroups: HeaderGroup<T>[];
};

const getStyle = <T,>(columnDef: ColumnDef<T, any>) => ({
  minWidth: columnDef.minSize,
  width: columnDef.size,
  maxWidth: columnDef.maxSize,
});

const TableHead = <T,>({ headerGroups }: TableHeadProps<T>) => (
  <thead>
    {headerGroups.map((headerGroup, index: number) => (
      <tr key={headerGroup.id} className="bg-secondary">
        {headerGroup.headers.map((header) => (
          <th
            key={header.id}
            colSpan={header.colSpan}
            style={getStyle(header.column.columnDef)}
            className={cn(
              'p-s-12 text-16 text-neutral-secondary text-start font-normal',
              {
                'pr-s-20': index < headerGroup.headers.length - 1,
              }
            )}
          >
            {header.isPlaceholder ? null : (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                {...{
                  className: header.column.getCanSort() ? 'cursor-pointer' : '',
                  onClick: header.column.getToggleSortingHandler(),
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {{
                  asc: (
                    <i className="ri-arrow-up-line text-18 leading-5 ml-s-4" />
                  ),
                  desc: (
                    <i className="ri-arrow-down-line text-18 leading-5 ml-s-4" />
                  ),
                }[header.column.getIsSorted() as string] ?? null}
              </div>
            )}
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

export default TableHead;
