// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0  */
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, Switch } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import {
  Offer,
  OfferHeadhunterConditions,
  OfferStatusTypeCode,
  RecruitmentNegotiationHeadhunterPropertiesState,
  RecruitmentNegotiationPropertyState,
  RecruitmentNegotiationRoom,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';
import { isDefined } from '@utils/type-utils';

import { StateBadge, StateButtons, StatesMenu } from '../../../components';
import { RecruitmentNegotiationContext } from '../../../context';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';

const conditionsKeys: [
  keyof OfferHeadhunterConditions,
  keyof Omit<RecruitmentNegotiationHeadhunterPropertiesState, '__typename'>,
][] = [
  ['maximumReplacement', 'maximumReplacement'],
  ['headhuntingExclusivityPeriod', 'exclusivityPeriod'],
];

const HeadHunterConditions = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const {
    rooms,
    checkedRooms,
    onAcceptValues,
    onRejectValues,
    getModifiedProperties,
    shouldDisplayMenu,
  } = useContext(RecruitmentNegotiationContext);

  const getCellContent = useCallback(
    (condition: keyof OfferHeadhunterConditions, offer: Offer) => {
      const cellValue = offer.headhunterContractualConditions?.[condition];

      switch (condition) {
        case 'maximumReplacement':
          return String(cellValue);
        case 'headhuntingExclusivityPeriod':
          return tGlobal('suffix.week', { count: cellValue as number });
        default:
          return '-';
      }
    },
    [tGlobal]
  );

  const renderStatesMenu = useCallback(
    (
      id: string,
      property: keyof RecruitmentNegotiationHeadhunterPropertiesState,
      state: RecruitmentNegotiationPropertyState,
      content: string
    ) => (
      <StatesMenu
        onAccept={onAcceptValues(id, 'headhunterPropertiesState', [property])}
        onReject={onRejectValues(id, 'headhunterPropertiesState', [property])}
      >
        <Switch fallback={<span>{content}</span>}>
          <Switch.Match
            when={state === RecruitmentNegotiationPropertyState.Modified}
          >
            <StateBadge value={content} state={state} />
          </Switch.Match>
        </Switch>
      </StatesMenu>
    ),
    [onAcceptValues, onRejectValues]
  );

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('headHunterConditions')}
        </th>

        {rooms?.map(
          ({ id, headhunterPropertiesState }: RecruitmentNegotiationRoom) => (
            <td
              key={`offerHeadHunterConditions-${id}`}
              className={cn(
                TH_ROW_CLASS_NAME.highlight,
                'border-l-1 border-b-1',
                {
                  '!border-x-main border-x-2': checkedRooms.includes(id),
                }
              )}
            >
              <Switch fallback={<>&nbsp;</>}>
                <Switch.Match
                  when={conditionsKeys.some(
                    ([, state]) =>
                      headhunterPropertiesState[state] ===
                      RecruitmentNegotiationPropertyState.Modified
                  )}
                >
                  <StateButtons
                    onAccept={onAcceptValues(
                      id,
                      'headhunterPropertiesState',
                      getModifiedProperties(
                        headhunterPropertiesState
                      ) as (keyof RecruitmentNegotiationHeadhunterPropertiesState)[]
                    )}
                    onReject={onRejectValues(
                      id,
                      'headhunterPropertiesState',
                      getModifiedProperties(
                        headhunterPropertiesState
                      ) as (keyof RecruitmentNegotiationHeadhunterPropertiesState)[]
                    )}
                  />
                </Switch.Match>
              </Switch>
            </td>
          )
        )}
      </tr>

      {conditionsKeys.map(([condition, property]) => (
        <tr key={`offerHeadHunterConditions-${condition}`}>
          <th scope="col" className={TH_ROW_CLASS_NAME.base}>
            <TooltipEllipsis toolTip={t(condition)} lineClamp={2}>
              <div>{t(condition)}</div>
            </TooltipEllipsis>
          </th>

          {rooms?.map(
            ({
              id,
              negotiatingOffer: offer,
              status,
              headhunterPropertiesState,
            }: RecruitmentNegotiationRoom) => (
              <td
                key={`offerHeadHunterConditions-${condition}-${id}`}
                className={cn(TD_CLASS_NAME.base, {
                  'border-x-2 !border-x-main': checkedRooms.includes(id),
                  'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
                  'p-s-0':
                    isDefined(
                      offer.headhunterContractualConditions?.[condition]
                    ) &&
                    shouldDisplayMenu(
                      status,
                      headhunterPropertiesState[property]
                    ),
                })}
              >
                <PlaceholderSwitcher
                  value={offer.headhunterContractualConditions?.[condition]}
                >
                  <Switch
                    fallback={
                      <StateBadge
                        value={getCellContent(condition, offer)}
                        state={headhunterPropertiesState[property]}
                      />
                    }
                  >
                    <Switch.Match
                      when={shouldDisplayMenu(
                        status,
                        headhunterPropertiesState[property]
                      )}
                    >
                      {renderStatesMenu(
                        id,
                        property,
                        headhunterPropertiesState[property],
                        getCellContent(condition, offer)
                      )}
                    </Switch.Match>
                  </Switch>
                </PlaceholderSwitcher>
              </td>
            )
          )}
        </tr>
      ))}
    </>
  );
};

export default HeadHunterConditions;
