import { gql } from 'urql';

import { RecruitmentNegotiationListFragment } from './fragment';

const RecruitmentNegotiations = gql`
  ${RecruitmentNegotiationListFragment}

  query RecruitmentNegotiations($filterBy: RecruitmentNegotiationsFilters) {
    recruitmentNegotiations(filterBy: $filterBy) {
      results {
        ...RecruitmentNegotiationListFragment
      }
      totalCount
    }
  }
`;

export default RecruitmentNegotiations;
