import { useOffer } from '@domain/offer';

import { toViewModel } from '../view-models/get-offer.vm';

const useGetOffer = (offerId: string) => {
  const { data, fetching, error, reexecuteQuery } = useOffer({
    input: { offerId },
  });

  const viewModel = toViewModel({
    offer: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchOffer: reexecuteQuery,
  };
};

export default useGetOffer;
