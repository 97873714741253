import { gql } from 'urql';

import { RequestFragment } from '@infrastructure/graphql/request';

import RecruitmentNegotiationRoomFragment from './recruitment.negotiation.room.fragment';

const RecruitmentNegotiationFragment = gql`
  ${RecruitmentNegotiationRoomFragment}
  ${RequestFragment}

  fragment RecruitmentNegotiationFragment on RecruitmentNegotiation {
    id
    accountId
    requestId
    userId
    status
    createdAt
    updatedAt
    rooms {
      ...RecruitmentNegotiationRoomFragment
    }
    request {
      ...RequestFragment
    }
  }
`;

export default RecruitmentNegotiationFragment;
