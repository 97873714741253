import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateNegotiationRoomRejectArgs,
} from '@domain/graphql.types';
import { MutationCandidateNegotiationRoomReject } from '@infrastructure/graphql/candidate';

const useCandidateNegotiationRoomReject = () => {
  const [{ data, fetching, error }, rejectNegotiationRoom] = useMutation<
    Pick<Mutation, 'candidateNegotiationRoomReject'>,
    MutationCandidateNegotiationRoomRejectArgs
  >(MutationCandidateNegotiationRoomReject);

  return {
    rejectNegotiationRoom,
    data,
    fetching,
    error,
  };
};

export default useCandidateNegotiationRoomReject;
