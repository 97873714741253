import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { LoadingSpinner } from '@application/components/spinner';
import { NoContent } from '@application/components/table';
import { CandidateNegotiationRoomsFilters } from '@domain/graphql.types';

import { RoomListCard } from '../../components';
import {
  useCandidateNegotiationRoomFilters,
  useCandidateNegotiationRoomsList,
} from '../../hooks';

const CandidateNegotiationRoomsList = () => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'list.noContent.rooms',
  });

  const [tableFilters, setTableFilters] =
    useState<CandidateNegotiationRoomsFilters>();

  const [showLoader, setShowLoader] = useState(false);

  const location = useLocation();

  const hash = useMemo(
    () =>
      location.hash
        ? location.hash.replace('#', '').replace('-', '_')
        : 'in_progress',
    [location]
  );

  const { candidateNegotiationRooms, isLoading } =
    useCandidateNegotiationRoomsList(tableFilters);

  const { filters } = useCandidateNegotiationRoomFilters();

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <div
      className="relative pt-s-32 overflow-x-auto"
      id="candidate-negotiation-list"
      aria-labelledby={hash && `tab-${hash}`}
      role="tabpanel"
    >
      {showLoader && isLoading && (
        <div className="h-s-384 w-full flex justify-center items-center">
          <LoadingSpinner size="lg" />
        </div>
      )}

      {!isLoading && candidateNegotiationRooms.length > 0 && (
        <ul className="flex flex-col gap-s-16">
          {candidateNegotiationRooms.map((room) => (
            <li key={room.id}>
              {/* TODO: make card clickable when we have the link to the negotiation */}
              <RoomListCard data={room} />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && candidateNegotiationRooms.length === 0 && (
        <NoContent title={t(`${hash}.title`)} />
      )}
    </div>
  );
};

export default CandidateNegotiationRoomsList;
