import { useTranslation } from 'react-i18next';

import MessageDisplay from '@application/components/messageDisplay/MessageDisplay';
import { Table } from '@application/components/table';

import { useOfferCandidatesList } from '../hooks/useOfferCandidatesList';

const OfferCandidatesList = ({
  offerId,
  withResume,
  noCandidatesExplaination,
}: {
  offerId: string;
  withResume?: boolean;
  noCandidatesExplaination?: string;
}) => {
  const { columns, offerCandidates, isLoading } = useOfferCandidatesList({
    offerId,
    withResume,
  });

  const { t } = useTranslation('offers', {
    keyPrefix: 'candidates',
  });

  return offerCandidates?.length > 0 ? (
    <Table data={offerCandidates} columns={columns} isLoading={isLoading} />
  ) : (
    noCandidatesExplaination && (
      <MessageDisplay
        label={t('list.noCandidatesExplaination')}
        text={noCandidatesExplaination}
      />
    )
  );
};

export default OfferCandidatesList;
