import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

import { RequestFormFields } from '../schema';

type ToViewModel = {
  request: {
    data: Pick<Mutation, 'requestUpdate'> | Record<string, never> | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  request: { data = {}, fetching, error },
}: ToViewModel): ViewModel<RequestFormFields> =>
  new ViewModel<RequestFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data as RequestFormFields,
  });
