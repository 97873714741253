import { useMutation } from 'urql';

import { Mutation, MutationUserDeleteAvatarArgs } from '@domain/graphql.types';
import { UserDeleteAvatar } from '@infrastructure/graphql/user';

const useUserDeleteAvatar = () => {
  const [{ data, fetching, error }, deleteUserAvatar] = useMutation<
    Pick<Mutation, 'userDeleteAvatar'>,
    MutationUserDeleteAvatarArgs
  >(UserDeleteAvatar);

  return {
    deleteUserAvatar,
    data,
    fetching,
    error,
  };
};

export default useUserDeleteAvatar;
