import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

interface CountdownProps {
  targetDate: DateTime;
}

const Countdown = ({ targetDate }: CountdownProps) => {
  const [timeRemaining, setTimeRemaining] = useState<string>('');

  const twoDigitMinimumDisplay = (digit: number) =>
    digit.toString().padStart(2, '0');

  useEffect(() => {
    const updateCountdown = () => {
      const now = DateTime.now();
      const target = targetDate;
      const diff = target
        .diff(now, ['days', 'hours', 'minutes', 'seconds'])
        .toObject();

      if (
        diff.days !== undefined &&
        diff.hours !== undefined &&
        diff.minutes !== undefined &&
        diff.seconds !== undefined
      ) {
        const days = twoDigitMinimumDisplay(Math.floor(diff.days));
        const hours = twoDigitMinimumDisplay(Math.floor(diff.hours));
        const minutes = twoDigitMinimumDisplay(Math.floor(diff.minutes));
        const seconds = twoDigitMinimumDisplay(Math.floor(diff.seconds));

        setTimeRemaining(`${days}:${hours}:${minutes}:${seconds}`);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    updateCountdown();

    return () => clearInterval(interval);
  }, [targetDate]);

  return <div className="font-normal">{timeRemaining}</div>;
};

export default Countdown;
