import { useCallback } from 'react';

import { OUTLINE_STYLES } from '@application/components/buttons/constants';
import { cn } from '@utils/lib-utils';

export type OnboardingSelectionItemProps = {
  description: string;
  badgeNumber: number;
  onClick: () => void;
};
const OnboardingSelectionItem = ({
  description,
  badgeNumber,
  onClick,
}: OnboardingSelectionItemProps) => {
  const onCloseKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onCloseKeyDown}
      role="button"
      aria-label="OnboardingSelectionItem"
      className="flex group p-s-16 h-s-80 items-center hover:bg-neutral rounded-md"
    >
      <div
        className={cn(
          'btn h-s-40 w-s-40 p-s-0 min-h-[2rem] min-w-[2rem] lg:min-w-[2rem]',
          OUTLINE_STYLES,
          'group-hover:bg-secondary'
        )}
      >
        <span className="block text-16 font-semibold text-center rounded-sm">
          {badgeNumber}
        </span>
      </div>

      <span className="text-16 font-semibold ml-s-16">{description}</span>

      <i className="ri-arrow-right-s-line text-24 ml-auto" />
    </div>
  );
};
export default OnboardingSelectionItem;
