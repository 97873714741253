import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import { CandidateOfferListFragment } from './fragment';

const CandidateOffers = gql`
  ${CandidateOfferListFragment}

  query CandidateOffers(
    $filterBy: CandidateOffersFilters,
    $sortBy: [CandidateOffersSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    candidateOffers(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['CandidateOfferListFragment'])}
  }
`;

export default CandidateOffers;
