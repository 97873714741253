import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

import { normalizeCreateResponseData } from '../../normalizers';
import { OperationUnitFormFields } from '../../types';

type ToViewModel = {
  operationUnit: {
    data:
      | Pick<Mutation, 'operationUnitCreate'>
      | Record<string, never>
      | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  operationUnit: { data = {}, fetching, error },
}: ToViewModel): ViewModel<OperationUnitFormFields> =>
  new ViewModel<OperationUnitFormFields>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data
      ? normalizeCreateResponseData(data)
      : ({} as OperationUnitFormFields),
  });
