import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow, VisibilitySwitcher } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import { CandidateOpportunity } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type ConditionsSummaryProps = {
  data?: CandidateOpportunity;
};

const ConditionsSummary = ({ data }: ConditionsSummaryProps) => {
  const { t } = useTranslation('candidates');

  const sectionGridRowClassName = 'border-x';
  const lastSectionGridRowClassName = 'border-b rounded-b-md';
  const textClassName = 'text-18 font-semibold';
  const valueTextClassName = cn(textClassName, 'text-primary');

  const conditions = useMemo(
    () => [
      {
        label: 'labels.maximumGuaranteeOffered',
        value: data?.recruitmentConditions?.guaranteedPeriodValue
          ? `${data.recruitmentConditions.guaranteedPeriodValue} ${t(
              'suffix.months'
            )}`
          : '-',
        isVisible: data?.recruitmentConditions?.guaranteedPeriodDisplay,
      },
      {
        label: 'labels.remunerationType',
        value: data?.recruitmentConditions?.remunerationTypeValue
          ? t(
              `enum.conditionRemunerationType.${data.recruitmentConditions.remunerationTypeValue.toLowerCase()}`
            )
          : '-',
        isVisible: data?.recruitmentConditions?.remunerationTypeDisplay,
      },
      {
        label: 'labels.minimumTrialPeriodDuration',
        value: data?.recruitmentConditions?.probationPeriodValue
          ? `${data.recruitmentConditions.probationPeriodValue} ${t(
              'suffix.weeks'
            )}`
          : '-',
        isVisible: data?.recruitmentConditions?.probationPeriodDisplay,
      },
      {
        label: 'labels.preferredPaymentMethod',
        value: data?.recruitmentConditions?.paymentMethodValue
          ? t(
              `enum.offerConditionPaymentMethodCode.${data.recruitmentConditions.paymentMethodValue.toLowerCase()}`
            )
          : '-',
        isVisible: data?.recruitmentConditions?.paymentMethodDisplay,
      },
      {
        label: 'labels.desiredBonus',
        value: data?.recruitmentConditions?.bonusValue
          ? `${data.recruitmentConditions.bonusValue}$`
          : '-',
        isVisible: data?.recruitmentConditions?.bonusDisplay,
      },
    ],
    [data, t]
  );

  return (
    <>
      <SummaryHeader
        className="mt-s-16"
        headerTitle={t('subtitles.conditions')}
      />

      {conditions.map((condition, index) => (
        <SectionGridRow
          key={condition.label}
          className={cn(
            sectionGridRowClassName,
            conditions.length - 1 === index && lastSectionGridRowClassName
          )}
          isTopRow={index === 0}
        >
          {t(condition.label)}
          &nbsp;
          <VisibilitySwitcher
            content={
              <span className={valueTextClassName}>{condition.value}</span>
            }
            visible={!!condition.isVisible}
          />
        </SectionGridRow>
      ))}
    </>
  );
};

export default ConditionsSummary;
