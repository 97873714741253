import { gql } from 'urql';

const RequestInternationalRecruitmentConditionsFragment = gql`
  fragment RequestInternationalRecruitmentConditionsFragment on RequestInternationalRecruitmentConditions {
    mandatoryWorkPermitValue
    mandatoryWorkPermitDisplay
    exclusivityPeriodValue
    exclusivityPeriodDisplay
    maximumReplacementValue
    maximumReplacementDisplay
  }
`;

export default RequestInternationalRecruitmentConditionsFragment;
