import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, IconButton, LanguageButtons } from '@application/components';
import { TermsAndConditionsCheckbox } from '@application/components/terms-and-conditions';
import { AccountContext, UserContext } from '@application/context';
import { OnboardingPage } from '@application/enums/pagesUrl';
import { AccountTypeCode, ServiceType } from '@domain/graphql.types';

import { Container } from './components';
import { TRANSLATION_PREFIX_KEYS } from './constants';
import { useCreateAccount } from './hooks';
import { CREATE_ACCOUNT_SCHEMA, CreateAccountFormFields } from './schema';
import {
  AgencyServices,
  CompanyAddress,
  CompanyInfo,
  IndustryType,
} from './steps';

type CreateAccountPageProps = {
  type: 'ENTERPRISE' | 'AGENCY';
};

/**
 * This component will handle the creation page for a company, with either an agency or enterprise type
 * This component is the same for both page because the content is exactly the same
 * The only difference is a parameter that will be sent to the API, and some translation key
 */
const CreateAccountPage = ({ type }: CreateAccountPageProps) => {
  const navigate = useNavigate();

  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('signup', {
    keyPrefix: TRANSLATION_PREFIX_KEYS[type],
  });

  const isAgency = type === AccountTypeCode.Agency;

  const methods = useForm<CreateAccountFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(CREATE_ACCOUNT_SCHEMA),
    defaultValues: {
      type,
      termsAndConditionsAccepted: false,
    },
  });

  const {
    createAccount,
    viewModel: { isLoading },
  } = useCreateAccount(type);

  const { setHasFinishedOnboarding, refreshUser } = useContext(UserContext);
  const { refreshAccount } = useContext(AccountContext);

  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<CreateAccountFormFields> = useCallback(
    async (data) => {
      const result = await createAccount({
        accountCreateInput: {
          ...data,
          type,
          services: isAgency ? (data.services as ServiceType[]) : undefined,
          termsAndConditionsAccepted: true, // FIXME: Update during KEH-11 development
        },
      });

      if (!result.error) {
        refreshAccount();
        refreshUser();
        setHasFinishedOnboarding(true);
      }
    },
    [
      createAccount,
      isAgency,
      refreshAccount,
      refreshUser,
      setHasFinishedOnboarding,
      type,
    ]
  );

  const termsAndConditionsAccepted = methods.watch(
    'termsAndConditionsAccepted'
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end m-s-32">
        <IconButton
          text={tGlobal('backPreviousPage')}
          onClick={() => navigate(OnboardingPage.ACCOUNT_TYPE)}
          icon={<i className="ri-arrow-left-line" />}
        />
        <LanguageButtons />
      </div>

      <Container>
        <h1 className="mb-s-32 whitespace-pre-line">{t('heading')}</h1>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <CompanyInfo type={type} />
            <CompanyAddress type={type} />
            {!isAgency && <IndustryType type={type} />}

            {isAgency && <AgencyServices />}

            <TermsAndConditionsCheckbox
              className="mt-s-32"
              control={methods.control}
              name="termsAndConditionsAccepted"
            />

            <Button
              primary
              type="submit"
              className="mb-s-32"
              loading={isLoading}
              disabled={!termsAndConditionsAccepted}
            >
              {t('form.button.submit')}
            </Button>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default CreateAccountPage;
