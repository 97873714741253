import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { AccountTypeCode } from '@domain/graphql.types';

type AccountTypeGuardProps = {
  children: React.ReactNode;
  authorizedAccountType: AccountTypeCode;
};

const AccountTypeGuard = ({
  children,
  authorizedAccountType,
}: AccountTypeGuardProps) => {
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (account?.type !== authorizedAccountType) {
      navigate(PrivatePage.DASHBOARD);
    }
  }, [account?.type, authorizedAccountType, navigate]);

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default AccountTypeGuard;
