import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@application/components/buttons';
import { useClickOutside } from '@application/hooks';
import { cn } from '@utils/lib-utils';

import { TableHorizontalScrollDivRefContext } from '../table/Table';

type ActionProps = {
  label: string;
  onClick?: () => void;
  hidden?: boolean;
};

type ContextualMenuProps = {
  actions: ActionProps[];
  buttonClassName?: string;
  menuClassName?: string;
};

const ContextualMenu = ({
  actions,
  buttonClassName: customButtonClassName,
  menuClassName: customMenuClassName,
}: ContextualMenuProps) => {
  const tableHorizontalScrollDivRef = useContext(
    TableHorizontalScrollDivRefContext
  );
  const [open, setOpen] = useState(false);
  const [buttonRightPosition, setButtonRightPosition] = useState(0);
  const { t } = useTranslation();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    refs: [buttonRef, dropdownRef],
    onOutsideClick: () => setOpen(false),
  });

  useEffect(() => {
    const currentTableHorizontalScrollDivRef =
      tableHorizontalScrollDivRef?.current;

    const handleResizeOrScroll = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const offset = window.innerWidth < 1024 ? 16 : 8;
        const right = window.innerWidth - rect.right + offset;

        setOpen(false);
        setButtonRightPosition(right);
      }
    };

    window.addEventListener('resize', handleResizeOrScroll);
    currentTableHorizontalScrollDivRef?.addEventListener(
      'scroll',
      handleResizeOrScroll
    );

    handleResizeOrScroll();

    return () => {
      window.removeEventListener('resize', handleResizeOrScroll);
      currentTableHorizontalScrollDivRef?.removeEventListener(
        'resize',
        handleResizeOrScroll
      );
    };
  }, [tableHorizontalScrollDivRef]);

  return (
    <>
      <IconButton
        ref={buttonRef}
        text={t('more')}
        icon={<i className="ri-more-2-fill text-20" />}
        outline
        size="x-small"
        onClick={() => setOpen(!open)}
        className="animate-none"
      />

      <div
        ref={dropdownRef}
        className={cn(
          'absolute -mt-s-32 bg-base-100 border border-stroke-default rounded shadow-sm w-s-240',
          { block: open, hidden: !open },
          customMenuClassName
        )}
        style={{ right: `${buttonRightPosition}px` }}
      >
        <ol className="menu menu-vertical p-s-4">
          {actions.map((action: ActionProps) => (
            <Button
              className={cn(
                'justify-start px-s-8',
                {
                  // prettier-ignore
                  'hidden': action.hidden,
                },
                customButtonClassName
              )}
              ghost
              key={action.label}
              onClick={action.onClick}
            >
              <span className="text-start font-normal text-neutral-secondary">
                {action.label}
              </span>
            </Button>
          ))}
        </ol>
      </div>
    </>
  );
};

export default ContextualMenu;
