import {
  RecruitmentNegotiationRoom,
  RecruitmentNegotiationRoomsFilters,
} from '@domain/graphql.types';

import useGetRecruitmentNegotiationRooms from './useGetRecruitmentNegotiationRooms';

type UseRecruitmentNegotiationRoomsListProps = {
  count?: number;
  recruitmentNegotiationRooms: RecruitmentNegotiationRoom[];
  isLoading: boolean;
};

const useRecruitmentNegotiationRoomsList = (
  filters?: RecruitmentNegotiationRoomsFilters
): UseRecruitmentNegotiationRoomsListProps => {
  const {
    viewModel: { data, isLoading },
  } = useGetRecruitmentNegotiationRooms(filters);

  return {
    count: data?.recruitmentNegotiationRooms.totalCount,
    recruitmentNegotiationRooms:
      data?.recruitmentNegotiationRooms.results.slice() || [],
    isLoading,
  };
};

export default useRecruitmentNegotiationRoomsList;
