import clsx, { ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

import { FONT_SIZE, SPACING } from '@tailwind/theme';

/**
 *  Re-define Tailwind theme scales values
 */
const theme = {
  spacing: Object.keys(SPACING).map((key) => key),
};

/**
 * Handle conflicts between class groups here
 * @see https://github.com/dcastil/tailwind-merge/blob/v1.12.0/docs/configuration.md#conflicting-class-groups
 */
const conflictingClassGroups = {
  px: ['pr', 'pl'], // px removes a preceding pr or pl, but not the other way around.
  py: ['pt', 'pb'], // py removes a preceding pt or pb, but not the other way around.
  mx: ['mr', 'ml'], // mx removes a preceding mr or ml, but not the other way around.
  my: ['mt', 'mb'], // my removes a preceding mt or mb, but not the other way around.
};

/**
 * Modify CSS properties with provided custom values
 */
const classGroups = {
  'font-size': Object.keys(FONT_SIZE).map((key) => `text-${key}`),
};

const twMerge = extendTailwindMerge({
  theme,
  classGroups,
  conflictingClassGroups,
});

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));
