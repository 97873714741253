import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';

import { useAccountOperationTerritoryDelete } from '../../../../../domain/account';
import { toViewModel } from '../view-models/delete-account-operation-territory.vm';

const useDeleteAccountOperationTerritory = () => {
  const { data, fetching, error, deleteAccountOperationTerritory } =
    useAccountOperationTerritoryDelete();

  const { t } = useTranslation('organization', {
    keyPrefix: 'accountOperationTerritories.list.messages',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    accountOperationTerritoryDelete: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (data?.accountOperationTerritoryDelete.ok) {
      setAlertContent({
        action: t('deleteSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.accountOperationTerritoryDelete.ok, setAlertContent]);

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('deleteError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return {
    viewModel,
    deleteAccountOperationTerritory,
  };
};

export default useDeleteAccountOperationTerritory;
