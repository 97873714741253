import { useMemo } from 'react';

import { useNotFoundOnPredicate, useValidateIdParam } from '@application/hooks';
import { useRecruitmentNegotiation } from '@domain/negotiation';

import { toViewModel } from '../view-models/get-recruitment-negotiation-agreement.vm';

const useGetRecruitmentNegotiationAgreement = (negotiationId: string) => {
  useValidateIdParam(negotiationId);

  const { data, error, fetching } = useRecruitmentNegotiation({
    input: { negotiationId },
  });

  const viewModel = useMemo(
    () => toViewModel({ agreement: { data, error, fetching } }),
    [data, error, fetching]
  );

  useNotFoundOnPredicate({
    predicate: !viewModel?.data && !viewModel?.isLoading,
  });

  return {
    viewModel,
  };
};

export default useGetRecruitmentNegotiationAgreement;
