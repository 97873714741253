import { useOfferCandidate } from '@domain/offer';

import { toViewModel } from '../view-models/get-offer-candidate.vm';

const useGetOfferCandidate = (offerCandidateId: string) => {
  const { data, fetching, error, reexecuteQuery } = useOfferCandidate({
    offerCandidateGetInput: {
      id: offerCandidateId,
    },
  });

  const viewModel = toViewModel({
    offerCandidate: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchOfferCandidate: reexecuteQuery,
  };
};

export default useGetOfferCandidate;
