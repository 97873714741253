import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import { RecruitmentOpportunityListFragment } from './fragment';

const RecruitmentOpportunities = gql`
  ${RecruitmentOpportunityListFragment}

  query RecruitmentOpportunities(
    $filterBy: RecruitmentOpportunitiesFilters,
    $sortBy: [RecruitmentOpportunitiesSortDirective!]
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    recruitmentOpportunities(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['RecruitmentOpportunityListFragment'])}
  }
`;

export default RecruitmentOpportunities;
