import { gql } from 'urql';

const UserDeleteAvatar = gql`
  mutation UserDeleteAvatar($input: UserDeleteAvatarInput!) {
    userDeleteAvatar(input: $input) {
      ok
    }
  }
`;

export default UserDeleteAvatar;
