import { Avatar } from '@application/components/avatar';
import { User } from '@domain/graphql.types';

export type UserCellProps = {
  user?: Pick<User, 'name' | 'email' | 'avatarUrl'> | null;
};

const UserCell = ({ user }: UserCellProps) => (
  <div className="flex">
    <Avatar url={user?.avatarUrl} size="small" className="animate-none" />

    <div className="flex flex-col ml-s-8 justify-between">
      <span className="text-14 font-semibold">{user?.name}</span>
      <span className="text-12">{user?.email}</span>
    </div>
  </div>
);

export default UserCell;
