import { CandidateRequest } from '@domain/graphql.types';

import Expectations from './Expectations';
import GeneralInformation from './GeneralInformation';
import Notes from './Notes';
import SoftSkills from './SoftSkills';

type DescriptionSummaryProps = {
  data?: CandidateRequest;
};

const DescriptionSummary = ({ data }: DescriptionSummaryProps) => (
  <>
    <GeneralInformation data={data} />
    <Expectations data={data} />
    <SoftSkills data={data} />
    <Notes data={data} />
  </>
);

export default DescriptionSummary;
