import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

import { IconButton } from '../buttons';

export type AlertProps = {
  action?: string;
  text?: string;
  modifier?: 'alert-error' | 'alert-warning' | 'alert-info' | 'alert-success';
  onClose?: () => void;
};

/**
 * Note: Instead of using this component directly, use the `AlertContext` already implemented in the application.
 * To set an alert, import the `AlertContext` and use the `setAlertContent` function from the context to update the alert content.
 * Example usage:
 * const { setAlertContent } = useContext(AlertContext);
 * setAlertContent({
 *   text: 'Text',
 *   modifier: 'alert-info',
 * });
 */

const Alert = ({ action, text, modifier, onClose }: AlertProps) => {
  const { t } = useTranslation();

  const params = useMemo(() => {
    switch (modifier) {
      case 'alert-success':
        return {
          classNames: 'border-accent alert-success',
          icon: <i className="ri-checkbox-circle-fill" />,
          iconColor: 'text-accent',
          title: 'success',
        };
      case 'alert-info':
        return {
          classNames: 'border-info-content alert-info',
          icon: <i className="ri-information-fill" />,
          iconColor: 'text-info-content',
          title: 'info',
        };
      case 'alert-warning':
        return {
          classNames: 'border-warning-content alert-warning',
          icon: <i className="ri-error-warning-fill" />,
          iconColor: 'text-warning-content',
          title: 'warning',
        };
      case 'alert-error':
        return {
          classNames: 'border-error-content alert-error',
          icon: <i className="ri-error-warning-fill" />,
          iconColor: 'text-error-content',
          title: 'error',
        };
      default:
        return {
          classNames: 'border-neutral-secondary',
          icon: <i className="ri-information-fill" />,
          iconColor: 'text-neutral-secondary',
          title: 'info',
        };
    }
  }, [modifier]);

  return (
    <div
      className="fixed bottom-s-32 right-s-16 z-alert"
      data-testid={`TEST-${modifier}`}
    >
      <div
        className={cn(
          'alert relative p-s-24 flex flex-col items-start rounded-md border-0 border-l-4 max-w-[22.5rem] text-primary',
          params.classNames
        )}
      >
        <div className="flex items-center mr-s-32">
          <span className={cn('text-24', params.iconColor)}>{params.icon}</span>
          <h2 className="h3 font-semibold ml-s-16">
            {t(`alerts.title.${params.title}`)}
          </h2>
        </div>

        {action && (
          <p className="text-14 text-start">
            {t(
              modifier === 'alert-success'
                ? 'alerts.messages.success'
                : 'alerts.messages.error',
              { action }
            )}
          </p>
        )}

        {text && <p className="text-14">{text}</p>}
      </div>

      <IconButton
        text={t('button.close')}
        className="absolute top-s-8 right-s-8 bg-transparent hover:bg-white focus:bg-white"
        icon={<i className="ri-close-line text-24" />}
        onClick={onClose}
        radius="round"
        size="x-small"
        ghost
      />
    </div>
  );
};

export default Alert;
