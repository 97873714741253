import { gql } from '@urql/core';

const MutationOfferCandidateUpdateFeedback = gql`
  mutation OfferCandidateUpdateFeedback(
    $input: OfferCandidateUpdateFeedbackInput!
  ) {
    offerCandidateUpdateFeedback(input: $input) {
      offerCandidate {
        id
      }
    }
  }
`;

export default MutationOfferCandidateUpdateFeedback;
