import React, { ForwardedRef, ReactNode, useMemo } from 'react';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import HelperText from '../HelperText';
import Label from '../Label';
import { Switch } from './switch';

type SwitchFieldProps = {
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  label: ReactNode;
  /**
   * Specify the position of the label
   */
  labelPosition?: 'left' | 'right';
  /**
   * Provide text that is used alongside the `<input>` for additional help
   * or as a validation message on input error
   */
  helperText?: string;
  /**
   * Specify a custom `id` for the `<input>`
   */
  id?: string;
  /**
   * Specify the name of the `<input>`
   */
  name: string;
  /**
   * Specify the value of the `<input>`
   */
  value?: string | number | undefined;
  /**
   * Specify whether the `<input>` should be checked
   */
  checked?: boolean;
  /**
   * Specify whether the `<input>` should be disabled
   */
  disabled?: boolean;
  /**
   * Specify whether the `<input>` should be visually hidden
   */
  hideLabel?: boolean;
  /**
   * Provide a custom className to be applied to the `<input>`
   */
  className?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optionally provide an `onBlur` handler that is called whenever `<input>` loses focus
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

type Ref = HTMLDivElement;

const SwitchField = React.forwardRef<Ref, SwitchFieldProps>(
  (
    {
      label,
      labelPosition,
      id,
      name,
      value,
      disabled,
      hideLabel,
      helperText,
      className: customClassName,
      checked,
      onChange,
      onBlur,
      ...rest
    }: SwitchFieldProps,
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    const inputId = useMemo(() => id || randomString(), [id]);
    const helperTextId = useMemo(() => randomString(), []);

    const className = cn(
      'flex flex-row items-center',
      labelPosition === 'right' ? 'flex-row-reverse justify-end' : '',
      customClassName
    );

    return (
      <div ref={ref} className={className} {...rest}>
        <Label
          htmlFor={inputId}
          disabled={disabled}
          className={cn('mr-s-8 pb-s-0 text-14', { 'sr-only': hideLabel })}
        >
          {label}
        </Label>

        <Switch
          id={inputId}
          aria-describedby={helperText ? helperTextId : undefined}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />

        {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}
      </div>
    );
  }
);

export default SwitchField;
