import { gql } from '@urql/core';

const MutationRequestUpdate = gql`
  mutation requestUpdate($requestUpdateInput: RequestUpdateInput!) {
    requestUpdate(requestUpdateInput: $requestUpdateInput) {
      request {
        id
      }
    }
  }
`;

export default MutationRequestUpdate;
