import { useMutation } from 'urql';

import {
  Mutation,
  MutationAccountUserUpdateRoleArgs,
} from '@domain/graphql.types';
import { AccountUserUpdateRole } from '@infrastructure/graphql/account-user';

const useAccountUserUpdateRole = () => {
  const [{ data, fetching, error }, updateAccountUserRole] = useMutation<
    Pick<Mutation, 'accountUserUpdateRole'>,
    MutationAccountUserUpdateRoleArgs
  >(AccountUserUpdateRole);

  return {
    updateAccountUserRole,
    data,
    fetching,
    error,
  };
};

export default useAccountUserUpdateRole;
