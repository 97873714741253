import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@application/components';
import { Button } from '@application/components/buttons';
import { Box, Cluster } from '@application/components/container-layouts';
import { LoadingSpinner } from '@application/components/spinner';
import { mapOptions } from '@application/utils';

import { useGetIndustryTypes } from '../../profile/hooks';
import SpecialtiesOnboardingContext from './context/SpecialtiesOnboardingContext';
import { SpecialtiesOnboardingFormFields } from './types';

type OnboardingIndustriesProps = {
  setShowOnboarding: (state: boolean) => void;
  register: UseFormRegister<SpecialtiesOnboardingFormFields>;
  setValue: UseFormSetValue<SpecialtiesOnboardingFormFields>;
  watch: UseFormWatch<SpecialtiesOnboardingFormFields>;
};

const OnboardingIndustries = ({
  setShowOnboarding,
  register,
  setValue,
  watch,
}: OnboardingIndustriesProps) => {
  const { t: tGlobal } = useTranslation();
  const { i18n, t } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities.onboarding',
  });

  const [showLoader, setShowLoader] = useState(false);

  const { nextStep } = useContext(SpecialtiesOnboardingContext);

  const { data, isLoading } = useGetIndustryTypes();

  const industryTypes = useMemo(
    () => mapOptions(data, i18n.language),
    [data, i18n]
  );

  const industries = watch('industries');

  const selectDeselectAll = useCallback(() => {
    let industriesToSelect = [];

    if (industryTypes.length !== industries.length) {
      industriesToSelect = industryTypes.map((i) => i.value);
    }

    setValue('industries', industriesToSelect);
  }, [industryTypes, industries, setValue]);

  const goBack = useCallback(() => {
    setValue('industries', []);
    setShowOnboarding(false);
  }, [setValue, setShowOnboarding]);

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-s-16">
        <h2 className="h3">{t('industries.title')}</h2>
        <span>{t('steps', { current: 1, total: 3 })}</span>
      </div>

      <div className="flex items-center justify-between mb-s-24">
        <span>{t('industries.instructions')}</span>

        <Button
          className="bg-transparent underline"
          ghost
          onClick={selectDeselectAll}
        >
          <span>
            {tGlobal(
              industryTypes.length === industries.length
                ? 'button.deselect'
                : 'button.selectAll'
            )}
          </span>
        </Button>
      </div>

      <Box className="min-h-[24rem] mb-s-24" padding={24}>
        <Cluster space={16}>
          {showLoader && isLoading && (
            <div className="h-s-384 w-full flex justify-center items-center">
              <LoadingSpinner size="lg" />
            </div>
          )}

          {!isLoading &&
            industryTypes.map(({ value, label }) => (
              <Checkbox
                asButton
                key={value}
                value={value}
                label={label}
                {...register('industries')}
              />
            ))}
        </Cluster>
      </Box>
      {!isLoading && (
        <div className="flex justify-end gap-s-16">
          <Button size="large" onClick={goBack}>
            <span>{tGlobal('button.back')}</span>
          </Button>

          <Button
            size="large"
            primary
            disabled={industries.length === 0}
            onClick={nextStep}
          >
            <span>{tGlobal('button.continue')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default OnboardingIndustries;
