import { useQuery } from 'urql';

import {
  Query,
  QueryCandidateNegotiationRoomArgs,
} from '@domain/graphql.types';
import { CandidateNegotiationRoom } from '@infrastructure/graphql/candidate';

const useCandidateNegotiationRoom = (
  input: QueryCandidateNegotiationRoomArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateNegotiationRoom'>,
    QueryCandidateNegotiationRoomArgs
  >({
    query: CandidateNegotiationRoom,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateNegotiationRoom;
