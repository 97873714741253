import { OptionType } from '@application/components/select-field/select';

/**
 * Normalize response from Google Places API
 *
 * @param {readonly google.maps.places.AutocompletePrediction[]} predictions
 * @returns {OptionType[]}
 */
const normalize = (
  predictions: readonly google.maps.places.AutocompletePrediction[]
): OptionType[] =>
  predictions.map((address: google.maps.places.AutocompletePrediction) => ({
    value: address.place_id,
    label: address.description,
  })) || [];

export default normalize;
