import { gql } from '@urql/core';

const MutationRecruitmentNegotiationRoomRejectValues = gql`
  mutation RecruitmentNegotiationRoomRejectValues(
    $input: RecruitmentNegotiationStateUpdateInput!
  ) {
    recruitmentNegotiationRoomRejectValues(input: $input) {
      room {
        id
      }
    }
  }
`;

export default MutationRecruitmentNegotiationRoomRejectValues;
