import React, { ForwardedRef, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

import { LoadingSpinner } from '../spinner';
import {
  DISABLED_STYLES,
  GHOST_STYLES,
  OUTLINE_STYLES,
  PRIMARY_STYLES,
  SECONDARY_STYLES,
} from './constants';

type ButtonType = 'button' | 'submit' | 'reset';
type ButtonSize = 'x-small' | 'small' | 'medium' | 'large';

type ButtonProps = {
  children: ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
  className?: string;
  primary?: boolean;
  outline?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  loading?: boolean;
  onClick?: () => void;
};

type Ref = HTMLButtonElement;

const Button = React.forwardRef<Ref, ButtonProps>(
  (
    {
      type = 'button',
      size = 'medium',
      className: customClassName,
      primary,
      outline,
      ghost,
      disabled,
      onClick,
      children,
      icon,
      loading,
    }: ButtonProps,
    ref: ForwardedRef<Ref>
  ) => {
    const className = cn(
      'btn h-auto text-16 font-semibold normal-case md:max-h-[3rem]',
      primary && PRIMARY_STYLES,
      outline && OUTLINE_STYLES,
      !(primary || outline) && SECONDARY_STYLES,
      ghost && GHOST_STYLES,
      {
        'text-14 px-s-16 py-s-4 min-w-[7.375rem] min-h-[2rem] md:h-s-32':
          size === 'x-small',
        'text-14 px-s-16 py-s-10 min-w-[7.375rem] min-h-[2.5rem] md:h-s-40':
          size === 'small',
        'text-16 px-s-24 py-s-10 min-w-[8.75rem] min-h-[3rem] md:h-s-48 lg:min-w-[9.75rem]':
          size === 'medium',
        'text-16 px-s-24 py-s-16 min-w-[8.75rem] min-h-[3.5rem] md:h-s-56 lg:min-w-[9.75rem]':
          size === 'large',
      },
      DISABLED_STYLES,
      customClassName
    );

    return (
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        className={className}
        disabled={disabled}
      >
        {icon && (
          <span
            className={cn('font-normal [&&]:leading-none', {
              'text-16': size === 'x-small' || size === 'small',
              'text-20': size === 'medium' || size === 'large',
            })}
          >
            {icon}
          </span>
        )}
        {loading ? <LoadingSpinner /> : children}
      </button>
    );
  }
);

export default Button;
