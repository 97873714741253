import { useTranslation } from 'react-i18next';

import { SectionGridRow, VisibilitySwitcher } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import {
  CandidateOffer,
  CandidateOpportunity,
  CandidateRequest,
} from '@domain/graphql.types';

type OutsourcingConditionsProps = {
  offer: CandidateOffer | undefined;
  request: CandidateRequest | CandidateOpportunity | undefined;
};

const OutsourcingConditions = ({
  offer,
  request,
}: OutsourcingConditionsProps) => {
  const { t } = useTranslation('candidates');

  const requestChildrenClassName = 'text-16 font-semibold text-primary';
  const offerWrapperClassName = 'flex flex-wrap gap-x-s-16 items-center';
  const offerLabelClassName = 'text-16 text-primary';
  const offerValueClassName = 'text-18 font-semibold text-primary';

  const conditions = offer?.outsourcingConditions;
  const outsourcingConditions = request?.outsourcingConditions;

  return (
    <>
      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={outsourcingConditions?.billingPeriodValue}
              >
                <span className={requestChildrenClassName}>
                  {t(
                    `enum.candidateConditionBillingPeriod.${outsourcingConditions?.billingPeriodValue?.toLowerCase()}`
                  )}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!outsourcingConditions?.billingPeriodDisplay}
          />
        }
      >
        <span className={offerWrapperClassName}>
          <span className={offerLabelClassName}>
            {t('labels.billingPeriod')}
          </span>

          <PlaceholderSwitcher value={conditions?.billingPeriod}>
            <span className={offerValueClassName}>
              {t(
                `enum.candidateConditionBillingPeriod.${conditions?.billingPeriod?.toLowerCase()}`
              )}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <PlaceholderSwitcher
                value={outsourcingConditions?.minimumNoticeValue}
              >
                <span className={requestChildrenClassName}>
                  {t('suffix.week', {
                    count: Number(outsourcingConditions?.minimumNoticeValue),
                  })}
                </span>
              </PlaceholderSwitcher>
            }
            visible={!!outsourcingConditions?.minimumNoticeDisplay}
          />
        }
      >
        <span className={offerWrapperClassName}>
          <span className={offerLabelClassName}>
            {t('labels.minimumNoticePeriod')}
          </span>

          <PlaceholderSwitcher value={conditions?.minimumNotice}>
            <span className={offerValueClassName}>
              {t('suffix.week', { count: Number(conditions?.minimumNotice) })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>
    </>
  );
};

export default OutsourcingConditions;
