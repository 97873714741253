import { useMutation } from 'urql';

import {
  Mutation,
  MutationOperationUnitCreateArgs,
} from '@domain/graphql.types';
import { OperationUnitCreate } from '@infrastructure/graphql/operation';

const useOperationUnitCreate = () => {
  const [{ data, fetching, error }, createOperationUnit] = useMutation<
    Pick<Mutation, 'operationUnitCreate'>,
    MutationOperationUnitCreateArgs
  >(OperationUnitCreate);

  return {
    createOperationUnit,
    data,
    fetching,
    error,
  };
};

export default useOperationUnitCreate;
