import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

type MatchingScoreProps = {
  value: number;
  className?: string;
};

const MatchingScore = ({
  value,
  className: customClassName,
}: MatchingScoreProps) => {
  const { t } = useTranslation();

  return (
    <svg
      className={cn('mx-auto', customClassName)}
      width="57"
      height="24"
      viewBox="0 0 57 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('matching', { count: value })}</title>
      <rect
        x="0.400391"
        width="6"
        height="24"
        rx="2"
        fill={value >= 14 ? '#FFF0D2' : '#CECBD6'}
      />
      <rect
        x="10.4004"
        width="6"
        height="24"
        rx="2"
        fill={value >= 28 ? '#FFDEA4' : '#CECBD6'}
      />
      <rect
        x="20.4004"
        width="6"
        height="24"
        rx="2"
        fill={value >= 42 ? '#FAC470' : '#CECBD6'}
      />
      <rect
        x="30.4004"
        width="6"
        height="24"
        rx="2"
        fill={value >= 56 ? '#EF7B6C' : '#CECBD6'}
      />
      <rect
        x="40.4004"
        width="6"
        height="24"
        rx="2"
        fill={value >= 70 ? '#E44747' : '#CECBD6'}
      />
      <rect
        x="50.4004"
        width="6"
        height="24"
        rx="2"
        fill={value >= 84 ? '#EF1616' : '#CECBD6'}
      />
    </svg>
  );
};

export default MatchingScore;
