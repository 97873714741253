import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import * as yup from 'yup';

import {
  ConfirmationModal,
  PageLoader,
  Stack,
  Stepper,
} from '@application/components';
import { ModalContext } from '@application/context';
import { useWizard } from '@application/hooks';

import { useGetCandidateOpportunity } from '../opportunity/hooks';
import { CREATE_OFFER_STEPS, DEFAULT_VALUES } from './constants';
import { useCreateCandidateOffer } from './hooks';
import { normalizeCandidateOfferInputData } from './normalizers';
import {
  CANDIDATE_OFFER_PUBLISH_SCHEMA,
  CANDIDATE_OFFER_SCHEMA,
  CandidateOfferFormFields,
} from './schema';
import { AgencyPanel, CandidatePanel } from './steps';

const CreateCandidateOfferPage = () => {
  const [currentSchema, setCurrentSchema] = useState<yup.ObjectSchema<any>>(
    CANDIDATE_OFFER_SCHEMA
  );
  const [saving, setSaving] = useState<boolean>(false);

  const { t } = useTranslation('candidates');

  const { createCandidateOffer } = useCreateCandidateOffer();

  const { setModal } = useContext(ModalContext);

  const { id = '' } = useParams();

  const {
    viewModel: { data: candidateOpportunity, isLoading },
  } = useGetCandidateOpportunity(id);

  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<CandidateOfferFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(currentSchema),
  });

  const { activeStep, completedSteps, setActive, nextStep } = useWizard({
    steps: CREATE_OFFER_STEPS,
  });

  const onSubmit: SubmitHandler<CandidateOfferFormFields> = useCallback(
    async (data) => {
      await createCandidateOffer({
        input: {
          ...(normalizeCandidateOfferInputData(data) as any),
          candidateRequestId: id,
        },
      });
    },
    [createCandidateOffer, id]
  );

  const handleOnSave = () => {
    setValue('publishNow', false);
    setCurrentSchema(CANDIDATE_OFFER_SCHEMA);
    setSaving(true);
  };

  const handleOnPublish = () => {
    setValue('publishNow', true);
    setCurrentSchema(CANDIDATE_OFFER_PUBLISH_SCHEMA);
    setSaving(true);
  };

  useEffect(() => {
    if (!saving || Object.keys(formState.errors).length === 0) {
      return;
    }

    setModal({
      title: t('modal.titles.fillOutEmptyRequiredField', {
        count: Object.keys(formState.errors).length,
      }),
      maxWidth: '2xl',
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p>
                <Trans
                  i18nKey="modal.contents.requiredFieldError"
                  t={t}
                  values={{
                    count: Object.keys(formState.errors).length,
                    step: t(CREATE_OFFER_STEPS[0].title),
                  }}
                />
              </p>
              <p>
                {t('modal.contents.confirmRedirectStepOffer', {
                  count: Object.keys(formState.errors).length,
                })}
              </p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            /**
             * FIXME: This is a only temporary. The redirection is made explicitly
             * to the first step (0) of the form in case of error since all required fields are present in this step.
             * It should be handle dynamically by redirecting to the first step containing errors on required fields.
             */
            setActive(0);
          }}
        />
      ),
    });

    setSaving(false);
    // We want to execute it only on saving AND presence of errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors, saving]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="max-w-[66rem] w-full">
        <Stepper
          steps={CREATE_OFFER_STEPS}
          activeStep={activeStep}
          completedSteps={completedSteps}
          setActive={setActive}
          t={t}
        />

        <form className="md:px-s-48" onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && (
            <CandidatePanel
              candidateOpportunity={candidateOpportunity}
              clearErrors={clearErrors}
              control={control}
              errors={formState.errors}
              nextStep={nextStep}
              register={register}
              setError={setError}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
            />
          )}

          {activeStep === 1 && (
            <AgencyPanel
              candidateOpportunity={candidateOpportunity}
              control={control}
              errors={formState.errors}
              onPublish={handleOnPublish}
              onSave={handleOnSave}
              register={register}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateCandidateOfferPage;
