import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions, PagePanel } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import useIsViewOnlySubscription from '@application/views/billing/useIsViewOnlySubscription';
import { CandidateRequestType } from '@domain/graphql.types';

import { CandidateRequestFormFields } from '../../schema';
import OutsourcingFields from './OutsourcingFields';
import RecruitmentFields from './RecruitmentFields';
import TemporaryPlacementFields from './TemporaryPlacementFields';

type ConditionsPanelProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  onPublish?: () => void;
  onSave?: () => void;
  register: UseFormRegister<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  trigger: UseFormTrigger<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
  saveLoading: boolean;
  publishLoading: boolean;
};

const ConditionsPanel = ({
  control,
  errors,
  onPublish,
  onSave,
  register,
  setValue,
  trigger,
  watch,
  saveLoading,
  publishLoading,
}: ConditionsPanelProps) => {
  const { t } = useTranslation('candidates');
  const { t: TGlobal } = useTranslation();
  const hasViewOnlyRestriction = useIsViewOnlySubscription();
  const candidateRequestType = watch('type');

  return (
    <PagePanel title={t('steps.conditions')}>
      {candidateRequestType === CandidateRequestType.Recruitment && (
        <RecruitmentFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      )}
      {candidateRequestType === CandidateRequestType.TemporaryPlacement && (
        <TemporaryPlacementFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      )}
      {candidateRequestType === CandidateRequestType.Outsourcing && (
        <OutsourcingFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      )}
      <FormActions
        publishDisabled={hasViewOnlyRestriction}
        publishToolTip={
          hasViewOnlyRestriction
            ? TGlobal('membership.permission.upgradePlan')
            : undefined
        }
        cancelPath={RootPrivatePage.CANDIDATE_REQUESTS}
        isLastStep
        onPublish={onPublish}
        onSave={onSave}
        saveLoading={saveLoading}
        publishLoading={publishLoading}
      />
    </PagePanel>
  );
};

export default ConditionsPanel;
