import { useAccountAdvantageTypes } from '@domain/account';

import { toViewModel } from '../view-models/get-perks.vm';

const useGetPerks = () => {
  const { data, fetching, error } = useAccountAdvantageTypes();

  return toViewModel({
    accountAdvantageTypes: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetPerks;
