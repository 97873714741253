import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import CandidateOfferOutsourcingConditionsFragment from './candidate.offer.outsourcing.conditions.fragment';
import CandidateOfferRecruitmentConditionsFragment from './candidate.offer.recruitment.conditions.fragment';
import CandidateOfferTemporaryPlacementConditionsFragment from './candidate.offer.temporary.placement.conditions.fragment';

const CandidateNegotiatingOfferFragment = gql`
  ${DescriptionsFragment}
  ${CandidateOfferRecruitmentConditionsFragment}
  ${CandidateOfferOutsourcingConditionsFragment}
  ${CandidateOfferTemporaryPlacementConditionsFragment}

  fragment CandidateNegotiatingOfferFragment on CandidateOffer {
    id
    accountId
    account {
      name
    }
    userId
    candidateRequestId
    status
    matchingScore
    rank
    potentialJobDuration
    potentialAmount
    negotiationRoomId
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    operationUnitId
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    operationTerritoryCodes
    jobType
    jobDurationInMonths
    hoursPerWeek
    seasons
    fullTimeAvailability
    partTimeAvailability
    schedules
    jobModeCode
    remoteDaysAllowed
    paymentFrequencyCode
    annualSalaryMin
    annualSalaryMax
    hourlyRateMin
    hourlyRateMax
    hourlyBonus
    remunerationAmount
    otherPaymentTypeCode
    otherPaymentAmount
    candidateBonus
    candidateBonusAmount
    perks
    salesPitch
    linkProfile
    recruitmentConditions {
      ...CandidateOfferRecruitmentConditionsFragment
    }
    temporaryPlacementConditions {
      ...CandidateOfferTemporaryPlacementConditionsFragment
    }
    outsourcingConditions {
      ...CandidateOfferOutsourcingConditionsFragment
    }
    createdAt
    updatedAt
    availableTransitions
  }
`;

export default CandidateNegotiatingOfferFragment;
