import { Request } from '@domain/graphql.types';

import GeneralInformation from './GeneralInformation';
import Notes from './Notes';
import Remuneration from './Remuneration';
import Skills from './Skills';

type DescriptionSummaryProps = {
  data: Request | undefined;
};

const DescriptionSummary = ({ data }: DescriptionSummaryProps) => (
  <>
    <GeneralInformation data={data} />
    <Remuneration data={data} />
    <Skills data={data} />
    <Notes data={data} />
  </>
);

export default DescriptionSummary;
