import { ChangeEvent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';
import { SelectField } from '@application/components/select-field';
import { AccountContext } from '@application/context';
import { mapOptions } from '@application/utils';
import {
  AccountPreferredSpecialtiesFilters,
  AccountTypeCode,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { useGetIndustryTypes } from '../../profile/hooks';
import useGetIndustrySectors from '../actions/useGetIndustrySectors';

type PreferredSpecialtiesFiltersProps = {
  updateTextFilters: (event: ChangeEvent<HTMLInputElement>) => void;
  updateSelectFilters: (
    fieldName: keyof AccountPreferredSpecialtiesFilters
  ) => (values: any) => void;
};

const PreferredSpecialtiesFilters = ({
  updateTextFilters,
  updateSelectFilters,
}: PreferredSpecialtiesFiltersProps) => {
  const { account } = useContext(AccountContext);
  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'requestsOffers.jobOpportunities.list.filters',
  });

  const isAgency = account?.type === AccountTypeCode.Agency;

  const { data: INDUSTRY_TYPES = [] } = useGetIndustryTypes();
  const { data: INDUSTRY_SECTORS = [] } = useGetIndustrySectors();

  const INDUSTRY_TYPE_OPTIONS = useMemo(
    () => mapOptions(INDUSTRY_TYPES, i18n.language),
    [INDUSTRY_TYPES, i18n]
  );

  const INDUSTRY_SECTORS_OPTIONS = useMemo(
    () =>
      INDUSTRY_SECTORS.map(({ descriptions, code }) => ({
        value: code,
        label: descriptions[extractLanguage(i18n.language)],
      })),
    [INDUSTRY_SECTORS, i18n]
  );

  return (
    <Stack space={16}>
      <Cluster space={24}>
        <TextInputField
          label={isAgency ? t('searchJob') : t('searchPosition')}
          name="searchPosition"
          placeholder={`${isAgency ? t('searchJob') : t('searchPosition')}...`}
          withIcon="ri-search-2-line text-24"
          hideIconBorder
          className="flex-1"
          onChange={updateTextFilters}
        />

        <SelectField
          label={t('industryCodes')}
          name="industryCodes"
          options={INDUSTRY_TYPE_OPTIONS}
          className="flex-1"
          multiple
          limitTags={0}
          clearable
          onChange={updateSelectFilters('industryCodes')}
        />

        <SelectField
          label={t('sectorCodes')}
          name="sectorCodes"
          options={INDUSTRY_SECTORS_OPTIONS}
          className="flex-1"
          multiple
          limitTags={0}
          clearable
          onChange={updateSelectFilters('sectorCodes')}
        />
      </Cluster>
    </Stack>
  );
};

export default PreferredSpecialtiesFilters;
