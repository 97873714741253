import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useParams } from 'react-router';

import { ButtonLink, PageHeader, PageLoader } from '@application/components';
import { Badge } from '@application/components/badge';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { isDefined } from '@utils/type-utils';

import { mapDescriptions } from '../../../utils';
import { useGetCandidateRequest } from '../request/hooks';
import { ComparisonTable } from './table';

const CandidateOffersComparisonPage = () => {
  const { id = '' } = useParams();
  const { t, i18n } = useTranslation('candidates');
  const location = useLocation();

  const {
    viewModel: { data, isLoading },
  } = useGetCandidateRequest(id);

  const operationTerritoriesLabel = useMemo(() => {
    const operationTerritories = mapDescriptions(
      data?.operationTerritories?.map((territory) => territory.descriptions) ||
        [],
      i18n.language
    );

    if (operationTerritories.length > 1) {
      return `${operationTerritories[0]} +${operationTerritories.length - 1}`;
    }

    return operationTerritories[0] || undefined;
  }, [i18n.language, data?.operationTerritories]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={getLocalizedDescription(
          data?.jobSpecialty?.descriptions,
          i18n.language
        )}
        subtitle={<Badge value={operationTerritoriesLabel} />}
        complement={
          <Badge
            className="px-s-18"
            value={data?.candidateOffersCount.toString()}
            placeholder="0"
          />
        }
        backTo={RootPrivatePage.CANDIDATE_REQUESTS}
      >
        {isDefined(data?.candidateNegotiation) && (
          <ButtonLink
            primary
            icon={<i className="ri-shake-hands-line mr-s-4 font-normal" />}
            to={generatePath(PrivatePage.CANDIDATE_NEGOTIATION_DETAILS, {
              id: data?.candidateNegotiation?.id || null,
            })}
            from={location.pathname}
          >
            {t('button.negotiationsZone')}
          </ButtonLink>
        )}
      </PageHeader>

      {data && <ComparisonTable candidateRequest={data} />}
    </>
  );
};

export default CandidateOffersComparisonPage;
