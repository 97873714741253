import { useMutation } from 'urql';

import {
  Mutation,
  MutationAccountPreferredSpecialtyDeleteArgs,
} from '@domain/graphql.types';
import { AccountPreferredSpecialtyDelete } from '@infrastructure/graphql/account';

const useAccountPreferredSpecialtyDelete = () => {
  const [{ data, fetching, error }, deleteAccountPreferredSpecialty] =
    useMutation<
      Pick<Mutation, 'accountPreferredSpecialtyDelete'>,
      MutationAccountPreferredSpecialtyDeleteArgs
    >(AccountPreferredSpecialtyDelete);

  return {
    deleteAccountPreferredSpecialty,
    data,
    fetching,
    error,
  };
};

export default useAccountPreferredSpecialtyDelete;
