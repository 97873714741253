import {
  CandidateNegotiation,
  CandidateNegotiationRoom,
  CandidateNegotiationRoomStatus,
} from '../../../../domain/graphql.types';
import {
  CandidateNegotiationAgreement,
  CandidateNegotiationRoomAgreement,
} from './types';

export const normalizeCandidateNegotiationAgreement = (
  negotiation: CandidateNegotiation | undefined
): CandidateNegotiationAgreement | undefined => {
  if (!negotiation) {
    return undefined;
  }

  const acceptedRoom = negotiation.rooms.find(
    ({ status }) => status === CandidateNegotiationRoomStatus.Accepted
  );

  return (
    acceptedRoom && {
      candidateOfferAccountName: acceptedRoom.candidateOffer.account.name,
      candidateOfferUserEmail: acceptedRoom.candidateOffer.user.email,
    }
  );
};

export const normalizeCandidateNegotiationRoomAgreement = (
  room: CandidateNegotiationRoom | undefined
): CandidateNegotiationRoomAgreement | undefined => {
  if (!room || room.status !== CandidateNegotiationRoomStatus.Accepted) {
    return undefined;
  }

  return {
    candidateRequestAccountName: room.candidateRequest.account.name,
    candidateRequestUserEmail: room.candidateRequest.user.email,
  };
};
