import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BadgeGroup,
  PlaceholderSwitcher,
  Stack,
  Switcher,
} from '@application/components';
import { SummaryBlock } from '@application/components/summary';
import { Account } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { useGetIndustryTypes, useGetPerks } from '../profile/hooks';
import GeneralInformation from './GeneralInformation';
import SocialLinks from './SocialLinks';

type EnterpriseInformationProps = {
  account?: Account;
};

const EnterpriseInformation = ({ account }: EnterpriseInformationProps) => {
  const { t, i18n } = useTranslation('organization');

  const { data: INDUSTRY_TYPES_LIST = [] } = useGetIndustryTypes();
  const { data: PERKS_LIST = [] } = useGetPerks();

  const socialBenefits = useMemo(
    () =>
      account?.advantageCodes.flatMap((advantageCode) => {
        const benefit = getLocalizedDescription(
          PERKS_LIST.find((perk) => perk.value === advantageCode)?.text,
          i18n.language
        );

        return benefit ? [benefit] : [];
      }),
    [PERKS_LIST, account?.advantageCodes, i18n.language]
  );

  return (
    <>
      <Stack space={24}>
        <h1 className="text-32">{account?.name}</h1>

        <GeneralInformation account={account} />

        <SocialLinks
          linkedinUrl={account?.linkedinUrl ?? undefined}
          facebookUrl={account?.facebookUrl ?? undefined}
          twitterUrl={account?.twitterUrl ?? undefined}
          instagramUrl={account?.instagramUrl ?? undefined}
        />
      </Stack>

      <div>
        <SummaryBlock
          className="mb-s-16"
          verticalSpacing={0}
          headerTitle={t('information.branding')}
        >
          <PlaceholderSwitcher value={account?.employerOfferDescription}>
            <span className="text-14 text-neutral-secondary">
              {account?.employerOfferDescription}
            </span>
          </PlaceholderSwitcher>
        </SummaryBlock>

        <SummaryBlock
          className="mb-s-16"
          verticalSpacing={0}
          headerTitle={t('information.socialBenefits')}
        >
          <BadgeGroup elements={socialBenefits} />
        </SummaryBlock>

        <Switcher>
          <div>
            <SummaryBlock
              className="mb-s-16"
              verticalSpacing={0}
              headerTitle={t('information.industryType')}
            >
              <span className="text-14 text-neutral-secondary">
                {getLocalizedDescription(
                  INDUSTRY_TYPES_LIST.find(
                    (industryType) =>
                      industryType.value === account?.industryTypeCode
                  )?.text,
                  i18n.language
                ) || ''}
              </span>
            </SummaryBlock>
          </div>

          <div>
            <SummaryBlock
              className="mb-s-16"
              verticalSpacing={0}
              headerTitle={t('information.sectors')}
            >
              <PlaceholderSwitcher value={account?.sector}>
                <span className="text-14 text-neutral-secondary">
                  {getLocalizedDescription(
                    account?.sector?.descriptions,
                    i18n.language
                  )}
                </span>
              </PlaceholderSwitcher>
            </SummaryBlock>
          </div>
        </Switcher>

        <SummaryBlock
          className="mb-s-16"
          verticalSpacing={0}
          headerTitle={t('information.locations')}
        >
          <PlaceholderSwitcher value={account?.operationUnits}>
            <BadgeGroup
              elements={account?.operationUnits?.map(({ name }) => name)}
            />
          </PlaceholderSwitcher>
        </SummaryBlock>

        <SummaryBlock
          verticalSpacing={0}
          headerTitle={t('information.opportunities')}
        >
          <span className="text-14 text-neutral-secondary">-</span>
        </SummaryBlock>
      </div>
    </>
  );
};

export default EnterpriseInformation;
