import { useQuery } from 'urql';

import { Query, QueryOperationUnitArgs } from '@domain/graphql.types';
import { OperationUnit } from '@infrastructure/graphql/operation';

const useOperationUnit = (input: QueryOperationUnitArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'operationUnit'>,
    QueryOperationUnitArgs
  >({
    query: OperationUnit,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.operationUnit,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useOperationUnit;
