import React, { ForwardedRef, useMemo } from 'react';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import HelperText from '../HelperText';
import Label from '../Label';
import { FileInput } from './file-input';
import { FileTypes } from './file-input/FileInput';

type FileInputFieldProps = {
  /**
   * Specify the id of the `<FileInput>`
   */
  id?: string;
  /**
   * Specify the name of the `<FileInput>`
   */
  name: string;
  /**
   * Specify the value of the `<FileInput>`
   */
  value?: string;
  /**
   * Specify which types or extensions the `<FileInput>` should accept
   */
  acceptTypes: FileTypes;
  /**
   * Specify the label of the `<FileInput>`
   */
  label: string;
  /**
   * Specify the name of the file
   */
  fileName?: string;
  /**
   * Provide a custom className to be applied to the `<FileInput>`
   */
  className?: string;
  /**
   * Provide a custom className to be applied to the `<input>` label
   */
  labelClassName?: string;
  /**
   * Provide text that is used alongside the `<input>` for additional help
   * or as a validation message on input error
   */
  helperText?: string;
  /**
   * Specify whether the `<FileInput>` is disabled
   */
  disabled?: boolean;
  /**
   * Specify whether the `<FileInput>` is no valid
   */
  invalid?: boolean;
  /**
   * Specify whether the `<FileInput>` label should be hidden
   */
  hideLabel?: boolean;
  /**
   * Optionally provide an `onChange` handler that is called whenever the hidden `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optionally provide an `onRemove` handler that is called whenever
   * the `<FileInput>` remove button has been clicked
   */
  onRemove?: () => void;
};

type Ref = HTMLDivElement;

const FileInputField = React.forwardRef<Ref, FileInputFieldProps>(
  (
    {
      id,
      label,
      name,
      value,
      acceptTypes,
      fileName,
      className: customClassName,
      labelClassName: customLabelClassName,
      helperText,
      disabled,
      invalid,
      hideLabel,
      onChange,
      onRemove,
      ...rest
    }: FileInputFieldProps,
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    const inputId = useMemo(() => id || randomString(), [id]);
    const helperTextId = useMemo(() => randomString(), []);

    const className = cn('', customClassName);
    const labelClassName = cn(
      '',
      { 'sr-only': hideLabel },
      customLabelClassName
    );

    return (
      <div ref={ref} className={className} {...rest}>
        <Label
          htmlFor={inputId}
          disabled={disabled}
          invalid={invalid}
          className={labelClassName}
        >
          {label}
        </Label>

        <FileInput
          id={inputId}
          name={name}
          value={value}
          acceptedTypes={acceptTypes}
          fileName={fileName}
          aria-describedby={helperText ? helperTextId : undefined}
          onChange={onChange}
          onRemove={onRemove}
        />

        {helperText && (
          <HelperText id={helperTextId} invalid={invalid}>
            {helperText}
          </HelperText>
        )}
      </div>
    );
  }
);

export default FileInputField;
