import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountContext, AlertContext } from '@application/context';
import { OperationUnitErrors } from '@domain/graphql.types';
import { useOperationUnitDelete } from '@domain/operation/unit';

import { toViewModel } from '../view-models/delete-operation-unit.vm';

const useDeleteOperationUnit = () => {
  const { data, fetching, error, deleteOperationUnit } =
    useOperationUnitDelete();
  const { refreshAccount } = useContext(AccountContext);

  const { t } = useTranslation('operationUnit', {
    keyPrefix: 'list',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    operationUnitDelete: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (data?.ok) {
      refreshAccount();
      setAlertContent({
        modifier: 'alert-success',
        action: t('messages.success.delete'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ok, setAlertContent]);

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    const associatedRequestError = errorCodes.find(
      (e) => e.message === OperationUnitErrors.AssociatedRequest
    );

    setAlertContent(
      associatedRequestError
        ? {
            modifier: 'alert-error',
            text: t('messages.errors.associatedRequest'),
          }
        : {
            modifier: 'alert-error',
            action: t('messages.errors.delete'),
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return {
    viewModel,
    deleteOperationUnit,
  };
};

export default useDeleteOperationUnit;
