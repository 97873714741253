import { useMutation } from 'urql';

import { Mutation, MutationOfferDeleteArgs } from '@domain/graphql.types';
import { MutationOfferDelete } from '@infrastructure/graphql/offer';

const useOfferDelete = () => {
  const [{ data, fetching, error }, deleteOffer] = useMutation<
    Pick<Mutation, 'offerDelete'>,
    MutationOfferDeleteArgs
  >(MutationOfferDelete);

  return {
    deleteOffer,
    data,
    fetching,
    error,
  };
};

export default useOfferDelete;
