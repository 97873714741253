import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useOffersReject } from '@domain/offer';

import { toViewModel } from '../view-models/reject-offers.vm';

const useRejectOffers = () => {
  const { data, fetching, error, rejectOffers } = useOffersReject();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    offers: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      modifier: 'alert-error',
      action: t('rejectOffersError'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.offersReject.offers) {
      setAlertContent({
        modifier: 'alert-success',
        action: t('rejectOffersSuccess'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    viewModel,
    rejectOffers,
  };
};

export default useRejectOffers;
