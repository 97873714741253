import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryList } from '@application/components/summary';
import { CandidateOpportunity } from '@domain/graphql.types';

type NotesProps = {
  data?: CandidateOpportunity;
};

const Notes = ({ data }: NotesProps) => {
  const { t } = useTranslation('candidates');

  return (
    <SummaryBlock className="mt-s-16">
      <SummaryList
        className="!mt-s-0"
        density="default"
        data={[
          {
            text: t('labels.notes'),
            description: data?.additionalNotes || '-',
          },
        ]}
      />
    </SummaryBlock>
  );
};

export default Notes;
