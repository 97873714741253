import { gql } from 'urql';

const CandidateRequestTemporaryPlacementConditionsFragment = gql`
  fragment CandidateRequestTemporaryPlacementConditionsFragment on CandidateRequestTemporaryPlacementConditions {
    probationPeriodValue
    probationPeriodDisplay
    minimumNoticeValue
    minimumNoticeDisplay
    possibleBuybackValue
    possibleBuybackDisplay
    possibleBuybackBonusValue
    possibleBuybackBonusDisplay
    travelExpensesResponsibility
    trainingExpensesResponsibility
    employeeHelpProgramResponsibility
    cnesstResponsibility
    otherResponsibilities
    responsibilitiesDisplay
    billingPeriodValue
    billingPeriodDisplay
  }
`;

export default CandidateRequestTemporaryPlacementConditionsFragment;
