import { ReactNode } from 'react';

export type AccordionDetailsProps = {
  /**
   * Specify the id of the `<AccordionDetails>` container element
   */
  id: string;
  /**
   * Specify whether the `<AccordionDetails>` container element is expanded
   */
  expanded?: boolean;
  /**
   * Specify content elements of the `<AccordionDetails>`
   */
  children: ReactNode;
};

const AccordionDetails = ({
  id,
  expanded,
  children,
}: AccordionDetailsProps) => (
  <div id={id} aria-expanded={expanded} className="collapse-content px-s-24">
    {children}
  </div>
);

export default AccordionDetails;
