import { ChangeEvent, useContext, useState } from 'react';
import { MultiValue } from 'react-select';

import { OptionType } from '@application/components/select-field/select';
import { AccountContext } from '@application/context';
import { debounce } from '@application/utils';
import {
  OperationTerritoryCode,
  OperationUnitsFilters,
} from '@domain/graphql.types';

const useFilters = () => {
  const { account } = useContext(AccountContext);

  const defaultFilters = {
    accountId: account?.id,
    operationTerritoryCodes: undefined,
    text: undefined,
  };

  const [filters, setFilters] = useState<OperationUnitsFilters>(defaultFilters);

  const updateSelectOperationTerritoryFilters = (
    values: MultiValue<OptionType>
  ) => {
    const filterValues = values.map(
      (v: OptionType) => v.value as OperationTerritoryCode
    );

    setFilters((currentFilters) => ({
      ...currentFilters,
      operationTerritoryCodes: filterValues,
    }));
  };

  const updateTextFilters = debounce((event: ChangeEvent<HTMLInputElement>) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }))
  );

  return {
    filters,
    updateTextFilters,
    updateSelectOperationTerritoryFilters,
  };
};

export default useFilters;
