// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { RankBadge } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { PrivatePage } from '@application/enums/pagesUrl';
import { Offer } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../constants';
import {
  GeneralConditions,
  HeadHunterConditions,
  InternationalRecruitmentConditions,
  OutsourcingConditions,
  TemporaryPlacementConditions,
} from './rows';

type TableBodyProps = {
  offer: Offer;
};

const TableBody = ({ offer }: TableBodyProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  const resumeCount = useMemo(() => offer?.resumeCount || 0, [offer]);

  return (
    <tbody>
      <tr>
        <td className="sr-only">&nbsp;</td>
        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'h-[3.5rem] border-l-0 text-center'
          )}
        >
          {offer.rank && <RankBadge rank={offer.rank} />}
        </td>
      </tr>

      <tr>
        <td className="sr-only">&nbsp;</td>
        <td
          className={cn(
            TD_CLASS_NAME.base,
            TD_CLASS_NAME.fixed,
            'h-[3.5rem] border-l-0 text-center'
          )}
        >
          <PlaceholderSwitcher value={resumeCount || undefined}>
            <Link
              className="underline font-semibold hover:no-underline"
              to={generatePath(PrivatePage.VIEW_OFFER, {
                id: offer.id,
              })}
            >
              {t('tableHeaders.resumeCount', { count: resumeCount })}
            </Link>
          </PlaceholderSwitcher>
        </td>
      </tr>

      <GeneralConditions
        data={offer.generalContractualConditions}
        totalCosts={offer.totalCosts}
      />

      <HeadHunterConditions data={offer.headhunterContractualConditions!} />

      <TemporaryPlacementConditions
        data={offer.temporaryPlacementContractualConditions!}
      />

      <InternationalRecruitmentConditions
        data={offer.internationalRecruitmentContractualConditions!}
      />

      <OutsourcingConditions data={offer.outsourcingContractualConditions!} />
    </tbody>
  );
};

export default TableBody;
