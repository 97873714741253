import { useContext } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Stack,
  Switcher,
} from '@application/components/container-layouts';
import { SelectField } from '@application/components/select-field';
import { AccountContext } from '@application/context';
import { useSpecialties } from '@application/hooks';
import { mapOptions } from '@application/utils';
import { AccountIndustryType, AccountTypeCode } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { useGetBusinessSizes } from '../../hooks';
import { OrganizationProfileFormFields } from '../../types';

type IndustryEmployeesFieldsProps = {
  control: Control<OrganizationProfileFormFields>;
  disabled?: boolean;
  setValue: UseFormSetValue<OrganizationProfileFormFields>;
  watch: UseFormWatch<OrganizationProfileFormFields>;
};

const IndustryEmployeesFields = ({
  control,
  disabled = false,
  setValue,
  watch,
}: IndustryEmployeesFieldsProps) => {
  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'updateProfileForm.generalInformation',
  });

  const { account } = useContext(AccountContext);

  const { data: BUSINESS_SIZES_LIST = [] } = useGetBusinessSizes();

  const BUSINESS_SIZES_OPTIONS = mapOptions(BUSINESS_SIZES_LIST, i18n.language);

  const industryCode = watch('industryTypeCode');
  const sectorCode = watch('sectorCode');

  const {
    INDUSTRY_TYPE_OPTIONS,
    INDUSTRY_SECTORS_OPTIONS,
    onIndustryTypeChange,
    onIndustrySectorChange,
  } = useSpecialties({
    industryType: industryCode ?? undefined,
    industrySector: sectorCode ?? undefined,
    language: extractLanguage(i18n.language),
    setSector: (value: string | undefined) => setValue('sectorCode', value),
    setType: (value: AccountIndustryType | undefined) =>
      setValue('industryTypeCode', value),
    useCodeAsValue: true,
  });

  return (
    <Box>
      <Switcher space={24}>
        {account?.type === AccountTypeCode.Enterprise && (
          <Stack space={24}>
            <Controller
              name="industryTypeCode"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <SelectField
                  disabled={disabled}
                  className="flex-grow"
                  name={name}
                  label={t('labels.industryType')}
                  options={INDUSTRY_TYPE_OPTIONS}
                  value={INDUSTRY_TYPE_OPTIONS.find(
                    (size) => size.value === value
                  )}
                  onChange={onIndustryTypeChange(onChange, value)}
                  searchable
                />
              )}
            />
            <Controller
              name="sectorCode"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <SelectField
                  disabled={disabled}
                  name={name}
                  label={t('labels.activitySector')}
                  options={INDUSTRY_SECTORS_OPTIONS}
                  value={
                    INDUSTRY_SECTORS_OPTIONS.find(
                      (size) => size.value === value
                    ) || null
                  }
                  onChange={onIndustrySectorChange(onChange, value)}
                  searchable
                />
              )}
            />
          </Stack>
        )}

        <Controller
          name="sizeTypeCode"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <SelectField
              disabled={disabled}
              className="flex-grow"
              name={name}
              label={t('labels.totalEmployees')}
              options={BUSINESS_SIZES_OPTIONS}
              value={BUSINESS_SIZES_OPTIONS.find(
                (size) => size.value === value
              )}
              onChange={(option: any) => onChange(option.value)}
              searchable
            />
          )}
        />
      </Switcher>
    </Box>
  );
};

export default IndustryEmployeesFields;
