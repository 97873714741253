import { ElementType, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

import { Space } from './types';

type BorderWidth = 1 | 2 | 3;

type BoxProps = {
  /**
   * Specify content elements of the `<Box>`
   */
  children: ReactNode;
  /**
   * Specify the HTML element applied to the `<Box>` container
   */
  as?: ElementType;
  /**
   * Specify the padding value in px  applied to the `<Box>`
   */
  padding?: Space;
  /**
   * Specify the border-width value in px applied to the `<Box>`
   */
  borderWidth?: BorderWidth;
  /**
   * Specify whether the `<Box>` should have a border
   */
  noBorder?: boolean;
  /**
   * Provide a custom className to be applied to the `<Box>`
   */
  className?: string;
};

/**
 * The **Box** layout creates a box shape :
 * - by providing padding on all sides, or no sides at all
 * - by taking care of properties that can be easily inherited to children elements from their parent ancestors
 *
 * @see [https://every-layout.dev/](https://every-layout.dev/)
 *
 * @example
 * Default use:
 * <Box>
 *   <-- the box's contents -->
 * </Box>
 *
 * With padding and borderWidth values:
 *  <Box padding={16} borderWidth={2}>
 *   <-- the box's contents -->
 * </Box>
 */
const Box = ({
  as: Tag = 'div',
  padding = 16,
  borderWidth = 1,
  noBorder = false,
  children,
  className: customClassName,
}: BoxProps) => {
  const paddingClassName = {
    'p-s-0': padding === 0,
    'p-s-8': padding === 8,
    'p-s-16': padding === 16,
    'p-s-24': padding === 24,
    'p-s-32': padding === 32,
    'p-s-64': padding === 64,
  };

  const borderClassName = {
    border: !noBorder && borderWidth === 1,
    'border-2': !noBorder && borderWidth === 2,
    'border-[3px]': !noBorder && borderWidth === 3,
  };

  const className = cn(
    'text-primary border-3 bg-base-100 rounded-md [&*]:text-[inherit]',
    {
      ...paddingClassName,
      ...borderClassName,
      /* NOTE: Apply a transparent outline in the absence of as border
      to restore the box shape on high contrast colors [♿️ a11y] */
      'border-0 outline outline-2 -outline-offset-2 outline-transparent':
        noBorder,
    },
    customClassName
  );

  return <Tag className={className}>{children}</Tag>;
};

export default Box;
