import { useMutation } from 'urql';

import {
  Mutation,
  MutationRecruitmentNegotiationAddRoomsArgs,
} from '@domain/graphql.types';
import { MutationRecruitmentNegotiationAddRooms } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiationAddRooms = () => {
  const [{ data, fetching, error }, addRoomsToRecruitmentNegotiation] =
    useMutation<
      Pick<Mutation, 'recruitmentNegotiationAddRooms'>,
      MutationRecruitmentNegotiationAddRoomsArgs
    >(MutationRecruitmentNegotiationAddRooms);

  return {
    addRoomsToRecruitmentNegotiation,
    data,
    fetching,
    error,
  };
};

export default useRecruitmentNegotiationAddRooms;
