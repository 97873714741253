import { useTranslation } from 'react-i18next';

import { Box, Link } from '@application/components';
import { BadgeList } from '@application/components/badge';
import { CandidateRequestTypeBadge } from '@application/components/request-type-badges';
import { CandidateNegotiationRoom } from '@domain/graphql.types';

import { useRoomListCard } from '../hooks';
import ListIconButtonLink from './ListIconButtonLink';

type RoomListCardProps = {
  data: CandidateNegotiationRoom;
};

const RoomListCard = ({ data }: RoomListCardProps) => {
  const { t: tGlobal } = useTranslation();

  const { operationTerritories, pathToRoom, specialty, timeSinceLastActivity } =
    useRoomListCard(data);

  return (
    <Box className="min-w-[66rem] w-fit h-s-96 shadow-xs">
      <div className="grid grid-cols-[4fr_1fr_1fr] items-center">
        <div className="flex flex-col gap-s-8">
          <Link
            to={pathToRoom}
            className="no-underline hover:underline focus:underline"
          >
            <span className="text-18 font-semibold">{specialty}</span>
          </Link>

          <div className="flex gap-s-8 items-center">
            <BadgeList values={operationTerritories} />

            <i className="ri-circle-fill text-6" />

            <span className="text-14">
              {tGlobal('date.latestActivity', {
                date: tGlobal(`date.options.${timeSinceLastActivity.unit}`, {
                  count: timeSinceLastActivity.value,
                }),
              })}
            </span>
          </div>
        </div>

        {data?.candidateRequest.type ? (
          <CandidateRequestTypeBadge type={data.candidateRequest.type} />
        ) : (
          <span>-</span>
        )}

        <ListIconButtonLink
          count={data.notificationCount}
          iconName="ri-alarm-warning-line"
          to={pathToRoom}
        />
      </div>
    </Box>
  );
};

export default RoomListCard;
