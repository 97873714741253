import React, { useMemo, useState } from 'react';

import { NavContext } from '@application/context';

type NavProviderProps = {
  children: React.ReactNode;
};

const NavProvider = ({ children }: NavProviderProps) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isBottomMenuOpen, setIsBottomMenuOpen] = useState(false);

  const value = useMemo(
    () => ({
      isNavOpen,
      isBottomMenuOpen,
      setIsNavOpen,
      setIsBottomMenuOpen,
    }),
    [isNavOpen, isBottomMenuOpen, setIsBottomMenuOpen, setIsNavOpen]
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export default NavProvider;
