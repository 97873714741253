import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useInvitationAccept } from '@domain/account-user';

import { toViewModel } from './accept-invitation.vm';

const useAcceptInvitation = () => {
  const { data, fetching, error, acceptInvitation } = useInvitationAccept();
  const { t } = useTranslation('invitation', {
    keyPrefix: 'acceptInvitation',
  });
  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    invitationAccept: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('actions.acceptInvitationError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return {
    viewModel,
    acceptInvitation,
  };
};

export default useAcceptInvitation;
