import { Auth0Provider } from '@auth0/auth0-react';
// Import order of CSS files is important and should
// not be changed unless intentional.
import 'daisyui/dist/full.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'remixicon/fonts/remixicon.css';

import {
  AccountProvider,
  AlertProvider,
  GqlProvider,
  LanguageProvider,
  ModalProvider,
  NavProvider,
  UserProvider,
} from '@application/providers';
import { Router } from '@application/router';

import { initSentry } from '../monitoring/init.sentry';
import './styles/main.css';

if (import.meta.env.DEV && import.meta.env.VITE_MSW_MOCK === 'true') {
  const { worker } = await import('../mocks/browser');
  await worker.start();
}

initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <LanguageProvider>
      <AlertProvider>
        <Auth0Provider
          domain={`${import.meta.env.VITE_REACT_APP_AUTH_DOMAIN}`}
          clientId={`${import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID}`}
          useRefreshTokens
          authorizationParams={{
            audience: `${import.meta.env.VITE_REACT_APP_AUTH_AUDIENCE}`,
            redirect_uri: window.location.origin,
          }}
          cacheLocation="localstorage"
        >
          <GqlProvider>
            <AccountProvider>
              <UserProvider>
                <ModalProvider>
                  <NavProvider>
                    <Router />
                  </NavProvider>
                </ModalProvider>
              </UserProvider>
            </AccountProvider>
          </GqlProvider>
        </Auth0Provider>
      </AlertProvider>
    </LanguageProvider>
  </React.StrictMode>
);
