import { useMutation } from 'urql';

import {
  Mutation,
  MutationUserSendPasswordChangeRequestArgs,
} from '@domain/graphql.types';
import { UserSendPasswordChangeRequest } from '@infrastructure/graphql/user';

const useUserSendPasswordChangeRequest = () => {
  const [{ data, fetching, error }, userSendPasswordChangeRequest] =
    useMutation<
      Pick<Mutation, 'userSendPasswordChangeRequest'>,
      MutationUserSendPasswordChangeRequestArgs
    >(UserSendPasswordChangeRequest);

  return {
    userSendPasswordChangeRequest,
    data: data?.userSendPasswordChangeRequest,
    fetching,
    error,
  };
};

export default useUserSendPasswordChangeRequest;
