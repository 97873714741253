import { gql } from 'urql';

const AccountOperationTerritoryUpdate = gql`
  mutation AccountOperationTerritoryUpdate(
    $input: AccountOperationTerritoryUpdateInput!
  ) {
    accountOperationTerritoryUpdate(input: $input) {
      accountOperationTerritory {
        id
      }
    }
  }
`;

export default AccountOperationTerritoryUpdate;
