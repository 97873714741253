import { useQuery } from 'urql';

import { Query } from '@domain/graphql.types';
import { AccountSizes } from '@infrastructure/graphql/account';

const useAccountSizes = () => {
  const [result] = useQuery<Pick<Query, 'accountSizes'>, void>({
    query: AccountSizes,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
  };
};

export default useAccountSizes;
