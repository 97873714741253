import { useTranslation } from 'react-i18next';

import { JobModeCode } from '@domain/graphql.types';

const useJobModeOptions = () => {
  const { t } = useTranslation();

  return Object.values(JobModeCode).map((code) => ({
    value: code,
    label: t(`enum.jobModeCode.${code.toLowerCase()}`),
  }));
};

export default useJobModeOptions;
