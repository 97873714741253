import { Link } from 'react-router-dom';

type ChoiceLinkProps = {
  text: string;
  url: string;
};
const ChoiceLink = ({ text, url }: ChoiceLinkProps) => (
  <Link
    to={url}
    className="btn w-full h-s-96 lg:h-s-144 my-s-6 px-s-20 bg-base-100 normal-case text-16 lg:text-20 hover:bg-secondary focus:bg-secondary active:bg-secondary"
  >
    <span
      className={
        url === '/create-agency'
          ? 'max-w-[10rem] lg:max-w-[12rem]'
          : `max-w-[20rem]`
      }
    >
      {text}
    </span>
  </Link>
);

export default ChoiceLink;
