type PublicLayoutProps = {
  children: React.ReactNode;
};

const PublicLayout = ({ children }: PublicLayoutProps) => (
  <div className="flex flex-row min-h-[100vh] h-full w-full scroll-smooth overflow-y-auto bg-neutral">
    {children}
  </div>
);

export default PublicLayout;
