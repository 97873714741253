import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { ModalContext } from '@application/context';

import {
  OperationUnitsListContext,
  withOperationUnitsListProvider,
} from '../context';
import CreateOperationUnitModal from '../create-operation-unit/CreateOperationUnitModal';
import FiltersFields from './components/FiltersFields';
import OperationUnitList from './components/OperationUnitList';
import useFilters from './hooks/useFilters';

const OperationUnitListPage = () => {
  const { t } = useTranslation('operationUnit', {
    keyPrefix: 'list',
  });
  const { setModal } = useContext(ModalContext);
  const { setShouldRefreshList } = useContext(OperationUnitsListContext);

  const afterSubmit = useCallback(() => {
    setShouldRefreshList(true);
    setModal(null);
  }, [setModal, setShouldRefreshList]);

  const openCreateOperationUnitModal = useCallback(() => {
    setShouldRefreshList(false);
    setModal({
      title: t('createNewOperationUnit'),
      maxWidth: '2xl',
      content: (
        <CreateOperationUnitModal
          afterSubmit={afterSubmit}
          onClose={() => setModal(null)}
        />
      ),
    });
  }, [afterSubmit, setModal, setShouldRefreshList, t]);

  const { filters, updateTextFilters } = useFilters();

  return (
    <>
      <div className="flex w-full justify-between mb-s-8">
        <h1 className="h3">{t('manageCompanyLocations')}</h1>
        <Button
          onClick={openCreateOperationUnitModal}
          icon={<i className="ri-map-pin-add-line" />}
          primary
        >
          {t('addNewOperationUNit')}
        </Button>
      </div>

      <div className="grow lg:w-[40%] mb-s-32">
        <FiltersFields updateTextFilters={updateTextFilters} />
      </div>

      <OperationUnitList filters={filters} />
    </>
  );
};

export const WrapperOperationUnitListPage = withOperationUnitsListProvider(
  OperationUnitListPage
);
