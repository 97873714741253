import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';
import { useTermsAndConditionsModal } from '@application/components/modal/useTermsAndConditionsModal';

const TermsAndConditions = () => {
  const { t: tGlobal } = useTranslation();

  const openModal = useTermsAndConditionsModal();

  return (
    <section className="flex justify-between">
      <h2 className="self-center h4">{tGlobal('termsAndConditions.title')}</h2>

      <Button primary onClick={openModal}>
        {tGlobal('button.consult')}
      </Button>
    </section>
  );
};

export default TermsAndConditions;
