import { RecruitmentNegotiationRoomsFilters } from '@domain/graphql.types';
import { useRecruitmentNegotiationRooms } from '@domain/negotiation';

import { toViewModel } from '../view-models/get-recruitment-negotiation-rooms.vm';

const useGetRecruitmentNegotiationRooms = (
  filterBy?: RecruitmentNegotiationRoomsFilters
) => {
  const { data, fetching, error, reexecuteQuery } =
    useRecruitmentNegotiationRooms({
      filterBy,
    });

  const viewModel = toViewModel({
    recruitmentNegotiationRooms: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRecruitmentNegotiationRooms: reexecuteQuery,
  };
};

export default useGetRecruitmentNegotiationRooms;
