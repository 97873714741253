import {
  AccountAdvantageTypeCode,
  CandidateOffer,
  JobScheduleTypeCode,
  OperationTerritoryCode,
  Season,
} from '@domain/graphql.types';
import { parseNumber } from '@utils/data-utils';

import { CandidateOfferFormFields } from './schema';

export const normalizeCandidateOfferInputData = (
  data: CandidateOfferFormFields
) => ({
  ...data,
  hourlyRateMin: data.hourlyRateMin?.toFixed(2) || null,
  hourlyRateMax: data.hourlyRateMax?.toFixed(2) || null,
  hourlyBonus: data.hourlyBonus?.toFixed(2) || null,
  remunerationAmount: data.remunerationAmount?.toFixed(2) || null,
  otherPaymentAmount: data.otherPaymentAmount?.toFixed(2) || null,
  candidateBonusAmount: data.candidateBonusAmount?.toFixed(2) || null,

  /* RECRUITMENT CONDITIONS */
  recruitmentConditionBudget:
    data.recruitmentConditionBudget?.toFixed(2) || null,
  recruitmentConditionDeposit:
    data.recruitmentConditionDeposit?.toFixed(2) || null,
  recruitmentConditionBonus: data.recruitmentConditionBonus?.toFixed(2) || null,

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuybackBonus:
    data.temporaryPlacementConditionPossibleBuybackBonus?.toFixed(2) || null,
});

export const normalizeCandidateOfferEditData = (
  data: CandidateOffer
): CandidateOfferFormFields => ({
  candidateRequestId: data.candidateRequestId,
  operationUnitId: data.operationUnitId,
  operationTerritoryCodes:
    data.operationTerritoryCodes as OperationTerritoryCode[],
  jobType: data.jobType,
  jobDurationInMonths: data.jobDurationInMonths,
  hoursPerWeek: (data.hoursPerWeek as string[]) || [],
  seasons: data.seasons as Season[],
  fullTimeAvailability: data.fullTimeAvailability,
  partTimeAvailability: data.partTimeAvailability,
  schedules: data.schedules as JobScheduleTypeCode[],
  jobModeCode: data.jobModeCode,
  remoteDaysAllowed: data.remoteDaysAllowed,
  paymentFrequencyCode: data.paymentFrequencyCode,
  annualSalaryMin: data.annualSalaryMin,
  annualSalaryMax: data.annualSalaryMax,
  hourlyRateMin: parseNumber(data.hourlyRateMin),
  hourlyRateMax: parseNumber(data.hourlyRateMax),
  hourlyBonus: parseNumber(data.hourlyBonus),
  remunerationAmount: parseNumber(data.remunerationAmount),
  otherPaymentTypeCode: data.otherPaymentTypeCode,
  otherPaymentAmount: parseNumber(data.otherPaymentAmount),
  perks: (data.perks as AccountAdvantageTypeCode[]) || [],
  candidateBonus: data.candidateBonus,
  candidateBonusAmount: parseNumber(data.candidateBonusAmount),
  salesPitch: data.salesPitch,
  linkProfile: data.linkProfile,

  /* RECRUITMENT CONDITIONS */
  recruitmentConditionBudget: parseNumber(data.recruitmentConditions?.budget),
  recruitmentConditionPaymentMethod: data.recruitmentConditions?.paymentMethod,
  recruitmentConditionStartOfAgreementPaymentMethod:
    data.recruitmentConditions?.startOfAgreementPaymentMethod,
  recruitmentConditionPaymentAmount: data.recruitmentConditions?.paymentAmount,
  recruitmentConditionDeposit: parseNumber(data.recruitmentConditions?.deposit),
  recruitmentConditionPaymentFrequency:
    data.recruitmentConditions?.paymentFrequency,
  recruitmentConditionGuaranteedPeriod:
    data.recruitmentConditions?.guaranteedPeriod,
  recruitmentConditionProbationPeriod:
    data.recruitmentConditions?.probationPeriod,
  recruitmentConditionBonus: parseNumber(data.recruitmentConditions?.bonus),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionProbationPeriod:
    data.temporaryPlacementConditions?.probationPeriod,
  temporaryPlacementConditionMinimumNotice:
    data.temporaryPlacementConditions?.minimumNotice,
  temporaryPlacementConditionPossibleBuyback:
    data.temporaryPlacementConditions?.possibleBuyback,
  temporaryPlacementConditionPossibleBuybackBonus: parseNumber(
    data.temporaryPlacementConditions?.possibleBuybackBonus
  ),
  temporaryPlacementConditionTravelExpensesResponsibility:
    data.temporaryPlacementConditions?.travelExpensesResponsibility,
  temporaryPlacementConditionTrainingExpensesResponsibility:
    data.temporaryPlacementConditions?.trainingExpensesResponsibility,
  temporaryPlacementConditionEmployeeHelpProgramResponsibility:
    data.temporaryPlacementConditions?.employeeHelpProgramResponsibility,
  temporaryPlacementConditionCnesstResponsibility:
    data.temporaryPlacementConditions?.cnesstResponsibility,
  temporaryPlacementConditionOtherResponsibilities:
    data.temporaryPlacementConditions?.otherResponsibilities,
  temporaryPlacementConditionBillingPeriod:
    data.temporaryPlacementConditions?.billingPeriod,

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMinimumNotice: data.outsourcingConditions?.minimumNotice,
  outsourcingConditionBillingPeriod: data.outsourcingConditions?.billingPeriod,
});
