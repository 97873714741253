import { getLocalizedDescription } from '../../utils/i18n-utils';

const joinDescriptions = (
  descriptions: { en: string; fr: string }[],
  language: string
): string =>
  descriptions
    .map((description) => getLocalizedDescription(description, language))
    .join(', ');

export default joinDescriptions;
