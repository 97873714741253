import { RecruitmentNegotiationsFilters } from '@domain/graphql.types';
import { useRecruitmentNegotiations } from '@domain/negotiation';

import { toViewModel } from '../view-models/get-recruitment-negotiations.vm';

const useGetRecruitmentNegotiations = (
  filterBy?: RecruitmentNegotiationsFilters
) => {
  const { data, fetching, error, reexecuteQuery } = useRecruitmentNegotiations({
    filterBy,
  });

  const viewModel = toViewModel({
    recruitmentNegotiations: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRecruitmentNegotiations: reexecuteQuery,
  };
};

export default useGetRecruitmentNegotiations;
