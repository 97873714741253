import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  SectionGrid,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { CandidateOpportunity } from '@domain/graphql.types';

import { CandidateOfferFormFields } from '../../schema';
import PaymentMethodsFields from './PaymentMethodFields';

type RecruitmentFieldsProps = {
  candidateOpportunity?: CandidateOpportunity;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  register: UseFormRegister<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  trigger: UseFormTrigger<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const RecruitmentFields = ({
  candidateOpportunity,
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: RecruitmentFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  return (
    <SectionGrid title={t('subtitles.offerAgency')} isOffer>
      <SectionGridRow
        isTopRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.recruitmentConditions
                ?.guaranteedPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.month', {
                    count:
                      candidateOpportunity.recruitmentConditions
                        .guaranteedPeriodValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.recruitmentConditions
                ?.guaranteedPeriodDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.maximumGuaranteeOffered')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.months')}
          mediumSuffix
          invalid={!!errors.recruitmentConditionGuaranteedPeriod}
          helperText={
            errors.recruitmentConditionGuaranteedPeriod?.message &&
            tGlobal(errors.recruitmentConditionGuaranteedPeriod?.message, {
              min: '1',
            })
          }
          {...register('recruitmentConditionGuaranteedPeriod')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={<VisibilitySwitcher content={<span>-</span>} visible />}
      >
        <FormInputWithSuffix
          type="number"
          step={0.01}
          minValue={1}
          maxValue={99999999.99}
          label={t('labels.recruitmentConditionBudget')}
          suffix="$"
          noMargin
          mediumSuffix
          invalid={!!errors.recruitmentConditionBudget}
          helperText={
            errors.recruitmentConditionBudget?.message &&
            tGlobal(errors.recruitmentConditionBudget?.message, {
              max: '99,999,999.99',
              min: '1',
            })
          }
          {...register('recruitmentConditionBudget')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.recruitmentConditions
                ?.probationPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.week', {
                    count:
                      candidateOpportunity.recruitmentConditions
                        .probationPeriodValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.recruitmentConditions
                ?.probationPeriodDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          type="number"
          minValue={1}
          label={t('labels.minimumTrialPeriod')}
          suffix={t('suffix.weeks')}
          noMargin
          mediumSuffix
          invalid={!!errors.recruitmentConditionProbationPeriod}
          helperText={
            errors.recruitmentConditionProbationPeriod?.message &&
            tGlobal(errors.recruitmentConditionProbationPeriod?.message, {
              min: '1',
            })
          }
          {...register('recruitmentConditionProbationPeriod')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="text-16 text-primary font-semibold text-center py-s-16 px-s-16">
                {candidateOpportunity?.recruitmentConditions
                  ?.paymentMethodValue ? (
                  <p className="mb-s-10">
                    {t(
                      `enum.offerConditionPaymentMethodCode.${candidateOpportunity.recruitmentConditions.paymentMethodValue.toLowerCase()}`
                    )}
                  </p>
                ) : (
                  <span className="font-normal">-</span>
                )}

                {candidateOpportunity?.recruitmentConditions
                  ?.startOfAgreementPaymentMethodValue && (
                  <p className="mb-s-10">
                    {t(
                      `enum.offerConditionStartOfAgreementPaymentMethodCode.${candidateOpportunity.recruitmentConditions.startOfAgreementPaymentMethodValue.toLowerCase()}`
                    )}
                  </p>
                )}

                {candidateOpportunity?.recruitmentConditions
                  ?.paymentAmountValue && (
                  <p>
                    {
                      candidateOpportunity.recruitmentConditions
                        .paymentAmountValue
                    }
                    $
                  </p>
                )}

                {candidateOpportunity?.recruitmentConditions?.depositValue && (
                  <p>
                    {candidateOpportunity.recruitmentConditions.depositValue}$
                  </p>
                )}

                {candidateOpportunity?.recruitmentConditions
                  ?.paymentFrequencyValue && (
                  <p>
                    {t(
                      `enum.offerConditionPaymentFrequencyCode.${candidateOpportunity.recruitmentConditions.paymentFrequencyValue.toLowerCase()}`
                    )}
                  </p>
                )}
              </div>
            }
            visible={
              !!candidateOpportunity?.recruitmentConditions
                ?.paymentMethodDisplay
            }
          />
        }
      >
        <PaymentMethodsFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <VisibilitySwitcher
            content={
              candidateOpportunity?.recruitmentConditions?.bonusValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.currency', {
                    value:
                      candidateOpportunity.recruitmentConditions.bonusValue,
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={
              !!candidateOpportunity?.recruitmentConditions?.bonusDisplay
            }
          />
        }
      >
        <FormInputWithSuffix
          type="number"
          step={0.01}
          minValue={1}
          maxValue={99999999.99}
          label={t('labels.recruitmentConditionBonus')}
          suffix="$"
          noMargin
          mediumSuffix
          invalid={!!errors.recruitmentConditionBonus}
          helperText={
            errors.recruitmentConditionBonus?.message &&
            tGlobal(errors.recruitmentConditionBonus?.message, {
              max: '99,999,999.99',
              min: '1',
            })
          }
          {...register('recruitmentConditionBonus')}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default RecruitmentFields;
