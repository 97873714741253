import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const JobModes = gql`
  ${DescriptionsFragment}

  query JobModes {
    jobModes {
      values {
        descriptions {
          ...DescriptionsFragment
        }
        code
      }
    }
  }
`;

export default JobModes;
