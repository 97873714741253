import { useTranslation } from 'react-i18next';

import { ContextualMenu } from '@application/components/contextual-menu';

type ActionMenuProps = {
  headOffice?: boolean | null;
  id: string;
  onDelete: (id: string) => () => void;
  onUpdate: (id: string) => () => void;
};

const ActionMenu = ({
  headOffice,
  id,
  onDelete,
  onUpdate,
}: ActionMenuProps) => {
  const { t } = useTranslation('operationUnit', { keyPrefix: 'list.actions' });

  const actions = [
    {
      label: t('update'),
      onClick: onUpdate(id),
    },
    {
      label: t('delete'),
      onClick: onDelete(id),
      hidden: headOffice ?? undefined,
    },
  ];

  return (
    <ContextualMenu
      actions={actions}
      buttonClassName="!min-w-fit"
      menuClassName="w-fit"
    />
  );
};

export default ActionMenu;
