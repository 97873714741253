export const SECONDARY_STYLES =
  'bg-base-100 border-stroke-default hover:bg-secondary focus:outline-primary-focus';

export const PRIMARY_STYLES = 'btn-accent';

export const OUTLINE_STYLES =
  'btn-outline border-stroke-default focus:bg-secondary focus:text-secondary-content hover:border-stroke-default hover:bg-secondary hover:text-secondary-content outline-secondary-content';

export const GHOST_STYLES = 'btn-ghost border-transparent';

export const DISABLED_STYLES =
  'disabled:bg-disabled-default [&&:disabled]:text-disabled-content disabled:cursor-not-allowed disabled:pointer-events-auto disabled:hover:bg-disabled-default disabled:hover:text-disabled-content disabled:opacity-80';
