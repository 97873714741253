import { PaginationState, Updater } from '@tanstack/react-table';
import { useCallback, useState } from 'react';

import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from '@application/components/table/constants';
import { Pagination } from '@domain/pagination/pagination';

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [cursors, setCursors] = useState<{
    startCursor?: string;
    endCursor?: string;
  }>();

  const [pagination, setPagination] = useState<Pagination>({
    first: DEFAULT_PAGE_SIZE,
  });

  const resetPagination = useCallback(() => {
    setCurrentPage({
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    setPagination({ first: DEFAULT_PAGE_SIZE });
  }, [setCurrentPage, setPagination]);

  const handlePaginationChange = useCallback(
    (updater: Updater<PaginationState>): void => {
      const nextPage =
        updater instanceof Function ? updater(currentPage) : updater;

      const nextPagination: Pagination = {};

      if (nextPage.pageIndex > currentPage.pageIndex) {
        nextPagination.first = nextPage.pageSize;
        nextPagination.after = cursors?.endCursor;
      } else if (nextPage.pageIndex < currentPage.pageIndex) {
        nextPagination.last = nextPage.pageSize;
        nextPagination.before = cursors?.startCursor;
      }

      setPagination(nextPagination);
      setCurrentPage(nextPage);
    },
    [currentPage, cursors]
  );

  return {
    currentPage,
    handlePaginationChange,
    pagination,
    resetPagination,
    setCurrentPage,
    setCursors,
  };
};

export default usePagination;
