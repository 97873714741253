import { gql } from '@urql/core';

import AccountUserFragment from '../account.user.fragment';

const InvitationAccept = gql`
  ${AccountUserFragment}

  mutation InvitationAccept($input: AcceptInvitationInput!) {
    invitationAccept(input: $input) {
      accountUser {
        ...AccountUserFragment
      }
    }
  }
`;

export default InvitationAccept;
