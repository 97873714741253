import { useAuth0 } from '@auth0/auth0-react';
import React, { useMemo } from 'react';
import { Provider } from 'urql';

import { client } from '@infrastructure/graphql';

type GqlProviderProps = {
  children: React.ReactNode;
};

const GqlProvider = ({ children }: GqlProviderProps) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const value = useMemo(
    () => ({
      getAccessTokenSilently,
      isAuthenticated,
    }),
    [getAccessTokenSilently, isAuthenticated]
  );

  return <Provider value={client(value)}>{children}</Provider>;
};

export default GqlProvider;
