import { gql } from '@urql/core';

const MutationCandidateOfferAccept = gql`
  mutation CandidateOfferAccept($input: CandidateOfferInput!) {
    candidateOfferAccept(input: $input) {
      candidateOffer {
        id
      }
    }
  }
`;

export default MutationCandidateOfferAccept;
