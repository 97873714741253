import { useMutation } from 'urql';

import { Mutation, MutationOfferUpdateArgs } from '@domain/graphql.types';
import { MutationOfferUpdate } from '@infrastructure/graphql/offer';

const useOfferUpdate = () => {
  const [{ data, fetching, error }, updateOffer] = useMutation<
    Pick<Mutation, 'offerUpdate'>,
    MutationOfferUpdateArgs
  >(MutationOfferUpdate);

  return {
    updateOffer,
    data,
    fetching,
    error,
  };
};

export default useOfferUpdate;
