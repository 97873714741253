import { gql } from 'urql';

import AccountOperationTerritoryFragment from './operation-territories/account.operation.territory.fragment';

const AccountUpdate = gql`
  ${AccountOperationTerritoryFragment}

  mutation AccountUpdate($accountUpdateInput: AccountUpdateInput!) {
    accountUpdate(accountUpdateInput: $accountUpdateInput) {
      account {
        id
        name
        type
        ownerId
        website
        address
        phoneNumber
        phoneDisplay
        advantageCodes
        industryTypeCode
        services
        sizeTypeCode
        employerOfferDescription
        facebookUrl
        instagramUrl
        linkedinUrl
        twitterUrl
        operationTerritories {
          ...AccountOperationTerritoryFragment
        }
      }
    }
  }
`;

export default AccountUpdate;
