import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  DisplayButton,
  Fieldset,
  FormInputWithSuffix,
  Radio,
  SectionGrid,
  SectionGridRow,
} from '@application/components';
import { CandidateConditionBillingPeriod } from '@domain/graphql.types';

import { CandidateRequestFormFields } from '../../schema';

type OutsourcingFieldsProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
  register: UseFormRegister<CandidateRequestFormFields>;
  setValue: UseFormSetValue<CandidateRequestFormFields>;
  watch: UseFormWatch<CandidateRequestFormFields>;
};

const OutsourcingFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
}: OutsourcingFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const billingPeriod = watch('outsourcingConditionBillingPeriodValue');

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof CandidateRequestFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  return (
    <SectionGrid title={t('subtitles.outsourcing')}>
      <SectionGridRow
        isTopRow
        rightChildren={
          <DisplayButton
            name="outsourcingConditionMinimumNoticeDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.minimumNoticePeriod')}
          type="number"
          minValue={1}
          noMargin
          suffix={t('suffix.weeks')}
          mediumSuffix
          invalid={!!errors.outsourcingConditionMinimumNoticeValue}
          helperText={
            errors.outsourcingConditionMinimumNoticeValue?.message &&
            tGlobal(errors.outsourcingConditionMinimumNoticeValue?.message, {
              min: '1',
            })
          }
          {...register('outsourcingConditionMinimumNoticeValue')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="outsourcingConditionBillingPeriodDisplay"
            control={control}
            watch={watch}
            setValue={setValue}
          />
        }
      >
        <Controller
          name="outsourcingConditionBillingPeriodValue"
          control={control}
          render={({ field: { name } }) => (
            <Fieldset legend={t('labels.billingPeriod')} legendSize="medium">
              <Cluster space={16} as="ul">
                {Object.values(CandidateConditionBillingPeriod).map(
                  (period) => (
                    <li key={period}>
                      <Radio
                        name={name}
                        value={period}
                        label={t(
                          `enum.candidateConditionBillingPeriod.${period.toLowerCase()}`
                        )}
                        onChange={(e) =>
                          handleOnRadioChange(
                            e as ChangeEvent<HTMLInputElement>,
                            name
                          )
                        }
                        checked={billingPeriod === period}
                        asButton
                      />
                    </li>
                  )
                )}
              </Cluster>
            </Fieldset>
          )}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default OutsourcingFields;
