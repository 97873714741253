import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { LoadingSpinner } from '@application/components/spinner';
import { NoContent } from '@application/components/table';
import { RecruitmentNegotiationsFilters } from '@domain/graphql.types';

import { ListCard } from '../components';
import {
  useRecruitmentNegotiationFilters,
  useRecruitmentNegotiationsList,
} from '../hooks';

const RecruitmentNegotiationsList = () => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.list',
  });

  const [tableFilters, setTableFilters] =
    useState<RecruitmentNegotiationsFilters>();

  const [showLoader, setShowLoader] = useState(false);

  const location = useLocation();

  const hash = useMemo(
    () =>
      location.hash
        ? location.hash.replace('#', '').replace('-', '_')
        : 'in_progress',
    [location]
  );

  const { recruitmentNegotiations, isLoading } =
    useRecruitmentNegotiationsList(tableFilters);

  const { filters } = useRecruitmentNegotiationFilters();

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <div
      className="relative pt-s-32 overflow-x-auto"
      id="recruitment-negotiation-list"
      aria-labelledby={hash && `tab-${hash}`}
      role="tabpanel"
    >
      {showLoader && isLoading && (
        <div className="h-s-384 w-full flex justify-center items-center">
          <LoadingSpinner size="lg" />
        </div>
      )}

      {!isLoading && recruitmentNegotiations.length > 0 && (
        <ul className="flex flex-col gap-s-16">
          {recruitmentNegotiations.map((negotiation) => (
            <li key={negotiation.id}>
              <ListCard data={negotiation} />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && recruitmentNegotiations.length === 0 && (
        <NoContent title={t(`noContent.${hash}.title`)} />
      )}
    </div>
  );
};

export default RecruitmentNegotiationsList;
