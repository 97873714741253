import { Button } from '@application/components/buttons';

type ToggleButtonsProps = {
  leftLabel: string;
  rightLabel: string;
  activeStep: number;
  setActive: (stepIndex: number) => void;
};

const ToggleButtons = ({
  activeStep,
  setActive,
  leftLabel,
  rightLabel,
}: ToggleButtonsProps) => (
  <>
    <Button
      primary={activeStep === 0}
      onClick={() => setActive(0)}
      className="rounded-r-none"
    >
      {leftLabel}
    </Button>
    <Button
      primary={activeStep === 1}
      onClick={() => setActive(1)}
      className="rounded-l-none"
    >
      {rightLabel}
    </Button>
  </>
);

export default ToggleButtons;
