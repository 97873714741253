import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MultiValue } from 'react-select';

import { OptionType } from '@application/components/select-field/select';
import { debounce } from '@application/utils';
import {
  RequestsFilters,
  RequestStatusTypeCode,
  RequestTypeCode,
} from '@domain/graphql.types';

const defaultFilters = {
  // TODO: Changer le filtre pour la deadline
  // dates: undefined,
  ids: undefined,
  operationTerritoryCodes: undefined,
  jobSpecialtyCodes: undefined,
  operationUnitIds: undefined,
  status: undefined,
  types: undefined,
};

const statusCodeMap: Record<string, RequestsFilters['status']> = {
  '#in-progress': [RequestStatusTypeCode.InProgress],
  '#agreement': [RequestStatusTypeCode.Agreement],
  '#paused': [RequestStatusTypeCode.Paused],
  '#draft': [RequestStatusTypeCode.Draft],
  '#archived': [RequestStatusTypeCode.Archived],
};

type UseRequestFiltersProps = {
  resetPagination?: () => void;
};

const useRequestFilters = ({ resetPagination }: UseRequestFiltersProps) => {
  const [filters, setFilters] = useState<RequestsFilters>(defaultFilters);

  const location = useLocation();

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      status: statusCodeMap[location.hash] || undefined,
    }));
  }, [location.hash]);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateTextFilter = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }));
  });

  const updateSelectFilters =
    (fieldName: keyof RequestsFilters) => (values: MultiValue<OptionType>) => {
      let filterValues: (string | RequestTypeCode)[] = [];

      if (values.length) {
        filterValues = values.map((v: OptionType) =>
          fieldName === 'types'
            ? RequestTypeCode[v.value as keyof typeof RequestTypeCode]
            : v.value
        );
      }

      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: filterValues,
      }));
    };

  // TODO: Changer le filtre pour la deadline
  // const updateDateFilters =
  //   (fieldName: keyof RequestsFilters) =>
  //   (event: ChangeEvent<HTMLInputElement>) => {
  //     setFilters((currentFilters) => ({
  //       ...currentFilters,
  //       [fieldName]: event.target.value
  //         ? [new Date(event.target.value)]
  //         : undefined,
  //     }));
  //   };

  return {
    filters,
    updateSelectFilters,
    // TODO: Changer le filtre pour la deadline
    // updateDateFilters,
    updateTextFilter,
  };
};

export default useRequestFilters;
