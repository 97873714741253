import { useMutation } from 'urql';

import { MutationUpdateCandidateAndOtherFiles } from '@infrastructure/graphql/offer';

const useOfferCandidateUpdateCandidateAndOtherFiles = () => {
  const [{ data, fetching, error }, updateCandidateAndOtherFiles] = useMutation(
    MutationUpdateCandidateAndOtherFiles
  );

  return {
    updateCandidateAndOtherFiles,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateUpdateCandidateAndOtherFiles;
