import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateRequestCreateArgs,
} from '@domain/graphql.types';
import { MutationCandidateRequestCreate } from '@infrastructure/graphql/candidate';

const useCandidateRequestCreate = () => {
  const [{ data, fetching, error }, createCandidateRequest] = useMutation<
    Pick<Mutation, 'candidateRequestCreate'>,
    MutationCandidateRequestCreateArgs
  >(MutationCandidateRequestCreate);

  return {
    createCandidateRequest,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestCreate;
