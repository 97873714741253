import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { Stack, Switcher } from '@application/components/container-layouts';
import { SelectField } from '@application/components/select-field';
import { mapOptions } from '@application/utils';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';
import {
  CandidateOpportunitiesFilters,
  CandidateRequestType,
} from '@domain/graphql.types';

type FiltersFieldsProps = {
  updateTextFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  updateSelectFilters: (
    fieldName: keyof CandidateOpportunitiesFilters
  ) => (values: any) => void;
};

const FiltersFields = ({
  updateTextFilter,
  updateSelectFilters,
}: FiltersFieldsProps) => {
  const { t, i18n } = useTranslation('candidates');

  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();

  const operationTerritories = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n.language]
  );

  const candidateRequestTypes = Object.entries(CandidateRequestType).map(
    ([key, value]) => ({
      label: t(`enum.candidateRequestType.${value.toLowerCase()}`),
      value: key,
    })
  );

  return (
    <Stack space={16}>
      <Switcher space={24} limit={3} className="items-end mb-s-16">
        <TextInputField
          label={t('filters.search')}
          placeholder={`${t('filters.search')}...`}
          name="text"
          withIcon="ri-search-2-line text-24"
          hideIconBorder
          className="flex-1"
          onChange={updateTextFilter}
        />

        <SelectField
          label={t('filters.operationTerritory')}
          name="operationTerritory"
          options={operationTerritories}
          className="flex-1"
          multiple
          onChange={updateSelectFilters('operationTerritoryCodes')}
          limitTags={0}
          clearable
        />

        <SelectField
          label={t('filters.types')}
          name="types"
          options={candidateRequestTypes}
          className="flex-1"
          onChange={updateSelectFilters('types')}
          multiple
          limitTags={0}
          clearable
        />
      </Switcher>
    </Stack>
  );
};

export default FiltersFields;
