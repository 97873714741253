import { useTranslation } from 'react-i18next';

import { ContextualMenu } from '@application/components/contextual-menu';

type ActionMenuProps = {
  id: string;
  onDuplicate: (requestId: string) => () => Promise<void>;
  onDelete: (requestId: string) => () => void;
  hideDelete?: boolean;
};

const ActionMenu = ({
  id,
  onDuplicate,
  onDelete,
  hideDelete,
}: ActionMenuProps) => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'list.actions',
  });

  const actions = [
    {
      label: t('duplicate'),
      onClick: onDuplicate(id),
    },
    {
      label: t('delete'),
      hidden: hideDelete,
      onClick: onDelete(id),
    },
  ];

  return <ContextualMenu actions={actions} />;
};

export default ActionMenu;
