import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useInvitationDelete } from '@domain/account-user';

import { toViewModel } from '../view-models/delete-invitation.vm';

const useDeleteInvitation = () => {
  const { data, fetching, error, deleteInvitation } = useInvitationDelete();

  const { t } = useTranslation('organization', {
    keyPrefix: 'users.list',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    invitationDelete: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('actions.deleteInvitationError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.ok) {
      setAlertContent({
        action: t('actions.deleteInvitationSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    viewModel,
    deleteInvitation,
  };
};

export default useDeleteInvitation;
