import { useQuery } from 'urql';

import { Query, QueryOfferArgs } from '@domain/graphql.types';
import { Offer } from '@infrastructure/graphql/offer';

const useOffer = (input: QueryOfferArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'offer'>,
    QueryOfferArgs
  >({
    query: Offer,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.offer,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useOffer;
