import MagicBell, {
  FloatingNotificationInbox,
} from '@magicbell/magicbell-react';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'usehooks-ts';

import { COLORS, FONT_FAMILY, FONT_SIZE, SCREENS } from '@tailwind/theme';
import { rem } from '@tailwind/utils.cjs';

import { IconButton } from '../buttons';
import {
  NotificationCenterContext,
  withNotificationCenterProvider,
} from './context';
import { Notification } from './notification';

const magicBellTheme = {
  icon: { borderColor: COLORS.primary, width: rem(48) },
  header: {
    fontFamily: FONT_FAMILY.title[0],
    backgroundColor: COLORS['base-100'],
    textColor: COLORS.primary,
    fontSize: FONT_SIZE[24][0],
    fontWeight: '600 !important',
  },
};

interface NotificationCenterProps {
  userExternalId: string;
  defaultIsOpen?: boolean;
}

/**
 * The NotificationCenter component
 *
 * @param props.userEmail Email of the user whose notifications will be displayed
 * @param props.defaultIsOpen Whether the notifications popover is opened by default
 *
 * @example
 * <NotificationCenter userEmail={email} defaultIsOpen />
 */
const NotificationCenter = ({
  userExternalId,
  defaultIsOpen,
}: NotificationCenterProps) => {
  const { t } = useTranslation();
  const { isPanelOpen, setIsPanelOpen } = useContext(NotificationCenterContext);
  const isSmallDevice = useMediaQuery(
    `only screen and (max-width : ${SCREENS.md})`
  );

  const onToggle = useCallback(() => {
    setIsPanelOpen(!isPanelOpen);
  }, [isPanelOpen, setIsPanelOpen]);

  const bellIcon = useMemo(
    () => (
      <IconButton
        ghost
        icon={<i className="ri-notification-3-line !font-normal !text-20" />}
        text={t('notifications.label')}
      />
    ),
    [t]
  );

  const panelWidth = useMemo(
    () => (isSmallDevice ? 375 : 512),
    [isSmallDevice]
  );

  const panelHeight = useMemo(
    () => (isSmallDevice ? 812 : 592),
    [isSmallDevice]
  );

  const locale = {
    name: 'custom',
    translations: {
      header: {
        'mark-all-read': t('notifications.markAllRead'),
      },
    },
  };

  return (
    <MagicBell
      apiKey={`${import.meta.env.VITE_MAGICBELL_API_KEY}`}
      userExternalId={userExternalId}
      theme={magicBellTheme}
      BellIcon={bellIcon}
      isOpen={isPanelOpen}
      locale={locale}
      defaultIsOpen={defaultIsOpen}
      onToggle={onToggle}
    >
      {(props) => (
        <FloatingNotificationInbox
          width={panelWidth}
          height={panelHeight}
          layout={['header', 'content']}
          NotificationItem={Notification}
          {...props}
          hideArrow
          notificationPreferencesEnabled={false}
        />
      )}
    </MagicBell>
  );
};

export const WrapperNotificationCenter =
  withNotificationCenterProvider(NotificationCenter);
