import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '@application/hooks';
import { cn } from '@utils/lib-utils';

import { NavigationButton } from '../buttons';
import { ButtonLink } from '../links';
import { Tooltip } from '../tooltip';

type NavButtonProps = {
  label: string;
  isNavOpen: boolean;
  icon: string;
  disabled?: boolean;
  options?: {
    label: string;
    url: string;
  }[];
};

const NavButton = ({
  label,
  isNavOpen,
  options,
  icon,
  disabled,
}: NavButtonProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();

  const liRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!isNavOpen) {
      setDrawerOpen(false);
    }
  }, [isNavOpen]);

  useClickOutside({
    refs: [liRef],
    isDisregarded: isNavOpen,
    onOutsideClick: () => setDrawerOpen(false),
  });

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

  return (
    <Tooltip
      message={!isNavOpen && !isDrawerOpen ? t(`sidebar.${label}`) : undefined}
      position="right"
    >
      <div>
        <li
          ref={liRef}
          className={cn(
            'flex menu-dropdown rounded-md mt-s-8',
            isDrawerOpen && 'bg-secondary',
            !isNavOpen && 'relative max-w-[3.7rem]'
          )}
        >
          <NavigationButton
            childrenUrls={options?.map((o) => o.url)}
            className={cn(
              isNavOpen &&
                (isDrawerOpen
                  ? 'menu-dropdown-show menu-dropdown-toggle'
                  : 'menu-dropdown-toggle')
            )}
            icon={<i className={`mr-s-8 text-24 ${icon}`} />}
            onClick={toggleDrawer}
            disabled={disabled}
          >
            <span className="grow overflow-hidden whitespace-nowrap text-16 text-neutral-content ml-s-2">
              {t(`sidebar.${label}`)}
            </span>
          </NavigationButton>

          <ul
            className={cn(
              'menu-dropdown overflow-hidden rounded-md bg-secondary w-s-256',
              !isNavOpen && 'absolute left-s-56 before:hidden',
              isDrawerOpen && 'menu-dropdown-show'
            )}
          >
            {options?.map((option) => (
              <li
                key={option.label}
                className="text-16 my-s-8 text-neutral-content w-s-256"
              >
                <ButtonLink
                  rootLocationCheck
                  size="large"
                  isNavLink
                  className="justify-start font-normal px-s-16 border-transparent hover:border-transparent bg-secondary min-h-fit h-fit"
                  to={option.url}
                  onClick={() => setDrawerOpen(false)}
                >
                  {t(`sidebar.${option.label}`)}
                </ButtonLink>
              </li>
            ))}
          </ul>
        </li>
      </div>
    </Tooltip>
  );
};

export default NavButton;
