import { useMemo } from 'react';

import { JobPaymentFrequencyCode } from '@domain/graphql.types';

const RATIO = 0.15;
const MINIMUM_REQUEST_BUDGET = 3500;
const MAXIMUM_HOURS_PER_WEEK = 40;
const WEEKS_PER_YEAR = 52;
const ANNUAL_SALARY_FACTOR = 1;

type UseRequestBudgetProps = {
  jobPaymentFrequencyCode?: JobPaymentFrequencyCode | null;
  jobRemunerationAmount?: number | null;
  jobSalaryMax?: number | null;
  jobHourlyRateMax?: number | null;
};

const fixedAmountBudget = ({
  jobRemunerationAmount,
}: UseRequestBudgetProps) => {
  if (!jobRemunerationAmount) {
    return undefined;
  }

  return jobRemunerationAmount * RATIO;
};

const annualSalaryBudget = ({ jobSalaryMax }: UseRequestBudgetProps) => {
  if (!jobSalaryMax) {
    return undefined;
  }

  return jobSalaryMax * ANNUAL_SALARY_FACTOR * RATIO;
};

const hourlySalaryBudget = ({ jobHourlyRateMax }: UseRequestBudgetProps) => {
  if (!jobHourlyRateMax) {
    return undefined;
  }

  return jobHourlyRateMax * MAXIMUM_HOURS_PER_WEEK * WEEKS_PER_YEAR * RATIO;
};

const calculateBudget = (props: UseRequestBudgetProps) => {
  let budget: number | undefined;

  switch (props.jobPaymentFrequencyCode) {
    case JobPaymentFrequencyCode.FixedAmount:
      budget = fixedAmountBudget(props);
      break;
    case JobPaymentFrequencyCode.Hourly:
      budget = hourlySalaryBudget(props);
      break;
    case JobPaymentFrequencyCode.Yearly:
      budget = annualSalaryBudget(props);
      break;
    default:
      budget = undefined;
      break;
  }

  return budget && Math.max(MINIMUM_REQUEST_BUDGET, budget);
};

const useRequestBudget = (props: UseRequestBudgetProps) =>
  useMemo(() => calculateBudget(props), [props]);

export default useRequestBudget;
