import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { SelectField } from '@application/components';
import { Button } from '@application/components/buttons';
import { Modal } from '@application/components/modal';
import { AlertContext } from '@application/context';
import { OperationTerritoryCode } from '@domain/graphql.types';

import { flattenEdges } from '../../../../../utils/data-utils';
import { OptionType } from '../../../../components/select-field/select';
import { mapOptions } from '../../../../utils';
import { useGetOperationTerritories } from '../../profile/hooks';
import {
  useCreateAccountOperationTerritory,
  useGetAccountOperationTerritories,
} from '../hooks';

export type CreateOperationUnitModalProps = {
  onClose: () => void;
  afterSubmit: (operationTerritoryCodes: OperationTerritoryCode[]) => void;
};

const AddAccountOperationTerritoryModal = ({
  onClose,
  afterSubmit,
}: CreateOperationUnitModalProps) => {
  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'accountOperationTerritories.list',
  });
  const [operationTerritories, setOperationTerritories] = useState<[string]>();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  const {
    viewModel: { data },
  } = useGetAccountOperationTerritories();

  const presentAccountOperationTerritoryCodes = flattenEdges(
    data?.accountOperationTerritories.page.edges?.slice() || []
  ).map((a) => a.operationTerritory.code);

  const { setAlertContent } = useContext(AlertContext);

  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();

  const OPERATION_TERRITORIES_OPTIONS = useMemo(
    () =>
      mapOptions(
        OPERATION_TERRITORIES.filter(
          (o) =>
            !presentAccountOperationTerritoryCodes.includes(
              o.value as OperationTerritoryCode
            )
        ),
        i18n.language
      ),
    [
      OPERATION_TERRITORIES,
      i18n.language,
      presentAccountOperationTerritoryCodes,
    ]
  );
  const {
    createAccountOperationTerritories,
    viewModel: { isLoading },
  } = useCreateAccountOperationTerritory();

  const onSubmit = useCallback(
    async () => {
      if (!operationTerritories || operationTerritories.length < 1) {
        return;
      }
      const result = await createAccountOperationTerritories({
        input: {
          operationTerritoryCodes:
            operationTerritories as OperationTerritoryCode[],
        },
      });
      // Ideally we would put these operations in the useCreateOperationUnit.tsx, but the modal as it is kills the ongoing processes when closed.
      if (
        result.data?.accountOperationTerritoriesCreate
          .accountOperationTerritories
      ) {
        setAlertContent({
          action: t('messages.createSuccess'),
          modifier: 'alert-success',
        });
        afterSubmit(operationTerritories as OperationTerritoryCode[]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterSubmit, createAccountOperationTerritories, operationTerritories]
  );

  return (
    <div ref={modalRef} tabIndex={-1}>
      <Modal.CloseBtn onClick={onClose} />
      <SelectField
        label={t('operationTerritories')}
        name="operationTerritories"
        options={OPERATION_TERRITORIES_OPTIONS}
        value={
          OPERATION_TERRITORIES_OPTIONS.filter((l) =>
            operationTerritories?.includes(l.value)
          ) || null
        }
        onChange={(options: any) => {
          setOperationTerritories(
            options.map((option: OptionType) => option.value)
          );
        }}
        searchable
        clearable
        multiple
        limitTags={0}
      />

      <Modal.Action>
        <Button onClick={onClose} disabled={isLoading}>
          {tGlobal('button.cancel')}
        </Button>

        <Button primary onClick={onSubmit} loading={isLoading}>
          {t('validate')}
        </Button>
      </Modal.Action>
    </div>
  );
};

export default AddAccountOperationTerritoryModal;
