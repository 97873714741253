import { useMutation } from 'urql';

import {
  Mutation,
  MutationNegotiatingCandidateOfferUpdateArgs,
} from '@domain/graphql.types';
import MutationNegotiatingCandidateOfferUpdate from '@infrastructure/graphql/offer/candidate/update.negotiating.candidate.mutation';

const useNegotiatingOfferUpdate = () => {
  const [{ data, fetching, error }, updateNegotiatingCandidateOffer] =
    useMutation<
      Pick<Mutation, 'negotiatingCandidateOfferUpdate'>,
      MutationNegotiatingCandidateOfferUpdateArgs
    >(MutationNegotiatingCandidateOfferUpdate);

  return {
    updateNegotiatingCandidateOffer,
    data,
    fetching,
    error,
  };
};

export default useNegotiatingOfferUpdate;
