// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useCallback, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import { TextInput } from '@application/components/form/text-input-field/textInput';
import { Select } from '@application/components/select-field/select';
import { PlaceholderSwitcher } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { ModalContext } from '@application/context';
import { useOfferPaymentMethodOptions } from '@application/hooks';
import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { RecruitmentNegotiationRoomFormFields } from '../../../room/schema';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';
import { getCellStyleForState } from '../../utils';
import UpdateTotalCostsModal from '../update-total-costs-modal/UpdateTotalCostsModal';

type GeneralConditionsProps = {
  modificationEnabled: boolean;
  onSubmit: () => Promise<void>;
  room: RecruitmentNegotiationRoom;
};

const paymentMethodKey: keyof RecruitmentNegotiationRoomFormFields =
  'conditionPaymentMethod';
const depositKey: keyof RecruitmentNegotiationRoomFormFields =
  'conditionDeposit';
const maxRecruitmentPeriodKey: keyof RecruitmentNegotiationRoomFormFields =
  'conditionMaxRecruitmentPeriod';
const minGuaranteedPeriodKey: keyof RecruitmentNegotiationRoomFormFields =
  'conditionMinGuaranteedPeriod';

const GeneralConditions = ({
  modificationEnabled,
  onSubmit,
  room,
}: GeneralConditionsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const { setModal } = useContext(ModalContext);

  const methods = useFormContext<RecruitmentNegotiationRoomFormFields>();

  const { watch, control, setValue } = methods;

  const handleUpdateTotalCost = useCallback(() => {
    setModal({
      title: t('totalCostsModalTitle'),
      centered: true,
      maxWidth: 'fit',
      content: (
        <UpdateTotalCostsModal
          onSubmit={() => {
            setModal(null);
            onSubmit();
          }}
          methods={methods}
          onClose={() => setModal(null)}
          room={room}
        />
      ),
    });
  }, [methods, onSubmit, room, setModal, t]);

  const fieldInModification = watch('fieldInModification');

  const paymentMethodOptions = useOfferPaymentMethodOptions().map((method) => ({
    value: method.value,
    label: method.label.long,
  }));

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('generalConditions')}
        </th>

        <td
          className={cn(TH_ROW_CLASS_NAME.highlight, 'border-l-1 border-b-1')}
        >
          &nbsp;
        </td>
      </tr>

      <tr key="offer-total-costs">
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          {t('estimatedTotalCosts')}
        </th>

        <td className={cn(TD_CLASS_NAME.base, 'group')}>
          <div className="flex items-center justify-between h-full">
            <div
              className={cn(
                'flex items-center rounded-md h-full w-fit px-s-8',
                getCellStyleForState(room.generalPropertiesState.totalCosts)
              )}
            >
              <PlaceholderSwitcher value={room.negotiatingOffer.totalCosts}>
                <span>
                  {tGlobal('suffix.currencyValue', {
                    value: room.negotiatingOffer.totalCosts,
                  })}
                </span>
              </PlaceholderSwitcher>
            </div>

            <Button
              className={cn('hidden font-normal px-s-8 min-w-fit', {
                'group-hover:block': modificationEnabled,
              })}
              icon={<i className="ri-pencil-line mr-s-8" />}
              size="x-small"
              onClick={handleUpdateTotalCost}
            >
              {t('modify')}
            </Button>
          </div>
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('paymentMethod')} lineClamp={2}>
            <div>{t('paymentMethod')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === paymentMethodKey,
          })}
        >
          {fieldInModification === paymentMethodKey ? (
            <Controller
              name="conditionPaymentMethod"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  className="border-2 border-black h-full"
                  controlClassName="border-none"
                  name={name}
                  menuPlacement="bottom"
                  options={paymentMethodOptions}
                  invalid={!!error}
                  value={paymentMethodOptions.find(
                    (method) => method.value === value
                  )}
                  onChange={(option: any) => {
                    onChange(option.value);
                    onSubmit();
                  }}
                  searchable
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.generalPropertiesState.paymentMethod
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer.generalContractualConditions
                      .paymentMethod
                  }
                >
                  <span>
                    {tGlobal(
                      `enum.offerConditionPaymentMethodCode.short.${room.negotiatingOffer.generalContractualConditions.paymentMethod
                        ?.toString()
                        .toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() =>
                  setValue('fieldInModification', paymentMethodKey)
                }
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('deposit')} lineClamp={2}>
            <div>{t('deposit')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === depositKey,
          })}
        >
          {fieldInModification === depositKey ? (
            <Controller
              name="conditionDeposit"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  maxValue={99999999.99}
                  step={0.01}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center  justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(room.generalPropertiesState.deposit)
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer.generalContractualConditions.deposit
                  }
                >
                  <span>
                    {tGlobal('suffix.currencyValue', {
                      value:
                        room.negotiatingOffer.generalContractualConditions
                          .deposit,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() => setValue('fieldInModification', depositKey)}
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('maxRecruitmentPeriod')} lineClamp={2}>
            <div>{t('maxRecruitmentPeriod')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === maxRecruitmentPeriodKey,
          })}
        >
          {fieldInModification === maxRecruitmentPeriodKey ? (
            <Controller
              name="conditionMaxRecruitmentPeriod"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center  justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.generalPropertiesState.maxRecruitmentPeriod
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer.generalContractualConditions
                      .maxRecruitmentPeriod
                  }
                >
                  <span>
                    {tGlobal('suffix.week', {
                      count: room.negotiatingOffer.generalContractualConditions
                        .maxRecruitmentPeriod as number,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() =>
                  setValue('fieldInModification', maxRecruitmentPeriodKey)
                }
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('minGuaranteedPeriod')} lineClamp={2}>
            <div>{t('minGuaranteedPeriod')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === minGuaranteedPeriodKey,
          })}
        >
          {fieldInModification === minGuaranteedPeriodKey ? (
            <Controller
              name="conditionMinGuaranteedPeriod"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center  justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.generalPropertiesState.minGuaranteedPeriod
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer.generalContractualConditions
                      .minGuaranteedPeriod
                  }
                >
                  <span>
                    {tGlobal('suffix.month', {
                      count: room.negotiatingOffer.generalContractualConditions
                        .minGuaranteedPeriod as number,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() =>
                  setValue('fieldInModification', minGuaranteedPeriodKey)
                }
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default GeneralConditions;
