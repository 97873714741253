import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaField } from '@application/components';

import { Section } from '../../components';
import { CandidateRequestFormFields } from '../../schema';

type NotesFieldsProps = {
  control: Control<CandidateRequestFormFields, any>;
  errors: FieldErrors<CandidateRequestFormFields>;
};

const NotesFields = ({ control, errors }: NotesFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  return (
    <Section legend={t('subtitles.notes')} hideBox>
      <Controller
        name="additionalNotes"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <TextAreaField
            name={name}
            value={value || ''}
            className="mt-s-0"
            label={t('labels.notes')}
            hideLabel
            rows={4}
            maxChar={1024}
            onChange={onChange}
            invalid={!!errors.additionalNotes}
            helperText={
              errors.additionalNotes?.message &&
              tGlobal(errors.additionalNotes?.message, { max: '1024' })
            }
          />
        )}
      />
    </Section>
  );
};

export default NotesFields;
