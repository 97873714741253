import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { Alert } from '@application/components/alert';
import { AlertProps } from '@application/components/alert/Alert';
import { AlertContext } from '@application/context';

type AlertProviderProps = {
  children: React.ReactNode;
};

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [alertContent, setAlertContent] = useState<AlertProps | null>(null);

  useEffect(() => {
    if (alertContent) {
      const timeout = alertContent.modifier === 'alert-success' ? 5000 : 10000;
      const timer = setTimeout(() => setAlertContent(null), timeout);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [alertContent]);

  const contextValue = useMemo(
    () => ({ alertContent, setAlertContent }),
    [alertContent, setAlertContent]
  );

  return (
    <AlertContext.Provider value={contextValue}>
      {children}

      {alertContent &&
        createPortal(
          <Alert
            action={alertContent?.action}
            text={alertContent?.text}
            modifier={alertContent?.modifier}
            onClose={() => setAlertContent(null)}
          />,
          document.body
        )}
    </AlertContext.Provider>
  );
};

export default React.memo(AlertProvider);
