import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import {
  Offer,
  OfferTemporaryPlacementConditions,
  Request,
  RequestTemporaryPlacementConditions,
} from '@domain/graphql.types';

type TemporaryPlacementProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const TEMPORARY_PLACEMENT_CONDITIONS = [
  {
    key: 'foreignApplicants',
    label: 'foreignCandidates',
    valueType: 'boolean',
  },
  {
    key: 'possibleBuyback',
    label: 'buyback',
    valueType: 'boolean',
  },
];

const TemporaryPlacement = ({ data, request }: TemporaryPlacementProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const getContentValue = useCallback(
    (type: string, value: string | number | boolean | null | undefined) => {
      switch (type) {
        case 'boolean':
          return value
            ? tGlobal(`boolean.${value?.toString().toLowerCase() as string}`)
            : '-';
        default:
          return '-';
      }
    },
    [tGlobal]
  );

  return (
    <>
      {TEMPORARY_PLACEMENT_CONDITIONS.map(
        (
          condition: { key: string; label: string; valueType: string },
          index
        ) => (
          <SectionGridRow
            key={condition.key}
            isTopRow={index === 0}
            isOffer
            rightChildren={
              <PlaceholderSwitcher
                value={
                  request?.temporaryPlacementContractualConditions?.[
                    `${condition.key}Value` as keyof RequestTemporaryPlacementConditions
                  ]
                }
              >
                <span className="text-16 font-semibold">
                  {getContentValue(
                    condition.valueType,
                    request?.temporaryPlacementContractualConditions?.[
                      `${condition.key}Value` as keyof RequestTemporaryPlacementConditions
                    ]
                  )}
                </span>
              </PlaceholderSwitcher>
            }
          >
            <span className="mr-s-8">{t(`labels.${condition.label}`)}</span>

            <PlaceholderSwitcher
              value={
                data?.temporaryPlacementContractualConditions?.[
                  condition.key as keyof OfferTemporaryPlacementConditions
                ]
              }
            >
              <span className="text-16 font-semibold">
                {getContentValue(
                  condition.valueType,
                  data?.temporaryPlacementContractualConditions?.[
                    condition.key as keyof OfferTemporaryPlacementConditions
                  ]
                )}
              </span>
            </PlaceholderSwitcher>
          </SectionGridRow>
        )
      )}

      <SectionGridRow
        isOffer
        rightChildren={
          <div className="text-16 text-primary font-semibold text-center">
            <span className="font-normal text-14">
              {t('labels.responsibilityTravel')}&nbsp;
            </span>
            <p className="mb-s-10">
              {request?.temporaryPlacementContractualConditions
                ?.travelExpensesResponsibilityValue
                ? t(
                    `enum.requestConditionResponsibility.${request.temporaryPlacementContractualConditions.travelExpensesResponsibilityValue.toLowerCase()}`
                  )
                : '-'}
            </p>

            <span className="font-normal text-14">
              {t('labels.responsibilityTraining')}&nbsp;
            </span>
            <p className="mb-s-10">
              {request?.temporaryPlacementContractualConditions
                ?.trainingExpensesResponsibilityValue
                ? t(
                    `enum.requestConditionResponsibility.${request.temporaryPlacementContractualConditions.trainingExpensesResponsibilityValue.toLowerCase()}`
                  )
                : '-'}
            </p>

            <span className="font-normal text-14">
              {t('labels.employeeHelpProgram')}&nbsp;
            </span>
            <p className="mb-s-10">
              {request?.temporaryPlacementContractualConditions
                ?.employeeHelpProgramResponsibilityValue
                ? t(
                    `enum.requestConditionResponsibility.${request.temporaryPlacementContractualConditions.employeeHelpProgramResponsibilityValue.toLowerCase()}`
                  )
                : '-'}
            </p>

            <span className="font-normal text-14">
              {t('labels.responsibilityCnesst')}&nbsp;
            </span>
            <p className="mb-s-10">
              {request?.temporaryPlacementContractualConditions
                ?.cnesstExpensesResponsibilityValue
                ? t(
                    `enum.requestConditionResponsibility.${request.temporaryPlacementContractualConditions.cnesstExpensesResponsibilityValue.toLowerCase()}`
                  )
                : '-'}
            </p>
          </div>
        }
      >
        <div className="flex flex-wrap items-center gap-s-16">
          <p>{t('labels.responsibilities')}</p>
          <div>
            <ul className="list-disc pl-s-16">
              <li>
                {t('labels.travelCosts')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.travelExpensesResponsibility
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.travelExpensesResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>

              <li>
                {t('labels.trainingCosts')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.trainingExpensesResponsibility
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.trainingExpensesResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>

              <li>
                {t('labels.employeeHelpProgram')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.employeeHelpProgramResponsibility
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.employeeHelpProgramResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>

              <li>
                {t('labels.cnesst')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.cnesstExpensesResponsibility
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionResponsibility.${data?.temporaryPlacementContractualConditions?.cnesstExpensesResponsibility?.toLowerCase()}`
                    )}
                  </span>
                </PlaceholderSwitcher>
              </li>

              <li>
                {t('labels.other')}:&nbsp;
                <PlaceholderSwitcher
                  value={
                    data?.temporaryPlacementContractualConditions
                      ?.otherResponsibilities
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {
                      data?.temporaryPlacementContractualConditions
                        ?.otherResponsibilities
                    }
                  </span>
                </PlaceholderSwitcher>
              </li>
            </ul>
          </div>
        </div>
      </SectionGridRow>
    </>
  );
};

export default TemporaryPlacement;
