import { gql } from '@urql/core';

const MutationCandidateNegotiationRoomReject = gql`
  mutation CandidateNegotiationRoomReject(
    $input: CandidateNegotiationRoomInput!
  ) {
    candidateNegotiationRoomReject(input: $input) {
      room {
        id
      }
    }
  }
`;

export default MutationCandidateNegotiationRoomReject;
