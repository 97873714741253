import { useEffect, useMemo, useRef, useState } from 'react';

import useExternalScript from '@application/hooks/useExternalScript';
import { debounce } from '@application/utils';

export type Prediction = google.maps.places.AutocompletePrediction;

const useFetchPredictions = () => {
  const [isReady, setIsReady] = useState(false);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);

  const GOOGLE_MAPS_API_URL = useMemo(
    () =>
      `https://maps.googleapis.com/maps/api/js?key=${
        import.meta.env.VITE_GOOGLE_MAPS_API_KEY
      }&libraries=places&callback=Function.prototype`,
    []
  );

  const { loaded } = useExternalScript(GOOGLE_MAPS_API_URL, 'google-maps');

  const getAddressPredictions = useMemo(
    () =>
      // Use debouncing here by waiting few ms between keystrokes
      // in order to avoid too many calls to get address predictions from Google Places API.
      debounce(
        async (
          request: {
            input: string;
            componentRestrictions?: google.maps.places.ComponentRestrictions;
          },
          callback: (predictions: readonly Prediction[]) => void
        ) => {
          const { predictions }: google.maps.places.AutocompleteResponse =
            await (autocompleteServiceRef.current as any).getPlacePredictions(
              request
            );

          callback(predictions);
        },
        400
      ),
    []
  );

  useEffect(() => {
    if (!loaded || typeof window === 'undefined') {
      return;
    }

    // Initialize Google Autocomplete service
    if (!autocompleteServiceRef.current && window.google) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteServiceRef.current) {
      return;
    }

    setIsReady(true);
  }, [loaded]);

  return {
    isReady,
    autocompleteServiceRef,
    getAddressPredictions,
  };
};

export default useFetchPredictions;
