import { useMutation } from 'urql';

import {
  Mutation,
  MutationAccountPreferredSpecialtiesCreateArgs,
} from '@domain/graphql.types';
import { AccountPreferredSpecialtiesCreate } from '@infrastructure/graphql/account';

const useAccountPreferredSpecialtiesCreate = () => {
  const [{ data, fetching, error }, createAccountPreferredSpecialties] =
    useMutation<
      Pick<Mutation, 'accountPreferredSpecialtiesCreate'>,
      MutationAccountPreferredSpecialtiesCreateArgs
    >(AccountPreferredSpecialtiesCreate);

  return {
    createAccountPreferredSpecialties,
    data: data?.accountPreferredSpecialtiesCreate,
    fetching,
    error,
  };
};

export default useAccountPreferredSpecialtiesCreate;
