import { useQuery } from 'urql';

import { Query, QueryCandidateNegotiationsArgs } from '@domain/graphql.types';
import { CandidateNegotiations } from '@infrastructure/graphql/candidate';

const useCandidateNegotiations = (input: QueryCandidateNegotiationsArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateNegotiations'>,
    QueryCandidateNegotiationsArgs
  >({
    query: CandidateNegotiations,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateNegotiations;
