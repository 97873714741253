import { useQuery } from 'urql';

import {
  Query,
  QueryAccountOperationTerritoriesArgs,
} from '@domain/graphql.types';

import { AccountOperationTerritories } from '../../../infrastructure/graphql/account';

const useAccountOperationTerritories = (
  input?: QueryAccountOperationTerritoriesArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'accountOperationTerritories'>,
    QueryAccountOperationTerritoriesArgs
  >({
    query: AccountOperationTerritories,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useAccountOperationTerritories;
