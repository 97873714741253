import { gql } from 'urql';

const RecruitmentOpportunityInternationalRecruitmentConditionsFragment = gql`
  fragment RecruitmentOpportunityInternationalRecruitmentConditionsFragment on RequestInternationalRecruitmentConditions {
    exclusivityPeriodDisplay
    exclusivityPeriodValue
    mandatoryWorkPermitDisplay
    mandatoryWorkPermitValue
    maximumReplacementDisplay
    maximumReplacementValue
  }
`;

export default RecruitmentOpportunityInternationalRecruitmentConditionsFragment;
