import { useOperationUnit } from '@domain/operation/unit';

import { toViewModel } from '../view-models/get-operation-unit.vm';

const useGetOperationUnit = (operationUnitId: string) => {
  const { data, fetching, error, reexecuteQuery } = useOperationUnit({
    input: { operationUnitId },
  });

  const viewModel = toViewModel({
    operationUnit: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchOperationUnit: reexecuteQuery,
  };
};

export default useGetOperationUnit;
