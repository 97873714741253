import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';

import { Fieldset, HelperText, Radio } from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import { Languages } from '@application/enums/languages';
import { ValueText } from '@application/utils';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

type RadioGroupProps = {
  data: ValueText[];
  name: string;
  language: keyof typeof Languages;
  className?: string;
  legend?: string | ReactNode;
  legendClassName?: string;
  hideLegend?: boolean;
  legendSize?: 'small' | 'medium' | 'big';
  /**
   * Specify if the `<legend>` and children should be aligned in a row
   */
  alignRow?: boolean;
  value?: string | boolean | undefined;
  helperText?: string;
  invalid?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  allowDeselect?: boolean;
  disabled?: boolean;
};

const RadioGroup = ({
  data,
  name,
  language,
  className: customClassName,
  legend,
  legendClassName: customlegendClassName,
  hideLegend,
  legendSize,
  alignRow,
  onChange,
  value,
  helperText,
  invalid,
  disabled,
  allowDeselect = false,
}: RadioGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<
    string | boolean | undefined
  >(value);

  const className = cn('', customClassName);

  const helperTextId = useMemo(() => randomString(), []);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const handleSelectedRadioClick = (event: any): void => {
    const currentValue = (event.target as HTMLInputElement).value;

    if (currentValue === selectedValue) {
      setSelectedValue(undefined);

      if (onChange)
        onChange({ ...event, target: { ...event.target, value: null } });
    }
  };

  useEffect(() => {
    if (selectedValue === value) {
      return;
    }

    setSelectedValue(value);
  }, [selectedValue, value]);

  return (
    <Fieldset
      legend={legend}
      hideLegend={hideLegend}
      legendSize={legendSize}
      legendClassName={customlegendClassName}
      className={className}
      alignRow={alignRow}
      invalid={invalid}
    >
      <Cluster space={16} as="ul">
        {data.map((d: ValueText) => (
          <li key={d.value}>
            <Radio
              name={name}
              value={d.value}
              label={d.text[language as keyof typeof Languages]}
              onChange={handleRadioChange as any}
              checked={!disabled && `${selectedValue}` === d.value}
              asButton
              disabled={disabled}
              onClick={allowDeselect ? handleSelectedRadioClick : undefined}
            />
          </li>
        ))}
      </Cluster>

      {helperText && (
        <HelperText id={helperTextId} invalid={invalid}>
          {helperText}
        </HelperText>
      )}
    </Fieldset>
  );
};

export default RadioGroup;
