import { gql } from '@urql/core';

const MutationOfferCandidateCreate = gql`
  mutation OfferCandidateCreate($input: OfferCandidateCreateInput!) {
    offerCandidateCreate(input: $input) {
      offerCandidate {
        id
      }
    }
  }
`;

export default MutationOfferCandidateCreate;
