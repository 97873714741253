import { useMutation } from 'urql';

import { Mutation, MutationInvitationDeleteArgs } from '@domain/graphql.types';
import { InvitationDelete } from '@infrastructure/graphql/account-user';

const useInvitationDelete = () => {
  const [{ data, fetching, error }, deleteInvitation] = useMutation<
    Pick<Mutation, 'invitationDelete'>,
    MutationInvitationDeleteArgs
  >(InvitationDelete);

  return {
    deleteInvitation,
    data: data?.invitationDelete,
    fetching,
    error,
  };
};

export default useInvitationDelete;
