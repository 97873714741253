import { useRecruitmentOpportunity } from '@domain/opportunity';

import { toViewModel } from '../view-models/get-recruitment-opportunity.vm';

const useGetRecruitmentOpportunity = (requestId: string) => {
  const { data, fetching, error } = useRecruitmentOpportunity({
    input: { requestId },
  });

  const viewModel = toViewModel({
    recruitmentOpportunity: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
  };
};

export default useGetRecruitmentOpportunity;
