import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateOfferDeleteArgs,
} from '@domain/graphql.types';
import { MutationCandidateOfferDelete } from '@infrastructure/graphql/candidate/offer';

const useCandidateOfferDelete = () => {
  const [{ data, fetching, error }, deleteCandidateOffer] = useMutation<
    Pick<Mutation, 'candidateOfferDelete'>,
    MutationCandidateOfferDeleteArgs
  >(MutationCandidateOfferDelete);

  return {
    deleteCandidateOffer,
    data,
    fetching,
    error,
  };
};

export default useCandidateOfferDelete;
