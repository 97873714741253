import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const CandidateRequestListFragment = gql`
  ${DescriptionsFragment}
  fragment CandidateRequestListFragment on CandidateRequest {
    id
    firstName
    lastName
    status
    type
    operationTerritoryCodes
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    receivingOfferDeadline
    candidateOffersCount
    availableTransitions
    createdAt
    updatedAt
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    user {
      name
      avatarUrl
    }
  }
`;

export default CandidateRequestListFragment;
