import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import {
  RecruitmentNegotiationsFilters,
  RecruitmentNegotiationStatus,
} from '@domain/graphql.types';

const defaultFilters = {
  statuses: undefined,
};

const statusCodeMap: Record<
  string,
  RecruitmentNegotiationsFilters['statuses']
> = {
  '#in-progress': [RecruitmentNegotiationStatus.InProgress],
  '#completed': [RecruitmentNegotiationStatus.Completed],
};

const useRecruitmentNegotiationFilters = () => {
  const [filters, setFilters] =
    useState<RecruitmentNegotiationsFilters>(defaultFilters);

  const { hash } = useLocation();

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      statuses: statusCodeMap[hash] || undefined,
    }));
  }, [hash]);

  return {
    filters,
  };
};

export default useRecruitmentNegotiationFilters;
