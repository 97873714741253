import { Fieldset } from '@application/components';
import { Box } from '@application/components/container-layouts';
import { Space } from '@application/components/container-layouts/types';
import { cn } from '@utils/lib-utils';

type SectionProps = {
  legend?: string;
  children: React.ReactNode;
  className?: string;
  hideBox?: boolean;
  hideLegend?: boolean;
  padding?: Space;
};

const Section = ({
  children,
  className: customClassName,
  legend,
  hideLegend,
  hideBox,
  padding: customPadding,
}: SectionProps) => {
  const padding = customPadding !== undefined ? customPadding : 24;
  return (
    <Fieldset
      className={cn('w-full flex flex-col mb-s-24', customClassName)}
      legend={legend}
      legendSize="big"
      hideLegend={hideLegend}
    >
      {hideBox ? (
        children
      ) : (
        <Box padding={padding} className="w-full">
          {children}
        </Box>
      )}
    </Fieldset>
  );
};

export default Section;
