import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { IconButton } from '@application/components/buttons';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type HeadhunterConditionsProps = {
  data: Request | undefined;
};

const HeadhunterConditions = ({ data }: HeadhunterConditionsProps) => {
  const { t } = useTranslation('requests');

  return (
    <>
      <SectionGridRow
        isTopRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.headhunterContractualConditions
                    ?.maximumReplacementDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.maxReplacements')}</span>
        <PlaceholderSwitcher
          value={data?.headhunterContractualConditions?.maximumReplacementValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.headhunterContractualConditions?.maximumReplacementValue}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.headhunterContractualConditions
                    ?.headhuntingExclusivityPeriodDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.exclusivityPeriod')}</span>
        <PlaceholderSwitcher
          value={
            data?.headhunterContractualConditions
              ?.headhuntingExclusivityPeriodValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {
              data?.headhunterContractualConditions
                ?.headhuntingExclusivityPeriodValue
            }
            &nbsp;{t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>
    </>
  );
};

export default HeadhunterConditions;
