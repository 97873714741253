import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

type RoomsTableProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const RoomsTable = ({ onChange }: RoomsTableProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });

  return (
    <table className="w-full bg-base-100">
      <caption className="sr-only">{t('ongoingNegotiations')}</caption>
      <TableHeader onChange={onChange} />
      <TableBody />
    </table>
  );
};

export default RoomsTable;
