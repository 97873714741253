import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useCandidateOfferCreate } from '@domain/candidate/offer';

import { toViewModel } from '../view-models/create-candidate-offer.vm';

const useCreateCandidateOffer = () => {
  const { data, fetching, error, createCandidateOffer } =
    useCandidateOfferCreate();

  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const navigate = useNavigate();
  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({ candidateOffer: { data, fetching, error } });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('createOfferError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateOfferCreate?.candidateOffer) {
      setAlertContent({
        action: t('createOfferSuccess'),
        modifier: 'alert-success',
      });

      navigate(
        generatePath(PrivatePage.VIEW_CANDIDATE_OFFER, {
          id: data.candidateOfferCreate.candidateOffer.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { createCandidateOffer, viewModel };
};

export default useCreateCandidateOffer;
