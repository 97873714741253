import { useMutation } from 'urql';

import { Mutation, MutationAccountUserDeleteArgs } from '@domain/graphql.types';
import { AccountUserDelete } from '@infrastructure/graphql/account-user';

const useAccountUserDelete = () => {
  const [{ data, fetching, error }, deleteAccountUser] = useMutation<
    Pick<Mutation, 'accountUserDelete'>,
    MutationAccountUserDeleteArgs
  >(AccountUserDelete);

  return {
    deleteAccountUser,
    data: data?.accountUserDelete,
    fetching,
    error,
  };
};

export default useAccountUserDelete;
