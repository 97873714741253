import { gql } from 'urql';

const RecruitmentOpportunityTemporaryPlacementConditionsFragment = gql`
  fragment RecruitmentOpportunityTemporaryPlacementConditionsFragment on RequestTemporaryPlacementConditions {
    possibleBuybackValue
    possibleBuybackBonus
    possibleBuybackDisplay
    risksValue
    risksDisplay
    travelExpensesResponsibilityValue
    trainingExpensesResponsibilityValue
    employeeHelpProgramResponsibilityValue
    cnesstExpensesResponsibilityValue
    otherResponsibilitiesValue
    responsibilitiesDisplay
    foreignApplicantsValue
    foreignApplicantsDisplay
  }
`;

export default RecruitmentOpportunityTemporaryPlacementConditionsFragment;
