import { useTranslation } from 'react-i18next';

import { StatusTabs } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';

import { OfferStatusTypeCode } from '../../../../domain/graphql.types';
import { OffersList } from './list';

const OfferListPage = () => {
  const { t } = useTranslation('offers');

  return (
    <>
      <div className="mb-s-32 w-fit">
        <StatusTabs
          t={t}
          mainPath={RootPrivatePage.OFFERS}
          type="offer"
          statuses={[
            OfferStatusTypeCode.Accepted,
            OfferStatusTypeCode.Draft,
            OfferStatusTypeCode.Published,
            OfferStatusTypeCode.Rejected,
          ]}
        />
      </div>

      <OffersList />
    </>
  );
};

export default OfferListPage;
