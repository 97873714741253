import { useQuery } from 'urql';

import { Query, QueryCandidateRequestArgs } from '@domain/graphql.types';
import { CandidateRequest } from '@infrastructure/graphql/candidate';

const useCandidateRequest = (input: QueryCandidateRequestArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateRequest'>,
    QueryCandidateRequestArgs
  >({
    query: CandidateRequest,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.candidateRequest,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateRequest;
