import { gql } from 'urql';

const OperationUnitUpdate = gql`
  mutation OperationUnitUpdate($input: OperationUnitUpdateInput!) {
    operationUnitUpdate(input: $input) {
      operationUnit {
        id
      }
    }
  }
`;

export default OperationUnitUpdate;
