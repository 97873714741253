import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { MultiValue } from 'react-select';

import { OptionType } from '@application/components/select-field/select';
import { debounce } from '@application/utils';
import { AccountPreferredSpecialtiesFilters } from '@domain/graphql.types';

const defaultFilters = {
  text: undefined,
  industryCodes: undefined,
  sectorCodes: undefined,
};

type UseFiltersProps = {
  resetPagination?: () => void;
};

const useFilters = ({ resetPagination }: UseFiltersProps) => {
  const [filters, setFilters] =
    useState<AccountPreferredSpecialtiesFilters>(defaultFilters);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateTextFilter = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }));
  });

  const updateSelectFilters =
    (fieldName: keyof AccountPreferredSpecialtiesFilters) =>
    (values: MultiValue<OptionType>) => {
      let filterValues: string[] = [];

      if (values.length) {
        filterValues = values.map(({ value }: OptionType) => value);
      }

      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: filterValues,
      }));
    };

  const hasFilters = useMemo(
    () =>
      Object.values(filters).some((s) =>
        typeof s === 'string' ? !!s : (s?.length || 0) > 0
      ),
    [filters]
  );

  return {
    hasFilters,
    filters,
    updateSelectFilters,
    updateTextFilter,
  };
};

export default useFilters;
