import { gql } from 'urql';

import PlainCandidateOfferFragment from '../../offer/fragment/plain.candidate.offer.fragment';
import CandidateRequestFragment from '../../request/fragment/candidate.request.fragment';
import CandidateNegotiationGeneralPropertiesStateFragment from './candidate.negotiation.general.properties.state.fragment';
import CandidateNegotiationOutsourcingPropertiesStateFragment from './candidate.negotiation.outsourcing.properties.state.fragment';
import CandidateNegotiationRecruitmentPropertiesStateFragment from './candidate.negotiation.recruitment.properties.state.fragment';
import CandidateNegotiationTemporaryPlacementPropertiesStateFragment from './candidate.negotiation.temporary.placement.properties.state.fragment';

const CandidateNegotiationRoomFragment = gql`
  ${CandidateNegotiationGeneralPropertiesStateFragment}
  ${CandidateNegotiationRecruitmentPropertiesStateFragment}
  ${CandidateNegotiationTemporaryPlacementPropertiesStateFragment}
  ${CandidateNegotiationOutsourcingPropertiesStateFragment}
  ${PlainCandidateOfferFragment}
  ${CandidateRequestFragment}

  fragment CandidateNegotiationRoomFragment on CandidateNegotiationRoom {
    id
    candidateNegotiationId
    candidateRequestId
    candidateRequestAccountId
    candidateRequestUserId
    candidateOfferId
    candidateOfferAccountId
    candidateOfferUserId
    negotiatingCandidateOfferId
    status
    generalPropertiesState {
      ...CandidateNegotiationGeneralPropertiesStateFragment
    }
    recruitmentPropertiesState {
      ...CandidateNegotiationRecruitmentPropertiesStateFragment
    }
    temporaryPlacementPropertiesState {
      ...CandidateNegotiationTemporaryPlacementPropertiesStateFragment
    }
    outsourcingPropertiesState {
      ...CandidateNegotiationOutsourcingPropertiesStateFragment
    }
    createdAt
    updatedAt
    negotiatingCandidateOffer {
      ...PlainCandidateOfferFragment
    }
    candidateOffer {
      ...PlainCandidateOfferFragment
    }
    candidateRequest {
      ...CandidateRequestFragment
    }
  }
`;

export default CandidateNegotiationRoomFragment;
