import { gql } from '@urql/core';

import { CandidateNegotiationListFragment } from './fragment';

const CandidateNegotiations = gql`
  ${CandidateNegotiationListFragment}

  query CandidateNegotiations($filterBy: CandidateNegotiationsFilters) {
    candidateNegotiations(filterBy: $filterBy) {
      results {
        ...CandidateNegotiationListFragment
      }
      totalCount
    }
  }
`;

export default CandidateNegotiations;
