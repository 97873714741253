import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import {
  CandidateRequest,
  JobPaymentFrequencyCode,
} from '@domain/graphql.types';

type ExpectationsProps = {
  data?: CandidateRequest;
};

const Expectations = ({ data }: ExpectationsProps) => {
  const { t } = useTranslation('candidates');

  const remuneration = useMemo(() => {
    if (data?.paymentFrequencyCode === JobPaymentFrequencyCode.Yearly) {
      return t('labels.perYear', {
        min: data.annualSalaryMin,
        max: data.annualSalaryMax,
      });
    }

    if (data?.paymentFrequencyCode === JobPaymentFrequencyCode.Hourly) {
      return t('labels.perHour', {
        min: data.hourlyRateMin,
        max: data.hourlyRateMax,
      });
    }

    if (data?.paymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount) {
      return t('labels.fixedAmount', {
        amount: data.remunerationAmount,
      });
    }

    return '-';
  }, [data, t]);

  const otherRemuneration = useMemo(
    () =>
      data?.otherPaymentTypeCode
        ? t(
            `labels.otherCompensations.${data?.otherPaymentTypeCode?.toLowerCase()}`,
            {
              amount: data?.otherPaymentAmount,
            }
          )
        : null,
    [data, t]
  );

  const expectations = useMemo(
    () => [
      {
        label: 'jobType',
        value: data?.jobType
          ? t(`enum.jobDurationCode.${data?.jobType.toLowerCase()}`)
          : '-',
      },
      {
        label: 'hoursPerWeek',
        value: data?.hoursPerWeek
          ? data.hoursPerWeek.map((h) => `${h}h`).join(', ')
          : '-',
      },
      {
        label: 'desiredSchedule',
        value:
          data?.schedules && data?.schedules.length
            ? data?.schedules
                .map((s) => t(`enum.jobScheduleTypeCode.${s.toLowerCase()}`))
                .join(', ')
            : '-',
      },
      {
        label: 'remoteWork',
        value: data?.jobModeCode
          ? t(`enum.jobModeCode.${data?.jobModeCode.toLowerCase()}`)
          : '-',
      },
      {
        label: 'remunerationType',
        value: remuneration || '-',
      },
      {
        label: 'otherCompensation',
        value: otherRemuneration || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, t]
  );

  return (
    <>
      <SummaryHeader
        className="mt-s-16"
        headerTitle={t('subtitles.expectations')}
      />

      <section className="grid grid-cols-[1fr] border rounded-b-md">
        {expectations.map((exp, index) => (
          <SectionGridRow key={exp.label} isTopRow={index === 0}>
            {`${t(`labels.${exp.label}`).replace(':', '')}:`}
            &nbsp;
            <span className="text-18 font-semibold text-primary">
              {exp.value}
            </span>
          </SectionGridRow>
        ))}
      </section>
    </>
  );
};

export default Expectations;
