import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import OperationUnitFragment from './operation.unit.fragment';

const OperationUnits = gql`
  ${OperationUnitFragment}
  query OperationUnits(
    $filterBy: OperationUnitsFilters,
    $sortBy: [OperationUnitsSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    operationUnits(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['OperationUnitFragment'])}
  }
`;

export default OperationUnits;
