import { useQuery } from 'urql';

import { Query, QueryRequestArgs } from '@domain/graphql.types';
import Request from '@infrastructure/graphql/request/get.query';

const useRequest = (input: QueryRequestArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'request'>,
    QueryRequestArgs
  >({
    query: Request,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data: data?.request,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useRequest;
