import { gql } from 'urql';

const RecruitmentOpportunityHeadhunterConditionsFragment = gql`
  fragment RecruitmentOpportunityHeadhunterConditionsFragment on RequestHeadhunterConditions {
    maximumReplacementValue
    maximumReplacementDisplay
    headhuntingExclusivityPeriodValue
    headhuntingExclusivityPeriodDisplay
  }
`;

export default RecruitmentOpportunityHeadhunterConditionsFragment;
