import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryList } from '@application/components/summary';
import SummaryBlock, {
  SummaryBlockProps,
} from '@application/components/summary/SummaryBlock';
import { SummaryListProps } from '@application/components/summary/SummaryList';
import { Request, RequestTypeCode } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';

type GeneralInformationProps = {
  data: Request | undefined;
};

const GeneralInformation = ({ data }: GeneralInformationProps) => {
  const { t, i18n } = useTranslation('requests');

  const summaryBlockData: Omit<SummaryBlockProps, 'children'> = useMemo(
    () => ({
      headerTitle: t('subtitles.description'),
      title: getLocalizedDescription(
        data?.jobSpecialty?.descriptions,
        i18n.language
      ),
      subtitle: data?.specialty || undefined,
      requestTypesBadge: data?.types?.length
        ? (data.types as RequestTypeCode[])
        : undefined,
    }),
    [data, t, i18n.language]
  );

  const summaryListJobInternalTitleData: SummaryListProps = useMemo(
    () => ({
      data: [
        {
          text: t('labels.jobInternalTitle'),
          description: data?.jobInternalTitle || '-',
        },
      ],
    }),
    [data, t]
  );

  function formatExperienceLevel(
    min: number | undefined | null,
    max: number | undefined | null,
    suffix: string
  ): string {
    if (min != null && max != null) {
      return `${min}-${max} ${suffix}`;
    }
    if (min != null) {
      return `${min} ${suffix}`;
    }
    if (max != null) {
      return `${max} ${suffix}`;
    }
    return '-';
  }

  const summaryListJobData: SummaryListProps = useMemo(
    () => ({
      data: [
        {
          text: t('labels.industry'),
          description:
            getLocalizedDescription(
              data?.account.industryType?.descriptions,
              i18n.language
            ) || '-',
        },
        {
          text: t('labels.activitySector'),
          description:
            getLocalizedDescription(
              data?.account?.sector?.descriptions,
              i18n.language
            ) || '-',
        },
        {
          text: t('labels.deadline'),
          description: data?.receivingOfferDeadline
            ? formatDate(data.receivingOfferDeadline, i18n.language)
            : '-',
        },
        {
          text: t('labels.startDate'),
          description: data?.desiredStartDate
            ? formatDate(data.desiredStartDate, i18n.language)
            : '-',
        },
        {
          text: t('labels.location'),
          description: data?.operationUnit?.name || '-',
        },
        {
          text: t('labels.sectorCode'),
          description:
            getLocalizedDescription(
              data?.sector?.descriptions,
              i18n.language
            ) || '-',
        },
        {
          text: t('labels.operationTerritory'),
          badges:
            data?.operationTerritories?.map(
              (territory) =>
                getLocalizedDescription(
                  territory.descriptions,
                  i18n.language
                ) || '-'
            ) || [],
        },
        {
          text: t('labels.citiesSubRegions'),
          badges: data?.citiesSubRegions?.map((subRegion) => subRegion) || [],
        },
        {
          text: t('labels.desiredEducationalLevels'),
          badges:
            data?.desiredEducationLevels?.map(
              (educationLevel) =>
                getLocalizedDescription(
                  educationLevel.descriptions,
                  i18n.language
                ) || '-'
            ) || [],
        },
        {
          text: t('labels.desiredStudyField'),
          description: data?.desiredStudyField || '-',
        },
        {
          text: t('labels.experience'),
          description: formatExperienceLevel(
            data?.jobExperienceLevelMin,
            data?.jobExperienceLevelMax,
            t('suffix.years')
          ),
        },
        {
          text: t('labels.positionsToFill'),
          description: data?.jobOpenPositions?.toString() || '-',
        },
      ],
    }),
    [data, t, i18n.language]
  );

  return (
    <SummaryBlock {...summaryBlockData}>
      <SummaryList density="compact" {...summaryListJobInternalTitleData} />

      <hr className="my-s-24" />

      <SummaryList density="comfortable" {...summaryListJobData} />
    </SummaryBlock>
  );
};

export default GeneralInformation;
