import { useTranslation } from 'react-i18next';

import { CandidateConditionBillingPeriod } from '@domain/graphql.types';

const useCandidateBillingPeriodOptions = () => {
  const { t } = useTranslation();

  return Object.values(CandidateConditionBillingPeriod).map((code) => ({
    value: code,
    label: t(`enum.candidateConditionBillingPeriod.${code.toLowerCase()}`),
  }));
};

export default useCandidateBillingPeriodOptions;
