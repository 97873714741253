import { FC } from 'react';

import { cn } from '@utils/lib-utils';

type SummaryHeaderProps = {
  headerTitle: string;
  className?: string;
};

const SummaryHeader: FC<SummaryHeaderProps> = ({ className, headerTitle }) => (
  <div
    className={cn(
      'p-s-16 border border-b-transparent rounded-t-md bg-secondary',
      className
    )}
  >
    <span className="text-18 font-semibold">{headerTitle}</span>
  </div>
);

export default SummaryHeader;
