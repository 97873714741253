import { gql } from '@urql/core';

const MutationAccountPreferredSpecialtiesCreate = gql`
  mutation AccountPreferredSpecialtiesCreate(
    $input: AccountPreferredSpecialtiesCreateInput!
  ) {
    accountPreferredSpecialtiesCreate(input: $input) {
      accountPreferredSpecialties {
        id
      }
    }
  }
`;

export default MutationAccountPreferredSpecialtiesCreate;
