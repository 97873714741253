// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { RecruitmentNegotiationRoom } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TextInput } from '../../../../../../components/form/text-input-field/textInput';
import { RecruitmentNegotiationRoomFormFields } from '../../../room/schema';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../../constants';
import { getCellStyleForState } from '../../utils';

type InternationalRecruitmentConditionsProps = {
  modificationEnabled: boolean;
  onSubmit: () => Promise<void>;
  room: RecruitmentNegotiationRoom;
};

const exclusivityKey: keyof RecruitmentNegotiationRoomFormFields =
  'internationalRecruitmentConditionExclusivityPeriod';
const replacementKey: keyof RecruitmentNegotiationRoomFormFields =
  'internationalRecruitmentConditionMaximumReplacement';

const InternationalRecruitmentConditions = ({
  modificationEnabled,
  room,
  onSubmit,
}: InternationalRecruitmentConditionsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const { control, setValue, watch } =
    useFormContext<RecruitmentNegotiationRoomFormFields>();

  const fieldInModification = watch('fieldInModification');

  return (
    <>
      <tr>
        <th className={cn(TH_ROW_CLASS_NAME.base, TH_ROW_CLASS_NAME.highlight)}>
          {t('internationalRecruitmentConditions')}
        </th>

        <td
          className={cn(TH_ROW_CLASS_NAME.highlight, 'border-l-1 border-b-1')}
        >
          &nbsp;
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('exclusivityPeriod')} lineClamp={2}>
            <div>{t('exclusivityPeriod')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === exclusivityKey,
          })}
        >
          {fieldInModification === exclusivityKey ? (
            <Controller
              name="internationalRecruitmentConditionExclusivityPeriod"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.internationalRecruitmentPropertiesState
                      .exclusivityPeriod
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer
                      .internationalRecruitmentContractualConditions
                      ?.exclusivityPeriod
                  }
                >
                  <span>
                    {tGlobal('suffix.week', {
                      count: room.negotiatingOffer
                        .internationalRecruitmentContractualConditions
                        ?.exclusivityPeriod as number,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() => setValue('fieldInModification', exclusivityKey)}
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>

      <tr>
        <th scope="col" className={TH_ROW_CLASS_NAME.base}>
          <TooltipEllipsis toolTip={t('maximumReplacement')} lineClamp={2}>
            <div>{t('maximumReplacement')}</div>
          </TooltipEllipsis>
        </th>

        <td
          className={cn(TD_CLASS_NAME.base, 'group', {
            'p-s-0': fieldInModification === replacementKey,
          })}
        >
          {fieldInModification === replacementKey ? (
            <Controller
              name="internationalRecruitmentConditionMaximumReplacement"
              control={control}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextInput
                  className="rounded-none border-2 border-black h-full"
                  name={name}
                  id={name}
                  type="number"
                  minValue={1}
                  invalid={!!error}
                  value={value || ''}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              )}
            />
          ) : (
            <div className="flex items-center justify-between h-full">
              <div
                className={cn(
                  'flex items-center rounded-md h-full w-fit px-s-8',
                  getCellStyleForState(
                    room.internationalRecruitmentPropertiesState
                      .maximumReplacement
                  )
                )}
              >
                <PlaceholderSwitcher
                  value={
                    room.negotiatingOffer
                      .internationalRecruitmentContractualConditions
                      ?.maximumReplacement
                  }
                >
                  <span>
                    {
                      room.negotiatingOffer
                        .internationalRecruitmentContractualConditions
                        ?.maximumReplacement
                    }
                  </span>
                </PlaceholderSwitcher>
              </div>

              <Button
                className={cn('hidden font-normal px-s-8 min-w-fit', {
                  'group-hover:block': modificationEnabled,
                })}
                icon={<i className="ri-pencil-line mr-s-8" />}
                size="x-small"
                onClick={() => setValue('fieldInModification', replacementKey)}
              >
                {t('modify')}
              </Button>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default InternationalRecruitmentConditions;
