import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const EducationLevels = gql`
  ${DescriptionsFragment}

  query EducationLevels {
    educationLevels {
      values {
        descriptions {
          ...DescriptionsFragment
        }
        code
      }
    }
  }
`;

export default EducationLevels;
