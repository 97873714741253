import { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Switch } from '@application/components';
import {
  CandidateNegotiationRoom,
  CandidateNegotiationRoomStatus,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { CandidateNegotiationContext } from '../../context';
import { TH_COL_CLASS_NAME } from '../constants';

type TableHeaderProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const TableHeader = ({ onChange }: TableHeaderProps) => {
  const { t } = useTranslation('candidates');

  const { rooms, checkedRooms, isComparing } = useContext(
    CandidateNegotiationContext
  );

  return (
    <thead>
      <tr>
        <th
          className={cn(
            'w-s-192 min-w-[12rem] max-w-[12rem]',
            TH_COL_CLASS_NAME
          )}
        >
          <span className="sr-only">{t('labels.enterpriseName')}</span>
        </th>

        {rooms?.map(
          (
            {
              id,
              status,
              negotiatingCandidateOffer: { account },
            }: CandidateNegotiationRoom,
            index
          ) => (
            <th
              key={id}
              className={cn(
                TH_COL_CLASS_NAME,
                'w-s-192 min-w-[12rem] max-w-[12rem]',
                {
                  '!border-x-2 border-t-2 border-t-main border-x-main':
                    checkedRooms.includes(id),
                  '[&:last-child]:rounded-tr-md':
                    checkedRooms.includes(id) && index === rooms.length - 1,
                }
              )}
            >
              <Switch
                fallback={
                  <Checkbox
                    id={id}
                    checked={checkedRooms.includes(id)}
                    onChange={onChange}
                    disabled={isComparing}
                    labelDirection="bottom"
                    label={account.name}
                    name={account.name}
                    value={String(checkedRooms.includes(id))}
                    className="p-s-0"
                    labelTextClassName="font-semibold"
                  />
                }
              >
                <Switch.Match
                  when={status === CandidateNegotiationRoomStatus.Rejected}
                >
                  <div className="flex flex-col gap-s-10">
                    <i className="text-24 leading-initial font-normal text-error-content ri-close-circle-line" />
                    <span className="label-text normal-case text-16 font-semibold">
                      {account.name}
                    </span>
                  </div>
                </Switch.Match>

                <Switch.Match
                  when={status === CandidateNegotiationRoomStatus.Accepted}
                >
                  <div className="flex flex-col gap-s-10">
                    <i className="text-24 leading-initial font-normal text-success-content ri-shake-hands-line" />
                    <span className="label-text normal-case text-16 font-semibold">
                      {account.name}
                    </span>
                  </div>
                </Switch.Match>
              </Switch>
            </th>
          )
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;
