export default class ViewModel<T> {
  public readonly isLoading: boolean;

  public readonly errorCodes: ReadonlyArray<{ code: string; message: string }>;

  public readonly data: T;

  constructor({
    isLoading,
    errorCodes,
    data,
  }: {
    isLoading: boolean;
    errorCodes?: ReadonlyArray<{ code: string; message: string }>;
    data: T;
  }) {
    this.isLoading = isLoading;
    this.errorCodes = errorCodes ?? [];
    this.data = data;
  }

  get hasError() {
    return this.errorCodes.length > 0;
  }
}
