import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateRequestDuplicateArgs,
} from '@domain/graphql.types';
import { MutationCandidateRequestDuplicate } from '@infrastructure/graphql/candidate';

const useCandidateRequestDuplicate = () => {
  const [{ data, fetching, error }, duplicateCandidateRequest] = useMutation<
    Pick<Mutation, 'candidateRequestDuplicate'>,
    MutationCandidateRequestDuplicateArgs
  >(MutationCandidateRequestDuplicate);

  return {
    duplicateCandidateRequest,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestDuplicate;
