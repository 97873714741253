import { useJobPaymentFrequenciesGet } from '@domain/job';

import { toViewModel } from '../view-models/get-job-payment-frequencies.vm';

const useGetJobPaymentFrequencies = () => {
  const { data = {}, fetching, error } = useJobPaymentFrequenciesGet();

  return toViewModel({
    jobPaymentFrequencies: {
      data,
      fetching,
      error,
    },
  });
};

export default useGetJobPaymentFrequencies;
