import { gql } from '@urql/core';

const MutationOffersReject = gql`
  mutation OffersReject($input: RejectOffersInput!) {
    offersReject(input: $input) {
      offers {
        id
      }
    }
  }
`;

export default MutationOffersReject;
