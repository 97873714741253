import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useUserSendPasswordChangeRequest } from '@domain/user';

import { toViewModel } from '../view-models/send-password-change-request.vm';

const useSendPasswordChangeRequest = () => {
  const {
    data,
    fetching,
    error,
    userSendPasswordChangeRequest: sendPasswordChangeRequest,
  } = useUserSendPasswordChangeRequest();

  const { t } = useTranslation('user');

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    sendPasswordChangeRequest: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (data?.ok) {
      setAlertContent({
        text: t('messages.passwordChangeRequestSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ok, setAlertContent]);

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('messages.passwordChangeRequestError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length, setAlertContent]);

  return {
    viewModel,
    sendPasswordChangeRequest,
  };
};

export default useSendPasswordChangeRequest;
