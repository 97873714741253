import { useContext, useMemo } from 'react';

import { AccountContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { AccountTypeCode } from '@domain/graphql.types';

import NavButton from './NavButton';
import NavLink from './NavLink';

type TopLinksProps = {
  isNavOpen: boolean;
};

const TopLinks = ({ isNavOpen }: TopLinksProps) => {
  const { account } = useContext(AccountContext);

  const negoRoomLinks = useMemo(() => {
    const links = [];

    if (account?.type === AccountTypeCode.Enterprise) {
      links.push({
        label: 'hrNeeds',
        url: RootPrivatePage.RECRUITMENT_NEGOTIATIONS,
      });
      if (import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION === 'true') {
        links.push({
          label: 'availableCandidates',
          url: RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS,
        });
      }
    } else {
      links.push({
        label: 'hrNeeds',
        url: RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS,
      });
      if (import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION === 'true') {
        links.push({
          label: 'availableCandidates',
          url: RootPrivatePage.CANDIDATE_NEGOTIATIONS,
        });
      }
    }

    return links;
  }, [account?.type]);

  const NAV_LINKS = [
    {
      label: 'dashboard',
      icon: 'ri-dashboard-line',
      url: RootPrivatePage.DASHBOARD,
    },
    {
      label: 'negotiations',
      icon: 'ri-shake-hands-line',
      options: negoRoomLinks,
    },
    {
      label: 'requests',
      icon: 'ri-suitcase-line',
      options: [
        account?.type === AccountTypeCode.Enterprise
          ? {
              label: 'hrNeeds',
              url: RootPrivatePage.REQUESTS,
            }
          : {
              label: 'availableCandidates',
              url: RootPrivatePage.CANDIDATE_REQUESTS,
            },
      ],
    },
    {
      label: 'opportunities',
      icon: 'ri-alarm-warning-line',
      options: [
        account?.type === AccountTypeCode.Enterprise
          ? {
              label: 'availableCandidates',
              url: RootPrivatePage.CANDIDATE_OPPORTUNITIES,
            }
          : {
              label: 'hrNeeds',
              url: RootPrivatePage.RECRUITMENT_OPPORTUNITIES,
            },
      ],
    },
    {
      label: 'offersManagement',
      icon: 'ri-money-dollar-circle-line',
      url:
        account?.type === AccountTypeCode.Enterprise
          ? RootPrivatePage.CANDIDATE_OFFERS
          : RootPrivatePage.OFFERS,
    },
  ];

  return (
    <ul className="mx-0">
      {NAV_LINKS.map((link) =>
        link.url ? (
          <NavLink
            key={link.label}
            label={link.label}
            isNavOpen={isNavOpen}
            icon={link.icon}
            url={link.url}
          />
        ) : (
          <NavButton
            key={link.label}
            label={link.label}
            isNavOpen={isNavOpen}
            icon={link.icon}
            options={link.options}
          />
        )
      )}
    </ul>
  );
};

export default TopLinks;
