import { gql } from 'urql';

const CandidateNegotiationRecruitmentPropertiesStateFragment = gql`
  fragment CandidateNegotiationRecruitmentPropertiesStateFragment on CandidateNegotiationRecruitmentPropertiesState {
    budget
    bonus
    guaranteedPeriod
    paymentMethod
  }
`;

export default CandidateNegotiationRecruitmentPropertiesStateFragment;
