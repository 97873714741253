interface SummaryDescriptionProps {
  description?: string;
}

const SummaryDescription = ({ description }: SummaryDescriptionProps) => (
  <span className="text-18 font-semibold text-primary">
    {description || '-'}
  </span>
);

export default SummaryDescription;
