import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import { Link, RankBadge } from '@application/components';
import { PrivatePage } from '@application/enums/pagesUrl';
import {
  CandidateNegotiationRoom,
  CandidateRequestType,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { CandidateNegotiationContext } from '../../context';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../constants';
import { EmployerBrand, OfferToRecruiter } from './rows';

const TableBody = () => {
  const { t } = useTranslation('candidates');

  const { rooms, checkedRooms } = useContext(CandidateNegotiationContext);

  return (
    <tbody>
      <tr>
        <th className={TH_ROW_CLASS_NAME.base}>{t('labels.rank')}</th>
        {rooms?.map(
          ({
            id,
            negotiatingCandidateOffer: { rank },
          }: CandidateNegotiationRoom) => (
            <td
              aria-label={t('labels.rank')}
              key={`rank-${id}`}
              className={cn(TD_CLASS_NAME.base, {
                'border-x-2 !border-x-main': checkedRooms.includes(id),
              })}
            >
              {rank ? <RankBadge rank={rank} /> : undefined}
            </td>
          )
        )}
      </tr>

      <tr>
        <th className={TH_ROW_CLASS_NAME.base}>
          {t('labels.organizationProfile')}
        </th>
        {rooms?.map(
          ({ id, candidateOffer: { accountId } }: CandidateNegotiationRoom) => (
            <td
              aria-label={t('labels.organizationProfile')}
              key={`organizationProfile-${id}`}
              className={cn(TD_CLASS_NAME.base, {
                'border-x-2 !border-x-main': checkedRooms.includes(id),
              })}
            >
              <Link
                className="text-16"
                to={generatePath(PrivatePage.ORGANIZATION_PUBLIC_PROFILE, {
                  id: accountId,
                })}
              >
                {t('button.viewProfile')}
              </Link>
            </td>
          )
        )}
      </tr>

      {/* This is a temporary mapping */}
      {rooms?.map((room, index) => (
        <Fragment key={index}>
          <EmployerBrand
            room={room}
            type={room.candidateRequest.type as CandidateRequestType}
            checked={checkedRooms.includes(room.id)}
          />
          <OfferToRecruiter
            room={room}
            type={room.candidateRequest.type as CandidateRequestType}
            checked={checkedRooms.includes(room.id)}
            rounded={
              checkedRooms.includes(room.id) && index === rooms.length - 1
            }
          />
        </Fragment>
      ))}
    </tbody>
  );
};

export default TableBody;
