import { ChangeEvent, useCallback, useState } from 'react';
import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  Fieldset,
  FormInputWithSuffix,
  Radio,
  SectionGrid,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { Button } from '@application/components/buttons';
import { Modal } from '@application/components/modal';
import {
  ConditionRemunerationType,
  RecruitmentNegotiationRoom,
} from '@domain/graphql.types';

import { normalizeRecruitmentNegotiationRoomEditData } from '../../../room/normalizers';
import { RecruitmentNegotiationRoomFormFields } from '../../../room/schema';

export type UpdateTotalCostsModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  methods: UseFormReturn;
  room: RecruitmentNegotiationRoom;
};

const UpdateTotalCostsModal = ({
  onClose,
  onSubmit,
  methods,
  room,
}: UpdateTotalCostsModalProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.totalCostsModal',
  });

  const {
    control,
    setValue,
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  // Workaround as watch does not work, maybe because we are in a modal.
  const [conditionRemunerationType, setConditionRemunerationType] = useState(
    getValues('conditionRemunerationType')
  );

  const typedErrors =
    errors as FieldErrors<RecruitmentNegotiationRoomFormFields>;

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof RecruitmentNegotiationRoomFormFields
  ) => {
    setValue(name, event.target.value as any);
    setConditionRemunerationType(event.target.value);
  };

  const handleOnClose = useCallback(() => {
    const initialValues = normalizeRecruitmentNegotiationRoomEditData(room);
    reset(initialValues);
    onClose();
  }, [onClose, reset, room]);

  return (
    <div className="max-w-[53rem]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <span className="text-neutral-secondary">
          {t('labels.description')}
        </span>
        <SectionGrid hideTitle gridColsClassName="grid-cols-[1fr_10rem]">
          <SectionGridRow
            isTopRow
            rightChildren={
              <VisibilitySwitcher
                content={
                  <div className="flex flex-col w-full h-full">
                    <span className="flex items-center justify-center rounded-tr-md h-s-64 text-14 bg-secondary">
                      {t('labels.budgetExpectations')}
                    </span>

                    <span className="flex text-16 items-center justify-center h-full font-semibold text-primary">
                      {t('fixedAmount', {
                        amount:
                          room.request.generalContractualConditions
                            .maximumBudgetValue,
                      })}
                    </span>
                  </div>
                }
                visible
              />
            }
          >
            <Fieldset
              legend={`${t('labels.remunerationType')}:`}
              legendSize="medium"
            >
              <Controller
                name="conditionRemunerationType"
                control={control}
                render={({ field: { name } }) => (
                  <Cluster space={16} as="ul" className="mt-s-16">
                    {Object.values(ConditionRemunerationType).map((type) => (
                      <li key={type}>
                        <Radio
                          name={name}
                          value={type}
                          label={tGlobal(
                            `enum.conditionRemunerationType.${type.toLowerCase()}`
                          )}
                          asButton
                          onChange={(e) =>
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            )
                          }
                          checked={conditionRemunerationType === type}
                        />
                      </li>
                    ))}
                  </Cluster>
                )}
              />

              {conditionRemunerationType ===
                ConditionRemunerationType.AnnualSalaryPercentage && (
                <FormInputWithSuffix
                  className="mt-s-16"
                  label={t('labels.remunerationPercentage')}
                  suffix={tGlobal('suffix.percentage')}
                  type="number"
                  mediumSuffix
                  noMargin
                  minValue={1}
                  invalid={!!typedErrors.conditionRemunerationPercentage}
                  helperText={
                    typedErrors.conditionRemunerationPercentage?.message &&
                    tGlobal(
                      typedErrors.conditionRemunerationPercentage?.message
                    )
                  }
                  {...register('conditionRemunerationPercentage')}
                />
              )}

              {conditionRemunerationType ===
                ConditionRemunerationType.FixedAmount && (
                <FormInputWithSuffix
                  className="mt-s-16"
                  label={t('labels.remunerationAmount')}
                  suffix={tGlobal('suffix.currency')}
                  type="number"
                  mediumSuffix
                  noMargin
                  minValue={1}
                  maxValue={99999999.99}
                  step={0.01}
                  invalid={!!typedErrors.conditionRemunerationAmount}
                  helperText={
                    typedErrors.conditionRemunerationAmount?.message &&
                    tGlobal(typedErrors.conditionRemunerationAmount?.message, {
                      max: '99,999,999.99',
                      min: '1',
                    })
                  }
                  {...register('conditionRemunerationAmount')}
                />
              )}

              {conditionRemunerationType ===
                ConditionRemunerationType.HourlyRate && (
                <div className="mt-s-16">
                  <FormInputWithSuffix
                    label={t('labels.remunerationHourlyRate')}
                    suffix={tGlobal('suffix.currencyPerHour')}
                    type="number"
                    mediumSuffix
                    noMargin
                    minValue={1}
                    maxValue={99999999.99}
                    step={0.01}
                    invalid={!!typedErrors.conditionRemunerationHourlyRate}
                    helperText={
                      typedErrors.conditionRemunerationHourlyRate?.message &&
                      tGlobal(
                        typedErrors.conditionRemunerationHourlyRate?.message,
                        {
                          max: '99,999,999.99',
                          min: '1',
                        }
                      )
                    }
                    {...register('conditionRemunerationHourlyRate')}
                  />

                  <FormInputWithSuffix
                    label={t('labels.remunerationEstimatedHours')}
                    suffix={tGlobal('suffix.hours')}
                    type="number"
                    className="min-w-[40rem]"
                    mediumSuffix
                    minValue={1}
                    invalid={!!typedErrors.conditionRemunerationEstimatedHours}
                    helperText={
                      typedErrors.conditionRemunerationEstimatedHours
                        ?.message &&
                      tGlobal(
                        typedErrors.conditionRemunerationEstimatedHours?.message
                      )
                    }
                    {...register('conditionRemunerationEstimatedHours')}
                  />
                </div>
              )}
            </Fieldset>
          </SectionGridRow>
        </SectionGrid>

        <Modal.Action>
          <Button onClick={handleOnClose}>{tGlobal('button.cancel')}</Button>

          <Button primary type="submit">
            {tGlobal('button.validate')}
          </Button>
        </Modal.Action>
      </form>
    </div>
  );
};

export default UpdateTotalCostsModal;
