import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountCreate } from '@domain/account';

import { TRANSLATION_PREFIX_KEYS } from '../constants';
import { toViewModel } from '../view-models/create-account.vm';

const useCreateAccount = (type: 'ENTERPRISE' | 'AGENCY') => {
  const { data, fetching, error, createAccount } = useAccountCreate();

  const { t } = useTranslation('signup', {
    keyPrefix: TRANSLATION_PREFIX_KEYS[type],
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    account: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('messages.createError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    viewModel,
    createAccount,
  };
};

export default useCreateAccount;
