import {
  CandidateNegotiation,
  CandidateNegotiationsFilters,
} from '@domain/graphql.types';

import useGetCandidateNegotiations from './useGetCandidateNegotiations';

type UseCandidateNegotiationsListProps = {
  count?: number;
  candidateNegotiations: CandidateNegotiation[];
  isLoading: boolean;
};

const useCandidateNegotiationsList = (
  filters?: CandidateNegotiationsFilters
): UseCandidateNegotiationsListProps => {
  const {
    viewModel: { data, isLoading },
  } = useGetCandidateNegotiations(filters);

  return {
    count: data?.candidateNegotiations.totalCount,
    candidateNegotiations: data?.candidateNegotiations.results.slice() || [],
    isLoading,
  };
};

export default useCandidateNegotiationsList;
