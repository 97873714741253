import { useValidateIdParam } from '@application/hooks';
import { useCandidateNegotiation } from '@domain/candidate';

import { toViewModel } from '../view-models/get-candidate-negotiation.vm';

const useGetCandidateNegotiation = (negotiationId: string) => {
  useValidateIdParam(negotiationId);

  const { data, fetching, error, reexecuteQuery } = useCandidateNegotiation({
    input: { negotiationId },
  });

  const viewModel = toViewModel({
    negotiation: {
      data: data?.candidateNegotiation,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refreshCandidateNegotiation: reexecuteQuery,
  };
};

export default useGetCandidateNegotiation;
