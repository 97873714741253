import { gql } from '@urql/core';

const MutationSaveAndPublishCandidateOffer = gql`
  mutation SaveAndPublish(
    $input: CandidateOfferUpdateInput!
    $statusInput: CandidateOfferUpdateStatusInput!
  ) {
    candidateOfferUpdate(input: $input) {
      candidateOffer {
        id
      }
    }
    candidateOfferUpdateStatus(input: $statusInput) {
      candidateOffer {
        id
      }
    }
  }
`;

export default MutationSaveAndPublishCandidateOffer;
