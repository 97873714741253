import { Cell, flexRender, Row } from '@tanstack/react-table';
import { ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

import NoContent from './NoContent';
import { RowHeight } from './types';

type TableBodyProps<T> = {
  rows: Row<T>[];
  noDataComponent?: ReactNode;
  columnsCount: number;
  rowHeight?: RowHeight;
  isRowColorChangeOnHover?: boolean;
};

const TableBody = <T,>({
  rows,
  noDataComponent = <NoContent />,
  columnsCount,
  rowHeight = 'medium',
  isRowColorChangeOnHover = true,
}: TableBodyProps<T>) => {
  const tdClassName = cn('px-s-12 text-14', {
    'h-s-56': rowHeight === 'medium',
    'h-s-72': rowHeight === 'tall',
  });

  const trClassName = cn('bg-base-100', {
    'hover:bg-neutral': isRowColorChangeOnHover,
  });

  return (
    <tbody>
      {rows.map((row: Row<T>) => (
        <tr key={row.id} className={trClassName}>
          {row.getVisibleCells().map((cell: Cell<T, any>) => (
            <td key={cell.id} className={tdClassName}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}

      {rows.length === 0 && (
        <tr className="bg-base-100">
          <td colSpan={columnsCount}>{noDataComponent}</td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
