import {
  KeyboardEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClassNamesConfig,
  ControlProps,
  GroupBase,
  MultiValue as MultiValueType,
  SelectComponentsConfig,
} from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';

import { cn } from '@utils/lib-utils';

import {
  ClearIndicator,
  MultiValue,
  MultiValueRemove,
  Placeholder,
} from './components';
import {
  CLEAR_INDICATOR_STYLES,
  CONTROL_STYLES,
  DROPDOWN_INDICATOR_STYLES,
  MULTI_VALUE_LABEL_STYLES,
  MULTI_VALUE_REMOVE_STYLES,
  MULTI_VALUE_STYLES,
  PLACEHOLDER_STYLES,
  SELECT_INPUT_STYLES,
  SINGLE_VALUE_STYLES,
  VALUE_CONTAINER_STYLES,
} from './constants';
import { OptionType, SelectProps } from './types';

const CreatableSelect = ({
  options,
  id,
  name,
  value,
  defaultValue,
  placeholder,
  clearable = true,
  disabled,
  autoFocus,
  invalid,
  className: customClassName,
  controlClassName: customControlClassName,
  formatOptionLabel,
  validateOnKeyDown,
  onInputChange,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  ...rest
}: SelectProps) => {
  const [inputValue, setInputValue] = useState('');
  const [newValue, setNewValue] = useState<MultiValueType<OptionType>>([
    ...(value as MultiValueType<OptionType>),
  ]);

  const { t } = useTranslation();

  const defaultPlaceholder = t(
    'select.placeholders.writeAndPressKeyToValidate',
    { key: t('keys.enter') }
  );

  const components: SelectComponentsConfig<
    OptionType,
    true,
    GroupBase<OptionType>
  > = {
    Placeholder,
    MultiValue: MultiValue(),
    MultiValueRemove,
    ClearIndicator: clearable ? ClearIndicator : undefined,
    DropdownIndicator: null,
    Menu: () => null,
  };

  const classNames: ClassNamesConfig<
    OptionType,
    true,
    GroupBase<OptionType>
  > = {
    container: () => cn(customClassName),
    control: ({
      isFocused,
      isDisabled,
    }: ControlProps<OptionType, boolean, GroupBase<OptionType>>) =>
      cn(
        CONTROL_STYLES.base,
        CONTROL_STYLES.multiple,
        isFocused ? CONTROL_STYLES.focus : CONTROL_STYLES.nonFocus,
        invalid && CONTROL_STYLES.invalid,
        isDisabled && CONTROL_STYLES.disabled,
        customControlClassName
      ),
    input: () => SELECT_INPUT_STYLES,
    placeholder: () => PLACEHOLDER_STYLES,
    valueContainer: () => VALUE_CONTAINER_STYLES,
    singleValue: () => SINGLE_VALUE_STYLES,
    multiValue: () => MULTI_VALUE_STYLES,
    multiValueLabel: () => MULTI_VALUE_LABEL_STYLES,
    multiValueRemove: () => MULTI_VALUE_REMOVE_STYLES,
    dropdownIndicator: () => DROPDOWN_INDICATOR_STYLES,
    clearIndicator: () => CLEAR_INDICATOR_STYLES,
  };

  const createOption = (label: string) =>
    ({
      value: label,
      label,
    }) as OptionType;

  /**
   * NOTE: We need to set new value here on change otherwise
   * we can't remove entered values -_-
   */
  const handleChange = (newOptions: MultiValueType<OptionType>) =>
    setNewValue(newOptions);

  const handleInputChange = (val: string) => setInputValue(val);

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (!inputValue) return;

      switch (event.key) {
        case 'Enter':
          // We can apply any validation passed as a props here on new entered value
          // before creating the label (i.e. filter by specific types)
          if (validateOnKeyDown && !validateOnKeyDown(inputValue)) {
            event.preventDefault();
            return;
          }

          setNewValue((prev) => [...prev, createOption(inputValue)]);
          setInputValue('');

          event.preventDefault();
          break;
        default:
      }

      if (onKeyDown) {
        onKeyDown(event);
      }
    },
    [inputValue, onKeyDown, validateOnKeyDown]
  );

  useEffect(() => {
    if (onChange) {
      onChange(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue]);

  useEffect(() => {
    if (onInputChange) {
      onInputChange(inputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <ReactCreatableSelect
      isMulti
      inputId={id}
      autoFocus={autoFocus}
      components={components}
      classNames={classNames}
      name={name}
      value={value}
      inputValue={inputValue}
      options={options}
      isClearable={clearable}
      menuIsOpen={false}
      placeholder={placeholder || defaultPlaceholder}
      formatOptionLabel={formatOptionLabel}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      {...rest}
    />
  );
};

export default CreatableSelect;
