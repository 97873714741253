import { gql } from 'urql';

const CandidateNegotiationGeneralPropertiesStateFragment = gql`
  fragment CandidateNegotiationGeneralPropertiesStateFragment on CandidateNegotiationGeneralPropertiesState {
    salary
    hours
    duration
    otherRemunerations
    operationTerritory
    jobType
    jobMode
  }
`;

export default CandidateNegotiationGeneralPropertiesStateFragment;
