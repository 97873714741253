import { gql } from '@urql/core';

const MutationRequestDelete = gql`
  mutation RequestDelete($deleteRequestInput: DeleteRequestInput!) {
    requestDelete(deleteRequestInput: $deleteRequestInput) {
      ok
    }
  }
`;

export default MutationRequestDelete;
