import { useCandidateOffers } from '@domain/candidate';
import {
  CandidateOffersFilters,
  CandidateOffersSortDirective,
} from '@domain/graphql.types';
import { Pagination } from '@domain/pagination/pagination';

import { toViewModel } from '../view-models/get-candidate-offers.vm';

const useGetCandidateOffers = (
  sortBy: CandidateOffersSortDirective[],
  filterBy?: CandidateOffersFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } = useCandidateOffers({
    sortBy,
    filterBy,
    ...pagination,
  });

  const viewModel = toViewModel({
    candidateOffers: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateOffers: reexecuteQuery,
  };
};

export default useGetCandidateOffers;
