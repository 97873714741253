import { useContext } from 'react';

import { AccountContext } from '@application/context';
import { AccountBillingSubscriptionType } from '@domain/graphql.types';

const useIsViewOnlySubscription = () => {
  const { account } = useContext(AccountContext);

  return (
    account?.subscriptionType === AccountBillingSubscriptionType.AgencyViewOnly
  );
};

export default useIsViewOnlySubscription;
