import { useMutation } from 'urql';

import { MutationSaveAndPublishCandidateOffer } from '@infrastructure/graphql/candidate/offer';

const useCandidateOfferSaveAndPublish = () => {
  const [{ data, fetching, error }, saveAndPublish] = useMutation(
    MutationSaveAndPublishCandidateOffer
  );

  return {
    saveAndPublish,
    data,
    fetching,
    error,
  };
};

export default useCandidateOfferSaveAndPublish;
