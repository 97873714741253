import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SectionContainer, TextInputField } from '@application/components';
import { Button } from '@application/components/buttons';
import { UserContext } from '@application/context';

import { useUpdateEmail } from '../hooks';
import { UPDATE_EMAIL_SCHEMA } from '../schema';
import { UpdateEmailFormFields } from '../types';

const UpdateEmail = () => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('user');

  const { user, updateUserEmail } = useContext(UserContext);

  const {
    viewModel: { isLoading },
    updateEmail,
  } = useUpdateEmail();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateEmailFormFields>({
    defaultValues: {
      email: user?.email,
    },
    mode: 'onBlur',
    resolver: yupResolver(UPDATE_EMAIL_SCHEMA),
  });

  const onSubmit: SubmitHandler<UpdateEmailFormFields> = useCallback(
    async ({ email }) => {
      if (!user?.id) {
        return;
      }

      const result = await updateEmail({
        input: {
          email: email || '',
          userId: user?.id,
        },
      });

      if (result.data?.userUpdateEmail.user) {
        updateUserEmail(result.data.userUpdateEmail.user.email);
      }
    },
    [updateEmail, updateUserEmail, user?.id]
  );

  return (
    <SectionContainer
      title={t('subtitles.updateEmail')}
      contentClassName="mt-s-16"
    >
      <form className="flex gap-s-24" onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          className="w-full"
          hideLabel
          label={t('labels.email')}
          invalid={!!errors.email}
          helperText={
            errors.email?.message &&
            tGlobal(errors.email?.message, {
              field: t('labels.email'),
            })
          }
          {...register('email')}
        />

        <Button primary type="submit" loading={isLoading}>
          {tGlobal('button.edit')}
        </Button>
      </form>
    </SectionContainer>
  );
};

export default UpdateEmail;
