import { useRecruitmentNegotiationRoom } from '@domain/negotiation';

import { toViewModel } from '../view-models/get-recruitment-negotiation-room.vm';

const useGetRecruitmentNegotiationRoom = (roomId: string) => {
  const { data, fetching, error, reexecuteQuery } =
    useRecruitmentNegotiationRoom({
      input: { roomId },
    });

  const viewModel = toViewModel({
    room: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRecruitmentRoom: reexecuteQuery,
  };
};

export default useGetRecruitmentNegotiationRoom;
