import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, PlaceholderSwitcher, Stack } from '@application/components';
import { UserContext } from '@application/context';
import { formatDate } from '@utils/date-utils';

const GeneralInformation = () => {
  const { t, i18n } = useTranslation('user');

  const { accountUser, user } = useContext(UserContext);

  return (
    <Box>
      <Stack as="ul" space={8} className="text-14">
        <li className="flex flex-row items-baseline">
          <i className="ri-map-pin-2-line text-20 mr-s-8" />
          <PlaceholderSwitcher value={user?.location}>
            {user?.location}
          </PlaceholderSwitcher>
        </li>

        {user?.phoneDisplay && (
          <li className="flex flex-row items-baseline">
            <i className="ri-phone-line text-20 mr-s-8" />
            <PlaceholderSwitcher value={user?.phoneNumber}>
              {user?.phoneNumber}
            </PlaceholderSwitcher>
          </li>
        )}

        <li className="flex flex-row items-baseline">
          <i className="ri-mail-line text-20 mr-s-8" />
          <PlaceholderSwitcher value={user?.email}>
            <Link
              to={`mailto:${user?.professionalEmail ?? user?.email}`}
              className="text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {user?.professionalEmail ?? user?.email}
            </Link>
          </PlaceholderSwitcher>
        </li>

        <li>
          <i className="ri-profile-line text-20 mr-s-8" />
          <span>
            {t('personalInformation.memberSince')}&nbsp;
            {formatDate(accountUser?.createdAt, i18n.language, 'month-year')}
          </span>
        </li>
      </Stack>
    </Box>
  );
};

export default GeneralInformation;
