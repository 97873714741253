import { gql } from '@urql/core';

import AccountUserFragment from '../account.user.fragment';

const AccountUserInvite = gql`
  ${AccountUserFragment}

  mutation AccountUserInvite($input: InviteAccountUserInput!) {
    accountUserInvite(input: $input) {
      accountUser {
        ...AccountUserFragment
      }
    }
  }
`;

export default AccountUserInvite;
