import { gql } from '@urql/core';

const UserGetNotificationPreferences = gql`
  query UserGetNotificationPreferences {
    me {
      user {
        notificationPreferences {
          email
          inApp
        }
      }
    }
  }
`;

export default UserGetNotificationPreferences;
