import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { UserSendPasswordChangeRequestResponse } from '@domain/graphql.types';

type ToViewModel = {
  sendPasswordChangeRequest: {
    data: UserSendPasswordChangeRequestResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  sendPasswordChangeRequest: { data, fetching, error },
}: ToViewModel): ViewModel<UserSendPasswordChangeRequestResponse | undefined> =>
  new ViewModel<UserSendPasswordChangeRequestResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
