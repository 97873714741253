import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import { Stack } from '../container-layouts';
import { Space } from '../container-layouts/types';
import AccordionItem, {
  AccordionItemProps,
} from './accordion-item/AccordionItem';

type AccordionProps = {
  /**
   * Specify the space between each `<AccordionItem>`
   */
  space?: Space;
  /**
   * Specify content elements of the `<Accordion>`
   */
  children: ReactNode;
  /**
   * Specify a custom `className` to apply to the `<Accordion>` container
   */
  className?: string;
};

/**
 *
 * @example
 * <Accordion space={24}>
 *   <AccordionItem summary='Hello? Who’s there?'>
 *     <p>Bouh 👻</p>
 *   </AccordionItem
 *
 *   <AccordionItem summary={<h2>Jerry? Is that you?</h2>}>
 *     <p>Raugh 🧟‍♂️</p>
 *   </AccordionItem>
 * </Accordion>
 */
const Accordion = ({
  space = 16,
  children,
  className: customClassName,
}: AccordionProps) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const items = React.useMemo(
    () =>
      React.Children.toArray(children).map((child) => {
        if (
          !React.isValidElement(child) ||
          !(child.props as AccordionItemProps).summary
        ) {
          throw new Error(
            'Accordion: child must be a valid AccordionItem element'
          );
        }
        return child;
      }),
    [children]
  );

  useEffect(() => {
    setExpandedItems(
      (state) =>
        [
          ...state,
          ...items
            .filter((current) => current.props.expanded)
            .map((current) => current.key),
        ] as string[]
    );
  }, [items]);

  const name = useMemo(() => `accordion-${randomString()}`, []);

  const className = cn({ 'join join-vertical': space === 0 }, customClassName);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setExpandedItems((state) => [...state, event.target.value]);
    } else {
      setExpandedItems((state) => [
        ...state.filter((p) => p !== event.target.value),
      ]);
    }
  };

  return (
    <Stack as="ul" space={space} className={className}>
      {items.map((item) => (
        <li key={item.key}>
          <AccordionItem
            {...item.props}
            name={name}
            value={item.key}
            expanded={expandedItems.includes(item.key as string)}
            onChange={handleChange}
            className={space === 0 && 'join-item'}
          />
        </li>
      ))}
    </Stack>
  );
};

export default Accordion;
