import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DisplayButton,
  FormInputWithSuffix,
  RangeSlider,
  SectionGrid,
  SectionGridRow,
  Tooltip,
} from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';
import { formattedNowDate } from '@utils/date-utils';

import { RequestFormFields } from '../../../schema';
import PaymentMethodsFields from './PaymentMethodsFields';

type MainFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const MainFields = ({
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: MainFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const conditionMinimumBudgetValue = watch('conditionMinimumBudgetValue');
  const conditionMaximumBudgetValue = watch('conditionMaximumBudgetValue');
  const conditionMinGuaranteedPeriodValue = watch(
    'conditionMinGuaranteedPeriodValue'
  );
  const conditionBonusMonthValue = watch('conditionBonusMonthValue');
  const conditionHiredDateBonusRequirement = watch(
    'conditionHiredDateBonusRequirement'
  );

  const conditionCandidateInHandMandatoryValue = watch(
    'conditionCandidateInHandMandatoryValue'
  );

  return (
    <SectionGrid title={t('subtitles.generalConditions')}>
      {!conditionCandidateInHandMandatoryValue && (
        <SectionGridRow
          isTopRow
          rightChildren={
            <DisplayButton
              name="conditionMaxRecruitmentPeriodDisplay"
              control={control}
              setValue={setValue}
              watch={watch}
            />
          }
        >
          <FormInputWithSuffix
            label={t('labels.duration')}
            suffix={t('suffix.weeks')}
            type="number"
            mediumSuffix
            noMargin
            minValue={1}
            invalid={!!errors.conditionMaxRecruitmentPeriodValue}
            helperText={
              errors.conditionMaxRecruitmentPeriodValue?.message &&
              tGlobal(errors.conditionMaxRecruitmentPeriodValue?.message)
            }
            {...register('conditionMaxRecruitmentPeriodValue')}
          />
        </SectionGridRow>
      )}

      <SectionGridRow
        isTopRow={!!conditionCandidateInHandMandatoryValue}
        rightChildren={
          <DisplayButton
            name="conditionProbationPeriodDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.trialPeriodLength')}
          suffix={t('suffix.weeks')}
          type="number"
          mediumSuffix
          noMargin
          minValue={1}
          invalid={!!errors.conditionProbationPeriodValue}
          helperText={
            errors.conditionProbationPeriodValue?.message &&
            tGlobal(errors.conditionProbationPeriodValue?.message)
          }
          {...register('conditionProbationPeriodValue')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="conditionPaymentMethodDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <Stack space={16}>
          <PaymentMethodsFields
            control={control}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
          />
        </Stack>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="conditionDepositDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <FormInputWithSuffix
          type="number"
          minValue={0}
          maxValue={99999999.99}
          step={0.01}
          invalid={!!errors.conditionDepositValue}
          noMargin
          helperText={
            errors.conditionDepositValue?.message &&
            tGlobal(errors.conditionDepositValue?.message, {
              max: '99,999,999.99',
              min: '1',
            })
          }
          label={t('labels.depositPayment')}
          suffix={t('suffix.currency')}
          {...register('conditionDepositValue')}
        />
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <Tooltip message={t('labels.nonModifiableVisibilityTooltips')}>
            <div>
              <DisplayButton
                name="conditionBudgetDisplay"
                control={control}
                setValue={setValue}
                watch={watch}
                disabled
              />
            </div>
          </Tooltip>
        }
      >
        <Cluster className="w-full">
          <div className="flex items-center mr-s-8">
            <Tooltip message={t('labels.baselineBudgetTooltips')}>
              <i className="ri-error-warning-line mr-s-8 text-20 leading-none text-neutral-secondary" />
            </Tooltip>
            {t('labels.baselineBudget')}
          </div>
          <RangeSlider
            minLabel={t('labels.minBudgetInput')}
            maxLabel={t('labels.maxBudgetInput')}
            minName="conditionMinimumBudgetValue"
            maxName="conditionMaximumBudgetValue"
            onChange={(name: string, value: number | string) => {
              setValue(name as keyof RequestFormFields, value, {
                shouldDirty: true,
              });
              trigger(name as keyof RequestFormFields);
            }}
            minValue={conditionMinimumBudgetValue || undefined}
            maxValue={conditionMaximumBudgetValue || undefined}
            min={3500}
            max={100000}
            suffix="$"
            className="w-full"
            helperText={
              (errors.conditionMinimumBudgetValue?.message &&
                tGlobal(errors.conditionMinimumBudgetValue?.message)) ||
              (errors.conditionMaximumBudgetValue?.message &&
                tGlobal(errors.conditionMaximumBudgetValue?.message))
            }
          />
        </Cluster>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <DisplayButton
            name="conditionMinGuaranteedPeriodDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <Stack>
          <div className="flex items-center mr-s-8">
            <Tooltip message={t('labels.additionalBonusTooltips')}>
              <i className="ri-information-line mr-s-8 text-20 leading-none text-neutral-secondary" />
            </Tooltip>
            {t('labels.additionalBonusOffered')}
          </div>
          <FormInputWithSuffix
            label={t('labels.minGuaranteedPeriod')}
            suffix={t('suffix.months')}
            type="number"
            mediumSuffix
            noMargin
            minValue={1}
            invalid={!!errors.conditionMinGuaranteedPeriodValue}
            helperText={
              errors.conditionMinGuaranteedPeriodValue?.message &&
              tGlobal(errors.conditionMinGuaranteedPeriodValue?.message, {
                min: '1',
              })
            }
            {...register('conditionMinGuaranteedPeriodValue')}
          />
          {conditionMinGuaranteedPeriodValue && (
            <FormInputWithSuffix
              label={t('labels.minGuaranteedPeriodBonusValue')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.conditionMinGuaranteedPeriodBonusValue}
              helperText={
                errors.conditionMinGuaranteedPeriodBonusValue?.message &&
                tGlobal(
                  errors.conditionMinGuaranteedPeriodBonusValue?.message,
                  {
                    max: '99,999,999.99',
                    min: '1',
                  }
                )
              }
              {...register('conditionMinGuaranteedPeriodBonusValue')}
            />
          )}
        </Stack>
      </SectionGridRow>

      <SectionGridRow
        partialBorderTop
        rightChildren={
          <DisplayButton
            name="conditionBonusDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <Stack>
          <FormInputWithSuffix
            inputClassName="w-[80px]"
            label={t('labels.ifCandidate')}
            suffix={`${t('suffix.months')} ${t('labels.stayAfterHired')}`}
            type="number"
            mediumSuffix
            noMargin
            minValue={1}
            invalid={!!errors.conditionBonusMonthValue}
            helperText={
              errors.conditionBonusMonthValue?.message &&
              tGlobal(errors.conditionBonusMonthValue?.message)
            }
            {...register('conditionBonusMonthValue')}
          />
          {conditionBonusMonthValue && (
            <FormInputWithSuffix
              label={t('labels.additionalBonusOffered')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.conditionBonusValue}
              helperText={
                errors.conditionBonusValue?.message &&
                tGlobal(errors.conditionBonusValue?.message, {
                  max: '99,999,999.99',
                  min: '1',
                })
              }
              {...register('conditionBonusValue')}
            />
          )}
        </Stack>
      </SectionGridRow>

      <SectionGridRow
        partialBorderTop
        rightChildren={
          <DisplayButton
            name="conditionHiredDateBonusDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <Stack>
          <FormInputWithSuffix
            label={t('labels.ifHiredBefore')}
            type="date"
            minValue={formattedNowDate()}
            invalid={!!errors.conditionHiredDateBonusRequirement}
            helperText={
              errors.conditionHiredDateBonusRequirement?.message &&
              tGlobal(errors.conditionHiredDateBonusRequirement?.message)
            }
            {...register('conditionHiredDateBonusRequirement')}
          />
          {conditionHiredDateBonusRequirement && (
            <FormInputWithSuffix
              label={t('labels.additionalBonusOffered')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.conditionHiredDateBonusValue}
              helperText={
                errors.conditionHiredDateBonusValue?.message &&
                tGlobal(errors.conditionHiredDateBonusValue?.message, {
                  max: '99,999,999.99',
                  min: '1',
                })
              }
              {...register('conditionHiredDateBonusValue')}
            />
          )}
        </Stack>
      </SectionGridRow>
    </SectionGrid>
  );
};

export default MainFields;
