import {
  OperationUnitsFilters,
  OperationUnitsSortDirective,
} from '@domain/graphql.types';
import { useOperationUnits } from '@domain/operation/unit';

import { toViewModel } from '../view-models/get-operation-units.vm';

const useGetOperationUnits = (
  sortBy: OperationUnitsSortDirective[],
  filterBy?: OperationUnitsFilters
) => {
  const { data, pageData, fetching, error, reexecuteQuery } = useOperationUnits(
    {
      sortBy,
      filterBy,
    }
  );

  const viewModel = toViewModel({
    operationUnits: {
      data: {
        operationUnits: data,
        pageData,
      },
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchOperationUnits: reexecuteQuery,
  };
};

export default useGetOperationUnits;
