import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { IconButton } from '@application/components/buttons';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type InternationalRecruitmentConditionsProps = {
  data: Request | undefined;
};

const InternationalRecruitmentConditions = ({
  data,
}: InternationalRecruitmentConditionsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow
        isTopRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.internationalRecruitmentContractualConditions
                    ?.mandatoryWorkPermitDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.validWorkPermit')}</span>
        <PlaceholderSwitcher
          value={
            data?.internationalRecruitmentContractualConditions
              ?.mandatoryWorkPermitValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${!!data?.internationalRecruitmentContractualConditions
                ?.mandatoryWorkPermitValue}`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.internationalRecruitmentContractualConditions
                    ?.exclusivityPeriodDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">
          {t('labels.foreignCandidatesExclusivityPeriod')}
        </span>
        <PlaceholderSwitcher
          value={
            data?.internationalRecruitmentContractualConditions
              ?.exclusivityPeriodValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {
              data?.internationalRecruitmentContractualConditions
                ?.exclusivityPeriodValue
            }
            &nbsp;
            {t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow
        rightChildren={
          <IconButton
            ghost
            text={t('button.toggleVisibility')}
            icon={
              <i
                className={
                  data?.internationalRecruitmentContractualConditions
                    ?.maximumReplacementDisplay
                    ? 'ri-eye-fill'
                    : 'ri-eye-off-line'
                }
              />
            }
            readOnly
          />
        }
      >
        <span className="mr-s-8">{t('labels.maxReplacements')}</span>
        <PlaceholderSwitcher
          value={
            data?.internationalRecruitmentContractualConditions
              ?.maximumReplacementValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {
              data?.internationalRecruitmentContractualConditions
                ?.maximumReplacementValue
            }
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>
    </>
  );
};

export default InternationalRecruitmentConditions;
