import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  CheckboxGroup,
  CreatableSelectField,
  FormInputWithSuffix,
  HelperText,
  Label,
  RadioGroup,
} from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';
import { RangeSlider } from '@application/components/range-slider';
import type { OptionType } from '@application/components/select-field/select';
import { OfferFormFields } from '@application/views/recruitment/offer/types';
import { Section } from '@application/views/recruitment/request/components';
import {
  useGetJobDurations,
  useGetJobModes,
  useGetJobPaymentFrequencies,
  useGetJobPaymentOtherTypes,
  useGetJobScheduleTypes,
} from '@application/views/recruitment/request/hooks';
import {
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';
import { INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX } from '@utils/yup-utils';

type CandidateExpectationFieldsProps = {
  index: number;
};

const CandidateExpectationFields = ({
  index,
}: CandidateExpectationFieldsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });

  const { data: JOB_DURATIONS = [] } = useGetJobDurations();
  const { data: JOB_SCHEDULE_TYPES = [] } = useGetJobScheduleTypes();
  const { data: JOB_MODES = [] } = useGetJobModes();
  const { data: JOB_PAYMENT_FREQUENCIES = [] } = useGetJobPaymentFrequencies();
  const { data: JOB_PAYMENT_OTHER_TYPES = [] } = useGetJobPaymentOtherTypes();

  const {
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    register,
    trigger,
    formState: { errors },
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const schedules = watch(`offerCandidates.${index}.schedules`) || [];
  const duration = watch(`offerCandidates.${index}.jobTypeCode`);
  const mode = watch(`offerCandidates.${index}.jobModeCode`);
  const paymentFrequency = watch(
    `offerCandidates.${index}.paymentFrequencyCode`
  );
  const otherPaymentTypes =
    watch(`offerCandidates.${index}.otherPaymentTypes`) || [];
  const hourMin = watch(`offerCandidates.${index}.hourlyRateMin`);
  const hourMax = watch(`offerCandidates.${index}.hourlyRateMax`);
  const salaryMin = watch(`offerCandidates.${index}.annualSalaryMin`);
  const salaryMax = watch(`offerCandidates.${index}.annualSalaryMax`);
  const hoursPerWeek = watch(`offerCandidates.${index}.hoursPerWeek`) || [];

  const handleScheduleChange = (event: any): void => {
    if (event.target.checked) {
      setValue(`offerCandidates.${index}.schedules`, [
        ...schedules,
        event.target.value,
      ]);
    } else {
      setValue(`offerCandidates.${index}.schedules`, [
        ...schedules.filter((s) => s !== event.target.value),
      ]);
    }
    trigger(`offerCandidates.${index}.schedules`);
  };

  const handleOnOtherPaymentTypesChange = (event: any): void => {
    if (event.target.checked) {
      setValue(`offerCandidates.${index}.otherPaymentTypes`, [
        ...otherPaymentTypes,
        event.target.value,
      ]);
    } else {
      setValue(`offerCandidates.${index}.otherPaymentTypes`, [
        ...otherPaymentTypes.filter((p) => p !== event.target.value),
      ]);
    }
  };

  const hasAvailabilityErrors =
    errors.offerCandidates !== undefined &&
    (!!errors.offerCandidates[index]?.fullTimeAvailability ||
      !!errors.offerCandidates[index]?.partTimeAvailability);

  // filter on jobDurations because OpenEndedContract
  // is not applicable in requests
  return (
    <Section hideBox legend={t('subtitles.candidateExpectations')}>
      <Stack space={24}>
        <Controller
          name={`offerCandidates.${index}.jobTypeCode`}
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_DURATIONS.filter(
                (j) => j.value !== JobDurationCode.OpenEndedContract
              )}
              legend={t('labels.jobType')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobDurationCode);
                setValue(
                  `offerCandidates.${index}.jobDurationInMonths`,
                  undefined
                );
                trigger(name);
                trigger(`offerCandidates.${index}.jobDurationInMonths`);
              }}
              legendSize="small"
              value={value as string}
              invalid={!!errors.offerCandidates?.[index]?.jobTypeCode}
              helperText={
                errors.offerCandidates?.[index]?.jobTypeCode?.message &&
                tGlobal(
                  errors.offerCandidates?.[index]?.jobTypeCode
                    ?.message as string
                )
              }
            />
          )}
        />

        {(duration === JobDurationCode.Contract ||
          duration === JobDurationCode.Freelance ||
          duration === JobDurationCode.Internship) && (
          <FormInputWithSuffix
            label={t('labels.contractPeriod')}
            suffix={tGlobal('suffix.months')}
            type="number"
            minValue={1}
            invalid={!!errors.offerCandidates?.[index]?.jobDurationInMonths}
            helperText={
              errors.offerCandidates?.[index]?.jobDurationInMonths?.message &&
              tGlobal(
                errors.offerCandidates?.[index]?.jobDurationInMonths
                  ?.message as string,
                { min: '1' }
              )
            }
            {...register(`offerCandidates.${index}.jobDurationInMonths`)}
          />
        )}

        <div>
          {/* Had to put the label outside CreatableSelectField to align with the other controls/error on the line and while resizing */}
          <Label
            htmlFor="jobAvailabilityInHoursPerWeek"
            invalid={
              errors.offerCandidates !== undefined &&
              !!errors.offerCandidates[index]?.hoursPerWeek
            }
          >
            {t('labels.hoursPerWeek')}
          </Label>
          <Cluster space={16} align="center">
            <Controller
              name={`offerCandidates.${index}.hoursPerWeek`}
              control={control}
              render={({ field: { name }, fieldState: { error } }) => (
                <CreatableSelectField
                  name={name}
                  hideLabel
                  value={
                    hoursPerWeek?.map((v) => ({
                      label: v,
                      value: v,
                    })) as OptionType[]
                  }
                  className="flex-1 min-w-fit"
                  validateOnKeyDown={(newValue: string) =>
                    INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                  }
                  onInputChange={(newValue: string) => {
                    // Check if entered value is an integer or one decimal digit number only
                    // return an error otherwise
                    if (
                      newValue &&
                      !INTEGER_ONE_DECIMAL_DIGIT_NUMBER_REGEX.test(newValue)
                    ) {
                      setError(name, {
                        type: 'custom',
                        message: tGlobal(
                          'validations.integerOrOneDecimalDigitNumber'
                        ),
                      });
                      return;
                    }
                    clearErrors(name);
                  }}
                  onChange={(options: any) => {
                    setValue(
                      name,
                      options.map((option: any) => option.value)
                    );
                    trigger(name);
                  }}
                  formatOptionLabel={({ value: newValue }) => `${newValue}h`}
                  invalid={!!error}
                  helperText={
                    error?.message
                      ? tGlobal(error?.message, {
                          min: '1',
                        })
                      : tGlobal('creatableSelect.enterHoursAsIntegerOrDecimal')
                  }
                />
              )}
            />
            <div>
              <Cluster space={16} align="center">
                <Controller
                  name={`offerCandidates.${index}.fullTimeAvailability`}
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Checkbox
                      name={name}
                      value={String(value)}
                      label={t('labels.fullTime')}
                      onChange={(event) => {
                        onChange(event);
                        trigger(
                          `offerCandidates.${index}.partTimeAvailability`
                        );
                        trigger(
                          `offerCandidates.${index}.fullTimeAvailability`
                        );
                      }}
                      checked={Boolean(value)}
                      asButton
                    />
                  )}
                />

                <Controller
                  name={`offerCandidates.${index}.partTimeAvailability`}
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Checkbox
                      name={name}
                      value={String(value)}
                      label={t('labels.partTime')}
                      onChange={(event) => {
                        onChange(event);
                        trigger(
                          `offerCandidates.${index}.fullTimeAvailability`
                        );
                        trigger(
                          `offerCandidates.${index}.partTimeAvailability`
                        );
                      }}
                      checked={Boolean(value)}
                      asButton
                    />
                  )}
                />
              </Cluster>

              {hasAvailabilityErrors && (
                <HelperText
                  id="jobAvailability"
                  invalid={hasAvailabilityErrors}
                >
                  {tGlobal('validations.required.jobAvailability')}
                </HelperText>
              )}
            </div>
          </Cluster>
        </div>

        <Controller
          name={`offerCandidates.${index}.schedules`}
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <CheckboxGroup
              data={JOB_SCHEDULE_TYPES}
              legend={t('labels.scheduleMultiple')}
              language={extractLanguage(i18n.language)}
              onChange={handleScheduleChange}
              legendSize="small"
              values={value}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />

        <Controller
          name={`offerCandidates.${index}.jobModeCode`}
          control={control}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              data={JOB_MODES}
              legend={t('labels.remoteWork')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobModeCode);
                trigger(name);
                trigger(`offerCandidates.${index}.allowedRemoteDays`);
              }}
              legendSize="small"
              value={value as string}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />

        {mode === JobModeCode.Hybrid && (
          <FormInputWithSuffix
            type="number"
            minValue={1}
            maxValue={7}
            label={t('labels.remoteDaysAllowed')}
            suffix={tGlobal('suffix.daysPerWeek')}
            invalid={!!errors.offerCandidates?.[index]?.allowedRemoteDays}
            helperText={
              errors.offerCandidates?.[index]?.allowedRemoteDays?.message &&
              tGlobal(
                errors.offerCandidates?.[index]?.allowedRemoteDays
                  ?.message as string,
                {
                  min: '1',
                  max: '7',
                }
              )
            }
            {...register(`offerCandidates.${index}.allowedRemoteDays`)}
          />
        )}

        <Controller
          name={`offerCandidates.${index}.paymentFrequencyCode`}
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_PAYMENT_FREQUENCIES}
              legend={t('labels.remunerationType')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobPaymentFrequencyCode);
                trigger(name);
                trigger(`offerCandidates.${index}.remunerationAmount`);
              }}
              legendSize="small"
              value={value as string}
              invalid={!!errors.offerCandidates?.[index]?.paymentFrequencyCode}
              helperText={
                errors.offerCandidates?.[index]?.paymentFrequencyCode
                  ?.message &&
                tGlobal(
                  errors.offerCandidates?.[index]?.paymentFrequencyCode
                    ?.message as string
                )
              }
            />
          )}
        />

        {paymentFrequency === JobPaymentFrequencyCode.Yearly && (
          <RangeSlider
            minLabel={t('labels.minSalary')}
            maxLabel={t('labels.maxSalary')}
            minName={`offerCandidates.${index}.annualSalaryMin`}
            maxName={`offerCandidates.${index}.annualSalaryMax`}
            onChange={(name: string, value: number | string) =>
              setValue(name as keyof OfferFormFields, value)
            }
            minValue={salaryMin || undefined}
            maxValue={salaryMax || undefined}
            min={1000}
            max={1000000}
            suffix="$"
          />
        )}

        {paymentFrequency === JobPaymentFrequencyCode.Hourly && (
          <>
            <RangeSlider
              minLabel={t('labels.minHourlyRate')}
              maxLabel={t('labels.maxHourlyRate')}
              minName={`offerCandidates.${index}.hourlyRateMin`}
              maxName={`offerCandidates.${index}.hourlyRateMax`}
              onChange={(name, value) => {
                setValue(name as keyof OfferFormFields, value);
              }}
              minValue={hourMin || undefined}
              maxValue={hourMax || undefined}
              min={0.01}
              max={999.99}
              step={0.01}
              suffix="$/h"
            />

            <FormInputWithSuffix
              label={t('labels.hourlyBonus')}
              suffix={tGlobal('suffix.currency')}
              type="number"
              minValue={1}
              maxValue={999.99}
              step={0.01}
              alignLeft
              invalid={!!errors.offerCandidates?.[index]?.hourlyBonus}
              helperText={
                errors.offerCandidates?.[index]?.hourlyBonus?.message &&
                tGlobal(
                  errors.offerCandidates?.[index]?.hourlyBonus
                    ?.message as string,
                  {
                    max: '999.99',
                    min: '1',
                  }
                )
              }
              {...register(`offerCandidates.${index}.hourlyBonus`)}
            />
          </>
        )}

        {paymentFrequency === JobPaymentFrequencyCode.FixedAmount && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.compensationAmount')}
            suffix={tGlobal('suffix.currency')}
            invalid={!!errors.offerCandidates?.[index]?.remunerationAmount}
            helperText={
              errors.offerCandidates?.[index]?.remunerationAmount?.message &&
              tGlobal(
                errors.offerCandidates?.[index]?.remunerationAmount
                  ?.message as string,
                {
                  max: '99,999,999.99',
                  min: '1',
                }
              )
            }
            {...register(`offerCandidates.${index}.remunerationAmount`)}
          />
        )}

        <Controller
          name={`offerCandidates.${index}.otherPaymentTypes`}
          control={control}
          render={({ field: { value } }) => (
            <CheckboxGroup
              data={JOB_PAYMENT_OTHER_TYPES}
              legend={t('labels.otherCompensation')}
              language={extractLanguage(i18n.language)}
              onChange={handleOnOtherPaymentTypesChange}
              legendSize="small"
              values={value}
              invalid={!!errors.offerCandidates?.[index]?.otherPaymentTypes}
              helperText={
                errors.offerCandidates?.[index]?.otherPaymentTypes?.message &&
                tGlobal(
                  errors.offerCandidates?.[index]?.otherPaymentTypes
                    ?.message as string
                )
              }
            />
          )}
        />

        {otherPaymentTypes?.includes(JobPaymentOtherTypesCode.HiringBonus) && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.otherCompensationAmount')}
            suffix={tGlobal('suffix.currency')}
            invalid={!!errors.offerCandidates?.[index]?.otherRemunerationAmount}
            helperText={
              errors.offerCandidates?.[index]?.otherRemunerationAmount
                ?.message &&
              tGlobal(
                errors.offerCandidates?.[index]?.otherRemunerationAmount
                  ?.message as string,
                {
                  max: '99,999,999.99',
                  min: '1',
                }
              )
            }
            {...register(`offerCandidates.${index}.otherRemunerationAmount`)}
          />
        )}
      </Stack>
    </Section>
  );
};

export default CandidateExpectationFields;
