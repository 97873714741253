export const getPage = (fragments: string[]): string => {
  const fragment = fragments.map((f) => `...${f}`).join(' ');

  return `page {
      edges {
        cursor
        node {
        ${fragment}
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
    pageData {
      count
      limit
      offset
    }
  }`;
};
