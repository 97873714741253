import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useUserUpdateNotificationPreferences } from '@domain/user';

import { toViewModel } from '../view-models/update-notification-preferences.vm';

const useUpdateNotificationPreferences = () => {
  const {
    data,
    fetching,
    error,
    userUpdateNotificationPreferences: updateNotificationPreferences,
  } = useUserUpdateNotificationPreferences();

  const { t } = useTranslation('user');

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    updateNotificationPreferences: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (data?.preferences) {
      setAlertContent({
        action: t('messages.updatePreferencesSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.preferences, setAlertContent]);

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('messages.updatePreferencesError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length, setAlertContent]);

  return {
    viewModel,
    updateNotificationPreferences,
  };
};

export default useUpdateNotificationPreferences;
