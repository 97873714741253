import { gql } from '@urql/core';

import RequestOfferFragment from '../fragment/request.offer.fragment';

const RequestOffers = gql`
  ${RequestOfferFragment}

  query RequestOffers($input: RequestOffersInput!) {
    requestOffers(input: $input) {
      offers {
        ...RequestOfferFragment
      }
    }
  }
`;

export default RequestOffers;
