import { useMutation } from 'urql';

import {
  Mutation,
  MutationCandidateOffersRejectArgs,
} from '@domain/graphql.types';
import { MutationCandidateOffersReject } from '@infrastructure/graphql/candidate/offer';

const useCandidateOffersReject = () => {
  const [{ data, fetching, error }, rejectCandidateOffers] = useMutation<
    Pick<Mutation, 'candidateOffersReject'>,
    MutationCandidateOffersRejectArgs
  >(MutationCandidateOffersReject);

  return {
    rejectCandidateOffers,
    data,
    fetching,
    error,
  };
};

export default useCandidateOffersReject;
