import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountUpdate } from '@domain/account';
import { Mutation } from '@domain/graphql.types';

import { toViewModel } from '../view-models/update-profile.vm';

const useUpdateProfile = () => {
  const {
    data = {} as Pick<Mutation, 'accountUpdate'>,
    fetching,
    error,
    updateAccount: updateProfile,
  } = useAccountUpdate();

  const { t } = useTranslation('organization', {
    keyPrefix: 'updateProfileForm',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    account: {
      data: data.accountUpdate,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('messages.errors.update'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.accountUpdate?.account) {
      setAlertContent({
        action: t('messages.success.update'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.accountUpdate?.account]);

  return {
    viewModel,
    updateProfile,
  };
};

export default useUpdateProfile;
