import { useTranslation } from 'react-i18next';

import {
  PlaceholderSwitcher,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type GeneralConditionsProps = {
  data: RecruitmentOpportunity | undefined;
};

const GeneralConditions = ({ data }: GeneralConditionsProps) => {
  const { t, i18n } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow isTopRow>
        <span className="mr-s-8">{t('labels.duration')}</span>

        <VisibilitySwitcher
          visible={
            data?.generalContractualConditions.maxRecruitmentPeriodDisplay ||
            false
          }
          content={
            <PlaceholderSwitcher
              value={
                data?.generalContractualConditions.maxRecruitmentPeriodValue
              }
            >
              <span className="text-18 font-semibold text-primary">
                {data?.generalContractualConditions.maxRecruitmentPeriodValue}
                &nbsp;
                {t('suffix.weeks')}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.mustProposeCandidates')}</span>
        <PlaceholderSwitcher
          value={
            data?.generalContractualConditions.candidateInHandMandatoryValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${!!data?.generalContractualConditions
                .candidateInHandMandatoryValue}`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.trialPeriodLength')}</span>

        <VisibilitySwitcher
          visible={
            data?.generalContractualConditions.probationPeriodDisplay || false
          }
          content={
            <PlaceholderSwitcher
              value={data?.generalContractualConditions.probationPeriodValue}
            >
              <span className="text-18 font-semibold text-primary">
                {data?.generalContractualConditions.probationPeriodValue}
                &nbsp;
                {t('suffix.weeks')}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>

      <SectionGridRow>
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.preferredPaymentMethod')}&nbsp;
            <VisibilitySwitcher
              visible={
                data?.generalContractualConditions.paymentMethodDisplay || false
              }
              content={
                <PlaceholderSwitcher
                  value={data?.generalContractualConditions.paymentMethodValue}
                >
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionPaymentMethodCode.${data?.generalContractualConditions.paymentMethodValue?.toLowerCase()}`
                    )}
                  </span>

                  {data?.generalContractualConditions
                    .startOfAgreementPaymentMethodValue && (
                    <div className="mr-s-8 mt-s-16 flex">
                      <span className="mr-s-8">
                        {`${t(`enum.requestConditionStartOfAgreementPaymentMethodCode.${data?.generalContractualConditions.startOfAgreementPaymentMethodValue?.toLowerCase()}`)}: `}
                      </span>
                      <span className="text-18 font-semibold text-primary">
                        {t(
                          `enum.requestConditionPaymentFrequencyCode.${data?.generalContractualConditions.paymentFrequencyValue?.toLowerCase()}`
                        )}
                      </span>
                    </div>
                  )}
                </PlaceholderSwitcher>
              }
            />
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.depositPayment')}</span>

        <VisibilitySwitcher
          visible={data?.generalContractualConditions.depositDisplay || false}
          content={
            <PlaceholderSwitcher
              value={data?.generalContractualConditions?.depositValue}
            >
              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: data?.generalContractualConditions?.depositValue,
                })}
              </span>
            </PlaceholderSwitcher>
          }
        />
      </SectionGridRow>

      <SectionGridRow>
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.BaselineBudget')}&nbsp;
            <VisibilitySwitcher
              visible={
                data?.generalContractualConditions.budgetDisplay || false
              }
              content={
                <PlaceholderSwitcher
                  value={data?.generalContractualConditions.minimumBudgetValue}
                >
                  <span className="text-18 font-semibold text-primary">
                    {tGlobal('suffix.currencyValue', {
                      value:
                        data?.generalContractualConditions?.minimumBudgetValue,
                    })}
                  </span>

                  {data?.generalContractualConditions?.maximumBudgetValue && (
                    <div className="mr-s-8 mt-s-16 flex">
                      <span>{t('labels.minBudget')}&nbsp;</span>

                      <span className="text-18 font-semibold text-primary">
                        {tGlobal('suffix.currencyValue', {
                          value:
                            data?.generalContractualConditions
                              ?.minimumBudgetValue,
                        })}
                      </span>

                      <div className="divider divider-horizontal ml-s-8 mr-s-16 before:w-s-1 after:w-s-1" />

                      <span>{t('labels.maxBudget')}&nbsp;</span>

                      <span className="text-18 font-semibold text-primary">
                        {tGlobal('suffix.currencyValue', {
                          value:
                            data?.generalContractualConditions
                              ?.maximumBudgetValue,
                        })}
                      </span>
                    </div>
                  )}
                </PlaceholderSwitcher>
              }
            />
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow>
        <div className="flex flex-col">
          <span className="mr-s-8 mb-s-16">{t('labels.additionalBonus')}</span>

          <div>
            <span className="mr-s-8">{t('labels.minGuaranteedPeriod')}</span>

            <VisibilitySwitcher
              visible={
                data?.generalContractualConditions.minGuaranteedPeriodDisplay ||
                false
              }
              content={
                <PlaceholderSwitcher
                  value={
                    data?.generalContractualConditions?.minGuaranteedPeriodValue
                  }
                >
                  <span className="text-18 font-semibold text-primary">
                    {
                      data?.generalContractualConditions
                        ?.minGuaranteedPeriodValue
                    }
                    &nbsp;
                    {t('suffix.months')}
                  </span>
                </PlaceholderSwitcher>
              }
            />
          </div>
          {data?.generalContractualConditions?.minGuaranteedPeriodDisplay && (
            <div className="mt-s-8">
              <span className="mr-s-8">
                {t('labels.minGuaranteedPeriodBonusValue')}
              </span>
              <PlaceholderSwitcher
                value={
                  data?.generalContractualConditions
                    ?.minGuaranteedPeriodBonusValue
                }
              >
                <span className="text-18 font-semibold text-primary">
                  {tGlobal('suffix.currencyValue', {
                    value:
                      data?.generalContractualConditions
                        ?.minGuaranteedPeriodBonusValue,
                  })}
                </span>
              </PlaceholderSwitcher>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow partialBorderTop>
        <div className="flex flex-col">
          <div>
            {data?.generalContractualConditions.bonusDisplay &&
            data?.generalContractualConditions?.bonusMonthValue ? (
              <>
                {t('labels.ifCandidate')}
                <span className="text-18 ml-s-8 font-semibold text-primary">
                  {data?.generalContractualConditions?.bonusMonthValue}
                  &nbsp;
                  {t('suffix.months')}
                </span>
                <span className="ml-s-8">{t('labels.stayAfterHired')}</span>
              </>
            ) : (
              <>
                <span className="mr-s-8">
                  {t('labels.ifCandidate')}&nbsp;{t('labels.stayAfterHired')}:
                </span>
                <VisibilitySwitcher
                  visible={
                    data?.generalContractualConditions.bonusDisplay || false
                  }
                  content={
                    <PlaceholderSwitcher
                      value={
                        data?.generalContractualConditions?.bonusMonthValue
                      }
                    >
                      {data?.generalContractualConditions?.bonusMonthValue && (
                        <span className="text-18 font-semibold text-primary">
                          {data?.generalContractualConditions?.bonusMonthValue}
                          &nbsp;
                          {t('suffix.months')}
                        </span>
                      )}
                    </PlaceholderSwitcher>
                  }
                />
              </>
            )}

            {data?.generalContractualConditions.bonusDisplay && (
              <p className="mr-s-8 mt-s-8">
                {t('labels.additionalBonusOffered')}&nbsp;
                <PlaceholderSwitcher
                  value={data?.generalContractualConditions?.bonusValue}
                >
                  <span className="text-18 font-semibold text-primary">
                    {tGlobal('suffix.currencyValue', {
                      value: data?.generalContractualConditions?.bonusValue,
                    })}
                  </span>
                </PlaceholderSwitcher>
              </p>
            )}
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow partialBorderTop>
        <div className="flex flex-col">
          <div>
            <span className="mr-s-8">{t('labels.ifHiredBefore')}</span>

            <VisibilitySwitcher
              visible={
                data?.generalContractualConditions.hiredDateBonusDisplay ||
                false
              }
              content={
                <PlaceholderSwitcher
                  value={
                    data?.generalContractualConditions?.hiredDateBonusValue
                  }
                >
                  {data?.generalContractualConditions
                    ?.hiredDateBonusRequirement && (
                    <span className="text-18 mr-s-8 font-semibold text-primary">
                      {formatDate(
                        data?.generalContractualConditions
                          ?.hiredDateBonusRequirement,
                        i18n.language
                      )}
                    </span>
                  )}
                </PlaceholderSwitcher>
              }
            />
          </div>

          {data?.generalContractualConditions.hiredDateBonusDisplay && (
            <p className="mr-s-8 mt-s-8">
              {t('labels.additionalBonusOffered')}&nbsp;
              <PlaceholderSwitcher
                value={data?.generalContractualConditions?.hiredDateBonusValue}
              >
                <span className="text-18 font-semibold text-primary">
                  {tGlobal('suffix.currencyValue', {
                    value:
                      data?.generalContractualConditions?.hiredDateBonusValue,
                  })}
                </span>
              </PlaceholderSwitcher>
            </p>
          )}
        </div>
      </SectionGridRow>
    </>
  );
};

export default GeneralConditions;
