import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconBadge, Tooltip } from '@application/components';
import { CandidateRequestType } from '@domain/graphql.types';

type CandidateRequestTypeBadgeProps = {
  type: CandidateRequestType | undefined;
};

const CandidateRequestTypeBadge = ({
  type,
}: CandidateRequestTypeBadgeProps) => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'enum.candidateRequestType',
  });

  const badgeData = useMemo(
    () => ({
      typeColors: {
        [CandidateRequestType.Recruitment]: 'light-blue',
        [CandidateRequestType.TemporaryPlacement]: 'light-yellow',
        [CandidateRequestType.Outsourcing]: 'light-pink',
      },
      icon: {
        [CandidateRequestType.Recruitment]: 'customer-service',
        [CandidateRequestType.TemporaryPlacement]: 'timer',
        [CandidateRequestType.Outsourcing]: 'profile',
      },
    }),
    []
  );

  return type ? (
    <div className="flex">
      <Tooltip message={t(type.toLowerCase())} position="top">
        <IconBadge
          color={badgeData.typeColors[type]}
          icon={badgeData.icon[type]}
        />
      </Tooltip>
    </div>
  ) : null;
};

export default CandidateRequestTypeBadge;
