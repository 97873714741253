// NOTE: We need to turn off this eslint rule exceptionally in order to not have an error
// when there is no header associated to a cell value as seen in the UI

/* eslint jsx-a11y/control-has-associated-label: 0 */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher } from '@application/components/switch';
import { RequestTemporaryPlacementConditions } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME } from '../../constants';

const conditionsKey: (keyof RequestTemporaryPlacementConditions)[] = [
  'possibleBuybackValue',
  'travelExpensesResponsibilityValue',
  'trainingExpensesResponsibilityValue',
  'cnesstExpensesResponsibilityValue',
];

type TemporaryPlacementConditionsProps = {
  data: RequestTemporaryPlacementConditions;
};

const TemporaryPlacementConditions = ({
  data,
}: TemporaryPlacementConditionsProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const getCellContent = useCallback(
    (condition: keyof RequestTemporaryPlacementConditions) => {
      const cellValue = data?.[condition];

      switch (condition) {
        case 'possibleBuybackValue':
          return cellValue ? tGlobal('boolean.true') : tGlobal('boolean.false');
        case 'travelExpensesResponsibilityValue':
        case 'trainingExpensesResponsibilityValue':
        case 'cnesstExpensesResponsibilityValue':
          return tGlobal(
            `enum.requestConditionResponsibility.${cellValue
              ?.toString()
              .toLowerCase()}`
          );
        default:
          return '-';
      }
    },
    [data, tGlobal]
  );

  return (
    <>
      <tr>
        <th className="sr-only">
          <span>{t('temporaryPlacementConditions')}</span>
        </th>
        <td className={cn(TD_CLASS_NAME.base, TD_CLASS_NAME.highlight)}>
          &nbsp;
        </td>
      </tr>
      {conditionsKey.map(
        (condition: keyof RequestTemporaryPlacementConditions) => (
          <tr key={`requestTemporaryPlacementConditions-${condition}`}>
            <th scope="col" className="sr-only">
              {t(condition)}
            </th>
            <td
              className={cn(
                TD_CLASS_NAME.base,
                TD_CLASS_NAME.fixed,
                'border-l-0'
              )}
            >
              <PlaceholderSwitcher value={data?.[condition]}>
                <span>{getCellContent(condition)}</span>
              </PlaceholderSwitcher>
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default TemporaryPlacementConditions;
