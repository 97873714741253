import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseQueryExecute } from 'urql';

import { RankBadge } from '@application/components';
import {
  RecruitmentNegotiationRoom,
  RecruitmentNegotiationRoomStatus,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { useUpdateNegotiatingOffer } from '../../hooks';
import {
  normalizeRecruitmentNegotiationRoomEditData,
  normalizeRecruitmentNegotiationRoomInputData,
} from '../../room/normalizers';
import {
  RECRUITMENT_NEGOTIATION_ROOM_SCHEMA,
  RecruitmentNegotiationRoomFormFields,
} from '../../room/schema';
import { TD_CLASS_NAME, TH_ROW_CLASS_NAME } from '../constants';
import {
  GeneralConditions,
  HeadHunterConditions,
  InternationalRecruitmentConditions,
  OutsourcingConditions,
  ResumeLink,
  TemporaryPlacementConditions,
} from './rows';

type TableBodyProps = {
  room: RecruitmentNegotiationRoom;
  refetchRoom: UseQueryExecute;
};

const TableBody = ({ room, refetchRoom }: TableBodyProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.tableHeaders',
  });

  const methods = useForm<RecruitmentNegotiationRoomFormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(RECRUITMENT_NEGOTIATION_ROOM_SCHEMA),
  });

  const { handleSubmit, reset, setValue, watch } = methods;

  const { updateNegotiatingOffer } = useUpdateNegotiatingOffer();

  const onSubmit: SubmitHandler<RecruitmentNegotiationRoomFormFields> =
    useCallback(
      async (values) => {
        setValue('fieldInModification', null);

        await updateNegotiatingOffer({
          input: {
            ...normalizeRecruitmentNegotiationRoomInputData(values),
            id: room.negotiatingOfferId,
          },
        });

        refetchRoom();
      },
      [refetchRoom, room.negotiatingOfferId, setValue, updateNegotiatingOffer]
    );

  useEffect(() => {
    if (room) {
      const updatedDefaultValues =
        normalizeRecruitmentNegotiationRoomEditData(room);

      reset(updatedDefaultValues);
    }
  }, [room, reset]);

  const fieldInModification = watch('fieldInModification');

  const modificationEnabled = useMemo(
    () =>
      !fieldInModification &&
      room.status === RecruitmentNegotiationRoomStatus.InProgress,
    [fieldInModification, room.status]
  );

  return (
    <tbody>
      <tr>
        <th className={TH_ROW_CLASS_NAME.base}>{t('rank')}</th>
        <td
          aria-label={t('information.rank')}
          className={cn(TD_CLASS_NAME.base, 'text-center')}
        >
          {room.negotiatingOffer.rank ? (
            <RankBadge rank={room.negotiatingOffer.rank} />
          ) : undefined}
        </td>
      </tr>

      <ResumeLink room={room} />

      <FormProvider {...methods}>
        <GeneralConditions
          room={room}
          onSubmit={handleSubmit(onSubmit)}
          modificationEnabled={modificationEnabled}
        />

        <HeadHunterConditions
          room={room}
          onSubmit={handleSubmit(onSubmit)}
          modificationEnabled={modificationEnabled}
        />

        <TemporaryPlacementConditions
          room={room}
          onSubmit={handleSubmit(onSubmit)}
          modificationEnabled={modificationEnabled}
        />

        <InternationalRecruitmentConditions
          room={room}
          onSubmit={handleSubmit(onSubmit)}
          modificationEnabled={modificationEnabled}
        />

        <OutsourcingConditions
          room={room}
          onSubmit={handleSubmit(onSubmit)}
          modificationEnabled={modificationEnabled}
        />
      </FormProvider>
    </tbody>
  );
};

export default TableBody;
