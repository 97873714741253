import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { DeleteInvitationResponse } from '@domain/graphql.types';

type ToViewModel = {
  invitationDelete: {
    data: DeleteInvitationResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  invitationDelete: { data, fetching, error },
}: ToViewModel): ViewModel<DeleteInvitationResponse | undefined> =>
  new ViewModel<DeleteInvitationResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
