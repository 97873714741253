import { gql } from '@urql/core';

const MutationNegotiatingOfferUpdate = gql`
  mutation NegotiatingNegotiatingOfferUpdate(
    $input: NegotiatingOfferUpdateInput!
  ) {
    negotiatingOfferUpdate(input: $input) {
      offer {
        id
      }
    }
  }
`;

export default MutationNegotiatingOfferUpdate;
