import { gql } from 'urql';

import { DescriptionsFragment } from '../../../descriptions';

const RecruitmentNegotiationListFragment = gql`
  ${DescriptionsFragment}

  fragment RecruitmentNegotiationListFragment on RecruitmentNegotiation {
    id
    accountId
    requestId
    userId
    status
    createdAt
    updatedAt
    notificationCount
    roomsCount
    latestActivity
    request {
      operationTerritoryCodes
      operationUnit {
        name
      }
      types
      jobSpecialty {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
      operationTerritories {
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
    rooms {
      offer {
        account {
          logoUrl
          name
        }
      }
    }
  }
`;

export default RecruitmentNegotiationListFragment;
