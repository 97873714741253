import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useEventListener } from 'usehooks-ts';

import { NavContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { useCrisp } from '@application/hooks';
import { cn } from '@utils/lib-utils';

import { Sidebar } from '../sidebar';
import { TopNav } from '../top-bar/top-nav';

type PrivateLayoutProps = {
  children: React.ReactNode;
};

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const { isNavOpen, setIsNavOpen } = useContext(NavContext);
  const navigate = useNavigate();
  const location = useLocation();

  useCrisp();

  useEventListener('kehops-not-found', () =>
    navigate(RootPrivatePage.NOT_FOUND, { replace: true })
  );

  const isNegotiationRoomsChat = location.pathname.includes('chat');

  return (
    <div className="w-full flex flex-row min-h-[100vh]">
      <Sidebar
        isNavOpen={isNavOpen}
        toggleNav={(): void => setIsNavOpen(!isNavOpen)}
      />

      <div className="grow max-h-[100vh] relative flex flex-col overflow-y-auto scroll-smooth">
        <TopNav onClick={(): void => setIsNavOpen(!isNavOpen)} />
        <main
          className={cn('grow p-s-24 lg:p-s-32', {
            'overflow-hidden': isNegotiationRoomsChat,
          })}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default PrivateLayout;
