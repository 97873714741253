import { RecruitmentNegotiationPropertyState } from '@domain/graphql.types';

import { TD_CLASS_NAME } from './constants';

export const getCellStyleForState = (
  state: RecruitmentNegotiationPropertyState
): string => {
  switch (state) {
    case RecruitmentNegotiationPropertyState.Accepted:
      return TD_CLASS_NAME.accepted;
    case RecruitmentNegotiationPropertyState.Rejected:
      return TD_CLASS_NAME.rejected;
    case RecruitmentNegotiationPropertyState.Modified:
      return TD_CLASS_NAME.modified;
    default:
      return '-';
  }
};
