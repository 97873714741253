import { gql } from '@urql/core';

const MutationCandidateNegotiationComplete = gql`
  mutation CandidateNegotiationComplete(
    $input: CandidateNegotiationCompleteInput!
  ) {
    candidateNegotiationComplete(input: $input) {
      negotiation {
        id
      }
    }
  }
`;

export default MutationCandidateNegotiationComplete;
