import { Languages } from '@application/enums/languages';
import {
  MeResponse,
  Query,
  UpdateUserProfileInput,
} from '@domain/graphql.types';

import { UserProfile, UserProfileWithDefaultAccount } from './types';

export const normalizeGetData = ({
  me: { user } = {} as MeResponse,
}:
  | Pick<Query, 'me'>
  | Record<string, never>): UserProfileWithDefaultAccount => ({
  bio: user?.bio || '',
  defaultAccountId: user?.defaultAccountId || '',
  facebookUrl: user?.facebookUrl || '',
  firstName: user?.firstName || '',
  id: user?.id,
  instagramUrl: user?.instagramUrl || '',
  jobDescription: user?.jobDescription || '',
  lastName: user?.lastName || '',
  linkedinUrl: user?.linkedinUrl || '',
  location: user?.location || '',
  professionalEmail: user?.professionalEmail || '',
  phoneDisplay: user?.phoneDisplay || false,
  phoneNumber: user?.phoneNumber || '',
  spokenLanguages: (user?.spokenLanguages as Languages[]) || [],
  twitterUrl: user?.twitterUrl || '',
  why: user?.why || '',
});

export const normalizeUpdateInputData = (
  user: UserProfile
): UpdateUserProfileInput => ({
  bio: user?.bio || '',
  facebookUrl: user?.facebookUrl || '',
  firstName: user?.firstName || '',
  id: user?.id || '',
  instagramUrl: user?.instagramUrl || '',
  jobDescription: user?.jobDescription || '',
  lastName: user?.lastName || '',
  linkedinUrl: user?.linkedinUrl || '',
  location: user?.location || '',
  professionalEmail: user?.professionalEmail || '',
  phoneDisplay: user?.phoneDisplay,
  phoneNumber: user?.phoneNumber || '',
  spokenLanguages: (user?.spokenLanguages as Languages[]) || [],
  twitterUrl: user?.twitterUrl || '',
  why: user?.why || '',
});
