import React, { useCallback, useMemo } from 'react';

import { AccountContext } from '@application/context';
import { useAccountGet } from '@domain/account';

type AccountProviderProps = {
  children: React.ReactNode;
};

const AccountProvider = ({ children }: AccountProviderProps) => {
  const { data, fetching, error, reexecuteQuery } = useAccountGet(true);
  const account = data?.account || undefined;

  const initializeAccount = useCallback(
    () => reexecuteQuery(),
    [reexecuteQuery]
  );

  const preferredSpecialtyCodes = useMemo(
    () => account?.preferredSpecialties.map((s) => s.specialty.code) || [],
    [account]
  );

  const value = useMemo(
    () => ({
      account,
      error,
      initializeAccount,
      isLoading: fetching,
      preferredSpecialtyCodes,
      refreshAccount: reexecuteQuery,
    }),
    [
      account,
      error,
      fetching,
      initializeAccount,
      preferredSpecialtyCodes,
      reexecuteQuery,
    ]
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export default AccountProvider;
