import { gql } from '@urql/core';

const MutationCandidateRequestUpdate = gql`
  mutation CandidateRequestUpdate($input: CandidateRequestUpdateInput!) {
    candidateRequestUpdate(input: $input) {
      candidateRequest {
        id
      }
    }
  }
`;

export default MutationCandidateRequestUpdate;
