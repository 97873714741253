import { useMemo } from 'react';

import { useNotFoundOnPredicate, useValidateIdParam } from '@application/hooks';
import { useRecruitmentNegotiationRoom } from '@domain/negotiation';

import { toViewModel } from '../view-models/get-recruitment-negotiation-room-agreement.vm';

const useGetRecruitmentNegotiationRoomAgreement = (roomId: string) => {
  useValidateIdParam(roomId);

  const { data, error, fetching } = useRecruitmentNegotiationRoom({
    input: { roomId },
  });

  const viewModel = useMemo(
    () => toViewModel({ agreement: { data, error, fetching } }),
    [data, error, fetching]
  );

  useNotFoundOnPredicate({
    predicate: !viewModel?.data && !viewModel?.isLoading,
  });

  return {
    viewModel,
  };
};

export default useGetRecruitmentNegotiationRoomAgreement;
