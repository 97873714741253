import { useMutation } from 'urql';

import {
  Mutation,
  MutationRecruitmentNegotiationRoomRejectValuesArgs,
} from '@domain/graphql.types';
import { MutationRecruitmentNegotiationRoomRejectValues } from '@infrastructure/graphql/negotiation';

const useRecruitmentNegotiationRoomRejectValues = () => {
  const [{ data, fetching, error }, rejectNegotiationRoomValues] = useMutation<
    Pick<Mutation, 'recruitmentNegotiationRoomRejectValues'>,
    MutationRecruitmentNegotiationRoomRejectValuesArgs
  >(MutationRecruitmentNegotiationRoomRejectValues);

  return {
    rejectNegotiationRoomValues,
    data,
    fetching,
    error,
  };
};

export default useRecruitmentNegotiationRoomRejectValues;
