import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@application/components';
import { cn } from '@utils/lib-utils';

export type FileTypes = {
  values: string;
  text: string;
};

export type FileInputProps = {
  /**
   * Specify the id of the`<FileInput>`
   */
  id: string;
  /**
   * Specify the name of the `<FileInput>` input
   */
  name: string;
  /**
   * Specify the value of the `<FileInput>` input
   */
  value?: string;
  /**
   * Specify whether the `<FileInput>` is disabled
   */
  disabled?: boolean;
  /**
   * Specify the name of the file
   */
  fileName?: string;
  /**
   * Specify the accepted file types and extensions
   */
  acceptedTypes: FileTypes;
  /**
   * Optionally provide a custom className to apply to  the `<FileInput>`
   */
  className?: string;
  /**
   * Optionally provide an `onChange` handler that is called whenever the hidden `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optionally provide an `onRemove` handler that is called whenever the remove button is clicked
   */
  onRemove?: () => void;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

const FileInput = ({
  id,
  name,
  value,
  disabled,
  fileName,
  acceptedTypes,
  onChange,
  onRemove,
  className: customClassName,
  ...rest
}: FileInputProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const wrapperClassName = 'relative border-1 border-dashed rounded-md';

  const fileButtonClassName = cn(
    'grid grid-cols-[max-content_1fr] items-center gap-s-16 w-full px-s-16 py-s-12 text-left',
    customClassName
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      // reset value here to bypass default file input behavior
      // which not firing upon selecting the same file.
      if (hiddenFileInput?.current?.value) {
        hiddenFileInput.current.value = '';
      }
      hiddenFileInput?.current?.click();
    },
    []
  );

  return (
    <>
      <input
        type="file"
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        ref={hiddenFileInput}
        accept={acceptedTypes.values}
        onChange={onChange}
        className="sr-only pointer-events-none"
        {...rest}
      />

      <div className={wrapperClassName}>
        <button
          disabled={disabled}
          onClick={handleClick}
          className={fileButtonClassName}
        >
          <i className="ri-upload-cloud-2-line p-s-8 leading-none text-24 bg-accent rounded-md" />
          <span className="block">
            <span className="block font-semibold">
              {fileName || t('button.selectFile')}
            </span>
            <span className="block text-12">{acceptedTypes.text}</span>
          </span>
        </button>

        {fileName && (
          <div className="absolute right-s-16 top-s-0 h-full flex items-center">
            <IconButton
              ghost
              icon={<i className="ri-close-line text-24 font-normal" />}
              text={t('buttons.removeFile')}
              radius="round"
              size="small"
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FileInput;
