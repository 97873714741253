import { useQuery } from 'urql';

import { Query, QueryCandidateOpportunitiesArgs } from '@domain/graphql.types';
import { CandidateOpportunities } from '@infrastructure/graphql/candidate';

const useCandidateOpportunities = (input?: QueryCandidateOpportunitiesArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateOpportunities'>,
    QueryCandidateOpportunitiesArgs
  >({
    query: CandidateOpportunities,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateOpportunities;
