import { Config } from 'tailwindcss/types/config';

import fullConfig from './config.cjs';

const { daisyui, theme: twTheme } = fullConfig as Omit<Config, 'content'>;

type ThemeValue = {
  [key: string]: any;
};

export const COLORS: ThemeValue = {
  ...twTheme?.extend?.colors,
  ...daisyui?.themes[0].kehops,
};

export const SPACING: ThemeValue = {
  ...twTheme?.spacing,
};

export const FONT_SIZE: ThemeValue = {
  ...twTheme?.fontSize,
};

export const FONT_FAMILY: ThemeValue = {
  ...twTheme?.fontFamily,
};

export const SCREENS: ThemeValue = {
  ...twTheme?.screens,
};

/**
 * Export tailwind theme only from config
 * - Merge `daisyUI` and tailwind theme colors all together
 */
const theme: ThemeValue = {
  ...twTheme,
  colors: {
    ...COLORS,
  },
};

export default theme;
