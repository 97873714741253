import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaField } from '@application/components';

import { Section } from '../../../request/components';
import { OfferFormFields } from '../../types';

const SalesPitchFields = () => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'offer',
  });
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
  }: UseFormReturn<OfferFormFields> = useFormContext();

  return (
    <Section legend={t('subtitles.salesArguments')} className="mt-s-40" hideBox>
      <Controller
        name="salesPitch"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <TextAreaField
            name={name}
            value={value || ''}
            rows={4}
            maxChar={2500}
            onChange={onChange}
            invalid={!!errors.salesPitch}
            helperText={
              errors.salesPitch?.message &&
              tGlobal(errors.salesPitch?.message, { max: '1024' })
            }
          />
        )}
      />
    </Section>
  );
};

export default SalesPitchFields;
