import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import SummaryBadges from '@application/components/summary/SummaryBadges';
import { OfferCandidate } from '@domain/graphql.types';

type SoftSkillsProps = {
  offerCandidate: OfferCandidate | undefined;
};

const SoftSkills = ({ offerCandidate }: SoftSkillsProps) => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'candidateView',
  });

  const skillFields = useMemo(
    () => [
      {
        text: t('labels.softSkills'),
        badges: offerCandidate?.softSkills?.map((softSkill) => softSkill) || [],
      },
    ],
    [offerCandidate, t]
  );

  return (
    <>
      <SummaryHeader
        className="mt-s-16"
        headerTitle={t('subtitles.skillsCompetencies')}
      />

      <section className="grid grid-cols-[1fr] border rounded-b-md">
        {skillFields.map((remunerationField, index) => (
          <SectionGridRow key={remunerationField.text} isTopRow={index === 0}>
            {remunerationField.text} : &nbsp;
            <SummaryBadges badges={remunerationField.badges} />
          </SectionGridRow>
        ))}
      </section>
    </>
  );
};

export default SoftSkills;
