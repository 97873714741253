import { ChangeEvent, ReactNode, useMemo } from 'react';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';

export type AccordionItemProps = {
  /**
   * Specify content elements of the `<AccordionItem>` details
   */
  children: ReactNode;
  /**
   * Specify content elements of the `<AccordionItem>` summary
   */
  summary: ReactNode;
  /**
   * Specify the name of the `<AccordionItem>` input radio
   */
  name?: string;
  /**
   * Specify the value of the `<AccordionItem>` input radio
   */
  value?: string;
  /**
   * Specify whether the `<AccordionItem>` is expanded
   */
  expanded?: boolean;

  /**
   * Optionally provide an `onChange` handler that is called whenever the radio `<input>` is updated
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Provide a custom className to be applied to the `<AccordionItem>`
   */
  customClassName?: string;
};

const AccordionItem = ({
  name,
  value,
  children,
  summary,
  expanded,
  onChange,
  customClassName,
}: AccordionItemProps) => {
  const headerId = useMemo(() => `accordion-item-header-${randomString()}`, []);
  const panelId = useMemo(() => `accordion-item-panel-${randomString()}`, []);

  const className = cn(
    'collapse collapse-arrow border border-stroke-default rounded-md',
    customClassName
  );

  return (
    <div className={className}>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={expanded}
        onChange={onChange}
      />
      <AccordionSummary id={headerId} aria-controls={panelId}>
        {summary}
      </AccordionSummary>
      <AccordionDetails id={panelId} expanded={expanded}>
        {children}
      </AccordionDetails>
    </div>
  );
};

export default AccordionItem;
