import { gql } from 'urql';

const UserUpdateProfile = gql`
  mutation UserUpdateProfile($input: UpdateUserProfileInput!) {
    userUpdateProfile(input: $input) {
      user {
        id
      }
    }
  }
`;

export default UserUpdateProfile;
