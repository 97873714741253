import { useCallback } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Cluster, IconButton, Link } from '@application/components';
import { FileInputField } from '@application/components/form/file-input-field';
import { useUploadFile } from '@application/hooks';
import {
  useDeleteCandidateOfferCV,
  useDeleteCandidateOfferOtherFile,
} from '@application/views/recruitment/offer/hooks';
import { OfferFormFields } from '@application/views/recruitment/offer/types';
import { CandidateRequestFile } from '@domain/graphql.types';

import { OtherFile } from '../components';

type AttachedFilesFieldsProps = {
  index: number;
};

const AttachedFilesFields = ({ index }: AttachedFilesFieldsProps) => {
  const { t } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const { deleteOfferCandidateCV } = useDeleteCandidateOfferCV();
  const { deleteOfferCandidateOtherFile } = useDeleteCandidateOfferOtherFile();

  const {
    control,
    setValue,
    watch,
    setError,
    clearErrors,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const resume = watch(`offerCandidates.${index}.resume` as any);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const otherFiles = watch(`offerCandidates.${index}.otherFiles`) || [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const linkFileNames = watch(`offerCandidates.${index}.linkFileNames`) || [];
  const offerCandidate = watch(`offerCandidates.${index}` as any);

  const isFileUnique = useCallback(
    (fileName: string) =>
      !otherFiles.some(
        (otherFile) =>
          typeof otherFile !== 'string' && otherFile?.name === fileName
      ) &&
      !linkFileNames.some(
        (linkFile) => linkFile?.fileName?.split('/').pop() === fileName
      ),
    [linkFileNames, otherFiles]
  );

  const { onFileChange, getReadableExtensions, getAllowedExtensions } =
    useUploadFile({
      setFile: (name: string, value: CandidateRequestFile) => {
        if (name === `offerCandidates.${index}.resume`) {
          setValue(name as keyof OfferFormFields, value.file);
        } else if (
          name === `offerCandidates.${index}.otherFiles` &&
          isFileUnique(value.file.name)
        ) {
          setValue(name as keyof OfferFormFields, [...otherFiles, value.file]);
        }
      },
      setFileError: (name: string, message: string) =>
        setError(name as any, { type: 'custom', message }),
      clearFileErrors: (name: string) => {
        clearErrors(name as any);
      },
    });

  const handleDeleteOtherFiles = useCallback(
    async (file: string | File) => {
      const isFileUploaded = typeof file === 'string';

      if (isFileUploaded) {
        const fileUrl = linkFileNames.find(
          (linkFile) => linkFile?.link === file
        )?.fileName;
        if (fileUrl) {
          await deleteOfferCandidateOtherFile({
            input: {
              offerCandidateId: offerCandidate.id,
              fileUrl,
            },
          });
        }
        setValue(`offerCandidates.${index}.otherFiles`, [
          ...otherFiles.filter(
            (otherFile) => typeof otherFile !== 'string' || otherFile !== file
          ),
        ]);
        setValue(`offerCandidates.${index}.linkFileNames`, [
          ...linkFileNames.filter((linkFile) => linkFile?.link !== file),
        ]);
      } else {
        setValue(`offerCandidates.${index}.otherFiles`, [
          ...otherFiles.filter(
            (otherFile) =>
              typeof otherFile === 'string' || otherFile?.name !== file.name
          ),
        ]);
      }
    },
    [
      deleteOfferCandidateOtherFile,
      offerCandidate.id,
      linkFileNames,
      setValue,
      index,
      otherFiles,
    ]
  );

  const handleDeleteCV = useCallback(async () => {
    await deleteOfferCandidateCV({
      input: { offerCandidateId: offerCandidate.id },
    });

    setValue(`offerCandidates.${index}.resume`, undefined);
  }, [deleteOfferCandidateCV, index, setValue, offerCandidate.id]);

  return (
    <>
      <Controller
        name={`offerCandidates.${index}.resume`}
        control={control}
        render={({ field: { name }, fieldState: { error } }) => (
          <FileInputField
            name={name}
            label={t('labels.resume')}
            acceptTypes={{
              values: getAllowedExtensions(),
              text: getReadableExtensions(),
            }}
            fileName={resume?.name}
            invalid={!!error}
            helperText={
              error?.message &&
              tGlobal(error.message, {
                fileExtensions: getAllowedExtensions().split(',').join(', '),
              })
            }
            onChange={onFileChange}
            onRemove={() => {
              setValue(`offerCandidates.${index}.resume`, undefined);
            }}
          />
        )}
      />

      {typeof resume === 'string' && (
        <div className="flex items-center gap-s-4">
          <Link to={resume} newTab className="underline text-16 font-semibold">
            {t('labels.viewCv')}
          </Link>
          <IconButton
            ghost
            icon={<i className="ri-close-line text-24 font-normal" />}
            text={t('buttons.removeFile')}
            radius="round"
            size="small"
            onClick={handleDeleteCV}
          />
        </div>
      )}

      <Controller
        name={`offerCandidates.${index}.otherFiles`}
        control={control}
        render={({ field: { name }, fieldState: { error } }) => (
          <FileInputField
            name={name}
            label={t('labels.otherFiles')}
            acceptTypes={{
              values: getAllowedExtensions(),
              text: getReadableExtensions(),
            }}
            invalid={!!error}
            helperText={
              error?.message &&
              tGlobal(error.message, {
                fileExtensions: getAllowedExtensions().split(',').join(', '),
              })
            }
            onChange={onFileChange}
          />
        )}
      />

      <Cluster>
        {otherFiles.map((file) => (
          <OtherFile
            key={typeof file === 'object' ? file.name : file}
            file={file}
            linkFileNames={linkFileNames}
            onDeleteOtherFiles={handleDeleteOtherFiles}
          />
        ))}
      </Cluster>
    </>
  );
};

export default AttachedFilesFields;
