import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { Stack, Switcher } from '@application/components/container-layouts';
import { SelectField } from '@application/components/select-field';
import { mapOptions } from '@application/utils';
import { useGetOperationTerritories } from '@application/views/organization/profile/hooks';

type FiltersFieldsProps = {
  updateTextFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  updateSelectFilters: (values: any) => void;
};

const FiltersFields = ({
  updateTextFilter,
  updateSelectFilters,
}: FiltersFieldsProps) => {
  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'accountOperationTerritories.filters',
  });
  const { data: OPERATION_TERRITORIES = [] } = useGetOperationTerritories();

  const operationTerritories = useMemo(
    () => mapOptions(OPERATION_TERRITORIES, i18n.language),
    [OPERATION_TERRITORIES, i18n]
  );

  return (
    <Stack space={16}>
      <Switcher space={24} limit={2} threshold="lg" className="items-end">
        <TextInputField
          label={t('search')}
          name="text"
          placeholder={`${t('search')}...`}
          withIcon="ri-search-2-line text-24"
          hideIconBorder
          className="flex-1"
          onChange={updateTextFilter}
        />

        <SelectField
          label={t('filterByOperationTerritory')}
          name="operationTerritories"
          options={operationTerritories}
          onChange={updateSelectFilters}
          multiple
          className="flex-1"
          limitTags={0}
          clearable
        />
      </Switcher>
    </Stack>
  );
};

export default FiltersFields;
