import { cn } from '@utils/lib-utils';

export type ModalActionProps = {
  children: React.ReactNode | null;
  className?: string;
};

const ModalAction = ({
  children,
  className: customClassName,
}: ModalActionProps) => {
  const className = cn('modal-action mt-s-32', customClassName);

  return children ? <div className={className}>{children}</div> : null;
};

export default ModalAction;
