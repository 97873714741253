import { useTranslation } from 'react-i18next';

import { IconButton } from '../buttons';

export type ModalCloseBtnProps = {
  onClick: () => void;
};

const ModalCloseBtn = ({ onClick }: ModalCloseBtnProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      className="absolute right-s-16 top-s-16"
      icon={<i className="ri-close-line text-32 font-normal" />}
      onClick={onClick}
      text={t('button.close')}
      radius="round"
      ghost
    />
  );
};
export default ModalCloseBtn;
