import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

// translations keys in breadcrumbs are the url paths
const Breadcrumbs = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const params = useParams();

  const paths = useMemo(
    () =>
      Object.values(params)
        .flatMap((p) => p ?? [])
        .reduce((acc, curr) => acc.replace(curr, ''), location.pathname)
        .split('/')
        .filter((p) => !!p),
    [location, params]
  );

  return (
    <ul className="p-small flex w-s-0 overflow-hidden lg:w-full text-neutral-secondary">
      {paths.map((path, index) => (
        <Fragment key={path}>
          <li>
            {index === paths.length - 1 ? (
              <span>{t(`breadcrumbs.urls.${path}`)}</span>
            ) : (
              <Link
                to={`/${paths.slice(0, index + 1).join('/')}`}
                className="text-neutral-secondary hover:underline"
              >
                {t(`breadcrumbs.urls.${path}`)}
              </Link>
            )}
          </li>
          {index < paths.length - 1 && <>&nbsp;/&nbsp;</>}
        </Fragment>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
