import { useQuery } from 'urql';

import { Query, QueryCandidateNegotiationArgs } from '@domain/graphql.types';
import { CandidateNegotiation } from '@infrastructure/graphql/candidate';

const useCandidateNegotiation = (input: QueryCandidateNegotiationArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'candidateNegotiation'>,
    QueryCandidateNegotiationArgs
  >({
    query: CandidateNegotiation,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useCandidateNegotiation;
