import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Request, RequestResponse } from '@domain/graphql.types';

type ToViewModel = {
  request: {
    data: RequestResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  request: { data, fetching, error },
}: ToViewModel): ViewModel<Request | undefined> =>
  new ViewModel<Request | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data: data?.request || undefined,
  });
