import React, { ForwardedRef, useMemo } from 'react';

import { cn } from '@utils/lib-utils';

type IconBadgeProps = {
  icon?: string;
  className?: string;
  color?: string;
};

type Ref = HTMLDivElement;

const IconBadge = React.forwardRef<Ref, IconBadgeProps>(
  (
    { icon, className: customClassName, color, ...rest }: IconBadgeProps,
    ref: ForwardedRef<Ref>
  ) => {
    const colorClassName = useMemo(() => {
      switch (color) {
        case 'light-blue':
          return 'bg-badge-light-blue border-badge-light-blue text-badge-blue';
        case 'light-green':
          return 'bg-badge-light-green border-badge-light-green text-badge-green';
        case 'light-yellow':
          return 'bg-badge-light-yellow border-badge-light-yellow text-badge-yellow';
        case 'light-pink':
          return 'bg-badge-light-pink border-badge-light-pink text-badge-pink';
        default:
          return '';
      }
    }, [color]);

    const iconClassName = useMemo(() => {
      switch (icon) {
        case 'customer-service':
          return 'ri-customer-service-2-line';
        case 'timer':
          return 'ri-timer-2-line';
        case 'profile':
          return 'ri-profile-line';
        case 'global':
          return 'ri-global-line';
        default:
          return '';
      }
    }, [icon]);

    return (
      <div
        ref={ref}
        className={cn(
          'badge w-s-32 h-s-32 px-s-12 py-s-10 min-h-[2rem]',
          colorClassName,
          customClassName
        )}
        // Add tabindex 0 in order to make the div focusable
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        {...rest}
      >
        <i className={cn('text-16', iconClassName)} />
      </div>
    );
  }
);

export default IconBadge;
