import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FormInputWithSuffix,
  Modal,
  RadioGroup,
  RangeSlider,
  Stack,
} from '@application/components';
import { useGetJobPaymentFrequencies } from '@application/views/recruitment/request/hooks';
import {
  CandidateOffer,
  CandidateRequestType,
  JobPaymentFrequencyCode,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { REMUNERATION_DEFAULT_VALUES } from '../../room/constants';
import { normalizeRemunerationInputData } from '../../room/normalizers';
import { REMUNERATION_SCHEMA } from '../../room/schema';
import {
  CandidateNegotiationRoomFormFields,
  RemunerationFormFields,
} from '../../room/types';

type UpdateRemunerationModalProps = {
  type: CandidateRequestType | undefined;
  data: CandidateOffer | undefined;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<CandidateNegotiationRoomFormFields>;
};

const UpdateRemunerationModal = ({
  type,
  data,
  isLoading = false,
  onClose,
  onSubmit,
}: UpdateRemunerationModalProps) => {
  const { t: tGlobal } = useTranslation();
  const { i18n, t } = useTranslation('candidates');

  const { data: JOB_PAYMENT_FREQUENCIES = [] } = useGetJobPaymentFrequencies();

  const JOB_PAYMENT_FREQUENCIES_OPTIONS = useMemo(() => {
    switch (type) {
      case CandidateRequestType.TemporaryPlacement:
      case CandidateRequestType.Outsourcing:
        return JOB_PAYMENT_FREQUENCIES.filter(
          (p) => p.value !== JobPaymentFrequencyCode.Yearly
        );
      default:
        return JOB_PAYMENT_FREQUENCIES;
    }
  }, [type, JOB_PAYMENT_FREQUENCIES]);

  // NOTE: In order to trigger validation only on fields present in the modal
  // we need to use a separated schema from the original one.
  // Those field values will be merged to values from the original schema at submit.
  const {
    control,
    register,
    trigger,
    setValue,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<RemunerationFormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(REMUNERATION_SCHEMA),
    defaultValues: REMUNERATION_DEFAULT_VALUES,
  });

  const paymentFrequencyCode = watch('paymentFrequencyCode');
  const hourlyRateMin = watch('hourlyRateMin');
  const hourlyRateMax = watch('hourlyRateMax');
  const annualSalaryMin = watch('annualSalaryMin');
  const annualSalaryMax = watch('annualSalaryMax');

  useEffect(() => {
    if (data) {
      const d = {
        ...normalizeRemunerationInputData(data),
      };
      reset(d as RemunerationFormFields);
    }
  }, [data, reset]);

  return (
    <form
      onSubmit={() => {
        handleSubmit(onSubmit)();
        onClose();
      }}
    >
      <Modal.CloseBtn onClick={onClose} />

      <Stack>
        <Controller
          name="paymentFrequencyCode"
          control={control}
          render={({ field: { name, value } }) => (
            <RadioGroup
              name={name}
              data={JOB_PAYMENT_FREQUENCIES_OPTIONS}
              legend={t('labels.remunerationType')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as JobPaymentFrequencyCode);
                trigger('remunerationAmount');
                trigger(name);
              }}
              legendSize="small"
              value={(value as JobPaymentFrequencyCode) || undefined}
              invalid={!!errors.paymentFrequencyCode}
              helperText={
                errors.paymentFrequencyCode?.message &&
                tGlobal(errors.paymentFrequencyCode?.message)
              }
            />
          )}
        />

        {paymentFrequencyCode === JobPaymentFrequencyCode.Yearly && (
          <RangeSlider
            minLabel={t('labels.minSalary')}
            maxLabel={t('labels.maxSalary')}
            minName="annualSalaryMin"
            maxName="annualSalaryMax"
            onChange={(name: string, value: number | string) =>
              setValue(name as keyof RemunerationFormFields, value as number)
            }
            minValue={(annualSalaryMin as number) || undefined}
            maxValue={(annualSalaryMax as number) || undefined}
            min={1}
            max={1000}
            suffix="k$"
          />
        )}

        {paymentFrequencyCode === JobPaymentFrequencyCode.Hourly && (
          <>
            <RangeSlider
              minLabel={t('labels.minHourlyRate')}
              maxLabel={t('labels.maxHourlyRate')}
              minName="hourlyRateMin"
              maxName="hourlyRateMax"
              onChange={(name: string, value: number | string) =>
                setValue(name as keyof RemunerationFormFields, value as number)
              }
              minValue={(hourlyRateMin as number) || undefined}
              maxValue={(hourlyRateMax as number) || undefined}
              min={0.01}
              max={999.99}
              step={0.01}
              suffix="$/h"
            />

            <FormInputWithSuffix
              label={t('labels.hourlyBonus')}
              suffix="$"
              type="number"
              minValue={1}
              maxValue={999.99}
              step={0.01}
              alignLeft
              invalid={!!errors.hourlyBonus}
              helperText={
                errors.hourlyBonus?.message &&
                tGlobal(errors.hourlyBonus?.message, {
                  max: '999.99',
                  min: '1',
                })
              }
              {...register('hourlyBonus')}
            />
          </>
        )}

        {paymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount && (
          <FormInputWithSuffix
            type="number"
            step={0.01}
            minValue={1}
            maxValue={99999999.99}
            label={t('labels.compensationAmount')}
            suffix="$"
            invalid={!!errors.remunerationAmount}
            helperText={
              errors.remunerationAmount?.message &&
              tGlobal(errors.remunerationAmount?.message, {
                max: '99,999,999.99',
                min: '1',
              })
            }
            {...register('remunerationAmount')}
          />
        )}
      </Stack>

      <Modal.Action>
        <Button onClick={onClose} disabled={isLoading}>
          {tGlobal('button.cancel')}
        </Button>

        <Button primary type="submit" loading={isLoading}>
          {t('button.updateRemuneration')}
        </Button>
      </Modal.Action>
    </form>
  );
};

export default UpdateRemunerationModal;
