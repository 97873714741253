import { gql } from '@urql/core';

const InvitationDelete = gql`
  mutation InvitationDelete($input: DeleteInvitationInput!) {
    invitationDelete(input: $input) {
      ok
    }
  }
`;

export default InvitationDelete;
