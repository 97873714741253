import { useMutation } from 'urql';

import {
  Mutation,
  MutationRequestStatusUpdateArgs,
} from '@domain/graphql.types';
import { MutationRequestStatusUpdate } from '@infrastructure/graphql/request';

const useRequestStatusUpdate = () => {
  const [{ data, fetching, error }, updateRequestStatus] = useMutation<
    Pick<Mutation, 'requestStatusUpdate'>,
    MutationRequestStatusUpdateArgs
  >(MutationRequestStatusUpdate);

  return {
    updateRequestStatus,
    data,
    fetching,
    error,
  };
};

export default useRequestStatusUpdate;
