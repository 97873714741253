import { ChangeEvent, FocusEvent, useMemo } from 'react';

import { Checkbox, Fieldset, HelperText } from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import { Languages } from '@application/enums/languages';
import { ValueText } from '@application/utils';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

type CheckboxGroupProps = {
  data: ValueText[];
  language: keyof typeof Languages;
  className?: string;
  legend?: string;
  hideLegend?: boolean;
  legendSize?: 'small' | 'medium' | 'big';
  values?: any[] | undefined | null;
  helperText?: string;
  invalid?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
};

const CheckboxGroup = ({
  data,
  language,
  className: customClassName,
  legend,
  hideLegend,
  legendSize,
  onChange,
  onBlur,
  values,
  helperText,
  invalid,
  disabled = false,
}: CheckboxGroupProps) => {
  const className = cn('', customClassName);

  const helperTextId = useMemo(() => randomString(), []);

  return (
    <Fieldset
      legend={legend}
      hideLegend={hideLegend}
      legendSize={legendSize}
      className={className}
      invalid={invalid}
    >
      <Cluster space={16} as="ul">
        {data.map(({ value, text }: ValueText) => (
          <li key={value}>
            <Checkbox
              aria-describedby={helperTextId}
              disabled={disabled}
              name={value}
              value={value}
              label={text[language as keyof typeof Languages]}
              onChange={onChange}
              onBlur={onBlur}
              checked={values?.includes(value)}
              asButton
            />
          </li>
        ))}
      </Cluster>

      {helperText && (
        <HelperText id={helperTextId} invalid={invalid}>
          {helperText}
        </HelperText>
      )}
    </Fieldset>
  );
};

export default CheckboxGroup;
