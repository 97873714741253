import { useMutation } from 'urql';

import {
  Mutation,
  MutationOfferCandidateCreateArgs,
} from '@domain/graphql.types';
import { MutationOfferCandidateCreate } from '@infrastructure/graphql/offer';

const useOfferCandidateCreate = () => {
  const [{ data, fetching, error }, createOfferCandidate] = useMutation<
    Pick<Mutation, 'offerCandidateCreate'>,
    MutationOfferCandidateCreateArgs
  >(MutationOfferCandidateCreate);

  return {
    createOfferCandidate,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateCreate;
