import { RecruitmentNegotiationRoomMessageInput } from '@domain/graphql.types';
import { useRecruitmentNegotiationRoomMessages } from '@domain/negotiation/';

import { toViewModel } from '../view-models/get-recruitment-negotiation-room-message.vm';

const useGetRecruitmentNegotiationRoomMessages = (
  input: RecruitmentNegotiationRoomMessageInput
) => {
  const { data, fetching, error, reexecuteQuery } =
    useRecruitmentNegotiationRoomMessages({
      input,
    });

  const viewModel = toViewModel({
    recruitmentNegotiationRoomMessages: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRecruitmentNegotiationRoomMessages: reexecuteQuery,
  };
};

export default useGetRecruitmentNegotiationRoomMessages;
