import { ElementType, ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

type HeadingGroupProps = {
  title: ReactNode;
  titleAs?: ElementType;
  subtitle?: ReactNode;
  /**
   * Specify the HTML tag applied to the subtitle
   *
   * NOTE: Restrict to `<p>`, `<span>` and `<div>` to respect semantic
   */
  subtitleAs?: 'p' | 'span' | 'div';
  className?: string;
  titleClassName?: string;
  subtitleClassName?: string;
};

const HeadingGroup = ({
  title,
  titleAs: TitleTag = 'h2',
  subtitle,
  subtitleAs: SubtitleTag = 'p',
  className: customClassName,
  titleClassName: customTitleClassName,
  subtitleClassName: customSubtitleClassName,
}: HeadingGroupProps) => {
  const className = cn('flex items-center gap-s-16', customClassName);
  const titleClassName = cn('h2', customTitleClassName);
  const subtitleClassName = cn('', customSubtitleClassName);

  return (
    <hgroup
      role="group"
      aria-roledescription="Heading group"
      className={className}
    >
      <TitleTag className={titleClassName}>{title}</TitleTag>
      <SubtitleTag
        aria-roledescription="subtitle"
        className={subtitleClassName}
      >
        {subtitle}
      </SubtitleTag>
    </hgroup>
  );
};

export default HeadingGroup;
