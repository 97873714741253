import { gql } from 'urql';

const RecruitmentNegotiationRoomMessages = gql`
  query RecruitmentNegotiationRoomMessages(
    $input: RecruitmentNegotiationRoomMessageInput!
  ) {
    recruitmentNegotiationRoomMessages(input: $input) {
      results {
        id
        recruitmentNegotiationRoomId
        accountId
        userId
        message
        sentAt
      }
      totalCount
    }
  }
`;

export default RecruitmentNegotiationRoomMessages;
