import React, { ForwardedRef, ReactNode, useMemo } from 'react';

import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

import HelperText from '../HelperText';
import Label from '../Label';
import { TextInput, TextInputType } from './textInput';

type TextInputFieldProps = {
  /**
   * Provide the text that will be read by a screen reader
   * when visiting this control
   */
  label: ReactNode;
  /**
   * Provide text that is used alongside the `<input>` for additional help
   * or as a validation message on input error
   */
  helperText?: string;
  /**
   * Specify the type <input> element to display
   */
  type?: TextInputType;
  /**
   * Specify a custom `id` for the `<input>`
   */
  id?: string;
  /**
   * Specify the name of the `<input>`
   */
  name: string;
  /**
   * Specify the value of the `<input>`
   */
  value?: string | number | undefined;
  /**
   * Specify the placeholder attribute for the `<input>`
   */
  placeholder?: string;
  /**
   *  Specify the maximum of character allowed for the `<input>`
   */
  maxChar?: number;
  /**
   * Specify the minimum allowed value for the `<input>`
   */
  minValue?: number | string | undefined;
  /**
   * Specify the maximum allowed value for the `<input>`
   */
  maxValue?: number | string | undefined;
  /**
   * Specify whether the `<input>` should be disabled
   */
  disabled?: boolean;
  /**
   * Specify wether the `<input>` should be invalid
   */
  invalid?: boolean;
  /**
   * Specify whether the `<label>` should be visually hidden
   */
  hideLabel?: boolean;
  /**
   * Specify the class of the icon
   */
  withIcon?: string;
  /**
   * Specify if the border between the input and the icon is hidden
   */
  hideIconBorder?: boolean;
  /**
   * Provide a custom className to be applied to the `<input>`
   */
  className?: string;
  /**
   * Provide a custom className to be applied to the `<input>`
   */
  inputClassName?: string;
  /**
   * Provide a custom className to be applied to the label `<input>`
   */
  labelClassName?: string;
  /**
   * Specify whether the `<input>` should be readOnly
   */
  readOnly?: boolean;
  /**
   * Optionally provide an `onChange` handler that is called whenever `<input>` is updated
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optionally provide an `onBlur` handler that is called whenever `<input>` loses focus
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Specify what it the step for the input type number
   */
  step?: number;
  /**
   * Action to execute on display Change
   */
  onDisplayChange?: (isVisible: boolean) => void;
  /**
   * Value of the associated display
   */
  displayValue?: boolean;
};

type Ref = HTMLDivElement;

const TextInputField = React.forwardRef<Ref, TextInputFieldProps>(
  (
    {
      label,
      type,
      id,
      name,
      value,
      placeholder,
      minValue,
      maxValue,
      disabled,
      invalid,
      helperText,
      hideLabel,
      withIcon,
      hideIconBorder,
      className: customClassName,
      inputClassName: customInputClassName,
      labelClassName: customLabelClassName,
      readOnly,
      onChange,
      onBlur,
      step,
      onDisplayChange,
      displayValue,
      ...rest
    }: TextInputFieldProps,
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    // const [display, setdisplay] = useState(true);
    const inputId = useMemo(() => id || randomString(), [id]);
    const helperTextId = useMemo(() => randomString(), []);

    const className = cn('', customClassName);
    const labelClassName = cn({ 'sr-only': hideLabel }, customLabelClassName);
    const inputWrapperClassName = cn('flex', {
      'flex-row-reverse': withIcon,
      'flex-row': !!onDisplayChange,
    });
    const inputClassName = cn(customInputClassName, {
      'rounded-l-none peer flex-1': withIcon,
      'rounded-r-none peer flex-1': !!onDisplayChange,
      'border-l-0 pl-s-0': hideIconBorder,
    });
    const iconWrapperClassName = cn(
      'flex items-center justify-center h-s-48 min-w-[3rem] border-1 rounded-l-md border-stroke-default border-r-0 peer-focus:border-primary bg-primary-content',
      {
        'text-error-content bg-error border-error-content peer-focus:border-error-content':
          invalid,
        'disabled:text-disabled-content': disabled,
        'disabled:text-disabled-content disabled:border-error-content':
          disabled && invalid,
        'read-only:bg-readonly': readOnly,
      }
    );
    const visibilityIconWrapperClassName = cn(
      'flex items-center justify-center h-s-48 min-w-[3rem] border-1 rounded-r-md border-stroke-default border-l-0 peer-focus:border-primary bg-primary-content cursor-pointer',
      {
        'text-error-content bg-error border-error-content peer-focus:border-error-content':
          invalid,
        'disabled:text-disabled-content': disabled,
        'disabled:text-disabled-content disabled:border-error-content':
          disabled && invalid,
        'read-only:bg-readonly': readOnly,
      }
    );
    const iconClassName = cn('text-20', withIcon);

    return (
      <div ref={ref} className={className} {...rest}>
        <Label
          htmlFor={inputId}
          disabled={disabled}
          invalid={invalid}
          className={labelClassName}
        >
          {label}
        </Label>

        <div className={inputWrapperClassName}>
          <TextInput
            id={inputId}
            aria-describedby={helperText ? helperTextId : undefined}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            invalid={invalid}
            onChange={onChange}
            onBlur={onBlur}
            className={inputClassName}
            minValue={minValue}
            maxValue={maxValue}
            step={step}
          />

          {withIcon && (
            <div className={iconWrapperClassName}>
              <i className={iconClassName} />
            </div>
          )}

          {!!onDisplayChange && (
            <button
              className={visibilityIconWrapperClassName}
              onClick={() => onDisplayChange(!displayValue)}
              type="button"
              aria-label={displayValue ? 'show' : 'hide'}
            >
              <i className={displayValue ? 'ri-eye-fill' : 'ri-eye-off-line'} />
            </button>
          )}
        </div>

        {helperText && (
          <HelperText id={helperTextId} invalid={invalid}>
            {helperText}
          </HelperText>
        )}
      </div>
    );
  }
);

export default TextInputField;
